import React, { useEffect, useState } from 'react';

import { Plan } from 'interfaces/plans';
import { Range } from 'interfaces/range';

import styles from './ComparisonStrategiesGraph.module.scss';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import LinePreview from 'views/Analyze/components/LinePreview';
import { FilterChip } from 'interfaces/filter-chip';
import ChipFilter from 'components/ChipFilter/ChipFilter';
import MultilineChart from 'components/GraphElements/MultilineChart/MultilineChart';
import TimeRangeSlider from 'components/TimeRangeSlider/TimeRangeSlider';
import {
  useGetPlanTimeseriesQuery,
  useGetPlanTimeseriesVariablesQuery,
} from 'services/plans.service';
import { renderLineData } from 'utils/renderDataInGraph';
import { getNumberTicks } from 'utils/d3-utils/getTicks';
import { getNumberScale } from 'utils/d3-utils/getScale';
import { getDash } from 'utils/dashes.util';
import { useGetStrategiesTimeseriesQuery } from 'services/shop.service';
import { Strategy } from 'interfaces/strategies';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { planComparisonActions } from 'redux/slices/planComparisonSlice';
import { planCreationSelector } from 'redux/slices/planCreationSlice';
import { strategiesSelectors } from 'redux/slices/planStrategies.slice';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import dayjs from 'dayjs';
import { RESOLUTION } from 'config/constants';
import { translate } from 'utils/translations.util';

interface ComparisonStrategiesGraphProps {
  plan?: Plan;
  strategies: Strategy[];
  variablesFilters?: FilterChip[];
}

interface variableTimeSeriesWeek {
  [variable: string]: number;
  timestamp: number;
  dateTimeWeek: number;
}

const ComparisonStrategiesGraph: React.FC<ComparisonStrategiesGraphProps> = ({
  strategies,
  variablesFilters,
  plan,
}) => {
  const [firstStrategyToggle, setFirstStrategyToggle] = useState(strategies.length >= 1 && true);
  const [secondStrategyToggle, setSecondStrategyToggle] = useState(strategies.length >= 2 && true);
  const [planToggle, setPlanToggle] = useState(true);
  const { previewPlanId } = useAppSelector(planCreationSelector);
  const shop = useAppSelector(strategiesSelectors.shop);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const compareStrategies = useAppSelector(strategiesSelectors.compareStrategies);

  const dispatch = useAppDispatch();

  const { data: firstStrategy } = useGetStrategiesTimeseriesQuery(
    {
      shopId: shop?.id!,
      strategyId: String(compareStrategies[0]?.id),
    },
    { skip: !compareStrategies[0] }
  );

  const { data: secondStrategy } = useGetStrategiesTimeseriesQuery(
    {
      shopId: shop?.id!,
      strategyId: String(compareStrategies[1]?.id),
    },
    { skip: !compareStrategies[1] }
  );

  const { data: planTimeseriesVariables } = useGetPlanTimeseriesVariablesQuery(
    Number(previewPlanId),
    {
      skip: !previewPlanId,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: timeseriesData } = useGetPlanTimeseriesQuery(
    {
      planId: Number(previewPlanId),
      variables: planTimeseriesVariables?.map((variable) => variable.id),
      accountId: accountId,
    },
    {
      skip: !planTimeseriesVariables,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    let datesArray = timeseriesData?.local_datetime;
    let timeRangeStart = datesArray && dayjs(datesArray[0]?.local_datetime).unix();
    let timeRangeEnd =
      datesArray && dayjs(datesArray[datesArray.length - 1]?.local_datetime).unix();

    setInitialRange({ min: timeRangeStart, max: timeRangeEnd });
    setCurrentRange({ min: timeRangeStart, max: timeRangeEnd });
  }, [firstStrategy, secondStrategy, timeseriesData]);

  const [initialRange, setInitialRange] = useState({ min: 0, max: 30 });
  const [currentRange, setCurrentRange] = useState({ min: 0, max: 30 });

  const handleCurrentRangeChange = (newRange: Range) => {
    setCurrentRange(newRange);
  };

  const getToggleValue = (input: number) => {
    switch (input) {
      case 0:
        return firstStrategyToggle;
      case 1:
        return secondStrategyToggle;
      default:
        return false;
    }
  };

  const handleStrategyToggle = (input: number) => (value: boolean) => {
    switch (input) {
      case 0:
        setFirstStrategyToggle(!firstStrategyToggle);
        return;
      case 1:
        setSecondStrategyToggle(!secondStrategyToggle);
        return;
      default:
        return;
    }
  };

  const getAxis = (input: number) => {
    switch (input) {
      case 0:
        return 'left';
      case 1:
        return 'right';
      default:
        return 'left';
    }
  };

  const getFilteredGraphData = (data: variableTimeSeriesWeek[]) => {
    return data.filter(
      (datetime) => datetime.timestamp > currentRange.min && datetime.timestamp < currentRange.max
    );
  };

  const renderData = () => {
    const data = [];

    if (planToggle) {
      if (timeseriesData) {
        data.push(
          ...(variablesFilters
            ?.map((variable, index: number) =>
              renderLineData({
                data: getFilteredGraphData(timeseriesData[variable.value]),
                variable: variable.value,
                color: variable.color,
                stroke: getDash(0),
                axis: getAxis(index),
              })
            )
            .flat() || [])
        );
      }
    }
    if (firstStrategyToggle && firstStrategy) {
      data.push(
        ...(variablesFilters
          ?.map((variable, index: number) =>
            renderLineData({
              data: getFilteredGraphData(firstStrategy[variable.value]),
              variable: variable.value + strategies?.[0]?.id,
              color: variable.color,
              stroke: getDash(1),
              axis: getAxis(index),
            })
          )
          .flat() || [])
      );
    }
    if (secondStrategyToggle && secondStrategy) {
      data.push(
        ...(variablesFilters
          ?.map((variable, index: number) =>
            renderLineData({
              data: getFilteredGraphData(secondStrategy[variable.value]),
              variable: variable.value + strategies?.[1]?.id,
              color: variable.color,
              stroke: getDash(2),
              axis: getAxis(index),
            })
          )
          .flat() || [])
      );
    }

    return data;
  };

  const getLeftYInterval = () => {
    const interval = [];

    if (planToggle && variablesFilters) {
      interval.push(timeseriesData?.ranges[variablesFilters[0]?.value]);
    }
    if (firstStrategyToggle && variablesFilters) {
      interval.push(firstStrategy?.ranges[variablesFilters[0]?.value]);
    }
    if (secondStrategyToggle && variablesFilters) {
      interval.push(secondStrategy?.ranges[variablesFilters[0]?.value]);
    }

    if (!interval[0]) {
      return [{ min: 0, max: 4 }];
    }

    return interval;
  };

  const getRightYInterval = () => {
    const interval = [];

    if (planToggle && variablesFilters) {
      interval.push(timeseriesData?.ranges[variablesFilters[1]?.value]);
    }
    if (firstStrategyToggle && variablesFilters) {
      interval.push(firstStrategy?.ranges[variablesFilters[1]?.value]);
    }
    if (secondStrategyToggle && variablesFilters) {
      interval.push(secondStrategy?.ranges[variablesFilters[1]?.value]);
    }
    if (!interval[0]) {
      return [{ min: 0, max: 4 }];
    }

    return interval;
  };

  const getDataToDisplay = () => {
    const data = [];
    if (planToggle && timeseriesData) {
      data.push(
        ...(variablesFilters
          ?.map((variable, index: number) => ({
            data: getFilteredGraphData(timeseriesData[variable.value]),
            keyName: variable.value,
            color: variable.color,
            stroke: getDash(0),
            hasData: true,
            axis: getAxis(index),
            unit: translate(variable.unit),
          }))
          .flat() || [])
      );
    }

    if (firstStrategyToggle && firstStrategy) {
      data.push(
        ...(variablesFilters
          ?.map((variable, index: number) => ({
            data: getFilteredGraphData(firstStrategy[variable.value]),
            keyName: variable.value + strategies?.[0]?.id,
            color: variable.color,
            stroke: getDash(1),
            axis: getAxis(index),
            unit: translate(variable.unit),
          }))
          .flat() || [])
      );
    }
    if (secondStrategyToggle && secondStrategy) {
      data.push(
        ...(variablesFilters
          ?.map((variable, index: number) => ({
            data: getFilteredGraphData(secondStrategy[variable.value]),
            keyName: variable.value + strategies?.[1]?.id,
            color: variable.color,
            stroke: getDash(2),
            axis: getAxis(index),
            unit: translate(variable.unit),
          }))
          .flat() || [])
      );
    }
    return data;
  };

  return (
    <div className={styles.container}>
      <div className={styles['first-column']}>
        <div className={styles['switch-container']}>
          <div className={styles['switch-item']}>
            <div>
              <ToggleSwitch
                initialValue={planToggle}
                onToggle={(value: boolean) => setPlanToggle(!planToggle)}
              />
            </div>
            <div>
              <LinePreview index={0} />
            </div>
            <div className={styles['switch-plan']}>My Plan</div>
          </div>
          {strategies?.map((strategy, index: number) => (
            <div className={styles['switch-item']} key={index + 1}>
              <div>
                <ToggleSwitch
                  initialValue={getToggleValue(index)}
                  onToggle={handleStrategyToggle(index)}
                />
              </div>
              <div>
                <LinePreview index={index + 1} />
              </div>
              <div className={styles['switch-plan']}>{strategy.name}</div>
            </div>
          ))}
        </div>
        <div className={styles['chips-container']}>
          <ChipFilter
            chips={variablesFilters}
            onClear={(value) => dispatch(planComparisonActions.removeCompareVariable(value))}
            onClearAll={() => dispatch(planComparisonActions.removeCompareAllVariable())}
          />
        </div>
      </div>

      <div className={styles['second-column']}>
        <div className={styles['graph']}>
          <MultilineChart
            xAxisProperty='timestamp'
            title='comparison'
            showTitle={false}
            hasTooltip={true}
            XInterval={[currentRange.min, currentRange.max]}
            getTicks={getNumberTicks()}
            customScale={getNumberScale}
            axisDecimals={0}
            tooltipDecimals={0}
            resolution={RESOLUTION._1_week}
            leftAxisColor={(variablesFilters?.length && variablesFilters?.[0].color) || '#fff'}
            rightAxisColor={(variablesFilters?.length && variablesFilters?.[1]?.color) || '#fff'}
            isBiAxial={variablesFilters?.length! > 1}
            YInterval={getLeftYInterval()}
            rightAxisYInterval={getRightYInterval()}
            dataToDisplay={getDataToDisplay()}
          >
            {renderData()}
          </MultilineChart>
        </div>
        <TimeRangeSlider
          currentRange={currentRange}
          initialRange={initialRange}
          step={1}
          resolution={RESOLUTION._1_week}
          onCurrentRangeChange={handleCurrentRangeChange}
          customClassName='trs-comparison'
        />
      </div>
    </div>
  );
};
export default ComparisonStrategiesGraph;
