import UseGetNotificationItemData from 'hooks/useGetNotificationItemData';
import useUrlState from 'hooks/useUrlState';
import { CropseasonNotification } from 'interfaces/cropseason-notification';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { nofiticationSelector, notificationActions } from 'redux/slices/notification.slice';

import { ReactComponent as CloseSideViewIcon } from '../../../styles/icons/close-side-view.svg';
import styles from './NotificationDetails.module.scss';
import NotificationGraph from './NotificationGraph';

interface NotificationDetailsProps {
  notification: CropseasonNotification;
  isMobile: boolean;
}

const NotificationDetails: React.FC<NotificationDetailsProps> = ({ notification, isMobile }) => {
  const dispatch = useAppDispatch();
  const { selectedLanguage } = useAppSelector(languageSelector);
  const selectedNotification = useAppSelector(nofiticationSelector.selectedNotifications);
  const { getNotificationInfo } = UseGetNotificationItemData(selectedLanguage);

  const [, setUrlState] = useUrlState({
    selectedNotificationId: '',
  });

  const handleCloseDetails = () => {
    setUrlState({ selectedNotificationId: undefined });
    dispatch(notificationActions.setSelectedNotifications(null));
  };

  const hasGraphData = notification.values.graph;

  return (
    <>
      {selectedNotification && (
        <div className={styles['notification-details-container']}>
          <div className={styles['notification-details-header']}>
            <p className={styles['notification-details-header-title']}>
              {' '}
              {getNotificationInfo(selectedNotification)}
            </p>
            <CloseSideViewIcon
              className={styles['notification-details-close']}
              onClick={() => handleCloseDetails()}
            />
          </div>
          {hasGraphData && <NotificationGraph notification={notification} isMobile={isMobile} />}
        </div>
      )}
    </>
  );
};

export default NotificationDetails;
