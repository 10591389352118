import { IdName } from 'interfaces/id-name';
import { dataSorter } from 'utils/data-sorter';
import { baseApi } from 'redux/slices/baseApi.slice';

type UserSettings = {
  receive_emails: boolean;
  receive_push_message: boolean;
  accounts: {
    [account_id: number]: {
      default_cropseason_id: number;
      last_time_seen_notifications?: string;
    };
  };
};

export const userAccountInformationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserAccountId: builder.query<any, void>({
      query: () => 'access/users/me',
      transformResponse: (rawResult: { result: { user: { start_account_id: number } } }) => {
        if (process.env.REACT_APP_ACCOUNT) {
          return process.env.REACT_APP_ACCOUNT;
        }
        return rawResult.result.user.start_account_id;
      },
    }),
    getUserLinkedAccounts: builder.query<any, void>({
      query: () => 'access/accounts/me',
      transformResponse: (rawResult: { result: { accounts: IdName[] } }): IdName[] => {
        return dataSorter(rawResult.result.accounts, 'asc', 'name');
      },
    }),
    getUserSettings: builder.query<any, void>({
      query: () => 'user/me/settings',
      transformResponse: (rawResult: { result: UserSettings }): UserSettings => {
        return rawResult.result;
      },
    }),
    removeDefaultCropSeason: builder.mutation<any, { data: Partial<UserSettings> }>({
      query: ({ data }) => ({
        url: `user/me/settings`,
        body: data,
        method: 'PUT',
        credentials: 'include',
      }),
      async onQueryStarted({ data }, { dispatch, queryFulfilled, getState }) {
        let modifiedResult = dispatch(
          userAccountInformationApi.util.updateQueryData('getUserSettings', undefined, () => {
            return data;
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          modifiedResult.undo();
          console.error('Could not update defaultCropSeason.');
        }
      },
    }),
    updateUserSettings: builder.mutation<any, { data: Partial<UserSettings> }>({
      query: ({ data }) => ({
        url: `user/me/settings`,
        body: data,
        method: 'PUT',
        credentials: 'include',
      }),
      async onQueryStarted({ data }, { dispatch, queryFulfilled }) {
        let modifiedResult = dispatch(
          userAccountInformationApi.util.updateQueryData('getUserSettings', undefined, () => {
            return data;
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          modifiedResult.undo();
          console.error('Could not update defaultCropSeason.');
        }
      },
    }),
    consumeAccesToken: builder.mutation<any, string>({
      query: (access_token_id) => {
        return {
          url: `access/grant-access-token/` + access_token_id + '/consumed?value=true',
          method: 'PUT',
          credentials: 'include',
        };
      },
    }),
  }),
});

export const {
  useGetUserAccountIdQuery,
  useGetUserLinkedAccountsQuery,
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
  useRemoveDefaultCropSeasonMutation,
  useConsumeAccesTokenMutation,
} = userAccountInformationApi;
