import { FileCardActions } from 'interfaces/plans';
import React, { ReactElement } from 'react';
import { ReactComponent as Checkmark } from 'styles/icons/checkmark-circle.svg';
import { ReactComponent as Close } from 'styles/icons/close.svg';
import { ReactComponent as More } from 'styles/icons/more-horizontal-small.svg';

import styles from './FileTableRow.module.scss';

interface FileTableRowProps {
  icon?: ReactElement;
  actions?: FileCardActions;
  title?: string;
  isEditing?: boolean;
  children?: ReactElement;
  isClickable?: boolean;
  handleCardClick?: () => void;
  renameInput?: string;
  onChangeRename?: () => void;
  handleConfirmRename?: (value: string) => void;
  handleCancelRename?: () => void;
  toggleActions?: () => void;
  showActions?: () => void;
  popupRef?: React.RefObject<HTMLDivElement>;
  componentId?: string;
}

const FileTableRow: React.FC<FileTableRowProps> = ({
  icon,
  actions = {},
  title = '',
  isEditing = false,
  children = [],
  isClickable = false,
  handleCardClick = () => null,
  renameInput = '',
  onChangeRename = () => null,
  handleConfirmRename = () => null,
  handleCancelRename = () => null,
  toggleActions = () => null,
  showActions = () => null,
  popupRef = null,
  componentId = '',
}) => {
  const getClassName = (renameInput: string): string => {
    if (!actions.HANDLE_VALIDATE) {
      return '';
    }

    return !actions.HANDLE_VALIDATE(renameInput) ? styles['not-valid'] : '';
  };

  return (
    <tr
      className={`${styles.card} ${styles['light-mode']} ${isClickable && 'c-hand'}`}
      onClick={handleCardClick}
    >
      {isEditing ? (
        <>
          <td>
            <div className={styles['input-container']}>
              <input
                autoFocus
                value={renameInput}
                onChange={onChangeRename}
                type='text'
                className={`${styles.input} ${getClassName(renameInput)}`}
              ></input>
              <div className={styles['confirmations-container']}>
                <Checkmark
                  className={`${styles['actions-icon']} icon-light c-hand`}
                  onClick={() => handleConfirmRename(renameInput)}
                />
                <Close
                  className={`${styles['actions-icon']} icon-light c-hand`}
                  onClick={handleCancelRename}
                />
              </div>
            </div>
          </td>
          <td colSpan={5}></td>
        </>
      ) : (
        <>
          <td>
            <div className={styles['title-container']}>
              <div className={styles.icon}>{icon}</div>
              <div className={styles.title}>{title}</div>
            </div>
          </td>
          {children}
        </>
      )}
      {!isEditing && (
        <td>
          <div className={styles.actions}>
            <div
              onClick={(event) => {
                event.stopPropagation();
                toggleActions();
              }}
              className={`accordion 
            ${styles['accordion-generic-card']} 
            ${isClickable && 'c-hand'}`}
              open={isClickable && showActions}
              ref={popupRef}
            >
              <More
                className={`${styles['actions-icon']}
                'icon-light'`}
              />
              <label className='accordion-header' htmlFor={componentId}></label>
              <div className={`${styles['accordion-generic-card-body']} accordion-body`}>
                {actions.SHOW_ACTIONS()}
              </div>
            </div>
          </div>
        </td>
      )}
    </tr>
  );
};
export default FileTableRow;
