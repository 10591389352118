import React from 'react';

interface Point {
  x: number;
  y: number;
}
interface QuadrantProps {
  points: Point[];
  plantLoadTicks?: number[];
  stemDiamterTicks?: number[];
}

const GRAPH_WIDTH = 325;
const GRAPH_HEIGHT = 100;
const DOTS_OFFSET = 18;

const Y_POINT_INVERSE = 245;

const mapPosition = (point: Point): Point => {
  return {
    x: point.x * GRAPH_WIDTH + GRAPH_WIDTH,
    y: point.y * GRAPH_HEIGHT + GRAPH_HEIGHT,
  };
};

const radiusBasedOnIndex = ({ index, dataLength }: { index: number; dataLength: number }) => {
  return (3 * index) / dataLength;
};

const areDotsApart = (point1: Point, point2: Point, index: number, dataLength: number): boolean => {
  return (
    Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)) >
    radiusBasedOnIndex({ index, dataLength }) * 4 + DOTS_OFFSET
  );
};

const Quadrant: React.FC<QuadrantProps> = ({ points, plantLoadTicks, stemDiamterTicks }) => {
  const positions = points.map(mapPosition);

  const pointSizeOffset = 4;
  const renderArrow = (index: number) => {
    if (index > 0 && areDotsApart(positions[index - 1], positions[index], index, positions.length))
      return (
        <g id='arrow'>
          <defs>
            <marker
              id={`head${index}`}
              orient='auto'
              markerWidth='10'
              markerHeight='10'
              refX={
                4 +
                (radiusBasedOnIndex({ index, dataLength: positions.length }) * pointSizeOffset) /
                  2 +
                2
              }
              refY='3'
            >
              <line x1='0' y1='6' x2='3.1' y2='3' strokeWidth='1' stroke='#E7F0F3' />
              <line x1='0' y1='0' x2='3.1' y2='3' strokeWidth='1' stroke='#E7F0F3' />
            </marker>
          </defs>
          <line
            x1={positions[index - 1].x}
            y1={Y_POINT_INVERSE - positions[index - 1].y}
            x2={positions[index].x}
            y2={Y_POINT_INVERSE - positions[index].y}
            strokeWidth='2'
            strokeDasharray='2, 2'
            stroke='#E7F0F3'
            markerEnd={`url(#head${index})`}
          />
        </g>
      );
  };

  const renderData = () => {
    return positions.map((point, index) => {
      const radius = radiusBasedOnIndex({ index, dataLength: positions.length });
      return (
        <g key={index}>
          <circle
            cx={point.x}
            cy={Y_POINT_INVERSE - point.y + 0.5}
            r={(radius + 1) * pointSizeOffset + 0.5}
            fill='#000000cc'
          />
          <circle
            cx={point.x}
            cy={Y_POINT_INVERSE - point.y + 2}
            r={(radius + 1) * pointSizeOffset + 3}
            fill='#00000044'
          />
          <circle
            cx={point.x}
            cy={Y_POINT_INVERSE - point.y}
            r={(radius + 1) * pointSizeOffset}
            fill='#E7F0F3'
            stroke='#00000088'
            strokeWidth='1'
          />
          {renderArrow(index)}
        </g>
      );
    });
  };

  function renderXAxisTicks() {
    return plantLoadTicks?.map((tick, index) => {
      return (
        <text x={`${149 * index}`} y='345' fill='#E7F0F3' fontSize={18} key={index}>
          {tick.toFixed(0) || ''}
        </text>
      );
    });
  }

  function renderYAxisTicks() {
    const reversedTicks = stemDiamterTicks?.slice().reverse();
    return reversedTicks?.map((tick, index) => {
      return (
        <text
          x='-10'
          y={`${55 * index + 25} `}
          fill='#E7F0F3'
          textAnchor='end'
          fontSize={18}
          key={index}
        >
          {tick.toFixed(1) || ''}
        </text>
      );
    });
  }

  const textFontSize = 23;

  return (
    <svg height='100%' width='100%' viewBox='-100 0 760 400'>
      <g id='axes'>
        <rect width='650' height='320' stroke='#E7F0F3' strokeWidth='1' fill='none' />
        <text x='20' y='30' textAnchor='start' fill='#E7F0F3' fontSize={textFontSize}>
          Strong
        </text>

        <line x1='1' y1='160' x2='650' y2='160' strokeWidth='1' stroke='#E7F0F3' />
        <line x1='325' y1='1' x2='325' y2='319' strokeWidth='1' stroke='#E7F0F3' />
        <line
          x1='1'
          y1='270'
          x2='649'
          y2='270'
          strokeWidth='0.5'
          stroke='#576776'
          strokeDasharray='3, 3'
        />
        <line
          x1='1'
          y1='200'
          x2='649'
          y2='200'
          strokeWidth='0.5'
          stroke='#576776'
          strokeDasharray='3, 3'
        />
        <line
          x1='1'
          y1='130'
          x2='649'
          y2='130'
          strokeWidth='0.5'
          stroke='#576776'
          strokeDasharray='3, 3'
        />
        <line
          x1='1'
          y1='60'
          x2='649'
          y2='60'
          strokeWidth='0.5'
          stroke='#576776'
          strokeDasharray='3, 3'
        />
        <line
          x1='130'
          y1='1'
          x2='130'
          y2='319'
          strokeWidth='0.5'
          stroke='#576776'
          strokeDasharray='3, 3'
        />
        <line
          x1='260'
          y1='1'
          x2='260'
          y2='319'
          strokeWidth='0.5'
          stroke='#576776'
          strokeDasharray='3, 3'
        />
        <line
          x1='390'
          y1='1'
          x2='390'
          y2='319'
          strokeWidth='0.5'
          stroke='#576776'
          strokeDasharray='3, 3'
        />
        <line
          x1='520'
          y1='1'
          x2='520'
          y2='319'
          strokeWidth='0.5'
          stroke='#576776'
          strokeDasharray='3, 3'
        />
        <text x='20' y='58' textAnchor='start' fill='#E7F0F3' fontSize={textFontSize}>
          Vegetative
        </text>
        <text x='630' y='30' textAnchor='end' fill='#E7F0F3' fontSize={textFontSize}>
          Strong
        </text>
        <text x='630' y='58' textAnchor='end' fill='#E7F0F3' fontSize={textFontSize}>
          Generative
        </text>
        <text x='20' y='278' fill='#E7F0F3' fontSize={textFontSize}>
          Weak
        </text>
        <text x='20' y='305' fill='#E7F0F3' fontSize={textFontSize}>
          Vegetative
        </text>
        <text x='630' y='278' textAnchor='end' fill='#E7F0F3' fontSize={textFontSize}>
          Weak
        </text>
        <text x='630' y='305' textAnchor='end' fill='#E7F0F3' fontSize={textFontSize}>
          Generative
        </text>
        <text
          x='80'
          y='-220'
          textAnchor='bottom'
          fill='#E7F0F3'
          fontSize={14}
          writingMode='tb'
          transform={`rotate(180 0 0)`}
        >
          Stem diameter (mm)
        </text>
        <text x='270' y='-10' fill='#E7F0F3' fontSize={14}>
          Plant Load (fruit/m2)
        </text>
      </g>
      {renderXAxisTicks()}
      {renderData()}
      {renderYAxisTicks()}
    </svg>
  );
};
export default Quadrant;
