import { useMemo } from 'react';
import * as d3 from 'd3';

// If y0 and y1 are the same no area is shown, instead the representation should be a line.
// To avoid this, we add a small constant to the y0 value in order to represent a line.

const CONST_LINE_HEIGHT = 0.007;

const adjustY0 = (y0: number, y1: number) => {
  if (y0 === y1) {
    return y0 - CONST_LINE_HEIGHT;
  } else {
    return y0;
  }
}

export const useAreaController = (data: any, xScale?: any, yScale?: any) => {
  const d = useMemo(() => {
    const area = d3
      .area()
      .defined(
        (d: any) => d && (d.value[0] || d.value[0] === 0) && (d.value[1] || d.value[1] === 0)
      )
      .x((d) => xScale((d as any).timestamp))
      .y0((d) => yScale(adjustY0((d as any).value[0], (d as any).value[1])))
      .y1((d) => yScale((d as any).value[1]))

    if (data) {
      return area(data);
    }
  }, [xScale, yScale, data]);

  return d || '';
};
