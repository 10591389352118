import React, { ReactNode, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as Grid } from 'styles/icons/grid.svg';
import { ReactComponent as Climate } from 'styles/icons/climate.svg';
import { ReactComponent as WaterManagement } from 'styles/icons/water-management.svg';
import { ReactComponent as WaterEfficiency } from 'styles/icons/water-efficiency.svg';
import { ReactComponent as Weight } from 'styles/icons/fresh-weight-gain.svg';
import { ReactComponent as CropStatus } from 'styles/icons/crop-status.svg';
import { ReactComponent as Production } from 'styles/icons/production.svg';
import { ReactComponent as Settings } from 'styles/icons/settings-2.svg';
import { ReactComponent as Calendar } from 'styles/icons/calendar.svg';
import { ReactComponent as Trending } from 'styles/icons/trending-up.svg';
import { ReactComponent as Close } from 'styles/icons/close.svg';
import { ReactComponent as Bell } from 'styles/icons/bell.svg';
import { ReactComponent as NotificationSettings } from 'styles/icons/notification-settings.svg';

import { sideBarSelector, toggle, setLinksGroup } from 'redux/slices/sidebar.slice';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';
import AuthService from 'services/auth.service';

import { BREAKPOINT_LAPTOP } from 'config/constants';

import './Links.scss';
import LinkedAccounts from './LinkedAccounts/LinkedAccounts';
import Profile from './Profile/Profile';
import { translate } from 'utils/translations.util';
import { languageSelector } from 'redux/slices/language.slice';
import { track } from 'utils/analtycs';
import useFeatureFlags from 'hooks/useFeatureFlags';

interface LinksProps {
  children?: ReactNode;
}

const getNavLinkClassName = ({ isActive }: { isActive: boolean }) =>
  isActive ? 'active-route-link' : 'inactive-route-link';

const ExpandedSidebar: React.FC<{ selectedLanguage: string; path: string }> = ({
  path,
  selectedLanguage,
}) => {
  const checkPathForPlanSettings = () => {
    if (
      path.includes('/plan/preview') ||
      path.includes('/shop') ||
      path.includes('/plan-compare-graph')
    ) {
      return 'active-route-link';
    }
  };
  const dispatch = useAppDispatch();
  const windowSize: WindowSize = useWindowSize();
  const linksGroup = useAppSelector(sideBarSelector).linksGroup;
  const { enabledYieldPrediction } = useFeatureFlags();
  const { enableImportPage } = useFeatureFlags();

  const handleSetLinksGroup = ({ name, input }: { name: string; input: boolean }) => {
    dispatch(setLinksGroup({ [name]: !input }));
  };

  const handleLinkClick = () => {
    if (windowSize.width <= BREAKPOINT_LAPTOP) {
      dispatch(toggle());
    }
  };

  const yieldPredictionText = translate('Yield-Prediction-Page-Title', selectedLanguage);

  return (
    <>
      <li className={`menu-item`}>
        <div className={`accordion accordion-links`} open={linksGroup.track}>
          <input type='checkbox' name='accordion-checkbox' hidden />
          <label
            className='accordion-header c-hand'
            onClick={() => {
              handleSetLinksGroup({ name: 'track', input: linksGroup.track });
            }}
          >
            <i className='icon icon-arrow-down rotate-180' />
            {translate('Main menu Track button', selectedLanguage)}
          </label>
          <div className='accordion-body'>
            <NavLink to='dashboard' className={getNavLinkClassName} onClick={handleLinkClick}>
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <Grid className='icon-fill' />
                </div>
                <span>{translate('Main menu - Dashboard', selectedLanguage)}</span>
              </div>
            </NavLink>
            <NavLink to='climate' className={getNavLinkClassName} onClick={handleLinkClick}>
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <Climate className='icon-fill' />
                </div>
                <span>{translate('Menu - Climate', selectedLanguage)}</span>
              </div>
            </NavLink>
            <NavLink
              to='water-management'
              className={getNavLinkClassName}
              onClick={handleLinkClick}
            >
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <WaterManagement className='icon-fill' />
                </div>
                <span>{translate('Menu - Water management', selectedLanguage)}</span>
              </div>
            </NavLink>
            <NavLink
              to='fresh-weight-gain'
              className={getNavLinkClassName}
              onClick={handleLinkClick}
            >
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <Weight className='icon-fill' />
                </div>
                <span>{translate('Menu - Fresh weight gain', selectedLanguage)}</span>
              </div>
            </NavLink>
            <NavLink
              to='water-efficiency'
              className={getNavLinkClassName}
              onClick={handleLinkClick}
            >
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <WaterEfficiency className='icon-fill' />
                </div>
                <span>{translate('Menu - Water efficiency', selectedLanguage)}</span>
              </div>
            </NavLink>
            <NavLink to='crop-status' className={getNavLinkClassName} onClick={handleLinkClick}>
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <CropStatus className='icon-fill' />
                </div>
                <span>{translate('Menu - Crop status', selectedLanguage)}</span>
              </div>
            </NavLink>
            <NavLink to='production' className={getNavLinkClassName} onClick={handleLinkClick}>
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <Production className='icon-stroke' />
                </div>
                <span>{translate('Menu - Production', selectedLanguage)}</span>
              </div>
            </NavLink>
          </div>
        </div>
      </li>
      <li className='menu-item'>
        <div className='accordion accordion-links' open={linksGroup.plan}>
          <input type='checkbox' name='accordion-checkbox ' hidden />
          <label
            className='accordion-header c-hand'
            onClick={() => handleSetLinksGroup({ name: 'plan', input: linksGroup.plan })}
          >
            <i className='icon icon-arrow-down rotate-180' />
            {translate('Menu - Plan group', selectedLanguage)}
          </label>
          <div className='accordion-body'>
            <NavLink
              to='plan-settings'
              onClick={handleLinkClick}
              className={({ isActive }: { isActive: boolean }) => {
                return `${getNavLinkClassName({ isActive })} ${checkPathForPlanSettings()}`;
              }}
            >
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <Settings className='icon-fill' />
                </div>
                <span>
                  {translate('Create Plan - left main menu - Create Plan', selectedLanguage)}
                </span>
              </div>
            </NavLink>
            <NavLink to='my-plans' className={getNavLinkClassName} onClick={handleLinkClick}>
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <Calendar className='icon-fill' />
                </div>
                <span>
                  {translate('Create Plan - left main menu - My Plans', selectedLanguage)}
                </span>
              </div>
            </NavLink>
          </div>
        </div>
      </li>
      <li className='menu-item'>
        <div className='accordion accordion-links' open={linksGroup.analyze}>
          <input type='checkbox' name='accordion-checkbox ' hidden />
          <label
            className='accordion-header c-hand'
            onClick={() => handleSetLinksGroup({ name: 'analyze', input: linksGroup.analyze })}
          >
            <i className='icon icon-arrow-down rotate-180' />
            {translate('Menu - Analyze', selectedLanguage)}
          </label>
          <div className='accordion-body'>
            <NavLink to='analyze' className={getNavLinkClassName} onClick={handleLinkClick}>
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <Trending className='icon-fill' />
                </div>
                <span>{translate('Menu - Analyze', selectedLanguage)}</span>
              </div>
            </NavLink>
          </div>
        </div>
      </li>

      <li className='menu-item'>
        <div className='accordion accordion-links' open={linksGroup.notifications}>
          <input type='checkbox' name='accordion-checkbox ' hidden />
          <label
            className='accordion-header c-hand'
            onClick={() =>
              handleSetLinksGroup({ name: 'notifications', input: linksGroup.notifications })
            }
          >
            <i className='icon icon-arrow-down rotate-180' />
            {translate('Notify', selectedLanguage)}
          </label>
          <div className='accordion-body'>
            <NavLink to='notification' className={getNavLinkClassName} onClick={handleLinkClick}>
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <Bell className='icon-fill' />
                </div>
                <span>{translate('Notifications', selectedLanguage)}</span>
              </div>
            </NavLink>
            <NavLink
              to='notification-settings'
              className={getNavLinkClassName}
              onClick={handleLinkClick}
            >
              <div className='accordion-item'>
                <div className='accordion-item-icon'>
                  <NotificationSettings className='icon-fill' />
                </div>
                <span>{translate('Menu - Notification Settings', selectedLanguage)}</span>
              </div>
            </NavLink>
          </div>
        </div>
      </li>
      {enabledYieldPrediction && (
        <li className='menu-item'>
          <div className='accordion accordion-links' open={linksGroup.yieldPrediction}>
            <input type='checkbox' name='accordion-checkbox ' hidden />
            <label
              className='accordion-header c-hand'
              onClick={() =>
                handleSetLinksGroup({ name: 'yieldPrediction', input: linksGroup.yieldPrediction })
              }
            >
              <i className='icon icon-arrow-down rotate-180' />
              {yieldPredictionText}
            </label>
            <div className='accordion-body'>
              <NavLink
                to='yield-prediction'
                className={getNavLinkClassName}
                onClick={handleLinkClick}
              >
                <div className='accordion-item'>
                  <div className='accordion-item-icon'></div>
                  <span>{yieldPredictionText}</span>
                </div>
              </NavLink>
            </div>
          </div>
        </li>
      )}

      {enableImportPage && (
        <li className='menu-item'>
          <div className='accordion accordion-links' open={linksGroup.importData}>
            <input type='checkbox' name='accordion-checkbox ' hidden />
            <label
              className='accordion-header c-hand'
              onClick={() =>
                handleSetLinksGroup({ name: 'importData', input: linksGroup.importData })
              }
            >
              <i className='icon icon-arrow-down rotate-180' />
              {translate('Main menu - Import Data', selectedLanguage)}
            </label>
            <div className='accordion-body'>
              <NavLink to='import-data' className={getNavLinkClassName} onClick={handleLinkClick}>
                <div className='accordion-item'>
                  <div className='accordion-item-icon'></div>
                  <span>{translate('Menu - Upload', selectedLanguage)}</span>
                </div>
              </NavLink>
            </div>
          </div>
        </li>
      )}
    </>
  );
};

const renderProfileComponent: any = (width: number, userDetails: any, setShowAccountIds: any) => {
  return (
    width <= BREAKPOINT_LAPTOP && <Profile show={setShowAccountIds} userDetails={userDetails} />
  );
};

const Links: React.FC<LinksProps> = ({ children }) => {
  const windowSize: WindowSize = useWindowSize();
  const isOpen = useAppSelector(sideBarSelector).isOpen;
  const dispatch = useAppDispatch();
  const userDetails = AuthService.getUserDetails();
  const [showAccountIds, setShowAccountIds] = useState(false);
  const { selectedLanguage } = useAppSelector(languageSelector);
  const { pathname } = useLocation();

  function getMenuClass() {
    if (!isOpen && windowSize.width > BREAKPOINT_LAPTOP) {
      return 'inactive';
    }
  }

  function renderSidebar() {
    if (isOpen) {
      return <ExpandedSidebar selectedLanguage={selectedLanguage} path={pathname} />;
    }
  }
  return (
    <div className={`menu-links-container ${getMenuClass()} `}>
      {!showAccountIds ? (
        <>
          {children}
          <ul className={`menu menu-links p-relative`}>
            {windowSize.width <= BREAKPOINT_LAPTOP && (
              <div className='close-button c-hand' onClick={() => dispatch(toggle())}>
                <Close className='icon-close' />
              </div>
            )}
            {renderProfileComponent(windowSize.width, userDetails, setShowAccountIds)}
            {renderSidebar()}
          </ul>
        </>
      ) : (
        <div className={`menu menu-links p-relative`}>
          <LinkedAccounts show={setShowAccountIds} />
        </div>
      )}
    </div>
  );
};
export default Links;
