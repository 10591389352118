import axios from 'axios';
import AuthService from 'services/auth.service';

const _axios = axios.create();

const configure = (): void => {
    _axios.interceptors.request.use((configuration): any => {
        if (AuthService.isLoggedIn()) {
            const callback = () => {
                configuration.headers!.Authorization = `Bearer ${AuthService.getToken()}`;
                return Promise.resolve(configuration);
            };
            return AuthService.updateToken(callback);
        }
    });
};

const get = async (url: string, params = {}) => {
    let configuration = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/${url}`,
        params: params,
    };
    return await _axios(configuration);
};

const post = async (url: string, data: any) => {
    let configuration = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/${url}`,
        data: data,
    };
    return await _axios(configuration);
};

const put = async (url: string, data: any) => {
    let configuration = {
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/${url}`,
        data: data,
    };
    return await _axios(configuration);
};

const remove = async (url: string, id: string | number) => {
    let configuration = {
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/${url}/${id}`,
    };
    return await _axios(configuration);
};

const getAxiosClient = () => _axios;

const postKeycloak = async (data: any) => {
    let configuration = {
        method: 'post',
        url: `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/token`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data,
    };

    return await _axios(configuration);
};

const HttpService = {
    configure,
    get,
    post,
    put,
    remove,
    getAxiosClient,
    postKeycloak,
};

export default HttpService;
