import './CropSeasonPreview.scss';

import React, { ReactNode } from 'react';
import { translate } from 'utils/translations.util';

import LinePreview from './LinePreview';
import { CropSeason } from 'interfaces/crop-season';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';

interface CropSeasonPreviewProps {
  cropSeasons: CropSeason[];
}

const CropSeasonPreview: React.FC<CropSeasonPreviewProps> = ({ cropSeasons }) => {
  let content: ReactNode = '';
  const { selectedLanguage } = useAppSelector(languageSelector);

  if (cropSeasons.length === 0) {
    content = translate('Analyze: header message', selectedLanguage);
  } else {
    content = cropSeasons.map(({ name, id }, index) => (
      <div key={id} className='crop-season'>
        <LinePreview index={index}></LinePreview>
        <span className='name'>{name}</span>
      </div>
    ));
  }

  return <div className={`crop-season-preview-container`}>{content}</div>;
};
export default CropSeasonPreview;
