import { CropseasonNotification } from 'interfaces/cropseason-notification';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { translate } from 'utils/translations.util';
import dayjs from 'dayjs';
import { createIntervalFromResolution } from 'utils/time.util';
import { useGetFilterOptionsMutation } from 'services/analyze.service';
import { RESOLUTION } from 'config/constants';
import { filterUniqueArrayElements } from 'utils/filterUniqueArrayElements';
import { AnalyzeFilter } from 'interfaces/analyze';
import { FilterChip } from 'interfaces/filter-chip';
import { getAnalyzeColor } from 'utils/colors.util';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { FilterVariable } from 'interfaces/filter-variable';
import { Range } from 'interfaces/range';
import { analyzeActions } from 'redux/slices/analyze.slice';
import { VARIABLES_ICONS } from 'config/variable-icons';
import useUrlState from 'hooks/useUrlState';
import NOTIFICATION_INFO from 'config/notifications/notifications.json';

interface UseGetNotificationDetails {
  chips: FilterChip[];
  graphVariables: AnalyzeFilter[];
  relatedChips: FilterChip[];
  graphRelatedVariables: AnalyzeFilter[];
  from: number;
  to: number;
  roundedTimeRange: Range;
  selectedVariables: string[];
  getAnalyzeUrl: (isMobile: boolean, showRelatedvariables: boolean) => string;
}

export default function UseGetNotificationItemDetails(
  notification: CropseasonNotification,
  accountId: number,
  relatedVariables: string[],
  selectedVariables: string[]
): UseGetNotificationDetails {
  const [getFilterOptions] = useGetFilterOptionsMutation();
  let [allVariables, setAllVariables] = useState<FilterVariable[]>([]);
  const [chips, setChips] = useState<FilterChip[]>([]);
  const [relatedChips, setRelatedChips] = useState<FilterChip[]>([]);
  const { selectedLanguage } = useAppSelector(languageSelector);
  const [graphVariables, setGraphVariables] = useState<AnalyzeFilter[]>([]);
  const [graphRelatedVariables, setGraphRelatedVariables] = useState<AnalyzeFilter[]>([]);
  const dispatch = useAppDispatch();

  const [urlState, setUrlState] = useUrlState({});

  const resolution = notification.values.graph.resolution;

  const timeRange = createIntervalFromResolution(
    dayjs(notification?.local_datetime, resolution).unix(),
    resolution,
    2
  );

  const from = dayjs.unix(timeRange.min).startOf('day').unix();
  const to = dayjs.unix(timeRange.max).endOf('day').unix();

  const roundedTimeRange = {
    min: from,
    max: to,
  };

  const getAnalyzeUrl = (isMobile: boolean, showRelatedVariables: boolean) => {
    dispatch(analyzeActions.removeAllVariables());
    let urlVariables = selectedVariables.join('&var=');
    if (showRelatedVariables) {
      urlVariables = urlVariables + '&var=' + relatedVariables.join('&var=');
    }
    setUrlState({
      ...urlState,
      var: relatedVariables.map((variable) => variable).concat(selectedVariables),
    });
    const resolution = notification?.values?.graph?.resolution || RESOLUTION._1_day;
    let analyzeURL = `/analyze?from=${from}&to=${to}&accountId=${accountId}&var=${urlVariables}&resolution=${resolution}&selectedCropseasons=${
      notification.cropseason.id
    }&sideBarOpen=${!isMobile}`;
    return analyzeURL;
  };

  //TODO - this call for ALl Variables should not be needed if the API would return the variables with all the data (scaling_group,  etc.)
  //TODO - ask for API update and refactor this
  const getAllVariables = useCallback(
    async (list: any[]) => {
      if (accountId) {
        return Promise.all(
          list.map(async (item) => {
            return await getFilterOptions({
              cropSeasonId: item?.id,
              dateRange: { from, to },
              resolution,
              accountId: accountId,
            }).unwrap();
          })
        );
      } else {
        return [];
      }
    },
    [accountId, from, getFilterOptions, resolution, to]
  );
  //TODO - remove variable.name after API responds with Id's everywhere
  useEffect(() => {
    if (notification.cropseason.id) {
      getAllVariables([notification.cropseason]).then((variables) => {
        return setAllVariables(filterUniqueArrayElements(variables.flat(), 'id', 'name'));
      });
    } else {
      setAllVariables([]);
    }
  }, [notification.cropseason, resolution, accountId, getAllVariables]);

  const getDetailedVariablesAndChips = useCallback(
    (variables: string[], shiftColorIndex?: number | undefined) => {
      let analyzeVariables = [] as AnalyzeFilter[];
      let chips = [] as FilterChip[];

      //TODO - remove variable.name after API responds with Id's everywhere

      if (allVariables.length > 0) {
        let graphVariablesName = allVariables.filter((variable) =>
          variables.includes(variable.name)
        );
        let graphVariablesId = allVariables.filter((variable) => {
          if (variable.id) return variables.includes(variable.id);
        });

        let graphVariables = graphVariablesName.concat(graphVariablesId);

        const currentVariableColors = ['#FFFFFF'] as string[];

        let actualColors = graphVariables.length === 1;
        graphVariables.forEach((variableData, index) => {
          analyzeVariables.push({
            color: actualColors ? '#FFFFFF' : getAnalyzeColor(currentVariableColors),
            label: translate(variableData.id || variableData.name, selectedLanguage),
            value: variableData.name,
            unit: translate(variableData?.unit, selectedLanguage),
            icon: VARIABLES_ICONS[variableData?.source],
            source: variableData?.source,
            scalingGroup: variableData.scaling_group,
          });
          chips.push({
            label:
              translate(variableData.id || variableData.name, selectedLanguage) +
              ' - ' +
              variableData?.unit,
            color: actualColors ? '#FFFFFF' : getAnalyzeColor(currentVariableColors),
            value: variableData.id || variableData.name,
            unit: translate(variableData?.unit, selectedLanguage),
            icon: VARIABLES_ICONS[variableData?.source],
          });
          currentVariableColors.push(getAnalyzeColor(currentVariableColors));
        });
      }
      return { analyzeVariables, chips };
    },
    [allVariables, selectedLanguage]
  );

  useEffect(() => {
    const { analyzeVariables, chips } = getDetailedVariablesAndChips(selectedVariables);
    setChips(chips);
    setGraphVariables(analyzeVariables);
    let relatedVariablesDetailed = getDetailedVariablesAndChips(
      relatedVariables,
      1
    ).analyzeVariables;
    let relatedChipsDetailed = getDetailedVariablesAndChips(relatedVariables, 1).chips;
    setRelatedChips(relatedChipsDetailed);
    setGraphRelatedVariables(relatedVariablesDetailed);
  }, [selectedVariables, relatedVariables, getDetailedVariablesAndChips]);

  return {
    chips,
    graphVariables,
    relatedChips,
    graphRelatedVariables,
    from,
    to,
    roundedTimeRange,
    selectedVariables,
    getAnalyzeUrl,
  };
}
