import './PlanSettings.scss';

import Stepper from 'components/Stepper/Stepper';
import { PLAN_CREATION_STEPS } from 'config/constants';
import useUrlState from 'hooks/useUrlState';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { customPlanSelector, resetCustomPlan, setCustomPlan } from 'redux/slices/customPlan.slice';
import { languageSelector } from 'redux/slices/language.slice';
import { planCreationSelector, setOption } from 'redux/slices/planCreationSlice';
import { stepperSelector } from 'redux/slices/stepperSlice';
import {
  useGetLocationsQuery,
  useGetRootStocksQuery,
  useGetVarietiesQuery,
} from 'services/metaData.service';
import { ReactComponent as Info } from 'styles/icons/info.svg';
import {
  firstSettingDateValidation,
  harvestValidation,
  headRemovalValidation,
  sowingValidation,
} from 'utils/cropPlanDatesValidation.util';
import { translate } from 'utils/translations.util';
import DatesStep from 'views/PlanSettings/PlanCreation/DatesStep/DatesStep';
import LocationStep from 'views/PlanSettings/PlanCreation/LocationStep/LocationStep';
import SaveStep from 'views/PlanSettings/PlanCreation/SaveStep/SaveStep';
import VarietyStep from 'views/PlanSettings/PlanCreation/VarietyStep/VarietyStep';

import CustomSettingsPlanStep from './PlanCreation/CustomSettingsPlanStep/CustomSettingsPlanStep';
import LinkingStep from './PlanCreation/LinkingStep/LinkingStep';
import PlanPreviewStep from './PlanCreation/PlanPreviewStep/PlanPreviewStep';
import {
  ECValidation,
  ripeningDateValidation,
  temperatureStrategyValidation,
} from 'utils/customSettngsValidation';

interface PlanSettingsProps {}

const PlanSettings: React.FC<PlanSettingsProps> = () => {
  const [lastValidStep, setLastValidStep] = useState(-1);
  const dispatch = useAppDispatch();

  const variety = useAppSelector(planCreationSelector).variety;
  const location = useAppSelector(planCreationSelector).location;
  const activeStep = useAppSelector(stepperSelector('planCreationStep'));
  const plantingDate = useAppSelector(planCreationSelector).plantingDate;
  const headRemovalDate = useAppSelector(planCreationSelector).headRemovalDate;
  const harvestDate = useAppSelector(planCreationSelector).harvestDate;
  const sowingDate = useAppSelector(planCreationSelector).sowingDate;
  const firstSettingDate = useAppSelector(planCreationSelector).firstSettingDate;
  const { data: varieties } = useGetVarietiesQuery({ limit: 1000, segment: '' });
  const { data: rootstocks } = useGetRootStocksQuery();
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { data: locations } = useGetLocationsQuery(accountId, { skip: !accountId });
  const { selectedLanguage } = useAppSelector(languageSelector);
  const { planCreationStarted } = useAppSelector(planCreationSelector);
  const [, setUrlState] = useUrlState({
    activePlanCreationStep: '',
  });

  const customSettings = useAppSelector(customPlanSelector).customPlanState;

  const containerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const windowSize: WindowSize = useWindowSize();

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === containerRef.current) {
          const newWidth = (entry.target as HTMLElement).offsetWidth || 0;
          if (footerRef.current) {
            footerRef.current.style.width = `${newWidth}px`;
          }
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [containerRef]);

  useEffect(() => {
    if (!activeStep) {
      setLastValidStep(-1);
      dispatch(setOption({ planCreationStarted: true }));
      dispatch(resetCustomPlan({}));
    }
    if (activeStep !== 4) {
      dispatch(
        setCustomPlan({
          lightsTouched: false,
        })
      );
    }
    setUrlState({ activePlanCreationStep: String(activeStep) });
  }, [activeStep]);

  // When leaving the page while on custom settings step, do not show the reset temperature modal

  useEffect(() => {
    return () => {
      dispatch(
        setCustomPlan({
          lightsTouched: false,
        })
      );
    };
  }, []);

  const lastValidStepUpdate = (): number => {
    if (
      plantingDate &&
      !harvestValidation(harvestDate, plantingDate, headRemovalDate) &&
      !headRemovalValidation(harvestDate, plantingDate, headRemovalDate) &&
      !sowingValidation(plantingDate, sowingDate) &&
      !firstSettingDateValidation(headRemovalDate, plantingDate, firstSettingDate)
    ) {
      setLastValidStep(4);
      return 4;
    } else if (location) {
      setLastValidStep(3);
      return 3;
    } else if (variety) {
      setLastValidStep(2);
      return 2;
    } else {
      setLastValidStep(1);
      return 1;
    }
  };

  const isValidStepToNavigate = (step: number): boolean => {
    setLastValidStep(activeStep);
    if (step === 0 || step === 1) return true;
    else if (step === 2 && variety) return true;
    else if (step === 3 && variety && location) return true;
    else if (step === 4 || step === 5) {
      if (
        variety &&
        location &&
        plantingDate &&
        !harvestValidation(harvestDate, plantingDate, headRemovalDate) &&
        !headRemovalValidation(harvestDate, plantingDate, headRemovalDate) &&
        !sowingValidation(plantingDate, sowingDate) &&
        !firstSettingDateValidation(headRemovalDate, plantingDate, firstSettingDate) &&
        !ECValidation(customSettings.EC?.min, customSettings.EC?.max, selectedLanguage) &&
        !ripeningDateValidation(
          headRemovalDate,
          customSettings.accelerated_ripening?.starting_from,
          harvestDate,
          selectedLanguage
        ) &&
        !temperatureStrategyValidation(customSettings.phase_temp_strategies || [])
      ) {
        return true;
      }
    }
    return false;
  };

  const renderSelectedStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <LinkingStep
            varieties={varieties || []}
            rootstocks={rootstocks || []}
            locations={locations || []}
          />
        );
      case 1:
        return (
          <VarietyStep
            varieties={varieties || []}
            rootstocks={rootstocks || []}
            lastValidStep={lastValidStep === 1}
          />
        );
      case 2:
        return <LocationStep locations={locations || []} lastValidStep={lastValidStep === 2} />;
      case 3:
        return (
          <DatesStep checkIsValidStep={isValidStepToNavigate} lastValidStep={lastValidStep === 3} />
        );
      case 4:
        return <CustomSettingsPlanStep />;
      case 5:
        return <PlanPreviewStep lastValidStep={lastValidStep === 4} />;
      case 6:
        return <SaveStep lastValidStep={lastValidStep === 4} />;
      default:
        return <div>Step not found</div>;
    }
  };

  const renderStepper = (windowWidth: number) => {
    if (windowSize.width <= windowWidth) {
      return (
        <Stepper
          isValidStepToNavigate={isValidStepToNavigate}
          lastValidStepUpdate={lastValidStepUpdate}
          stepsList={PLAN_CREATION_STEPS}
          finalStepButtonText={'Save Plan'}
          stepperName={'planCreationStep'}
          numberOfStepsState={'numberOfStepsPlanCreation'}
        />
      );
    }
  };

  const showPlanCreationInfo = () => {
    return activeStep === 0;
  };

  return (
    <div className='plan-settings-view-container' ref={containerRef}>
      <div className='plan-settings-view'>
        <div className='plan-settings-title'>
          <div className='plan-settings-title-content'>
            {translate('Create Plan - left main menu - Create Plan', selectedLanguage)}
            {showPlanCreationInfo() && (
              <div>
                <Info
                  width={20}
                  height={20}
                  className='icon-light c-hand'
                  id={`tooltip-anchor-create-plan`}
                  data-tooltip-variant='light'
                />

                <Tooltip
                  anchorSelect={`#tooltip-anchor-create-plan`}
                  place='right'
                  style={{ zIndex: 10, width: '295px', display: 'flex', height: 'auto' }}
                  opacity={1}
                >
                  <div className='plan-settings-details'>
                    {translate('Create Plan - Create Plan explanation', selectedLanguage)}
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>

        <div className={'plan-settings-content footer-fixed'}>
          {planCreationStarted && (
            <div className='step-content'>{renderSelectedStep(activeStep)}</div>
          )}
        </div>
      </div>
      {planCreationStarted && (
        <div className={'plan-settings-footer footer-fixed'} ref={footerRef}>
          <div className='stepper-container'>{renderStepper(Infinity)}</div>
        </div>
      )}
    </div>
  );
};

export default PlanSettings;
