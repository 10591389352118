import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setPlansRenameId } from 'redux/slices/plansSlice';
import {
  useDeletePlanMutation,
  useGetPlanByCropSeasonIdMutation,
  useUpdatePlanMutation,
} from 'services/plans.service';
import styles from './PlansActions.module.scss';
import { TOAST_PROMISE } from 'config/constants';
import { dispatchPromiseToast } from 'utils/toast.util';
import { languageSelector } from 'redux/slices/language.slice';
import { translate } from 'utils/translations.util';
import { ModalService } from 'services/modal.service';
import CropSeasonSelector from 'components/Filters/CropSeason/CropSeasonSelector';
import { CropSeason, CropSeasonShort } from 'interfaces/crop-season';
import UnLinkPlanToCropSeasonMessage from 'components/Dialogs/UnlinkPlan';
import { getActiveCropseasonIntitialValue } from 'utils/getInitialValues';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { track } from 'utils/analtycs';

interface PlansActionsProps {
  id: string;
  handleDuplicate: () => void;
  hasLinkdedCropSeason: boolean;
  planName: string;
  planCropseason: CropSeasonShort | null;
}

const PlansActions: React.FC<PlansActionsProps> = ({
  id,
  handleDuplicate,
  hasLinkdedCropSeason,
  planName,
  planCropseason,
}) => {
  const dispatch = useAppDispatch();
  const [deletePlan] = useDeletePlanMutation();
  const [updatePlan] = useUpdatePlanMutation();
  const { selectedLanguage } = useAppSelector(languageSelector);
  const [confirmLinkPlan, setConfirmLinkPlan] = React.useState(false);
  const [confirmDeletePlan, setConfirmDeletePlan] = React.useState(false);
  const accountId = useAppSelector(accountIdSelector).accountId;

  const [linkedPlan, setLinkedPlan] = React.useState<{
    planId: string;
    linked: CropSeason | null;
  } | null>(null);

  const [getPlanByCropseason] = useGetPlanByCropSeasonIdMutation();

  const handleUnlinkPlan = () => {
    ModalService.open({
      title: translate('planTable- unlink - option', selectedLanguage),
      saveText: translate('planTable- unlink - option - modal - save', selectedLanguage),
      cancelText: translate('Cancel generic button', selectedLanguage),
      width: '380px',
      content: <UnLinkPlanToCropSeasonMessage name={planName} />,

      closeAction: (isConfirmed) => {
        if (isConfirmed) {
          setLinkedPlan({
            planId: id,
            linked: null,
          });
          setConfirmDeletePlan(true);
        }
      },
    });
  };

  const handleSetLinkedCropSeason = (value: CropSeason) => {
    getPlanByCropseason({ accountId: accountId, cropSeasonId: value.id })
      .unwrap()
      .then((response) => {
        if (response) {
          ModalService.open({
            title: 'Change linked Crop Plan',
            saveText: 'Change',
            cancelText: 'Cancel',
            width: '495px',
            content: <UnLinkPlanToCropSeasonMessage name={value.name} />,
            confirmActionTitle: translate(
              'Plan edit - link plan to cropseason warning header',
              selectedLanguage
            ),
            closeAction: (isConfirmed) => {
              if (!isConfirmed) {
              } else {
                setLinkedPlan({
                  planId: id,
                  linked: value,
                });
                setConfirmLinkPlan(true);
              }
            },
          });
        } else {
          setLinkedPlan({
            planId: id,
            linked: value,
          });
          setConfirmLinkPlan(false);
        }
      });
  };

  useEffect(() => {
    if (confirmLinkPlan && linkedPlan) {
      ModalService.close();
    }
  }, [confirmLinkPlan, linkedPlan]);

  useEffect(() => {
    if (linkedPlan && confirmLinkPlan) {
      const planUpdatePromise = updatePlan({
        id: Number(linkedPlan?.planId),
        data: {
          cropseason_id: linkedPlan?.linked ? linkedPlan.linked?.id : null,
        },
      });
      dispatchPromiseToast({
        type: TOAST_PROMISE,
        promise: planUpdatePromise,
        messageLoading: translate('Toast - PlanLink - loading', selectedLanguage),
        messageError: translate('Toast - PlanLink - error', selectedLanguage),
        messageSuccess: translate('Toast - PlanLink - success', selectedLanguage),
      });
    } else if (linkedPlan && confirmDeletePlan) {
      const planUpdatePromise = updatePlan({
        id: Number(linkedPlan.planId),
        data: {
          cropseason_id: null,
        },
      });
      dispatchPromiseToast({
        type: TOAST_PROMISE,
        promise: planUpdatePromise,
        messageLoading: translate('Toast - PlanUnlink - loading', selectedLanguage),
        messageError: translate('Toast - PlanUnlink - error', selectedLanguage),
        messageSuccess: translate('Toast - PlanUnlink - success', selectedLanguage),
      });
    }
    setConfirmLinkPlan(false);
    setConfirmDeletePlan(false);
  }, [linkedPlan, confirmLinkPlan]);

  const handleLinkPlan = () => {
    ModalService.open({
      title: translate('planTable - linkPlan -modal - title', selectedLanguage),
      saveText: translate('Save generic button', selectedLanguage),
      cancelText: translate('Plan edit - cancel', selectedLanguage),
      width: '495px',
      content: (
        <CropSeasonSelector
          hasDefaultCapability={false}
          localClassName={'plan-folder-link-cropseason'}
          setOnChange={handleSetLinkedCropSeason}
          initialCropSeason={getActiveCropseasonIntitialValue()}
        />
      ),

      closeAction: (isConfirmed) => {
        if (!isConfirmed) {
          setLinkedPlan({
            planId: id,
            linked: null,
          });
        } else {
          track('plan link', { planId: id });
          setConfirmLinkPlan(true);
        }
      },
    });
  };

  const handleChangeLink = () => {
    ModalService.open({
      title: translate('planTable - change - linkPlan -modal - title', selectedLanguage),
      saveText: translate('Save generic button', selectedLanguage),
      cancelText: translate('Plan edit - cancel', selectedLanguage),
      width: '495px',
      content: (
        <CropSeasonSelector
          hasDefaultCapability={false}
          localClassName={'plan-folder-link-cropseason'}
          setOnChange={handleSetLinkedCropSeason}
          initialCropSeason={planCropseason}
        />
      ),

      closeAction: (isConfirmed) => {
        if (!isConfirmed) {
          setLinkedPlan({
            planId: id,
            linked: null,
          });
        } else {
          setConfirmLinkPlan(true);
        }
      },
    });
  };

  return (
    <>
      <div
        className={`${styles['generic-item']} c-hand`}
        onClick={() => {
          track('plan duplicate', { planId: id });
          handleDuplicate();
        }}
      >
        <span>{translate('My plans - duplicate a specific plan', selectedLanguage)}</span>
      </div>
      <div
        className={`${styles['generic-item']} c-hand`}
        onClick={() => {
          track('plan rename', { planId: id });
          dispatch(setPlansRenameId(id));
        }}
      >
        <span>{translate('My plans - rename a specific plan')}</span>
      </div>
      {hasLinkdedCropSeason && (
        <>
          <div className={`${styles['generic-item']} c-hand`} onClick={handleUnlinkPlan}>
            <span>{translate('My plans - unlink a specific plan', selectedLanguage)}</span>
          </div>
          <div className={`${styles['generic-item']} c-hand`} onClick={handleChangeLink}>
            <span>{translate('plantable - changeLink  - option', selectedLanguage)}</span>
          </div>
        </>
      )}
      {!hasLinkdedCropSeason && (
        <div className={`${styles['generic-item']} c-hand`} onClick={handleLinkPlan}>
          <span>{translate('Link your plan to CropSeason', selectedLanguage)}</span>
        </div>
      )}
      <div
        className={`${styles['generic-item']} c-hand`}
        onClick={() => {
          track('plan delete', { planId: id });
          const deletePlanPromise = deletePlan(Number(id));
          dispatchPromiseToast({
            type: TOAST_PROMISE,
            promise: deletePlanPromise,
            messageLoading: translate('Toast - Plan delete - loading', selectedLanguage),
            messageError: translate('Toast - Plan delete - error', selectedLanguage),
            messageSuccess: translate('Toast - Plan delete - success', selectedLanguage),
          });
        }}
      >
        <span>{translate('My plans - delete a specific plan')}</span>
      </div>
    </>
  );
};
export default PlansActions;
