
export type ToastType = "TOAST_SUCCESS" | "TOAST_ERROR" | "TOAST_PROMISE";

export enum ToastTypes {
    success = "TOAST_SUCCESS",
    error = "TOAST_ERROR"
}
export interface Toast {
    type: ToastType;
    message: string;
}
export interface ToastPromise<T> {
    type: ToastType;
    promise?: Promise<T>;
    messageLoading?: string;
    messageSuccess?: string;
    messageError?: string
}


