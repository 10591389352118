import de from 'config/locales/de_DE.json';
import es from 'config/locales/es_ES.json';
import fr from 'config/locales/fr_FR.json';
import nl from 'config/locales/nl_NL.json';
import en from 'config/locales/en_GB.json';
import pl from 'config/locales/pl_PL.json';
import en_us from 'config/locales/en_US.json';
import { KeyValue } from 'interfaces/keyvalue';
const LANGUAGE = 'en';

const translations: KeyValue<KeyValue<string>> = {
  de,
  es,
  fr,
  nl,
  en,
  pl,
  en_us,
};

export default translations as KeyValue<KeyValue<string>>;

export const translate = (value?: string, language?: string): string => {
  if (!value) {
    return '';
  }

  const selectedLanguage = translations[language || LANGUAGE];
  if (!selectedLanguage) {
    return value;
  }

  if (!(value in selectedLanguage)) {
    return value;
  }

  return selectedLanguage[value as keyof typeof language];
};
