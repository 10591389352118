import dayjs from 'dayjs';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { dayJsTranslate } from 'utils/dayjsTranslations';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);

export default function useDayJsTranslated() {
  const { selectedLanguage } = useAppSelector(languageSelector);
  let dayjsLanguage = dayJsTranslate(selectedLanguage);
  dayjs.locale(dayjsLanguage);
  dayjs.updateLocale(selectedLanguage, {
    weekStart: 0,
  });
}
