import React, { useState, useRef, useEffect } from 'react';

import { ReactComponent as CalendarIcon } from 'styles/icons/calendar.svg';
import { DatePicker } from '../DatePicker';

import { ReactComponent as Arrow } from 'styles/icons/arrow-right-more.svg';

import styles from './DatePickerDropdown.module.scss';

import { LEFT, RIGHT, Z_INDEX_CUSTOM_RANGE_TOOLTIP } from 'config/constants';
import dayjs, { Dayjs } from 'dayjs';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { translate } from 'utils/translations.util';
import { getUniqueId } from 'utils/getUniqueId';
import TooltipCustom from 'components/TooltipCustom/TooltipCustom';

export interface DatePickerDropdownValues {
  start?: Dayjs;
  end?: Dayjs;
}
interface DatePickerDropdownProps {
  dropDownTitle?: string;
  name?: string;
  required?: boolean;
  requiredText?: string;
  displayProperty?: string;
  customRangePosition?: string;
  initialValues?: DatePickerDropdownValues;
  cropSeasonDates?: { start: Dayjs; end: Dayjs };
  onChangeDropdown: (values: DatePickerDropdownValues) => void;
  customRangePositionStrategy?: 'fixed' | 'absolute';
}

const getOption24Hours = (selectedLanguage: string) => {
  const DATE_OPTION_24_HOURS = translate(
    'Level 2 - time period selector - 24hrs',
    selectedLanguage
  );
  return DATE_OPTION_24_HOURS;
};

const getOption7Days = (selectedLanguage: string) => {
  const DATE_OPTION_7_DAYS = translate('Level 2 - time period selector - 7 days', selectedLanguage);
  return DATE_OPTION_7_DAYS;
};

const getOption30Days = (selectedLanguage: string) => {
  const DATE_OPTION_30_DAYS = translate(
    'Level 2 - time period selector - 30 days',
    selectedLanguage
  );
  return DATE_OPTION_30_DAYS;
};

const getOptionCustom = (selectedLanguage: string) => {
  const DATE_OPTION_CUSTOM = translate(
    'Level 2 - time period selector - Custom range',
    selectedLanguage
  );
  return DATE_OPTION_CUSTOM;
};

const getEntireCropSeason = (selectedLanguage: string) => {
  const DATE_OPTION_CUSTOM = translate('Period selector: Entire CropSeason', selectedLanguage);
  return DATE_OPTION_CUSTOM;
};

const getOptionList = (selectedLanguage: string) => {
  const optionList: any = [
    { title: getOption24Hours(selectedLanguage) },
    { title: getOption7Days(selectedLanguage) },
    { title: getOption30Days(selectedLanguage) },
    { title: getEntireCropSeason(selectedLanguage) },
    { title: getOptionCustom(selectedLanguage) },
  ];

  return optionList;
};

interface DatePickersState {
  open: string;
  values: {
    left?: Dayjs;
    right?: Dayjs;
  };
}

const DATEPICKERS_INITIAL_DATA = {
  open: LEFT,
  values: { left: undefined, right: undefined },
};

const DatePickerDropdown: React.FC<DatePickerDropdownProps> = ({
  dropDownTitle,
  required = false,
  requiredText,
  customRangePosition = 'custom-range-left',
  initialValues,
  cropSeasonDates,
  onChangeDropdown,
  customRangePositionStrategy,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isCustomRangeOpen, setIsCustomRangeOpen] = useState(false);
  const { selectedLanguage } = useAppSelector(languageSelector);
  const [dropDownValue, setDropDownValue] = useState<{ title: string }>({ title: '' });
  const id = getUniqueId();

  useEffect(() => {
    setDropDownValue({
      title: initialValues
        ? `${initialValues?.start?.format('DD/MM/YY')} - ${initialValues?.end?.format('DD/MM/YY')}`
        : 'N/A',
    });
  }, [initialValues]);

  const optionList = getOptionList(selectedLanguage);

  const datepickerContainerRef = useRef<HTMLDivElement>(null);

  const [openDatepicker, setOpenDatepicker] = useState<DatePickersState>(DATEPICKERS_INITIAL_DATA);

  const getDropdownValues = (option: string): DatePickerDropdownValues => {
    switch (option) {
      case getOption24Hours(selectedLanguage):
        return onChangeDropdown({ start: dayjs().subtract(1, 'day'), end: dayjs() })!;
      case getOption7Days(selectedLanguage):
        return onChangeDropdown({ start: dayjs().subtract(1, 'week'), end: dayjs() })!;
      case getOption30Days(selectedLanguage):
        return onChangeDropdown({ start: dayjs().subtract(1, 'month'), end: dayjs() })!;
      case getEntireCropSeason(selectedLanguage):
        return onChangeDropdown({
          start: cropSeasonDates?.start || dayjs().subtract(1, 'month'),
          end: cropSeasonDates?.end || dayjs(),
        })!;
      default:
        return { start: undefined, end: undefined };
    }
  };

  function handleClick(event: any) {
    if (dropDownRef.current && isDropDownOpen && !dropDownRef.current.contains(event.target)) {
      setIsDropDownOpen(false);
      setIsCustomRangeOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  useEffect(() => {
    if (initialValues?.start && initialValues?.end) {
      setOpenDatepicker((previousData) => ({
        ...previousData,
        values: { left: initialValues.start!, right: initialValues.end! },
      }));
    }
  }, [initialValues?.start, initialValues?.end, setOpenDatepicker]);

  const handleDropDownItemSelect = (option: { title: string }) => {
    setDropDownValue(option);
    setIsDropDownOpen(false);
    setIsCustomRangeOpen(false);
    getDropdownValues(option.title);
  };

  const handleCustomRange = (option: { title: string }) => {
    if (isCustomRangeOpen) {
      setIsCustomRangeOpen(false);
    } else {
      handleClickFromDatepicker();
      setIsCustomRangeOpen(true);
    }
  };

  const renderErrorText = () => {
    if (requiredText) {
      return <div className='required-text'>{requiredText}</div>;
    }
  };

  const handleCancel = () => {
    if (!initialValues) {
      setIsCustomRangeOpen(false);
      return setOpenDatepicker(DATEPICKERS_INITIAL_DATA);
    } else {
      setIsCustomRangeOpen(false);
      return setOpenDatepicker({
        open: LEFT,
        values: {
          left: initialValues.start,
          right: initialValues.end,
        },
      });
    }
  };

  const handleApply = () => {
    onChangeDropdown({
      start: openDatepicker.values.left,
      end: openDatepicker.values.right,
    });
    setIsCustomRangeOpen(false);

    const selectedStartingDSate = openDatepicker.values.left?.format('DD/MM/YY');
    const selectedEndingDSate = openDatepicker.values.right?.format('DD/MM/YY');
    setDropDownValue({ title: `${selectedStartingDSate} - ${selectedEndingDSate}` });
  };

  const handleClickFromDatepicker = () => {
    setOpenDatepicker((previousData) => ({ ...previousData, open: LEFT }));
  };

  const handleClickToDatepicker = () => {
    setOpenDatepicker((previousData) => ({ ...previousData, open: RIGHT }));
  };

  const onChangeLeftDatepicker = (value: any) => {
    setOpenDatepicker((previousData) => ({
      ...previousData,
      values: { ...previousData.values, left: value },
      open: RIGHT,
    }));
  };

  const onChangeRightDatepicker = (value: any) => {
    setOpenDatepicker((previousData) => ({
      ...previousData,
      values: { ...previousData.values, right: value },
    }));
  };
  const renderCustomRangeTooltipContent = () => {
    return (
      <div
        ref={datepickerContainerRef}
        className={`${styles['custom-range-container']} ${styles[customRangePosition]}`}
        id='custom-range'
      >
        <div className={`${styles['datepickers-container']}`}>
          <div className={`${styles['from-date-picker']}`} onClick={handleClickFromDatepicker}>
            <DatePicker
              label={translate('Level 2 - time period selector - From', selectedLanguage)}
              labelPosition='top'
              dropDownPosition='dropdown-datepicker-left custom-range'
              initiallyOpen
              isCustomRange
              onChange={onChangeLeftDatepicker}
              startDate={openDatepicker.values.left}
              open={openDatepicker.open === LEFT}
            />
          </div>
          <div className={`${styles['to-date-picker']}`} onClick={handleClickToDatepicker}>
            <DatePicker
              label={translate('Level 2 - time period selector - To', selectedLanguage)}
              labelPosition='top'
              dropDownPosition='dropdown-datepicker-right custom-range'
              isCustomRange
              startDate={openDatepicker.values.right}
              onChange={onChangeRightDatepicker}
              open={openDatepicker.open === RIGHT}
            />
          </div>
        </div>
        <div className={`${styles['buttons-container']}`}>
          <button className={`btn ${styles['cancel-button']}`} onClick={handleCancel}>
            {translate('Level 2 - time period selector -Cancel', selectedLanguage)}
          </button>
          <button className={`btn ${styles['apply-button']}`} onClick={handleApply}>
            {translate('Level 2 - time period selector -Apply', selectedLanguage)}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={`dropdown-container ${styles.dropdown}`}>
      {dropDownTitle && (
        <div className={`dropdown-title  ${required && 'required'}`}>{dropDownTitle}</div>
      )}
      <div className={`dropdown ${isDropDownOpen ? 'active' : ''} c-hand`} ref={dropDownRef}>
        <div
          className='dropdown-button'
          onClick={() => {
            setIsDropDownOpen(!isDropDownOpen);
            setIsCustomRangeOpen(false);
          }}
        >
          <CalendarIcon className='icon-light calendar-icon' />
          <div className='dropdown-text-container'>{dropDownValue.title}</div>
        </div>
        {isDropDownOpen && (
          <ul className={`menu dropdown-menu ${styles.menu}`}>
            {optionList?.map((option: any, index: number) => {
              if (option.title !== getOptionCustom(selectedLanguage)) {
                return (
                  <div
                    className={`list-item-container ${styles['item-container']}`}
                    key={index}
                    onClick={() => handleDropDownItemSelect(option)}
                  >
                    <li className={`dropdown-item ${styles.item} ${styles['margin-item']}`}>
                      {option.title}
                    </li>
                  </div>
                );
              } else {
                return (
                  <div
                    className={`list-item-container ${styles.custom} ${styles['item-container']} ${
                      isCustomRangeOpen ? styles.hover : ''
                    }`}
                    key={index}
                    onClick={() => handleCustomRange(option)}
                    id={`custom-range-${id}`}
                  >
                    <li className={`dropdown-item ${styles.item} ${styles['margin-item']}`}>
                      {option.title}
                    </li>
                    <Arrow
                      className={`${styles['arrow-icon']} ${
                        isCustomRangeOpen ? 'rotate-360' : 'rotate-180'
                      } ${styles[customRangePosition]}`}
                    />
                  </div>
                );
              }
            })}
          </ul>
        )}
        {isCustomRangeOpen && (
          <TooltipCustom
            anchorSelect={`#custom-range-${id}`}
            dataForId={`#custom-range-${id}`}
            renderContent={renderCustomRangeTooltipContent}
            showTooltip
            backgroundColor='#fff'
            isOpen={isCustomRangeOpen}
            clickable
            style={{ zIndex: Z_INDEX_CUSTOM_RANGE_TOOLTIP, backgroundColor: 'white' }}
            positionStrategy={customRangePositionStrategy}
          />
        )}
      </div>
      <div className={styles['required-text']}>{renderErrorText()}</div>
    </div>
  );
};
export default DatePickerDropdown;
