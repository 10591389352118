import React from 'react';

import { PlacesType, Tooltip } from 'react-tooltip';

interface ReactTooltipProps {
  anchorSelect?: string; // used when anchor has id or class
  dataForId: string; // used when anchor has data-tooltip-id
  showTooltip: boolean;
  backgroundColor?: string;
  padding?: string;
  renderContent: () => JSX.Element;
  isOpen?: boolean;
  clickable?: boolean;
  style?: any;
  positionStrategy?: 'absolute' | 'fixed';
  place?: PlacesType;
  float?: boolean;
}

const TooltipCustom: React.FC<ReactTooltipProps> = ({
  dataForId,
  renderContent,
  showTooltip,
  backgroundColor = '#394958',
  padding = '8px 16px',
  isOpen,
  style = {
    backgroundColor: backgroundColor,
    borderRadius: '5px',
    zIndex: 2,
    padding: padding,
  },
  positionStrategy,
  anchorSelect,
  place,
  float,
}) => {
  const opacityValue = showTooltip ? 1 : 0;
  return (
    <Tooltip
      anchorSelect={anchorSelect}
      id={dataForId}
      place={place}
      float={float}
      close-on-scroll={true}
      data-tooltip-type='light'
      noArrow={true}
      style={{ ...style }}
      opacity={opacityValue}
      isOpen={isOpen}
      clickable
      positionStrategy={positionStrategy}
    >
      {renderContent()}
    </Tooltip>
  );
};
export default TooltipCustom;
