import React from 'react';

const useResize = (ref: any) => {
  const [dimensions, setDimensions] = React.useState<DOMRectReadOnly>();

  React.useEffect(() => {
    const element = ref.current;

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry: ResizeObserverEntry) => {
        setDimensions(entry.contentRect);
      });
    });
    if (element) {
      resizeObserver.observe(element);
    }
    return () => {
      resizeObserver.unobserve(element);
    };
  }, [ref]);
  return (
    dimensions || {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    }
  );
};

export default useResize;
