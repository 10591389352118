import BenchMarkGroupSuggestions from 'components/BenchMarkGroupSuggestions/BenchMarkGroupSuggestions';
import { BenchmarkGraph } from 'components/Track/BenchmarkGraph/BenchmarkGraph';
import LegendGraphDataToggleSwitches from 'components/Track/LegendGraphDataToggleSwitches/LegendGraphDataToggleSwitches';
import { activeCropseasonInitialValue, VARIABLES_WATER_MANAGEMENT } from 'config/constants';
import dayjs from 'dayjs';
import useGetBenchmark from 'hooks/useGetBenchmark';
import { LegendItem } from 'interfaces/legend-item';
import { Resolution } from 'interfaces/resolution';
import React, { useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { cropseasonSelector } from 'redux/slices/cropseason.slice';
import { sideBarSelector } from 'redux/slices/sidebar.slice';
import { trackFilterSelector } from 'redux/slices/trackFilterSlice';
import { useGetActualsQuery } from 'services/actuals.service';
import { useGetUserLinkedAccountsQuery } from 'services/userAccount.service';
import { getFooterSizeClass } from 'utils/general.util';
import { useResizeObserver, getBenchmarkLenged } from 'utils/graphLegendFooter';
import { findAccountName } from 'utils/userInfo';
import { getVariablesBasedOnResolution, hasBenchmarkResolutionError } from 'utils/variables.util';

interface PlanProps {}

const Benchmark: React.FC<PlanProps> = () => {
  const { dates, resolution, groups } = useAppSelector(trackFilterSelector);
  const { id: cropseasonId, name: cropseasonName } = useAppSelector(cropseasonSelector);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const isOpen = useAppSelector(sideBarSelector).isOpen;
  const { containerRef, containerHeight } = useResizeObserver();

  const { data: linkedAccounts } = useGetUserLinkedAccountsQuery();

  const currentAccountName = findAccountName(accountId, linkedAccounts) || '';

  const hasResolutionError =
    hasBenchmarkResolutionError(dates.start, dates.end, resolution.value as Resolution) !== false;

  const { data: actual, isSuccess } = useGetActualsQuery(
    {
      cropSeasonId: cropseasonId,
      startDate: dayjs.unix(dates.start).toISOString(),
      endDate: dayjs.unix(dates.end).toISOString(),
      resolution: resolution.value,
      variables: VARIABLES_WATER_MANAGEMENT,
      accountId: accountId,
    },
    {
      skip: cropseasonId === activeCropseasonInitialValue.id || !accountId || hasResolutionError,
    }
  );
  const { timeseries, legends, ribbons } = useGetBenchmark(
    cropseasonId,
    dates,
    resolution.value,
    VARIABLES_WATER_MANAGEMENT,
    groups
  );

  const variables = getVariablesBasedOnResolution(
    VARIABLES_WATER_MANAGEMENT,
    resolution.value as Resolution
  );

  const legend: LegendItem[] = getBenchmarkLenged(
    currentAccountName,
    cropseasonName,
    legends,
    ribbons,
    variables
  );

  return (
    <div>
      <BenchMarkGroupSuggestions />
      <div className='graph-container' style={{ paddingBottom: containerHeight }}>
        {Object.entries(variables).map(([key, value]) => (
          <div key={key} className='line-graph-container '>
            {!hasResolutionError && (
              <BenchmarkGraph
                actual={isSuccess ? actual : null}
                benchmarks={timeseries ? Object.values(timeseries) : null}
                ribbon={ribbons || null}
                variableKey={key}
                variableValue={value}
                resolution={resolution.value}
                dates={[dates.start, dates.end]}
              />
            )}
          </div>
        ))}
      </div>
      <div
        className={`fixed-footer-legend plan-legend ${getFooterSizeClass(isOpen)}`}
        ref={containerRef}
      >
        {legends && <LegendGraphDataToggleSwitches legendItems={legend} />}
      </div>
    </div>
  );
};

export default Benchmark;
