import React from 'react';
import './Button.scss';
import { ButtonSize, ButtonVariant } from 'interfaces/button';

interface ButtonProps {
  id: string;
  text?: string;
  styleType?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  onHandleClick: (event: any) => void;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  id,
  text,
  styleType = 'primary',
  size = 'medium',
  disabled = false,
  onHandleClick,
  children,
}) => {
  return (
    <button
      id={id}
      onClick={(event) => onHandleClick(event)}
      className={`generic-button-base generic-button-${styleType} generic-button-${size} ${
        disabled ? 'button-disabled' : ''
      }`}
    >
      {text}
      {children}
    </button>
  );
};

export default Button;
