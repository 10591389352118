import de from 'dayjs/locale/de';
import fr from 'dayjs/locale/fr';
import es from 'dayjs/locale/es';
import nl from 'dayjs/locale/nl';
import en from 'dayjs/locale/en';
import pl from 'dayjs/locale/pl';
import { KeyValue } from 'interfaces/keyvalue';
const LANGUAGE = 'en';

const dayjsTranslations: KeyValue<any> = {
    de,
    es,
    fr,
    nl,
    en,
    pl,
};

export default dayjsTranslations as KeyValue<KeyValue<string>>;

export const dayJsTranslate = (language?: string) => {

    const dayjs = dayjsTranslations[language || LANGUAGE];

    return dayjs;
};
