import { analyzeActions } from '../redux/slices/analyze.slice';
import { resetCustomPlan } from 'redux/slices/customPlan.slice';
import { reset } from 'redux/slices/planCreationSlice';
import { setStep } from 'redux/slices/stepperSlice';
import store from 'redux/store';

export default function resetPlanCreationAndAnalyzeState() {
  store.dispatch(reset());
  store.dispatch(setStep({ stateName: 'planCreationStep', stepNumber: 0 }));
  store.dispatch(resetCustomPlan({}));
  store.dispatch(analyzeActions.resetValues());
}
