import CardContainer from 'components/CardContainer/CardContainer';
import FileCard from 'components/FileCard/FileCard';
import { MAX_LENGTH_FOLDER_NAME, MIN_LENGTH_FOLDER_NAME } from 'config/constants';
import useUrlState from 'hooks/useUrlState';
import { FileCardActions, PlansFolders } from 'interfaces/plans';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  plansSelector,
  setPlansRenameId,
  setSelectedPlansFolderName,
} from 'redux/slices/plansSlice';
import { ModalService } from 'services/modal.service';
import { Plan, useDeletePlanMutation, useUpdatePlanMutation } from 'services/plans.service';
import { ReactComponent as Folder } from 'styles/icons/folder.svg';
import { track } from 'utils/analtycs';
import { isBetweenRange } from 'validators/plans.validator';

import FolderActions from '../FolderActions/FolderActions';
import { dispatchToast } from 'utils/toast.util';
import { ToastTypes } from 'interfaces/toast';
import { translate } from 'utils/translations.util';
import { languageSelector } from 'redux/slices/language.slice';

interface PlanFoldersProps {
  filteredPlansFolder: PlansFolders[];
}

const folderActions = (id: string, handleDelete: () => void) => () =>
  <FolderActions id={id} handleDelete={handleDelete} />;

const PlanFolders: React.FC<PlanFoldersProps> = ({ filteredPlansFolder }) => {
  const { renameId } = useAppSelector(plansSelector);
  const [, setUrlState] = useUrlState({
    selectedFolder: '',
  });

  const dispatch = useAppDispatch();
  const [deletePlan] = useDeletePlanMutation();
  const [updatePlan] = useUpdatePlanMutation();
  const { selectedLanguage } = useAppSelector(languageSelector);

  return (
    <>
      {filteredPlansFolder.map((folder: PlansFolders) => {
        const folderId = folder.folderName;

        const handleDelete = () => {
          ModalService.open({
            title: 'Confirm deletion',
            saveText: 'Cancel',
            cancelText: 'Delete',
            width: '362px',
            content: (
              <div className='text' style={{ marginTop: '17px' }}>
                Are you sure you want to delete the folder and all Crop Plans within this folder?
                You can’t undo this action.
              </div>
            ),
            closeAction: async (isCanceled: boolean) => {
              if (isCanceled) {
                return;
              }
              let deletePlanFolderPromises: any[] = [];
              folder?.plans?.forEach((plan: any) => {
                const deletePromise = deletePlan(plan.id);
                deletePlanFolderPromises.push(deletePromise);
              });
              await Promise.all(deletePlanFolderPromises).then((results) => {
                let showError = false;
                results.forEach((result) => {
                  if (result.error) {
                    showError = true;
                  }
                });
                if (showError) {
                  dispatchToast({
                    type: ToastTypes.error,
                    message: translate('Toast - Plan Folder Delete - error', selectedLanguage),
                  });
                } else {
                  dispatchToast({
                    type: ToastTypes.success,
                    message: translate('Toast - Plan Folder Delete - success', selectedLanguage),
                  });
                }
              });
            },
          });
        };

        const folderFileCardActions: FileCardActions = {
          SHOW_ACTIONS: folderActions(folderId, handleDelete),
          HANDLE_CARD_CLICK: () => {
            track(`select plan folder`, { folderId });
            dispatch(setSelectedPlansFolderName(folder.folderName));
            setUrlState({ selectedFolder: folder.folderName });
          },
          HANDLE_RENAME_CONFIRM: async (newName) => {
            let renamePlanFolderPromises: any[] = [];
            folder?.plans?.forEach((plan: Plan) => {
              const toastProsmiseRename = updatePlan({
                id: plan.id,
                data: { path: '/' + newName },
              });
              renamePlanFolderPromises.push(toastProsmiseRename);
            });
            await Promise.all(renamePlanFolderPromises).then((results) => {
              let showError = false;
              results.forEach((result) => {
                if (result.error) {
                  showError = true;
                }
              });
              if (showError) {
                dispatchToast({
                  type: ToastTypes.error,
                  message: translate('Toast - Plan Folder Edit - error', selectedLanguage),
                });
              } else {
                dispatchToast({
                  type: ToastTypes.success,
                  message: translate('Toast - Plan Folder Edit - success', selectedLanguage),
                });
              }
            });
            dispatch(setPlansRenameId(''));
          },
          HANDLE_RENAME_CANCEL: () => {
            dispatch(setPlansRenameId(''));
          },
          HANDLE_VALIDATE: (newName) => {
            return isBetweenRange(newName, MIN_LENGTH_FOLDER_NAME, MAX_LENGTH_FOLDER_NAME);
          },
        };

        return (
          <CardContainer
            key={folder.folderName}
            disabled={!!renameId && folderId !== renameId}
            actions={folderFileCardActions}
            isEditing={folderId === renameId}
          >
            <FileCard
              colorMode='DARK'
              actions={folderFileCardActions}
              title={folder.folderName}
              icon={<Folder className='icon-light' />}
            ></FileCard>
          </CardContainer>
        );
      })}
    </>
  );
};

export default PlanFolders;
