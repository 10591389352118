import { BaseType, select } from 'd3';

export function insertLinebreaks(node: BaseType) {
  const element = select(node);
  const words = element.text().split('\n');

  element.text('');

  for (let i = 0; i < words.length; i++) {
    let tspan = element.append('tspan').text(words[i]);
    if (i > 0) {
      tspan.attr('x', 0).attr('dy', '15');
    }
  }
}
