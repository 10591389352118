import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { useLocation } from 'react-router-dom';

import Profile from 'components/Profile/Profile';
import { ReactComponent as Menu } from 'styles/icons/menu.svg';
import { ReactComponent as Logo } from 'styles/icons/logo.svg';

import './Navbar.scss';

import { toggle } from 'redux/slices/sidebar.slice';
import { mapPageNameFromURL } from 'utils/navigation.util';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import NotificationBell from 'components/NotificationBell/NotificationBell';

interface INavbarProps {}

const Navbar: FC<INavbarProps> = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { selectedLanguage } = useAppSelector(languageSelector);

  const [currentPath, setCurrentPath] = useState<string>('');
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  return (
    <header className='navbar'>
      <section className='navbar-section logo-header-container'>
        <div className='logo-header'>
          <Logo className='icon-logo' />
          <div className='logo-name'>
            Plant<span>Balance</span>
          </div>
        </div>
      </section>
      <section className='navbar-section page-title-container'>
        <p className='page-title'>{mapPageNameFromURL(currentPath, selectedLanguage)}</p>
      </section>
      <section className='navbar-section profile-header-container'>
        <div className='notification'>
          <NotificationBell />
        </div>
        <div className='profile-header'>
          <Profile />
        </div>
      </section>
      <section className='navbar-section menu-container'>
        <div className='hamburger-container c-hand' onClick={() => dispatch(toggle())}>
          <Menu className='icon-fill' />
        </div>
      </section>
    </header>
  );
};
export default Navbar;
