import { useFlag } from '@unleash/proxy-client-react';

export default function useFeatureFlags() {
  const enabledGuardrailsBasedOnXPercent = useFlag('guardrailsBasedOnXPercent');
  const enabledPlanShop = useFlag('PlanShop');
  const enabledYieldPrediction = useFlag('yieldPredictionPage');
  const enableAdaptivePlan = useFlag('AdaptivePlan');
  const enableProfilePageNotifications = useFlag('ProfilePageNotifications');
  const enableUserIvite = useFlag('UserInvite');
  const enableImportPage = useFlag('ImportPage');

  return {
    enabledGuardrailsBasedOnXPercent,
    enabledPlanShop,
    enabledYieldPrediction,
    enableAdaptivePlan,
    enableProfilePageNotifications,
    enableUserIvite,
    enableImportPage,
  };
}
