import React, { useMemo, useState, useEffect, useRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import isToday from 'dayjs/plugin/isToday';

import { DateRange } from 'interfaces/date-range';

import './DatePickerCalendar.scss';
import { getFirstWeekOfGivenDate } from 'utils/time.util';

dayjs.extend(isToday);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);

enum SelectionPhase {
  SELECTING_START,
  SELECTING_END,
}

export interface DatePickerCalendarProps {
  shownDate: Dayjs;
  startDate?: Dayjs | null;
  endDate?: Dayjs;
  isDateRange: boolean;
  onChange: (value: DateRange | Dayjs) => void;
  weekMode?: boolean;
  shownWeek?: number;
}

export interface CalendarCell {
  text: string;
  value: Dayjs;
  isToday: boolean;
  isCurrentMonth: boolean;
}

function getCalendarCells(date: Dayjs): CalendarCell[] {
  const daysArray = new Array(date.daysInMonth()).fill(1);
  const calendarCells: CalendarCell[] = [];

  const prepareCell = (date: Dayjs, dayNumber: number) => {
    const cellValue = date.clone().set('date', dayNumber);
    return {
      text: String(dayNumber),
      value: cellValue,
      isToday: cellValue.isToday(),
    };
  };

  daysArray.forEach((_, i) => {
    calendarCells.push({ ...prepareCell(date, i + 1), isCurrentMonth: true });
  });

  const precedingDays = (date.startOf('month').weekday() || 7) - 1;

  const lastMonth = date.subtract(1, 'month');
  for (let i = 0; i < precedingDays; i++) {
    calendarCells.unshift({
      ...prepareCell(lastMonth, lastMonth.daysInMonth() - i),
      isCurrentMonth: false,
    });
  }

  const followingDays = 42 - (daysArray.length + precedingDays);

  const nextMonth = date.add(1, 'month');
  for (let i = 0; i < followingDays; i++) {
    calendarCells.push({ ...prepareCell(nextMonth, i + 1), isCurrentMonth: false });
  }

  return calendarCells;
}

function getCalendarRows(date: Dayjs): Array<CalendarCell[]> {
  const cells = getCalendarCells(date);
  const rows: Array<CalendarCell[]> = [];

  for (let i = 0; i < cells.length; i += 7) {
    rows.push(cells.slice(i, i + 7));
  }

  return rows;
}

function getCalendarWeeks(rows: Array<CalendarCell[]>): { week: number; value: Dayjs }[] {
  return rows.map((row) => ({ week: row[0].value.week(), value: row[0].value }));
}

export const DatePickerCalendar: React.FC<DatePickerCalendarProps> = ({
  shownDate,
  startDate,
  endDate,
  isDateRange,
  onChange,
  weekMode = false,
  shownWeek = 1,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(startDate?.clone());
  const [selectedEndDate, setSelectedEndDate] = useState(endDate?.clone());
  const [selectionPhase, setSelectionPhase] = useState(SelectionPhase.SELECTING_START);
  const [selectedWeek, setSelectedWeek] = useState<number>(shownWeek);

  const datepickerContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (datepickerContainerRef.current) {
      datepickerContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  });

  useEffect(() => {
    if (startDate) {
      setSelectedStartDate(startDate);

      const [, , week] = getFirstWeekOfGivenDate(startDate);

      setSelectedWeek(week);
    }
  }, [startDate]);

  useEffect(() => {
    setSelectedEndDate(endDate);
  }, [endDate]);

  const rows = useMemo(() => getCalendarRows(shownDate), [shownDate]);
  const weeks = useMemo(() => getCalendarWeeks(rows), [rows]);

  const selectDate = (value: Dayjs) => {
    if (isDateRange) {
      if (selectionPhase === SelectionPhase.SELECTING_START) {
        setSelectedStartDate(value);
        setSelectedEndDate(undefined);
        setSelectionPhase(SelectionPhase.SELECTING_END);
      } else if (selectionPhase === SelectionPhase.SELECTING_END) {
        if (value.diff(selectedStartDate) > 0) {
          setSelectedEndDate(value);
          setSelectionPhase(SelectionPhase.SELECTING_START);

          if (selectedStartDate && value) {
            onChange({ start: selectedStartDate, end: value });
          }
        } else {
          setSelectedStartDate(value);
          setSelectedEndDate(undefined);
        }
      }
    } else {
      setSelectedStartDate(value);
      onChange(value);
    }
  };

  const getDateClasses = (cell: CalendarCell, index: number) => {
    let classes = '';

    if (
      selectedStartDate &&
      selectedEndDate &&
      selectedStartDate.diff(cell.value, 'hour') < 0 &&
      selectedEndDate.diff(cell.value, 'hour') > 0
    ) {
      classes += 'between ';
      if (index === 0) {
        if (weekMode) {
          classes += 'start ';
        }
        classes += 'first ';
      } else if (index === 6) {
        classes += 'last ';
      }
    } else if (selectedStartDate?.diff(cell.value, 'hour') === 0) {
      classes += 'start ';
      if (
        selectedEndDate &&
        selectedStartDate?.diff(selectedEndDate, 'hour') !== 0 &&
        index !== 6
      ) {
        classes += 'complete ';
      }
    } else if (selectedEndDate?.diff(cell.value, 'hour') === 0) {
      classes += 'end ';
      if (index !== 0) {
        classes += 'complete ';
      }
    }

    if (cell.isCurrentMonth) {
      classes += 'this-month ';
    }
    if (cell.isToday) {
      classes += 'today ';
    }

    return classes;
  };

  const selectWeek = (date: any) => {
    return () => {
      const [firstDay, lastDay, week] = getFirstWeekOfGivenDate(date.value);

      onChange({ start: firstDay, end: lastDay });
      setSelectedStartDate(firstDay);
      setSelectedEndDate(lastDay);
      setSelectedWeek(week);
    };
  };

  return (
    <div
      className={`datepicker-calendar ${weekMode ? 'week-mode' : ''}`}
      ref={datepickerContainerRef}
    >
      <div className='weekdays'>
        <div className='header content'>W</div>
        {weeks.map((week) => (
          <div
            key={week.week}
            className={`content ${week.week === selectedWeek ? 'selected-week' : ''} text-noselect`}
            onClick={weekMode ? selectWeek(week) : undefined}
          >
            {week.week}
          </div>
        ))}
      </div>

      <div className='separator'></div>

      <div>
        <div className='header'>
          {rows[0].map(({ value }, i) => (
            <div key={i} className='cell content text-noselect'>
              {value.format('dd')[0]}
            </div>
          ))}
        </div>

        {rows.map((cells, rowIndex) => (
          <div key={rowIndex} className='row'>
            {cells.map((cell, i) => (
              <div
                key={`${cell.text}-${i}`}
                className={`cell day-cell ${getDateClasses(cell, i)} text-noselect`}
                onClick={
                  weekMode
                    ? selectWeek(cell)
                    : (event) => {
                        event.stopPropagation();
                        selectDate(cell.value);
                      }
                }
              >
                <div className='content'>{cell.text}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
