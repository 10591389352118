import React from 'react';

import styles from './NotificationSettings.module.scss';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';

import { cropseasonSelector } from 'redux/slices/cropseason.slice';

import Settings from './Settings/Settings';
import {
  useGetCropseasonNotificationSettingsQuery,
  useUpdateNotificationSettingMutation,
} from 'services/notifications.service';
import useTranslate from 'hooks/useTranslate';
import { getSettingsTranslationKey } from 'config/notifications/getSettingsTranslationKey';
import { KeyValue } from 'interfaces/keyvalue';
import CropSeasonSelector from 'components/Filters/CropSeason/CropSeasonSelector';
import { TOAST_PROMISE } from 'config/constants';
import { dispatchPromiseToast } from 'utils/toast.util';
import { track } from 'utils/analtycs';

const NotificationSettings: React.FC = () => {
  const accountId = useAppSelector(accountIdSelector).accountId;
  const activeCropseason = useAppSelector(cropseasonSelector);
  const translate = useTranslate();

  const { data: settings } = useGetCropseasonNotificationSettingsQuery(
    { current_account_id: accountId, cropseason_id: activeCropseason.id },
    { skip: !accountId || !activeCropseason.id, refetchOnMountOrArgChange: true }
  );

  const [updateSetting, { isSuccess, originalArgs }] = useUpdateNotificationSettingMutation();

  const handleUpdateInputSetting = (id: string) => (threshold: KeyValue<Number>) => {
    track('notification settings update', { notificationId: id, threshold });
    const updateStettingsPromise = updateSetting({
      current_account_id: accountId,
      notificationSettingId: id,
      data: [
        {
          op: 'replace',
          path: '/settings',
          value: {
            ...threshold,
          },
        },
      ],
    });
    dispatchPromiseToast({
      type: TOAST_PROMISE,
      promise: updateStettingsPromise,
      messageLoading: translate('Toast - Notification - update'),
      messageError: translate('Toast - Notification - update - error'),
      messageSuccess: translate('Toast - Notification - update - success'),
    });
  };

  const handleUpdateToggleSetting = (id: string) => (toggle: boolean) => {
    track('notification settings update', { notificationId: id, toggle });
    const updateToggleSetting = updateSetting({
      current_account_id: accountId,
      notificationSettingId: id,
      data: [
        {
          op: 'replace',
          path: '/enabled',
          value: toggle,
        },
      ],
    });
    dispatchPromiseToast({
      type: TOAST_PROMISE,
      promise: updateToggleSetting,
      messageLoading: translate('Toast - Notification - toggle - loading'),
      messageError: translate('Toast - Notification - toggle - error') + ' ' + toggle,
      messageSuccess: translate('Toast - Notification - toggle - success') + ' ' + toggle,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{translate('Notify / Notification Settings')}</div>
        <div className={styles['notification-filters']}>
          <div className={styles.filters}>
            <div className={styles.cropseasons}>
              <CropSeasonSelector />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {settings?.map((setting) => {
          return (
            <Settings
              iconType={setting.type}
              text={translate(getSettingsTranslationKey(setting.type))}
              initialValues={{
                enabled: setting.enabled,
                value: setting.settings,
              }}
              key={setting.id}
              handleApplyChanges={handleUpdateInputSetting(setting.id)}
              applyToggleChanges={handleUpdateToggleSetting(setting.id)}
              id={setting.id}
              editable={Object.values(setting.settings).length > 0}
              constraints={setting.constraints}
              defaultValue={setting.defaults}
              hasChangesApplied={setting.id === originalArgs?.notificationSettingId && isSuccess}
              isDisorderNotification={
                setting.settings?.correction_factor || setting.settings?.correction_factor === 0
                  ? true
                  : false
              }
            />
          );
        })}
      </div>
    </div>
  );
};
export default NotificationSettings;
