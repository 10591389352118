import { CustomSettingsVariabiles } from 'interfaces/custom-settings';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { customPlanSelector, setCustomPlan } from 'redux/slices/customPlan.slice';
import { languageSelector } from 'redux/slices/language.slice';
import { planCreationSelector } from 'redux/slices/planCreationSlice';
import { ModalService } from 'services/modal.service';
import {
  useGetCustomSettingsTemperatureStrategiesQuery,
  useGetCustomSettingsVariabilesQuery,
} from 'services/plans.service';
import { formatTimestampToYearMonthDay } from 'utils/formatTimestampToYearMonthDay';
import { isMissing } from 'utils/general.util';
import { getCustomPlanValues } from 'utils/getCustomPlanValues';
import { getTemperatureSettingsDates } from 'utils/temperatureStrategies';
import { translate } from 'utils/translations.util';

interface UseCustomSettingsResult {
  customSettings?: CustomSettingsVariabiles;
  plantingDate?: number;
  harvestDate?: number;
  headRemovalDate?: number;
  locationId?: number;
}

export default function useCreationCustomSettings(): UseCustomSettingsResult {
  const dispatch = useDispatch();

  const { selectedLanguage } = useAppSelector(languageSelector);

  const customSettingsParams = {
    varietyId: useAppSelector(planCreationSelector).variety?.id,
    plantingDate: useAppSelector(planCreationSelector).plantingDate,
    harvestDate: useAppSelector(planCreationSelector).harvestDate,
    headRemovalDate: useAppSelector(planCreationSelector).headRemovalDate,
    location: useAppSelector(planCreationSelector).location?.id,
  };

  const { data: customSettings } = useGetCustomSettingsVariabilesQuery(
    {
      varietyId: customSettingsParams.varietyId!,
      transplantDate: formatTimestampToYearMonthDay(customSettingsParams.plantingDate!),
      head_removal_date: formatTimestampToYearMonthDay(customSettingsParams.headRemovalDate!),
      end_date: formatTimestampToYearMonthDay(customSettingsParams.harvestDate!), //New parameter
      location_id: customSettingsParams.location,
    },
    {
      skip:
        !customSettingsParams.varietyId ||
        !customSettingsParams.plantingDate ||
        !customSettingsParams.headRemovalDate ||
        !customSettingsParams.harvestDate ||
        !customSettingsParams.location,
    }
  );

  const hasCreationCustomPlanValues = () => {
    const customPlanValues = getCustomPlanValues(
      'creationCustomSettings'
    ) as CustomSettingsVariabiles;

    return (
      customPlanValues?.CO2_max &&
      customPlanValues?.CO2_min &&
      customPlanValues?.pruning_events.length > 0 &&
      customPlanValues?.stem_density_events.length > 0 &&
      customPlanValues?.phase_temp_strategies &&
      customPlanValues?.phase_temp_strategies.length > 0
    );
  };

  const getCreationCustomSettingsData = () => {
    if (hasCreationCustomPlanValues()) {
      return getCustomPlanValues('creationCustomSettings');
    }
    return {
      ...customSettings,
    };
  };

  const customPlanStateValues = getCustomPlanValues('customPlanState') as CustomSettingsVariabiles;
  const [customSettingsData] = useState(getCreationCustomSettingsData() as any);

  //currrent artificial lights values are taken from custom plan state
  //If no customPlanState is present, then the values are taken planCration or from the API call
  //latest values of Artificial Lights  are necessary for the Temperature strategy call
  const artificialLightsCurrent = {
    AL_enabled:
      customPlanStateValues?.AL_enabled ||
      customSettingsData?.AL_enabled ||
      customSettings?.AL_enabled,
    AL_max_capacity:
      customPlanStateValues?.AL_max_capacity ||
      customSettingsData?.AL_max_capacity ||
      customSettings?.AL_max_capacity,
    AL_percentage_LED:
      customPlanStateValues?.AL_percentage_LED ||
      customSettingsData?.AL_percentage_LED ||
      customSettings?.AL_percentage_LED,
    AL_radiation_threshold:
      customPlanStateValues?.AL_radiation_threshold ||
      customSettingsData?.AL_radiation_threshold ||
      customSettings?.AL_radiation_threshold,
  };

  const customRowsDates = useAppSelector(customPlanSelector).customPlanState?.phase_temp_strategies;
  const updatedRows =
    useAppSelector(customPlanSelector).customPlanState?.phase_temp_strategies_new_dates;

  const { data: customSettingsTemperatureStrategies } =
    useGetCustomSettingsTemperatureStrategiesQuery(
      {
        transplant_date: formatTimestampToYearMonthDay(customSettingsParams.plantingDate!),
        end_date: formatTimestampToYearMonthDay(customSettingsParams.harvestDate!),
        AL_enabled: artificialLightsCurrent.AL_enabled,
        AL_max_capacity: artificialLightsCurrent.AL_max_capacity,
        AL_percentage_LED: artificialLightsCurrent.AL_percentage_LED,
        AL_radiation_threshold: artificialLightsCurrent.AL_radiation_threshold,
        location_id: customSettingsParams.location,
        phase_start: getTemperatureSettingsDates(customRowsDates, updatedRows),
      },
      {
        skip:
          !customSettingsParams.harvestDate ||
          !customSettingsParams.plantingDate ||
          isMissing(artificialLightsCurrent.AL_enabled) ||
          !artificialLightsCurrent.AL_max_capacity ||
          !artificialLightsCurrent.AL_percentage_LED ||
          !artificialLightsCurrent.AL_radiation_threshold ||
          !customSettingsParams.location ||
          customPlanStateValues?.temperatureTouched === true,
      }
    );

  const modalCloseAction = (isConfirmed: boolean) => {
    if (isConfirmed) {
      dispatch(
        setCustomPlan({
          phase_temp_strategies: customSettingsTemperatureStrategies,
          temperatureTouched: false,
        })
      );
    } else {
      dispatch(
        setCustomPlan({
          AL_enabled: customSettingsData?.AL_enabled,
          AL_max_capacity: customSettingsData?.AL_max_capacity,
          AL_percentage_LED: customSettingsData?.AL_percentage_LED,
          AL_radiation_threshold: customSettingsData?.AL_radiation_threshold,
          lightsTouched: false,
          temperatureTouched: true,
        })
      );
    }
  };

  // If there is temp strategies in plan Creation selector, return entire object,
  //otherwise return the updated temp strategies from the API call
  const getCutomSettingsAllData = () => {
    if (!customSettingsData.phase_temp_strategies) {
      return {
        ...customSettingsData,
        phase_temp_strategies:
          customSettingsTemperatureStrategies || customSettings?.phase_temp_strategies,
      };
    } else if (customPlanStateValues?.lightsTouched) {
      return {
        ...customSettingsData,
        phase_temp_strategies: customSettingsTemperatureStrategies,
      };
    }

    return { ...customSettingsData };
  };

  useEffect(() => {
    setCustomPlan({
      phase_temp_strategies: customSettingsTemperatureStrategies,
    });
    if (
      customPlanStateValues?.lightsTouched === true &&
      customPlanStateValues?.temperatureTouched === true
    ) {
      ModalService.open({
        title: translate('dialogBox - resetTemperature - title', selectedLanguage),
        saveText: translate('Reset generic button', selectedLanguage),
        cancelText: translate('Cancel generic button', selectedLanguage),
        width: '495px',
        content: <div>{translate('dialogBox - resetTemperature - message', selectedLanguage)}</div>,
        closeAction: (isConfirmed) => {
          modalCloseAction(isConfirmed);
        },
      });
    }
  }, [
    customPlanStateValues?.AL_enabled,
    customPlanStateValues?.AL_max_capacity,
    customPlanStateValues?.AL_percentage_LED,
    customPlanStateValues?.AL_radiation_threshold,
  ]);

  return {
    customSettings: getCutomSettingsAllData(),
    plantingDate: customSettingsParams.plantingDate,
    harvestDate: customSettingsParams.harvestDate,
    headRemovalDate: customSettingsParams.headRemovalDate,
    locationId: customSettingsParams.location,
  };
}
