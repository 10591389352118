import React from 'react';
import dayjs from 'dayjs';

import './MyPlans.scss';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  plansSelector,
  setMyPlansFilters,
  setPlansRenameId,
  setSelectedPlansFolderName,
} from 'redux/slices/plansSlice';

import { NavLink } from 'react-router-dom';

import { ReactComponent as Plus } from 'styles/icons/plus.svg';

import DropDown from 'components/DropDown/DropDown';
import PlanFolders from './PlanFolders/PlanFolders';
import Plans from './Plans/Plans';

import DatePickerDropdown from 'components/DatePicker/DatePickerDropdown/DatePickerDropdown';
import { ReactComponent as ArrowLeft } from 'styles/icons/arrow-left.svg';

import { useGetPlansQuery } from 'services/plans.service';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';

import {
  filterPlansFoldersByVarietyAndTime,
  getPlansFoldersAndData,
  getPlansVarieties,
} from 'utils/plans.util';
import { BREAKPOINT_TABLET } from 'config/constants';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import useTranslate from 'hooks/useTranslate';
import useUrlState from 'hooks/useUrlState';

interface MyPlansProps {}

const MyPlans: React.FC<MyPlansProps> = () => {
  const dispatch = useAppDispatch();
  const windowSize: WindowSize = useWindowSize();
  const { myPlansFilters, selectedPlansFolderName } = useAppSelector(plansSelector);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { data: plans } = useGetPlansQuery(
    { accountId },
    { skip: !accountId, refetchOnMountOrArgChange: true }
  );
  const translate = useTranslate();
  const [, setUrlState] = useUrlState({
    startDate: '',
    endDate: '',
    varietyFilter: '',
  });

  const folders = getPlansFoldersAndData(plans || []);
  const varieties: string[] = getPlansVarieties(plans || []);
  const filteredPlansFolders = filterPlansFoldersByVarietyAndTime(
    folders || [],
    myPlansFilters.variety,
    { to: myPlansFilters.to, from: myPlansFilters.from }
  );

  const selectedPlansFolder = filteredPlansFolders.find(
    (folder) => folder.folderName === selectedPlansFolderName
  );

  const dropdownOnChange = (name: any, option: any) => {
    setUrlState({ varietyFilter: option });
    dispatch(setMyPlansFilters({ [name]: option }));
  };

  const onChangeDates = (values: any) => {
    setUrlState({ startDate: String(values.start.unix()), endDate: String(values.end.unix()) });
    dispatch(
      setMyPlansFilters({
        from: values.start.unix(),
        to: values.end.unix(),
      })
    );
  };

  function handleDatesError() {
    if (myPlansFilters.to < myPlansFilters.from) {
      return 'The start date should be earlier than the end date.';
    }
  }

  const renderFolderHeader = () => {
    return (
      <div className='selected-folder-header-container'>
        <ArrowLeft
          className='selected-folder-back icon-light c-hand'
          width={35}
          height={35}
          onClick={() => {
            dispatch(setSelectedPlansFolderName(''));
            dispatch(setPlansRenameId(''));
          }}
        />
        <h2 className='selected-folder-title'>{selectedPlansFolderName}</h2>
        <NavLink to='/plan-settings' className='create-plan-link'>
          <div className='my-plans-create-button c-hand'>
            <Plus className='icon-light my-plans-create-button-icon' />
            <div className='my-plans-create-button-text'>
              {translate('My Plans - create a new plan')}
            </div>
          </div>
        </NavLink>
      </div>
    );
  };

  const getDatepicekrClass = () => {
    if (windowSize.width <= BREAKPOINT_TABLET) {
      return `custom-range-left`;
    }
    return `custom-range-right`;
  };

  const renderFoldersHeader = () => {
    return (
      <>
        <div className='my-plans-title-container'>
          <h2 className='my-plans-title'>{translate('My Plans - header')}</h2>
        </div>
        <div className='my-plans-filters-and-actions'>
          <div className='my-plans-datepicker'>
            <DatePickerDropdown
              onChangeDropdown={onChangeDates}
              initialValues={{
                start: dayjs.unix(myPlansFilters.from),
                end: dayjs.unix(myPlansFilters.to),
              }}
              required
              requiredText={handleDatesError()}
              customRangePosition={getDatepicekrClass()}
            />
          </div>
          <div className='my-plans-dropdown'>
            <DropDown
              required={false}
              initialValue={myPlansFilters.variety}
              name='variety'
              optionList={varieties ? ['Not specified', ...varieties] : ['Not specified']}
              setOnChange={dropdownOnChange}
              rightSide
            />
          </div>
          <NavLink to='/plan-settings' className='create-plan-link'>
            <div className='my-plans-create-button c-hand'>
              <Plus className='icon-light my-plans-create-button-icon' />
              <div className='my-plans-create-button-text'>
                {translate('My Plans - create a new plan')}
              </div>
            </div>
          </NavLink>
        </div>
      </>
    );
  };

  const renderFoldersOrPlans = () => {
    if (selectedPlansFolder) {
      return <Plans selectedPlansFolder={selectedPlansFolder} />;
    }
    return <PlanFolders filteredPlansFolder={filteredPlansFolders} />;
  };

  return (
    <div className='my-plans-container'>
      <div className='my-plans-header'>
        <p className='my-page-page-name'>
          Plan
          {`${selectedPlansFolderName ? ' / ' + selectedPlansFolderName : ''}`}
        </p>
        {selectedPlansFolderName ? renderFolderHeader() : renderFoldersHeader()}
      </div>
      <div className={`my-plans-folders-container ${selectedPlansFolderName ? 'light-mode' : ''}`}>
        {renderFoldersOrPlans()}
      </div>
    </div>
  );
};
export default MyPlans;
