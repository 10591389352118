import { RootState } from 'redux/store';
import { createSlice } from '@reduxjs/toolkit';
import { CropSeason } from 'interfaces/crop-season';
import { activeCropseasonInitialValue } from 'config/constants';

const initialState: CropSeason = activeCropseasonInitialValue;

export const cropseasonSlice = createSlice({
  name: 'cropseason',
  initialState,
  reducers: {
    setActiveCropseason: (state, { payload }: { payload: CropSeason }) => {
      return payload;
    },
  },
});

export const { setActiveCropseason } = cropseasonSlice.actions;

export const cropseasonSelector = (state: RootState) => state.cropseason;

export default cropseasonSlice.reducer;