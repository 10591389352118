import LegendGraphDataToggleSwitches from 'components/Track/LegendGraphDataToggleSwitches/LegendGraphDataToggleSwitches';
import PlanGraph from 'components/Track/PlanGraph/PlanGraph';
import { activeCropseasonInitialValue, RESOLUTION, VARIABLES_CLIMATE } from 'config/constants';
import dayjs from 'dayjs';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { useGetAdaptivePlanData } from 'hooks/useGetAdaptivePlanData';
import { useGetDataForPlan } from 'hooks/useGetDataForPlan';
import useGetPlanLegends from 'hooks/usePlanLegends';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { cropseasonSelector } from 'redux/slices/cropseason.slice';
import { sideBarSelector } from 'redux/slices/sidebar.slice';
import { trackFilterSelector } from 'redux/slices/trackFilterSlice';
import { useGetActualsQuery } from 'services/actuals.service';
import {
  useGetGuardrailsQuery,
  useGetGuardrailVariableStatusQuery,
} from 'services/guardrails.service';
import { getFooterSizeClass } from 'utils/general.util';
import { getGuardrailsDataBasedOnPlan } from 'utils/getGuardrailsBasedOnPlan';
import { getActualsVariables } from 'utils/plan-variables-mapper';
import { getPlanVariableIds } from 'utils/plans.util';
import { getVariablesStatusWarning, getVariablesDaysWeekResolution } from 'utils/variables.util';

interface PlanProps {}

const Plan: React.FC<PlanProps> = () => {
  const { dates, resolution } = useAppSelector(trackFilterSelector);
  const { id: cropseasonId } = useAppSelector(cropseasonSelector);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const climateVariables = getVariablesDaysWeekResolution(VARIABLES_CLIMATE);
  const { enabledGuardrailsBasedOnXPercent } = useFeatureFlags();
  const isOpen = useAppSelector(sideBarSelector).isOpen;

  let { data, isSuccess } = useGetActualsQuery(
    {
      cropSeasonId: cropseasonId,
      startDate: dayjs.unix(dates.start).toISOString(),
      endDate: dayjs.unix(dates.end).toISOString(),
      resolution: resolution?.value || RESOLUTION._1_day,
      variables: getActualsVariables(VARIABLES_CLIMATE),
      accountId: accountId,
    },
    {
      skip:
        !cropseasonId ||
        (cropseasonId === activeCropseasonInitialValue.id && dates.end < dates.start) ||
        !accountId,
    }
  );

  const { data: guardrail, isSuccess: isGuardRailSuccess } = useGetGuardrailsQuery(
    {
      cropSeasonId: cropseasonId,
      startDate: dayjs.unix(dates.start).toISOString(),
      endDate: dayjs.unix(dates.end).toISOString(),
      resolution: resolution.value,
      variables: VARIABLES_CLIMATE,
      accountId: accountId,
    },
    {
      skip:
        cropseasonId === activeCropseasonInitialValue.id ||
        dates.end < dates.start ||
        enabledGuardrailsBasedOnXPercent,
    }
  );

  const { data: variableStatus } = useGetGuardrailVariableStatusQuery(
    {
      cropseasonId,
      accountId,
      varDay: climateVariables.varDay,
      varWeek: climateVariables.varWeek,
    },
    {
      skip: !cropseasonId || !accountId,
    }
  );

  const mappedVariablesToPlanID = getPlanVariableIds(VARIABLES_CLIMATE);

  const planData = useGetDataForPlan(mappedVariablesToPlanID);

  const adaptivePlan = useGetAdaptivePlanData(VARIABLES_CLIMATE, data?.lastTimestamp);

  let guardrailXPercent = getGuardrailsDataBasedOnPlan(
    planData.plan,
    mappedVariablesToPlanID,
    enabledGuardrailsBasedOnXPercent
  );

  let guardrailData = guardrail || guardrailXPercent;

  const legendItems = useGetPlanLegends(
    VARIABLES_CLIMATE,
    guardrailData,
    planData,
    data,
    adaptivePlan,
    mappedVariablesToPlanID
  );

  if (!data) {
    return null;
  }

  return (
    <div className='graph-container plan-graph'>
      {Object.entries(VARIABLES_CLIMATE).map(([key, value]) => {
        let newClassName = getVariablesStatusWarning(variableStatus, value);

        return (
          <div className={`line-graph-container ${newClassName}`} key={key}>
            <PlanGraph
              actual={isSuccess ? data : undefined}
              guardrail={guardrailData}
              plan={planData.plan}
              planDetails={planData.planDetails}
              variableKey={key}
              variableValue={value}
              resolution={resolution.value}
              dates={[dates.start, dates.end]}
              adaptivePlan={adaptivePlan}
            />
          </div>
        );
      })}

      {legendItems && (
        <div className={`fixed-footer-legend plan-legend ${getFooterSizeClass(isOpen)}`}>
          <LegendGraphDataToggleSwitches legendItems={legendItems} showFullItem={true} />
        </div>
      )}
    </div>
  );
};
export default Plan;
