import { TemperatureStrategy } from 'interfaces/custom-settings';

/**
 * This function combines the dates from customRowsDates and updatedRows
 * and returns a sorted array of unique dates.
 * It is intended for use when refreshing the page to ensure all dates
 * are included in the API call.
 * @returns Array of string dates in "YYYY-MM-DD" format
 */
export const getTemperatureSettingsDates = (
  customRowsDates?: TemperatureStrategy[],
  updatedRows?: any[]
) => {
  let allDates: string[] = [];
  if (!customRowsDates || !updatedRows) {
    return allDates;
  }

  allDates = [
    ...customRowsDates.map((row) => row.phase_start),
    ...updatedRows.map((row) => row.date),
  ].filter(Boolean) as string[];

  // Deduplicate by converting to a Set and back to an array
  allDates = [...new Set(allDates)];

  // Sort by date (assuming allDates is an array of string dates in "YYYY-MM-DD" format)
  allDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  return allDates;
};
