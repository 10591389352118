import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { QUERY_LIMIT } from 'config/constants';
import dayjs from 'dayjs';
import { CropSeasonShort, Variety } from 'interfaces/crop-season';
import { CustomSettingsVariabiles } from 'interfaces/custom-settings';
import {
  DeletePlanResponse,
  Folders,
  Plan as PlanAndCusttomSettings,
  PlanVariable,
} from 'interfaces/plans';
import { baseApi } from 'redux/slices/baseApi.slice';
import { getRanges } from 'utils/getIntervalsForGraphs';
import { formatQueryString } from 'utils/queryString';
import { Location } from 'interfaces/location';
import { TemperatureStrategy } from 'interfaces/custom-settings';
import { getSaveAsPlan } from 'utils/plans.util';
import { translate } from 'utils/translations.util';

export interface Plan {
  id: number;
  name: string;
  path: string;
  creation_timestamp: string;
  last_modified_timestamp: string;
  cropseason: CropSeasonShort;
  variety: Variety;
  location: Location;
}

interface CustomSettingsVarResponse {
  result: {
    custom_plan_settings: {
      AL_enabled: boolean;
      AL_max_capacity: number;
      AL_percentage_LED: number;
      AL_radiation_threshold: number;
      CO2_max: number;
      CO2_min: number;
      first_flowering_timestamp?: number;
      pruning_events: [{ timestamp: number; value: number }];
      stem_density_events: [{ timestamp: number; value: number }];
      sowing_date: string;
      EC: { min: number; max: number };
      accelerated_ripening?: {
        enabled: boolean;
        starting_from: number;
      };
    };
  };
}

interface GetPlansQueryParams {
  accountId: number;
  cropseasonId?: number;
  skip?: number;
}

interface GetPlansDataQueryParams {
  planId: number;
  skip?: number;
}

const getPlansQuery = ({ accountId, cropseasonId, skip }: GetPlansQueryParams) => {
  const ENDPOINT = `plans?`;
  return (
    ENDPOINT +
    formatQueryString({
      skip: skip,
      limit: QUERY_LIMIT,
      current_account_id: accountId,
      cropseason_id: cropseasonId,
    })
  );
};

const getPlanDataQuery = ({ planId, skip }: GetPlansDataQueryParams) => {
  const ENDPOINT = `plans/${planId}?`;
  return (
    ENDPOINT +
    formatQueryString({
      skip: skip,
      limit: QUERY_LIMIT,
    })
  );
};

const transformResponse = (rawResult: any) => {
  let response: { refetch: boolean; data?: number[] } = { refetch: false };

  const result = rawResult.data.result.plans;

  if (!result) {
    return response;
  }

  response.refetch = rawResult.data.pagination.next_page ? true : false;
  response.data = result;

  return response;
};

const retrievePlans = async (
  _arg: GetPlansQueryParams,
  fetchWithBaseQuery: (
    arg: string | FetchArgs
  ) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>
) => {
  let response: any = {
    data: undefined,
  };
  let skip = 0;
  let shouldRefetch = false;

  do {
    let partialResponse = await fetchWithBaseQuery(getPlansQuery({ ..._arg, skip }));
    if (partialResponse.error?.status === 404) {
      return {
        response: null,
      };
    }
    const { data, refetch } = transformResponse(partialResponse);
    shouldRefetch = refetch;

    if (response.data && data) {
      response.data.push(...data);
    } else {
      response.data = data;
    }

    skip += QUERY_LIMIT;
  } while (shouldRefetch);

  return response;
};

const retrievePlanData = async (
  _arg: GetPlansDataQueryParams,
  fetchWithBaseQuery: (
    arg: string | FetchArgs
  ) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>
) => {
  let skip = 0;
  try {
    let rawResults = await fetchWithBaseQuery(getPlanDataQuery({ ..._arg, skip }));
    if (rawResults.error && Number(rawResults.error.status) > 300) {
      return {
        response: null,
      };
    }
    return (rawResults.data as any).result.plan;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const plansApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlan: builder.query<PlanAndCusttomSettings, number>({
      query: (planId: number) => `plans/${planId}`,
      transformResponse: (rawResults: { result: { plan: PlanAndCusttomSettings } }): any => {
        return rawResults.result.plan;
      },
    }),
    getPlans: builder.query<Plan[], GetPlansQueryParams>({
      providesTags: ['Plan'],
      queryFn: async (_arg, queryApi, _extraOptions, fetchWithBaseQuery) => {
        let response = await retrievePlans(_arg, fetchWithBaseQuery);

        return response;
      },
    }),
    getFolders: builder.query<Folders, number>({
      query: (accountId) => {
        const ENDPOINT = 'plans/folders?';
        return (
          ENDPOINT +
          formatQueryString({
            current_account_id: accountId,
          })
        );
      },
      transformResponse: (rawResult: { result: { folder_names: Folders } }): Folders => {
        return rawResult.result.folder_names.map((folder) => folder.substring(1));
      },
    }),
    deletePlan: builder.mutation<DeletePlanResponse, number>({
      query: (id: number) => ({ url: `plans/${id}`, method: 'DELETE', credentials: 'include' }),
      invalidatesTags: ['Plan'],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        const accountId = getState().baseApi.queries['getUserAccountId(undefined)']?.data;
        try {
          const { data: deletedPlan } = await queryFulfilled;
          if (deletedPlan.result.deleted_plan.id === id) {
            dispatch(
              plansApi.util.updateQueryData(
                'getPlans',
                { accountId: accountId as number },
                (plans) => {
                  return plans.filter((plan) => {
                    return plan.id !== id;
                  });
                }
              )
            );
          }
        } catch (error) {
          console.error('Could not delete plan.');
        }
      },
    }),
    updatePlan: builder.mutation<any, { id: number; data: Partial<PlanAndCusttomSettings> | any }>({
      query: ({ id, data }) => ({
        url: `plans/${id}`,
        body: data,
        method: 'PUT',
        credentials: 'include',
      }),
      async onQueryStarted({ id, data }, { dispatch, queryFulfilled, getState }) {
        const accountId = getState().baseApi.queries['getUserAccountId(undefined)']?.data;

        try {
          const { data: updatedPlan } = await queryFulfilled;
          dispatch(
            plansApi.util.updateQueryData(
              'getPlans',
              { accountId: accountId as number },
              (plans) => {
                return plans.map((plan) => {
                  if (plan.id !== id && data.cropseason_id === plan.cropseason?.id) {
                    return { ...plan, cropseason: null };
                  } else if (plan.id === id) {
                    return { ...plan, ...updatedPlan.result.updated_plan };
                  }
                  return plan;
                });
              }
            )
          );
        } catch (error) {}
      },
    }),
    createPlan: builder.mutation<any, any>({
      query: (data) => ({
        url: `plans`,
        body: data,
        method: 'POST',
        credentials: 'include',
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled, getState }) {
        const accountId = getState().baseApi.queries['getUserAccountId(undefined)']?.data;

        try {
          const { data: createdPlan } = await queryFulfilled;
          dispatch(
            plansApi.util.updateQueryData(
              'getPlans',
              { accountId: accountId as number },
              (plans) => {
                if (createdPlan.result.plan) {
                  plans.push(createdPlan.result.plan);
                }
              }
            )
          );
        } catch (error) {
          console.error('Could not create plan.');
        }
      },
    }),
    duplicatePlan: builder.mutation<any, any>({
      queryFn: async (arg, api, extraOptions, fetchWithBaseQuery) => {
        // Call retrievePlanData before POST to get missing plan parameters
        let planDataForSave = {};
        if (!arg.parameters) {
          const planId = arg.planId;
          try {
            const planData = await retrievePlanData({ planId }, fetchWithBaseQuery);
            if (!planData) {
              return null;
            }

            arg.parameters = planData.parameters;

            planDataForSave = getSaveAsPlan(arg);
            const result = await fetchWithBaseQuery({
              url: `plans`,
              body: planDataForSave,
              method: 'POST',
              credentials: 'include',
            });

            return result as any;
          } catch (error) {
            console.error(error);
          }
        }
      },
      async onQueryStarted(data, { dispatch, queryFulfilled, getState }) {
        const accountId = getState().baseApi.queries['getUserAccountId(undefined)']?.data;

        try {
          const { data: createdPlan } = await queryFulfilled;
          dispatch(
            plansApi.util.updateQueryData(
              'getPlans',
              { accountId: accountId as number },
              (plans) => {
                let currentPlan = createdPlan.result?.new_plan;

                if (currentPlan) {
                  const newPlan = {
                    ...currentPlan,
                    location: currentPlan.parameters.location,
                    variety: currentPlan.parameters.variety,
                  };
                  plans.push(newPlan);
                }
              }
            )
          );
        } catch (error) {
          console.error(translate('Duplicate Plan - Error'));
        }
      },
    }),

    getPlanByCropSeasonId: builder.mutation<
      PlanAndCusttomSettings,
      { accountId: number; cropSeasonId: number }
    >({
      query: ({ accountId, cropSeasonId }) => {
        const ENDPOINT = `plans?`;
        return (
          ENDPOINT +
          formatQueryString({
            cropseason_id: cropSeasonId,
            skip: 0,
            limit: 1,
            current_account_id: accountId,
          })
        );
      },
      transformResponse: (rawResults: { result: { plans: PlanAndCusttomSettings[] } }): any => {
        return rawResults.result.plans[0];
      },
    }),
    getCustomSettingsVariabiles: builder.query<
      CustomSettingsVariabiles,
      {
        varietyId: number;
        transplantDate: string;
        head_removal_date?: string;
        end_date?: string;
        location_id?: number;
      }
    >({
      query: ({ varietyId, transplantDate, head_removal_date, location_id }) => {
        const ENDPOINT = `plans/defaults/parameters/custom-settings?`;
        return (
          ENDPOINT +
          formatQueryString({
            variety_id: varietyId,
            transplant_date: transplantDate,
            head_removal_date,
            end_date: head_removal_date,
            location_id,
          })
        );
      },
      transformResponse: (rawResults: CustomSettingsVarResponse): CustomSettingsVariabiles => {
        return rawResults.result.custom_plan_settings;
      },
    }),
    getCustomSettingsTemperatureStrategies: builder.query<
      TemperatureStrategy[],
      {
        transplant_date: string;
        end_date: string;
        phase_start?: string[];
        AL_enabled: boolean;
        AL_max_capacity: number;
        AL_percentage_LED: number;
        AL_radiation_threshold: number;
        location_id?: number;
      }
    >({
      query: ({
        transplant_date,
        end_date,
        phase_start,
        AL_enabled,
        AL_max_capacity,
        AL_percentage_LED,
        AL_radiation_threshold,
        location_id,
      }) => {
        const ENDPOINT = `plans/defaults/parameters/custom-settings/phase-temp-strategies?`;

        return (
          ENDPOINT +
          formatQueryString({
            transplant_date,
            end_date,
            phase_start,
            AL_enabled,
            AL_max_capacity,
            AL_percentage_LED,
            AL_radiation_threshold,
            location_id,
          })
        );
      },
      transformResponse: (rawResults: any): TemperatureStrategy[] => {
        return rawResults.result.phase_temp_strategies;
      },
    }),

    getPlanTimeseriesVariables: builder.query<PlanVariable[], number>({
      query: (accountId) => `plans/variables`,
      transformResponse: (rawResults: {
        result: { variables: PlanVariable[] };
      }): PlanVariable[] => {
        return rawResults.result.variables.map((variable) => {
          return {
            id: variable.id,
            new_name: variable.new_name,
            unit: variable.unit,
          };
        });
      },
    }),

    getPlanTimeseries: builder.query<
      any,
      { planId: number; variables?: string[]; accountId: number }
    >({
      query: ({ planId, variables, accountId }) => {
        const ENDPOINT = `plans/timeseries?id=${planId}&current_account_id=${accountId}&`;
        return (
          ENDPOINT +
          formatQueryString({
            variable_id: variables,
          })
        );
      },
      transformResponse: (rawResults: { result: { timeseries: any } }): any => {
        const keyId = Object.keys(rawResults.result.timeseries)[0];
        const response = rawResults.result.timeseries[keyId];
        let finalResponse: any = [];
        let dateTimeWeeks = response.local_datetime.map((week: string) => dayjs(week).week());
        let timestamps = response.local_datetime.map((time: string) => dayjs(time).unix());
        let responseKeyValue = {};

        Object.keys(response).forEach((key) => {
          finalResponse = { ...finalResponse, [key]: [] };
          responseKeyValue = { ...responseKeyValue, [key]: key };
        });

        function setFinalReponse(data: number, index: number, property: string) {
          finalResponse[property].push({
            [property]: data,
            timestamp: timestamps[index],
            dateTimeWeek: dateTimeWeeks[index],
          });
        }

        for (let property in response) {
          response[property].forEach((graphData: number, index: number) => {
            setFinalReponse(graphData, index, property);
          });
        }
        return {
          ...finalResponse,
          ranges: {
            ...getRanges(response, responseKeyValue),
            weeks: [dateTimeWeeks[0], dateTimeWeeks[dateTimeWeeks.length - 1]],
          },
        };
      },
    }),
  }),
});

export const {
  useGetPlanQuery,
  useGetPlansQuery,
  useDeletePlanMutation,
  useUpdatePlanMutation,
  useCreatePlanMutation,
  useDuplicatePlanMutation,
  useGetFoldersQuery,
  useGetPlanByCropSeasonIdMutation,
  useGetCustomSettingsVariabilesQuery,
  useGetCustomSettingsTemperatureStrategiesQuery,
  useGetPlanTimeseriesVariablesQuery,
  useGetPlanTimeseriesQuery,
} = plansApi;
