import type { RootState } from 'redux/store';
import { createSlice } from '@reduxjs/toolkit';

interface accountIdSliceState {
  accountId: number;
}

const initialState: accountIdSliceState = {
  accountId: 0,
};

export const accountIdSlice = createSlice({
  name: 'accountId',
  initialState,
  reducers: {
    setAccountId: (state, { payload }) => {
      state.accountId = payload;
    },
  },
});

export const { setAccountId } = accountIdSlice.actions;

export const accountIdSelector = (state: RootState) => state.accountId;

export default accountIdSlice.reducer;
