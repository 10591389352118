import analyzeColors from 'config/analyze-colors.json';
import benchmarkColors from 'config/benchmark-colors.json';

const DEFAULT_COLOR = '#fff';

export const ANALYZE_COLOR_COUNT = Object.keys(analyzeColors).length;
export const BENCHMARK_COLOR_COUNT = Object.keys(benchmarkColors).length;

export const getAnalyzeColor = (currentVariablesColors: string[]): string => {
  if (!currentVariablesColors || !currentVariablesColors.length) {
    return DEFAULT_COLOR;
  }

  let resultColor = Object.values(analyzeColors).find(
    (color) => !currentVariablesColors.includes(color)
  );
  return resultColor || DEFAULT_COLOR;
};

export const getBenchmarkColor = (index: number): string => {
  if (typeof index !== 'number') {
    return DEFAULT_COLOR;
  }

  return benchmarkColors[`${index % BENCHMARK_COLOR_COUNT}` as keyof typeof benchmarkColors];
};
