
import { Range } from 'interfaces/range';
import { GraphLine } from "interfaces/line-graph-data";
import { useCallback } from "react";


type UseYRangeInterval = {
    getYRangeInterval: () => Range;
}

export default function useGetYRangeInterval(
    graphLines: GraphLine[],
): UseYRangeInterval {

    //Yrange interval is the min and max values of all graph lines data
    const getYRangeInterval = useCallback((): Range => {
        const data = graphLines;

        const graphLinesData = data.map((d) => d.data);
        let minMaxGraphLineValues: number[] = [];

        graphLinesData.forEach((graphLineData) => {
            let grapLineValues: number[] = [];
            graphLineData.forEach((graphLinePoint: any) => {
                grapLineValues.push(Object.values(graphLinePoint)[1] as number);
            });
            let filteredGraphLineValues = grapLineValues.filter(Number.isFinite);
            if (filteredGraphLineValues.length) {
                minMaxGraphLineValues.push(Math.min(...filteredGraphLineValues));
                minMaxGraphLineValues.push(Math.max(...filteredGraphLineValues));
            }
        });
        let globalGraphLineMin = Math.min(...minMaxGraphLineValues),
            globalGraphLineMax = Math.max(...minMaxGraphLineValues);

        //in case graph line is a flat 0,0,0 line put max bigger than 0 so that the line is visible
        if (globalGraphLineMin === globalGraphLineMax) {
            globalGraphLineMax += 0.5;
        }

        return { min: globalGraphLineMin, max: globalGraphLineMax };
    }, [graphLines]);

    return { getYRangeInterval };

};

