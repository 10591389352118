import React, { useRef, useState } from 'react';
import './FileUploadInput.scss';
import useTranslate from 'hooks/useTranslate';

interface FileUploadInputProps {
  onFileChange: (file: File) => void;
}

const FileUploadInput: React.FC<FileUploadInputProps> = ({ onFileChange }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>('');
  const translate = useTranslate();

  const handleFileChange = () => {
    if (fileInputRef?.current?.files) {
      const file = fileInputRef.current.files[0];
      setFileName(file.name);
      fileInputRef.current.value = '';
      onFileChange(file);
    }
  };

  return (
    <div className='file-upload'>
      <div className={'input-file-upload-container'}>
        <input
          type='file'
          name='file'
          id='fileInput'
          ref={fileInputRef}
          onChange={handleFileChange}
          className='file-input-upload'
        />
        <label htmlFor='fileInput' className='file-label'>
          {translate('Import Data - Choose File')}
        </label>
        <span className='file-input-label'>{fileName}</span>
      </div>
    </div>
  );
};

export default FileUploadInput;
