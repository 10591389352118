import styles from './StaticInput.module.scss';

type StaticInputStyleType = 'small' | 'medium' | 'large';
type StaticInputAlign = 'center' | 'left' | 'right';

interface StaticInputProps {
  value: string | number;
  headerText?: string | undefined;
  align: StaticInputAlign;
  icon?: JSX.Element;
  type?: StaticInputStyleType;
}

const StaticInput: React.FC<StaticInputProps> = ({
  value,
  headerText,
  align,
  icon,
  type = 'small',
}) => {
  const alignClass = `static-input-${align}`;

  return (
    <div
      className={styles['static-input-container'] + ' ' + styles[`static-input-container-${type}`]}
    >
      {headerText && <div className={styles['header-text']}>{headerText}</div>}
      <div className={styles['static-input'] + ' ' + styles[`${alignClass}`]}>
        {value}
        {icon && <div className={styles['icon-container']}>{icon}</div>}
      </div>
    </div>
  );
};

export default StaticInput;
