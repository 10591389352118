import './EditPlanSidebar.scss';

import { DatePicker } from 'components/DatePicker/DatePicker';
import UnLinkPlanToCropSeasonMessage from 'components/Dialogs/UnlinkPlan';
import DropButton from 'components/DropButton/DropButton';
import DropDown from 'components/DropDown/DropDown';
import DropUp from 'components/DropUp/DropUp';
import {
  MAX_LENGTH_PLAN_NAME,
  MIN_LENGTH_PLAN_NAME,
  TOAST_ERROR,
  TOAST_PROMISE,
} from 'config/constants';
import dayjs, { Dayjs } from 'dayjs';
import { CropSeason } from 'interfaces/crop-season';
import { DateRange } from 'interfaces/date-range';
import { Plan } from 'interfaces/plans';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import {
  customPlanSelector,
  resetCustomPlan,
  setCustomPlan,
  setEditCustomSettings,
} from 'redux/slices/customPlan.slice';
import { languageSelector } from 'redux/slices/language.slice';
import { setOption } from 'redux/slices/planCreationSlice';
import { plansSelector, toggleEditSidebar } from 'redux/slices/plansSlice';
import { useGetCropseasonsQuery } from 'services/cropseason.service';
import {
  useGetLocationsQuery,
  useGetRootStocksQuery,
  useGetVarietiesQuery,
} from 'services/metaData.service';
import { ModalService } from 'services/modal.service';
import {
  useCreatePlanMutation,
  useGetPlanByCropSeasonIdMutation,
  useUpdatePlanMutation,
} from 'services/plans.service';
import { ReactComponent as Close } from 'styles/icons/close.svg';
import { ReactComponent as EditIcon } from 'styles/icons/edit-2.svg';
import { track } from 'utils/analtycs';
import {
  firstSettingDateValidation,
  harvestValidation,
  headRemovalValidation,
  sowingValidation,
} from 'utils/cropPlanDatesValidation.util';
import { formatTimestampToYearMonthDay } from 'utils/formatTimestampToYearMonthDay';
import { getCustomPlanValues } from 'utils/getCustomPlanValues';
import { getChangedPlanParams } from 'utils/plans.util';
import { dispatchPromiseToast, dispatchToast } from 'utils/toast.util';
import { translate } from 'utils/translations.util';
import { checkFileName } from 'utils/validation.util';
import PlanCustomSettings from 'views/PlanSettings/PlanCustomSettings/PlanCustomSettings';

import useEditPlanCustomSettings from '../hooks/useEditPlanCustomSettings';
import SavePlanAsContent from './SavePlanAsContent/SavePlanAsContent';
import {
  ECValidation,
  ripeningDateValidation,
  temperatureStrategyValidation,
} from 'utils/customSettngsValidation';

interface EditPlanSidebarProps {
  refetchPlanData: () => void;
  refetchVariables: () => void;
  refetchTimeseries: () => void;
  planData: Plan;
}

export interface SidebarFileFolderNames {
  fileNameField: string;
  folderNameField: string;
}

const LinkPlanToCropSeasonMessage: React.FC<{ touched?: boolean }> = ({ touched = false }) => {
  const { selectedLanguage } = useAppSelector(languageSelector);
  return (
    <div>{translate('Plan edit - link plan to cropseason warning body', selectedLanguage)}</div>
  );
};

const EditPlanSidebar: React.FC<EditPlanSidebarProps> = ({
  refetchPlanData,
  refetchVariables,
  refetchTimeseries,
  planData,
}) => {
  let navigate = useNavigate();
  const accountId: number = useAppSelector(accountIdSelector).accountId;
  const planPlantingDate = planData?.parameters?.transplant_date;
  const planHarvestDate = planData?.parameters?.end_date;
  const planHeadRemovalDate = planData?.parameters?.head_removal_date;
  const { selectedLanguage } = useAppSelector(languageSelector);

  const { data: cropSeasons } = useGetCropseasonsQuery(accountId, { skip: !accountId });
  const { data: locationsOptions } = useGetLocationsQuery(accountId, { skip: !accountId });
  const [cropSeason, setCropSeason] = useState(
    getCropSeason(cropSeasons!, planData.cropseason?.id)
  );

  const customPlanState = useAppSelector(customPlanSelector).customPlanState;

  // useRef() creates a mutable object `customPlanStateRef`
  // which allows us to store the latest `customPlanState`
  // This is useful when using the `validateFunction`
  // as it provides access to the latest state value `customPlanState`
  // and avoids the stale closure problem
  const customPlanStateRef = useRef(customPlanState);

  useEffect(() => {
    customPlanStateRef.current = customPlanState;
  }, [customPlanState]);

  const [trigger, setTrigger] = useState<{ initiated: boolean; value: boolean }>({
    initiated: false,
    value: true,
  });

  useEffect(() => {
    const customPlanValuesEdit = getCustomPlanValues('editCustomSettings');
    if (trigger.initiated && customPlanValuesEdit) {
      setSidebarPlanParameters((prevVals: any) => ({
        ...prevVals,
        custom_settings: { ...prevVals.custom_settings, ...customPlanValuesEdit },
      }));
    }
  }, [trigger]);

  useEffect(() => {
    dispatch(resetCustomPlan({}));
    setCropSeason(getCropSeason(cropSeasons!, planData.cropseason?.id));
    setEditCustomSettings();
  }, [cropSeasons, planData]);

  let resetParametersObject = {
    ...planData.parameters,
    transplant_date: dayjs(planData?.parameters?.transplant_date).unix(),
    first_setting_date: dayjs(planData?.parameters?.first_setting_date).unix(),
    head_removal_date: dayjs(planData?.parameters?.head_removal_date).unix(),
    end_date: dayjs(planData?.parameters?.end_date).unix(),
    sowing_date: dayjs(planData?.parameters?.sowing_date).unix(),
    cropseason_id: planData.cropseason?.id,
  };

  const initialCustomSettings = planData.parameters.custom_settings;
  const initialPlanParameters = resetParametersObject;
  const [sidebarPlanParameters, setSidebarPlanParameters] = useState<any>(resetParametersObject);

  const harvestDate = sidebarPlanParameters?.end_date;
  const plantingDate = sidebarPlanParameters?.transplant_date;
  const headRemovalDate = sidebarPlanParameters?.head_removal_date;
  const firstSettingDate = sidebarPlanParameters?.first_setting_date;
  const sowingDate = sidebarPlanParameters?.sowing_date;

  const { customSettings, updatedResetParametersObject } = useEditPlanCustomSettings({
    planData,
    resetParametersObject,
    sidebarPlanParameters,
  });

  const sideBarDisabled = !sidebarPlanParameters.custom_settings && !customSettings;

  useEffect(() => {
    setSidebarPlanParameters(updatedResetParametersObject);
    setCropSeason(getCropSeason(cropSeasons!, planData.cropseason?.id));
  }, [planData, cropSeasons]);

  const dispatch: any = useAppDispatch();
  const { editSidebar } = useAppSelector(plansSelector);
  const planId = useParams();

  const { data: varietiesOptions } = useGetVarietiesQuery({
    limit: 1000,
    segment: '',
  });

  const { data: rootstocksOptions } = useGetRootStocksQuery();

  const [getPlanByCropseason] = useGetPlanByCropSeasonIdMutation();

  const [updatePlan, { isSuccess: updateSuccess }] = useUpdatePlanMutation();
  const [createPlan] = useCreatePlanMutation();

  const modalCloseAction = (closeActionState: boolean) => {
    if (!closeActionState) {
      setCropSeason(getCropSeason(cropSeasons!, planData.cropseason?.id));
    }
  };

  function getUpdatedSideBarParameters() {
    let updatedPlanParameters = {} as any;

    updatedPlanParameters = getChangedPlanParams(initialPlanParameters, sidebarPlanParameters);

    updatedPlanParameters.variety_id = sidebarPlanParameters.variety.id;
    updatedPlanParameters.location_id = sidebarPlanParameters.location.id;
    updatedPlanParameters.rootstock_id = sidebarPlanParameters.rootstock?.id;

    updatedPlanParameters.custom_settings = {
      ...sidebarPlanParameters.custom_settings,
    };

    return formatParameterDates(updatedPlanParameters);
  }

  function getSaveAsParameters(parameters: any) {
    const { variety, location, ...rest } = parameters;

    const updatedPlanParameters = { ...rest };

    updatedPlanParameters.variety_id = variety.id;
    updatedPlanParameters.location_id = location.id;

    return formatParameterDates(updatedPlanParameters);
  }

  function formatParameterDates(parameters: any) {
    if (parameters.end_date) {
      parameters.end_date = formatTimestampToYearMonthDay(parameters.end_date);
    }

    if (parameters.head_removal_date) {
      parameters.head_removal_date = formatTimestampToYearMonthDay(parameters.head_removal_date);
    }

    if (parameters.transplant_date) {
      parameters.transplant_date = formatTimestampToYearMonthDay(parameters.transplant_date);
    }

    if (parameters.sowing_date) {
      parameters.sowing_date = formatTimestampToYearMonthDay(parameters.sowing_date);
    }

    if (parameters.first_setting_date) {
      parameters.first_setting_date = formatTimestampToYearMonthDay(parameters.first_setting_date);
    }

    //Do not save plan with sowing date both on parameters and custom settings
    //TODO Remove this line when the API does not sent sowing date on custom settings
    if (parameters.sowing_date && parameters.custom_settings.sowing_date) {
      delete parameters.custom_settings.sowing_date;
    }

    return {
      ...parameters,
    };
  }

  useEffect(() => {
    const cropSeasonId = cropSeason?.id;
    const planCropseasonId = planData.cropseason?.id;
    const cropSeasonPlantingDate = cropSeason?.transplant_date;

    const isPlantingDateDifferent =
      cropSeasonId !== planCropseasonId &&
      cropSeasonPlantingDate !== planPlantingDate &&
      cropSeason &&
      planData;

    if (cropSeasonId && cropSeasonId !== planCropseasonId) {
      getPlanByCropseason({ accountId: accountId, cropSeasonId: cropSeasonId! })
        .unwrap()
        .then((response: any) => {
          if (response) {
            ModalService.open({
              title: 'Change linked Crop Plan',
              saveText: 'Change',
              cancelText: 'Cancel',
              width: '495px',
              content: <UnLinkPlanToCropSeasonMessage name={cropSeason?.name} />,

              closeAction: (isConfirmed) => {
                modalCloseAction(isConfirmed);
              },
              confirmationAction: !!isPlantingDateDifferent,
              confirmActionDescription: translate(
                'Plan edit - link plan to cropseason warning body',
                selectedLanguage
              ),
              confirmationCancelAction: () => {
                setCropSeason(getCropSeason(cropSeasons!, planData.cropseason?.id));
              },
              confirmActionTitle: translate(
                'Plan edit - link plan to cropseason warning header',
                selectedLanguage
              ),
            });
          } else if (isPlantingDateDifferent) {
            ModalService.open({
              title: translate(
                'Plan edit - link plan to cropseason warning header',
                selectedLanguage
              ),
              saveText: translate(
                'Plan edit - link plan to cropseason warning - ok',
                selectedLanguage
              ),
              cancelText: translate('Plan edit - cancel', selectedLanguage),
              width: '495px',
              content: <LinkPlanToCropSeasonMessage />,

              closeAction: (isConfirmed) => {
                modalCloseAction(isConfirmed);
              },
            });
          }
        });
    }
  }, [cropSeason, cropSeasons]);

  useEffect(() => {
    if (updateSuccess) {
      refetchPlanData();
      refetchVariables();
      refetchTimeseries();
    }
  }, [updateSuccess]);

  if (!editSidebar) {
    return <></>;
  }

  function getCropSeason(list: CropSeason[], id: number | undefined) {
    if (!id) {
      return null;
    }
    return list?.find((option) => option.id === id);
  }

  const hasErrors = (customPlanValues: any) => {
    if (customPlanValues.EC && customPlanValues.accelerated_ripening?.starting_from) {
      if (
        ECValidation(customPlanValues.EC?.min, customPlanValues.EC?.max, selectedLanguage) ||
        ripeningDateValidation(
          dayjs(planHeadRemovalDate as string).unix(),
          customPlanValues.accelerated_ripening?.starting_from,
          dayjs(planHarvestDate as string).unix(),
          selectedLanguage
        )
      )
        return true;
    }

    if (temperatureStrategyValidation(customPlanValues.phase_temp_strategies)) return true;
    return false;
  };
  const validateFunction = () => {
    return !hasErrors(customPlanStateRef.current);
  };

  const handleOpenCustomSettings = () => {
    if (!sideBarDisabled && shouldOpenCustomSettings()) {
      ModalService.open({
        title: 'Custom plan settings',
        saveText: 'Apply',
        cancelText: 'Cancel',
        width: '640px',
        validateFunction,
        content: (
          <PlanCustomSettings
            plantingDate={dayjs(planPlantingDate).unix()}
            harvestDate={dayjs(planHarvestDate).unix()}
            headRemovalDate={dayjs(headRemovalDate).unix()}
            customPlanSettings={sidebarPlanParameters.custom_settings || customSettings}
            locationId={sidebarPlanParameters.location.id}
          />
        ),
        resetButton: true,
        confirmationAction: false,
        confirmActionTitle: 'Apply custom plan settings',
        confirmActionDescription:
          'By clicking on apply, all the values in this window will be used as input for the CropPlan.',
        resetAction: handleResetAction,
        closeAction: (isConfirmed) => {
          if (isConfirmed) {
            dispatch(
              setCustomPlan({
                lightsTouched: false,
              })
            );
            dispatch(setEditCustomSettings());
            setTrigger((previousValue) => ({ initiated: true, value: !previousValue.value }));
          } else {
            dispatch(setCustomPlan(sidebarPlanParameters.custom_settings || customSettings));
          }
        },
      });
    }
  };

  const onSowingChange = (newDate: DateRange | Dayjs) => {
    setSidebarPlanParameters({
      ...sidebarPlanParameters,
      sowing_date: dayjs(newDate as Dayjs).unix(),
    });
  };

  const onFirstSettingDateChange = (newDate: DateRange | Dayjs) => {
    setSidebarPlanParameters({
      ...sidebarPlanParameters,
      first_setting_date: dayjs(newDate as Dayjs).unix(),
    });
  };

  const handleResetAction = () => {
    dispatch(
      resetCustomPlan({
        plantingDate: dayjs(planPlantingDate).unix(),
        harvestDate: dayjs(planHarvestDate).unix(),
        customPlanSettings: initialCustomSettings,
        customSettings: customSettings,
      })
    );
  };

  const shouldOpenCustomSettings = () => {
    return (
      !sowingValidation(plantingDate, dayjs.unix(sowingDate).unix()) &&
      !headRemovalValidation(harvestDate, plantingDate, headRemovalDate) &&
      !harvestValidation(harvestDate, plantingDate, headRemovalDate)
    );
  };

  const getDateError = () => {
    const headRemovalError = headRemovalValidation(harvestDate, plantingDate, headRemovalDate);
    const harvestError = harvestValidation(harvestDate, plantingDate, headRemovalDate);
    const sowingError = sowingValidation(plantingDate, dayjs.unix(sowingDate).unix());
    const firstSettingError = firstSettingDateValidation(
      headRemovalDate,
      plantingDate,
      firstSettingDate
    );

    return headRemovalError || harvestError || sowingError || firstSettingError;
  };

  const saveAs = async (names: SidebarFileFolderNames) => {
    const createResponsePromise = createPlan({
      name: names.fileNameField,
      path: '/' + names.folderNameField,
      account_id: accountId,
      cropseason_id: cropSeason?.id,
      parameters: getSaveAsParameters(sidebarPlanParameters),
    }).unwrap();
    let createResponse;

    dispatchPromiseToast({
      type: TOAST_PROMISE,
      promise: createResponsePromise,
      messageLoading: translate('Toast - PlanCreation - loading - message', selectedLanguage),
      messageError: translate('Toast - PlanCreation - error - message', selectedLanguage),
      messageSuccess: translate('Toast - PlanCreation - success - message', selectedLanguage),
    });

    try {
      createResponse = await createResponsePromise;
    } catch (error) {
      console.error(error);
      return;
    }

    names = { fileNameField: '', folderNameField: '' };
    dispatch(setOption({ fileName: '', folderName: '' }));

    const createdPlanId = createResponse?.result?.new_plan?.id;

    if (createdPlanId) {
      navigate(`/plan/edit/${createdPlanId}`, {
        state: {
          id: createdPlanId,
        },
      });
    }
    dispatch(toggleEditSidebar());
  };

  return (
    <div className='sidebar-content'>
      <div className='sidebar-header'>
        <div className='sidebar-header-title'>
          {translate('Plan edit - header', selectedLanguage)}
        </div>
        <div
          className='close-directories-button c-hand'
          onClick={() => {
            dispatch(toggleEditSidebar());
          }}
        >
          <Close className='icon-light' />
        </div>
      </div>
      <div className='sidebar-crop-filters'>
        <DropDown
          initialValue={{
            name: sidebarPlanParameters.variety.name,
            id: sidebarPlanParameters.variety.id,
          }}
          optionList={varietiesOptions}
          name='crop-variety'
          setOnChange={(_, value) => {
            setSidebarPlanParameters({
              ...sidebarPlanParameters,
              variety: {
                name: value.name,
                id: value.id,
              },
            });
          }}
          dropDownTitle={translate('Plan edit - edit your crop variety', selectedLanguage)}
          requiredText=''
          required={false}
          displayProperty={'name'}
        />
        <DropDown
          initialValue={{ name: sidebarPlanParameters?.rootstock?.name || 'Not specified' }}
          optionList={
            rootstocksOptions
              ? [{ name: 'Not specified' }, ...rootstocksOptions]
              : [{ name: 'Not specified' }]
          }
          name='rootstock'
          setOnChange={(_, value) => {
            setSidebarPlanParameters({
              ...sidebarPlanParameters,
              rootstock: {
                id: value.id === 'Not specified' ? null : value.id,
                name: value.name === 'Not specified' ? null : value.name,
              },
            });
          }}
          dropDownTitle={translate('Plan edit - edit your rootstock', selectedLanguage)}
          requiredText=''
          required={false}
          displayProperty={'name'}
        />
      </div>
      <div className='sidebar-crop-location'>
        <DropDown
          initialValue={{ name: sidebarPlanParameters?.location.name }}
          optionList={locationsOptions}
          name='location'
          setOnChange={(_, value) => {
            setSidebarPlanParameters({
              ...sidebarPlanParameters,
              location: {
                id: value.id,
                name: value.name,
              },
            });
          }}
          dropDownTitle={translate('Plan edit - edit your location', selectedLanguage)}
          requiredText=''
          required={false}
          displayProperty={'name'}
        />
      </div>
      <div>
        <hr></hr>
      </div>
      <div className='sidebar-crop-date-filters'>
        <DatePicker
          startDate={
            sowingDate ? dayjs.unix(sowingDate) : dayjs.unix(plantingDate).subtract(80, 'days')
          }
          dropDownPosition='dropdown-datepicker-bottom dropdown-datepicker-middle dropdown-datepicker-fixed'
          labelPosition='label-top'
          label={translate('Custom Plan - sowing date - title')}
          insideTooltip={true}
          onChange={onSowingChange}
        />

        {sowingValidation(plantingDate, sowingDate) && (
          <span className='error-text'>{sowingValidation(plantingDate, sowingDate)}</span>
        )}

        <DatePicker
          dropDownPosition='dropdown-datepicker-bottom dropdown-datepicker-middle dropdown-datepicker-fixed'
          label={translate('Plan edit - edit your planting date', selectedLanguage)}
          startDate={plantingDate ? dayjs.unix(plantingDate) : dayjs()}
          onChange={(value) => {
            setSidebarPlanParameters({
              ...sidebarPlanParameters,
              transplant_date: dayjs(value as Dayjs).unix(),
            });
          }}
          insideTooltip={true}
        />

        <DatePicker
          startDate={
            firstSettingDate
              ? dayjs.unix(firstSettingDate)
              : dayjs.unix(plantingDate).add(23, 'days')
          }
          dropDownPosition='dropdown-datepicker-bottom dropdown-datepicker-middle dropdown-datepicker-fixed'
          labelPosition='label-top'
          label='First Setting Date'
          onChange={onFirstSettingDateChange}
          insideTooltip={true}
        />

        {firstSettingDate &&
          firstSettingDateValidation(headRemovalDate, plantingDate, firstSettingDate) && (
            <span className='error-text'>
              {firstSettingDateValidation(headRemovalDate, plantingDate, firstSettingDate)}
            </span>
          )}
        <DatePicker
          dropDownPosition='dropdown-datepicker-top dropdown-datepicker-middle dropdown-datepicker-fixed'
          label={translate('Plan edit - edit your planned head removal', selectedLanguage)}
          startDate={
            headRemovalDate ? dayjs.unix(headRemovalDate) : dayjs.unix(plantingDate).add(40, 'week')
          }
          onChange={(value) => {
            setSidebarPlanParameters({
              ...sidebarPlanParameters,
              head_removal_date: dayjs(value as Dayjs).unix(),
            });
          }}
          insideTooltip={true}
        />
        {headRemovalValidation(harvestDate, plantingDate, headRemovalDate) && (
          <span className='error-text'>
            {headRemovalValidation(harvestDate, plantingDate, headRemovalDate)}
          </span>
        )}
        <DatePicker
          dropDownPosition='dropdown-datepicker-top dropdown-datepicker-middle dropdown-datepicker-fixed'
          label={translate('Plan edit - edit your last harvest date', selectedLanguage)}
          startDate={
            harvestDate ? dayjs.unix(harvestDate) : dayjs.unix(plantingDate).add(50, 'week')
          }
          onChange={(value) => {
            setSidebarPlanParameters({
              ...sidebarPlanParameters,
              end_date: dayjs(value as Dayjs).unix(),
            });
          }}
          insideTooltip={true}
        />
        {harvestValidation(harvestDate, plantingDate, headRemovalDate) && (
          <span className='error-text'>
            {harvestValidation(harvestDate, plantingDate, headRemovalDate)}
          </span>
        )}
      </div>
      <div>
        <hr />
      </div>
      <div className='custom-plan-settings-container c-hand' onClick={handleOpenCustomSettings}>
        <EditIcon className='icon-light custom-plan-settings-icon' />
        <span className='custom-plan-settings-text'>Custom plan settings</span>
      </div>
      <div className='crop-season'>
        <DropUp
          optionList={cropSeasons || []}
          displayProperty={'name'}
          initialValue={cropSeason}
          setOnChange={setCropSeason}
          initialText={'Link to cropseason'}
          hasTooltip
        />
      </div>
      <div className='sidebar-buttons-container'>
        <div
          className='sidebar-cancel-button btn'
          onClick={() => {
            track(`plan revert changes`, {
              changes: { ...sidebarPlanParameters },
              initialData: {
                ...resetParametersObject,
              },
            });
            setCropSeason(getCropSeason(cropSeasons || [], planData.cropseason?.id));
            setSidebarPlanParameters(resetParametersObject);
            dispatch(toggleEditSidebar());
          }}
        >
          {translate('Plan edit - cancel', selectedLanguage)}
        </div>
        <div className='sidebar-save-drop-button'>
          <DropButton
            isDropUp={true}
            actions={[
              {
                label: translate('Plan edit - save', selectedLanguage),
                action: () => {
                  const dateError = getDateError();
                  if (dateError) {
                    dispatchToast({ type: TOAST_ERROR, message: dateError });
                    return;
                  }

                  track(`plan update`, {
                    newData: {
                      cropseason_id: cropSeason?.id,
                      parameters: sidebarPlanParameters,
                    },
                    oldData: { ...planData.parameters },
                  });

                  const updatePlanPromisse = updatePlan({
                    id: Number(planId.id),
                    data: {
                      cropseason_id: cropSeason?.id,
                      parameters: getUpdatedSideBarParameters(),
                    },
                  });

                  dispatchPromiseToast({
                    type: TOAST_PROMISE,
                    promise: updatePlanPromisse,
                    messageLoading: translate(
                      'Toast - PlanEdit - loading- message',
                      selectedLanguage
                    ),
                    messageError: translate(
                      'Toast - PlanEdit - errror - message',
                      selectedLanguage
                    ),
                    messageSuccess: translate(
                      'Toast - PlanEdit - success- message',
                      selectedLanguage
                    ),
                  });
                  dispatch(toggleEditSidebar());
                },
              },
              {
                label: translate('Plan edit - save as', selectedLanguage),
                action: () => {
                  const dateError = getDateError();
                  if (dateError) {
                    dispatchToast({ type: TOAST_ERROR, message: dateError });
                    return;
                  }

                  let names: SidebarFileFolderNames = { fileNameField: '', folderNameField: '' };
                  const validateFunction = () => {
                    return !!(
                      !checkFileName(
                        names.fileNameField,
                        MIN_LENGTH_PLAN_NAME,
                        MAX_LENGTH_PLAN_NAME
                      ) && names.folderNameField
                    );
                  };

                  ModalService.open({
                    title: 'Save as plan',
                    saveText: 'Save as',
                    cancelText: 'Cancel',
                    width: '495px',
                    validateFunction: validateFunction,
                    content: <SavePlanAsContent names={names} />,
                    closeAction: async (isConfirmed) => {
                      if (isConfirmed) {
                        track(`plan save as`, {
                          plan: {
                            cropseason_id: cropSeason?.id,
                            parameters: sidebarPlanParameters,
                          },
                        });
                        await saveAs(names);
                      }
                      ModalService.close();
                    },
                  });
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
export default EditPlanSidebar;
