import React, { useEffect } from 'react';
import styles from './Notification.module.scss';
import { ReactComponent as NotificationIcon } from '../../../styles/icons/notification.svg';

import { Range } from 'interfaces/range';
import useGraphNotifications from 'views/Analyze/hooks/useGraphNotifications';

import { isInRange } from 'utils/time.util';
import { DESKTOP_GRAPH_PADDING, MOBILE_GRAPH_PADDING } from 'config/constants';
import * as d3 from 'd3';
import { CropseasonNotification } from 'interfaces/cropseason-notification';
import dayjs from 'dayjs';

interface NotificationProps {
  width?: number;
  height?: number;
  xScale?: any;
  yScale?: any;
  currentTimeRange: Range;
  groupedNotificationsCallback?: (input: any) => void;
  notificationsData: CropseasonNotification[];
  isMobile?: boolean;
}

const Notification: React.FC<NotificationProps> = ({
  xScale,
  currentTimeRange,
  notificationsData,
  width,
  height = 440,
  groupedNotificationsCallback = () => {},
  isMobile = false,
}) => {
  const notificationIconWidth = 20;
  const notificationIconHeight = 16;
  const yPosition = isMobile ? height + 23 : height - 7;

  const { getGroupedNotifications, renderNumber } = useGraphNotifications(
    notificationsData,
    width || 0,
    currentTimeRange
  );

  let groupedNotifications = getGroupedNotifications();

  useEffect(() => {
    groupedNotificationsCallback(width);
  }, [width, groupedNotificationsCallback]);

  const showTimestampLine = (x: number) => {
    d3.select('.notificationIconTimestampLine').attr('x1', x).attr('x2', x).attr('opacity', 1);
  };

  const hideTimestampLine = () => {
    d3.select('.notificationIconTimestampLine').attr('opacity', 0);
  };

  const AXIS_OFFSET = isMobile ? MOBILE_GRAPH_PADDING : DESKTOP_GRAPH_PADDING;

  const showGridLine = (timestamp: number) => {
    showTimestampLine(xScale(timestamp));
  };
  return (
    <g className={`${styles['notification']}`}>
      {Object.keys(groupedNotifications)
        .reverse()
        .map((timestamp) => Number(timestamp))
        .filter((timestamp) => {
          return isInRange(timestamp, currentTimeRange);
        })
        .map((timestamp, index) => {
          let notificationNumber = groupedNotifications[timestamp]?.length;
          let xPosition =
            AXIS_OFFSET +
            xScale(dayjs.unix(timestamp).utc(true).unix()) -
            notificationIconWidth / 2;

          return (
            <g
              className={`${styles['notification-icon']}`}
              transform={`translate(${xPosition}, ${yPosition} )`}
              data-tooltip-id={`notification-icon-${timestamp}-${index}`}
              key={`notification-icon-${timestamp}-${index}`}
            >
              <NotificationIcon
                width={notificationIconWidth}
                height={notificationIconHeight}
                onMouseOver={(event) => showGridLine(dayjs.unix(timestamp).utc(true).unix())}
                onMouseOut={hideTimestampLine}
              />
              {notificationNumber > 1 && renderNumber(notificationNumber)}
            </g>
          );
        })}
    </g>
  );
};

export default Notification;
