import React, { ChangeEvent, ReactElement, useState } from 'react';

import styles from './LegendGraphDataToggleSwitches.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setToggle, trackDataTogglesSelector } from 'redux/slices/graphDataToggleSwitches.slice';
import { Tooltip } from 'react-tooltip';
import { getUniqueId } from 'utils/getUniqueId';
import { LegendItem } from 'interfaces/legend-item';
import chroma from 'chroma-js';
import { ReactComponent as Info } from 'styles/icons/info.svg';
import { BENCHMARK_RIBBON_COLOR } from 'config/constants';

const CHECK_TICK_DARK = '#576776';
const CHECK_TICK_LIGHT = '#fff';
const CHECK_DISABLED_COLOR = '#8293a6';

const getTooltipText = (noAvailableText: string) => {
  return (
    <div>
      No {noAvailableText} available <br /> for this variable
    </div>
  );
};

export const renderTooltipDisabled = (noAvailableText: string, tooltipAnchor: string) => {
  return (
    <div className='info-icon'>
      <Tooltip
        data-html={true}
        anchorSelect={`#${tooltipAnchor}`}
        place='top'
        positionStrategy='fixed'
        className='planGraph-tooltip'
        style={{ backgroundColor: '#fff', color: '#000' }}
      >
        {getTooltipText(noAvailableText)}
      </Tooltip>
    </div>
  );
};

interface LegendGraphDataToggleSwitchesProps {
  legendItems: LegendItem[];
  showFullItem?: boolean;
}

const LegendGraphDataToggleSwitches: React.FC<LegendGraphDataToggleSwitchesProps> = ({
  legendItems,
  showFullItem = false,
}) => {
  const toggles = useAppSelector(trackDataTogglesSelector);
  const dispatch = useAppDispatch();

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    input: string,
    disabled?: boolean
  ) => {
    const checked = event.target.checked;
    if (!disabled) {
      dispatch(setToggle({ [input]: checked }));
    }
  };

  const getCustomClassBasedOnName = (input: string) => {
    switch (input) {
      case 'actuals':
        return styles.actuals;
      case '#fff':
        return styles.actuals;
      case 'guardRails':
        return styles.guardRails;
      case 'plan':
        return styles.plan;
      case 'adaptivePlan':
        return styles.adaptivePlan;
      case BENCHMARK_RIBBON_COLOR:
        return styles.ribbon;
      default:
        return styles.genericLines;
    }
  };

  const handleClickOntext = (input: string) => {
    dispatch(setToggle({ [input]: !toggles[input] }));
  };

  const getStringForTooltip = (input: string) => {
    switch (input) {
      case 'actuals':
        return 'Actuals';
      case 'guardRails':
        return 'Guardrails';
      case 'plan':
        return 'Plan';
      case 'adaptivePlan':
        return 'Adaptive Plan';
      case BENCHMARK_RIBBON_COLOR:
        return 'ribbon';
      default:
        return 'Generic';
    }
  };

  return (
    <div className={styles.container}>
      {legendItems.map((item) => {
        const uniqueId = getUniqueId();
        const lightness = chroma(item.color).luminance();
        const ckeckColor = lightness > 0.5 ? CHECK_TICK_DARK : CHECK_TICK_LIGHT;
        const isChecked = toggles[item.stateName] !== false;

        let color;
        if (item.disabled || !isChecked) {
          color = CHECK_DISABLED_COLOR;
        } else {
          color = item.color;
        }

        return (
          <div
            className={`${styles['legend-item']} ${
              !item.disabled && getCustomClassBasedOnName(item.stateName)
            } 
           ${item.disabled && styles['legend-item-disabled']}
            ${showFullItem ? styles['text-full'] : ''}
          `}
            key={uniqueId}
          >
            {item.disabled && renderTooltipDisabled(getStringForTooltip(item.stateName), uniqueId)}
            <label className={`form-checkbox c-hand `}>
              <input
                type='checkbox'
                checked={toggles[item.stateName] !== false}
                onChange={(event) => {
                  handleOnChange(event, item.stateName, item.disabled);
                }}
                className={styles['legend-input']}
                id={`${uniqueId}`}
              />
              <i className={'form-icon'} style={{ backgroundColor: color, borderColor: color }}>
                <span
                  className={styles['before-element']}
                  style={{ borderColor: ckeckColor }}
                ></span>
              </i>
              <span
                className={`${styles.text} c-hand ${
                  toggles[item.stateName] === false && styles['text-off']
                } `}
                onClick={() => {
                  handleClickOntext(item.stateName);
                }}
              >
                <span className={styles['text-title']}>{item.title && item.title}</span>
                <span className={styles['text-label']}> {item.label}</span>
              </span>
              {item.customContent ||
                (item.cropSummary && (
                  <div className={'info-icon'}>
                    <Info
                      width={20}
                      className='icon-light c-hand'
                      id={`tooltip-anchor${uniqueId}`}
                      data-tooltip-variant='light'
                    />
                    <Tooltip
                      anchorSelect={`#tooltip-anchor${uniqueId}`}
                      place='right'
                      positionStrategy='fixed'
                      opacity={1}
                      style={{ zIndex: 10, height: 'auto' }}
                    >
                      <div>Name: {item.cropSummary.name}</div>
                      <div>Variety: {item.cropSummary.variety}</div>
                      <div>Planting date: {item.cropSummary.plantingDate.format('DD-MM-YYYY')}</div>
                      <div>Location: {item.cropSummary.location}</div>
                    </Tooltip>
                  </div>
                ))}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default LegendGraphDataToggleSwitches;
