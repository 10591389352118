export const normalize = (
  array: number[],
  maximum: number = 0
): { array: number[]; maximum: number } => {
  if (!array || !array.length) {
    return { array: [], maximum: 0 };
  }

  let localMaximum = array.reduce((max, value) => Math.max(max, Math.abs(value)), 0);

  let scaling = maximum || localMaximum;

  if (scaling) {
    return {
      array: array.map((element) => {
        if (!element) {
          return element;
        }
        return element / scaling;
      }),
      maximum: localMaximum,
    };
  } else {
    return {
      array: array.filter((item) => item !== null),
      maximum: 0,
    };
  }
};
