import React from 'react';
import dayjs from 'dayjs';

import { ReactComponent as Link } from 'styles/icons/link-2.svg';

import styles from './PlanFileCardContent.module.scss';
import { Plan } from 'services/plans.service';

interface PlanFileCardContentProps {
  plan: Plan;
}

const PlanFileCardContent: React.FC<PlanFileCardContentProps> = ({ plan }) => {
  return (
    <>
      <td className={styles.location}>{plan.location.name}</td>
      <td className={styles.variety}>{plan.variety.name}</td>
      <td className={styles['crop-season']}>
        <div className={styles.icon}>
          <Link className='icon-small' />
        </div>
        <p>{plan.cropseason?.name}</p>
      </td>
      <td className={styles.modified}>
        {dayjs(plan.last_modified_timestamp).format('DD MMM YYYY')}
      </td>
    </>
  );
};

export default PlanFileCardContent;
