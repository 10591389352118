import React, { Fragment } from 'react';

interface BarProps {
  xScale?: any;
  yScale?: any;
  data: any;
  transform?: string;
  xProperty: string;
  yProperty: string;
  height?: number;
}

const Bar: React.FC<BarProps> = ({ xScale, yScale, data, xProperty, yProperty, height }) => {
  return (
    <>
      <defs>
        <linearGradient id={`default`} x1='0' y1='0' x2='0' y2='2'>
          <stop offset='0' stopColor={'rgba(227, 227, 227, 0.4)'} stopOpacity={1} />
          <stop offset='45%' stopColor={'rgba(225, 225, 225, 0.06)'} stopOpacity={0.8} />
        </linearGradient>
      </defs>
      {data?.map((d: any, index: number) => {
        return (
          <Fragment key={index}>
            {d.colors && (
              <defs>
                <linearGradient id={`color`} x1='0' y1='0' x2='0' y2='2'>
                  <stop offset='0' stopColor={d?.colors?.startColor} stopOpacity={1} />
                  <stop offset='45%' stopColor={d?.colors?.stopColor} stopOpacity={0.8} />
                </linearGradient>
              </defs>
            )}
            <rect
              key={index}
              x={xScale(String(d[xProperty]))}
              y={yScale(d[yProperty])}
              width={xScale.bandwidth() - 5}
              height={(height || 0) - yScale(d[yProperty]) || 0}
              fill={`${d.colors ? 'url(#color)' : 'url(#default)'} `}
            />
          </Fragment>
        );
      })}
    </>
  );
};
export default Bar;
