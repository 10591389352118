import { dataSorter } from './../utils/data-sorter';

import { CropSeason, CropSeasonVariable } from 'interfaces/crop-season';

import { formatQueryString } from 'utils/queryString';
import { baseApi } from 'redux/slices/baseApi.slice';

const ENDPOINT = `cropseasons?`;

interface CropseasonRawResult {
  message: string;
  result: {
    cropseason: CropSeason;
  };
}

const cropseasonApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCropseasons: builder.query<CropSeason[], number>({
      query: (accountId: number) => {
        return (
          ENDPOINT +
          formatQueryString({
            skip: 0,
            limit: 500,
            current_account_id: accountId,
          })
        );
      },
      transformResponse: (rawResult: any): CropSeason[] => {
        return dataSorter(rawResult.result.cropseasons, 'desc', 'name');
      },
    }),
    getCropseason: builder.query<any, number>({
      query: (cropSeasonId) => `cropseasons/${cropSeasonId}`,
      transformResponse: (rawResult: CropseasonRawResult): CropSeason => {
        return rawResult.result.cropseason;
      },
    }),
    getVariables: builder.query<CropSeasonVariable[], number>({
      query: (id: number) => {
        const ENDPOINT = `cropseasons/variables?resolution=1_week&current_account_id=${id}`;
        return ENDPOINT;
      },
      transformResponse: (rawResult: any): CropSeasonVariable[] => {
        return rawResult.result.variables;
      },
    }),
  }),
});

const enhancedCropseasonApi = cropseasonApi.enhanceEndpoints({
  addTagTypes: ['Cropseason'],
  endpoints: {
    getCropseasons: {
      providesTags: ['Cropseason'],
    },
  },
});

export const { useGetCropseasonQuery, useGetCropseasonsQuery, useGetVariablesQuery } =
  enhancedCropseasonApi;
