export type ButtonSize = 'small' | 'medium';
export type ButtonVariant = 'primary' | 'secondary' | 'hollow';

export enum ButtonSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export enum ButtonVariantEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  HOLLOW = 'hollow',
}
