import React from 'react';

import Modal from 'components/Modal/Modal';
import Navbar from 'components/Navbar/Navbar';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';
import Sidebar from 'components/Sidebar/Sidebar';
import SidebarMobile from 'components/SidebarMobile/SidebarMobile';

import { BREAKPOINT_LAPTOP } from 'config/constants';
import { Outlet } from 'react-router-dom';
import useSetLocationUrl from 'hooks/useSetLocationUrl';
import { usePageViews } from 'hooks/usePageView';
import useSwitchAccountId from 'hooks/useSwitchAccountId';
import { useSetCropseason } from 'hooks/useSetCropSeason';

const RootLayout: React.FC = () => {
  const windowSize: WindowSize = useWindowSize();
  useSetLocationUrl();

  usePageViews();

  useSwitchAccountId();

  useSetCropseason();

  function renderSidebar() {
    if (windowSize.width > BREAKPOINT_LAPTOP) {
      return <Sidebar />;
    } else {
      return <SidebarMobile />;
    }
  }

  return (
    <div className={`app-container dark-theme`}>
      <Modal />
      <div className='header-container'>
        <Navbar />
      </div>
      <div className='sidebar-container'>{renderSidebar()}</div>
      <div className='main-container'>
        <Outlet />
      </div>
    </div>
  );
};
export default RootLayout;
