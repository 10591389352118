import React from 'react';

import { ReactComponent as Export } from 'styles/icons/file-export.svg';

import { mapGraphPlanDataToTableFormat, mapTimeseriesDataToArray } from 'utils/plans.util';
import useTranslate from 'hooks/useTranslate';

import styles from './ExportTableContent.module.scss';
import { createCsvFile } from 'utils/file-utils/createCSVFile';
import { getVariableName } from 'utils/plan-variables-mapper';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';

interface ExportTableContentProps {
  timeseriesData?: any;
  planName: string;
}

const ExportTableContent: React.FC<ExportTableContentProps> = ({ timeseriesData, planName }) => {
  const timeseriesArray = mapTimeseriesDataToArray(timeseriesData);
  const [tableData, tableHeadersArray] = mapGraphPlanDataToTableFormat(timeseriesArray);
  const { selectedLanguage } = useAppSelector(languageSelector);

  function createCSVContent() {
    let CSVContent = `Calendar Week,${tableHeadersArray
      .map((header: any) => getVariableName(header.id, selectedLanguage))
      .join()}${tableData.map(
      (row: any) =>
        `\n${row
          .map(
            (rowData: any, index: number) =>
              `${index === 0 ? `${rowData.dateTimeWeek},` : ''}  ${rowData.value || '-'}`
          )
          .join()}`
    )}\n`;

    return CSVContent;
  }

  return (
    <div
      className={`${styles['download-container']} c-hand`}
      onClick={() => createCsvFile({ fileName: planName, content: createCSVContent() })}
    >
      <span>Export table</span>
      <Export className='icon-primary' />
    </div>
  );
};
export default ExportTableContent;
