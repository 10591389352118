import NOTIFICATION_INFO from 'config/notifications/notifications.json';

export const getSettingsTranslationKey = (input: string) => {
  if (!input) {
    return '';
  }
  const NOTIFICATIONS = JSON.parse(JSON.stringify(NOTIFICATION_INFO));
  const key = NOTIFICATIONS[input]?.settings_text;

  if (!key) {
    return input;
  }

  return key;
};
