import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import translations from 'utils/translations.util';

export default function useTranslate() {
  const { selectedLanguage } = useAppSelector(languageSelector);
  let language = translations[selectedLanguage];

  return (input: string) => {
    if (!language) {
      language = translations['en'];
    }

    if (!(input in language)) {
      return input;
    }

    return language[input];
  };
}
