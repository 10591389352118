import './EditPlansGraphsContent.scss';

import React from 'react';
import MultilineChart from 'components/GraphElements/MultilineChart/MultilineChart';
import Line from 'components/GraphElements/Line/Line';
import { getNumberTicks } from 'utils/d3-utils/getTicks';
import { getNumberScale } from 'utils/d3-utils/getScale';
import TitleTooltip from 'components/GraphElements/TitleTooltip/TitleTooltip';
import { roundToDecimals } from 'utils/roundToDecimals';
import { weekFromTimestamp, weekFromTimestampNoLabel } from 'utils/d3-utils/tooltip-formatter';
import { useParams } from 'react-router-dom';
import { useGetUnitsForEditPlan } from 'hooks/useGetUnitsForEditPlan';
import useTranslate from 'hooks/useTranslate';
import {
  EDIT_PLAN_LEGENDS,
  PLAN_VARIABLES_OLD_NAME_ID_MAPPING,
  RESOLUTION,
} from 'config/constants';
import { getVariableXInterval } from 'utils/variables.util';

interface EditPlansGraphsContentProps {
  timeseriesData: any;
  expectedYield: number;
  planName: string;
}

const EditPlansGraphsContent: React.FC<EditPlansGraphsContentProps> = ({
  timeseriesData,
  expectedYield,
}) => {
  let { id } = useParams();
  const getUnit = useGetUnitsForEditPlan;
  const translate = useTranslate();

  return (
    <>
      <div className='edit-plans-expected-yield graph-content-header'>
        <div className='edit-plans-expected-yield-text'>
          <span className='yield-text'>{translate('Plan graph view - expected yield')}</span>
          <span className='yield-text emphasised'>
            {roundToDecimals(expectedYield, 2)} {translate('kg/m^2')}
          </span>
        </div>
      </div>
      <div className='plan-graphs-container'>
        <div className='edit-plan-graph-container edit-plan-input-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.RADCJM,
                  color: '#00FFD1',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.RADCJM],
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.RADCJM),
                },
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.OUTEMP,
                  color: '#CB36FF',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.OUTEMP],
                  axis: 'right',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.OUTEMP),
                },
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHTEMP,
                  color: '#CB36FF',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHTEMP],
                  axis: 'right',
                  strokeWidth: 2,
                  stroke: '3 4',
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHTEMP),
                },
              ]}
              title='Climate'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.CLIMATE}
              YInterval={[timeseriesData?.ranges[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.RADCJM]]}
              XInterval={getVariableXInterval(
                timeseriesData,
                PLAN_VARIABLES_OLD_NAME_ID_MAPPING.RADCJM
              )}
              rightAxisYInterval={[
                timeseriesData?.ranges?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.OUTEMP],
                timeseriesData?.ranges?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHTEMP],
              ]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              titleTooltip={<TitleTooltip text='Input' />}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
              showTitle={false}
            >
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.RADCJM]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING.RADCJM}
                color='#00FFD1'
              />
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.OUTEMP]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING.OUTEMP}
                axis={'right'}
                color='#CB36FF'
              />
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHTEMP]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHTEMP}
                axis={'right'}
                color='#CB36FF'
                stroke='3 4'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='edit-plan-graph-container edit-plan-input-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHCO2C,
                  color: '#fff',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHCO2C],
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHCO2C),
                },
              ]}
              title='CO2'
              xAxisProperty='timestamp'
              YInterval={[timeseriesData?.ranges[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHCO2C]]}
              XInterval={getVariableXInterval(
                timeseriesData,
                PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHCO2C
              )}
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legend={EDIT_PLAN_LEGENDS.CO2}
              legendFontSize={'12px'}
              titleTooltip={<TitleTooltip text='Input' />}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
              showTitle={false}
            >
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHCO2C]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHCO2C}
                color='#fff'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='edit-plan-graph-container edit-plan-input-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.density.setting'],
                  color: '#fff',
                  data: timeseriesData?.[
                    PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.density.setting']
                  ],
                  strokeWidth: 2,
                  axis: 'left',
                  unit: getUnit(
                    Number(id),
                    PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.density.setting']
                  ),
                },
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.pruning,
                  color: '#CB36FF',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.pruning],
                  strokeWidth: 2,
                  axis: 'left',
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.pruning),
                },
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI,
                  color: '#36DBFF',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI],
                  strokeWidth: 2,
                  axis: 'left',
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI),
                },
              ]}
              title='Plant Status'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.PLANT_STATUS}
              YInterval={[
                timeseriesData?.ranges[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.density.setting']],
                timeseriesData?.ranges[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.pruning],
                timeseriesData?.ranges?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI],
              ]}
              XInterval={getVariableXInterval(
                timeseriesData,
                PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.density.setting']
              )}
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              titleTooltip={<TitleTooltip text='Input' />}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
              showTitle={false}
            >
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.density.setting']]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.density.setting']}
                color='#fff'
              />
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.pruning]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING.pruning}
                color='#CB36FF'
              />
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI}
                color='#36DBFF'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='edit-plan-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['harvested.fruits.m2.wk'],
                  color: '#00FFD1',
                  data: timeseriesData?.[
                    PLAN_VARIABLES_OLD_NAME_ID_MAPPING['harvested.fruits.m2.wk']
                  ],
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(
                    Number(id),
                    PLAN_VARIABLES_OLD_NAME_ID_MAPPING['harvested.fruits.m2.wk']
                  ),
                },
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.harvestMaturity,
                  color: '#CB36FF',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.harvestMaturity],
                  axis: 'right',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.harvestMaturity),
                },
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['setting.fruits.m2.wk'],
                  color: '#fff',
                  data: timeseriesData?.[
                    PLAN_VARIABLES_OLD_NAME_ID_MAPPING['setting.fruits.m2.wk']
                  ],
                  axis: 'left',
                  strokeWidth: 2,
                  stroke: '3 4',
                  unit: getUnit(
                    Number(id),
                    PLAN_VARIABLES_OLD_NAME_ID_MAPPING['setting.fruits.m2.wk']
                  ),
                },
              ]}
              title='Maturity'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.MATURITY}
              YInterval={[
                timeseriesData?.ranges[
                  PLAN_VARIABLES_OLD_NAME_ID_MAPPING['harvested.fruits.m2.wk']
                ],
                timeseriesData?.ranges?.[
                  PLAN_VARIABLES_OLD_NAME_ID_MAPPING['setting.fruits.m2.wk']
                ],
              ]}
              XInterval={getVariableXInterval(
                timeseriesData,
                PLAN_VARIABLES_OLD_NAME_ID_MAPPING.harvestMaturity
              )}
              rightAxisYInterval={[
                timeseriesData?.ranges?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.harvestMaturity],
              ]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
              showTitle={false}
            >
              <Line
                data={
                  timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['harvested.fruits.m2.wk']]
                }
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING['harvested.fruits.m2.wk']}
                color='#00FFD1'
              />
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.harvestMaturity]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING.harvestMaturity}
                axis={'right'}
                color='#CB36FF'
              />
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['setting.fruits.m2.wk']]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING['setting.fruits.m2.wk']}
                axis={'left'}
                color='#00FFD1'
                stroke='3 4'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='edit-plan-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.diam'],
                  color: '#00FFD1',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.diam']],
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.diam']),
                },
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['plantload.fruits.m2.calc'],
                  color: '#CB36FF',
                  data: timeseriesData?.[
                    PLAN_VARIABLES_OLD_NAME_ID_MAPPING['plantload.fruits.m2.calc']
                  ],
                  axis: 'right',
                  strokeWidth: 2,
                  unit: getUnit(
                    Number(id),
                    PLAN_VARIABLES_OLD_NAME_ID_MAPPING['plantload.fruits.m2.calc']
                  ),
                },
              ]}
              title='Plant load'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.PLANT_LOAD}
              YInterval={[timeseriesData?.ranges[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.diam']]]}
              XInterval={getVariableXInterval(
                timeseriesData,
                PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.diam']
              )}
              rightAxisYInterval={[
                timeseriesData?.ranges?.[
                  PLAN_VARIABLES_OLD_NAME_ID_MAPPING['plantload.fruits.m2.calc']
                ],
              ]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
              showTitle={false}
            >
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.diam']]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.diam']}
                color='#00FFD1'
              />
              <Line
                data={
                  timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['plantload.fruits.m2.calc']]
                }
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING['plantload.fruits.m2.calc']}
                axis={'right'}
                color='#CB36FF'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='edit-plan-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.growthRate,
                  color: '#00FFD1',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.growthRate],
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.growthRate),
                },
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem load cell raw'],
                  color: '#CB36FF',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem load cell raw']],
                  axis: 'right',
                  strokeWidth: 2,
                  unit: getUnit(
                    Number(id),
                    PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem load cell raw']
                  ),
                },
              ]}
              title='Fresh Weight Gain'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.FRESH_WEIGHT_GAIN}
              YInterval={[timeseriesData?.ranges[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.growthRate]]}
              XInterval={getVariableXInterval(
                timeseriesData,
                PLAN_VARIABLES_OLD_NAME_ID_MAPPING.growthRate
              )}
              rightAxisYInterval={[
                timeseriesData?.ranges?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem load cell raw']],
              ]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
              showTitle={false}
            >
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.growthRate]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING.growthRate}
                color='#00FFD1'
              />
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem load cell raw']]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem load cell raw']}
                axis={'right'}
                color='#CB36FF'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='edit-plan-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.yield,
                  color: '#00FFD1',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.yield],
                  strokeWidth: 2,
                  axis: 'left',
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.yield),
                },
                {
                  axis: 'right',
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield.cu'],
                  color: '#CB36FF',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield.cu']],
                  strokeWidth: 2,
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield.cu']),
                },
              ]}
              title='Weekly yield'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.WEEKLY_YIELD}
              YInterval={[timeseriesData?.ranges[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield']]]}
              XInterval={getVariableXInterval(
                timeseriesData,
                PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield']
              )}
              rightAxisYInterval={[
                timeseriesData?.ranges?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield.cu']],
              ]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
              showTitle={false}
            >
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield']]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield']}
                color='#00FFD1'
              />
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield.cu']]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield.cu']}
                axis={'right'}
                color='#CB36FF'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='edit-plan-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.brix,
                  color: '#00FFD1',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.brix],
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.brix),
                },
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.afw,
                  color: '#CB36FF',
                  data: timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.afw],
                  axis: 'right',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.afw),
                },
              ]}
              title='Brix'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.BRIX}
              YInterval={[timeseriesData?.ranges[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.brix]]}
              XInterval={getVariableXInterval(
                timeseriesData,
                PLAN_VARIABLES_OLD_NAME_ID_MAPPING.brix
              )}
              rightAxisYInterval={[
                timeseriesData?.ranges?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.afw],
              ]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
              showTitle={false}
            >
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.brix]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING.brix}
                color='#00FFD1'
              />
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.afw]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING.afw}
                axis={'right'}
                color='#CB36FF'
              />
            </MultilineChart>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditPlansGraphsContent;
