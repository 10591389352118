import NOTIFICATION_INFO from 'config/notifications/notifications.json';
import dayjs from 'dayjs';
import {
  CropseasonNotification,
  Notification,
  MissingDataNotification,
  MissingDataNotificationValue,
} from 'interfaces/cropseason-notification';
import { replaceFirstTwoMatches } from 'utils/general.util';
import { getNotificationValue } from 'utils/notification-value-mapper';
import { roundToDecimals } from 'utils/roundToDecimals';
import { isToday, isYesterday } from 'utils/time.util';
import { translate } from 'utils/translations.util';

export const NOTIFICATION_INFO_TITLE_REGEX_VALUE = /\${value}/g;
export const NOTIFICATION_INFO_TITLE_REGEX_THRESHOLD = /\${threshold}/g;
export const NOTIFICATION_INFO_TITLE_REGEX_EXCEDED_VARIABLES = /\${variables}/g;

interface UseGetNotificationItemDataResult {
  getFormatedTimestamp(timestamp: string): string;
  getNotificationInfo(notification: Notification): string;
}

const geNotificationInfoValue = (
  notification: CropseasonNotification,
  translatedTitleName: string
) => {
  let rawValue = getNotificationValue(notification);
  let value = roundToDecimals(rawValue, 0).toString();
  let unit = translate(notification.values.unit);

  if (value) {
    translatedTitleName = translatedTitleName.replace(
      NOTIFICATION_INFO_TITLE_REGEX_VALUE,
      value + unit
    );
  }

  return translatedTitleName;
};

const geNotificationInfoExcededVariables = (
  notification: CropseasonNotification,
  translatedTitleName: string
) => {
  let exceededVars = notification.values.exceeded_vars
    ?.map((varName) => translate(varName))
    .join(', ');

  if (exceededVars) {
    translatedTitleName = translatedTitleName.replace(
      NOTIFICATION_INFO_TITLE_REGEX_EXCEDED_VARIABLES,
      exceededVars
    );
  }

  return translatedTitleName;
};

const getNotificationInfoTreshold = (
  notification: CropseasonNotification,
  translatedTitleName: string,
  thresholdPlaceholders: number
) => {
  let notificationValues = notification.values;
  let valueTresholdLow = roundToDecimals(notificationValues?.threshold_low, 0).toString();
  let valueTresholdHigh = roundToDecimals(notificationValues?.threshold_high, 0).toString();
  let valueTreshold = roundToDecimals(notificationValues?.threshold, 0).toString();
  // let unit = translate(notificationValues.unit);

  if (thresholdPlaceholders === 1 && valueTreshold) {
    return translatedTitleName.replace(
      NOTIFICATION_INFO_TITLE_REGEX_THRESHOLD,
      valueTreshold
    );
  } else if (thresholdPlaceholders === 2 && valueTresholdLow && valueTresholdHigh) {
    translatedTitleName = replaceFirstTwoMatches(
      translatedTitleName,
      NOTIFICATION_INFO_TITLE_REGEX_THRESHOLD,
      valueTresholdLow,
      valueTresholdHigh
    );
  }

  return translatedTitleName;
};

const isNotificationTypeMissingData = (notification: Notification) => {
  const notificationValues = notification.values;

  if ('missing_data_from_all_sensors' in notificationValues) {
    return true;
  }
};

const isNotificationTypeDisorder = (notification: Notification) => {
  const notificationValues = notification.values;

  if ('level' in notificationValues) {
    return true;
  }
};

export default function UseGetNotificationItemData(
  selectedLanguage: string
): UseGetNotificationItemDataResult {
  const getNotificationInfo = (notification: Notification) => {
    let type = notification.type,
      translatedTitleName = type;

    if (isNotificationTypeMissingData(notification)) {
      translatedTitleName = getMissigDataNotificationInfo(notification as MissingDataNotification);
    } else if (isNotificationTypeDisorder(notification)) {
      translatedTitleName = getDisorderNotificationInfo(notification as CropseasonNotification);
    } else {
      translatedTitleName = getCropseasonDataNotificationInfo(
        notification as CropseasonNotification
      );
    }

    return translatedTitleName;
  };

  const getMissigDataNotificationInfo = (notification: MissingDataNotification) => {
    let type = notification.type;
    const notificationValues = notification.values as MissingDataNotificationValue;
    const sourceType = notificationValues.source_type;
    let translatedTitleName = type;
    if (sourceType == 'stem load cell' || sourceType == 'wire load cell') {
      translatedTitleName = translate('Faulty sensor notification', selectedLanguage);
    } else if (sourceType == 'substrate scale') {
      translatedTitleName = translate('Substrate scale notification', selectedLanguage);
    } else if (sourceType == 'All sources missing') {
      translatedTitleName = translate('All sensors are offline', selectedLanguage);
    } else {
      translatedTitleName = sourceType;
    }
    const isOfflineText = translate('Misssing sensor notification is offline text') + '. ';
    const sourceTypeText =
      notificationValues.source_type.charAt(0).toUpperCase() +
      notificationValues.source_type.slice(1);

    return ` ${sourceTypeText} ${notificationValues.sensorDeviceID} ${isOfflineText} ${translatedTitleName}`;
  };

  const getDisorderNotificationInfo = (notification: CropseasonNotification) => {
    const NOTIFICATIONS = JSON.parse(JSON.stringify(NOTIFICATION_INFO));
    let type = notification.type;
    let title_name = NOTIFICATIONS[type]?.title + notification.values.level;

    let translatedTitleName = translate(title_name, selectedLanguage);

    let finalTranslatedTitleName = geNotificationInfoExcededVariables(
      notification,
      translatedTitleName
    );

    return finalTranslatedTitleName;
  };

  const getCropseasonDataNotificationInfo = (notification: CropseasonNotification) => {
    const NOTIFICATIONS = JSON.parse(JSON.stringify(NOTIFICATION_INFO));
    let type = notification.type;
    let title_name = NOTIFICATIONS[type]?.title;

    let translatedTitleName = translate(title_name, selectedLanguage);

    let thresholdPlaceholder =
      translatedTitleName.match(NOTIFICATION_INFO_TITLE_REGEX_THRESHOLD) || [];

    translatedTitleName = geNotificationInfoValue(
      notification as CropseasonNotification,
      translatedTitleName
    );
    translatedTitleName = getNotificationInfoTreshold(
      notification,
      translatedTitleName,
      thresholdPlaceholder.length
    );

    return translatedTitleName;
  };

  const getFormatedTimestamp = (timestamp: string): string => {
    let timeFormated = '';
    let unix_date = dayjs(timestamp).unix();
    if (isToday(unix_date)) {
      timeFormated =
        translate('Notification-tooltip-timestamp-today', selectedLanguage) +
        ' ' +
        translate('Notification-tooltip-timestamp-at', selectedLanguage) +
        ' ' +
        dayjs(timestamp).format('HH:mm A');
    } else if (isYesterday(unix_date)) {
      timeFormated =
        translate('Notification-tooltip-timestamp-yesterday', selectedLanguage) +
        ' ' +
        translate('Notification-tooltip-timestamp-at', selectedLanguage) +
        ' ' +
        dayjs(timestamp).format('HH:mm A');
    } else {
      timeFormated =
        dayjs(timestamp).format('DD-MM-YYYY') +
        ' ' +
        translate('Notification-tooltip-timestamp-at', selectedLanguage) +
        ' ' +
        dayjs(timestamp).format('HH:mm A');
    }
    return timeFormated;
  };

  return { getFormatedTimestamp, getNotificationInfo };
}
