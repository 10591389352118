import React, { useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { cropseasonSelector } from 'redux/slices/cropseason.slice';

import { useGetBenchMarkSuggestionsQuery } from 'services/benchmark.service';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { BREAKPOINT_MOBILE, activeCropseasonInitialValue } from 'config/constants';
import {
  trackFilterSelector,
  setGroup,
  resetGroups,
  setGroupsDropDown,
} from 'redux/slices/trackFilterSlice';

import BenchMarkChips from './BenchMarkChips/BenchMarkChips';
import { UidName } from 'interfaces/uid-name';

import styles from './BenchMarkGroupSuggestions.module.scss';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';
import MultiselectDropDown from 'components/MultiselectDropDown/MultiselectDropDown';

interface BenchMarkSuggestion {
  name: string;
  value: string;
}

const BenchMarkGroupSuggestions: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id: cropseasonId } = useAppSelector(cropseasonSelector);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { groups } = useAppSelector(trackFilterSelector);
  const windowSize: WindowSize = useWindowSize();
  const [currentSuggestions, setCurrentSuggestions] = useState<any[]>([]);

  const { data: suggestions } = useGetBenchMarkSuggestionsQuery(
    { cropseasonId, accountId },
    {
      skip: cropseasonId === activeCropseasonInitialValue.id || !accountId,
    }
  );

  useEffect(() => {
    if (cropseasonId === activeCropseasonInitialValue.id) {
      setCurrentSuggestions([]);
    } else if (suggestions) {
      setCurrentSuggestions(
        suggestions.map((suggestion) => ({
          id: suggestion.id,
          name: suggestion.name,
        }))
      );
    }
  }, [suggestions, cropseasonId, accountId, dispatch]);

  useEffect(() => {
    let filteredSuggestions = [];
    if (groups.length) {
      filteredSuggestions = currentSuggestions.filter((suggestion) =>
        groups.some((group) => group.id === suggestion.id)
      );
    }
    if (currentSuggestions && !filteredSuggestions.length) {
      const firstSuggestion = currentSuggestions[0];
      if (firstSuggestion) {
        dispatch(resetGroups());
        dispatch(setGroup(firstSuggestion));
      }
    } else if (!filteredSuggestions.length) {
      dispatch(resetGroups());
      dispatch(setGroupsDropDown(filteredSuggestions));
    }
  }, [currentSuggestions, dispatch, cropseasonId, accountId]);

  const handleSetOnChange = useCallback(
    (input: UidName) => {
      dispatch(setGroup(input));
    },
    [dispatch]
  );

  const getSuggestionsOptions = (): BenchMarkSuggestion[] => {
    return (
      currentSuggestions?.map((suggestion) => ({
        value: suggestion.id,
        name: suggestion.name,
      })) || []
    );
  };

  const getDropDownSelectedValues = useCallback((): BenchMarkSuggestion[] => {
    return groups?.map((group: any) => ({
      value: group.id,
      name: group.name,
    }));
  }, [groups]);

  const handleDropdownOnChange = useCallback(
    (input: BenchMarkSuggestion[]) => {
      const groups = input.map((selectedInput) => ({
        id: selectedInput.value,
        name: selectedInput.name,
      }));

      dispatch(setGroupsDropDown(groups));
    },
    [dispatch]
  );

  const renderContent = () => {
    if (windowSize.width <= BREAKPOINT_MOBILE) {
      return (
        <MultiselectDropDown
          optionList={getSuggestionsOptions()}
          initialValues={getDropDownSelectedValues()}
          onChange={handleDropdownOnChange}
        />
      );
    }
    return (
      <BenchMarkChips
        options={currentSuggestions}
        setOnChange={handleSetOnChange}
        currentValues={groups}
      />
    );
  };

  return <div className={styles.container}>{renderContent()}</div>;
};
export default BenchMarkGroupSuggestions;
