import React from 'react';

const INVERTED = {
  foreground: 'white',
  background: 'black',
};

const NORMAL = {
  foreground: 'black',
  background: 'white',
};

interface MaskProps {
  id: string;
  width?: number;
  height?: number;
  areaPath: string;
  isInverted?: boolean;
}

const Mask: React.FC<MaskProps> = ({
  id,
  width = 70,
  height = 50,
  areaPath,
  isInverted = false,
}) => {
  return (
    <mask id={id} maskUnits='userSpaceOnUse'>
      <rect
        x='0'
        y='0'
        width={width - 70}
        height={height - 40}
        fill={isInverted ? INVERTED.background : NORMAL.background}
      />
      <path d={areaPath} fill={isInverted ? INVERTED.foreground : NORMAL.foreground} />
    </mask>
  );
};
export default Mask;
