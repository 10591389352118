import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Arrow } from 'styles/icons/arrow-right-more.svg';

import AuthService from 'services/auth.service';
import { useGetUserLinkedAccountsQuery } from 'services/userAccount.service';
import { findAccountName, getUserFullName, getUserInitials } from 'utils/userInfo';
import { checkCurentAccountId } from 'utils/checkCurrentAccountId';
import resetPlanCreationAndAnalyzeState from 'utils/resetPlanCreationAndAnalyzeState';
import useTranslate from 'hooks/useTranslate';

import { IdName } from 'interfaces/id-name';

import './Profile.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { accountIdSelector, setAccountId } from 'redux/slices/accountId.slice';
import { userInfoSelector } from 'redux/slices/userInfo.slice';
import useUrlState from 'hooks/useUrlState';
import { setActiveCropseason } from 'redux/slices/cropseason.slice';
import { TOAST_PROMISE, activeCropseasonInitialValue } from 'config/constants';
import { notificationActions } from 'redux/slices/notification.slice';
import { ModalService } from 'services/modal.service';
import InviteModal from './InviteModal/InviteModal';
import { isValidEmail } from 'validators/email.validator';
import { useInviteUserMutation } from 'services/userInvite.service';
import { useGetLocationsQuery } from 'services/metaData.service';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { track } from 'utils/analtycs';
import { dispatchPromiseToast } from 'utils/toast.util';

interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => {
  const userDetails = AuthService.getUserDetails();
  const { data: linkedAccounts } = useGetUserLinkedAccountsQuery();
  const accountId = useAppSelector(accountIdSelector).accountId;
  const [isAccountsMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const profileRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { familyName, givenName } = useAppSelector(userInfoSelector);
  const translate = useTranslate();
  const [, setUrlState, resetState] = useUrlState({
    accountId: '',
  });
  const [inviteUser] = useInviteUserMutation();
  const { data: locations } = useGetLocationsQuery(accountId, { skip: !accountId });
  const { enableUserIvite } = useFeatureFlags();

  function handleClick(event: any) {
    if (profileRef.current && open && !profileRef.current.contains(event.target)) {
      setOpen(false);
      if (isAccountsMenuOpen) {
        setIsAccountMenuOpen(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  const handleLogout = () => {
    AuthService.doLogout();
  };

  const handleInviteUser = () => {
    const input = { email: ' ' };
    const validateFunction = () => {
      return !!isValidEmail(input.email);
    };
    ModalService.open({
      title: translate('Profile - Invite user'),
      cancelText: translate('Cancel generic button'),
      saveText: translate('Invite generic button'),
      width: '495px',
      validateFunction,
      content: <InviteModal inputValues={input} />,
      closeAction: async (isConfirmed) => {
        if (isConfirmed) {
          track('invite user', { email: input.email, location_id: locations?.[0].id });

          const inviteUserPromise = inviteUser({
            email: input.email,
            location_id: locations?.[0].id,
            account: findAccountName(accountId, linkedAccounts),
          });

          dispatchPromiseToast({
            type: TOAST_PROMISE,
            promise: inviteUserPromise,
            messageLoading: translate('Toast - UserInvite - loading'),
            messageError: translate('Toast - UserInvite - error'),
            messageSuccess: translate('Toast - UserInvite - success'),
          });
        }
      },
    });
  };

  return (
    <div className='accordion profile-accordion' open={open} ref={profileRef}>
      <input type='checkbox' id='accordion-profile' name='accordion-checkbox ' hidden />
      <label
        className='accordion-header c-hand header-profile'
        onClick={() => {
          setOpen(!open);
          setIsAccountMenuOpen(false);
        }}
      >
        <div className='profile-name'>
          {getUserFullName(givenName, familyName)}
          <div className='account-name'>{findAccountName(accountId, linkedAccounts)}</div>
        </div>
        <figure
          className='avatar profile-image'
          data-initial={getUserInitials(givenName, familyName)}
        >
          {userDetails?.img && <img src={userDetails.img} alt='profile' />}
        </figure>
        <i className='icon icon-arrow-down rotate-180 profile-icon' />
      </label>
      <div className='accordion-body profile-body c-hand'>
        <div className='accordion-item profile-item' onClick={() => navigate('/profile')}>
          <span>{translate('Profile Page - selector in top-right menu')}</span>
        </div>
        <div
          className='accordion-item profile-item account accordion accounts-accordion'
          open={isAccountsMenuOpen}
          onClick={() => {
            setIsAccountMenuOpen(!isAccountsMenuOpen);
          }}
        >
          <input type='checkbox' id='accounts-accordion' name='accordion-checkbox ' hidden />
          <label className='accordion-header c-hand header-profile'>
            <span>{translate('Profile - Switch account')}</span>
            <Arrow className='account-icon rotate-360' />
          </label>
          <div className='accordion-body account-body c-hand accounts'>
            {linkedAccounts?.map((account: IdName, index: number) => {
              return (
                <div
                  className='accordion-item profile-item profile-item-small'
                  onClick={() => {
                    resetPlanCreationAndAnalyzeState();
                    dispatch(setAccountId(account.id));
                    dispatch(setActiveCropseason(activeCropseasonInitialValue));
                    dispatch(notificationActions.resetFilter());
                    resetState();
                    setUrlState({ accountId: `${account.id}` });
                    setOpen(false);
                  }}
                  key={index}
                >
                  {account.name}
                  {checkCurentAccountId(account.id, accountId)}
                </div>
              );
            })}
          </div>
        </div>
        {enableUserIvite && (
          <button className='btn btn-invite' onClick={handleInviteUser}>
            Invite user
          </button>
        )}
        <hr className='invite-separator' />
        <div onClick={handleLogout} className='accordion-item profile-item'>
          <span>{translate('Profile - Log out')}</span>
        </div>
      </div>
    </div>
  );
};
export default Profile;
