import { useCallback } from 'react';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { analyzeSelectors } from 'redux/slices/analyze.slice';
import {
  useGetVarietiesLinkedToCropseasonQuery,
  useGetLocationsQuery,
  useGetLocationsLinkedToCropseasonQuery,
} from 'services/metaData.service';
import { Location } from 'interfaces/location';
import { Variety } from 'interfaces/crop-season';

export default function useSidebarFilterOptions() {
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { data: accountIdLocations } = useGetLocationsQuery(accountId, { skip: !accountId });
  const { data: locationsLinkedToCropseason } = useGetLocationsLinkedToCropseasonQuery(accountId, {
    skip: !accountId,
  });

  const { locations } = useAppSelector(analyzeSelectors.cropseason);

  const { data: varietiesLinkedToCropseason } = useGetVarietiesLinkedToCropseasonQuery(
    {
      accountId,
      limit: 1000,
      locations: locations.map((location) => String(location.id)),
    },
    { skip: !accountId, refetchOnMountOrArgChange: true }
  );
  //TODO commented the years selection for when Corjan decides to put it back in
  // const { data: yearsLinkedToCropseason } = useGetYearsLinkedToCropseasonQuery(
  //   {
  //     accountId,
  //     locations: locations.map((location) => location.id),
  //     varieties: varieties.map((variety) => variety.id),
  //   },
  //   {
  //     skip: !accountId,
  //     refetchOnMountOrArgChange: true,
  //   }
  // );

  const getLocationOptions = useCallback((): Location[] => {
    const filteredLocations = locationsLinkedToCropseason?.filter((location: { id: any }) =>
      accountIdLocations?.some((accountLocation) => location.id === accountLocation.id)
    );
    return (
      filteredLocations?.map((location) => ({
        name: location.name,
        value: location.name,
        id: location.id,
      })) || []
    );
  }, [accountIdLocations, locationsLinkedToCropseason]);

  const getVarietyOptions = useCallback((): Variety[] => {
    return varietiesLinkedToCropseason?.map((variety) => ({ name: variety, value: variety })) || [];
  }, [varietiesLinkedToCropseason]);

  //TODO commented the years selection for when Corjan decides to put it back in
  // const getYearOptions = useCallback((): Year[] => {
  //   return yearsLinkedToCropseason?.map((year) => ({ name: year, value: Number(year) })) || [];
  // }, [yearsLinkedToCropseason]);

  return { getLocationOptions, getVarietyOptions };
}
