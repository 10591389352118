import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AuthService from 'services/auth.service';
import { useConsumeAccesTokenMutation } from 'services/userAccount.service';
import { getQueryParam } from 'utils/queryString';

import useTranslate from './useTranslate';

export function useConsumeInviteToken() {
  const userDetails = AuthService.getUserDetails();
  const inviteToken = getQueryParam('invite_token_id');
  const [consumeAccesTokenId] = useConsumeAccesTokenMutation();
  const [appReady, setAppReady] = useState<boolean>(!inviteToken);
  const [isConsumed, setIsConsumed] = useState<boolean>(false);
  const translate = useTranslate();

  useEffect(() => {
    if (!userDetails) {
      return;
    }

    if (inviteToken && !appReady && !isConsumed) {
      setIsConsumed(true);
      consumeAccesTokenId(inviteToken)
        .then((response: any) => {
          console.error(response?.error);
          if (response?.error) {
            toast.error(translate('Toast - consume invitation - fail'));
          }
          setAppReady(true);
        })
        .catch(() => {
          toast.error(translate('Toast - consume invitation - fail'));
        });
    }
  }, [userDetails]);

  return appReady;
}
