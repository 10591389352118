import Area from 'components/GraphElements/Area/Area';
import MultilineChart from 'components/GraphElements/MultilineChart/MultilineChart';
import { NOTIFICATION_COLOR, RESOLUTION } from 'config/constants';
import dayjs from 'dayjs';
import React, { useCallback, useRef } from 'react';
import { ReactComponent as SliderButton } from 'styles/icons/slider-button.svg';
import './TimeRangeSlider.scss';

import useResize from '../GraphElements/hooks/useResize';
import useDrag from 'hooks/useDrag';
import Line from 'components/GraphElements/Line/Line';
import { useAppSelector } from 'redux/hooks';
import { analyzeSelectors } from 'redux/slices/analyze.slice';
import { Range } from 'interfaces/range';

interface RangeSliderProps {
  initialRange: Range;
  currentRange: Range;
  step: number;
  customClassName?: string;
  railGraphData?: Array<{ timestamp: number; value: number[] }>;
  resolution?: string;
  onCurrentRangeChange: (newRange: Range) => void;
  notificationsData?: any;
  showShortHourFormat?: boolean;
}

const enum RangeSliderDateFormat {
  dateFormatHour,
  dateFormatDay,
  dateFormatOnlyHour,
}

const SLIDE_BUTTON_WIDTH = 30;

const TimeRangeSlider: React.FC<RangeSliderProps> = ({
  initialRange,
  currentRange,
  step,
  customClassName,
  railGraphData,
  resolution = null,
  onCurrentRangeChange,
  notificationsData,
  showShortHourFormat,
}) => {
  const { min, max } = initialRange;
  let differenceRange = initialRange.max - initialRange.min;
  const componentRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { width } = useResize(componentRef);
  const notificationsEnabled = useAppSelector(analyzeSelectors.notifications);

  let rangeMovePerPixel = differenceRange / width;

  const SLIDE_MAXIM = ((max - min) / (SLIDE_BUTTON_WIDTH * 2)) * 3;

  const { min: minValue, max: maxValue } = currentRange;

  const onDragLeft = useCallback(
    (speed: number) => {
      if (minValue + rangeMovePerPixel > initialRange.min) {
        onCurrentRangeChange({
          min: minValue - rangeMovePerPixel * speed,
          max: maxValue - rangeMovePerPixel * speed,
        });
      }
    },
    [initialRange.min, maxValue, minValue, onCurrentRangeChange, rangeMovePerPixel]
  );

  const onDragRight = useCallback(
    (speed: number) => {
      if (maxValue + rangeMovePerPixel < initialRange.max) {
        onCurrentRangeChange({
          min: minValue + rangeMovePerPixel * speed,
          max: maxValue + rangeMovePerPixel * speed,
        });
      }
    },
    [initialRange.max, maxValue, minValue, onCurrentRangeChange, rangeMovePerPixel]
  );

  const { handleDragStart } = useDrag(onDragLeft, onDragRight);

  const handleMinChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const current = parseInt(event.target.value);
      const newMinValue = Math.min(current, maxValue - SLIDE_MAXIM);
      onCurrentRangeChange({ min: newMinValue, max: maxValue });
    },
    [SLIDE_MAXIM, maxValue, onCurrentRangeChange]
  );

  const handleMaxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const current = parseInt(event.target.value);
      const newMaxValue = Math.max(current, minValue + SLIDE_MAXIM);
      onCurrentRangeChange({ min: minValue, max: newMaxValue });
    },
    [onCurrentRangeChange, minValue, SLIDE_MAXIM]
  );

  const getFormattedData = (data: number, format: RangeSliderDateFormat) => {
    const newData = dayjs.unix(data).toISOString();
    switch (format) {
      case RangeSliderDateFormat.dateFormatHour: {
        return dayjs(newData).format('HH:mm');
      }
      case RangeSliderDateFormat.dateFormatDay: {
        return dayjs(newData).format('ddd DD MMM');
      }
      default: {
        return dayjs(newData).format('ddd DD MMM');
      }
    }
  };

  const renderResolutionData = (date: number, resolution: string | null) => {
    switch (resolution) {
      case RESOLUTION._1_week: {
        return <div className='trs-control-info'>{'Week ' + dayjs.unix(date).week()}</div>;
      }
      case RESOLUTION._1_hour: {
        return (
          <div className='trs-control-info'>
            <div className='trs-control-info-hour'>
              {getFormattedData(date, RangeSliderDateFormat.dateFormatHour)}
            </div>
            {!showShortHourFormat && (
              <div className='trs-control-info-day'>
                {getFormattedData(date, RangeSliderDateFormat.dateFormatDay)}
              </div>
            )}
          </div>
        );
      }
      default: {
        return <div className='trs-control-info'>{dayjs.unix(date).toISOString()}</div>;
      }
    }
  };

  const renderNotificationsData = () => {
    return (
      (notificationsEnabled &&
        notificationsData?.map((data: any, index: number) => (
          <Line
            data={data}
            xProperty='timestamp'
            property='value'
            color={NOTIFICATION_COLOR}
            transform='translate(0,0)'
            key={'notifications-slider-' + index}
          />
        ))) ||
      []
    );
  };

  const minPos = ((minValue - min) / (max - min)) * 100;
  const maxPos = ((maxValue - min) / (max - min)) * 100;

  return (
    <div className={'trs ' + customClassName}>
      <div className='trs-wrapper'>
        <div className='trs-input-wrapper'>
          <input
            className='trs-input'
            type='range'
            value={minValue}
            min={min}
            max={max}
            step={step}
            onChange={handleMinChange}
          />
          <input
            className='trs-input'
            type='range'
            value={maxValue}
            min={min}
            max={max}
            step={step}
            onChange={handleMaxChange}
          />
        </div>

        <div className='trs-control-wrapper'>
          <div className='trs-control trs-control-left' style={{ left: `${minPos}%` }}>
            <SliderButton className='trs-slider-button'></SliderButton>
            {renderResolutionData(minValue, resolution)}
          </div>
          <div className='trs-rail' ref={componentRef}>
            <div
              className={`trs-inner-rail`}
              style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
              draggable={true}
              onMouseDown={handleDragStart}
            />

            <MultilineChart
              xAxisProperty='timestamp'
              title='trs-graph'
              YInterval={[{ min: -1.0, max: 1.0 }]}
              XInterval={[min, max]}
              hasTooltip={false}
              showTitle={false}
              showAxis={false}
              showYAxisTicks={false}
              hasAxisOffset={false}
              hasLegend={false}
              customTransform='translate(0,13)'
            >
              {[
                ...renderNotificationsData(),
                <Area data={railGraphData} color='#8293A6' key={'notifications-slider-area'} />,
              ]}
            </MultilineChart>
          </div>
          <div className='trs-control trs-control-right' style={{ left: `${maxPos}%` }}>
            <SliderButton className='trs-slider-button'></SliderButton>
            {renderResolutionData(maxValue, resolution)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeRangeSlider;
