import React, { ChangeEvent, useEffect, useState } from 'react';

import styles from './NumberInput.module.scss';

import { ReactComponent as ChevronUp } from 'styles/icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from 'styles/icons/chevron-down.svg';

type NumberInputStyleType = 'small' | 'medium' | 'large';

interface NumberInputProps {
  onChange?: (currentValue: string) => void;
  initialValue?: number;
  amount?: number;
  setValue?: () => {};
  headerText?: string;
  positive?: boolean;
  numberOfDecimals?: number;
  customClassName?: string;
  disabled?: boolean;
  minValue?: number;
  type?: NumberInputStyleType;
  isHighlighted?: boolean;
}

const NumberInput: React.FC<NumberInputProps> = ({
  initialValue,
  onChange,
  headerText,
  amount = 1,
  positive = false,
  numberOfDecimals = 0,
  customClassName,
  disabled,
  minValue,
  type = 'medium',
  isHighlighted = false,
}) => {
  const [currentValue, setCurrentValue] = useState(Number(initialValue).toFixed(numberOfDecimals));

  const [isBlurred, setIsBlurred] = useState(false);

  const handleIncrement = () => {
    if (currentValue == null) {
      return;
    }
    setCurrentValue((parseFloat(currentValue) + amount).toFixed(numberOfDecimals));

    if (onChange) {
      onChange((parseFloat(currentValue) + amount).toFixed(numberOfDecimals));
    }
  };

  const handleDecrement = () => {
    if (currentValue == null) {
      return;
    }
    if (
      (positive && parseFloat(currentValue) - amount < 0) ||
      (minValue && parseFloat(currentValue) - amount < minValue)
    ) {
      return;
    }
    setCurrentValue((parseFloat(currentValue) - amount).toFixed(numberOfDecimals));

    if (onChange) {
      onChange((parseFloat(currentValue) - amount).toFixed(numberOfDecimals));
    }
  };

  useEffect(() => {
    setCurrentValue(Number(initialValue).toFixed(numberOfDecimals) || '');
  }, [initialValue, numberOfDecimals]);

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    setIsBlurred(false);
    setCurrentValue(event.target.value);
  }

  useEffect(() => {
    if (onChange && isBlurred) {
      if (parseFloat(currentValue).toFixed(numberOfDecimals) !== 'NaN') {
        onChange(parseFloat(currentValue).toFixed(numberOfDecimals));
      }
    }
  }, [currentValue, isBlurred]);

  function handleOnBlur() {
    setIsBlurred(true);
    if (parseFloat(currentValue).toFixed(numberOfDecimals) === 'NaN') {
      if (minValue) {
        setCurrentValue(Number(minValue).toFixed(numberOfDecimals));
      } else {
        setCurrentValue('0');
      }
    } else {
      if (
        (positive && parseFloat(currentValue) < 0) ||
        (minValue && parseFloat(currentValue) < minValue)
      ) {
        setCurrentValue(Number(minValue).toFixed(numberOfDecimals));
      } else {
        setCurrentValue(currentValue);
      }
    }
  }

  return (
    <div
      className={
        `${styles['main-container']} ${styles[customClassName || '']} ${
          disabled && styles['main-container-disabled']
        } ` +
        styles[`main-container-${type}`] +
        (isHighlighted ? ` ${styles['isHighlighted']}` : '')
      }
    >
      {headerText && <div className={styles['header']}>{headerText}</div>}
      <div className={styles['input-container']}>
        <input
          className={styles['value-container']}
          type='text'
          value={currentValue}
          onChange={handleInputChange}
          onBlur={handleOnBlur}
        />
        <div className={styles['controls-container']}>
          <div className={`${styles['increment-container']} c-hand`} onClick={handleIncrement}>
            <ChevronUp
              width={17}
              height={17}
              className={`icon-light ${disabled && styles['disabled-icons']}`}
            />
          </div>
          <div className={`${styles['decrement-container']} c-hand`} onClick={handleDecrement}>
            <ChevronDown
              width={17}
              height={17}
              className={`icon-light ${disabled && styles['disabled-icons']}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NumberInput;
