import './Accordion.scss';
import { useEffect, useState } from 'react';
import { ReactComponent as ChevronIcon } from 'styles/icons/chevron-down.svg';

export type OptionWithName<T> = T & { id: string | null; name: string };

interface AccordionProps<T> {
  options: T[];
  visibleOptions?: T[];
  title: string;
  isInitialOpen: boolean;
  isCollapsed?: boolean;
  renderElement: (option: T, hasCheck: boolean) => JSX.Element;
  isLightTheme?: boolean;
  hasCheck: boolean;
}

const Accordion = <T extends OptionWithName<unknown>>(props: AccordionProps<T>) => {
  const {
    options = [],
    visibleOptions = options,
    title,
    isInitialOpen,
    isCollapsed = !isInitialOpen,
    renderElement,
    isLightTheme = false,
    hasCheck = false,
  } = props;

  const [isOpen, setIsOpen] = useState(isInitialOpen);

  useEffect(() => {
    setIsOpen(!isCollapsed);
  }, [isCollapsed]);

  const accordionTheme = isLightTheme ? 'accordion-light' : 'accordion-dark';

  return (
    <div
      key={title}
      className={`accordion ${accordionTheme} ${!visibleOptions.length ? 'hidden' : ''}`}
    >
      <input
        type='checkbox'
        checked={isOpen}
        onChange={(event) => setIsOpen(!!event.target.checked)}
        id={`accordion-${title}`}
        name='accordion-checkbox'
        hidden
      />
      <label className='accordion-header c-hand' htmlFor={`accordion-${title}`}>
        {title}
        <ChevronIcon className='icon-arrow' />
      </label>
      <div className='accordion-body'>
        {options.map((option, index) => {
          const optionId = option.id || option.name;
          const rowClassName = index % 2 === 0 ? 'row-even' : 'row-odd';
          let key = (title + optionId + index).replaceAll(' ', '');
          return (
            <div key={key} className={`accordion-row ${rowClassName}`}>
              {renderElement(option, hasCheck)}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Accordion;
