export const getYAxisInterval = (
  values: ({ min: number; max: number } | undefined)[],
  isExact: boolean = false
): [number, number] => {
  let minValue: number = Infinity;
  let maxValue: number = -Infinity;

  values.forEach((data: any) => {
    if (data?.min !== -Infinity && data?.max !== Infinity) {
      if ((data?.min && data?.min < minValue) || (data?.min === 0 && data?.min < minValue)) {
        minValue = data?.min;
      }
      if (data?.max && data?.max > maxValue) {
        maxValue = data?.max;
      }
    }
  });
  if (isExact) {
    return [minValue, maxValue];
  } else {
    return [Math.floor(minValue), Math.ceil(maxValue)];
  }
};
