import React from 'react';

import useResize from './useResize';

interface DimensionResponse {
  svgWidth: number;
  svgHeight: number;
  width: number;
  height: number;
}

const useDimensions = (): [React.RefObject<HTMLDivElement>, DimensionResponse] => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { width, height } = useResize(ref);

  return [
    ref,
    {
      //-30 value is the Y-Axis legend width
      //+10 value is the X-Axis legend height
      svgWidth: Math.max(width - 30, 0),
      svgHeight: Math.max(height + 10, 0),
      width,
      height,
    },
  ];
};

export default useDimensions;
