import React from 'react';

interface CircleProps {
  data: any;
  xScale?: any;
  yScale?: any;
  transform?: string;
  color?: any;
  property?: string;
  strokeColor?: string;
}

const Circle: React.FC<CircleProps> = ({
  data,
  yScale,
  xScale,
  transform,
  property = '',
  color = '#fff',
  strokeColor = '#fff',
}) => {
  return (
    <circle
      stroke={strokeColor}
      strokeWidth={1.5}
      style={{ fill: color }}
      cx={xScale(data?.[0]['timestamp']) || -100}
      cy={yScale(data?.[0][property]) || -100}
      r={5}
      transform={transform}
    />
  );
};
export default Circle;
