import { useEffect, useState } from 'react';

import { SORT } from 'config/constants';
import { dataSorter } from 'utils/data-sorter';
import { KeyValue } from 'interfaces/keyvalue';

const useSorter = (data: any, fields: any) => {
  const [activeSortKey, setActiveSortKey] = useState<string | undefined>(undefined);
  const [dataToDisplay, setDataToDisplay] = useState(data);
  const [fieldsToSort, setFieldsToSort] = useState(fields);

  useEffect(() => {
    if (Object.keys(fieldsToSort).find((key) => fieldsToSort[key] !== SORT.NONE)) {
      setActiveSortKey(Object.keys(fieldsToSort).find((key) => fieldsToSort[key] !== SORT.NONE));
    } else {
      setActiveSortKey(undefined);
    }
  }, [fieldsToSort]);

  useEffect(() => {
    if (!activeSortKey) {
      setDataToDisplay(data);
    } else {
      sortingHandler(activeSortKey, false);
    }
  }, [data]);

  const resetState = () => {
    const resetStateArray = Object.keys(fieldsToSort).map((item: any) => {
      const resetStateObject: KeyValue<string> = {}
      resetStateObject[item] = SORT.NONE
      return resetStateObject
    });
    const resetState = Object.assign({}, ...resetStateArray);
    return resetState;
  }

  const sortingHandler = (field: string, shouldChangeSortingOrder = true) => {

    const resetedState = resetState();
    if (fieldsToSort[field] === SORT.DESCENDING) {
      if (shouldChangeSortingOrder) {
        setFieldsToSort({
          ...resetedState,
          [field]: SORT.ASCENDING,
        });
        setDataToDisplay(dataSorter(data, SORT.ASCENDING, field));
      } else {
        setDataToDisplay(dataSorter(data, SORT.DESCENDING, field));
      }
    } else if (fieldsToSort[field] === SORT.ASCENDING) {
      if (shouldChangeSortingOrder) {
        setFieldsToSort({
          ...resetedState,
          [field]: SORT.NONE,
        });
        setDataToDisplay(data);
      } else {
        setDataToDisplay(dataSorter(data, SORT.ASCENDING, field));
      }
    } else if (fieldsToSort[field] === SORT.NONE) {
      if (shouldChangeSortingOrder) {
        setFieldsToSort({
          ...resetedState,
          [field]: SORT.DESCENDING,
        });
        setDataToDisplay(dataSorter(data, SORT.DESCENDING, field));
      } else {
        setDataToDisplay(data);
      }
    }
  };

  return [dataToDisplay, fieldsToSort, sortingHandler];
};

export default useSorter; 
