import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import AuthService from 'services/auth.service';

import { KEYCLOAK_ACCOUNT_URL } from 'config/constants';

export const keyCloakBaseApi = createApi({
  reducerPath: 'keycloak',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_KEYCLOAK_URL + KEYCLOAK_ACCOUNT_URL,
    prepareHeaders: async (headers) => {
      if (AuthService.isTokenExpired()) {
        await AuthService.updateToken2();
      }
      headers.set('Authorization', `Bearer ${AuthService.getToken()}`);
      return headers;
    },
  }),
  endpoints: () => ({}),
});
