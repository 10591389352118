import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DashboardData } from 'interfaces/trackings';
import isoWeek from 'dayjs/plugin/isoWeek';
import { ToastType } from 'interfaces/toast';
import { CropSeason } from 'interfaces/crop-season';
import { translate } from 'utils/translations.util';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(isoWeek);

export const HOVER_TRESHOLD = dayjs.duration({ minutes: 5 }).asMilliseconds();

export const TIMEZONE = dayjs.tz.guess();
export const UTCOffset = dayjs().utcOffset();

export const BREAKPOINT_TOOLTIP_PLAN_RIGHT = 1428;
export const BREAKPOINT_LAPTOP = 1024;
export const BREAKPOINT_LAPTOP_LARGE = 1640;
export const BREAKPOINT_TABLET = 768;
export const BREAKPOINT_MOBILE = 425;
export const BREAKPOINT_4K = 2560;
export const BREAKPOINT_DROPDOWN_RIGHT_TRACKS_HEADER = 1240;
export const GENERIC_CARD_DARK_MODE = 'dark';
export const GENERIC_CARD_LIGHT_MODE = 'light';
export const BREAKPOINT_LAPTOP_L = 1440;
export const BREAKPOINT_FULL_HD = 1920;

export const MAX_LENGTH_PLAN_NAME = 50;
export const MAX_LENGTH_FOLDER_NAME = 1000;

export const MIN_LENGTH_PLAN_NAME = 1;
export const MIN_LENGTH_FOLDER_NAME = 1;

export const DEFAULT_LINE_GRAPH_WIDTH = 2;

export const BLUR_MESSAGE =
  'For this variable no data is available for the selected time interval.';

export const LEFT = 'LEFT';
export const RIGHT = 'RIGHT';

export const BENCHMARK_LEGEND_LINE_COLOR = '#FFE175';

export const AUTO_CALCULATE_DECIMALS = -1;

export const PLAN_VARIABLES_OLD_NAME_ID_MAPPING = {
  RADCJM: 'snrdd',
  RADJCM: 'snrdd',
  GHCO2C: 'ghcot',
  AL_hours: 'dayal',
  OUTEMP: 'otemp',
  GHTEMP: 'gtemp',
  'stem.density.setting': 'stemd',
  pruning: 'prung',
  'harvested.fruits.m2.wk': 'hrvsp',
  'harvested.fruits.m2.cu': 'hrvfr',
  'setting.fruits.m2.wk': 'frtsp',
  'setting.truss.stem.wk': 'trssp',
  'setting.truss.stem.cu': 'ntrus',
  'setting.fruits.m2.cu': 'nfrut',
  'plantload.fruits.m2.calc': 'pload',
  harvestMaturity: 'hrvma',
  'stem.diam': 'sdiam',
  growthRate: 'grwrt',
  brix: 'brixx',
  afw: 'avgfw',
  'yield.cu': 'cuyld',
  yield: 'yield',
  'plant weight m2': 'pmass',
  'stem load cell raw': 'smass',
  ECIRRI: 'ecirr',
  dli: 'dliii',
  LAI: 'leafa',
  drain: 'drain',
  slabExhaustion: 'slaon',
  waterSupply: 'watly',
  ccJ: 'ccjcj',
};

export const DESIRED_TABLE_ORDER = [
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.RADCJM, shownDecimals: 0 },
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.AL_hours, shownDecimals: 0 },
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.dli, shownDecimals: 0 },
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.OUTEMP, shownDecimals: 1 },
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHTEMP, shownDecimals: 1 },
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.GHCO2C, shownDecimals: 0 },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.ECIRRI,
    shownDecimals: AUTO_CALCULATE_DECIMALS,
  },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.density.setting'],
    shownDecimals: 2,
  },
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.pruning, shownDecimals: 0 },
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['stem.diam'], shownDecimals: 2 },
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI, shownDecimals: 2 },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['setting.fruits.m2.wk'],
    shownDecimals: 1,
  },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['setting.fruits.m2.cu'],
    shownDecimals: AUTO_CALCULATE_DECIMALS,
  },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['harvested.fruits.m2.wk'],
    shownDecimals: 1,
  },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['harvested.fruits.m2.cu'],
    shownDecimals: AUTO_CALCULATE_DECIMALS,
  },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['plantload.fruits.m2.calc'],
    shownDecimals: 0,
  },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.harvestMaturity,
    shownDecimals: 0,
  },
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.growthRate, shownDecimals: 0 },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['setting.truss.stem.cu'],
    shownDecimals: AUTO_CALCULATE_DECIMALS,
  },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['setting.truss.stem.wk'],
    shownDecimals: AUTO_CALCULATE_DECIMALS,
  },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.afw,
    shownDecimals: AUTO_CALCULATE_DECIMALS,
  },
  {
    id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.brix,
    shownDecimals: AUTO_CALCULATE_DECIMALS,
  },
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.yield, shownDecimals: AUTO_CALCULATE_DECIMALS },
  { id: PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield.cu'], shownDecimals: 1 },
];

export const PLAN_CREATION_STEPS = [
  {
    stepNumber: '1',
    stepText: 'Create Plan - step 0 link to cropseason',
  },
  { stepNumber: '2', stepText: 'Create Plan - go to 1 select variety screen' },
  { stepNumber: '3', stepText: 'Create Plan - go to 2 select location screen' },
  { stepNumber: '4', stepText: 'Create Plan - go to select crop plan dates screen' },
  { stepNumber: '5', stepText: 'Plan creation  - custom settings' },
];

//TODO - remove this when after it is replaced with getActiveCropseasonIntitialValue() everywhere
export const activeCropseasonInitialValue = {
  id: 0,
  name: 'Select Cropseason',
  account_id: 0,
  transplant_date: '',
  is_active: false,
  end_date: '',
  variety: { id: 0, name: '' },
  location: { id: 0, name: '', tzone: '' },
  tzone: '',
};

export const noCropSeason = {
  id: 0,
  name: 'No Cropseason',
  account_id: 0,
  transplant_date: '',
  is_active: false,
  end_date: '',
  variety: { id: 0, name: '' },
  location: { id: 0, name: '', tzone: '' },
  tzone: '',
} as CropSeason;

export const ENTER_KEYCODE = 13;
export const ESCAPE_KEYCODE = 27;
export const DASHBOARD_TRACKINGS_INITIAL_DATA: DashboardData = {
  climate: {
    name: 'climate',
    status: 'to_be_determined',
    unit: '°C',
    value: 0,
    variable_name: '24h Temperature',
    timeseries: {
      lineData: [],
      areaData: [],
      yInterval: { min: Infinity, max: -Infinity },
      xInterval: [0, 100],
    },
  },
  water_efficiency: {
    name: 'water_efficiency',
    status: 'to_be_determined',
    unit: 'liter/m²',
    value: 0,
    variable_name: 'Transpiration',
    last_7_days: {
      value: [],
      weekday_number: [],
    },
    timeseries: {
      lineData: [],
      areaData: [],
      yInterval: { min: Infinity, max: -Infinity },
      xInterval: [0, 100],
    },
  },
  production: {
    name: 'production',
    status: 'to_be_determined',
    unit: 'kg/m²',
    value: 0,
    variable_name: 'Weekly Yield',
    timeseries: {
      barData: [],
      yInterval: { min: Infinity, max: -Infinity },
      xInterval: ['0', '100'],
    },
    historic_data: { weeknr: [], value: [] },
  },
  fresh_weight_gain: {
    name: 'fresh_weight_gain',
    status: 'to_be_determined',
    unit: 'grams/m²',
    value: 0,
    variable_name: 'Fresh Weight Gain',
    timeseries: {
      lineData: [],
      areaData: [],
      yInterval: { min: Infinity, max: -Infinity },
      xInterval: [0, 100],
    },
  },
  water_management: {
    name: 'water_management',
    status: 'to_be_determined',
    unit: '%',
    value: 0,
    variable_name: 'Slab Exhaustion',
    gauge: {
      max: 0,
      min: 0,
      value: 0,
      lower_target_zone: 0,
      upper_target_zone: 0,
    },
  },
  crop_status: {
    name: 'crop_status',
    status: 'to_be_determined',
    unit: '%',
    variable_name: 'Crop Status',
    timeseries: [{ x: 0, y: 0 }],
  },
};

export const ONE_DAY_IN_MILLISECONDS = 8.64e7;

export const RESOLUTION = {
  _5_min: '5_min',
  _1_hour: '1_hour',
  _1_day: '1_day',
  _1_week: '1_week',
};

// Tracking lvl2 variables

export const VARIABLES_STATUS = {
  tooHigh: 'too_high',
  tooLow: 'too_low',
  missing: 'missing',
};

export const VARIABLES_CLIMATE = {
  RADJCM: 'RADJCM',
  GHTEMP: 'GHTEMP',
  GHTEMPday: 'GHTEMPday',
  GHTEMPnight: 'GHTEMPnight',
  GHTEMPdiff: 'GHTEMPdiff',
  OUTEMP: 'OUTEMP',
  GHCO2C: 'GHCO2C',
  VADEFE: 'VADEFE',
  dli: 'dli',
};

export const VARIABLES_CROP_STATUS = {
  stemDiameter: 'stem.diam',
  plantLoad: 'plantload.fruits.m2.calc',
  settingsFruits: 'setting.fruits.m2.wk',
};

export const VARIABLES_FRESH_WEIGHT_GAIN = {
  stemDensitySetting: 'stem.density.setting',
  stemLoadCell: 'stem load cell raw',
  growthRate: 'growthRate',
};

export const VARIABLES_PRODUCTION = {
  yield: 'yield',
  yieldCumulative: 'yield.cu',
  brix: 'brix',
  harvestMaturity: 'harvestMaturity',
  afw: 'afw',
};

export const VARIABLES_WATER_EFFICIENCY = {
  waterUptake: 'waterUptake',
  transpiration: 'transpiration',
};

export const VARIABLES_WATER_MANAGEMENT = {
  slabExhaustion: 'slabExhaustion',
  drain: 'drain',
  waterSupply: 'waterSupply',
  ECIRRI: 'ECIRRI',
  ccJ: 'ccJ',
};

// Tracking lvl2 resolution

export const VARIABLE_MAX_RESOLUTION = {
  RADJCM: RESOLUTION._1_hour,
  GHTEMPday: RESOLUTION._1_day,
  GHTEMPnight: RESOLUTION._1_day,
  GHTEMPdiff: RESOLUTION._1_day,
  OUTEMP: RESOLUTION._1_hour,
  GHCO2C: RESOLUTION._1_hour,
  VADEFE: RESOLUTION._1_hour,
  stemDiameter: RESOLUTION._1_week,
  plantLoad: RESOLUTION._1_week,
  settingsFruits: RESOLUTION._1_week,
  stemDensitySetting: RESOLUTION._1_week,
  growthRate: RESOLUTION._5_min,
  stemLoadCell: RESOLUTION._5_min,
  yield: RESOLUTION._1_week,
  yieldCumulative: RESOLUTION._1_week,
  brix: RESOLUTION._1_week,
  harvestMaturity: RESOLUTION._1_week,
  afw: RESOLUTION._1_week,
  waterUptake: RESOLUTION._5_min,
  transpiration: RESOLUTION._5_min,
  slabExhaustion: RESOLUTION._1_hour,
  drain: RESOLUTION._1_hour,
  ccJ: RESOLUTION._1_hour,
  waterSupply: RESOLUTION._1_hour,
  ECIRRI: RESOLUTION._1_hour,
  dli: RESOLUTION._1_week,
  totalPar: RESOLUTION._1_week,
};

// Tracking lvl2 decimals

export const VARIABLE_GRAPH_DECIMALS = {
  RADJCM: 0,
  GHTEMPday: 1,
  GHTEMPnight: 1,
  GHTEMPdiff: 1,
  OUTEMP: 1,
  GHCO2C: 0,
  VADEFE: 1,
  stemDiameter: 1,
  plantLoad: 0,
  settingsFruits: 0,
  stemDensitySetting: 1,
  growthRate: 0,
  stemLoadCell: 0,
  yield: 1,
  yieldCumulative: 0,
  brix: 1,
  harvestMaturity: 0,
  afw: 1,
  waterUptake: 1,
  transpiration: 1,
  slabExhaustion: 0,
  drain: 1,
  ccJ: 1,
  waterSupply: 1,
  ECIRRI: 1,
  dli: 0,
  totalPAR: 0,
};

export const VARIABLE_TOOLTIP_DECIMALS = {
  RADJCM: 0,
  GHTEMPday: 1,
  GHTEMPnight: 1,
  GHTEMPdiff: 1,
  OUTEMP: 1,
  GHCO2C: 0,
  VADEFE: 1,
  stemDiameter: 1,
  plantLoad: 0,
  settingsFruits: 1,
  stemDensitySetting: 2,
  growthRate: 0,
  stemLoadCell: 0,
  yield: 2,
  yieldCumulative: 1,
  brix: 1,
  harvestMaturity: 0,
  afw: 1,
  waterUptake: 1,
  transpiration: 1,
  slabExhaustion: 1,
  drain: 1,
  ccJ: 1,
  waterSupply: 1,
  ECIRRI: 1,
  dli: 0,
  totalPAR: 0,
};

export const NUMBER_OF_TICKS_FOR_GRAPHS = {
  DESKTOP: 8,
  TABLET: 6,
  MOBILE: 6,
};

export const SORT = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
  NONE: 'none',
};

export const ANALYZE_VARIABLES_BLACK_LIST = ['RADJCM'];

export const GRAPH_TICKS_COUNT = 8;

export const QUERY_LIMIT = 1000;

export const EMAIL_PATTERN = "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*";

export const KEYCLOAK_ACCOUNT_URL = '/realms/aph/account';

export const DEFAULT_LANGUAGE = { value: 'en', displayValue: 'English' };

export const GUARD_RAIL_GRAPH_COLOR = '#10BE3C';

export const GUARD_RAIL_GRAPH_LEGEND_TOOLTIP_COLOR = '#00CB49';

export const PLAN_GRAP_COLOR = '#00FFD1';

export const ADAPTIVE_PLAN_GRAH_COLOR = '#79BFFF';

export const EXACT_Y_INTERVAL_VARIABLES = ['drain'];

export const SUNRISE_BG_COLOR = '#394958';

export const NOTIFICATION_COLOR = '#FF8947';

export const SUNSET_BG_COLOR = '#233545';
export const EDIT_PLAN_LEGENDS = {
  CLIMATE: [
    { label: 'light.sum.total.day', color: '#00FFD1', stateName: '' },
    { label: 'OUTEMP', color: '#CB36FF', stateName: '' },
    { label: 'GHTEMP', color: '#CB36FF', stroke: '3 4', stateName: '' },
  ],
  CO2: [{ label: 'GHCO2C', color: '#fff', stateName: '' }],
  PLANT_STATUS: [
    { label: 'stem.density.setting', color: '#fff', stateName: '' },
    { label: 'pruning', color: '#CB36FF', stateName: '' },
    { label: 'LAI', color: '#36DBFF', stateName: '' },
  ],
  MATURITY: [
    { label: 'harvest.fruit.m2.wk', color: '#00FFD1', stateName: '' },
    { label: 'harvestMaturity', color: '#CB36FF', stateName: '' },
    { label: 'setting.fruit.m2.wk', color: '#00FFD1', stroke: '3 4', stateName: '' },
  ],
  PLANT_LOAD: [
    { label: 'stem.diam', color: '#00FFD1', stateName: '' },
    { label: 'plantload.fruits.m2.calc', color: '#CB36FF', stateName: '' },
  ],
  FRESH_WEIGHT_GAIN: [
    { label: 'growthRate', color: '#00FFD1', stateName: '' },
    { label: 'stem load cell raw', color: '#CB36FF', stateName: '' },
  ],
  WEEKLY_YIELD: [
    { label: 'yield', color: '#00FFD1', stateName: '' },
    { label: 'yield.cu', color: '#CB36FF', stateName: '' },
  ],
  BRIX: [
    { label: 'brix', color: '#00FFD1', stateName: '' },
    { label: 'afw', color: '#CB36FF', stateName: '' },
  ],
};

export const YIELD_PAGE_CHIPS = {
  YIELD: {
    label: 'Weekly Yield',
    color: '#fff',
    value: 'Weekly Yield',
  },
  YIELD_PREDICTION: {
    label: 'Weekly Yield - Prediction',
    color: '#A4FA60',
    value: 'Weekly Yield - Prediction',
  },
};

export const PAGINATION_DOTS_FLAG = -1;

export const PAGINATION_DROPDOWN_LIST = [
  { value: '10', displayValue: '10 per page ' },
  { value: '30', displayValue: '30 per page ' },
  { value: '50', displayValue: '50 per page ' },
  { value: '100', displayValue: '100 per page ' },
];

export const STRATEGIES_FIELDS = {
  afw: 'afw',
  brix: 'brix',
  yield: 'yield',
};

export enum ShopStatus {
  Generating = 'generating',
  Finished = 'finished',
}

export const TRACKING_STATUS_NAME = {
  climate: 'climate',
  watherManangement: 'watherManangement',
  waterEfficiency: 'waterEfficiency',
  freshWeightGain: 'freshWeightGain',
  cropStatus: 'cropStatus',
  production: 'production',
};

export const BENCHMARK_RIBBON_COLOR = '#C379FF';

export const REFETCH_NOTIFICATIONS_MILLISECONDS = 60000;

export const Z_INDEX_CUSTOM_RANGE_TOOLTIP = 100000;

//DESKTOP / MOBILE VIEW CONSTANTS
export const DESKTOP_GRAPH_PADDING = 50;
export const MOBILE_GRAPH_PADDING = 20;

//TOASTS
export const TOAST_SUCCESS: ToastType = 'TOAST_SUCCESS';
export const TOAST_ERROR: ToastType = 'TOAST_ERROR';
export const TOAST_PROMISE: ToastType = 'TOAST_PROMISE';

//RESOLUTIONS

export const PRODUCTION_RESOLUTION = RESOLUTION._1_week;
export const CROPSTATUS_RESOLUTION = RESOLUTION._1_week;
