import './Sidebar.scss';

import DatePickerDropdown from 'components/DatePicker/DatePickerDropdown/DatePickerDropdown';
import DropDown from 'components/DropDown/DropDown';
import MultiSelectChipsDropdown from 'components/MultiSelectChipsDropdown/MultiSelectChipsDropdown';
import MultiselectDropDown from 'components/MultiselectDropDown/MultiselectDropDown';
import SearchInput from 'components/SearchInput/SearchInput';
import SideSlide from 'components/SideSlide/SideSlide';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { ANALYZE_VARIABLES_BLACK_LIST, BREAKPOINT_TABLET, RESOLUTION } from 'config/constants';
import dayjs from 'dayjs';
import useTranslate from 'hooks/useTranslate';
import useUrlState from 'hooks/useUrlState';
import useWindowSize from 'hooks/useWindowSize';
import { AnalyzeData, AnalyzeFilter } from 'interfaces/analyze';
import { CropSeason, Variety, Year } from 'interfaces/crop-season';
import { FilterVariable } from 'interfaces/filter-variable';
import { IdName } from 'interfaces/id-name';
import { Location } from 'interfaces/location';
import { Range } from 'interfaces/range';
import { Resolution } from 'interfaces/resolution';
import { SearchInputConfig } from 'interfaces/searchInput';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { analyzeActions, analyzeSelectors } from 'redux/slices/analyze.slice';
import { languageSelector } from 'redux/slices/language.slice';
import {
  useGetDayProfilesQuery,
  useGetFilterOptionsMutation,
  useGetTimeseriesQuery,
} from 'services/analyze.service';
import { useGetCropseasonsQuery } from 'services/cropseason.service';
import { useGetUserSettingsQuery } from 'services/userAccount.service';
import { ReactComponent as More } from 'styles/icons/more-horizontal-small.svg';
import { getCropSeasonsTimeRange } from 'utils/cropSeasons.utils';
import { filterUniqueArrayElements } from 'utils/filterUniqueArrayElements';
import { getResolutionsInitialValues, getResolutionTranslated } from 'utils/getInitialValues';
import { addResolutionToTimestamp } from 'utils/time.util';
import { handleResolutionError, mapVariablesToFilterOptions } from 'utils/variables.util';

import Accordion from '../../../components/Accordion/Accordion';
import useGetSidebarFilterOptions from '../hooks/useGetSidebarFilterOptions';
import useGetVariableOption from '../hooks/useGetVariableOption';

interface SidebarProps {
  onCropSeasonsChange?: (cropSeasons: IdName[]) => void;
  onVariablesChange?: (variables: AnalyzeData | [] | undefined) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onVariablesChange = () => {} }) => {
  const { width: windowWidth } = useWindowSize();
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const [filteredCropSeasons, setFilteredCropSeasons] = useState<IdName[]>([]);
  const [variables, setVariables] = useState<FilterVariable[]>([]);
  const [searchFilteredCropSeaons, setSearchFilteredCropSeasons] = useState(filteredCropSeasons);
  const SELECTED_CROPSEASON_FILTER = 'selectedCropseasons';

  const { locations, varieties, selectedCropseasons } = useAppSelector(analyzeSelectors.cropseason);
  const { from, to, resolution } = useAppSelector(analyzeSelectors.settings);
  const groupPerHour = useAppSelector(analyzeSelectors.groupPerHour);
  const [previousResolution, setPreviousResolution] = useState<Resolution | undefined>(undefined);
  const hasVariables = variables.length > 0;
  const selectedVariables = useAppSelector(analyzeSelectors.variables);

  const [urlState, setUrlState] = useUrlState({
    locations: [],
    from: from,
    to: to,
    resolution: resolution,
    selectedCropseasons: [],
    varieties: [],
    groupPerHour,
  });

  const isSidebarOpen = useAppSelector(analyzeSelectors.isSidebarOpen);
  const keyword = useAppSelector(analyzeSelectors.keyword);

  const isAllvariableCollapsed = useAppSelector(analyzeSelectors.isAllvariableCollapsed);
  const accountId = useAppSelector(accountIdSelector).accountId;

  const { selectedLanguage } = useAppSelector(languageSelector);

  const { data: cropSeasons } = useGetCropseasonsQuery(accountId, { skip: !accountId });

  const [getFilterOptions] = useGetFilterOptionsMutation();

  const timeRange = {
    min: from,
    max: to,
  } as Range;

  const expandedRangeView = {
    min: timeRange.min,
    max: dayjs(
      addResolutionToTimestamp({
        input: dayjs.unix(timeRange.max).toISOString(),
        resolution: resolution as Resolution,
      })
    ).unix(),
  };
  const { data: defaultCropSeason } = useGetUserSettingsQuery();
  //TODO - remove variable.name after API responds with Id's everywhere

  let { data: analyzeData } = useGetTimeseriesQuery(
    {
      cropSeasonIds: selectedCropseasons.map((cropseason) => cropseason.id as number),
      startDate: dayjs.unix(expandedRangeView.min).toISOString(),
      endDate: dayjs.unix(expandedRangeView.max).toISOString(),
      resolution: resolution,
      variables: variables
        .filter((variable) => variable.id === null)
        .map((variable) => variable.name),
      variables_id: variables
        .filter((variable) => variable.id !== null)
        .map((variable) => variable.id),
      accountId: accountId,
    },
    {
      skip:
        !selectedCropseasons.length ||
        !variables.length ||
        !accountId ||
        !resolution ||
        !from ||
        !to ||
        !!handleDatesError(),
      refetchOnMountOrArgChange: true,
    }
  );
  //TODO - remove variable.name after API responds with Id's everywhere
  let { data: dayProfilesData } = useGetDayProfilesQuery(
    {
      cropSeasonIds: selectedCropseasons.map((cropseason) => cropseason.id as number),
      startDate: dayjs.unix(from).toISOString(),
      endDate: dayjs.unix(to).toISOString(),
      resolution: resolution,
      variables: variables
        .filter((variable) => variable.id === null)
        .map((variable) => variable.name),
      variables_id: variables
        .filter((variable) => variable.id !== null)
        .map((variable) => variable.id),
      accountId: accountId,
    },
    {
      skip:
        !selectedCropseasons.length ||
        !accountId ||
        !from ||
        !to ||
        !groupPerHour ||
        !variables.length ||
        handleResolutionError(from, to, resolution) !== false,
      refetchOnMountOrArgChange: true,
    }
  );

  const getAllVariables = useCallback(
    async (list: any[]) => {
      if (accountId) {
        return Promise.all(
          list.map(async (item) => {
            return await getFilterOptions({
              cropSeasonId: item?.id,
              dateRange: { from, to },
              resolution,
              accountId: accountId,
            })
              .unwrap()
              .catch((error: any) => console.error(error));
          })
        );
      } else {
        return [];
      }
    },
    [from, getFilterOptions, resolution, to, accountId]
  );

  useEffect(() => {
    getAllVariables(selectedCropseasons).then((variables: any[]) => {
      selectedVariables.forEach((selectedVariable: AnalyzeFilter) => {
        const isVariableDisabled = !variables
          .flat()
          .filter(Boolean)
          .map((variable) => {
            //TODO - remove name after API responds with Id's everywhere
            const variableId = variable.id || variable.name;
            return variableId;
          })
          .includes(selectedVariable.value);
        dispatch(
          analyzeActions.updateVariable({ ...selectedVariable, disabled: isVariableDisabled })
        );
      });
    });
  }, [selectedCropseasons, from, resolution, to, accountId]);

  const { renderVariableOption } = useGetVariableOption();
  const { getLocationOptions, getVarietyOptions } = useGetSidebarFilterOptions();

  const applyFilters = useCallback(
    (input: any, applyFilterLength: boolean = true) => {
      const locationValues = locations.map(({ name }) => name);
      const varietiesValues = varieties.map(({ name }) => name);
      // const yearsValues = years.map(({ value }) => value);
      //TODO commented the years selection for when Corjan decides to put it back in
      return input.filter((cropseason: CropSeason) => {
        return (
          ((applyFilterLength && !locationValues.length) ||
            locationValues.includes(cropseason.location?.name)) &&
          ((applyFilterLength && !varieties.length) ||
            varietiesValues.includes(cropseason.variety?.name))
          // &&
          // (!yearsValues.length ||
          //   yearsValues.includes(dayjs(cropseason.transplant_date).get('year')))
        );
      });
    },
    [locations, varieties]
  );

  const handleFilterChange = useCallback(
    (name: string) => (value: CropSeason[] | Variety[] | Location[] | Year[]) => {
      setUrlState({
        ...urlState,
        [name]: value.map((data) => {
          if ('id' in data) {
            return data.id;
          }
          return data.name;
        }),
      });

      dispatch(analyzeActions.setCropeasons({ [name]: value }));
    },
    [dispatch, setUrlState, urlState]
  );

  const handleTimeFilterChange = useCallback(
    (name: string) => (value: any) => {
      if (name === 'to' || name === 'from') {
        dispatch(analyzeActions.setSettings({ [name]: value.unix() }));
      } else {
        setUrlState((previousValue) => ({ ...previousValue, resolution: value }));
        dispatch(analyzeActions.setSettings({ [name]: value }));
      }
    },
    [dispatch, setUrlState]
  );

  useEffect(() => {
    if (handleResolutionError(from, to, resolution) || variables.length === 0) {
      onVariablesChange([]);
    } else {
      if (groupPerHour && dayProfilesData) {
        onVariablesChange(dayProfilesData);
      } else if (!groupPerHour && analyzeData) {
        onVariablesChange(analyzeData);
      }
    }
  }, [
    dayProfilesData,
    analyzeData,
    groupPerHour,
    onVariablesChange,
    from,
    to,
    resolution,
    variables.length,
  ]);

  const getCollapseText = useMemo((): string => {
    if (isAllvariableCollapsed) {
      return translate('Analyze variable section expand all');
    } else {
      return translate('Analyze variable section collapse all');
    }
  }, [isAllvariableCollapsed]);

  useEffect(() => {
    if (!cropSeasons) {
      return;
    }
    const filteredSeasons = applyFilters(cropSeasons, true);

    const filteredSelectedSeasonSeasons = applyFilters(selectedCropseasons, true);
    dispatch(analyzeActions.setCropeasons({ selectedCropseasons: filteredSelectedSeasonSeasons }));

    setFilteredCropSeasons(filteredSeasons);
  }, [locations, varieties, cropSeasons, applyFilters, dispatch]);

  useEffect(() => {
    setSearchFilteredCropSeasons(filteredCropSeasons);
  }, [filteredCropSeasons]);

  useEffect(() => {
    if (selectedCropseasons.length > 0 && !handleResolutionError(from, to, resolution)) {
      getAllVariables(selectedCropseasons).then((variables) => {
        //TODO: remove name after API responds with Id's everywhere
        return setVariables(
          filterUniqueArrayElements(variables.flat(), 'id', 'name').filter(
            (variable) => !ANALYZE_VARIABLES_BLACK_LIST.includes(variable.name)
          )
        );
      });
    } else {
      setVariables([]);
    }
  }, [selectedCropseasons, from, to, resolution, getAllVariables, accountId]);

  useEffect(() => {
    const defaultCropseasonId = defaultCropSeason?.accounts?.[accountId]?.default_cropseason_id;
    if (defaultCropseasonId && !selectedCropseasons.length) {
      const cropseason = filteredCropSeasons?.find((season) => season.id === defaultCropseasonId);
      if (cropseason) {
        dispatch(analyzeActions.setCropeasons({ selectedCropseasons: [cropseason] }));
      }
    } else if (!selectedCropseasons.length && filteredCropSeasons.length) {
      const firstCropseason = filteredCropSeasons[0];
      dispatch(analyzeActions.setCropeasons({ selectedCropseasons: [firstCropseason] }));
    }
  }, [defaultCropSeason, accountId, filteredCropSeasons]);

  const handleDatepickerChange = useCallback(
    (value: any) => {
      let from = value.start.unix();
      let to = value.end.unix();

      setUrlState((previousValue) => ({ ...previousValue, from, to }));
      dispatch(analyzeActions.setSettings({ from, to }));
    },
    [dispatch, setUrlState]
  );

  const filterCropseasonsBySearch = useCallback(
    (keyword: string) => {
      const cropSeasons = [...filteredCropSeasons];
      if (!cropSeasons) {
        return;
      }
      const filteredList = cropSeasons.filter((cropseason) =>
        cropseason.name.toLowerCase().includes(keyword.toLowerCase())
      );
      setSearchFilteredCropSeasons(filteredList);
    },
    [filteredCropSeasons]
  );

  const toggleGroupPerHour = (toggleValue: boolean) => {
    dispatch(analyzeActions.toggleGroupPerHour());

    if (toggleValue === true) {
      setPreviousResolution(resolution);
      dispatch(analyzeActions.setSettings({ resolution: RESOLUTION._1_hour }));
      setUrlState((previousValue) => ({
        ...previousValue,
        resolution: '1_hour' as Resolution,
        groupPerHour: toggleValue,
      }));
    } else {
      if (previousResolution) {
        dispatch(
          analyzeActions.setSettings({ resolution: previousResolution, groupPerHour: toggleValue })
        );
        setUrlState((previousValue) => ({
          ...previousValue,
          resolution: previousResolution,
          groupPerHour: toggleValue,
        }));
      }
    }
  };

  function handleDatesError() {
    if (to < from) {
      return translate('Datepicker - error - start date before end date');
    }
  }

  return (
    <div className='analyze-sidebar-container'>
      {!isSidebarOpen && (
        <button
          className='c-hand btn expand'
          onClick={() => dispatch(analyzeActions.openSidebar())}
        >
          <More className='icon-fill' />
        </button>
      )}

      <SideSlide
        title={translate('Analyze page - Filter menu - settings')}
        isOpen={isSidebarOpen}
        onClose={() => dispatch(analyzeActions.closeSidebar())}
        hasHeader={true}
      >
        <div className='side-slide'>
          <div className='top-section'>
            <h5 className='subtitle'>CropSeasons</h5>
            <div className='dropdown'>
              <MultiselectDropDown
                optionList={getLocationOptions()}
                onChange={handleFilterChange('locations')}
                placeholder={translate('Analyze select location')}
                initialValues={locations}
              ></MultiselectDropDown>
            </div>
            <div className='dropdown'>
              <MultiselectDropDown
                optionList={getVarietyOptions()}
                onChange={handleFilterChange('varieties')}
                placeholder={translate('Analyze select variety')}
                initialValues={varieties}
              ></MultiselectDropDown>
            </div>
            {/* <div className='dropdown'>
              <MultiselectDropDown
                optionList={getYearOptions()}
                onChange={handleFilterChange('years')}
                placeholder={translate('Analyze select year')}
                initialValues={years}
              ></MultiselectDropDown>
            </div> */}
            <div className='dropdown multi-select'>
              <MultiSelectChipsDropdown
                optionList={searchFilteredCropSeaons}
                displayProperty='name'
                searchOptions={{
                  placeholder: translate('CropSeason selector - search cropseason'),
                  onChange: filterCropseasonsBySearch,
                }}
                placeholder={translate('Analyze - CropSeason selector')}
                setOnChange={handleFilterChange(SELECTED_CROPSEASON_FILTER)}
                initialValue={selectedCropseasons}
              />
            </div>
            <hr />
            <h5 className='subtitle'>{translate('Analyze Time section')}</h5>
            <div className='date-container'>
              <DatePickerDropdown
                onChangeDropdown={handleDatepickerChange}
                initialValues={{ start: dayjs.unix(from), end: dayjs.unix(to) }}
                required
                customRangePosition={
                  windowWidth > BREAKPOINT_TABLET ? 'custom-range-left' : 'custom-range-right'
                }
                cropSeasonDates={
                  selectedCropseasons.length
                    ? getCropSeasonsTimeRange(selectedCropseasons)
                    : undefined
                }
                customRangePositionStrategy='fixed'
                requiredText={handleDatesError()}
              />
            </div>
            <DropDown
              initialValue={getResolutionTranslated(resolution)}
              optionList={getResolutionsInitialValues()}
              displayProperty='displayValue'
              setOnChange={(_, value) => handleTimeFilterChange('resolution')(value.value)}
              disabled={groupPerHour}
            />
            <div className='resolution-error'>{handleResolutionError(from, to, resolution)}</div>

            <div className='analyze-group-per-hour-with-info'>
              <div className={'analyze-group-per-hour '}>
                <span className={'analyze-group-per-hour-text'}>
                  {translate('Analyze page - toggle - groupPerHour')}
                </span>

                <ToggleSwitch
                  initialValue={groupPerHour}
                  onToggle={(toggleValue) => {
                    toggleGroupPerHour(toggleValue);
                  }}
                />
              </div>
            </div>
          </div>
          <div className='bottom-section'>
            <hr />
            <h5 className='subtitle'>{translate('Analyze - sidebar - variables')}</h5>
            <SearchInput
              placeholder={translate('Analyze: search variable')}
              onChange={(event) => dispatch(analyzeActions.setKeyword(event.target.value))}
              value={keyword}
              disabled={!hasVariables}
              disabledtext={translate('Analyze page - no-variables - search disabled text')}
              type={SearchInputConfig.StyleDark}
            ></SearchInput>
            {hasVariables && (
              <span
                className='collapse link-light'
                onClick={() => dispatch(analyzeActions.toggleCollapse())}
              >
                {getCollapseText}
              </span>
            )}
          </div>
          <div className='accordions-container'>
            {mapVariablesToFilterOptions(variables, selectedLanguage)?.map((variable) => {
              const visibleOptions = variable.options.filter((option: { label: string }) => {
                return option.label.toLowerCase().includes(keyword.toLowerCase());
              });
              return (
                <div key={variable.type}>
                  <Accordion
                    title={variable.type}
                    isInitialOpen={false}
                    isCollapsed={isAllvariableCollapsed}
                    renderElement={renderVariableOption}
                    options={variable.options}
                    visibleOptions={visibleOptions}
                    hasCheck={true}
                  ></Accordion>
                </div>
              );
            })}
          </div>
        </div>
      </SideSlide>
    </div>
  );
};
export default Sidebar;
