import { PLAN_VARIABLES_OLD_NAME_ID_MAPPING, activeCropseasonInitialValue } from 'config/constants';
import dayjs from 'dayjs';
import { KeyValue } from 'interfaces/keyvalue';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { cropseasonSelector } from 'redux/slices/cropseason.slice';
import { trackFilterSelector } from 'redux/slices/trackFilterSlice';
import { useGetPlanTimeseriesDataQuery } from 'services/plan.service';
import { useGetPlansQuery } from 'services/plans.service';

export function useGetDataForPlan(
  variables: KeyValue<string>,
  resolutionData?: string,
  startDate?: string,
  endDate?: string
) {
  const { dates, resolution } = useAppSelector(trackFilterSelector);
  const { id: cropseasonId } = useAppSelector(cropseasonSelector);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { data: plans } = useGetPlansQuery(
    { accountId, cropseasonId },
    { skip: !accountId || !cropseasonId }
  );

  const planId = plans?.[0]?.id || 0;
  const planDetails = plans?.[0];
  const { data: plan, isSuccess } = useGetPlanTimeseriesDataQuery(
    {
      startDate: startDate || dayjs.unix(dates.start).toISOString(),
      endDate: endDate || dayjs.unix(dates.end).toISOString(),
      planId,
      resolution: resolutionData || resolution.value,
      variables: variables,
      accountId: accountId,
    },
    {
      skip:
        cropseasonId === activeCropseasonInitialValue.id ||
        !planId ||
        !accountId ||
        !resolution?.value ||
        !variables,
    }
  );

  return {
    plan: isSuccess ? plan : undefined,
    planDetails,
  };
}
