import React from 'react';
import './EnvironmentalErrors.scss';

interface IEnvironmentalErrorsProps {}

const EnvironmentalErrors: React.FC<IEnvironmentalErrorsProps> = () => {
  return (
    <div className='env-errors-container'>
      <h1>Application under maintenance</h1>
      <h4>
        We apologize for the inconvenience.
        <br />
        Our web application is currently down for maintenance. We appreciate your patience and will
        have it up and running as soon as possible.
        <br />
        Thank you for understanding.
      </h4>
    </div>
  );
};
export default EnvironmentalErrors;
