import React from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import RootLayout from 'layouts/RootLayout';
import Analyze from 'views/Analyze/Analyze';
import Climate from 'views/Climate/Climate';
import CropStatus from 'views/CropStatus/CropStatus';
import Dashboard from 'views/Dashboard/Dashboard';
import EditPlans from 'views/EditPlans/EditPlans';
import FreshWeightGain from 'views/FreshWeightGain/FreshWeightGain';
import MyPlans from 'views/MyPlans/MyPlans';
import PlansComparisonStep from 'views/PlanSettings/PlanCreation/PlansComparisonStep/PlansComparisonStep';
import CompareStrategies from 'views/PlanSettings/PlanCreation/CompareStrategies/CompareStrategies';
import WaitingPage from 'views/PlanSettings/PlanCreation/Waiting/Waiting';
import PlanSettings from 'views/PlanSettings/PlanSettings';
import Production from 'views/Production/Production';
import Profile from 'views/Profile/Profile';
import StrategyPreview from 'views/StrategyPreview/StrategyPreview';
import WaterEfficiency from 'views/WaterEfficiency/WaterEfficiency';
import WaterManagement from 'views/WaterManagement/WaterManagement';
import Notification from 'views/Notification/Notification';
import NotificationSettings from 'views/NotificationSettings/NotificationSettings';
import YieldPrediction from 'views/YieldPrediction/YieldPrediciton';
import ImportData from 'views/ImportData/ImportData';

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      { path: 'climate', element: <Climate /> },
      { path: 'water-management', element: <WaterManagement /> },
      { path: 'fresh-weight-gain', element: <FreshWeightGain /> },
      { path: 'water-efficiency', element: <WaterEfficiency /> },
      { path: 'crop-status', element: <CropStatus /> },
      { path: 'production', element: <Production /> },
      { path: 'plan-settings', element: <PlanSettings /> },
      { path: 'plan-compare-graph', element: <PlansComparisonStep /> },
      { path: 'my-plans', element: <MyPlans /> },
      { path: 'plan/edit/:id', element: <EditPlans /> },
      { path: 'plan/preview/:id', element: <EditPlans isPreview /> },
      { path: 'strategy/preview/:planId/:shopId/:strategyId', element: <StrategyPreview /> },
      { path: 'analyze', element: <Analyze /> },
      { path: 'notification', element: <Notification /> },
      { path: 'profile', element: <Profile /> },
      { path: 'shop/waiting-page/:id', element: <WaitingPage /> },
      { path: 'shop/:id', element: <CompareStrategies /> },
      { path: 'notification-settings', element: <NotificationSettings /> },
      { path: 'yield-prediction', element: <YieldPrediction /> },
      { path: 'import-data', element: <ImportData /> },
      { path: '*', element: <Navigate to='/dashboard' /> },
    ],
  },
]);

const Router: React.FC = () => {
  return <RouterProvider router={router} />;
};
export default Router;
