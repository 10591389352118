export function compare({
  operator,
  input,
  threshold,
}: {
  operator: string;
  input: number;
  threshold: number;
}) {
  switch (operator) {
    case 'lt':
      return input < threshold;
    case 'le':
      return input <= threshold;
    case 'gt':
      return input > threshold;
    case 'ge':
      return input >= threshold;
    default:
      return false;
  }
}
