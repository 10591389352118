import React, { ReactNode } from 'react';

import './Card.scss';

import useWindowSize, { WindowSize } from 'hooks/useWindowSize';

import { roundToDecimals } from 'utils/roundToDecimals';
import {
  getCardTitleTranslated,
  getTrackHeaderIcon,
  getTrackStatus,
  getTrackStatusIcon,
  getVariableDefinitionTranslated,
  getVariableNameTranslated,
  getVariableUnitTranslated,
} from 'utils/cards.util';

import { BREAKPOINT_MOBILE } from 'config/constants';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';

interface CardProps {
  name: string;
  variableName: string;
  variableId: string;
  value?: number;
  textValue?: string;
  status: string;
  unit: string;
  children?: ReactNode;
  className?: string;
  decimals?: number;
}

const renderCardBodyMobile = (
  width: number,
  variableId: string,
  name: string,
  variableName: string,
  selectedLanguage: string
) => {
  return (
    width <= BREAKPOINT_MOBILE && (
      <>
        <span className='card-title-mobile'>
          {getCardTitleTranslated(variableId, name, selectedLanguage)}
        </span>
        <span className='card-details-mobile'>
          {getVariableNameTranslated(variableId, variableName, selectedLanguage)}
        </span>
      </>
    )
  );
};

const renderGraphMobile = (width: number, element: ReactNode) => {
  return width <= BREAKPOINT_MOBILE && <div className='track-card-footer-graph'>{element}</div>;
};

const getValue = (decimals: number, value?: number, textValue?: string, unit?: string) => {
  if (textValue) {
    return <div className='track-card-value text-value'>{textValue || ''}</div>;
  } else {
    return (
      <>
        {roundToDecimals(value || 0, decimals)}
        <span>&nbsp;{unit}</span>
      </>
    );
  }
};

const Card: React.FC<CardProps> = ({
  name,
  variableName,
  variableId,
  value,
  textValue,
  status,
  unit,
  className,
  children,
  decimals = 1,
}) => {
  const windowSize: WindowSize = useWindowSize();
  const { selectedLanguage } = useAppSelector(languageSelector);
  return (
    <div className={`card track-card ${getTrackStatus(status)} ${className}`}>
      <div className='card-header track-card-header'>
        {getTrackHeaderIcon(name)}
        <div className='track-card-title'>
          {getCardTitleTranslated(variableId, name, selectedLanguage)}
        </div>
        <div className='icon-container'>{getTrackStatusIcon(status)}</div>
      </div>
      <div className='card-body track-card-body'>
        {renderCardBodyMobile(windowSize.width, variableId, name, variableName, selectedLanguage)}
        <div className='track-card-value'>
          {getValue(
            decimals,
            value,
            textValue,
            getVariableUnitTranslated(variableId, unit, selectedLanguage)
          )}
        </div>
        {windowSize.width > BREAKPOINT_MOBILE && <div className='track-card-graph'>{children}</div>}
      </div>
      <div className='card-footer track-card-footer'>
        {renderGraphMobile(windowSize.width, children)}
        <div className='track-card-footer-content'>
          <span>{getVariableNameTranslated(variableId, variableName, selectedLanguage)}: </span>{' '}
          {getVariableDefinitionTranslated(variableId, selectedLanguage)}
        </div>
      </div>
    </div>
  );
};
export default Card;
