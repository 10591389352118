import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { customPlanSelector, setCustomPlan } from 'redux/slices/customPlan.slice';
import { useGetCustomSettingsTemperatureStrategiesQuery } from 'services/plans.service';

enum temperatureStrategyActions {
  ADD = 'add_temperature_strategy',
  CHANGE = 'change_temperature_strategy',
  REMOVE = 'remove_temperature_strategy',
}

export default function useTemperatureStrategies(temperatureStrategiesParams: any): any {
  const currentPlanTemperatureRows =
    useAppSelector(customPlanSelector).customPlanState?.phase_temp_strategies;
  const updatedPlanTemperatureRows =
    useAppSelector(customPlanSelector).customPlanState?.phase_temp_strategies_new_dates;

  const dispatch = useDispatch();

  const [temperatureStrategiesParameters, setTemperatureStrategiesParams] = useState(
    temperatureStrategiesParams
  );

  const { data: newRowsTemperatureStrategy } = useGetCustomSettingsTemperatureStrategiesQuery(
    {
      transplant_date: dayjs
        .unix(temperatureStrategiesParameters.transplant_date)
        .format('YYYY-MM-DD'),
      end_date: dayjs.unix(temperatureStrategiesParameters.end_date).format('YYYY-MM-DD'),
      AL_enabled: temperatureStrategiesParameters.AL_enabled,
      AL_max_capacity: temperatureStrategiesParameters.AL_max_capacity,
      AL_percentage_LED: temperatureStrategiesParameters.AL_percentage_LED,
      AL_radiation_threshold: temperatureStrategiesParameters.AL_radiation_threshold,
      location_id: temperatureStrategiesParameters.location_id,
      phase_start: temperatureStrategiesParameters.newDateRow,
    },
    {
      skip: !temperatureStrategiesParameters || !temperatureStrategiesParameters.newDateRow,
    }
  );

  /**
   * This function is used to get the new array of dates for the API server call
   * afrer the action of adding or changing or removing a date
   * @param date: string - the date to be updated
   * @param index: number
   * @param action: temperatureStrategyActions
   * @returns sortedDates
   *
   */
  const getUpdatedListOfDates = (
    date: string,
    index: number,
    action: temperatureStrategyActions
  ) => {
    if (!currentPlanTemperatureRows) {
      return;
    }
    let allDates: any[] = [];

    if (action === temperatureStrategyActions.REMOVE) {
      let filterPlanTemperature = currentPlanTemperatureRows.filter(
        (row) => row.phase_start !== date
      );

      allDates = filterPlanTemperature.map((row) => {
        return row.phase_start;
      });
    }
    if (action === temperatureStrategyActions.ADD) {
      allDates = currentPlanTemperatureRows.map((row) => {
        return row.phase_start;
      });
      allDates.push(date);
    }
    if (action === temperatureStrategyActions.CHANGE) {
      allDates = currentPlanTemperatureRows.map((row) => {
        if (row.phase === index) {
          return date;
        } else {
          return row.phase_start;
        }
      });
    }
    const sortedDates = allDates.sort((a, b) => {
      return dayjs(a).unix() - dayjs(b).unix();
    });
    return sortedDates.filter((date) => date);
  };

  const detectChangedRows = (newRows: any, oldRows: any) => {
    // Create a set of unique dates from the oldRows array
    const oldRowDates = _getUniquePhaseStarts(oldRows);

    // Get new rows that were added (dates not in oldRows)
    const newAddedRows = _getNewAddedRows(newRows, oldRowDates);

    // Get changed rows (rows with different values in newRows compared to oldRows)
    // If row is changed by the user, keep the user input
    const changedRows = _getChangedRows(newRows, oldRows, updatedPlanTemperatureRows ?? []);

    // Combine newAddedRows and changedRows, and map them to the desired format
    const newUpdatedRows = _combineAndMapRows(newAddedRows, changedRows);
    let finalRows = newUpdatedRows;
    if (updatedPlanTemperatureRows) {
      finalRows = [
        ...(updatedPlanTemperatureRows ?? []),
        ...newUpdatedRows.filter(
          (newRow) => !updatedPlanTemperatureRows.some((oldRow) => newRow.date === oldRow.date)
        ),
      ];
    }
    // Dispatch the updatedRows to Redux
    dispatch(
      setCustomPlan({
        phase_temp_strategies_new_dates: finalRows,
      })
    );
  };

  // Helper function to get unique phase_start values
  const _getUniquePhaseStarts = (rows: any[]) => {
    return new Set(rows.map((row) => row.phase_start));
  };

  // Helper function to get new rows that were added (phase_start not in oldRows)
  const _getNewAddedRows = (newRows: any[], oldRowDates: Set<any>) => {
    return newRows.filter((row) => !oldRowDates.has(row.phase_start));
  };

  // Helper function to get rows with different values in newRows compared to oldRows
  const _getChangedRows = (newRows: any[], oldRows: any[], updatedPlanTemperatureRows: any[]) => {
    return newRows.filter((newRow) => {
      const oldRow = oldRows.find((row) => row.phase_start === newRow.phase_start);
      const updatedPlanRow = updatedPlanTemperatureRows.find(
        (row) => row.date === newRow.phase_start
      );
      if (oldRow) {
        return Object.keys(newRow).some(
          (key) => key !== 'phase' && newRow[key] !== oldRow[key] && !updatedPlanRow?.touched
        );
      }
      return false;
    });
  };

  // Helper function to combine newAddedRows and changedRows and map them to the desired format
  const _combineAndMapRows = (newAddedRows: any[], changedRows: any[]) => {
    return [...newAddedRows, ...changedRows].map((row, index) => ({
      date: row.phase_start,
      touched: false,
    }));
  };

  const handleDateChange = (index: number) => (date: any) => {
    if (currentPlanTemperatureRows) {
      const dateAdded = date.start ? date.start.format('YYYY-MM-DD') : undefined;

      let action = temperatureStrategyActions.ADD;
      if (index <= currentPlanTemperatureRows.length) {
        action = temperatureStrategyActions.CHANGE;
      }

      setTemperatureStrategiesParams({
        ...temperatureStrategiesParameters,
        newDateRow: getUpdatedListOfDates(dateAdded, index, action),
      });
    }
  };

  const handleRemoveRow = (phase: any) => {
    if (updatedPlanTemperatureRows) {
      let updatedRows = updatedPlanTemperatureRows.filter((row) => row.date !== phase.phase_start);
      dispatch(
        setCustomPlan({
          phase_temp_strategies_new_dates: updatedRows,
        })
      );
    }
    if (currentPlanTemperatureRows) {
      let temperatureStrategiesRows = currentPlanTemperatureRows.filter(
        (row) => row.phase_start !== phase.phase_start
      );
      dispatch(
        setCustomPlan({
          phase_temp_strategies: temperatureStrategiesRows,
        })
      );
      setTemperatureStrategiesParams({
        ...temperatureStrategiesParameters,
        newDateRow: getUpdatedListOfDates(
          phase.phase_start,
          phase.phase,
          temperatureStrategyActions.REMOVE
        ),
      });
    }
  };

  //If the temperature setting was updated by the user and after adding a new row the API send a new
  //value for the updated row, ignore the teperature send by the API and keep the user input
  const getUpdatatedRows = (updatedNewRowsTemperatureStrategy: any) => {
    let finalRows = [];

    finalRows = updatedNewRowsTemperatureStrategy.map((row: any) => {
      if (updatedPlanTemperatureRows) {
        let updatedRow = updatedPlanTemperatureRows.find(
          (updatedRow) => updatedRow.date === row.phase_start
        );
        if (updatedRow && updatedRow.touched) {
          return {
            ...row,
            temp_air: updatedRow.temp_air,
          };
        }
      }
      return row;
    });

    return finalRows;
  };

  useEffect(() => {
    if (newRowsTemperatureStrategy) {
      let updatedNewRowsTemperatureStrategy = [...newRowsTemperatureStrategy];

      //set changed rows
      detectChangedRows(updatedNewRowsTemperatureStrategy, currentPlanTemperatureRows);

      dispatch(
        setCustomPlan({
          phase_temp_strategies: getUpdatatedRows(updatedNewRowsTemperatureStrategy),
        })
      );
    }
  }, [newRowsTemperatureStrategy]);

  return {
    handleDateChange,
    handleRemoveRow,
    newRowsTemperatureStrategy,
  };
}
