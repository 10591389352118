import React from 'react';

import { ReactComponent as Info } from 'styles/icons/info.svg';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import styles from './TitleTooltip.module.scss';

interface TitleTooltipProps {
  text?: string;
}

const TitleTooltip: React.FC<TitleTooltipProps> = ({ text }) => {
  return (
    <div>
      <Info
        width={20}
        className='icon-light c-hand'
        id={`tooltip-anchor${text}`}
        data-tooltip-variant='light'
      />
      <ReactTooltip anchorSelect={`#tooltip-anchor${text}`} place='top' positionStrategy='fixed'>
        <div className={styles.content}>{text}</div>
      </ReactTooltip>
    </div>
  );
};
export default TitleTooltip;
