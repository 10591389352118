import { baseApi } from 'redux/slices/baseApi.slice';
import { CropStatusVariables } from 'interfaces/crop-status-variables';
import {
  parseDashboardClimateGraphData,
  parseDashboardFreshWeightGainGraphData,
  parseDashboardProductionGraphData,
  parseDashboardWaterEfficiencyGraphData,
} from 'utils/parseDashboardApiGraphData.util';
import { DashboardData } from 'interfaces/trackings';

const getChartData = (data: any) => {
  if (data.name === 'climate') {
    return parseDashboardClimateGraphData(data);
  } else if (data.name === 'fresh_weight_gain') {
    return parseDashboardFreshWeightGainGraphData(data);
  } else if (data.name === 'water_efficiency') {
    return parseDashboardWaterEfficiencyGraphData(data);
  } else if (data.name === 'crop_status') {
    return mapCropStatusVariables(data.timeseries);
  } else if (data.name === 'production') {
    return parseDashboardProductionGraphData(data);
  }
};

export const mapCropStatusVariables = (cropStatusVariables?: CropStatusVariables) => {
  if (!cropStatusVariables || !cropStatusVariables.weeknr) {
    return [];
  }

  return cropStatusVariables.weeknr.map((_, index) => ({
    x: cropStatusVariables.plant_load?.[index] || 0,
    y: cropStatusVariables.stem_diameter?.[index] || 0,
  }));
};

const dashboardApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTrackings: builder.query<DashboardData, { cropSeasonId: number, accountId: number }>({
      query: ({ cropSeasonId, accountId }) => `cropseasons/${cropSeasonId}/dashboard?current_account_id=${accountId}`,
      transformResponse: (rawResults: { result: { tiles: any } }): any => {
        const response = rawResults.result.tiles;
        let finalResponse = {};

        response.forEach((tracking: any) => {
          finalResponse = {
            ...finalResponse,
            [tracking.name]: {
              ...tracking,
              timeseries: getChartData(tracking),
            },
          };
        });
        return finalResponse;
      },
    }),
  }),
});

const enhancedDashboardApi = dashboardApi.enhanceEndpoints({
  addTagTypes: ['Trackings'],
  endpoints: {
    getTrackings: {
      providesTags: ['Trackings'],
    },
  },
});

export const { useGetTrackingsQuery } = enhancedDashboardApi;
