import './DatePicker.scss';

import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from 'interfaces/date-range';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CalendarIcon } from 'styles/icons/calendar.svg';
import { getFirstWeekOfGivenDate } from 'utils/time.util';

import { DatePickerCalendar } from './DatePickerCalendar/DatePickerCalendar';
import { DatePickerSelector } from './DatePickerSelector/DatePickerSelector';
import TooltipCustom from 'components/TooltipCustom/TooltipCustom';
import { getUniqueId } from 'utils/getUniqueId';

const DATE_PLACEHOLDER = '--/--/----';
const DATE_FORMAT = 'DD/MM/YYYY';

export interface DatePickerProps {
  startDate?: Dayjs;
  endDate?: Dayjs;
  isDateRange?: boolean;
  weekMode?: boolean;
  label?: string;
  onChange?: (newDate: DateRange | Dayjs) => void;
  labelPosition?: string;
  dropDownPosition?: string;
  disabled?: boolean;
  initiallyOpen?: boolean;
  isCustomRange?: boolean;
  open?: boolean;
  required?: boolean;
  insideTooltip?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  startDate,
  endDate,
  label,
  isDateRange = false,
  weekMode = false,
  disabled = false,
  onChange = () => {},
  labelPosition = 'label-top',
  dropDownPosition = 'dropdown-datepicker-right',
  initiallyOpen = false,
  isCustomRange = false,
  open = false,
  required = false,
  insideTooltip = false,
}) => {
  const [shownDate, setShownDate] = useState(dayjs());
  const [shownWeek, setShownWeek] = useState(0);
  const [isActive, setIsActive] = useState(initiallyOpen);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate?.clone());
  const [selectedEndDate, setSelectedEndDate] = useState(endDate?.clone());
  const selfRef = useRef<HTMLDivElement>(null);
  const id = getUniqueId();

  useEffect(() => {
    if (!startDate) {
      return;
    }
    if (!(startDate && weekMode)) {
      setSelectedStartDate(startDate?.clone());
      setSelectedEndDate(endDate?.clone());
      if (startDate) {
        setShownDate(startDate?.clone());
      }
      return;
    }
    const [firstDay, lastDay, week] = getFirstWeekOfGivenDate(startDate);
    setShownWeek(week);
    setShownDate(firstDay);
    setSelectedStartDate(firstDay);
    setSelectedEndDate(lastDay);
  }, [startDate, endDate]);

  const updateDates = (value: DateRange | Dayjs) => {
    if (weekMode) {
      const range = value as DateRange;

      const [, , week] = getFirstWeekOfGivenDate(range.start);

      onChange({ start: range.start, end: range.end });

      setShownWeek(week);
      setShownDate(range.start);
      // Close the DatePicker dropdown after selecting the date
      setIsActive(false);
      return;
    }
    if (isDateRange) {
      const range = value as DateRange;
      setSelectedStartDate(range.start);
      setSelectedEndDate(range.end);
    } else {
      const date = value as Dayjs;
      setSelectedStartDate(date);
    }

    onChange(value as Dayjs);
  };

  useEffect(() => {
    function handleClickOutside({ target }: MouseEvent) {
      if (isCustomRange) {
        return;
      }
      if (selfRef.current && !selfRef.current.contains(target as Node)) {
        setIsActive(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selfRef]);

  useEffect(() => {
    if (!isCustomRange) {
      return;
    }
    if (open) {
      return setIsActive(true);
    }
    return setIsActive(false);
  }, [open]);

  const renderDatePickerCalendar = () => {
    return (
      <div className='datepicker-menu'>
        <div className={'date-picker-dropdown'}>
          <DatePickerSelector
            shownDate={shownDate}
            increment={() => {
              setShownDate(shownDate.add(1, 'month'));
            }}
            decrement={() => {
              setShownDate(shownDate.add(-1, 'month'));
            }}
          />
          <DatePickerCalendar
            startDate={weekMode ? selectedStartDate : startDate}
            endDate={weekMode ? selectedEndDate : endDate}
            shownDate={shownDate}
            isDateRange={isDateRange}
            onChange={updateDates}
            weekMode={weekMode}
            shownWeek={shownWeek}
          />
        </div>
      </div>
    );
  };

  const renderTooltipCalendar = () => {
    return (
      <TooltipCustom
        anchorSelect={`#date-picker-${id}`}
        dataForId={`#date-picker-${id}`}
        renderContent={renderDatePickerCalendar}
        showTooltip
        backgroundColor='#fff'
        isOpen={true}
        clickable
        style={{ zIndex: 1, backgroundColor: 'white', padding: '0' }}
        positionStrategy={'fixed'}
      />
    );
  };

  const renderCalnedar = () => {
    if (insideTooltip) {
      return renderTooltipCalendar();
    } else {
      return renderDatePickerCalendar();
    }
  };

  return (
    <div
      className={`date-picker ${labelPosition} ${weekMode ? 'week-mode' : ''}`}
      ref={selfRef}
      id={`date-picker-${id}`}
    >
      <label className={`${required ? 'required' : ''} `}>{label}</label>
      <div
        className={`dropdown ${disabled ? ' disabled' : ''} ${dropDownPosition} ${
          isActive ? 'show-datepicker' : ''
        } ${isCustomRange && open ? 'custom-range focus' : ''} `}
      >
        <div
          className={`dropdown-toggle  `}
          tabIndex={0}
          onClick={() => !disabled && !isCustomRange && setIsActive(!isActive)}
        >
          <span className='text'>
            {weekMode
              ? `Week ${shownWeek}`
              : `${selectedStartDate?.format(DATE_FORMAT) || DATE_PLACEHOLDER}  ${
                  isDateRange
                    ? selectedEndDate
                      ? ` - ${selectedEndDate.format(DATE_FORMAT)}`
                      : ` - ${DATE_PLACEHOLDER}`
                    : ''
                }`}
          </span>
          <CalendarIcon className='icon-light calendar-icon' />
        </div>
        {renderCalnedar()}
      </div>
    </div>
  );
};
