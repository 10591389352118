import { PLAN_VARIABLES_OLD_NAME_ID_MAPPING, VARIABLES_CLIMATE } from 'config/constants';
import { convertMolPerM2Day_ToMicromolPerM2Sec } from './unitConversion';
import { translate } from 'utils/translations.util';

/**
 * Map plan Variables to their converion unit.
 *
 */
export function getUnitConversionPlanValue(variableId: string, value: number): number | undefined {
  if (variableId === PLAN_VARIABLES_OLD_NAME_ID_MAPPING.dli) {
    // convert mol/m2/day to µmol/m2/s. Converts DLI to totalPAR
    return convertMolPerM2Day_ToMicromolPerM2Sec(value);
  }
  return value;
}

export const getVariableName = (variableId: string, language: string) => {
  if (
    variableId === PLAN_VARIABLES_OLD_NAME_ID_MAPPING.dli ||
    variableId === VARIABLES_CLIMATE.dli
  ) {
    return translate('totalPAR', language);
  }
  return translate(variableId, language);
};

export const getVariableDataMapper = (variable: string, timeseries: any, variableKey: string) => {
  if (variable === PLAN_VARIABLES_OLD_NAME_ID_MAPPING.dli && timeseries) {
    return timeseries.map((item: any) => {
      if (item.value) {
        return {
          ...item,
          value: [
            convertMolPerM2Day_ToMicromolPerM2Sec(item.value[0]),
            convertMolPerM2Day_ToMicromolPerM2Sec(item.value[1]),
          ],
        };
      } else {
        return {
          ...item,
          [variableKey]: convertMolPerM2Day_ToMicromolPerM2Sec(item[variableKey]),
        };
      }
    });
  }

  return timeseries;
};

export const getVariableUnitMapper = (variable: string) => {
  switch (variable) {
    case PLAN_VARIABLES_OLD_NAME_ID_MAPPING.dli:
    case VARIABLES_CLIMATE.dli:
      return 'µmol/m2/s';
    default:
      return variable;
  }
};

export const getPlanToActualsMapper = (variable: string) => {
  switch (variable) {
    case PLAN_VARIABLES_OLD_NAME_ID_MAPPING.dli:
    case VARIABLES_CLIMATE.dli:
      return 'totalPAR';
    default:
      return variable;
  }
};

export const getActualsVariables = (variableObject: any) => {
  let result = { ...variableObject };
  if (result && result[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.dli]) {
    delete result?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.dli];
    result.totalPAR = 'totalPAR';
  }
  return result;
};
