import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'redux/store';
interface tableWidthsSliceState {
  [key: string]: number;
}

const initialState: tableWidthsSliceState = {};

export const tableWidthsSlice = createSlice({
  name: 'tableWidths',
  initialState,
  reducers: {
    setWidth: (state, { payload }) => {
      state[payload.key] = payload.value;
    },
  },
});

export const { setWidth } = tableWidthsSlice.actions;

export const tableWidthsSelector = (key: string) => (state: RootState) => state.tableWidths[key];

export default tableWidthsSlice.reducer;
