
import { Twilight } from 'interfaces/twilights';
import { baseApi } from 'redux/slices/baseApi.slice';

import { formatQueryString } from 'utils/queryString';
import { getUnix } from 'utils/time.util';

const miscApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTwilights: builder.query<
      Twilight[],
      {
        location_id: number;
        min_local_datetime: string;
        max_local_datetime: string;
      }
    >({
      query: ({ location_id, min_local_datetime, max_local_datetime }) => {
        const ENDPOINT = `/twilights?`;

        return (
          ENDPOINT +
          formatQueryString({
            location_id,
            min_local_datetime,
            max_local_datetime,
          })
        );
      },
      transformResponse: (rawResult: any): Twilight[] => {
        const twilights = Object.values(rawResult?.result?.twilights)[0] as [];
        const result = twilights.map((twilight: any) => ({
          timestamp: getUnix(twilight.local_datetime),
          isSunrise: twilight.is_sunrise,
        }));
        return result;
      },
    }),
  }),
});

export const { useGetTwilightsQuery } = miscApi;
