import { getSettingsTranslationKey } from 'config/notifications/getSettingsTranslationKey';
import { CropseasonNotification } from 'interfaces/cropseason-notification';
import { KeyValue } from 'interfaces/keyvalue';
import { baseApi } from 'redux/slices/baseApi.slice';
import { formatQueryString } from 'utils/queryString';
import { getUnix } from 'utils/time.util';
import { translate } from 'utils/translations.util';

interface CropseasonNotificationQueryParameters {
  cropseason_ids?: string[];
  current_account_id?: number;
  min_local_datetime?: string;
  max_local_datetime?: string;
}

interface CropseasonNotificationSettingsQueryParameters {
  cropseason_id: number;
  current_account_id?: number;
}

interface Result {
  cropseason_notifications: CropseasonNotification[];
}

interface SliderNotification {
  timestamp: number;
  value: number;
}

export interface NotificationSettingsThreshold {
  gt: number;
  ge: number;
  lt: number;
  le: number;
}

export interface Constraints {
  threshold?: Partial<NotificationSettingsThreshold>;
  threshold_lower?: Partial<NotificationSettingsThreshold>;
  threshold_upper?: Partial<NotificationSettingsThreshold>;
  // threshold_low and threshold_high are a variation of threshold_lower and threshold_upper
  // growth_rate_hr uses threshold_lower and threshold_upper in API by mistake
  threshold_low?: Partial<NotificationSettingsThreshold>;
  threshold_high?: Partial<NotificationSettingsThreshold>;
}

interface NotificationSetting {
  id: string;
  cropseason_id: number;
  type: string;
  enabled: boolean;
  settings: KeyValue<number>;
  defaults: {
    threshold: number;
  };
  constraints: Constraints;
}

  // For testing. Replace raw_response by mock_response
  // const mock_response = {
  //   "message": "Found 453 notifications.",
  //   "result": {
  //       "cropseason_notifications": [
  //           {
  //               "id": "2694cb8b-57fe-49c6-953d-40cff02cb1a5",
  //               "local_datetime": "2024-07-06T18:15:00",
  //               "cropseason": {
  //                   "id": 69377,
  //                   "name": "24 unlit Tomimaru Muchoo"
  //               },
  //               "location": {
  //                   "id": 56,
  //                   "name": "Experience Center"
  //               },
  //               "has_been_seen": true,
  //               "values": {
  //                   "value": 299.17,
  //                   "threshold": 300.0,
  //                   "unit": "ppm",
  //                   "graph": {
  //                       "resolution": "5_min",
  //                       "variables": [
  //                           "GHCO2C"
  //                       ]
  //                   }
  //               },
  //               "type": "hum_def_new"
  //           },
  //           {
  //               "id": "8a1d5634-a149-43cb-b988-10471e0064ab",
  //               "local_datetime": "2024-07-05T19:55:00",
  //               "cropseason": {
  //                   "id": 69377,
  //                   "name": "24 unlit Tomimaru Muchoo"
  //               },
  //               "location": {
  //                   "id": 56,
  //                   "name": "Experience Center"
  //               },
  //               "has_been_seen": false,
  //               "values": {
  //                   "value": 0.9,
  //                   "threshold": 1.0,
  //                   "unit": "g/m^3",
  //                   "graph": {
  //                       "resolution": "5_min",
  //                       "variables": [
  //                           "VADEFE"
  //                       ]
  //                   }
  //               },
  //               "type": "hum_def_low"
  //           },]}}
  


export const notificationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCropSeasonNotifications: builder.query<
      {
        sliderNotifications: [SliderNotification, SliderNotification][];
        notifications: CropseasonNotification[];
      },
      CropseasonNotificationQueryParameters
    >({
      query: ({ cropseason_ids, current_account_id, max_local_datetime, min_local_datetime }) => {
        const ENDPOINT = 'notifications/cropseason-notifications?';
        return (
          ENDPOINT +
          formatQueryString({
            cropseason_id: cropseason_ids,
            current_account_id,
            max_local_datetime,
            min_local_datetime,
          })
        );
      },
      transformResponse: (rawResult: { result: Result }) => {
        const sliderNotifications: [SliderNotification, SliderNotification][] = [];
        const result = rawResult.result.cropseason_notifications;
        result.reverse().forEach((data) =>
          sliderNotifications.push([
            {
              timestamp: getUnix(data.local_datetime),
              value: -1,
            },
            {
              timestamp: getUnix(data.local_datetime),
              value: 1,
            },
          ])
        );
        return { sliderNotifications, notifications: rawResult.result.cropseason_notifications };
      },
    }),
    readNotifications: builder.mutation<any, { accountId: number; notificationsIds: string[] }>({
      query: ({ accountId, notificationsIds }) => {
        const ENDPOINT = 'notifications/has-been-seen?';
        return {
          url:
            ENDPOINT +
            formatQueryString({
              current_account_id: accountId,
              id: notificationsIds,
            }),
          method: 'PATCH',
        };
      },
    }),
    getCropseasonNotificationSettings: builder.query<
      NotificationSetting[],
      CropseasonNotificationSettingsQueryParameters
    >({
      query: ({ cropseason_id, current_account_id }) => {
        const ENDPOINT = 'notifications/settings?';
        return (
          ENDPOINT +
          formatQueryString({
            cropseason_id,
            current_account_id,
          })
        );
      },
      transformResponse: (rawResult: { result: { settings: NotificationSetting[] } }) => {
        return rawResult.result.settings.sort((a, b) =>
          translate(getSettingsTranslationKey(a.type)).localeCompare(
            translate(getSettingsTranslationKey(b.type))
          )
        );
      },
    }),
    updateNotificationSetting: builder.mutation<
      void,
      { current_account_id: number; notificationSettingId: string; data: any }
    >({
      query: ({ notificationSettingId, current_account_id, data }) => {
        const ENDPOINT = `notifications/settings/${notificationSettingId}?`;
        return {
          method: 'PATCH',
          url: ENDPOINT + formatQueryString({ current_account_id }),
          body: data,
        };
      },
    }),
  }),
});

const enhanancedNotificationSettingsApi = notificationsApi.enhanceEndpoints({
  addTagTypes: ['Settings'],
  endpoints: {
    getCropseasonNotificationSettings: {
      providesTags: ['Settings'],
    },
    updateNotificationSetting: {
      invalidatesTags: ['Settings'],
    },
  },
});

export const {
  useGetCropSeasonNotificationsQuery,
  useReadNotificationsMutation,
  useGetCropseasonNotificationSettingsQuery,
  useUpdateNotificationSettingMutation,
} = enhanancedNotificationSettingsApi;
