import './Tooltip.scss';

import * as d3 from 'd3';
import React, { useCallback, useRef } from 'react';

import { hoverGraphDataTooltip } from 'interfaces/tooltip';

let mouseMoveTimer: NodeJS.Timeout;

type HoverPointKey = {
  [keyName: string]: number | undefined;
};

type HoverPoint = {
  data: HoverPointKey;
  timestamp: number;
  value?: Array<number>;
};

interface TooltipProps {
  width: number;
  height: number;
  xScale: any;
  yScale: any;
  anchorEl: any;
  dataToDisplay: any;
  resolution: string;
  tooltipName: string;
  tooltipDecimals: number;
  applyOffset?: boolean;
  yOffset?: number;
  drawTooltipLine?: boolean;
  drawTooltipPointShadow?: boolean;
  drawTooltipBorder?: boolean;
  customFormatter?: (input: any) => string;
  tooltipHoverDataCallback?: (input: any) => void;
  graphXInterval: [number, number];
  xAxisOffset: number;
}

const Tooltip: React.FC<TooltipProps> = ({
  xScale,
  yScale,
  width,
  height,
  anchorEl,
  dataToDisplay = [],
  resolution,
  tooltipName,
  tooltipDecimals = 1,
  applyOffset = true,
  yOffset = 0,
  drawTooltipLine = true,
  drawTooltipPointShadow = false,
  drawTooltipBorder = false,
  customFormatter,
  tooltipHoverDataCallback = () => {},
  graphXInterval,
  xAxisOffset,
  ...props
}) => {
  const ref = React.useRef(null);

  const isFirstDrawAfterMouseEnter = useRef(false);

  const getOffset = useCallback(
    (input: number) => {
      if (applyOffset) {
        return input;
      }
      return 0;
    },
    [applyOffset]
  );

  const drawLine = React.useCallback(
    (x: number) => {
      d3.select(ref.current)
        .select('.tooltipLine')
        .attr('x1', x + xAxisOffset)
        .attr('x2', x + xAxisOffset)
        .attr('y1', 10 + yOffset)
        .attr('y2', height - xAxisOffset / 2 + yOffset);
    },
    [ref, height, yOffset, xAxisOffset]
  );

  const bisectDate = d3.bisector((d: HoverPoint) => d?.timestamp)?.center;

  //From an array of points with timestamps, get the closest timestamp to the xDate
  const getClosestTimestamp = React.useCallback(
    (xDate: number): number => {
      let timeDiference = Infinity;
      let closestTimestamp = 0;
      let allElements = d3.select(ref.current).selectAll('.tooltipLinePoint');

      allElements.each(function (_: any, i: number) {
        let data = dataToDisplay[i]?.data || [];
        let graphXIntervalRange = {
          min: graphXInterval[0],
          max: graphXInterval[1],
        };

        //Tooltip Should disappear if ouside of closest range timestamp (if data points are not visible, tooltip should not be visible)
        data = data.filter(
          (d: any) =>
            d.timestamp >= graphXIntervalRange.min && d.timestamp <= graphXIntervalRange.max
        );

        const index = data.length ? bisectDate(data, xDate, 1) : 0;

        const d0 = data[index - 1];
        const d1 = data[index];
        const d = xDate - d0?.timestamp > d1?.timestamp - xDate ? d1 : d0;

        let tempdifference = Math.abs(xDate - d?.timestamp);
        if (tempdifference < timeDiference) {
          timeDiference = tempdifference;
          closestTimestamp = d?.timestamp;
        }
      });
      return closestTimestamp;
    },
    [dataToDisplay, bisectDate, graphXInterval]
  );

  const isAtCurentTimeStamp = React.useCallback(
    (date: number, xDate: number) => {
      const currentTimestampShow = getClosestTimestamp(xDate);
      return date === currentTimestampShow;
    },
    [getClosestTimestamp]
  );

  const isMinMaxArea = React.useCallback((d: HoverPoint) => {
    return d?.value?.length;
  }, []);

  const addHoverData = (
    hoverData: hoverGraphDataTooltip[],
    data: any,
    d: HoverPoint
  ): hoverGraphDataTooltip[] => {
    let keyName: string = data.keyName || '';
    hoverData.push({
      d,
      keyName: keyName,
      prefix: data?.prefix,
      unit: data?.unit,
      stroke: data?.stroke,
      scaling: data?.scaling,
      label: data?.label,
      color: data?.color,
      type: data?.type,
    });
    return hoverData;
  };

  const followPoints = React.useCallback(
    (event: any) => {
      const [x] = d3.pointer(event, anchorEl);
      const xDate = xScale.invert(x);

      let lineX = 0;
      let hoverData: hoverGraphDataTooltip[] = [];

      let dataCount = 0;

      d3.select(ref.current)
        .selectAll('.tooltipLinePoint')
        .attr('transform', (_, i) => {
          let data: any = dataToDisplay[i]?.data || [];
          let keyName: string = dataToDisplay[i]?.keyName || '';
          const index = data.length ? bisectDate(data, xDate, 1) : 0;
          const d0 = data[index - 1];
          const d1 = data[index];
          const d = xDate - d0?.timestamp > d1?.timestamp - xDate ? d1 : d0;

          let hasKey = d && (d.value?.length || d[keyName] || d[keyName] === 0);
          const color = dataToDisplay[i]?.color;

          if (!hasKey) {
            return 'translate(-200,-200)';
          }

          const xPos = xScale(d.timestamp);
          let yPos = -200;

          d3.select(`.circle-max-value-${tooltipName}`).attr('cx', -100).attr('cy', -100);

          if (isMinMaxArea(d) && isAtCurentTimeStamp(d.timestamp, xDate)) {
            addHoverData(hoverData, dataToDisplay[i], { ...d, [keyName]: d.value });

            yPos = dataToDisplay[i].scaleFunction(d.value[0]);
            if (!yPos) {
              return 'translate(-200,-200)';
            }

            if (true) {
              dataCount += 1;
              lineX = xScale(d.timestamp);

              d3.select(`.circle-max-value-${tooltipName}`)
                .attr('cx', xPos + getOffset(xAxisOffset))
                .attr('cy', dataToDisplay[i].scaleFunction(d.value[1]) + getOffset(10 + yOffset))
                .style('fill', color || '#fff')
                .attr('stroke', '#fff')
                .attr('opacity', 1);
            }
          } else {
            if (isAtCurentTimeStamp(d.timestamp, xDate)) {
              dataCount += 1;
              lineX = xScale(d.timestamp);
              addHoverData(hoverData, dataToDisplay[i], d);
              yPos = dataToDisplay[i].scaleFunction(d[keyName]) || 0;
            }
          }

          if (drawTooltipPointShadow) {
            d3.select(ref.current)
              .selectAll('.tooltipLinePointShadow')
              .attr(
                'transform',
                `translate(${xPos + getOffset(xAxisOffset)}, ${yPos + getOffset(10 + yOffset)})`
              );
          }
          return `translate(${xPos + getOffset(xAxisOffset)}, ${yPos + getOffset(10 + yOffset)})`;
        });
      if (isFirstDrawAfterMouseEnter.current) {
        isFirstDrawAfterMouseEnter.current = false;
        tooltipHoverDataCallback(hoverData);
      }

      clearTimeout(mouseMoveTimer);
      mouseMoveTimer = setTimeout(() => {
        tooltipHoverDataCallback(hoverData);
      }, 100);

      if (dataCount) {
        if (drawTooltipLine) {
          drawLine(lineX);
        }
      } else {
        drawLine(-200);
      }
    },
    [
      anchorEl,
      xScale,
      dataToDisplay,
      bisectDate,
      drawTooltipLine,
      drawTooltipPointShadow,
      getOffset,
      isAtCurentTimeStamp,
      isMinMaxArea,
      tooltipHoverDataCallback,
      tooltipName,
      yOffset,
      drawLine,
      xAxisOffset,
    ]
  );

  React.useEffect(() => {
    d3.select(anchorEl)
      .on('mouseout.tooltip', () => {
        d3.select(ref.current).attr('opacity', 0);
      })
      .on('mouseover.tooltip', () => {
        d3.select(ref.current).selectAll('.tooltipLinePoint').attr('opacity', 1);
        d3.select(ref.current).attr('opacity', 1);
        isFirstDrawAfterMouseEnter.current = true;
      })
      .on('mousemove.tooltip', (event) => {
        followPoints(event);
      });
  }, [anchorEl, followPoints]);

  if (!dataToDisplay.length) return null;

  return (
    <g ref={ref} opacity={0} {...props} className={`tooltipPoints-${tooltipName}`}>
      <circle r={4} strokeWidth={1.5} className={`circle-max-value-${tooltipName}`} opacity={0} />
      {dataToDisplay.map((data: any, index: number) => {
        return (
          <g key={index}>
            <circle
              className='tooltipLinePoint'
              r={4}
              opacity={0}
              stroke={'#fff'}
              fill={data.color}
              strokeWidth={1.5}
            />
            {drawTooltipPointShadow && (
              <circle
                className='tooltipLinePointShadow'
                fill='#E7F0F3'
                style={{ fillOpacity: 0.2 }}
                r='10'
              />
            )}
          </g>
        );
      })}
      <line className='tooltipLine' stroke='rgba(255, 255, 255, 0.1)' strokeWidth={'2px'} />
    </g>
  );
};
export default Tooltip;
