import './PreviewTable.scss';

import { useGetUnitsForEditPlan } from 'hooks/useGetUnitsForEditPlan';
import useTranslate from 'hooks/useTranslate';
import React, { Fragment, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { mapGraphPlanDataToTableFormat, mapTimeseriesDataToArray } from 'utils/plans.util';

interface PreviewTableProps {
  timeseriesData?: any;
  header: ReactNode;
}

const PreviewTable: React.FC<PreviewTableProps> = ({ timeseriesData, header }) => {
  const timeseriesArray = mapTimeseriesDataToArray(timeseriesData);
  const [tableData, tableHeadersArray] = mapGraphPlanDataToTableFormat(timeseriesArray);
  let { planId: id } = useParams();
  const getUnit = useGetUnitsForEditPlan;
  const translate = useTranslate();

  return (
    <div className='plans-table-container'>
      <div className='preview-table-header plans-table-unit'>{header}</div>
      <table cellSpacing={0} className='table table-striped table-hover graph-table'>
        <thead className='graph-table-head'>
          <tr className='graph-table-head-row'>
            <th className='graph-table-week-header'>Week</th>
            <th className='graph-table-header-spacer'></th>
            {tableHeadersArray?.map((item: any, index: number) => (
              <th className={`graph-table-week-data`} key={index}>
                <div className='variable'>{translate(item.name)}</div>
                <div className='unit'>{getUnit(Number(id), item.name)}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='graph-table-body'>
          {tableData.map((row: any, index: number) => {
            return (
              <tr className={`${index % 2 === 0 && 'active'} graph-table-body-row`} key={index}>
                {row.map((rowData: any, index: number) => {
                  return (
                    <Fragment key={'fragment' + index}>
                      {index === 0 && (
                        <>
                          <td className='graph-table-body-data graph-table-body-data-timestamp'>
                            {rowData.dateTimeWeek}
                          </td>
                          <td className='graph-table-body-spacer'></td>
                        </>
                      )}

                      <td className='graph-table-body-data'>{rowData.value ?? '-'}</td>
                    </Fragment>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default PreviewTable;
