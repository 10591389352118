export const isEmptyObject = (obj: any): boolean => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

//Checks if the value is nullOrUndefined
// checking !value is not enough because it will return false if value is 0
export const isMissing = (value: any): boolean => {
  return value === null || value === undefined;
};

export const isEllipsisActive = (element: any) => {
  if (element?.clientWidth < element?.scrollWidth) {
    var style = element.currentStyle || window.getComputedStyle(element);
    return style.textOverflow === 'ellipsis';
  }
  return false;
};

export const replaceFirstTwoMatches = (
  inputString: string,
  regex: RegExp,
  firstValue: string,
  secondValue: string
) => {
  let count = 0;

  const replacedString = inputString.replace(regex, (match) => {
    count++;
    if (count === 1) {
      return firstValue;
    } else if (count === 2) {
      return secondValue;
    } else {
      return match; // Return the original match for subsequent occurrences
    }
  });

  return replacedString;
};

export const replacePlaceholders = (inputString: string, replacementArray: string[]) => {
  return inputString.replace(/\$placeholder\$/g, function (match) {
    // Replace each occurrence of $placeholder$ with values from the array
    return replacementArray.shift() || match;
  });
};

export const getFooterSizeClass = (isOpen: boolean) => {
  let footerSizeClassName = 'isFull';
  if (isOpen) {
    footerSizeClassName = 'isSidebarOpened';
  }
  return footerSizeClassName;
};
