import React from 'react';

import { ReactComponent as Folder } from 'styles/icons/folder.svg';

import { useGetFoldersQuery } from 'services/plans.service';

import './FileList.scss';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { useAppSelector } from 'redux/hooks';

interface IFileListProps {
  selectedFolder: string;
  setSelectedFolder: (folder: string) => void;
  additionalFolders?: string[];
}

const FileList: React.FC<IFileListProps> = ({
  selectedFolder,
  setSelectedFolder,
  additionalFolders = [],
}) => {
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { data: folders } = useGetFoldersQuery(accountId, {
    skip: !accountId,
    refetchOnMountOrArgChange: true,
  });

  const allFolders = [...(folders || []), ...additionalFolders];

  const handleSelectFolder = (folder: string) => {
    setSelectedFolder(folder);
  };

  return (
    <div className='file-list-container'>
      {allFolders?.map((folder: string, index: number) => (
        <div
          className={`file-list-item ${selectedFolder === folder && 'active-list-item'}`}
          onClick={() => handleSelectFolder(folder)}
          key={index}
        >
          <Folder className='icon-light' />
          <span className={`item-title `}>{folder}</span>
        </div>
      ))}
    </div>
  );
};
export default FileList;
