import './Chip.scss';

import { FilterChip } from 'interfaces/filter-chip';
import { ReactComponent as CloseIcon } from 'styles/icons/close.svg';
import React from 'react';
import { VARIABLES_ICONS } from 'config/variable-icons';

interface IChipFilterProps {
  chip: FilterChip;
  onClear?: (value: string) => void;
  hasClose?: boolean;
}

const CHIP_DISABLED = '#a1a1a1';

const Chip: React.FC<IChipFilterProps> = ({ chip, onClear = () => {}, hasClose }) => {
  const additionalState = chip.disabled ? 'is-disabled' : '';
  const chipColor = !chip.disabled ? chip.color : CHIP_DISABLED;

  return (
    <span key={chip.value} className={`chip ${additionalState}`}>
      <div className='dot' style={{ backgroundColor: chipColor }}></div>
      {chip.label}
      {chip.source && <span className='chip-icon'>{VARIABLES_ICONS[chip.source]}</span>}
      {hasClose && (
        <span className='float-right' aria-label='Close'>
          <CloseIcon className='close-icon' onClick={() => onClear(chip.value)} />
        </span>
      )}
    </span>
  );
};
export default Chip;
