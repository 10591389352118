import dayjs from 'dayjs';
import { PlansFolders } from 'interfaces/plans';
import cloneDeep from 'lodash.clonedeep';
import {
  AUTO_CALCULATE_DECIMALS,
  DESIRED_TABLE_ORDER,
  ONE_DAY_IN_MILLISECONDS,
  PLAN_VARIABLES_OLD_NAME_ID_MAPPING,
} from 'config/constants';
import isEqual from 'lodash.isequal';
import { formatTimestampCustomSettings } from './formatTimeStamp';
import { Plan } from 'services/plans.service';
import { getUnitConversionPlanValue } from './plan-variables-mapper';

type mapVariablesInterface = Record<string, string>;

const getPropertyvalue = (item: any, currentDatetimeWeek: any) => {
  let propertyValue = item.value.find((item1: any) => item1.dateTimeWeek === currentDatetimeWeek);
  let variableId = item.id;
  propertyValue[variableId] = getUnitConversionPlanValue(variableId, propertyValue[variableId]);
  return propertyValue;
};

export const getPlansFoldersAndData = (plans: Plan[]): PlansFolders[] => {
  const plansMap = new Map();
  let folders: any[] = [];

  plans.forEach((plan: any) => {
    plan = { ...plan, path: plan.path.substring(1) };

    if (!plansMap.get(plan.path)) {
      plansMap.set(plan.path, [plan]);
    } else {
      plansMap.set(plan.path, [...plansMap.get(plan.path), plan]);
    }
  });

  plansMap.forEach((value, key) => {
    folders = [...folders, { folderName: key, plans: value }];
  });

  return folders;
};

export const getPlansVarieties = (plans: Plan[]): string[] => {
  const planVarieties = plans.map((plan: Plan) => plan.variety.name);
  const uniquePlantVarieties = Array.from(new Set(planVarieties)).map((variety) => {
    if (!variety) {
      return 'Not specified';
    } else {
      return variety;
    }
  });

  return uniquePlantVarieties;
};

export const filterPlansFoldersByVarietyAndTime = (
  plansFolders: PlansFolders[],
  variety: string,
  timePeriod: {
    from: number;
    to: number;
  }
) => {
  if (variety === 'Not specified' && !timePeriod.from && !timePeriod.to) {
    return plansFolders;
  }

  const filteredPlans = plansFolders.map((folder: PlansFolders) => {
    const filteredPlans = folder.plans.filter((plan: Plan) => {
      return (
        (plan.variety.name === variety || variety === 'Not specified') &&
        dayjs(plan.creation_timestamp).unix() > timePeriod.from &&
        dayjs(plan.creation_timestamp).unix() < timePeriod.to + ONE_DAY_IN_MILLISECONDS
      );
    });
    folder.plans = filteredPlans;

    return folder;
  });

  const filterFolders = filteredPlans.filter((folder) => folder.plans.length > 0);

  return filterFolders;
};

export const parsePlanVariablesQueryString = (variables?: string[]) => {
  if (!variables) {
    return '';
  }
  let queryStrings = '?';
  variables.forEach((variable, index) => {
    if (index > 0) {
      queryStrings += '&';
    }
    queryStrings = queryStrings + 'variable=' + variable;
  });
  return queryStrings;
};

export const mapGraphPlanDataToTableFormat = (data: any) => {
  let workData = cloneDeep(data);

  let orderedWorkData: any = [];

  DESIRED_TABLE_ORDER.forEach((parameter: { id: string; shownDecimals: number }) => {
    const foundItem = workData.find((dataItem: any) => dataItem?.id === parameter.id);
    if (foundItem) {
      orderedWorkData.push({ ...foundItem, shownDecimals: parameter.shownDecimals });
    }
  });

  let currentDatetimeWeek: any = null;
  let innerArray: any = [];
  let outerArray: any = [];

  for (
    let datetimeWeekCounter = 0;
    datetimeWeekCounter < orderedWorkData[0]?.value?.length;
    datetimeWeekCounter++
  ) {
    for (let index = 0; index < orderedWorkData.length; index++) {
      let item = orderedWorkData[index];
      if (!currentDatetimeWeek) {
        currentDatetimeWeek = item.value[datetimeWeekCounter].dateTimeWeek;
      }
      const propertyValue = getPropertyvalue(item, currentDatetimeWeek);

      let computedDecimals = item.shownDecimals;

      if (item.shownDecimals === AUTO_CALCULATE_DECIMALS) {
        if (propertyValue[item.id] < 10) {
          computedDecimals = 2;
        } else {
          computedDecimals = 1;
        }
      }

      innerArray.push({
        dateTimeWeek: currentDatetimeWeek,
        value: propertyValue[item.id]?.toFixed(computedDecimals),
      });
    }
    outerArray.push(innerArray);
    innerArray = [];
    currentDatetimeWeek = 0;
  }
  return [outerArray, orderedWorkData];
};

export const mapTimeseriesDataToArray = (timeseriesData: any) => {
  const workData = cloneDeep(timeseriesData);
  let timeseriesDataArray = [];

  for (let property in workData) {
    timeseriesDataArray.push({ id: property, value: workData[property] });
  }

  timeseriesDataArray = timeseriesDataArray.filter(
    (data: any) => data.id !== 'datetime' && data.id !== 'plan_weeknr'
  );

  return timeseriesDataArray;
};

const _isObjectPlanParams = (val: any) =>
  typeof val === 'object' && val !== null && !Array.isArray(val);

//Compares 2 nested objects (initial plan parameters and sidebar plan parameters)
//Returns new object containg all key value pairs from sidebar param that do not match initial param
//TO DO: Use Parameters Interface after Custtom Settings is added
export const getChangedPlanParams = (initial: any, changed: any) => {
  let output: any = {},
    merged = { ...initial, ...changed };

  for (const key in merged) {
    let initValue = initial[key],
      changedValue = changed[key];

    //for array of objects comparison, if one value is changed, return entire array
    if (Array.isArray(initValue) && Array.isArray(changedValue)) {
      //In case timestamp is in format with timezone (2023-03-01T22:00:00+00:00)
      //Update it in format without timezone before sending to backend (2023-03-01T22:00:00.000Z)
      let formatedInitialValue = initValue.map(({ timestamp, value }) => {
        return {
          timestamp: formatTimestampCustomSettings(dayjs(timestamp).unix()),
          value: value,
        };
      });
      let formatedChangedValue = changedValue.map(({ timestamp, value }) => ({
        timestamp: formatTimestampCustomSettings(dayjs(timestamp).unix()),
        value: value,
      }));
      if (formatedInitialValue.length !== formatedChangedValue.length) {
        output[key] = formatedChangedValue;
      } else {
        for (let j = 0; j < formatedInitialValue.length; j++) {
          if (!isEqual(formatedInitialValue[j], formatedChangedValue[j])) {
            output[key] = formatedChangedValue;
            break;
          }
        }
      }
      //In case Custom Settings does not exists, and a parameter is added, sent entire Custom Settings object
    } else if (!initValue && _isObjectPlanParams(changedValue)) {
      output[key] = changedValue;
      //In case on nested objects, do a recursive call
    } else if (_isObjectPlanParams(initValue) && _isObjectPlanParams(changedValue)) {
      output[key] = getChangedPlanParams(initValue, changedValue);
    } else if (initValue !== changedValue) output[key] = changedValue;
  }
  return output;
};

export const getSaveAsPlan = (plan: any) => {
  const { variety, location, ...rest } = plan.parameters;

  const updatedPlanParameters = {
    ...rest,
    variety_id: variety.id,
    location_id: location.id,
  };

  const savePlan = {
    name: plan.name,
    path: plan.path,
    account_id: plan.account_id,
    parameters: updatedPlanParameters,
  };

  return savePlan;
};

export const getPlanVariableIds = (variablesMap: mapVariablesInterface) => {
  let mappedVariablesToPlanID: mapVariablesInterface = {};

  Object.values(variablesMap).forEach((variable) => {
    if ((PLAN_VARIABLES_OLD_NAME_ID_MAPPING as mapVariablesInterface)[variable]) {
      mappedVariablesToPlanID[
        (PLAN_VARIABLES_OLD_NAME_ID_MAPPING as mapVariablesInterface)[variable]
      ] = (PLAN_VARIABLES_OLD_NAME_ID_MAPPING as mapVariablesInterface)[variable] || '';
    }
  });
  return mappedVariablesToPlanID;
};
