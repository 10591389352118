import { ReactComponent as NotificationVariationDrop } from 'styles/icons/notificationTypeVariationIcons/noification_variation_drop.svg';
import { ReactComponent as NotificationVariationBetween } from 'styles/icons/notificationTypeVariationIcons/notification_variation_between.svg';
import { ReactComponent as NotificationVariationFirst } from 'styles/icons/notificationTypeVariationIcons/notification_variation_first.svg';
import { ReactComponent as NotificationVariationHigh } from 'styles/icons/notificationTypeVariationIcons/notification_variation_high.svg';
import { ReactComponent as NotificationVariationLast } from 'styles/icons/notificationTypeVariationIcons/notification_variation_last.svg';
import { ReactComponent as NotificationVariationLate } from 'styles/icons/notificationTypeVariationIcons/notification_variation_late.svg';
import { ReactComponent as NotificationVariationMissing } from 'styles/icons/notificationTypeVariationIcons/notification_variation_missing.svg';

import { KeyValue } from 'interfaces/keyvalue';
import { ReactElement } from 'react';

export const NOTIFICATION_VARIATION_ICONS: KeyValue<ReactElement> = {
  drop: <NotificationVariationDrop className='notif_item_icon' width={20} height={20} />,
  low: <NotificationVariationDrop className='notif_item_icon' width={20} height={20} />,
  between: <NotificationVariationBetween className='notif_item_icon' width={20} height={20} />,
  first: <NotificationVariationFirst className='notif_item_icon' width={20} height={20} />,
  high: <NotificationVariationHigh className='notif_item_icon' width={20} height={20} />,
  last: <NotificationVariationLast className='notif_item_icon' width={20} height={20} />,
  late: <NotificationVariationLate className='notif_item_icon' width={20} height={20} />,
  missing: <NotificationVariationMissing className='notif_item_icon' width={20} height={20} />,
};
