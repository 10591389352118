import { ENTER_KEYCODE, ESCAPE_KEYCODE } from 'config/constants';
import { FileCardActions } from 'interfaces/plans';
import React, {
  Children,
  cloneElement,
  ReactElement,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

interface CardContainerProps {
  actions?: FileCardActions;
  disabled?: boolean;
  isEditing?: boolean;
  children?: ReactElement;
}

const CardContainer: React.FC<CardContainerProps> = ({
  actions = {},
  disabled,
  isEditing,
  children = [],
}) => {
  const componentId = useId();
  const [showActions, setShowActions] = useState(false);
  const [renameInput, setRenameInput] = useState('');

  const popupRef = useRef<HTMLDivElement>(null);
  const isClickable = !isEditing && !disabled;
  const onChangeRename = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRenameInput(event.target.value);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  });

  const handleClickOutside = (event: any) => {
    if (popupRef.current && showActions && !popupRef.current.contains(event.target)) {
      setShowActions(false);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.keyCode === ESCAPE_KEYCODE) {
      setShowActions(false);
      handleCancelRename();
    }
    if (event.keyCode === ENTER_KEYCODE) {
      setShowActions(false);
      handleConfirmRename(renameInput);
    }
  };

  const toggleActions = () => setShowActions(!showActions);

  const handleCardClick = () => {
    isClickable && actions.HANDLE_CARD_CLICK();
  };
  const handleCancelRename = () => {
    setRenameInput('');
    actions.HANDLE_RENAME_CANCEL();
  };

  const handleConfirmRename = (newName: string) => {
    if (!actions.HANLDE_VALIDATE || actions.HANLDE_VALIDATE(newName)) {
      actions.HANDLE_RENAME_CONFIRM(newName);
      setRenameInput('');
    }
  };

  return (
    <>
      {Children.map(children, (child) => {
        return cloneElement(child, {
          isEditing,
          isClickable,
          handleCardClick,
          renameInput,
          onChangeRename,
          handleConfirmRename,
          handleCancelRename,
          toggleActions,
          showActions,
          popupRef,
          componentId,
        });
      })}
    </>
  );
};
export default CardContainer;
