import React, { useCallback, useEffect, useState } from 'react';
import useGetSidebarFilterOptions from 'views/Analyze/hooks/useGetSidebarFilterOptions';

import styles from './NotificationFilter.module.scss';
import MultiselectDropDown from 'components/MultiselectDropDown/MultiselectDropDown';
import { translate } from 'utils/translations.util';
import MultiSelectChipsDropdown from 'components/MultiSelectChipsDropdown/MultiSelectChipsDropdown';
import { useGetCropseasonsQuery } from 'services/cropseason.service';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { nofiticationSelector, notificationActions } from 'redux/slices/notification.slice';
import { CropSeason } from 'interfaces/crop-season';
import { Location } from 'interfaces/location';
import DatePickerDropdown from 'components/DatePicker/DatePickerDropdown/DatePickerDropdown';
import { ReactComponent as CloseIcon } from 'styles/icons/close.svg';
import dayjs from 'dayjs';

import {
  useUpdateUserSettingsMutation,
  useGetUserSettingsQuery,
} from 'services/userAccount.service';
import { getCropSeasonsTimeRange } from 'utils/cropSeasons.utils';
import useDefaultCropSeason from 'hooks/useDefaultCropSeason';
import { languageSelector } from 'redux/slices/language.slice';
import useUrlState from 'hooks/useUrlState';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT_LAPTOP } from 'config/constants';
import { ReactComponent as More } from 'styles/icons/more-horizontal-small.svg';

const SELECTED_CROPSEASON_FILTER = 'selectedCropSeasons';
const SELECTED_LOCATION_FILTER = 'locations';

const NotificationFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const { dates, locations, selectedCropSeasons } = useAppSelector(nofiticationSelector.filters);

  const { getLocationOptions } = useGetSidebarFilterOptions();
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { getDefaultCropSeasonId } = useDefaultCropSeason();
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const { data: cropSeasons } = useGetCropseasonsQuery(accountId, {
    skip: !accountId,
    refetchOnMountOrArgChange: true,
  });
  const [filteredCropSeasons, setFilteredCropseason] = useState<CropSeason[]>([]);
  const [searchFilteredCropSeaons, setSearchFilteredCropSeasons] = useState(filteredCropSeasons);
  const [updateDefaultCropSeason] = useUpdateUserSettingsMutation();
  const { data: defaultCropSeason, isSuccess } = useGetUserSettingsQuery();
  const { selectedLanguage } = useAppSelector(languageSelector);
  const [urlState, setUrlState] = useUrlState<{
    notificationsStartDate: string;
    notificationsEndDate: string;
    notificationsLocations: string[];
    notificationsSelectedCropseasons: string[];
    accountId: number;
  }>({
    notificationsLocations: [],
    notificationsStartDate: String(dates.from),
    notificationsEndDate: String(dates.to),
    notificationsSelectedCropseasons: [],
    accountId,
  });

  const windowSize: WindowSize = useWindowSize();

  //Initial Selected CropSeason should Be the default one if exists or the first one
  useEffect(() => {
    let initialCropSeason: CropSeason[] = [];

    if (!cropSeasons || !cropSeasons.length) {
      return;
    }

    if (getDefaultCropSeasonId()) {
      initialCropSeason = cropSeasons.filter(
        (cropseason) => cropseason.id === getDefaultCropSeasonId()
      );
    } else {
      initialCropSeason = [cropSeasons[0]];
    }

    dispatch(notificationActions.setFilter({ selectedCropSeasons: initialCropSeason }));

    setUrlState({
      ...urlState,
      notificationsSelectedCropseasons: [String(initialCropSeason[0].id)],
    });
  }, [cropSeasons, getDefaultCropSeasonId(), accountId]);

  useEffect(() => {
    if (!cropSeasons) {
      return;
    }

    const locationValues = locations.map(({ name }) => name);

    const filteredCropseasons = cropSeasons.filter((cropseason) => {
      return !locationValues.length || locationValues.includes(cropseason.location.name);
    });

    setFilteredCropseason(filteredCropseasons);
  }, [locations, cropSeasons, accountId]);

  useEffect(() => {
    setSearchFilteredCropSeasons(filteredCropSeasons);
  }, [filteredCropSeasons]);

  const handeSetUrl = (input: string, value: CropSeason[] | Location[]) => {
    switch (input) {
      case SELECTED_LOCATION_FILTER: {
        setUrlState({
          ...urlState,
          notificationsLocations: value.map((location) => String(location.id)),
        });
        break;
      }
      case SELECTED_CROPSEASON_FILTER: {
        setUrlState({
          ...urlState,
          notificationsSelectedCropseasons: value.map((cropseason) => String(cropseason.id)),
        });
        break;
      }
      default:
        break;
    }
  };

  const handleFilterChange = useCallback(
    (name: string) => (value: CropSeason[] | Location[]) => {
      handeSetUrl(name, value);
      dispatch(notificationActions.setFilter({ [name]: value }));
    },
    [dispatch]
  );

  const filterCropseasonsBySearch = useCallback(
    (keyword: string) => {
      const cropSeasons = [...filteredCropSeasons];
      if (!cropSeasons) {
        return;
      }
      const filteredList = cropSeasons.filter((cropseason) =>
        cropseason.name.toLowerCase().includes(keyword.toLowerCase())
      );
      setSearchFilteredCropSeasons(filteredList);
    },
    [filteredCropSeasons]
  );

  useEffect(() => {
    if (
      isSuccess &&
      accountId &&
      defaultCropSeason?.accounts &&
      defaultCropSeason.accounts[accountId]
    ) {
      updateDefaultCropSeason({
        data: {
          ...defaultCropSeason,
          accounts: {
            [accountId]: {
              ...defaultCropSeason.accounts[accountId],
              last_time_seen_notifications: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
            },
          },
        },
      });
    }
  }, [isSuccess, accountId]);

  const handleDatepickerChange = useCallback(
    (value: any) => {
      let from = value.start.unix();
      let to = value.end.unix();
      setUrlState({
        notificationsStartDate: from,
        notificationsEndDate: to,
      });
      dispatch(notificationActions.setFilterDates({ from, to }));
    },
    [dispatch]
  );

  const renderFilters = () => {
    return (
      <div className={styles['notification-filters']}>
        <div className={styles.filters}>
          <div className={[styles['notification-filter'], styles.location].join(' ')}>
            <MultiselectDropDown
              optionList={getLocationOptions()}
              onChange={handleFilterChange(SELECTED_LOCATION_FILTER)}
              placeholder={translate('Generic-select-location', selectedLanguage)}
              initialValues={locations}
            />
          </div>
          <div className={[styles['notification-filter'], styles.cropseasons].join(' ')}>
            <MultiSelectChipsDropdown
              optionList={searchFilteredCropSeaons}
              displayProperty='name'
              searchOptions={{
                placeholder: translate('CropSeason selector - search cropseason', selectedLanguage),
                onChange: filterCropseasonsBySearch,
              }}
              placeholder={translate('Analyze - CropSeason selector', selectedLanguage)}
              setOnChange={handleFilterChange(SELECTED_CROPSEASON_FILTER)}
              initialValue={selectedCropSeasons}
            />
          </div>
          <div className={[styles['notification-filter'], styles['date-picker']].join(' ')}>
            <DatePickerDropdown
              onChangeDropdown={handleDatepickerChange}
              initialValues={{ start: dayjs.unix(dates.from), end: dayjs.unix(dates.to) }}
              required
              cropSeasonDates={
                selectedCropSeasons.length
                  ? getCropSeasonsTimeRange(selectedCropSeasons)
                  : undefined
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const toggleMobileFilters = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const renderMobileCollapsedFilters = () => {
    return (
      <>
        <div className={styles['mobileActionMoreFilters']}>
          {!isMobileOpen && <More className='icon-fill' onClick={toggleMobileFilters} />}
        </div>

        {isMobileOpen && (
          <div className={styles['mobileExpandedFilters']}>
            <div className={styles['mobileExpandedFilters-content']}>
              <div className={styles['mobileExpandedFilters-header']}>
                <span className={styles['mobileExpandedFilters-header-text']}>
                  {translate('Notification-page-mobile-filter-title', selectedLanguage)}
                </span>
                <CloseIcon
                  className={styles['mobileExpandedFilters-close']}
                  onClick={toggleMobileFilters}
                />
              </div>
              {renderFilters()}
            </div>
            <button className='btn btn-primary btn-full' onClick={toggleMobileFilters}>
              Close
            </button>
          </div>
        )}
      </>
    );
  };

  const renderResponsiveNotificationFilters = () => {
    if (windowSize.width > BREAKPOINT_LAPTOP) {
      return renderFilters();
    } else {
      return renderMobileCollapsedFilters();
    }
  };

  return <>{renderResponsiveNotificationFilters()}</>;
};
export default NotificationFilter;
