import { Range } from 'interfaces/range';
import { AnalyzeData, AnalyzeFilter } from 'interfaces/analyze';
import { KeyValue } from 'interfaces/keyvalue';
import { GraphLine } from 'interfaces/line-graph-data';
import { useCallback } from 'react';
import { getUTC } from 'utils/time.util';
import { normalize } from 'utils/normalize';
import { CropSeason, CropSeasonShort } from 'interfaces/crop-season';
import { getDash } from 'utils/dashes.util';
import dayjs from 'dayjs';

interface UseAnalyzeDataInterface {
  getGraphLinesData: () => GraphLine[];
  hasDataToRender: () => boolean;
}

export default function useGetAnalyzeData(
  selectedCropseasons: CropSeason[] | CropSeasonShort[],
  variablesTimeseries: AnalyzeData | [] | undefined,
  timeRange: Range,
  selectedVariables: AnalyzeFilter[],
  isHourTimestamp: boolean
): UseAnalyzeDataInterface {
  const getFilteredDataToDisplay = useCallback(
    (
      filter: AnalyzeFilter,
      data: number[],
      index: number,
      key: number,
      local_datetime: number[]
    ) => {
      const filteredData: { [x: string]: number; timestamp: number }[] = [];
      data.forEach((value, i) => {
        if (isHourTimestamp) {
          const now = dayjs().startOf('day').utc(true); // Create a Day.js object for the current day
          let futureTime = now.add(local_datetime[i], 'second');

          if (
            futureTime.unix() >= getUTC(timeRange.min) &&
            futureTime.unix() <= getUTC(timeRange.max)
          ) {
            filteredData.push({
              timestamp: futureTime.unix(),
              [filter.value + index + key]: value,
            });
          }
          return filteredData;
        } else if (
          local_datetime[i] >= getUTC(timeRange.min) &&
          local_datetime[i] <= getUTC(timeRange.max)
        ) {
          filteredData.push({
            timestamp: local_datetime[i],
            [filter.value + index + key]: value,
          });
        }
      });
      return filteredData;
    },
    [timeRange, isHourTimestamp]
  );

  //get the maximum scaling value of each group of variables
  const getMaximumsScalingPerGroup = useCallback((): KeyValue<number> => {
    if (!variablesTimeseries) {
      return {};
    }
    const maximumPerGroup: KeyValue<number> = {};

    selectedVariables.forEach((selectedVariable) => {
      Object.entries(variablesTimeseries).forEach(([key, group]) => {
        let variablesArray = group.variables;

        const { maximum } = normalize(variablesArray[selectedVariable.value]);
        maximumPerGroup[selectedVariable.scalingGroup] = Math.max(
          maximumPerGroup[selectedVariable.scalingGroup] || 0,
          maximum
        );
      });
    });

    return maximumPerGroup;
  }, [variablesTimeseries, selectedVariables]);

  const getGraphLinesData = useCallback((): GraphLine[] => {
    if (!selectedCropseasons.length || !variablesTimeseries) {
      return [];
    }

    const dataToDisplay: GraphLine[] = [];
    const maximumsPerGroup = getMaximumsScalingPerGroup();

    Object.entries(variablesTimeseries).forEach(([key, groupIdData]) => {
      const stroke = getDash(selectedCropseasons.findIndex(({ id }) => id === Number(key)));
      selectedVariables.forEach((selectedVariable, index) => {
        var { array: data } = normalize(
          groupIdData.variables[selectedVariable.value],
          maximumsPerGroup[selectedVariable.scalingGroup]
        );

        const filteredData = getFilteredDataToDisplay(
          selectedVariable,
          data,
          index,
          Number(key),
          groupIdData.local_datetime
        );
        if (filteredData.length > 0) {
          dataToDisplay.push({
            keyName: selectedVariable.value + index + key,
            color: selectedVariable.color,
            strokeWidth: 2,
            stroke,
            scaling: maximumsPerGroup[selectedVariable.scalingGroup],
            unit: selectedVariable?.unit,
            label: selectedVariable?.label,
            data: filteredData,
          });
        }
      });
    });
    return dataToDisplay;
  }, [
    variablesTimeseries,
    getMaximumsScalingPerGroup,
    selectedCropseasons,
    selectedVariables,
    getFilteredDataToDisplay,
  ]);

  //Verify there is still data to render for a certain time range
  const hasDataToRender = useCallback(() => {
    let hasData = false;
    getGraphLinesData().forEach((varible) => {
      hasData = varible.data?.length > 0;
    });
    return hasData;
  }, [getGraphLinesData]);

  return { getGraphLinesData, hasDataToRender };
}
