import { TemperatureStrategy } from 'interfaces/custom-settings';
import { translate } from './translations.util';

export const ripeningDateValidation = (
  headRemovalDate?: number,
  ripeningDate?: number,
  endDate?: number,
  language?: string
) => {
  if (!headRemovalDate) {
    return translate(`Custom settings - validation - missing head removal`, language);
  }

  if (!ripeningDate) {
    return translate(`Custom settings - validation - missing ripening date`, language);
  }

  if (!endDate) {
    return translate(`Custom settings - validation - missing end date`, language);
  }

  if (ripeningDate > endDate || ripeningDate < headRemovalDate) {
    return translate(`Custom settings - validation - ripening date range`, language);
  }
  return;
};

export const ECValidation = (min?: number, max?: number, language?: string) => {
  if (!min || !max) {
    return translate(`Custom settings - validation - missing EC`, language);
  }

  if (min > max) {
    return translate(`Custom settings - validation - min greater than max`, language);
  }
  return;
};

export const temperatureStrategyValidation = (temperatures: TemperatureStrategy[]) => {
  if (!temperatures) {
    return translate('Custom settings - validations - missing - temperatures');
  }

  if (temperatures.some((temperature) => !temperature.phase_start)) {
    return translate('Custom settings - validations - missing - date');
  }

  if (
    temperatures.some((temperature, i) =>
      temperatures.some((temp, j) => i !== j && temp.phase_start === temperature.phase_start)
    )
  ) {
    return translate('Custom settings - validations - phase start - exists');
  }

  return;
};
