import React from 'react';

import styles from './CustomSettingsPlanStep.module.scss';
import PlanCustomSettings from 'views/PlanSettings/PlanCustomSettings/PlanCustomSettings';
import { useAppSelector } from 'redux/hooks';
import { ReactComponent as Info } from 'styles/icons/info.svg';
import { Tooltip } from 'react-tooltip';
import { translate } from 'utils/translations.util';
import { languageSelector } from 'redux/slices/language.slice';

import useCreationCustomSettings from '../hooks/useCreationCustomSettings';

interface CustomSettingsPlanStepProps {}

const CustomSettingsPlanStep: React.FC<CustomSettingsPlanStepProps> = () => {
  const { selectedLanguage } = useAppSelector(languageSelector);

  const { customSettings, plantingDate, harvestDate, headRemovalDate, locationId } =
    useCreationCustomSettings();

  if (!customSettings || !plantingDate || !harvestDate) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title + ' step-title'}>
        {translate('Plan creation  - custom settings', selectedLanguage)}
        <Info
          width={20}
          height={20}
          className='icon-light c-hand'
          id={`tooltip-anchor-create-plan-variety`}
          data-tooltip-variant='light'
        />
        <Tooltip
          anchorSelect={`#tooltip-anchor-create-plan-variety`}
          place='right'
          style={{ zIndex: 10, width: '295px', display: 'flex', height: 'auto' }}
          opacity={1}
        >
          <div className='plan-settings-details'>
            {translate('Information hoover: Custom Plan Settings', selectedLanguage)}
          </div>
        </Tooltip>
      </div>
      <div className={`${styles.content} ${styles.contentFull}`}>
        <PlanCustomSettings
          plantingDate={plantingDate!}
          harvestDate={harvestDate!}
          headRemovalDate={headRemovalDate!}
          customPlanSettings={customSettings}
          locationId={locationId!}
        />
      </div>
    </div>
  );
};
export default CustomSettingsPlanStep;
