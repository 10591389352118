import dashes from 'config/dashes.json';

const DEFAULT_DASH = '';

export const getDash = (index: number): string => {
  if (!index) {
    return DEFAULT_DASH;
  }

  if (!(index in dashes)) {
    return DEFAULT_DASH;
  }

  return dashes[`${index}` as keyof typeof dashes];
};
