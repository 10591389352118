import React, { useCallback, useEffect, useState } from 'react';
import styles from './CropSeasonSelector.module.scss';
import DropDown from 'components/DropDown/DropDown';
import { getActiveCropseasonIntitialValue } from 'utils/getInitialValues';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { cropseasonSelector, setActiveCropseason } from 'redux/slices/cropseason.slice';
import useDefaultCropSeason from 'hooks/useDefaultCropSeason';
import { useGetCropseasonsQuery } from 'services/cropseason.service';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { ReactComponent as CheckMark } from 'styles/icons/checkmark.svg';
import { CropSeason, CropSeasonShort } from 'interfaces/crop-season';
import { noCropSeason } from 'config/constants';

type CropSeasonSelectorProps = {
  hasDefaultCapability?: boolean;
  cropSeasonSelectorTitle?: string;
  setOnChange?: (value: CropSeason) => void;
  localClassName?: string;
  initialCropSeason?: CropSeason | CropSeasonShort | null;
  hasNoCropSeasonOption?: boolean;
  selectedOption?: CropSeason;
};

const CropSeasonSelector: React.FC<CropSeasonSelectorProps> = ({
  hasDefaultCapability = true,
  cropSeasonSelectorTitle,
  setOnChange,
  localClassName,
  initialCropSeason,
  hasNoCropSeasonOption = false,
  selectedOption,
}) => {
  const dispatch = useAppDispatch();
  const accountId = useAppSelector(accountIdSelector).accountId;

  const activeCropseason = useAppSelector(cropseasonSelector);

  const { data: cropSeasons } = useGetCropseasonsQuery(accountId, {
    skip: !accountId,
    refetchOnMountOrArgChange: true,
  });

  const { handleDefaultCropseason, handleRemoveDefaultCropseason, isValueDefault } =
    useDefaultCropSeason();

  const [searchFilteredCropSeaons, setSearchFilteredCropSeasons] = useState(cropSeasons);

  useEffect(() => {
    let cropseasonsList =
      hasNoCropSeasonOption && cropSeasons ? [noCropSeason, ...cropSeasons] : cropSeasons;
    setSearchFilteredCropSeasons(cropseasonsList);
  }, [cropSeasons, setSearchFilteredCropSeasons]);

  const dropdownOnChange = (_: any, option: CropSeason) => {
    if (setOnChange) {
      setOnChange(option);
    } else {
      dispatch(setActiveCropseason(option));
    }
  };

  const filterCropseasonsBySearch = useCallback(
    (keyword: string) => {
      if (!cropSeasons) {
        return;
      }
      const filteredList = cropSeasons.filter((cropseason) =>
        cropseason.name.toLowerCase().includes(keyword.toLowerCase())
      );
      setSearchFilteredCropSeasons(filteredList);
    },
    [cropSeasons]
  );

  const activeCropseasonInitial =
    activeCropseason.id === getActiveCropseasonIntitialValue().id
      ? getActiveCropseasonIntitialValue()
      : activeCropseason;

  return (
    <div className={styles.filters + ' ' + localClassName}>
      <div className={styles.cropseasons}>
        <DropDown
          required={false}
          initialValue={selectedOption || initialCropSeason || activeCropseasonInitial}
          name='cropseason'
          dropDownTitle={cropSeasonSelectorTitle}
          optionList={searchFilteredCropSeaons || []}
          setOnChange={dropdownOnChange}
          displayProperty='name'
          rightSide
          hasTooltip
          activePreElement={<CheckMark />}
          hasDefaultValue={hasDefaultCapability}
          setAsDefault={hasDefaultCapability ? handleDefaultCropseason : undefined}
          removeDefault={hasDefaultCapability ? handleRemoveDefaultCropseason : undefined}
          isValueDefault={hasDefaultCapability ? isValueDefault : undefined}
          isSorted={true}
          searchOptions={{
            placeholder: 'Search CropSeason',
            onChange: filterCropseasonsBySearch,
          }}
        />
      </div>
    </div>
  );
};

export default CropSeasonSelector;
