import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Bell } from 'styles/icons/bell.svg';
import { ReactComponent as ChevronRight } from 'styles/icons/chevron-right.svg';

import styles from './NotificationBell.module.scss';
import NotificationItem from 'components/NotificationItem/NotificationItem';
import { useNavigate } from 'react-router-dom';
import { useGetCropSeasonNotificationsQuery } from 'services/notifications.service';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { REFETCH_NOTIFICATIONS_MILLISECONDS } from 'config/constants';
import {
  useUpdateUserSettingsMutation,
  useGetUserSettingsQuery,
} from 'services/userAccount.service';
import dayjs from 'dayjs';
import { NotificationItemConfig } from 'interfaces/notificationItem';

const NotificationBell: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const notificationsRef = useRef<HTMLDivElement>(null);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const [updateDefaultCropSeason] = useUpdateUserSettingsMutation();
  const { data: defaultCropSeason } = useGetUserSettingsQuery();

  const { data: notificationsData } = useGetCropSeasonNotificationsQuery(
    {
      current_account_id: accountId,
    },
    {
      skip: !accountId,
      refetchOnMountOrArgChange: true,
      pollingInterval: REFETCH_NOTIFICATIONS_MILLISECONDS,
    }
  );

  const reversedNotificationsData =
    [...(notificationsData?.notifications || [])].reverse().slice(0, 4) || [];

  const handleNavigate = () => {
    setIsOpen(false);
    navigate('/notification');
  };

  const handleClick = ({ target }: MouseEvent) => {
    if (notificationsRef.current && isOpen && !notificationsRef.current.contains(target as Node)) {
      setIsOpen(false);
    }
  };

  const handleIconClick = () => {
    updateDefaultCropSeason({
      data: {
        ...defaultCropSeason,
        accounts: {
          [accountId]: {
            default_cropseason_id: defaultCropSeason?.accounts?.[accountId]?.default_cropseason_id,
            last_time_seen_notifications: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
          },
        },
      },
    });
    setIsOpen(!isOpen);
  };

  const checkNotifications = () => {
    const lastNotification = reversedNotificationsData[0];
    const lastNotificationTimeStamp = dayjs(lastNotification?.local_datetime).unix() || Infinity;
    const defaultCropSeasonLastSeenNotificationTimestamp =
      dayjs(defaultCropSeason?.accounts?.[accountId]?.last_time_seen_notifications).unix() ||
      -Infinity;

    return lastNotificationTimeStamp >= defaultCropSeasonLastSeenNotificationTimestamp;
  };

  checkNotifications();

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  return (
    <div
      className={`accordion ${styles['notification-accordion']}`}
      open={isOpen}
      ref={notificationsRef}
    >
      <label
        className={`accordion-header c-hand ${styles['header-notifications']}`}
        onClick={handleIconClick}
      >
        {!!checkNotifications() && !!reversedNotificationsData.length && (
          <div className={styles['read-circle']}></div>
        )}
        <Bell className='icon-white-large' />
      </label>
      <div className={`accordion-body ${styles['notifications-body']}`}>
        <div className={styles.title}>Notify</div>
        <div className={styles.notifications}>
          {reversedNotificationsData.map((item, index) => {
            return (
              <NotificationItem
                data={item}
                id={index}
                key={`notification-navbar-key-${index}`}
                style={NotificationItemConfig.StyleLight}
                type={NotificationItemConfig.TypeTooltip}
                showNotificationState={true}
              />
            );
          })}
        </div>
        <div className={styles.footer}>
          <div onClick={handleNavigate} className='c-hand'>
            Go to Notification page <ChevronRight className='icon-pagination' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationBell;
