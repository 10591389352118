import Input from 'components/Input/Input';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { planCreationSelector, setOption } from 'redux/slices/planCreationSlice';
import FileList from 'views/PlanSettings/PlanCreation/FileList/FileList';
import { ReactComponent as FolderAddIcon } from 'styles/icons/folder-add.svg';

import './SavePlanAsContent.scss';
import { ModalService } from 'services/modal.service';
import { checkFileName, checkFolderName } from 'utils/validation.util';
import NewPlanFolderContent from './NewPlanFolderContent/NewPlanFolderContent';
import { SidebarFileFolderNames } from '../EditPlanSidebar';
import {
  MAX_LENGTH_FOLDER_NAME,
  MAX_LENGTH_PLAN_NAME,
  MIN_LENGTH_FOLDER_NAME,
  MIN_LENGTH_PLAN_NAME,
} from 'config/constants';
import { translate } from 'utils/translations.util';
import { languageSelector } from 'redux/slices/language.slice';
interface SavePlanAsContentProps {
  names: SidebarFileFolderNames;
  touched?: boolean;
}

const SavePlanAsContent: React.FC<SavePlanAsContentProps> = ({ names, touched = false }) => {
  const dispatch = useAppDispatch();
  const { fileName, folderName } = useAppSelector(planCreationSelector);
  const [createdFolders, setCreatedFolders] = useState<string[]>([]);
  const { selectedLanguage } = useAppSelector(languageSelector);

  const handleChange = (name: string) => (input: string) => {
    dispatch(setOption({ [name]: input }));
    if (name === 'fileName') {
      names.fileNameField = input;
    }
    if (name === 'folderName') {
      names.folderNameField = input;
    }
  };

  const addFolder = () => {
    const newFolderName = { newFolderName: '' };
    const validateFunction = () => {
      return !checkFolderName(
        newFolderName.newFolderName,
        MIN_LENGTH_FOLDER_NAME,
        MAX_LENGTH_FOLDER_NAME
      );
    };
    ModalService.open({
      title: 'Add folder',
      saveText: 'Add',
      cancelText: 'Cancel',
      width: '300px',
      validateFunction,
      content: <NewPlanFolderContent newFolderName={newFolderName} />,
      closeAction: (isConfirmed: boolean) => {
        if (isConfirmed) {
          setCreatedFolders([...createdFolders, newFolderName.newFolderName]);
        }
      },
    });
  };

  return (
    <div className='save-step save-as-modal-content'>
      <div className='input-container-save-as'>
        <Input
          errorText={
            touched ? checkFileName(fileName || '', MIN_LENGTH_PLAN_NAME, MAX_LENGTH_PLAN_NAME) : ''
          }
          label={translate('Name your CropPlan', selectedLanguage)}
          name='name'
          onChange={handleChange('fileName')}
          value={fileName || ''}
        />
      </div>
      <div className='save-as-file-list-container'>
        <span className='file-list-title'>
          {translate('Create Plan - select folder for saved plan', selectedLanguage)}
        </span>
        <FileList
          selectedFolder={folderName ? folderName : ''}
          setSelectedFolder={handleChange('folderName')}
          additionalFolders={createdFolders}
        />
        <span className='error-text'>
          {touched && !folderName && translate('Please select a folder', selectedLanguage)}
        </span>
      </div>
      <div className='save-as-new-folder' onClick={addFolder}>
        <FolderAddIcon className='icon-fill' />
        <span>{translate('Create Plan - Create a new folder', selectedLanguage)}</span>
      </div>
    </div>
  );
};
export default SavePlanAsContent;
