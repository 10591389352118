import './Tabs.scss';

import React from 'react';
import { Tab } from 'interfaces/tab';
import { useSearchParams } from 'react-router-dom';

interface TabsProps {
  tabs: Tab[];
  activeTab?: number;
  onChange?: (input: number) => void;
  className?: string;
}

const Tabs: React.FC<TabsProps> = ({ tabs = [], activeTab = 0, onChange, className }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const profileActiveTab = searchParams.get('activeTab');
  const activeIndexTab = profileActiveTab ? parseInt(profileActiveTab) : activeTab;

  function handleClick(input: number) {
    setSearchParams({ activeTab: String(input) });

    if (onChange) {
      onChange(input);
    }
  }

  return (
    <div className='tab-container'>
      <ul className='tab tab-header'>
        {tabs.map((tab, index) => (
          <li key={index} className={`tab-item ${activeIndexTab === index ? 'active' : ''}`}>
            <span className='tab-link' onClick={() => handleClick(index)}>
              {tab.label}
            </span>
          </li>
        ))}
      </ul>
      <div className={`tab-content ${className}`}>{tabs[activeIndexTab]?.content}</div>
    </div>
  );
};
export default Tabs;
