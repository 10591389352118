export function filterUniqueArrayElements(array: any[], key1?: string, key2?: string): any[] {
  var map: { [key: string]: any } = {};

  if (!array) {
    return [];
  }

  if (key2 && key1) {
    for (let i = 0; i < array.length; i++) {
      if (!Object(array[i]).hasOwnProperty(key1) || !Object(array[i]).hasOwnProperty(key2)) {
        continue;
      }
      const itemKey1 = array[i][key1];
      const itemKey2 = array[i][key2];
      if (itemKey1 !== null) {
        map[itemKey1] = array[i];
      } else if (itemKey2 !== null) {
        map[itemKey2] = array[i];
      }
    }
  } else if (key1) {
    for (let i = 0; i < array.length; i++) {
      if (!Object(array[i]).hasOwnProperty(key1)) {
        continue;
      }
      const itemKey = array[i][key1];
      if (itemKey !== null) {
        map[itemKey] = array[i];
      }
    }
  } else {
    for (let i = 0; i < array.length; i++) {
      map[array[i]] = array[i];
    }
  }
  return Object.values(map);
}
