import React from 'react';
import { SUNRISE_BG_COLOR, SUNSET_BG_COLOR } from 'config/constants';

import { ReactComponent as SunsetIcon } from 'styles/icons/sunset.svg';
import { ReactComponent as SunriseIcon } from 'styles/icons/sunrise.svg';

import { Twilight } from 'interfaces/twilights';
import Area from '../Area/Area';

const ICON_SIZE = 15;

interface SunsetSunriseProps {
  YInterval: any;
  xScale?: any;
  yScale?: any;
  sunRiseSunSetData: Twilight[];
  showIcons?: boolean;
}

const SunsetSunrise: React.FC<SunsetSunriseProps> = ({
  YInterval,
  xScale,
  yScale,
  sunRiseSunSetData,
  showIcons,
  ...props
}) => {
  const drawIcon = (isSunrise: boolean, index: number) => (constructedPath: string) => {
    const firstPointData = constructedPath?.split('M')[1];
    const secondPointData = constructedPath?.split('L')[1];
    const iconPadding = 20;

    let firstXCoord = parseInt(firstPointData.substring(0, firstPointData.indexOf(',')));
    let secondXCoord = parseInt(secondPointData.substring(0, secondPointData.indexOf(',')));

    const barSize = secondXCoord - firstXCoord;
    const displayIcon =
      (index === 1 || index === sunRiseSunSetData.length - 1) &&
      Math.ceil(barSize / 10) * 10 < ICON_SIZE + iconPadding
        ? 'none'
        : 'block';
    let translateX = (secondXCoord - firstXCoord) / 2;
    const coordinate = firstXCoord + translateX - ICON_SIZE / 2;

    const icon = isSunrise ? (
      <SunriseIcon width={ICON_SIZE} height={ICON_SIZE} />
    ) : (
      <SunsetIcon width={ICON_SIZE} height={ICON_SIZE} />
    );

    return (
      <g
        key={coordinate}
        style={{ display: displayIcon }}
        transform={`translate(${coordinate}, -30 ) `}
      >
        {icon}
      </g>
    );
  };

  const renderSunsetSunriseBar = (
    startTimestamp: number,
    endTimestamp: number,
    isSunrise: boolean,
    index: number
  ) => {
    const color = isSunrise ? SUNRISE_BG_COLOR : SUNSET_BG_COLOR;

    const barData = [
      {
        timestamp: startTimestamp,
        value: [YInterval[0]?.min, YInterval[0]?.max],
      },
      {
        timestamp: endTimestamp,
        value: [YInterval[0]?.min, YInterval[0]?.max],
      },
    ];

    return (
      <Area
        data={barData}
        xScale={xScale}
        yScale={yScale}
        color={color}
        opacity={1}
        drawCustmChild={showIcons ? drawIcon(isSunrise, index) : undefined}
      />
    );
  };
  const renderSunsetSunrise = () => {
    if (sunRiseSunSetData) {
      return sunRiseSunSetData.map((data: Twilight, index: number) => {
        if (index > 0) {
          const startTimestamp = sunRiseSunSetData[index - 1].timestamp;

          const endTimestamp = sunRiseSunSetData[index].timestamp;

          const isSunrise = sunRiseSunSetData[index - 1].isSunrise;

          return (
            <g key={index}>
              {renderSunsetSunriseBar(startTimestamp, endTimestamp, isSunrise, index)}
            </g>
          );
        } else {
          return null;
        }
      });
    }
  };

  return <g transform={`translate(50, 30)`}>{renderSunsetSunrise()}</g>;
};

export default SunsetSunrise;
