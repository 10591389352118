import { CropseasonNotification } from 'interfaces/cropseason-notification';
import styles from './NotificationGraph.module.scss';
import UseNotificationDetails from '../hooks/useNotificationDetails';
import dayjs from 'dayjs';
import useGetAnalyzeData from 'views/Analyze/hooks/useGetAnalyzeData';
import useGetYRangeInterval from 'views/Analyze/hooks/useGetYRangeInterval';
import SunsetSunrise from 'components/GraphElements/SunsetSunrise/SunsetSunrise';
import { renderLineData } from 'utils/renderDataInGraph';
import useTwilights from 'hooks/useTwilights';
import Notification from 'components/GraphElements/Notification/Notification';
import { useGetTimeseriesQuery } from 'services/analyze.service';
import { addResolutionToTimestamp } from 'utils/time.util';
import { Resolution } from 'interfaces/resolution';

import React, { useEffect, useMemo } from 'react';
import { track } from 'utils/analtycs';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { nofiticationSelector } from 'redux/slices/notification.slice';
import { useNavigate } from 'react-router-dom';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import Chip from 'components/Chip/Chip';
import MultilineChart from 'components/GraphElements/MultilineChart/MultilineChart';
import { analyzeTimestampFormatter } from 'utils/tickFormatter';
import { DESKTOP_GRAPH_PADDING, MOBILE_GRAPH_PADDING } from 'config/constants';
import NotificationTooltip from 'components/NotificationTooltip/NotificationTooltip';
import { ReactComponent as ArrowRight } from '../../../styles/icons/arrow-right.svg';
import { translate } from 'utils/translations.util';
import NOTIFICATION_INFO from 'config/notifications/notifications.json';

interface NotificationGraphProps {
  notification: CropseasonNotification;
  isMobile: boolean;
}

const NotificationGraph: React.FC<NotificationGraphProps> = ({ notification, isMobile }) => {
  const [showRelatedVariables, setShowRelatedVariables] = React.useState(false);
  const [graphHeight, setGraphHeight] = React.useState(0);

  const resolution = notification.values.graph?.resolution;
  const accountId = useAppSelector(accountIdSelector).accountId;
  const selectedNotification = useAppSelector(nofiticationSelector.selectedNotifications);

  let navigate = useNavigate();
  //TODO - remove variable.name after API responds with Id's everywhere
  const NOTIFICATION_RELATED_VARIABLES = useMemo(() => {
    return JSON.parse(JSON.stringify(NOTIFICATION_INFO))[notification.type].related_variables.map(
      (variable: any) => variable
    );
  }, [notification.type]);

  useEffect(() => {
    setShowRelatedVariables(false);
  }, [selectedNotification]);

  const selectedVariables =
    notification.values.exceeded_vars || notification.values.graph?.variables || [];

  const {
    chips,
    graphVariables,
    relatedChips,
    graphRelatedVariables,
    from,
    to,
    roundedTimeRange,
    getAnalyzeUrl,
  } = UseNotificationDetails(
    notification,
    accountId,
    NOTIFICATION_RELATED_VARIABLES.map((variable: any) => variable.id || variable.name),
    selectedVariables
  );

  const expandedRangeView = {
    min: roundedTimeRange.min,
    max: dayjs(
      addResolutionToTimestamp({
        input: dayjs.unix(roundedTimeRange.max).toISOString(),
        resolution: resolution as Resolution,
      })
    ).unix(),
  };

  //TODO - use variables_id fir the id's sent by the API
  const { data: notificationRelatedVariablesData } = useGetTimeseriesQuery(
    {
      cropSeasonIds: [notification.cropseason.id],
      startDate: dayjs.unix(roundedTimeRange.min).toISOString(),
      endDate: dayjs.unix(roundedTimeRange.max).toISOString(),
      resolution: resolution,
      variables: NOTIFICATION_RELATED_VARIABLES.map((variable: { name: any }) => variable.name),
      variables_id: NOTIFICATION_RELATED_VARIABLES.map((variable: { id: any }) => variable.id),
      accountId: accountId,
    },
    {
      skip:
        !selectedNotification ||
        !selectedNotification.cropseason ||
        !NOTIFICATION_RELATED_VARIABLES.length ||
        !resolution ||
        !accountId ||
        !from ||
        !to ||
        !showRelatedVariables,
      refetchOnMountOrArgChange: true,
    }
  );

  //TODO - use variables_id fir the id's sent by the API

  const { data: analyzeData } = useGetTimeseriesQuery(
    {
      cropSeasonIds: [notification.cropseason.id],
      startDate: dayjs.unix(roundedTimeRange.min).toISOString(),
      endDate: dayjs.unix(roundedTimeRange.max).toISOString(),
      resolution: resolution,
      variables: selectedVariables,
      variables_id: [],
      accountId: accountId,
    },
    {
      skip:
        !selectedNotification ||
        !selectedNotification.cropseason ||
        !selectedVariables.length ||
        !resolution ||
        !accountId ||
        !from ||
        !to,
      refetchOnMountOrArgChange: true,
    }
  );

  const { getGraphLinesData, hasDataToRender } = useGetAnalyzeData(
    [notification.cropseason],
    analyzeData,
    expandedRangeView,
    graphVariables,
    false
  );

  const getGraphRelatedVariablesLinesData = useGetAnalyzeData(
    [notification.cropseason],
    notificationRelatedVariablesData,
    expandedRangeView,
    graphRelatedVariables,
    false
  ).getGraphLinesData;

  const dataToDisplay = showRelatedVariables
    ? getGraphLinesData().concat(getGraphRelatedVariablesLinesData())
    : getGraphLinesData();

  const showRelatedVariablesChips = relatedChips.length > 0 && showRelatedVariables;

  const { getYRangeInterval } = useGetYRangeInterval(dataToDisplay);

  const { getSunsetSunriseData } = useTwilights(
    notification.location.id,
    from,
    to,
    getGraphLinesData(),
    roundedTimeRange
  );

  const renderGraphElements = useMemo(() => {
    let graphElements = getGraphLinesData()
      .map((data) =>
        renderLineData({
          data: data.data,
          variable: data.keyName || '',
          color: data.color,
          stroke: data.stroke,
        })
      )
      .flat();
    if (showRelatedVariables) {
      let graphRelatedElements = getGraphRelatedVariablesLinesData()
        .map((data) =>
          renderLineData({
            data: data.data,
            variable: data.keyName || '',
            color: data.color,
            stroke: data.stroke,
          })
        )
        .flat();

      graphElements = graphElements.concat(graphRelatedElements);
    }
    graphElements.push(
      <line
        className='notificationIconTimestampLine'
        stroke='rgba(255, 255, 255, 0.1)'
        strokeWidth={'2px'}
        x1={-100}
        x2={-100}
        y1={0}
        y2={graphHeight}
      />
    );

    if (hasDataToRender()) {
      graphElements.unshift(
        <SunsetSunrise
          key='sunsetSunrise'
          YInterval={[getYRangeInterval()]}
          sunRiseSunSetData={getSunsetSunriseData()}
          showIcons={true}
        />
      );
    }

    graphElements.push(
      <Notification
        key='notifications'
        currentTimeRange={roundedTimeRange}
        notificationsData={[notification]}
        isMobile={isMobile}
      />
    );

    return graphElements;
  }, [
    getGraphLinesData,
    getSunsetSunriseData,
    getYRangeInterval,
    hasDataToRender,
    notification,
    roundedTimeRange,
    isMobile,
    graphHeight,
    showRelatedVariables,
    getGraphRelatedVariablesLinesData,
  ]);

  let groupedNotifications = {
    [dayjs(notification.local_datetime).unix()]: [notification],
  };

  const toggleRelatedVariables = () => {
    track('notification show related variables', {
      notificationId: selectedNotification?.id,
      notificationType: selectedNotification?.type,
      relatedVariables: NOTIFICATION_RELATED_VARIABLES,
    });
    setShowRelatedVariables(!showRelatedVariables);
  };

  const openAnalyzePage = () => {
    track('Open Analyze Page from Notification Details', {
      ...notification,
    });
    navigate(getAnalyzeUrl(isMobile, showRelatedVariables));
  };

  return (
    <>
      <div className={styles['notification-details-content']}>
        {chips.length > 0 &&
          chips.map((chip, index) => {
            return (
              <div
                className={styles['notification-details-chip']}
                key={`notificationDetails-chip-${index}`}
              >
                <Chip chip={chip}></Chip>
              </div>
            );
          })}

        {relatedChips.length > 0 && (
          <div className={styles['related-variables-toggle']}>
            <ToggleSwitch onToggle={toggleRelatedVariables} initialValue={showRelatedVariables} />
            {translate('Notification_details_show_related_variables')}
          </div>
        )}
        {showRelatedVariablesChips && (
          <div className={styles['notification-details-related-chips']}>
            {relatedChips.map((chip, index) => {
              return (
                <div
                  className={styles['notification-details-chip']}
                  key={`notificationDetails-chip-${index}`}
                >
                  <Chip chip={chip}></Chip>
                </div>
              );
            })}
          </div>
        )}
        <div className={styles['notification-details-graph']}>
          <MultilineChart
            dataToDisplay={dataToDisplay}
            resolution={resolution}
            xAxisProperty='timestamp'
            title='Analyze'
            YInterval={[getYRangeInterval()]}
            hasYIntervalExact={true}
            XInterval={[from, to]}
            hasTooltip={true}
            showTitle={false}
            showYAxisTicks={false}
            customFormatter={analyzeTimestampFormatter(resolution)}
            customFormatXTick={analyzeTimestampFormatter(resolution, isMobile)}
            yOffset={20}
            enforceTicksStartOfDay={true}
            applyPadding={false}
            XTickPadding={30}
            xAxisOffset={isMobile ? MOBILE_GRAPH_PADDING : DESKTOP_GRAPH_PADDING}
            graphHeightCallback={setGraphHeight}
          >
            {renderGraphElements}
          </MultilineChart>
          <NotificationTooltip
            groupNotifications={groupedNotifications}
            showExtraInfo={true}
          ></NotificationTooltip>
        </div>
      </div>

      <div onClick={() => openAnalyzePage()} className={styles['notification-button-to-analyze']}>
        <span className={styles['notification-button-to-analyze-text']}>Analyze</span>
        <ArrowRight />
      </div>
    </>
  );
};
export default NotificationGraph;
