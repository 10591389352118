import './LinePreview.scss';

import React from 'react';
import { getDash } from 'utils/dashes.util';

interface ILinePreviewProps {
  index?: number;
  stroke?: string;
  width?: number;
  color?: string;
}

const LinePreview: React.FC<ILinePreviewProps> = ({
  index,
  stroke,
  width = 30,
  color = '#fff',
}) => {
  return (
    <div className={`line-preview-container`} style={{ width: `${width}px` }}>
      <svg height='10' width={width}>
        <g fill='none' stroke={color} strokeWidth='2'>
          <line strokeDasharray={stroke || getDash(index || 0)} x1='0' y1='5' x2='30' y2='5' />
        </g>
      </svg>
    </div>
  );
};
export default LinePreview;
