import { SearchInputConfig } from 'interfaces/searchInput';
import './SearchInput.scss';

import React, { useRef } from 'react';
import { ReactComponent as ClearIcon } from 'styles/icons/close.svg';
import { ReactComponent as SearchIcon } from 'styles/icons/search.svg';

const SearchInput: React.FC<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    disabledtext?: string;
    type?: SearchInputConfig.StyleDark | SearchInputConfig.StyleLight;
  }
> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const clear = (event: any) => {
    event.stopPropagation();
    if (inputRef.current) {
      inputRef.current.value = '';
      if (props.onReset) {
        const event: any = null;
        props.onReset(event);
      }
    }
  };

  return (
    <div className={'search-container ' + props.type}>
      <input ref={inputRef} type='text' {...props} />
      <SearchIcon className='icon-search' />
      {inputRef.current?.value && <ClearIcon className='icon-close' onClick={clear} />}
      {props.disabled && props.disabledtext && (
        <div className='disabled-text'>{props.disabledtext}</div>
      )}
    </div>
  );
};
export default SearchInput;
