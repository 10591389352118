import React, { forwardRef } from 'react';

import './Input.scss';

interface InputProps {
  name: string;
  label?: string;
  errorText?: string;
  onChange?: (input: string) => void;
  value?: string;
  disabled?: boolean;
  required?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      label = '',
      errorText = '',
      onChange = () => {},
      value,
      disabled = false,
      required = false,
    },
    inputRef
  ) => {
    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
      onChange(event.currentTarget.value);
    };

    return (
      <div className='input-container'>
        <span className={`input-label ${required ? 'input-required' : ''}`}>{label}</span>
        <input
          ref={inputRef}
          name={name}
          className={`input ${disabled ? 'disabled' : ''}`}
          onChange={handleChange}
          autoComplete='off'
          value={value}
          disabled={disabled}
        />
        <span className='error-text'>{errorText}</span>
      </div>
    );
  }
);
export default Input;
