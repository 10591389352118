import './Production.scss';

import Tabs from 'components/Tabs/Tabs';
import TrackHeader from 'components/Track/TrackHeader/TrackHeader';
import useTranslate from 'hooks/useTranslate';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { trackFilterSelector } from 'redux/slices/trackFilterSlice';
import { setTrackPageActiveTab } from 'utils/setTrackPageActiveTab';

import Benchmark from './Benchmark/Benchmark';
import Plan from './Plan/Plan';
import { hasBenchmarkResolutionError } from 'utils/variables.util';
import { Resolution } from 'interfaces/resolution';
import { PRODUCTION_RESOLUTION, TRACKING_STATUS_NAME } from 'config/constants';

interface ProductionProps {}

const Production: React.FC<ProductionProps> = () => {
  const { dates, activeTab, resolution } = useAppSelector(trackFilterSelector);
  const translate = useTranslate();

  const resolutionError = hasBenchmarkResolutionError(
    dates.start,
    dates.end,
    resolution.value as Resolution
  );
  const hasResolutionError = resolutionError !== false && activeTab === 1;

  return (
    <div className='track-page-container'>
      <div className='track-header-container'>
        <TrackHeader
          title={translate('Level 2 - Production - page title')}
          dates={dates}
          stateName={TRACKING_STATUS_NAME.production}
          resolutionsToShow={[PRODUCTION_RESOLUTION as Resolution]}
        />
        {hasResolutionError && <div className='track-dates-error'>{resolutionError}</div>}
      </div>
      <Tabs
        tabs={[
          { label: translate('Level 2 - tab - Plan'), content: <Plan /> },
          { label: translate('Level 2 - tab - Benchmark'), content: <Benchmark /> },
        ]}
        activeTab={activeTab}
        onChange={setTrackPageActiveTab}
      />
    </div>
  );
};
export default Production;
