import AuthService from "./auth.service";
import HttpService from "./http.service";

const setTokenUpdate = async () => {
    HttpService.postKeycloak(new URLSearchParams(AuthService.getKeycloakParameters())).then(
        (res) => {
            const { access_token, refresh_token } = res.data;
            localStorage.setItem('kc_token', access_token!);
            localStorage.setItem('kc_refreshToken', refresh_token!);
        }
    );
};

const tokenUpdate = {
    setTokenUpdate
};

export default tokenUpdate;