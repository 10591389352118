import { useState, useRef, useEffect, ChangeEvent } from 'react';

import { ReactComponent as ChevronDown } from 'styles/icons/chevron-down.svg';

import './MultiselectDropDown.scss';

export type OptionWithName<T> = T & { name: string; value?: any };
interface MultiselectDropDownProps<T> {
  title?: string;
  initialValues?: T[];
  optionList: T[];
  onChange?: (options: T[]) => void;
  isRequired?: boolean;
  requiredText?: string;
  placeholder?: string;
}

const MultiselectDropDown = <T extends OptionWithName<unknown>>(
  props: MultiselectDropDownProps<T>
) => {
  const {
    initialValues = [],
    optionList = [],
    title = '',
    onChange = () => {},
    isRequired = false,
    requiredText = '',
    placeholder = 'Select a value',
  } = props;
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<T[]>(initialValues);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    setSelectedOptions(initialValues);
  }, [initialValues]);

  function handleOutsideClick(event: MouseEvent) {
    if (
      dropDownRef.current &&
      isDropDownOpen &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setIsDropDownOpen(false);
      setIsTouched(true);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  const selectOption = (option: T, event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    let newOptions;
    if (checked) {
      newOptions = [...selectedOptions, option];
    } else {
      newOptions = selectedOptions.filter((existing) => option.value !== existing.value);
    }
    setSelectedOptions(newOptions);
    onChange(newOptions);
  };

  const renderError = () => {
    if (!selectedOptions.length && isTouched && isRequired) {
      return <div className='required-text'>{requiredText}</div>;
    }
  };

  return (
    <div className='multiselect-dropdown-container'>
      <div className={`dropdown-title ${isRequired && 'required'}`}>{title}</div>
      <div className={`dropdown ${isDropDownOpen ? 'active' : ''} c-hand`} ref={dropDownRef}>
        <div
          className='dropdown-button'
          onClick={() => {
            setIsDropDownOpen(!isDropDownOpen);
          }}
        >
          <span className='preview'>
            {selectedOptions.map((option) => option.name).join(', ') || placeholder}
          </span>
          <ChevronDown className={`icon-svg ${isDropDownOpen ? 'rotate-180' : 'dropdown-icon'}`} />
        </div>
        <ul className='menu dropdown-menu'>
          {optionList?.map((option, index) => (
            <li key={index} className='dropdown-item text-noselect'>
              <label className='form-checkbox'>
                <input
                  type='checkbox'
                  checked={!!selectedOptions.find((selected) => selected.value === option.value)}
                  onChange={(event) => selectOption(option, event)}
                />
                <i className='form-icon'></i>
                <span className='text'>{option.name}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
      {renderError()}
    </div>
  );
};

export default MultiselectDropDown;
