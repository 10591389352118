import useTranslate from 'hooks/useTranslate';
import { Point } from 'interfaces/point';
import React from 'react';

interface QuadrantProps {
  points: Point[];
}

const mapPosition = (point: Point): Point => {
  return { x: point.x * 100 + 100, y: point.y * 100 * -1 + 100 };
};

const areDotsApart = (point1: Point, point2: Point, index: number): boolean => {
  return (
    Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)) > index * 4 + 18
  );
};

const Quadrant: React.FC<QuadrantProps> = ({ points }) => {
  const positions = points?.map(mapPosition);
  const translate = useTranslate();

  const renderArrow = (index: number) => {
    if (index > 0 && areDotsApart(positions[index - 1], positions[index], index))
      return (
        <g id='arrow'>
          <defs>
            <marker
              id={`head${index}`}
              orient='auto'
              markerWidth='10'
              markerHeight='10'
              refX={4 + index * 0.75}
              refY='3'
            >
              <line x1='0' y1='6' x2='3.1' y2='3' strokeWidth='1' stroke='#E7F0F3' />
              <line x1='0' y1='0' x2='3.1' y2='3' strokeWidth='1' stroke='#E7F0F3' />
            </marker>
          </defs>
          <line
            x1={positions[index - 1].x}
            y1={positions[index - 1].y}
            x2={positions[index].x}
            y2={positions[index].y}
            strokeWidth='3'
            strokeDasharray='8, 8'
            stroke='#E7F0F3'
            markerEnd={`url(#head${index})`}
          />
        </g>
      );
  };

  const renderData = () => {
    return positions.map((point, index) => {
      return (
        <g key={index}>
          <circle cx={point.x} cy={point.y + 4} r={index * 2 + 3} fill='#00000044' />
          <circle cx={point.x} cy={point.y + 2} r={index * 2 + 3} fill='#000000cc' />
          <circle
            cx={point.x}
            cy={point.y}
            r={index * 2 + 3}
            fill='#E7F0F3'
            stroke='#00000088'
            strokeWidth='1'
          />
          {renderArrow(index)}
        </g>
      );
    });
  };

  return (
    <svg height='100%' width='100%' viewBox='-60 -2 250 250'>
      <g id='axes'>
        <rect width='200' height='200' stroke='#E7F0F3' strokeWidth='3' fill='none' />
        <text x='44' y='240' fill='#E7F0F3' fontSize={26}>
          {translate('Landing page Crop Status tile state')}
        </text>

        <text x='57' y='180' fill='#E7F0F3' fontSize={26} transform={`rotate(-90 20 220)`}>
          {translate('Landing page Crop Status tile plantload')}
        </text>

        <line x1='1' y1='100' x2='199' y2='100' strokeWidth='3' stroke='#E7F0F3' />
        <line x1='100' y1='1' x2='100' y2='199' strokeWidth='3' stroke='#E7F0F3' />
      </g>
      {renderData()}
    </svg>
  );
};
export default Quadrant;
