import CardContainer from 'components/CardContainer/CardContainer';
import FileTableRow from 'components/FileTableRow/FileTableRow';
import ResizableTableHeader from 'components/ResizableTableHeader/ResizableTableHeader';
import { MAX_LENGTH_PLAN_NAME, MIN_LENGTH_PLAN_NAME, SORT, TOAST_PROMISE } from 'config/constants';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import useSorter from 'hooks/useSorter';
import { PlansFolders } from 'interfaces/plans';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  plansSelector,
  setPlansRenameId,
  setSelectedPlansFolderName,
} from 'redux/slices/plansSlice';
import {
  Plan,
  useCreatePlanMutation,
  useDuplicatePlanMutation,
  useGetPlanQuery,
  useUpdatePlanMutation,
} from 'services/plans.service';
import { ReactComponent as File } from 'styles/icons/file-text.svg';
import { track } from 'utils/analtycs';
import { getSortingIcon } from 'utils/sort-icon-getter';
import { isBetweenRange } from 'validators/plans.validator';

import PlanFileCardContent from '../PlanFileCardContent/PlanFileCardContent';
import PlansActions from '../PlansActions/PlansActions';
import styles from './Plans.module.scss';
import { dispatchPromiseToast, dispatchToast } from 'utils/toast.util';
import { languageSelector } from 'redux/slices/language.slice';
import { translate } from 'utils/translations.util';
import { CropSeasonShort } from 'interfaces/crop-season';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { ToastTypes } from 'interfaces/toast';
import { GenericPromise } from 'interfaces/promise';

interface PlansProps {
  selectedPlansFolder: PlansFolders;
}

const Plans: React.FC<PlansProps> = ({ selectedPlansFolder }) => {
  const fields = {
    name: SORT.NONE,
    last_modified_timestamp: SORT.DESCENDING,
  };
  const [dataToDisplay, fieldsToSort, sorter] = useSorter(selectedPlansFolder.plans, fields);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updatePlan] = useUpdatePlanMutation();
  const [duplicatePlan] = useDuplicatePlanMutation();

  const plansActions =
    (
      id: string,
      handleDuplicate: () => void,
      hasLinkdedCropSeason: boolean,
      planName: string,
      planCropseason: CropSeasonShort
    ) =>
    () =>
      (
        <PlansActions
          id={id}
          handleDuplicate={handleDuplicate}
          hasLinkdedCropSeason={hasLinkdedCropSeason}
          planName={planName}
          planCropseason={planCropseason}
        />
      );

  dayjs.extend(relativeTime);

  const { renameId } = useAppSelector(plansSelector);
  const { selectedLanguage } = useAppSelector(languageSelector);
  const accountId = useAppSelector(accountIdSelector).accountId;

  const planFileCardActions = (plan: Plan, planId: string) => {
    const handleDuplicate = async (plan: Plan) => {
      const planDuplicatePromise: GenericPromise<any> = await duplicatePlan({
        name: plan.name,
        path: '/' + plan?.path,
        account_id: accountId,
        cropseason_id: plan?.cropseason?.id,
        planId: plan.id,
      });

      if (planDuplicatePromise.error) {
        dispatchToast({
          type: ToastTypes.error,
          message: translate('Toast - Plan Duplicate - error'),
        });
      } else {
        dispatchToast({
          type: ToastTypes.success,
          message: translate('Toast - Plan Duplicate - success'),
        });
      }
    };
    return {
      SHOW_ACTIONS: plansActions(
        planId,
        () => handleDuplicate(plan),
        !!plan.cropseason,
        plan.name,
        plan.cropseason
      ),
      HANDLE_CARD_CLICK: () => {
        track(`select plan`, { planId: plan?.id });
        navigate(`/plan/edit/${plan.id}`, {
          state: {
            id: plan.id,
          },
        });
        dispatch(setSelectedPlansFolderName(''));
      },
      HANDLE_RENAME_CONFIRM: (newName: string) => {
        const planRenamePromise = updatePlan({
          id: plan?.id,
          data: { ...plan, path: '/' + plan.path, name: newName },
        });

        dispatchPromiseToast({
          type: TOAST_PROMISE,
          promise: planRenamePromise,
          messageLoading: translate('Toast - Plan Rename - loading', selectedLanguage),
          messageError: translate('Toast - Plan Rename - error', selectedLanguage),
          messageSuccess: translate('Toast - Plan Rename - success', selectedLanguage),
        });
        dispatch(setPlansRenameId(''));
      },
      HANDLE_RENAME_CANCEL: () => {
        dispatch(setPlansRenameId(''));
      },
      HANDLE_VALIDATE: (newName: string) => {
        return isBetweenRange(newName, MIN_LENGTH_PLAN_NAME, MAX_LENGTH_PLAN_NAME);
      },
    };
  };

  return (
    <div className={styles.plansContent}>
      <table>
        <thead className={styles.headers}>
          <tr>
            <ResizableTableHeader storekey='name'>
              <div className={styles['plan-name']}>
                {translate('My Plans - overview page - Plan name')}
                <div
                  onClick={() => {
                    sorter('name', true);
                  }}
                >
                  {getSortingIcon(fieldsToSort['name'])}
                </div>
              </div>
            </ResizableTableHeader>
            <ResizableTableHeader storekey='location' className={styles.location}>
              {translate('My Plans - overview page - Location', selectedLanguage)}
            </ResizableTableHeader>
            <ResizableTableHeader storekey='variety' className={styles.variety}>
              {translate('My Plans - overview page - Variety', selectedLanguage)}
            </ResizableTableHeader>
            <ResizableTableHeader storekey='cropseason' className={styles['crop-season']}>
              {translate('My Plans - overview page -  CropSeason', selectedLanguage)}
            </ResizableTableHeader>
            <ResizableTableHeader storekey='modified'>
              <div className={styles.modified}>
                {translate('My Plans - overview page -  Modified', selectedLanguage)}
                <div
                  onClick={() => {
                    sorter('last_modified_timestamp', true);
                  }}
                >
                  {getSortingIcon(fieldsToSort['last_modified_timestamp'])}
                </div>
              </div>
            </ResizableTableHeader>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dataToDisplay.map((plan: Plan) => {
            const planId = plan.id.toString();

            return (
              <CardContainer
                key={planId}
                actions={planFileCardActions(plan, planId)}
                isEditing={planId === renameId}
                disabled={!!renameId && planId !== renameId}
              >
                <FileTableRow
                  title={plan.name}
                  icon={<File />}
                  actions={planFileCardActions(plan, planId)}
                >
                  <PlanFileCardContent plan={plan} />
                </FileTableRow>
              </CardContainer>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default Plans;
