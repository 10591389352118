import {
  Axis as D3Axis,
  axisBottom,
  AxisDomain,
  axisLeft,
  axisRight,
  easeLinear,
  select,
} from 'd3';
import useGetTicksForGraphs from 'hooks/useGetTicksForGraphs';
import React, { useEffect, useRef } from 'react';
import { insertLinebreaks } from 'utils/insertLinebreaks';

import styles from './Axis.module.scss';

interface AxisProps {
  type: 'left' | 'bottom' | 'right';
  scale: any;
  ticks: number;
  tickFormat: (d: any) => string;
  transform?: string;
  interval?: [number, number];
  isAnimating: boolean;
  className?: string;
  showTicks?: boolean;
  color?: string;
  resolution?: string;
  enforceTicksStartOfDay?: boolean;
  getTicks?: (domainInput: [number, number], input: number) => number[];
  tickPadding?: number;
}

function getAxisType(type: string) {
  switch (type) {
    case 'left': {
      return axisLeft;
    }
    case 'bottom': {
      return axisBottom;
    }
    case 'right':
    default: {
      return axisRight;
    }
  }
}

const getShouldHideSecondTick = (ticksCount: number, axis: D3Axis<AxisDomain>, type: string) => {
  if (ticksCount < 4 || type !== 'bottom') {
    return false;
  }

  const ticksArray = axis.tickValues();
  const length = ticksArray?.length || 0;

  const firstTickDistance = (ticksArray?.[1] as number) - (ticksArray?.[0] as number);
  const secondTickDistance =
    (ticksArray?.[length - 1] as number) - (ticksArray?.[length - 2] as number);

  return firstTickDistance / secondTickDistance < 0.6;
};

const getShouldHideBeforeLastTick = (
  ticksCount: number,
  axis: D3Axis<AxisDomain>,
  type: string
) => {
  if (ticksCount < 4 || type !== 'bottom') {
    return false;
  }

  const ticksArray = axis.tickValues();
  const length = ticksArray?.length || 0;

  const lastTickDistance =
    (ticksArray?.[length - 1] as number) - (ticksArray?.[length - 2] as number);
  const beforeLastTickDistance =
    (ticksArray?.[length - 2] as number) - (ticksArray?.[length - 3] as number);

  return lastTickDistance / beforeLastTickDistance < 0.6;
};

const Axis: React.FC<AxisProps> = ({
  type,
  scale,
  ticks,
  tickFormat,
  isAnimating = false,
  transform,
  interval,
  showTicks = true,
  color = '#fff',
  getTicks,
  resolution,
  enforceTicksStartOfDay,
  tickPadding = 5,
  ...props
}) => {
  const ref = useRef(null);
  const ticksArray = useGetTicksForGraphs(
    interval,
    ticks,
    getTicks,
    resolution,
    enforceTicksStartOfDay
  );

  useEffect(() => {
    const axisGenerator = getAxisType(type);
    const axis = axisGenerator(scale)
      .ticks(showTicks ? ticks : 0)
      .tickFormat(tickFormat)
      .tickPadding(tickPadding)
      .tickSizeOuter(0);
    if (ticksArray) {
      axis.tickValues(ticksArray);
    }
    const axisGroup = select(ref.current);
    if (!isAnimating) {
      axisGroup.call(axis as any);
    } else {
      axisGroup
        .transition()
        .duration(750)
        .ease(easeLinear)
        .call(axis as any);
    }

    const shouldHideBeforeLastTick = getShouldHideBeforeLastTick(ticks, axis, type);
    const shouldHideSecondTick = getShouldHideSecondTick(ticks, axis, type);

    const length = axis.tickValues()?.length || 0;
    axisGroup.selectAll('line').remove();
    axisGroup
      .selectAll('.tick text')
      .style('color', color)
      .each(function (_, index) {
        if (
          (shouldHideBeforeLastTick && index === length - 2) ||
          (shouldHideSecondTick && index === 1)
        ) {
          select(this).text('');
        }
        insertLinebreaks(this);
      });
  }, [scale, ticks, tickFormat, isAnimating, ticksArray, type, showTicks, color]);
  return <g ref={ref} transform={transform} {...props} className={styles.axis} />;
};
export default Axis;
