import type { RootState } from 'redux/store';
import { createSlice } from '@reduxjs/toolkit';

interface userNameSliceState {
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string | undefined;
}

const initialState: userNameSliceState = {
  givenName: '',
  familyName: '',
  email: '',
  phoneNumber: undefined,
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { setUserInfo } = userInfoSlice.actions;

export const userInfoSelector = (state: RootState) => state.userInfo;

export default userInfoSlice.reducer;
