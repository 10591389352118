import React, { ChangeEvent, useEffect, useState } from 'react';

import styles from './StrategiesInput.module.scss';

interface StrategiesInputProps {
  value?: number;
  setOnChange: (value: string) => void;
}

const StrategiesInput: React.FC<StrategiesInputProps> = ({ value, setOnChange }) => {
  const [currentValue, setCurrentValue] = useState(Number(value).toFixed(0));

  useEffect(() => {
    setCurrentValue(Number(value).toFixed(0) || '');
  }, [value]);

  useEffect(() => {
    setOnChange(currentValue);
  }, [currentValue]);

  function handleOnBlur() {
    if (parseFloat(currentValue).toFixed(0) === 'NaN') {
      setCurrentValue(Number(0).toFixed(0));
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    setCurrentValue(event.target.value);
  }

  return (
    <input
      className={styles.input}
      value={currentValue}
      type='text'
      onBlur={handleOnBlur}
      onChange={handleInputChange}
    />
  );
};
export default StrategiesInput;
