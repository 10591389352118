import './Spinner.scss';

import { ReactComponent as LoadingIcon } from 'styles/icons/loading.svg';
import { ReactComponent as LogoIcon } from 'styles/icons/logo.svg';

const Spinner: React.FC = () => {
  return (
    <div className='spinner-overlay'>
      <div className='spinner-container'>
        <LogoIcon className='logo-icon' />
        <LoadingIcon className='loading-icon' />
      </div>
    </div>
  );
};

export default Spinner;
