import { Toast } from 'interfaces/toast';
import { TOAST_SUCCESS, TOAST_ERROR } from 'config/constants';
import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';
import { ReactComponent as ToastSuccessIcon } from '../styles/icons/toast-success.svg';
import { ReactComponent as ToastErrorIcon } from '../styles/icons/toast-error.svg';

const toastCustomization = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
} as ToastOptions;

const renderSuccessToast = (message: string) => (
  <div className='react-toast toast-container toast-success'>
    <div className='toast-title'>
      <ToastSuccessIcon className='toast-icon' /> <b>Success</b>
    </div>
    <div className='toast-description'>{message}</div>
  </div>
);

const renderErrorToast = (message: string) => (
  <div className='react-toast toast-container toast-error'>
    <div className='toast-title'>
      <ToastErrorIcon className='toast-icon' /> <b>Error</b>
    </div>
    <div className='toast-description'>{message}</div>
  </div>
);

export const dispatchToast = (toastData: Toast) => {
  switch (toastData.type) {
    case TOAST_SUCCESS:
      toast.success(renderSuccessToast(toastData.message), {
        ...toastCustomization,
        icon: false,
        className: 'react-toast toast-container toast-success',
      });
      break;
    case TOAST_ERROR:
      toast.error(renderErrorToast(toastData.message), {
        ...toastCustomization,
        icon: false,
        className: 'react-toast toast-container toast-error',
      });
      break;
    default:
      break;
  }
};

export const dispatchPromiseToast = (toastData: any) => {
  toast
    .promise(
      toastData.promise,
      {
        pending: {
          render: toastData.messageLoading,
          className: 'react-toast toast-container toast-loading',
        },
      },
      toastCustomization
    )
    .then((result: any) => {
      if (result.error) {
        toast.error(renderErrorToast(toastData.messageError), {
          ...toastCustomization,
          icon: false,
          className: 'react-toast toast-container toast-error',
        });
      } else {
        toast.success(renderSuccessToast(toastData.messageSuccess), {
          ...toastCustomization,
          icon: false,
          className: 'react-toast toast-container toast-success',
        });
      }
    })
    .catch((error: any) => {
      // Show the error toast if there's an error in the promise
      toast.error(renderErrorToast(toastData.messageError), {
        ...toastCustomization,
        icon: false,
        className: 'react-toast toast-container toast-error',
      });
    });
};
