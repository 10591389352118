import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { ParsedDashBoardData } from 'interfaces/dashboard-data';
import {
  ClimateApiData,
  FreshWeightGainApiData,
  ParsedProductionTimeseriesData,
  Production,
  WaterEfficiencyApiData,
} from 'interfaces/trackings';
import { dataSorter } from 'utils/data-sorter';

import { getIntervalForActual } from './getIntervalsForGraphs';

dayjs.extend(utc);
dayjs.extend(weekOfYear);

export const EMPTY_LINE_RESPONSE = (): ParsedDashBoardData => ({
  lineData: [],
  areaData: [],
  yInterval: { min: Infinity, max: -Infinity },
  xInterval: [0, 100],
});

export const EMPTY_BAR_RESPONSE = (): ParsedProductionTimeseriesData => ({
  barData: [],
  xInterval: ['0', '100'],
  yInterval: { min: 0, max: 100 },
});

function parseDashboardData(data: any, keyName: string, timeInput: string, valueInput: string) {
  let parsedData = EMPTY_LINE_RESPONSE();

  if (!data) {
    return parsedData;
  }

  parsedData.yInterval = getIntervalForActual(data, valueInput);
  const xInterval = getIntervalForActual(data, timeInput);
  parsedData.xInterval = [xInterval.min, xInterval.max];

  for (let i = 0; i < data[valueInput].length; i++) {
    const timestamp = data[timeInput][i];
    const value = data[valueInput][i];

    parsedData.lineData.push({
      timestamp,
      [keyName]: value,
    });

    parsedData.areaData.push({
      timestamp,
      value: [Math.floor(parsedData.yInterval.min), value],
    });
  }

  return {
    ...parsedData,
    lineData: dataSorter(parsedData.lineData, 'asc', 'timestamp'),
    areaData: dataSorter(parsedData.areaData, 'asc', 'timestamp'),
  };
}

export const parseDashboardClimateGraphData = (data: ClimateApiData): ParsedDashBoardData => {
  if (!data) {
    return EMPTY_LINE_RESPONSE();
  }

  return parseDashboardData(data.timeseries, data.name, 'hour', 'temperature');
};

function getTimestampFromYearAndWeek(input: string) {
  let year = input.split('-W')[0];
  let weekNumber = Number(input.split('-W')[1]);

  return dayjs(year).utc(true).week(weekNumber).unix();
}

export const parseDashboardProductionGraphData = (
  data: Production
): ParsedProductionTimeseriesData => {
  if (!data?.historic_data) {
    return EMPTY_BAR_RESPONSE();
  }

  let mappedGraphData: {
    value: number;
    timestamp: number;
    colors?: {
      startColor: string;
      stopColor: string;
    };
  }[] = [];
  for (let i = 0; i < data.historic_data.value.length; i++) {
    mappedGraphData.push({
      value: data.historic_data.value[i],
      timestamp: getTimestampFromYearAndWeek(data.historic_data.weeknr[i]),
    });
  }

  if (data.status !== 'ok' && mappedGraphData.length) {
    mappedGraphData[mappedGraphData.length - 1].colors = {
      startColor: '#FF8947',
      stopColor: 'rgba(255, 137, 71, 0.06)',
    };
  }

  const yInterval = getIntervalForActual(data.historic_data, 'value');

  return {
    barData: mappedGraphData,
    xInterval: mappedGraphData.map((data) => String(data.timestamp)),
    yInterval,
  };
};

export const parseDashboardFreshWeightGainGraphData = (
  data: FreshWeightGainApiData
): ParsedDashBoardData => {
  if (!data) {
    return EMPTY_LINE_RESPONSE();
  }
  return parseDashboardData(data.hour_timeseries, data.name, 'hour', 'value');
};

export const parseDashboardWaterEfficiencyGraphData = (
  data: WaterEfficiencyApiData
): ParsedDashBoardData => {
  if (!data) {
    return EMPTY_LINE_RESPONSE();
  }
  return parseDashboardData(data.hour_timeseries, data.name, 'hour', 'value');
};
