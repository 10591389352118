
import { Range } from 'interfaces/range';
import { GraphLine } from "interfaces/line-graph-data";
import { useCallback } from "react";


type UseGetSliderAreaData = {
    getSliderGraphData: () => { timestamp: number; value: number[]; }[];
}

export default function useGetAnalyzeData(
    graphLinesData: GraphLine[],
    timeRange: Range,
): UseGetSliderAreaData {


    //Slider graph data is the sum of all graph lines data represented by an Area 
    const getSliderGraphData = useCallback(() => {
        const data = graphLinesData,
            linesLength = data.length,
            newPath = [];

        if (linesLength) {
            const pointsLength = data[0].data.length;
            for (let i = 0; i < pointsLength; i++) {
                let newPointSum = 0;
                let timeStampSum = 0;
                for (let j = 0; j < linesLength; j++) {
                    if (data[j].data[i]) {
                        newPointSum += Object.values(data[j].data[i])[1] as number;
                        timeStampSum += Object.values(data[j].data[i])[0] as number;
                    }
                }
                let resultedPoint = {};
                //do not show negative points
                if (newPointSum / linesLength > 0) {
                    let resultedTimestamp = timeStampSum / linesLength;
                    if (
                        resultedTimestamp < timeRange.max &&
                        resultedTimestamp > timeRange.min
                    ) {
                        resultedPoint = {
                            timestamp: timeStampSum / linesLength,
                            value: [0, newPointSum / linesLength],
                        };

                        newPath.push(resultedPoint);
                    }
                }
            }
        }
        return newPath as Array<{ timestamp: number; value: number[] }>;
    }, [graphLinesData, timeRange.max, timeRange.min]);



    return { getSliderGraphData };

};

