import { shopStatus } from 'interfaces/shop';
import dayjs from 'dayjs';

import { baseApi } from 'redux/slices/baseApi.slice';
import { STRATEGIES_FIELDS } from 'config/constants';
import { Strategy } from 'interfaces/strategies';
import { extractMinMax, getRanges } from 'utils/getIntervalsForGraphs';
import { formatQueryString } from 'utils/queryString';

const SAVE_STRATEGY_BODY_REQUEST = {
  op: 'replace',
  path: '/chosen_strategy',
};

const shopApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getShop: builder.query<any, string>({
      query: (shopId) => {
        const ENDPOINT = `plan-shops/${shopId}`;
        return ENDPOINT;
      },
      transformResponse: (rawResult: any): shopStatus => {
        const result = rawResult.result?.plan_shop;
        return result;
      },
    }),
    getShopStatus: builder.mutation<shopStatus, string>({
      query: (shopId) => {
        const ENDPOINT = `plan-shops/${shopId}/status`;
        return ENDPOINT;
      },
      transformResponse: (rawResult: any): shopStatus => {
        const result = rawResult.result;
        return result;
      },
    }),
    getStrategiesTimeseries: builder.query<any, { shopId: string; strategyId: string }>({
      query: ({ shopId, strategyId }) => {
        const ENDPOINT = `plan-shops/${shopId}/timeseries?`;
        return (
          ENDPOINT +
          formatQueryString({
            strategy_id: strategyId,
          })
        );
      },
      transformResponse: (rawResults: { result: { timeseries: any } }, meta, args): any => {
        const response = rawResults.result.timeseries[Number(args.strategyId)];
        let finalResponse: any = [];
        let weeks = response.plan_weeknr;
        let dateTimeWeeks = response.local_datetime.map((week: string) => dayjs(week).week());
        let timestamps = response.local_datetime.map((time: string) => dayjs(time).unix());
        let responseKeyValue = {};

        Object.keys(response).forEach((key) => {
          finalResponse = { ...finalResponse, [key]: [] };
          responseKeyValue = { ...responseKeyValue, [key]: key };
        });

        function setFinalReponse(data: number, index: number, property: string) {
          finalResponse[property].push({
            [property + args.strategyId]: data,
            timestamp: timestamps[index],
            dateTimeWeek: dateTimeWeeks[index],
            timestampWeek: weeks[index],
          });
        }

        for (let property in response) {
          response[property].forEach((graphData: number, index: number) => {
            setFinalReponse(graphData, index, property);
          });
        }
        return {
          ...finalResponse,
          ranges: {
            ...getRanges(response, responseKeyValue),
            weeks: [weeks[0], weeks[weeks.length - 1]],
          },
        };
      },
    }),
    createShop: builder.mutation<any, number>({
      query: (planId) => {
        const ENDPOINT = `plan-shops?plan_id=${planId}`;
        return {
          url: ENDPOINT,
          method: 'POST',
          credentials: 'include',
        };
      },
      transformResponse: (rawResult: {
        result: {
          plan_shop: {
            id: string;
          };
          estimated_time_in_seconds: number;
          estimated_n_strategies: number;
        };
      }): { id: string; estimatedTime: number; numberOfStrategies: number } => {
        return {
          id: rawResult.result.plan_shop.id,
          estimatedTime: rawResult.result.estimated_time_in_seconds,
          numberOfStrategies: rawResult.result.estimated_n_strategies,
        };
      },
    }),
    getStrategies: builder.query<any, string>({
      query: (shopId) => {
        const ENDPOINT = `plan-shops/${shopId}`;
        return ENDPOINT;
      },
      transformResponse: (rawResult: {
        result: {
          plan_shop: {
            strategies: Strategy[];
          };
        };
      }) => {
        const result = rawResult.result.plan_shop.strategies;
        const data = result?.map((strategy, index: number) => ({
          ...strategy,
          name: `Strategy ${index + 1}`,
        }));
        return {
          data,
          ranges: extractMinMax(result, STRATEGIES_FIELDS),
        };
      },
    }),
    saveStrategy: builder.mutation<any, { id: string; strategyId: number }>({
      query: ({ id, strategyId }) => ({
        url: `plan-shops/${id}`,
        method: 'PATCH',
        credentials: 'include',
        body: [
          {
            ...SAVE_STRATEGY_BODY_REQUEST,
            value: strategyId,
          },
        ],
      }),
    }),
  }),
});

export const {
  useGetShopQuery,
  useGetShopStatusMutation,
  useGetStrategiesTimeseriesQuery,
  useCreateShopMutation,
  useGetStrategiesQuery,
  useSaveStrategyMutation,
} = shopApi;
