import styles from './ImportData.module.scss';
import { useUploadExcelMutation } from 'services/upload.service';
import FileUploadInput from 'components/FileUploadInput/FileUploadInput';
import { dispatchToast } from 'utils/toast.util';
import { TOAST_ERROR, TOAST_SUCCESS } from 'config/constants';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { isExcelFileType } from 'utils/fileType.util';
import useTranslate from 'hooks/useTranslate';
import { track } from 'utils/analtycs';

const ImportData: React.FC = () => {
  const [uploadExcel] = useUploadExcelMutation();
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { selectedLanguage } = useAppSelector(languageSelector);
  const translate = useTranslate();

  const trackEventUpload = {
    name: 'Excel upload',
    success: 'success',
    failed: 'failed',
  };

  const handleUpload = async (file: File) => {
    if (!file) return;

    if (!isExcelFileType(file)) {
      track(`${trackEventUpload.name}`, {
        status: `${trackEventUpload.failed}`,
        message: 'Upload EXCEL - Invalid excel file type',
      });
      dispatchToast({
        type: TOAST_ERROR,
        message: translate('Upload EXCEL - Invalid excel file type'),
      });
      return;
    }
    const formData = new FormData();
    formData.append('file', file);

    const result: any = await uploadExcel({
      account_id: accountId,
      language: selectedLanguage,
      data: formData,
    });

    if (result?.error) {
      track(`${trackEventUpload.name}`, {
        status: `${trackEventUpload.failed}`,
        message: result.error?.data?.message,
      });
      const message =
        result.error.status === 422
          ? translate('Upload EXCEL - Upload failed')
          : translate(result.error?.data?.message);
      dispatchToast({
        type: TOAST_ERROR,
        message: message,
      });
    } else {
      track(`${trackEventUpload.name}`, {
        status: `${trackEventUpload.success}`,
        message: 'Upload EXCEL - Upload success',
        account_id: accountId,
        file_names: result.data?.file_names,
        template_type: result.data?.template_type,
      });
      dispatchToast({
        type: TOAST_SUCCESS,
        message: translate('Upload EXCEL - Upload success'),
      });
    }
  };

  return (
    <div className={styles['page-container']}>
      <div className={styles['page-header']}>
        <div className={styles['page-header-title']}>{translate('Menu - Upload')}</div>
      </div>
      <div className={styles['import-data-upload-content']}>
        <div className={styles['import-fields']}>
          <form id='uploadForm' encType='multipart/form-data'>
            <div>
              <FileUploadInput onFileChange={handleUpload} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ImportData;
