import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);

export function weekFormat(input: number) {
  return `Week ${input}`;
}

export function weekFromTimestamp(input: number) {
  return `Week ${dayjs.unix(input).week()}`;
}


export function weekFromTimestampNoLabel(input: number) {
  return dayjs.unix(input).week().toString();
}
