import React, { ReactNode, useState } from 'react';

import './Switch.scss';

type ColorMode = 'LIGHT' | 'DARK';

interface ISwitchProps {
  colorMode?: ColorMode;
  onChange?: (value: 1 | 2) => void;
  children?: ReactNode[];
}

const Switch: React.FC<ISwitchProps> = ({ colorMode = 'DARK', onChange = () => {}, children }) => {
  const [activeButton, setActiveButton] = useState<1 | 2>(1);

  const getColorClass = (colorMode: ColorMode): string => {
    if (colorMode === 'DARK') {
      return 'dark';
    } else if (colorMode === 'LIGHT') {
      return 'light';
    }
    return '';
  };

  const changeButton = (button: 1 | 2) => {
    setActiveButton(button);
    onChange(button);
  };

  return (
    <div className={`switch-container btn-group ${getColorClass(colorMode)}`}>
      <button
        className={`btn ${activeButton === 1 ? 'active' : ''}`}
        onClick={() => changeButton(1)}
      >
        {children && children[0]}
      </button>
      <button
        className={`btn ${activeButton === 2 ? 'active' : ''}`}
        onClick={() => changeButton(2)}
      >
        {children && children[1]}
      </button>
    </div>
  );
};
export default Switch;
