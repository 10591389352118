import { SORT } from 'config/constants';
import { ReactComponent as DoubleArrow } from 'styles/icons/arrow-double.svg';
import { ReactComponent as ChevronDown } from 'styles/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'styles/icons/chevron-up.svg';

export const getSortingIcon = (direction: string) => {
  if (direction === SORT.ASCENDING) {
    return <ChevronUp className='icon-small' />;
  } else if (direction === SORT.DESCENDING) {
    return <ChevronDown className='icon-small' />;
  } else {
    return <DoubleArrow className='icon-small' />;
  }
};

export const getSortingHoverIcon = (direction: string) => {
  if (direction === SORT.ASCENDING) {
    return <DoubleArrow className='icon-small' />;
  } else if (direction === SORT.DESCENDING) {
    return <ChevronUp className='icon-small' />;
  } else {
    return <ChevronDown className='icon-small' />;
  }
};
