import styles from './NotificationTooltip.module.scss';

import React from 'react';
import TooltipCustom from 'components/TooltipCustom/TooltipCustom';
import { GroupedNotification } from 'views/Analyze/hooks/useGraphNotifications';
import { CropseasonNotification } from 'interfaces/cropseason-notification';
import { translate } from 'utils/translations.util';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import NotificationItem from 'components/NotificationItem/NotificationItem';
import { NotificationItemConfig } from 'interfaces/notificationItem';

interface NotificationTooltipProps {
  groupNotifications: GroupedNotification;
  showExtraInfo?: boolean;
}

const MAX_TOOLTIP_NOTIFICATIONS = 5;

const NotificationTooltip: React.FC<NotificationTooltipProps> = ({
  groupNotifications,
  showExtraInfo = false,
}) => {
  const { selectedLanguage } = useAppSelector(languageSelector);

  const renderNotificationTooltipEnd = () => {
    return (
      <div className={styles['notification-tooltip-end']}>
        <span>{translate('Notification-tooltip-zoom-into-graph', selectedLanguage)}</span>
      </div>
    );
  };

  const renderNotificationTooltipContent = (
    notifications: CropseasonNotification[]
  ): JSX.Element => {
    let maxInfo =
      notifications.length > MAX_TOOLTIP_NOTIFICATIONS
        ? MAX_TOOLTIP_NOTIFICATIONS
        : notifications.length;
    return (
      <div className={styles['notification-items']}>
        {notifications.slice(0, maxInfo).map((notification, index) => {
          return (
            <NotificationItem
              data={notification}
              id={index}
              style={NotificationItemConfig.StyleLight}
              type={NotificationItemConfig.TypeTooltip}
              showExtraInfo={showExtraInfo}
            />
          );
        })}
        {notifications.length > MAX_TOOLTIP_NOTIFICATIONS && renderNotificationTooltipEnd()}
      </div>
    );
  };

  return (
    <>
      {groupNotifications &&
        Object.keys(groupNotifications)
          .reverse()
          .map((timestamp, index) => {
            return (
              <TooltipCustom
                dataForId={`notification-icon-${timestamp}-${index}`}
                key={`notification-icon-key-tooltip-${timestamp}-${index}`}
                renderContent={() =>
                  renderNotificationTooltipContent(groupNotifications[Number(timestamp)])
                }
                showTooltip={true}
                backgroundColor={'#ffffff'}
                padding='0'
                place='bottom'
              ></TooltipCustom>
            );
          })}
    </>
  );
};
export default NotificationTooltip;
