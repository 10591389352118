import React from 'react';
import { useAppDispatch } from 'redux/hooks';
import { setPlansRenameId } from 'redux/slices/plansSlice';
import useTranslate from 'hooks/useTranslate';

import styles from './FolderActions.module.scss';

interface FolderActionsProps {
  id: string;
  handleDelete: () => void;
}

const FolderActions: React.FC<FolderActionsProps> = ({ id, handleDelete }) => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  return (
    <>
      <div
        className={`${styles['generic-item']} c-hand`}
        onClick={() => {
          dispatch(setPlansRenameId(id));
        }}
      >
        <span>{translate('My Plans - rename a folder')}</span>
      </div>
      <div
        className={`${styles['generic-item']} c-hand`}
        onClick={() => {
          handleDelete();
        }}
      >
        <span>{translate('My Plans - delete a folder')}</span>
      </div>
    </>
  );
};
export default FolderActions;
