
import dayjs from 'dayjs';
import { Range } from 'interfaces/range';
import { useCallback } from 'react';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { analyzeSelectors } from 'redux/slices/analyze.slice';
import { useGetCropSeasonNotificationsQuery } from 'services/notifications.service';
import { getEndOfDayFormated, getStartOfDayFormated, getUTC } from 'utils/time.util';

export default function useGetAnalyzeNotifications(
  sliderTimeRange: Range,
  dates: { from: number; to: number }
) {
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { selectedCropseasons } = useAppSelector(analyzeSelectors.cropseason);

  const { data: notificationsData } = useGetCropSeasonNotificationsQuery(
    {
      current_account_id: accountId,
      cropseason_ids: selectedCropseasons.map((cropseason) => cropseason.id.toString()),
      min_local_datetime: getStartOfDayFormated(dayjs.unix(dates.from).toISOString()),
      max_local_datetime: getEndOfDayFormated(dayjs.unix(dates.to).toISOString()),
    },
    { skip: !selectedCropseasons.length || !accountId }
  );

  const getNotificationsData = useCallback(() => {
    if (!selectedCropseasons.length) {
      return [];
    }
    return notificationsData?.sliderNotifications?.filter((values) => {
      const timestamp = getUTC(values[0].timestamp);
      return timestamp >= getUTC(sliderTimeRange.min) && timestamp <= getUTC(sliderTimeRange.max);
    });
  }, [notificationsData, sliderTimeRange.max, sliderTimeRange.min, selectedCropseasons]);

  return {
    sliderNotifications: getNotificationsData(),
    notifications: notificationsData?.notifications,
  };
}
