import './TrackHeader.scss';

import DatePickerDropdown from 'components/DatePicker/DatePickerDropdown/DatePickerDropdown';
import DropDown from 'components/DropDown/DropDown';
import {
  activeCropseasonInitialValue,
  BREAKPOINT_DROPDOWN_RIGHT_TRACKS_HEADER,
  TRACKING_STATUS_NAME,
} from 'config/constants';
import dayjs from 'dayjs';
import useUrlState from 'hooks/useUrlState';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { cropseasonSelector, setActiveCropseason } from 'redux/slices/cropseason.slice';
import { languageSelector } from 'redux/slices/language.slice';
import {
  resetGroups,
  setDate,
  setResolution,
  trackFilterSelector,
} from 'redux/slices/trackFilterSlice';
import { useGetCropseasonsQuery } from 'services/cropseason.service';
import { ReactComponent as CheckMark } from 'styles/icons/checkmark.svg';
import { track } from 'utils/analtycs';
import {
  getActiveCropseasonIntitialValue,
  getResolutionsInitialValues,
} from 'utils/getInitialValues';
import { translate } from 'utils/translations.util';
import useDefaultCropSeason from 'hooks/useDefaultCropSeason';
import { Resolution } from 'interfaces/resolution';

interface TrackHeaderProps {
  title: string;
  dates: {
    start: number;
    end: number;
  };
  stateName: string;
  resolutionsToShow?: Resolution[];
}

const TrackHeader: React.FC<TrackHeaderProps> = ({
  title,
  dates,
  stateName,
  resolutionsToShow,
}) => {
  const windowSize: WindowSize = useWindowSize();
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { data: cropSeasons } = useGetCropseasonsQuery(accountId, { skip: !accountId });
  const [filteredCropseasons, setFilteredCropseasons] = useState(cropSeasons);

  const activeCropseason = useAppSelector(cropseasonSelector);
  const dispatch = useAppDispatch();
  const trackState = useAppSelector(trackFilterSelector);
  const RESOLUTIONS = getResolutionsInitialValues();
  const ACTIVE_CROPSEASON_INIT = getActiveCropseasonIntitialValue();
  const { selectedLanguage } = useAppSelector(languageSelector);
  const [urlState, setUrlState] = useUrlState({
    cropseasonId: String(activeCropseason.id),
    accountId: String(accountId),
    startDate: '',
    endDate: '',
    resolution: '',
  });

  useEffect(() => {
    setFilteredCropseasons(cropSeasons);
  }, [cropSeasons]);

  //variable values have a timestamp of 12 AM - so startOf a day. Choose the start of the day in the date picker to avoid bugs like data not showing
  const onChangeDates = (values: any) => {
    const valueStart = values.start.startOf('day').unix();
    setUrlState({ ...urlState, startDate: valueStart, endDate: values.end.unix() });
    dispatch(setDate({ start: valueStart, end: values.end.unix() }));
  };

  const dropdownOnChange = (_: any, option: any) => {
    track('change cropSeason in track pages', {
      ...option,
    });
    setUrlState({ ...urlState, cropseasonId: String(option.id) });
    dispatch(resetGroups());
    dispatch(setActiveCropseason(option));
  };

  const { handleDefaultCropseason, handleRemoveDefaultCropseason, isValueDefault } =
    useDefaultCropSeason();

  const onChangeResolution = (_: string, value: any) => {
    setUrlState({ ...urlState, resolution: value.value });
    dispatch(setResolution(value));
  };

  function handleDatesError() {
    if (dates.end < dates.start) {
      return 'The start date should be earlier than the end date.';
    }
  }

  const getDatepicekrClass = () => {
    if (windowSize.width <= 465) {
      return `custom-range-left`;
    }
    if (windowSize.width <= 563) {
      return `custom-range-right`;
    }
    if (windowSize.width <= 1110) {
      return `custom-range-middle`;
    }
    return `custom-range-left`;
  };

  const filterCropseasons = useCallback(
    (keyword: string) => {
      if (!cropSeasons) {
        return;
      }

      const filteredList = cropSeasons.filter((cropseason) =>
        cropseason.name.toLowerCase().includes(keyword.toLowerCase())
      );

      setFilteredCropseasons(filteredList);
    },
    [cropSeasons]
  );

  const getResolutionInitValue = (
    hasOnlyOneOption: boolean,
    trackStateResolution: any,
    resolutionList?: Resolution[]
  ) => {
    if (!resolutionList?.length) {
      return trackStateResolution;
    }

    if (hasOnlyOneOption || !resolutionList.some((res) => res === trackStateResolution.value)) {
      return RESOLUTIONS.find((r) => r.value === resolutionList[0]);
    }

    let foundResolution = resolutionList.find((res) => res === trackStateResolution.value);
    return RESOLUTIONS.find((r) => r.value === foundResolution);
  };

  const resolutionsList = resolutionsToShow
    ? resolutionsToShow.map((res) => RESOLUTIONS.find((r) => r.value === res))
    : RESOLUTIONS;

  const hasOnlyOneOption = resolutionsList?.length === 1;
  const resolutionInitValue = getResolutionInitValue(
    hasOnlyOneOption,
    trackState.resolution,
    resolutionsToShow
  );

  return (
    <div className='track-page-header'>
      <div className='track-page-title'>{title}</div>
      <div className='header-filters-container'>
        <div className='cropseason-dropdown-container'>
          <DropDown
            required={false}
            initialValue={
              activeCropseason.id === activeCropseasonInitialValue.id
                ? ACTIVE_CROPSEASON_INIT
                : activeCropseason
            }
            name='cropseason'
            optionList={filteredCropseasons || []}
            setOnChange={dropdownOnChange}
            displayProperty='name'
            rightSide={windowSize.width > BREAKPOINT_DROPDOWN_RIGHT_TRACKS_HEADER}
            hasTooltip
            activePreElement={<CheckMark />}
            isSorted={true}
            hasDefaultValue={true}
            setAsDefault={handleDefaultCropseason}
            removeDefault={handleRemoveDefaultCropseason}
            isValueDefault={isValueDefault}
            searchOptions={{
              placeholder: translate('CropSeason selector - search cropseason', selectedLanguage),
              onChange: filterCropseasons,
            }}
          />
        </div>
        <div className='cropseason-date-container'>
          <DatePickerDropdown
            onChangeDropdown={onChangeDates}
            initialValues={{ start: dayjs.unix(dates.start), end: dayjs.unix(dates.end) }}
            required
            requiredText={handleDatesError()}
            customRangePosition={getDatepicekrClass()}
            cropSeasonDates={{
              start: dayjs(activeCropseason.transplant_date),
              end:
                dayjs(activeCropseason.end_date) ||
                dayjs(activeCropseason.transplant_date).add(1, 'year'),
            }}
          />
        </div>
        <div className='cropseason-resolution-container'>
          <DropDown
            optionList={resolutionsList}
            displayProperty={'displayValue'}
            initialValue={resolutionInitValue}
            setOnChange={onChangeResolution}
            disabled={hasOnlyOneOption}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackHeader;
