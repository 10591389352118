import React from 'react';
import './TwilightLegend.scss';
import TwilightLegendItem from '../TwilightLegendItem/TwilightLegendItem';
import { SUNRISE_BG_COLOR, SUNSET_BG_COLOR } from 'config/constants';
import useTranslate from 'hooks/useTranslate';

const TwilightLegend: React.FC = () => {
  const translate = useTranslate();
  return (
    <div className={`lines-pattern-legend`}>
      <TwilightLegendItem
        width='20'
        height='20'
        color={SUNRISE_BG_COLOR}
        text={translate('Analzye Legend Day')}
      />
      <TwilightLegendItem
        width='20'
        height='20'
        color={SUNSET_BG_COLOR}
        text={translate('Analzye Legend Night')}
      />
    </div>
  );
};
export default TwilightLegend;
