import store from 'redux/store';
import { getCustomPlanValues } from './getCustomPlanValues';
import { formatTimestampToYearMonthDay } from './formatTimestampToYearMonthDay';

export function getDataForPlan(
  source: 'creationCustomSettings' | 'editCustomSettings' | 'customPlanState'
) {
  const {
    variety,
    location,
    plantingDate,
    headRemovalDate,
    harvestDate,
    rootstock,
    sowingDate,
    firstSettingDate,
  } = store.getState().planCreation;

  const confirmedCustomSettings = store.getState().customPlan[source];
  const data: {
    variety_id: number;
    location_id: number;
    rootstock_id?: number;
    transplant_date: string;
    first_setting_date: string;
    head_removal_date: string;
    sowing_date: string;
    end_date: string;
    custom_settings?: any;
  } = {
    variety_id: variety?.id!,
    location_id: location?.id!,
    sowing_date: formatTimestampToYearMonthDay(sowingDate!),
    transplant_date: formatTimestampToYearMonthDay(plantingDate!),
    first_setting_date: formatTimestampToYearMonthDay(firstSettingDate!),
    head_removal_date: formatTimestampToYearMonthDay(headRemovalDate!),
    end_date: formatTimestampToYearMonthDay(harvestDate!),
  };

  if (rootstock?.id) {
    data.rootstock_id = rootstock?.id!;
  }
  //TODO - do not sent sowing date anymore after plan POST accepts sowing date only outside custom_settings
  if (confirmedCustomSettings && Object.keys(confirmedCustomSettings).length) {
    data.custom_settings = {
      ...getCustomPlanValues(source),
      sowing_date: formatTimestampToYearMonthDay(sowingDate!),
    };
  }

  return data;
}
