import { createSlice } from '@reduxjs/toolkit';
import { KeyValue } from 'interfaces/keyvalue';
import { RootState } from 'redux/store';

type trackDataToggleSwitches = KeyValue<boolean>;

const initialState: trackDataToggleSwitches = {
  actuals: true,
  plan: true,
  guardRails: true,
  adaptivePlan: true,
};

export const trackDataToggleSwitchesSlice = createSlice({
  name: 'trackDataToggles',
  initialState,
  reducers: {
    setToggle: (state, { payload }) => {
      return (state = {
        ...state,
        ...payload,
      });
    },
  },
});
export const trackDataTogglesSelector = (state: RootState) => state.trackDataToggles;

export const { setToggle } = trackDataToggleSwitchesSlice.actions;

export default trackDataToggleSwitchesSlice.reducer;
