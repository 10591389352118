import dayjs from "dayjs";
import { CropSeason } from "interfaces/crop-season";

export const getCropSeasonsTimeRange = (cropSeasons: CropSeason[]) => {
    let min = Infinity, max = 0;
    cropSeasons.forEach((cropSeason) => {

        let startDate = dayjs(cropSeason.transplant_date).utc(true).unix();
        let endDate = dayjs(cropSeason.end_date).utc(true).unix();
        if (startDate < min) {
            min = startDate;
        }
        if (endDate > max) {
            max = endDate;
        }
    });
    return {
        start: dayjs.unix(min),
        end: dayjs.unix(max) || dayjs(min).add(1, 'year')
    }
}
