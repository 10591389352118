import dayjs, { Dayjs, UnitType } from 'dayjs';

import { RESOLUTION, UTCOffset } from 'config/constants';
import { Range } from 'interfaces/range';
import { Resolution } from 'interfaces/resolution';

export const getYearMonthAndDay = () => {
  const today: Date | string = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  return year + '.' + month + '.' + day;
};

export const getFirstWeekOfGivenDate = (
  date: Dayjs
): [firstDay: Dayjs, lastDay: Dayjs, week: number] => {
  let week: number;
  let firstDay: Dayjs;

  if (date.get('day') === 0) {
    firstDay = date.startOf('week').subtract(6, 'day').add(UTCOffset, 'minutes');
    week = date.get('week' as UnitType) - 1;
    if (week === 0) {
      week = 53;
    }
  } else {
    firstDay = date.startOf('week').add(1, 'day').add(UTCOffset, 'minutes');
    week = date.get('week' as UnitType);
  }

  const lastDay = firstDay.add(6, 'day');

  return [firstDay, lastDay, week];
};

export const getStartOfDay = (date: number | string): number => {
  if (typeof date === 'number') {
    return dayjs.unix(date).startOf('day').unix();
  } else {
    return dayjs(date).startOf('day').unix();
  }
};

export const getEndOfDay = (date: number | string): number => {
  if (typeof date === 'number') {
    return dayjs.unix(date).endOf('day').unix();
  } else {
    return dayjs(date).endOf('day').unix();
  }
};

export function getStartOfDayFormated(input: string) {
  return dayjs(input).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
}

export function getEndOfDayFormated(input: string) {
  return dayjs(input).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
}

export function getDayFormated(input: number) {
  return dayjs.unix(input).format('YYYY-MM-DDTHH:mm:ss');
}

export const getUnix = (date: string): number => {
  return dayjs(date).utc(true).unix();
};

export const getUTC = (date: number): number => {
  return dayjs.unix(date).utc(true).unix();
};

export const getUtcIso = (date: number): string => {
  return dayjs.unix(date).utc(true).toISOString();
};

export const isInRange = (timestamp: number, range: Range): boolean => {
  return timestamp >= range.min && timestamp <= range.max;
};

export const isToday = (timestamp: number): boolean => {
  return dayjs.unix(timestamp).utc(true).isToday();
};

export const isYesterday = (timestamp: number): boolean => {
  return dayjs.unix(timestamp).add(+1, 'day').utc(true).isToday();
};

export const isPast7Days = (timestamp: number): boolean => {
  let today = dayjs().startOf('day').utc(true).unix();
  let last7Days = dayjs().subtract(8, 'day').startOf('day').utc(true).unix();
  return timestamp >= last7Days && timestamp <= today;
};

export const isPast30Days = (timestamp: number): boolean => {
  let today = dayjs().startOf('day').utc(true).unix();
  let last30Days = dayjs().startOf('day').subtract(31, 'day').utc(true).unix();
  return timestamp >= last30Days && timestamp <= today;
};

export const intervalIsLast24H = (from: number, to: number): boolean => {
  let today = dayjs().startOf('day').utc(true).unix();
  let last24H = dayjs().subtract(1, 'day').startOf('day').utc(true).unix();

  return (
    dayjs.unix(to).startOf('day').utc(true).unix() === today &&
    last24H === dayjs.unix(from).startOf('day').utc(true).unix()
  );
};

export const intervalIsLast7Days = (from: number, to: number): boolean => {
  let today = dayjs().startOf('day').utc(true).unix();
  let last7Days = dayjs().subtract(8, 'day').startOf('day').utc(true).unix();
  return (
    dayjs.unix(to).startOf('day').utc(true).unix() === today &&
    last7Days === dayjs.unix(from).startOf('day').utc(true).unix()
  );
};

export const intervalIsLast30Days = (from: number, to: number): boolean => {
  let today = dayjs().startOf('day').utc(true).unix();
  let last30Days = dayjs().subtract(31, 'day').startOf('day').utc(true).unix();
  return (
    dayjs.unix(to).startOf('day').utc(true).unix() === today &&
    last30Days === dayjs.unix(from).startOf('day').utc(true).unix()
  );
};

export const createIntervalFromResolution = (
  timestamp: number,
  resolution: string,
  extendNumber: number
): Range => {
  let min = dayjs(timestamp),
    max = dayjs(timestamp);
  let time = dayjs.unix(timestamp);

  switch (resolution !== null && resolution !== undefined) {
    case resolution === RESOLUTION._5_min:
      min = time.subtract(5 * extendNumber, 'minutes');
      max = time.add(5 * extendNumber, 'minutes');
      break;
    case resolution === RESOLUTION._1_hour:
      min = time.subtract(1 * extendNumber, 'hour');
      max = time.add(1 * extendNumber, 'hour');
      break;
    case resolution === RESOLUTION._1_day:
      min = time.subtract(1 * extendNumber, 'day');
      max = time.add(1 * extendNumber, 'day');
      break;
    case resolution === RESOLUTION._1_week:
      min = time.subtract(1 * extendNumber, 'week');
      max = time.add(1 * extendNumber, 'week');
      break;
    default: {
      min = time.subtract(1 * extendNumber, 'day');
      max = time.add(1 * extendNumber, 'day');
    }
  }

  return {
    min: min.unix(),
    max: max.unix(),
  };
};

export const addResolutionToTimestamp = ({
  input,
  resolution,
}: {
  input: string;
  resolution: Resolution;
}) => {
  switch (resolution) {
    case Resolution.MIN_5: {
      return dayjs(input).add(5, 'minutes').format();
    }
    case Resolution.HOUR_1: {
      return dayjs(input).add(1, 'hour').format();
    }
    case Resolution.DAY_1:
    default:
      return dayjs(input).add(1, 'day').format();
    case Resolution.WEEK_1:
      return dayjs(input).add(1, 'week').format();
  }
};
export const subtractResolutionFromTimestamp = ({
  input,
  resolution,
}: {
  input: string;
  resolution: Resolution;
}) => {
  switch (resolution) {
    case Resolution.MIN_5: {
      return dayjs(input).subtract(5, 'minutes').format();
    }
    case Resolution.HOUR_1: {
      return dayjs(input).subtract(1, 'hour').format();
    }
    case Resolution.DAY_1:
    default:
      return dayjs(input).subtract(1, 'day').format();
    case Resolution.WEEK_1:
      return dayjs(input).subtract(1, 'week').format();
  }
};

// Function to get an array of weeks between two dates
export const getWeeksBetweenDates = (startDate: number, endDate: number) => {
  const weeks = [];
  let currentWeek = dayjs.unix(startDate);

  while (currentWeek.isBefore(dayjs.unix(endDate))) {
    weeks.push({
      start: currentWeek.format('YYYY-MM-DD'),
      end: currentWeek.add(6, 'days').format('YYYY-MM-DD'),
      weekNumber: currentWeek.week(),
    });

    currentWeek = currentWeek.add(1, 'week');
  }

  return weeks;
};

export const getLastThirtyDaysRange = () => {
  const today = dayjs();
  const thirtyDaysAgo = today.subtract(30, 'day');
  return {
    start: thirtyDaysAgo.unix(),
    end: today.unix(),
  };
};
