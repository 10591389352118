import { formatQueryString } from 'utils/queryString';

import { MetaData } from 'interfaces/meta-data';
import { dataSorter } from 'utils/data-sorter';
import { baseApi } from 'redux/slices/baseApi.slice';

interface Location {
  id: number;
  name: string;
  account_id: number;
}

const metaDataApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVarieties: builder.query<MetaData[], { limit: number; segment?: string }>({
      query: (data: { limit: number; segment?: string }) => {
        const ENDPOINT = `metadata/varieties?`;
        return (
          ENDPOINT +
          formatQueryString({
            skip: 0,
            limit: data.limit,
            segment: data.segment,
          })
        );
      },
      transformResponse: (rawResult: {
        message: string;
        result: { varieties: MetaData[] };
      }): MetaData[] => {
        let sortedResponse = dataSorter(rawResult.result.varieties, 'asc', 'name');
        return sortedResponse.filter((variety: MetaData) => variety.supported_by_plan);
      },
    }),
    getVarietiesLinkedToCropseason: builder.query<
      string[],
      { limit: number; accountId: number; segment?: string; locations?: string[] }
    >({
      query: (data: {
        limit: number;
        accountId: number;
        segment?: string;
        locations?: string[];
      }) => {
        const ENDPOINT = `metadata/varieties/linked-to-cropseason?`;
        return (
          ENDPOINT +
          formatQueryString({
            skip: 0,
            limit: data.limit,
            current_account_id: data.accountId,
            segment_id: data.segment,
            location_id: data.locations,
          })
        );
      },
      transformResponse: (rawResult: {
        message: string;
        result: { varieties: { variety: string }[] };
      }): string[] => {
        let sortedResult = dataSorter(rawResult.result.varieties, 'asc', 'name');
        return sortedResult.map((variety: { name: string }) => variety.name);
      },
    }),
    getRootStocks: builder.query<MetaData[], void>({
      query: () => 'metadata/rootstocks',
      transformResponse: (rawResult: { result: { rootstocks: MetaData[] } }): MetaData[] => {
        return dataSorter(rawResult.result.rootstocks, 'asc', 'name');
      },
    }),
    getLocations: builder.query<MetaData[], number>({
      query: (accountId) => {
        const ENDPOINT = `metadata/locations?`;
        return (
          ENDPOINT +
          formatQueryString({
            account_id: accountId,
          })
        );
      },
      transformResponse: (rawResult: { result: { locations: MetaData[] } }): MetaData[] => {
        return dataSorter(rawResult.result.locations, 'asc', 'name');
      },
    }),
    getLocationsLinkedToCropseason: builder.query<Location[], number>({
      query: (accountId) => {
        const ENDPOINT = `metadata/locations/linked-to-cropseason?`;
        return (
          ENDPOINT +
          formatQueryString({
            current_account_id: accountId,
          })
        );
      },
      transformResponse: (rawResult: { result: { locations: Location[] } }): Location[] => {
        return dataSorter(rawResult.result.locations, 'asc', 'name');
      },
    }),
    getYearsLinkedToCropseason: builder.query<
      string[],
      { accountId: number; locations?: string[]; varieties?: string[] }
    >({
      query: (data: { accountId: number; locations?: string[]; varieties?: string[] }) => {
        const ENDPOINT = `metadata/seasons/years/linked-to-cropseason?`;
        return (
          ENDPOINT +
          formatQueryString({
            current_account_id: data.accountId,
            location_id: data.locations,
            variety_id: data.varieties,
          })
        );
      },
      transformResponse: (rawResult: { result: { years: string[] } }): string[] => {
        return rawResult.result.years;
      },
    }),
  }),
});

export const {
  useGetVarietiesQuery,
  useGetRootStocksQuery,
  useGetLocationsQuery,
  useGetLocationsLinkedToCropseasonQuery,
  useGetVarietiesLinkedToCropseasonQuery,
  useGetYearsLinkedToCropseasonQuery,
} = metaDataApi;
