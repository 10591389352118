import { QUERY_LIMIT } from 'config/constants';
import { KeyValue } from 'interfaces/keyvalue';
import { Resolution } from 'interfaces/resolution';
import { UidName } from 'interfaces/uid-name';
import { baseApi } from 'redux/slices/baseApi.slice';
import { getRanges, mergeRanges } from 'utils/getIntervalsForGraphs';
import { formatQueryString } from 'utils/queryString';
import {
  addResolutionToTimestamp,
  getEndOfDayFormated,
  getStartOfDayFormated,
  subtractResolutionFromTimestamp,
} from 'utils/time.util';
import { getKeyValuesFromVariables, getResolutionFromMissingVariables } from 'utils/variables.util';

interface RequestParams {
  groupIds?: string[];
  accountId: number;
  resolution: string;
  startDate: string;
  endDate: string;
  variables: KeyValue<string>;
  limit?: number;
  refetchQueryStartTime?: string | null;
}

const query = ({
  groupIds,
  accountId,
  resolution,
  startDate,
  endDate,
  variables,
  limit = QUERY_LIMIT,
  refetchQueryStartTime = null,
}: RequestParams) => {
  const ENDPOINT = `benchmark-groups/timeseries?`;
  return (
    ENDPOINT +
    formatQueryString({
      id: groupIds,
      resolution,
      limit,
      min_local_datetime:
        refetchQueryStartTime ||
        getStartOfDayFormated(
          subtractResolutionFromTimestamp({
            input: startDate,
            resolution: resolution as Resolution,
          })
        ),
      max_local_datetime: getEndOfDayFormated(
        addResolutionToTimestamp({ input: endDate, resolution: resolution as Resolution })
      ),
      variable: Object.values(variables),
      current_account_id: accountId,
    })
  );
};

const transformResponse = (rawResult: any, variables: KeyValue<string>) => {
  const result = rawResult?.result.group_timeseries;

  if (!result) {
    return {};
  }

  Object.keys(result).forEach((groupId) => {
    result[groupId].ranges = {};
    if (result[groupId].cropseason_timeseries) {
      Object.keys(result[groupId].cropseason_timeseries).forEach((key) => {
        result[groupId].ranges[key] = getRanges(
          result[groupId].cropseason_timeseries[key],
          variables
        );
      });
    } else {
      result[groupId].ranges = getRanges(result[groupId].timeseries, variables);
    }
  });

  return result;
};

function mergeResults(result: any, response: any, variables: KeyValue<string>): any {
  let mergedResult = {} as any;
  let groupIds = Object.keys(response);

  groupIds.forEach((groupId: string) => {
    mergedResult[groupId] = {
      timeseries: {},
      cropseason_timeseries: {},
      ranges: {},
    };
    if (result[groupId].timeseries && Object.keys(result[groupId].timeseries).length !== 0) {
      Object.keys(variables).forEach((variable) => {
        if (result[groupId].timeseries[variable]) {
          mergedResult[groupId].timeseries[variable] = {
            min: [
              ...result[groupId].timeseries[variable]?.min,
              ...response[groupId].timeseries[variable]?.min,
            ],
            max: [
              ...result[groupId].timeseries[variable]?.max,
              ...response[groupId].timeseries[variable]?.max,
            ],
          };
        } else if (response[groupId].timeseries[variable]) {
          mergedResult[groupId].timeseries[variable] = {
            min: [...response[groupId].timeseries[variable]?.min],
            max: [...response[groupId].timeseries[variable]?.max],
          };
        } else {
          mergedResult[groupId].timeseries[variable] = {
            min: [],
            max: [],
          };
        }
      });
      mergedResult[groupId].timeseries.local_datetime = [
        ...result[groupId].timeseries.local_datetime,
        ...response[groupId].timeseries.local_datetime,
      ];
      mergedResult[groupId].ranges = mergeRanges(result[groupId].ranges, response[groupId].ranges);
    } else if (
      result[groupId].cropseason_timeseries &&
      Object.keys(result[groupId].cropseason_timeseries).length !== 0
    ) {
      let cropSeasonIds = Object.keys(response[groupId].cropseason_timeseries);

      cropSeasonIds.forEach((cropSeasonId: string) => {
        Object.keys(variables).forEach((variable) => {
          mergedResult[groupId].cropseason_timeseries[cropSeasonId] =
            mergedResult[groupId].cropseason_timeseries[cropSeasonId] || {};
          mergedResult[groupId].ranges[cropSeasonId] =
            mergedResult[groupId].ranges[cropSeasonId] || {};
          if (result[groupId].cropseason_timeseries[cropSeasonId][variable]) {
            mergedResult[groupId].cropseason_timeseries[cropSeasonId][variable] = result[
              groupId
            ].cropseason_timeseries[cropSeasonId][variable].concat(
              response[groupId].cropseason_timeseries[cropSeasonId][variable]
            );
          } else {
            mergedResult[groupId].cropseason_timeseries[cropSeasonId][variable] =
              response[groupId].cropseason_timeseries[cropSeasonId][variable];
          }
          mergedResult[groupId].cropseason_timeseries[cropSeasonId].local_datetime = [
            ...result[groupId].cropseason_timeseries[cropSeasonId].local_datetime,
            ...response[groupId].cropseason_timeseries[cropSeasonId].local_datetime,
          ];
          mergedResult[groupId].ranges[cropSeasonId][variable] =
            response[groupId].ranges[cropSeasonId][variable];
        });
      });
    }
  });
  return mergedResult;
}

const benchMarkApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBenchMarkSuggestions: builder.query<UidName[], { cropseasonId: number; accountId: number }>({
      query: ({ cropseasonId, accountId }) =>
        `benchmark-groups/suggestions?cropseason_id=${cropseasonId}&current_account_id=${accountId}`,
      transformResponse: (rawResult: any) => {
        return rawResult.result.suggestions;
      },
    }),

    getBenchMarkDetails: builder.query<any, { groupIds: string[]; accountId: number }>({
      query: ({ groupIds, accountId }) =>
        `benchmark-groups?${groupIds
          .map((id) => `id=${id}&`)
          .join('')}&current_account_id=${accountId}`,
      transformResponse: (rawResult: any) => {
        return rawResult.result.groups;
      },
    }),

    getBenchMarkTimeseries: builder.query<any, RequestParams>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        let startDateTime = null;

        let result: any = {
          refetch: false,
        };
        do {
          const partialResponse: any = await fetchWithBaseQuery(
            query({ ..._arg, startDate: _arg.startDate, refetchQueryStartTime: startDateTime })
          );

          let response = transformResponse(partialResponse.data, _arg.variables);

          startDateTime = partialResponse.data?.datetime_pagination.next_min_local_datetime;

          let missingVariables: string[] = [];

          Object.values(response).forEach((group: any) => {
            if (group) {
              missingVariables = missingVariables.concat(group.variables_not_found);
            }
          });

          const missingResolutionsMap = getResolutionFromMissingVariables(
            _arg.variables,
            missingVariables
          );

          for (let entry of Object.entries(missingResolutionsMap)) {
            const resolutionResponse = await fetchWithBaseQuery(
              query({
                ..._arg,
                resolution: entry[0],
                variables: getKeyValuesFromVariables(_arg.variables, entry[1]),
              })
            );

            const transformedResolutionResponse = transformResponse(
              resolutionResponse.data,
              _arg.variables
            );

            Object.assign(response, { refetches: transformedResolutionResponse });
          }

          if (result.refetch) {
            result = mergeResults(result, response, _arg.variables);
          } else {
            result = { ...response };
          }
          result.refetch = startDateTime < _arg.endDate;
          result.start_local_datetime = startDateTime;
        } while (result.refetch);

        return { data: result };
      },
    }),
  }),
});

export const {
  useGetBenchMarkSuggestionsQuery,
  useGetBenchMarkDetailsQuery,
  useGetBenchMarkTimeseriesQuery,
} = benchMarkApi;
