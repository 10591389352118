import React from 'react';

import { ReactComponent as Left } from 'styles/icons/arrow-ios-left.svg';

import { useGetUserLinkedAccountsQuery } from 'services/userAccount.service';
import { checkCurentAccountId } from 'utils/checkCurrentAccountId';
import resetPlanCreationAndAnalyzeState from 'utils/resetPlanCreationAndAnalyzeState';

import { IdName } from 'interfaces/id-name';

import styles from './LinkedAccounts.module.scss';
import { accountIdSelector, setAccountId } from 'redux/slices/accountId.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { resetGroups } from 'redux/slices/trackFilterSlice';
import { setActiveCropseason } from 'redux/slices/cropseason.slice';
import { activeCropseasonInitialValue } from 'config/constants';
import useUrlState from 'hooks/useUrlState';

interface LinkedAccountsProps {
  show: (show: boolean) => void;
}

const LinkedAccounts: React.FC<LinkedAccountsProps> = ({ show }) => {
  const { data: linkedAccounts } = useGetUserLinkedAccountsQuery();
  const accountId = useAppSelector(accountIdSelector).accountId;
  const dispatch = useAppDispatch();

  const [, setUrlState] = useUrlState({
    accountId: '',
  });

  return (
    <div className={styles['linked-accounts']}>
      <div className={styles.header} onClick={() => show(false)}>
        <Left />
        <span>Back</span>
      </div>
      <div className={styles.title}>Switch account</div>
      <div className={styles.content}>
        {linkedAccounts?.map((account: IdName, index: number) => {
          return (
            <div
              className={styles.item}
              onClick={() => {
                resetPlanCreationAndAnalyzeState();
                dispatch(resetGroups());
                dispatch(setAccountId(account.id));
                setUrlState({ accountId: `${account.id}` });
                dispatch(setActiveCropseason(activeCropseasonInitialValue));
                setTimeout(() => {
                  show(false);
                });
              }}
              key={index}
            >
              {account.name}
              {checkCurentAccountId(account.id, accountId)}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default LinkedAccounts;
