import {
  ADAPTIVE_PLAN_GRAH_COLOR,
  GUARD_RAIL_GRAPH_LEGEND_TOOLTIP_COLOR,
  PLAN_GRAP_COLOR,
} from 'config/constants';

import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { getUniqueId } from 'utils/getUniqueId';
import { getPlanToActualsMapper } from 'utils/plan-variables-mapper';
import useFeatureFlags from './useFeatureFlags';
import { ReactComponent as LinkIcon } from 'styles/icons/link-icon.svg';
import { useAppSelector } from 'redux/hooks';
import { sideBarSelector } from 'redux/slices/sidebar.slice';

export default function useGetPlanLegends(
  variables: any,
  guardrail: any,
  planData: any,
  actuals: any,
  adaptivePlan: any,
  variablesIds?: any
) {
  const hasPlan = (): boolean => {
    return Object.keys(variablesIds).some((variable) => {
      return planData?.plan && planData.plan[variable]?.length > 0;
    });
  };

  const hasGuardrail = (): boolean => {
    return hasPlan();
  };

  const hasActuals = (): boolean => {
    return Object.keys(variables).some((variable) => {
      const actualsLength = actuals && actuals[getPlanToActualsMapper(variable as string)]?.length;
      return actualsLength && actualsLength > 0;
    });
  };

  const hasAdaptivePlan = (): boolean => {
    return Object.keys(variables).some((variable) => {
      return adaptivePlan && adaptivePlan[variable as string]?.length > 0;
    });
  };

  const navigate = useNavigate();
  const uniqueId = getUniqueId();
  const { enableAdaptivePlan } = useFeatureFlags();

  const legendItems = [
    {
      color: '#fff',
      label: 'Actuals',
      stateName: 'actuals',
      disabled: !hasActuals(),
    },
    {
      color: GUARD_RAIL_GRAPH_LEGEND_TOOLTIP_COLOR,
      label: 'Guardrails',
      stateName: 'guardRails',
      disabled: !hasGuardrail(),
    },
    {
      color: PLAN_GRAP_COLOR,
      label: 'Plan',
      stateName: 'plan',
      disabled: !hasPlan(),
      customContent: planData?.planDetails ? (
        <>
          <div className='link-icon'>
            <LinkIcon
              width={20}
              className='icon-light c-hand'
              id={`tooltip-anchor-legend-link${uniqueId}`}
              data-tooltip-variant='light'
              onClick={() =>
                navigate(`/plan/edit/${planData.planDetails?.id}`, {
                  state: {
                    id: planData.planDetails?.id,
                  },
                })
              }
            ></LinkIcon>
            <Tooltip
              anchorSelect={`#tooltip-anchor-legend-link${uniqueId}`}
              place='top'
              positionStrategy='fixed'
              opacity={1}
            >
              {planData.planDetails?.name}
            </Tooltip>
          </div>
        </>
      ) : undefined,
    },
  ];

  if (enableAdaptivePlan) {
    legendItems.push({
      color: ADAPTIVE_PLAN_GRAH_COLOR,
      label: 'Adaptive Plan',
      stateName: 'adaptivePlan',
      disabled: !hasAdaptivePlan(),
    });
  }

  return legendItems || [];
}
