import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

interface stepperState {
  planCreationStep: number;
  numberOfStepsPlanCreation: number;
}

export const initialState: stepperState = {
  planCreationStep: 0,
  numberOfStepsPlanCreation: 5,
};

export const stepperSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    incrementStep: (
      state,
      {
        payload,
      }: PayloadAction<{
        stateName: StateKey;
        stateStepsNumber: StateKey;
      }>
    ) => {
      if (state[payload.stateName] === state[payload.stateStepsNumber]) {
        state[payload.stateName] = state[payload.stateStepsNumber];
      } else {
        state[payload.stateName] += 1;
      }
    },
    decrementStep: (state, { payload }: PayloadAction<{ stateName: StateKey }>) => {
      if (state[payload.stateName] === 0) {
        state[payload.stateName] = 0;
      } else {
        state[payload.stateName] -= 1;
      }
    },
    setStep: (state, { payload }: PayloadAction<{ stateName: StateKey; stepNumber: number }>) => {
      state[payload.stateName] = payload.stepNumber;
    },
  },
});

export const { incrementStep, decrementStep, setStep } = stepperSlice.actions;

export const stepperSelector = (input: StateKey) => (state: RootState) => state.stepper[input];

export default stepperSlice.reducer;

export type StateKey = keyof typeof initialState;
