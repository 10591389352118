import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { page } from 'utils/analtycs';

export function usePageViews() {
  const location = useLocation();
  let previousKey = '';
  useEffect(() => {
    if (location?.key === previousKey) {
      return;
    }
    previousKey = location?.key;

    const timeout = setTimeout(() => {
      page(location);
    }, 500);

    return () => clearTimeout(timeout);
  }, [location.pathname, location.search]);
}
