import { translate } from './translations.util';

export const mapPageNameFromURL = (url: string, selectedLanguage: string): string => {
  if (url.includes('plan/edit')) {
    url = url.substring(0, 10);
  }
  if (url.includes('/shop')) {
    return translate('Create Plan - left main menu - Create Plan', selectedLanguage);
  }
  switch (url) {
    case '/dashboard':
      return translate('Landing page title', selectedLanguage);
    case '/climate':
      return translate('Header - Climate', selectedLanguage);
    case '/water-management':
      return translate('Header - Water Management', selectedLanguage);
    case '/fresh-weight-gain':
      return translate('Header - Fresh Weight Gain', selectedLanguage);
    case '/water-efficiency':
      return translate('Header - Water Efficiency', selectedLanguage);
    case '/crop-status':
      return translate('Header - Crop Status', selectedLanguage);
    case '/production':
      return translate('Header - Production', selectedLanguage);
    case '/plan-settings':
      return translate('Create Plan - header', selectedLanguage);
    case '/my-plans':
      return translate('Header - Plan / My Plans', selectedLanguage);
    case '/plan/edit':
      return translate('Header - My Plans / Edit Plan', selectedLanguage);
    case '/plan/preview':
      return translate('Header - Create Plan / Preview Plan', selectedLanguage);
    case '/analyze':
      return translate('Header - Analyze', selectedLanguage);
    case '/benchmark':
      return translate('Header - Benchmark', selectedLanguage);
    case '/profile':
      return translate('Profile page - Profile', selectedLanguage);
    case '/notification':
      return translate('Notifications', selectedLanguage);
    case '/notification-settings':
      return translate('Notify / Notification Settings', selectedLanguage);
    case '/yield-prediction':
      return translate('Yield-Prediction-Page-Title', selectedLanguage);
    default:
      return translate('Landing page title', selectedLanguage);
  }
};
