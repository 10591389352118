import './EditPlansTableContent.scss';

import React, { Fragment } from 'react';
import { mapGraphPlanDataToTableFormat, mapTimeseriesDataToArray } from 'utils/plans.util';
import { roundToDecimals } from 'utils/roundToDecimals';
import { useGetUnitsForEditPlan } from 'hooks/useGetUnitsForEditPlan';
import { useParams } from 'react-router-dom';
import useTranslate from 'hooks/useTranslate';
import ExportTableContent from '../ExportTableContent/ExportTableContent';
import { getVariableName } from 'utils/plan-variables-mapper';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';

interface EditPlansTableContentProps {
  timeseriesData?: any;
  expectedYield: number;
  planName: string;
}

const EditPlansTableContent: React.FC<EditPlansTableContentProps> = ({
  timeseriesData,
  expectedYield,
  planName,
}) => {
  const timeseriesArray = mapTimeseriesDataToArray(timeseriesData);
  const [tableData, tableHeadersArray] = mapGraphPlanDataToTableFormat(timeseriesArray);
  let { id } = useParams();
  const getUnit = useGetUnitsForEditPlan;
  const translate = useTranslate();
  const { selectedLanguage } = useAppSelector(languageSelector);

  return (
    <>
      <div className='plans-table-container'>
        <div className='plans-table-unit-view plan-table-header '>
          <div className='edit-plans-expected-yield-text'>
            <span className='yield-text'>{translate('Plan graph view - expected yield')}</span>
            <span className='yield-text emphasised'>
              {roundToDecimals(expectedYield, 2)} {translate('kg/m^2')}
            </span>
          </div>
          <ExportTableContent timeseriesData={timeseriesData} planName={planName} />
        </div>
        <table cellSpacing={0} className='table table-striped table-hover graph-table'>
          <thead className='graph-table-head'>
            <tr className='graph-table-head-row'>
              <th className='graph-table-week-header'>Week</th>
              <th className='graph-table-header-spacer'></th>
              {tableHeadersArray?.map((item: any, index: number) => (
                <th className='graph-table-week-data' key={index}>
                  <div className='graph-table-week-data-content'>
                    <div className='variable'>{getVariableName(item.id, selectedLanguage)}</div>
                    <div className='unit'>{getUnit(Number(id), item.id)}</div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='graph-table-body'>
            {tableData.map((row: any, index: number) => {
              return (
                <tr className={`${index % 2 === 0 && 'active'} graph-table-body-row`} key={index}>
                  {row.map((rowData: any, index: number) => {
                    return (
                      <Fragment key={'fragment' + index}>
                        {index === 0 && (
                          <>
                            <td className='graph-table-body-data graph-table-body-data-timestamp'>
                              {rowData.dateTimeWeek}
                            </td>
                            <td className='graph-table-body-spacer'></td>
                          </>
                        )}
                        <td className='graph-table-body-data'>{rowData.value ?? '-'}</td>
                      </Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default EditPlansTableContent;
