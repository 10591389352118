import { ReactComponent as NotificationDefaultIcon } from 'styles/icons/notificationTypeIcons/notification_default.svg';
import { ReactComponent as NotificationDrain } from 'styles/icons/notificationTypeIcons/notification_drain.svg';
import { ReactComponent as NotificationFreshWeightGain } from 'styles/icons/notificationTypeIcons/notification_fresh_weight_gain.svg';
import { ReactComponent as NotificationCO2 } from 'styles/icons/notificationTypeIcons/notification_co2.svg';
import { ReactComponent as NotificationHumi } from 'styles/icons/notificationTypeIcons/notification_humi.svg';
import { ReactComponent as NotificationIrrigation } from 'styles/icons/notificationTypeIcons/notification_irrigation.svg';
import { ReactComponent as NotificationHumDef } from 'styles/icons/notificationTypeIcons/notification_humi_def.svg';
import { ReactComponent as NotificationTemp } from 'styles/icons/notificationTypeIcons/notification_temp.svg';
import { ReactComponent as NotificationYield } from 'styles/icons/notificationTypeIcons/notification_yield.svg';
import { ReactComponent as SensorData } from 'styles/icons/notificationTypeIcons/notification-sensor-data.svg';
import { ReactComponent as GrowthRateHr } from 'styles/icons/notificationTypeIcons/notification_growth_rate_hr.svg';
import { ReactComponent as Disorder } from 'styles/icons/notificationTypeIcons/notification_disorder.svg';

import { KeyValue } from 'interfaces/keyvalue';
import { ReactElement } from 'react';

export const NOTIFICATION_ICONS: KeyValue<ReactElement> = {
  default: <NotificationDefaultIcon className='notif_item_icon' width={20} height={20} />,
  temp: <NotificationTemp className='notif_item_icon' width={20} height={20} />,
  hum_def: <NotificationHumDef className='notif_item_icon' width={20} height={20} />,
  irrigation: <NotificationIrrigation className='notif_item_icon' width={20} height={20} />,
  humi: <NotificationHumi className='notif_item_icon' width={20} height={20} />,
  co2: <NotificationCO2 className='notif_item_icon' width={20} height={20} />,
  fresh_weight_gain: (
    <NotificationFreshWeightGain className='notif_item_icon' width={20} height={20} />
  ),
  drain: <NotificationDrain className='notif_item_icon' width={20} height={20} />,
  yield: <NotificationYield className='notif_item_icon' width={20} height={20} />,
  sensor_data: <SensorData className='notif_item_icon' width={20} height={20} />,
  growth_rate_hr: <GrowthRateHr className='notif_item_icon' width={20} height={20} />,
  BER_notification: <Disorder className='notif_item_icon disorder' width={20} height={20} />,
  YS_notification: <Disorder className='notif_item_icon disorder' width={20} height={20} />,
  SS_notification: <Disorder className='notif_item_icon disorder' width={20} height={20} />,
  CF_notification: <Disorder className='notif_item_icon disorder' width={20} height={20} />,
  BR_notification: <Disorder className='notif_item_icon disorder' width={20} height={20} />,
  MC_notification: <Disorder className='notif_item_icon disorder' width={20} height={20} />,
};
