import React from 'react';
import './TwilightLegendItem.scss';

interface TwilightLegendItemProps {
  width: string;
  height: string;
  color: string;
  text: string;
}

const TwilightLegendItem: React.FC<TwilightLegendItemProps> = ({ width, height, color, text }) => {
  return (
    <div className={`lines-pattern-legend-item`}>
      <svg width={width} height={height}>
        <rect width={width} height={height} fill={color} stroke={'#394958'} strokeWidth={2} />
      </svg>
      <span className='lines-pattern-legend-text'>{text}</span>
    </div>
  );
};
export default TwilightLegendItem;
