import type { RootState } from 'redux/store';
import dayjs from 'dayjs';
import { createSlice } from '@reduxjs/toolkit';

interface plansSliceState {
  renameId: string;
  editViewMode: 'TABLE' | 'GRAPH';
  editSidebar: boolean;
  myPlansFilters: MyPlansFilters;
  selectedPlansFolderName: string;
}

interface MyPlansFilters {
  variety: string;
  from: number;
  to: number;
}

const initialState: plansSliceState = {
  renameId: '',
  editViewMode: 'GRAPH',
  editSidebar: false,
  myPlansFilters: {
    variety: 'Not specified',
    from: dayjs().subtract(2, 'year').unix(),
    to: dayjs().unix(),
  },
  selectedPlansFolderName: '',
};

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlansRenameId: (state, { payload }) => {
      state.renameId = payload;
    },
    setEditViewMode: (state, { payload }: { payload: 'TABLE' | 'GRAPH' }) => {
      state.editViewMode = payload;
    },
    toggleEditSidebar: (state) => {
      state.editSidebar = !state.editSidebar;
    },
    setMyPlansFilters: (state, { payload }) => {
      return {
        ...state,
        myPlansFilters: {
          ...state.myPlansFilters,
          ...payload,
        },
      };
    },
    setSelectedPlansFolderName: (state, { payload }: { payload: string }) => {
      state.selectedPlansFolderName = payload;
    },
  },
});

export const {
  setPlansRenameId,
  setEditViewMode,
  toggleEditSidebar,
  setMyPlansFilters,
  setSelectedPlansFolderName,
} = plansSlice.actions;

export const plansSelector = (state: RootState) => state.plans;

export default plansSlice.reducer;
