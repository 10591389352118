import { IdName } from 'interfaces/id-name';

export const getUserInitials = (name: string | undefined, surName: string | undefined) => {
  let userInitials = '';
  if (name) {
    userInitials += name[0].toUpperCase();
  }
  if (surName) {
    userInitials += surName[0].toUpperCase();
  }
  return userInitials;
};

export const getUserFullName = (name: string | undefined, surName: string | undefined) => {
  let fullName = '';

  if (name) {
    fullName += name;
  }
  if (surName) {
    fullName += ` ${surName}`;
  }

  if (!name && !surName) {
    fullName = 'Unknow User';
  }
  return fullName;
};

export const findAccountName = (id: number, list: IdName[]) => {
  const account = list?.find((account: IdName) => account.id === id);

  return account?.name;
};
