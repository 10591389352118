import NOTIFICATION_INFO from 'config/notifications/notifications.json';

export const getNotificationTooltipExtra = (input: string) => {
  if (!input) {
    return '';
  }
  const NOTIFICATIONS = JSON.parse(JSON.stringify(NOTIFICATION_INFO));

  const key = NOTIFICATIONS[input]?.extra_text;

  if (!key) {
    return null;
  }

  return key;
};
