import './NotificationItemIcon.scss';
import React from 'react';

import { NOTIFICATION_ICONS } from 'config/notifications/notification-icons';
import { NOTIFICATION_VARIATION_ICONS } from 'config/notifications/notification-icons-variations';

export const NotificationIconStyle = {
  styleDark: 'bgDark',
  styleLight: 'bgLight',
  styleBig: 'iconBig',
  styleTooltip: 'iconTooltip',
};

interface NotificationItemIconProps {
  type: string;
  showEmpasised: boolean;
  iconStyle: string[];
}

const NotificationItemIcon: React.FC<NotificationItemIconProps> = ({
  type,
  showEmpasised,
  iconStyle,
}) => {
  if (!type) return NOTIFICATION_ICONS['default'];

  let iconTypes = Object.keys(NOTIFICATION_ICONS);
  let iconVariationTypes = Object.keys(NOTIFICATION_VARIATION_ICONS);

  let iconKey = iconTypes.filter((iconType) => {
    return type.includes(iconType);
  })[0];

  let iconVariation = iconVariationTypes.filter((iconVariation) => {
    return type.includes(iconVariation);
  })[0];

  const styleClasses = iconStyle.map((style) => style).join(' ');

  return (
    <div className={`notification-icon ${styleClasses}`}>
      {showEmpasised && <div className='read-circle'></div>}
      {NOTIFICATION_ICONS[iconKey] || NOTIFICATION_ICONS['default']}
      <div className='notification-icon-type-variation'>
        {NOTIFICATION_VARIATION_ICONS[iconVariation]}
      </div>
    </div>
  );
};
export default NotificationItemIcon;
