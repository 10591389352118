import dayjs from 'dayjs';
import { Resolution } from 'interfaces/resolution';

export const xTickFormater = (resolution: string, customFormatXTick?: (input: any) => string) => (input: number) => {
  if (!input) {
    return '';
  }
  if (customFormatXTick) {
    return customFormatXTick(input);
  }
  switch (resolution) {
    case Resolution.DAY_1:
    default: {
      return dayjs.unix(input).utc(false).format('ddd  D MMM');
    }
    case Resolution.WEEK_1: {
      return `Week  ${dayjs.unix(input).utc(false).week()}`;
    }
    case Resolution.HOUR_1: {
      return ` ${dayjs.unix(input).utc(false).format('HH:00')}\n${dayjs
        .unix(input)
        .utc(false)
        .format('ddd D MMM')}`;
    }
    case Resolution.MIN_5: {
      return ` ${dayjs.unix(input).utc(false).format('HH:mm')}\n${dayjs
        .unix(input)
        .utc(false)
        .format('ddd D MMM')}`;
    }
    case 'week':
      return `${input.toFixed(0)}`;
  }
};

export const roundTickPeriod = (periodInSeconds: number) => {
  if (periodInSeconds < dayjs.duration(8, 'hours').asSeconds()) {
    return dayjs.duration(6, 'hours').asSeconds();
  } else if (periodInSeconds < dayjs.duration(14, 'hours').asSeconds()) {
    return dayjs.duration(12, 'hours').asSeconds();
  } else if (periodInSeconds < dayjs.duration(30, 'hours').asSeconds()) {
    return dayjs.duration(24, 'hours').asSeconds();
  } else if (periodInSeconds < dayjs.duration(50, 'hours').asSeconds()) {
    return dayjs.duration(48, 'hours').asSeconds();
  } else if (periodInSeconds < dayjs.duration(4, 'days').asSeconds()) {
    return (
      Math.round(periodInSeconds / dayjs.duration(1, 'days').asSeconds()) *
      dayjs.duration(1, 'days').asSeconds()
    );
  } else {
    return (
      Math.round(periodInSeconds / dayjs.duration(7, 'days').asSeconds()) *
      dayjs.duration(7, 'days').asSeconds()
    );
  }
};

export const analyzeTimestampFormatter = (resolution: string, isMobile?: boolean, onlyHours?: boolean) => (input: number) => {
  if (!input) {
    return '';
  }

  const defaultFormat = 'ddd D MMM';
  const mobileFormat = 'D MMM';

  let dateFormat = isMobile ? mobileFormat : defaultFormat;

  switch (resolution) {
    case Resolution.DAY_1:
    default: {
      return dayjs.unix(input).utc(false).format(dateFormat);
    }
    case Resolution.WEEK_1: {
      return `Week  ${dayjs.unix(input).utc(false).format(dateFormat)}`;
    }
    case Resolution.HOUR_1: {
      let timestamp = dayjs.unix(input).utc(false).format('HH:00');
      if (!onlyHours) {
        return timestamp + "\n" + dayjs
          .unix(input)
          .utc(false)
          .format(dateFormat);
      }
      return timestamp;
    }
    case Resolution.MIN_5: {
      return ` ${dayjs.unix(input).utc(false).format('HH:mm')}\n${dayjs
        .unix(input)
        .utc(false)
        .format(dateFormat)}`;
    }
    case 'week':
      return `${input.toFixed(0)}`;
  }
};
