import { Range } from 'interfaces/range';

function expandRange(range: Range, exp_factor = 0.05): Range {
  let expansion: number = (range.max - range.min) * exp_factor;
  if (expansion === 0) {
    expansion = range.max * exp_factor;
  }
  return { min: range.min - expansion, max: range.max + expansion };
}


function centerRange(range: Range, center: number): Range {
  const distanceFromMin = center - range.min;
  const distancefromMax = range.max - center;
  const distance = Math.max(distanceFromMin, distancefromMax);
  return { min: center - distance, max: center + distance };
}


function getQuadrantRange(
  valuesRange: Range,
  boundsRange: Range,
  exp_factor = 0.075,
  exp_factor_bounds = 0.1
): Range {
  const expandedValuesRange = expandRange(valuesRange, exp_factor);
  const expandedBoundsRange = expandRange(boundsRange, exp_factor_bounds);
  const completeRange = {
    min: Math.min(expandedBoundsRange.min, expandedValuesRange.min),
    max: Math.max(expandedBoundsRange.max, expandedValuesRange.max),
  };
  const finalRange = centerRange(completeRange, (boundsRange.max + boundsRange.min) / 2);

  return finalRange;
}

export { getQuadrantRange };
