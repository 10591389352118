import './Dashboard.scss';

import Card from 'components/Card/Card';
import DropDown from 'components/DropDown/DropDown';
import Gauge from 'components/Gauge/Gauge';
import Bar from 'components/GraphElements/Bar/Bar';
import MultilineChart from 'components/GraphElements/MultilineChart/MultilineChart';
import Quadrant from 'components/Quadrant/Quadrant';
import {
  DASHBOARD_TRACKINGS_INITIAL_DATA,
  RESOLUTION,
  VARIABLES_CROP_STATUS,
  activeCropseasonInitialValue,
} from 'config/constants';
import { CropStatus, DashboardData } from 'interfaces/trackings';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { cropseasonSelector, setActiveCropseason } from 'redux/slices/cropseason.slice';
import { languageSelector } from 'redux/slices/language.slice';
import { sideBarSelector } from 'redux/slices/sidebar.slice';
import { useGetCropseasonsQuery } from 'services/cropseason.service';
import { useGetTrackingsQuery } from 'services/dashboard.service';
import { ReactComponent as CheckMark } from 'styles/icons/checkmark.svg';
import { track } from 'utils/analtycs';
import { getStringScale } from 'utils/d3-utils/getStringFormatScale';
import { weekFromTimestamp } from 'utils/d3-utils/tooltip-formatter';
import { getActiveCropseasonIntitialValue } from 'utils/getInitialValues';
import { mapPageNameFromURL } from 'utils/navigation.util';
import { objectFallbackData } from 'utils/objectFallbackData.util';

import DashboardAreaGraph from './DashboardAreaGraph/DashboardAreaGraph';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import useUrlState from 'hooks/useUrlState';
import useDefaultCropSeason from 'hooks/useDefaultCropSeason';
import { useGetActualsQuery } from 'services/actuals.service';
import dayjs from 'dayjs';
import { useGetDataForPlan } from 'hooks/useGetDataForPlan';
import { getGuardrailsDataBasedOnPlan } from 'utils/getGuardrailsBasedOnPlan';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { getQuadrantRange } from 'utils/quadrant.utils';
import { Point } from 'recharts/types/shape/Curve';
import { reduceNumberToGivenInterval } from 'utils/reduceNumberToGivenInterval';

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>('');
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { data: cropSeasons } = useGetCropseasonsQuery(accountId, { skip: !accountId });
  const [filteredCropseasons, setFilteredCropseasons] = useState(cropSeasons);
  const { enabledGuardrailsBasedOnXPercent } = useFeatureFlags();
  const dispatch = useAppDispatch();
  const activeCropseason = useAppSelector(cropseasonSelector);
  const isSidebarOpen = useAppSelector(sideBarSelector).isOpen;
  const { selectedLanguage } = useAppSelector(languageSelector);
  const [scatterPlotData, setScatterPlotData] = useState<Point[]>();

  const startDate = dayjs().subtract(6, 'week').startOf('day').toISOString();
  const endDate = dayjs().startOf('day').toISOString();

  const { data: cropStatusData } = useGetActualsQuery(
    {
      cropSeasonId: activeCropseason.id,
      startDate: startDate,
      endDate: endDate,
      resolution: RESOLUTION._1_week,
      variables: VARIABLES_CROP_STATUS,
      accountId: accountId,
    },
    {
      skip:
        !activeCropseason.id ||
        activeCropseason.id === activeCropseasonInitialValue.id ||
        !accountId,
    }
  );

  const planData = useGetDataForPlan(VARIABLES_CROP_STATUS, RESOLUTION._1_week, startDate, endDate);
  let guardrailData = useMemo(
    () =>
      getGuardrailsDataBasedOnPlan(
        planData.plan,
        VARIABLES_CROP_STATUS,
        enabledGuardrailsBasedOnXPercent
      ),
    [planData.plan, VARIABLES_CROP_STATUS]
  );

  useEffect(() => {
    if (
      cropStatusData?.plantLoad &&
      cropStatusData?.plantLoad.length &&
      guardrailData &&
      guardrailData.plantLoad &&
      guardrailData.plantLoad.length > 0 &&
      guardrailData.stemDiameter &&
      guardrailData.stemDiameter.length > 0
    ) {
      const plantLoadRange = getQuadrantRange(
        cropStatusData?.ranges?.plantLoad,
        guardrailData?.ranges?.plantLoad
      );
      const stemDiameterRange = getQuadrantRange(
        cropStatusData?.ranges?.stemDiameter,
        guardrailData?.ranges?.stemDiameter
      );

      let result: Point[] = [];
      for (let i = 0; i < cropStatusData.plantLoad.length; i++) {
        const plantLoadValue = cropStatusData.plantLoad[i]?.plantLoadActual;
        const stemDiameterValue = cropStatusData.stemDiameter[i]?.stemDiameterActual;

        if (
          plantLoadValue &&
          stemDiameterValue &&
          plantLoadRange.min &&
          plantLoadRange.max &&
          stemDiameterRange.min &&
          stemDiameterRange.max
        ) {
          result.push({
            x: reduceNumberToGivenInterval(
              plantLoadValue,
              plantLoadRange.min,
              plantLoadRange.max,
              [-1, 1]
            ),
            y: reduceNumberToGivenInterval(
              stemDiameterValue,
              stemDiameterRange.min,
              stemDiameterRange.max,
              [-1, 1]
            ),
          });
        }
      }

      setScatterPlotData(result);
    }
  }, [cropStatusData, guardrailData]);

  const [, setUrlState] = useUrlState({
    cropseasonId: String(activeCropseason.id),
  });

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  useEffect(() => {
    setFilteredCropseasons(cropSeasons);
  }, [cropSeasons]);

  const { handleDefaultCropseason, handleRemoveDefaultCropseason, isValueDefault } =
    useDefaultCropSeason();

  const { data: trackingsData } = useGetTrackingsQuery(
    { cropSeasonId: activeCropseason.id, accountId },
    {
      skip: !accountId || activeCropseason.id === getActiveCropseasonIntitialValue().id,
    }
  );

  const {
    climate,
    water_efficiency: waterEfficiency,
    production,
    fresh_weight_gain: freshWeightGain,
    water_management: waterManagement,
    crop_status: cropStatus,
  } = objectFallbackData<DashboardData>(DASHBOARD_TRACKINGS_INITIAL_DATA, trackingsData);

  const dropdownOnChange = (_: any, option: any) => {
    track('change cropSeason in dashboard', {
      ...option,
    });
    setUrlState({ cropseasonId: String(option.id) });
    dispatch(setActiveCropseason(option));
  };

  const getCropStatusValue = (cropStatus: CropStatus) => {
    if (!cropStatus?.value) {
      return '';
    }
    return `${cropStatus.value.strength || ''} ${cropStatus.value.generative || ''}`;
  };

  const filterCropseasons = (keyword: string) => {
    if (!cropSeasons) {
      return;
    }

    const filteredList = cropSeasons.filter((cropseason) =>
      cropseason.name.toLowerCase().includes(keyword.toLowerCase())
    );

    setFilteredCropseasons(filteredList);
  };

  return (
    <div className={`dashboard-container ${!isSidebarOpen && 'sidebar-closed'}`}>
      <p className='dashboard-page-title'>{mapPageNameFromURL(currentPath, selectedLanguage)}</p>
      <div className='dashboard-header'>
        <div className='cropseason-dropdown-container'>
          <DropDown
            required={false}
            initialValue={
              activeCropseason.id === getActiveCropseasonIntitialValue().id
                ? getActiveCropseasonIntitialValue()
                : activeCropseason
            }
            name='cropseason'
            optionList={filteredCropseasons || []}
            setOnChange={dropdownOnChange}
            displayProperty='name'
            rightSide
            hasTooltip
            activePreElement={<CheckMark />}
            hasDefaultValue={true}
            setAsDefault={handleDefaultCropseason}
            removeDefault={handleRemoveDefaultCropseason}
            isValueDefault={isValueDefault}
            isSorted={true}
            searchOptions={{ placeholder: 'Search CropSeason', onChange: filterCropseasons }}
          />
        </div>
      </div>

      {!activeCropseason.is_active && (
        <WarningMessage
          title='Info'
          message='No data is available for the selected CropSeason. Please select an
        active CropSeason.'
        />
      )}

      <div className={!activeCropseason.is_active ? 'blurred-content' : ''}>
        <div className='cards-container'>
          <NavLink to='../climate' className='dashboard-link'>
            <Card
              name={climate.name}
              variableName={climate.variable_name}
              variableId={climate.name}
              value={Number(climate.value)}
              status={climate.status}
              unit={climate.unit}
              className='climate'
            >
              <DashboardAreaGraph data={climate} title='climate' />
            </Card>
          </NavLink>
          <NavLink to='../water-management' className='dashboard-link'>
            <Card
              name={waterManagement.name}
              variableName={waterManagement.variable_name}
              variableId={waterManagement.name}
              value={Number(waterManagement.value)}
              status={waterManagement.status}
              unit={waterManagement.unit}
              className='water_management'
              decimals={0}
            >
              <Gauge
                min={waterManagement.gauge.min}
                max={waterManagement.gauge.max}
                value={waterManagement.gauge.value}
                lowerTarget={waterManagement.gauge.lower_target_zone}
                upperTarget={waterManagement.gauge.upper_target_zone}
              />
            </Card>
          </NavLink>
          <NavLink to='../fresh-weight-gain' className='dashboard-link'>
            <Card
              name={freshWeightGain.name}
              variableName={freshWeightGain.variable_name}
              variableId={freshWeightGain.name}
              value={Number(freshWeightGain.value)}
              status={freshWeightGain.status}
              unit={freshWeightGain.unit}
              className='fresh-weight-gain'
              decimals={0}
            >
              <DashboardAreaGraph data={freshWeightGain} title='production' />
            </Card>
          </NavLink>
          <NavLink to='../water-efficiency' className='dashboard-link'>
            <Card
              name={waterEfficiency.name}
              variableName={waterEfficiency.variable_name}
              variableId={waterEfficiency.name}
              value={Number(waterEfficiency.value)}
              status={waterEfficiency.status}
              unit={waterEfficiency.unit}
              className='water-efficiency'
            >
              <DashboardAreaGraph data={waterEfficiency} title='water efficiency' />
            </Card>
          </NavLink>
          <NavLink to='../crop-status' className='dashboard-link'>
            <Card
              name='Crop Status'
              variableName='Crop Status'
              variableId='crop_status'
              textValue={getCropStatusValue(cropStatus)}
              status='ok'
              unit=''
              className='crop-status'
            >
              {scatterPlotData && (
                <div className='large-graph'>
                  <Quadrant points={scatterPlotData}></Quadrant>
                </div>
              )}
            </Card>
          </NavLink>
          <NavLink to='../production' className='dashboard-link'>
            <Card
              name={production.name}
              variableName={production.variable_name}
              variableId={production.name}
              value={Number(production.value)}
              status={production.status}
              unit={production.unit}
              className='prod'
            >
              <MultilineChart
                resolution='week'
                title='production'
                xAxisProperty='s'
                showTitle={false}
                YInterval={[production.timeseries?.yInterval]}
                XInterval={production.timeseries?.xInterval}
                dataToDisplay={[
                  {
                    data: production.timeseries?.barData,
                    color: '#fff',
                    keyName: 'value',
                    unit: production.unit,
                  },
                ]}
                hasTooltip={false}
                showAxis={false}
                applyOffset={false}
                applyPadding={false}
                drawTooltipBorder={true}
                drawTooltipLine={false}
                customFormatter={weekFromTimestamp}
                customScale={getStringScale}
              >
                <Bar
                  data={production.timeseries?.barData}
                  xProperty='timestamp'
                  yProperty='value'
                />
              </MultilineChart>
            </Card>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
