import './Modal.scss';

import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'styles/icons/close.svg';
import { ModalService } from 'services/modal.service';
import { ModalProps } from 'interfaces/modal';

import { ReactComponent as Refresh } from 'styles/icons/refresh.svg';

interface IModalProps {}

const ConfirmationMessage: React.FC<{ touched?: boolean; text: string }> = ({
  touched = false,
  text,
}) => {
  return <div>{text}</div>;
};

const Modal: React.FC<IModalProps> = () => {
  const [modals, setModals] = useState<ModalProps[]>([]);

  ModalService.subscribe('modal', setModals);

  useEffect(() => {
    return () => {
      ModalService.unsubscribe('modal');
    };
  }, []);

  const touchModal = (modalIndex: number) =>
    setModals((prevState) => {
      return prevState.map((_modal, index) => {
        if (index === modalIndex) {
          return { ..._modal, touched: true };
        } else {
          return _modal;
        }
      });
    });

  const handleOverlayClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className='modal-component'>
      {modals.map((modal, modalIndex) => (
        <div key={modalIndex} className={`modal ${modals.length ? 'active' : ''}`} id='modal-id'>
          {modalIndex === modals.length - 1 && (
            <a
              href='#close'
              className='modal-overlay'
              aria-label='Close'
              onClick={handleOverlayClick}
            >
              {''}
            </a>
          )}
          <div className='modal-container' style={{ maxWidth: modal.width }}>
            <div className='modal-header'>
              <span className='float-right' aria-label='Close'>
                <CloseIcon className='close-icon' onClick={() => ModalService.close()} />
              </span>
              <div className='modal-title'>{modal.title}</div>
            </div>
            <div className='modal-body'>
              <div className='content'>
                {React.cloneElement(modal.content, { touched: modal.touched })}
              </div>
            </div>
            <div className='modal-footer'>
              {modal.resetButton && (
                <button className='btn reset-btn' onClick={modal.resetAction}>
                  <Refresh className='icon-white' />
                  Reset All
                </button>
              )}
              {modal.hideCancelButton ? null : (
                <button
                  className='btn'
                  onClick={() => {
                    ModalService.close();
                  }}
                >
                  {modal.cancelText || 'Cancel'}
                </button>
              )}

              <button
                className='btn  btn-primary'
                onClick={() => {
                  const validateResult = modal?.validateFunction ? modal?.validateFunction() : true;
                  touchModal(modalIndex);

                  if (modal.confirmationAction) {
                    ModalService.open({
                      title: modal.confirmActionTitle || '',
                      saveText: 'Confirm',
                      cancelText: 'Cancel',
                      width: '362px',
                      content: <ConfirmationMessage text={modal.confirmActionDescription || ''} />,
                      closeAction: (isConfirmed: boolean) => {
                        if (isConfirmed) {
                          ModalService.close(true);
                        } else {
                          ModalService.close();
                        }
                      },
                    });
                  } else {
                    if (validateResult) {
                      ModalService.close(true);
                    }
                  }
                }}
              >
                {modal.saveText || 'Save'}
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Modal;
