import DropDown from 'components/DropDown/DropDown';
import Pagination from 'components/Pagination/Pagination';
import StrategiesFilter from 'components/StrategiesFilter/StrategiesFilter';
import {
  MAX_LENGTH_PLAN_NAME,
  MIN_LENGTH_PLAN_NAME,
  PAGINATION_DROPDOWN_LIST,
} from 'config/constants';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { planCreationSelector, reset, setOption } from 'redux/slices/planCreationSlice';
import { strategiesActions, strategiesSelectors } from 'redux/slices/planStrategies.slice';
import { setStep } from 'redux/slices/stepperSlice';
import { ModalService } from 'services/modal.service';
import {
  useGetPlanTimeseriesQuery,
  useGetPlanTimeseriesVariablesQuery,
  useUpdatePlanMutation,
} from 'services/plans.service';
import { useGetStrategiesQuery } from 'services/shop.service';
import { ReactComponent as Left } from 'styles/icons/arrow-left.svg';
import { roundToDecimals } from 'utils/roundToDecimals';
import { checkFileName } from 'utils/validation.util';
import { SidebarFileFolderNames } from 'views/EditPlans/EditPlanSidebar/EditPlanSidebar';
import SavePlanAsContent from 'views/EditPlans/EditPlanSidebar/SavePlanAsContent/SavePlanAsContent';

import StrategiesTable from '../StrategiesTable/StrategiesTable';
import styles from './CompareStrategies.module.scss';
import useFilteredData from './hooks/useFilteredData';
import { useSetFilterRanges } from './hooks/useSetFilterRanges';
import { translate } from 'utils/translations.util';
import { languageSelector } from 'redux/slices/language.slice';
import { accountIdSelector } from 'redux/slices/accountId.slice';

interface CompareStrategiesProps {}

const CompareStrategies: React.FC<CompareStrategiesProps> = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const shop = useAppSelector(strategiesSelectors.shop);
  const { currentPage, pageSize, totalCount } = useAppSelector(strategiesSelectors.pagination);
  const { afw, brix, yield: yieldValue } = useAppSelector(strategiesSelectors.filters);
  const [dataToDisplay, setDataToDisplay] = useState<any>();
  const { previewPlanId, openSaveModal } = useAppSelector(planCreationSelector);
  const [updatePlan] = useUpdatePlanMutation();
  const compareStrategies = useAppSelector(strategiesSelectors.compareStrategies);
  const { selectedLanguage } = useAppSelector(languageSelector);
  const accountId = useAppSelector(accountIdSelector).accountId;

  const { data: strategies } = useGetStrategiesQuery(shop?.id!, {
    skip: !shop?.id,
    refetchOnMountOrArgChange: true,
  });
  const filteredData = useFilteredData({
    strategies,
    yieldValue,
    brix,
    afw,
  });
  useSetFilterRanges(strategies);

  const { data: planTimeseriesVariables } = useGetPlanTimeseriesVariablesQuery(
    Number(previewPlanId),
    {
      skip: !previewPlanId,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: timeseriesData } = useGetPlanTimeseriesQuery(
    {
      planId: Number(previewPlanId),
      variables: planTimeseriesVariables?.map((variable) => variable.id),
      accountId,
    },
    { skip: !previewPlanId || !planTimeseriesVariables, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (strategies?.data) {
      dispatch(strategiesActions.setPagination({ totalCount: strategies.data.length }));
      if (!currentPage) {
        setCurrentPage(1);
      }
      if (!pageSize) {
        dispatch(strategiesActions.setPagination({ pageSize: 10 }));
      }
    }
  }, [strategies?.data]);

  useEffect(() => {
    if (currentPage && pageSize && filteredData) {
      const array = [...filteredData];
      const startIndex =
        (currentPage - 1) * pageSize === totalCount ? 0 : (currentPage - 1) * pageSize;
      const endIndex =
        (currentPage - 1) * pageSize >= totalCount ? totalCount : currentPage * pageSize;

      const paginatedData = array.slice(startIndex, endIndex);

      setDataToDisplay(paginatedData);
    }
  }, [currentPage, pageSize, filteredData]);

  const setCurrentPage = (input: number) => {
    dispatch(strategiesActions.setPagination({ currentPage: input }));
  };

  const setDropDownValue = (_: any, option: any) => {
    dispatch(strategiesActions.setPagination({ pageSize: option.value }));
    setCurrentPage(1);
  };

  const openPlanPreview = () => {
    navigate(`../plan/preview/${previewPlanId}`, {
      state: {
        id: previewPlanId,
      },
    });
  };

  const handleOpenSave = () => {
    dispatch(setOption({ openSaveModal: true }));
  };

  const navigateBack = () => {
    navigate('/plan-settings');
  };

  const navigateToComparisonGraph = () => {
    navigate('/plan-compare-graph');
  };
  const getDropDownValue = () => {
    if (!pageSize) {
      return PAGINATION_DROPDOWN_LIST[0];
    } else {
      return PAGINATION_DROPDOWN_LIST.find((option) => option.value === String(pageSize));
    }
  };

  useEffect(() => {
    if (openSaveModal) {
      let names: SidebarFileFolderNames = { fileNameField: '', folderNameField: '' };
      const validateFunction = () => {
        return !!(
          !checkFileName(names.fileNameField, MIN_LENGTH_PLAN_NAME, MAX_LENGTH_PLAN_NAME) &&
          names.folderNameField
        );
      };
      ModalService.open({
        title: translate('Save Plan pop up window', selectedLanguage),
        saveText: translate('Save Plan window: Save', selectedLanguage),
        cancelText: translate('Save Plan window: Cancel', selectedLanguage),
        width: '495px',
        validateFunction: validateFunction,
        content: <SavePlanAsContent names={names} />,
        closeAction: async (isConfirmed) => {
          if (isConfirmed) {
            updatePlan({
              id: previewPlanId!,
              data: {
                name: names.fileNameField,
                path: '/' + names.folderNameField,
              },
            }).then((response) => {
              dispatch(setOption({ openSaveModal: false }));
              dispatch(reset());
              dispatch(strategiesActions.reset());
              dispatch(setStep({ stateName: 'planCreationStep', stepNumber: 0 }));
              navigate('/my-plans');
            });
            names = { fileNameField: '', folderNameField: '' };
            dispatch(setOption({ fileName: '', folderName: '' }));
          } else {
            dispatch(setOption({ openSaveModal: false }));
            ModalService.close();
          }
        },
      });
    }
  }, [openSaveModal]);

  return (
    <div className={styles['main-container']}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Left className='icon-dark c-hand' onClick={navigateBack} />
          {translate('Header strategy overview', selectedLanguage)}
        </div>
        <div className={styles['plan-details']}>
          <div className={styles['plan-details-title']}>
            My CropPlan
            <button className='btn-dark btn' onClick={openPlanPreview}>
              Preview
            </button>
            <button className='btn-primary btn' onClick={handleOpenSave}>
              Save Plan
            </button>
          </div>
          <div className={styles['plan-details-variables']}>
            <div className={styles.variable}>
              <div className={styles.name}>Cumulative yield (kg/m²)</div>
              <div className={styles.value}>
                {roundToDecimals(timeseriesData?.ranges['yield.cu'].max, 1) || '-'}
              </div>
            </div>
            <div className={styles.variable}>
              <div className={styles.name}>Average Brix (B)</div>
              <div className={styles.value}>
                {roundToDecimals(timeseriesData?.brixAverage, 0) || '-'}
              </div>
            </div>
            <div className={styles.variable}>
              <div className={styles.name}>Average fruit weight (g)</div>
              <div className={styles.value}>
                {roundToDecimals(timeseriesData?.afwAverage, 0) || '-'}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.content}>
          <StrategiesFilter ranges={strategies?.ranges} />
          <div className={styles['table-container']}>
            <div className={styles['table-header']}>
              CropPlans
              <div className={styles['header-pagination']}>
                <div className={styles['pagination-text']}>
                  Showing {(currentPage - 1) * pageSize! + 1} –
                  {Math.min(pageSize * currentPage, totalCount)} of {totalCount}
                </div>
                <div className={styles['pagination-dropdown']}>
                  <DropDown
                    colorMode='light'
                    optionList={PAGINATION_DROPDOWN_LIST}
                    displayProperty='displayValue'
                    initialValue={getDropDownValue}
                    setOnChange={setDropDownValue}
                  />
                </div>
              </div>
            </div>
            <div className={styles.table}>
              <StrategiesTable
                strategies={dataToDisplay}
                shopId={shop?.id || ''}
                planId={Number(previewPlanId)}
              />
            </div>
          </div>
        </div>
        <div className={styles.pagination}>
          <Pagination
            setOnChange={setCurrentPage}
            currentPage={currentPage}
            pageSize={pageSize}
            totalCount={totalCount}
          />
        </div>
      </div>
      <div className={styles['page-footer']}>
        <button
          className={`btn ${compareStrategies.length < 1 ? styles['disabled-compare-button'] : ''}`}
          onClick={navigateToComparisonGraph}
          disabled={compareStrategies.length < 1}
        >
          View Comparison
        </button>
      </div>
    </div>
  );
};
export default CompareStrategies;
