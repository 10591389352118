import { createSlice } from '@reduxjs/toolkit';
import { KeyValue } from 'interfaces/keyvalue';
import { Range } from 'interfaces/range';
import { Strategy } from 'interfaces/strategies';

import type { RootState } from 'redux/store';
interface StrategiesState {
  previewStrategy?: Strategy;
  compareStrategies: Strategy[];
  shop?: { id: string; estimatedTime: number; numberOfStrategies: number };
  pagination: {
    currentPage: number;
    totalCount: number;
    pageSize: number;
  };
  filters: KeyValue<Range>;
}

const initialState = (): StrategiesState => ({
  compareStrategies: [],
  pagination: {
    currentPage: 0,
    totalCount: 0,
    pageSize: 0,
  },
  filters: {
    yield: { min: 0, max: 100 },
    brix: { min: 0, max: 100 },
    afw: { min: 0, max: 100 },
  },
});

export const planStrategiesSlice = createSlice({
  name: 'strategies',
  initialState: initialState(),
  reducers: {
    addCompareStrategy: (state, { payload }: { payload: Strategy }) => {
      state.compareStrategies.push(payload);
    },
    removeCompareStrategy: (state, { payload }: { payload: number }) => {
      state.compareStrategies = state.compareStrategies.filter(
        (strategie) => strategie.id !== payload
      );
    },
    removeAllCompareStrategies: (state) => {
      state.compareStrategies = []
    },
    updateShop: (state, { payload }) => {
      state.shop = payload;
    },
    setPagination: (state, { payload }) => {
      state.pagination = {
        ...state.pagination,
        ...payload,
      };
    },
    setFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    setSingleFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        [payload.name]: { ...state.filters[payload.name], ...payload.value },
      };
    },
    reset: () => {
      return initialState();
    },
  },
});

export const strategiesActions = planStrategiesSlice.actions;

export const strategiesSelectors = {
  raw: (state: RootState) => state.planStrategies,
  compareStrategies: (state: RootState) => state.planStrategies.compareStrategies,
  previewStrategy: (state: RootState) => state.planStrategies.previewStrategy,
  shop: (state: RootState) => state.planStrategies.shop,
  pagination: (state: RootState) => state.planStrategies.pagination,
  filters: (state: RootState) => state.planStrategies.filters,
};

export default planStrategiesSlice.reducer;
