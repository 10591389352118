import React, { ReactElement, useRef } from 'react';
import { useAreaController } from '../hooks/Area.controller';

interface AreaProps {
  xScale?: any;
  yScale?: any;
  color?: string;
  data: any;
  transform?: string;
  opacity?: number;
  areaPath?: string;
  customPatternId?: string;
  customPattern?: ReactElement;
  drawCustmChild?: (constructedPath: string) => void;
  hasMask?: boolean;
  maskId?: string;
}

const Area: React.FC<AreaProps> = ({
  xScale,
  yScale,
  color = '#C956FF',
  data,
  transform = '',
  opacity = 0.7,
  areaPath,
  customPatternId,
  customPattern,
  drawCustmChild,
  hasMask = false,
  maskId,
  ...props
}) => {
  const ref = useRef(null);
  const constructedPath = useAreaController(data, xScale, yScale);

  return (
    <>
      {!hasMask && (
        <path
          ref={ref}
          d={areaPath || constructedPath}
          fill={`url(#${customPatternId ? customPatternId : `gradient-${color}`})`}
          opacity={1}
          transform={transform}
          className='area'
        />
      )}
      {hasMask && (
        <path
          ref={ref}
          d={areaPath || constructedPath}
          fill={`url(#${customPatternId ? customPatternId : `gradient-${color}`})`}
          opacity={1}
          transform={transform}
          className='area'
          mask={`url(#${maskId})`}
        />
      )}
      <defs>
        <linearGradient id={`gradient-${color}`} x1='0%' x2='100%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor={color ? color : '#e3e3e3'} stopOpacity={opacity} />
          <stop offset='96%' stopColor={color ? color : '#fff'} stopOpacity={opacity} />
        </linearGradient>
      </defs>
      {customPattern}
      {drawCustmChild?.(constructedPath)}
    </>
  );
};
export default Area;
