import { axisBottom, axisLeft, easeLinear, select } from 'd3';
import useGetTicksForGraphs from 'hooks/useGetTicksForGraphs';
import React from 'react';

import styles from './GridLine.module.scss';

interface GridLineProps {
  type: 'vertical' | 'horizontal' | 'right';
  scale: any;
  ticks: number;
  size?: number;
  interval?: [number, number];
  isAnimating: boolean;
  transform?: string;
  className?: string;
  getTicks?: (domainInput: [number, number], input: number) => number[];
  resolution?: string;
  enforceTicksStartOfDay?: boolean;
}

const GridLine: React.FC<GridLineProps> = ({
  type,
  scale,
  ticks,
  isAnimating = false,
  transform,
  interval,
  size = 1,
  getTicks,
  resolution,
  enforceTicksStartOfDay,
  ...props
}) => {
  const ref = React.useRef(null);

  const ticksArray = useGetTicksForGraphs(
    interval,
    ticks,
    getTicks,
    resolution,
    enforceTicksStartOfDay
  );

  React.useEffect(() => {
    const axisGenerator = type === 'vertical' ? axisBottom : axisLeft;
    const axis = axisGenerator(scale).ticks(ticks).tickSize(-size);

    if (ticksArray) {
      axis.tickValues(ticksArray);
    }
    const gridGroup = select(ref.current);
    if (!isAnimating) {
      gridGroup.call(axis as any);
    } else {
      gridGroup
        .transition()
        .duration(750)
        .ease(easeLinear)
        .call(axis as any);
    }
    gridGroup.select('.domain').remove();
    gridGroup.selectAll('text').remove();
  }, [scale, ticks, size, isAnimating, ticksArray, type]);

  return <g ref={ref} transform={transform} {...props} className={styles['grid-line']} />;
};
export default GridLine;
