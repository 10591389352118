import {
  BREAKPOINT_FULL_HD,
  BREAKPOINT_LAPTOP,
  BREAKPOINT_LAPTOP_L,
  BREAKPOINT_TABLET,
  DESKTOP_GRAPH_PADDING,
  MOBILE_GRAPH_PADDING,
  RESOLUTION,
} from 'config/constants';
import useTranslate from 'hooks/useTranslate';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import './Analyze.scss';
import { Range } from 'interfaces/range';

import ChipFilter from 'components/ChipFilter/ChipFilter';
import MultilineChart from 'components/GraphElements/MultilineChart/MultilineChart';
import SunsetSunrise from 'components/GraphElements/SunsetSunrise/SunsetSunrise';
import Notification from 'components/GraphElements/Notification/Notification';
import TimeRangeSlider from 'components/TimeRangeSlider/TimeRangeSlider';
import CropSeasonPreview from './components/CropSeasonPreview';
import Sidebar from './components/Sidebar';

import { AnalyzeData } from 'interfaces/analyze';
import { analyzeActions, analyzeSelectors } from 'redux/slices/analyze.slice';
import { getFirstLocationByName } from 'utils/location.utils';
import { renderLineData } from 'utils/renderDataInGraph';
import { analyzeTimestampFormatter } from 'utils/tickFormatter';
import {
  addResolutionToTimestamp,
  getEndOfDay,
  getStartOfDay,
  subtractResolutionFromTimestamp,
} from 'utils/time.util';
import { isEmptyObject } from 'utils/general.util';

import { accountIdSelector } from 'redux/slices/accountId.slice';
import { useGetLocationsLinkedToCropseasonQuery } from 'services/metaData.service';

import useGetAnalyzeData from './hooks/useGetAnalyzeData';
import useTwilights from 'hooks/useTwilights';
import useGetSliderAreaData from './hooks/useGetSliderAreaData';
import useGetYRangeInterval from './hooks/useGetYRangeInterval';

import useGraphNotifications from './hooks/useGraphNotifications';
import NotificationTooltip from '../../components/NotificationTooltip/NotificationTooltip';
import useGetAnalyzeNotifications from './hooks/useGetAnalyzeNotifications';
import dayjs from 'dayjs';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';
import TwilightLegend from 'components/GraphElements/TwilightLegend/TwilightLegend';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { Resolution } from 'interfaces/resolution';
import ClippingMask from 'components/GraphElements/ClippingMask/ClippingMask';
import { getUniqueId } from 'utils/getUniqueId';
import WarningMessage from 'components/WarningMessage/WarningMessage';

const MAX_NUMBER_OF_DAYS_FOR_SHOWING_TWILIGHT_ICONS = 31;

const Analyze = () => {
  const windowSize: WindowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const [analyzeData, setAnalyzeData] = useState<AnalyzeData | [] | undefined>();
  const selectedVariables = useAppSelector(analyzeSelectors.variables);
  const { from, to, resolution } = useAppSelector(analyzeSelectors.settings);
  const { selectedCropseasons } = useAppSelector(analyzeSelectors.cropseason);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const groupPerHour = useAppSelector(analyzeSelectors.groupPerHour);
  const { data: locationsLinkedToCropseason } = useGetLocationsLinkedToCropseasonQuery(accountId, {
    skip: !accountId,
  });
  const isSidebarOpen = useAppSelector(analyzeSelectors.isSidebarOpen);
  const translate = useTranslate();
  const notificationsEnabled = useAppSelector(analyzeSelectors.notifications);
  const [isMobile, setIsMobile] = useState(false);

  const locationId = getFirstLocationByName(
    locationsLinkedToCropseason,
    selectedCropseasons[0]?.location?.name
  );
  const [graphHeight, setGraphHeight] = useState(0);

  const [notificationsAreaWidth, setNotificationsAreaWidth] = useState(0);
  const updateGroupedNotifications = (width: number) => {
    setNotificationsAreaWidth(width);
  };

  const fromCloned = getStartOfDay(from);
  const toCloned = getEndOfDay(to);
  const maskId = 'analyze-mask' + getUniqueId();

  const initialTimeRangeAnalyze: Range = {
    min: fromCloned,
    max: toCloned,
  };

  const showUnavailableVariables = useMemo(() => {
    return !!selectedCropseasons?.length && selectedVariables.some((variable) => variable.disabled);
  }, [selectedVariables, selectedCropseasons]);

  useEffect(() => {
    const initialTimeRangeGroupPerHour: Range = {
      min: dayjs().startOf('day').unix(),
      max: dayjs().endOf('day').set('minute', 0).unix(),
    };

    const initialTimeRangeAnalyze: Range = {
      min: fromCloned,
      max: toCloned,
    };

    if (groupPerHour) {
      setInitialTimeRange(initialTimeRangeGroupPerHour);
    } else {
      setInitialTimeRange(initialTimeRangeAnalyze);
    }
  }, [groupPerHour, fromCloned, toCloned]);

  const [initialTimeRange, setInitialTimeRange] = useState<Range>(initialTimeRangeAnalyze);
  const [currentTimeRange, setCurrentTimeRange] = useState(initialTimeRange);
  const { sliderNotifications, notifications } = useGetAnalyzeNotifications(currentTimeRange, {
    from: fromCloned,
    to: toCloned,
  });

  useEffect(() => {
    setCurrentTimeRange({ min: initialTimeRange.min, max: initialTimeRange.max });
  }, [initialTimeRange]);

  const handleCurrentRangeChange = (newRange: Range) => {
    setCurrentTimeRange(newRange);
  };

  const expandedRangeView = {
    min: dayjs(
      subtractResolutionFromTimestamp({
        input: dayjs.unix(currentTimeRange.min).toISOString(),
        resolution: resolution as Resolution,
      })
    ).unix(),
    max: dayjs(
      addResolutionToTimestamp({
        input: dayjs.unix(currentTimeRange.max).toISOString(),
        resolution: resolution as Resolution,
      })
    ).unix(),
  };

  const { getGraphLinesData, hasDataToRender } = useGetAnalyzeData(
    selectedCropseasons,
    analyzeData,
    expandedRangeView,
    selectedVariables,
    groupPerHour
  );

  const { getSliderGraphData } = useGetSliderAreaData(getGraphLinesData(), currentTimeRange);
  const { getYRangeInterval } = useGetYRangeInterval(getGraphLinesData());

  const { getSunsetSunriseData } = useTwilights(
    locationId,
    initialTimeRange.min,
    initialTimeRange.max,
    getGraphLinesData(),
    currentTimeRange
  );

  const { getGroupedNotifications } = useGraphNotifications(
    notifications,
    notificationsAreaWidth || 0,
    currentTimeRange
  );

  let groupNotifications = getGroupedNotifications();

  useEffect(() => {
    setIsMobile(windowSize.width < BREAKPOINT_LAPTOP);
  }, [windowSize.width]);

  const showTwilightIcons = useCallback(() => {
    const datesDiff = dayjs
      .unix(currentTimeRange.max)
      .diff(dayjs.unix(currentTimeRange.min), 'days');
    if (
      windowSize.width <= BREAKPOINT_LAPTOP_L &&
      windowSize.width >= BREAKPOINT_TABLET &&
      datesDiff <= 21
    ) {
      return true;
    }
    if (
      windowSize.width > BREAKPOINT_LAPTOP_L &&
      windowSize.width < BREAKPOINT_FULL_HD &&
      datesDiff <= 24
    ) {
      return true;
    }
    if (
      windowSize.width >= BREAKPOINT_FULL_HD &&
      datesDiff <= MAX_NUMBER_OF_DAYS_FOR_SHOWING_TWILIGHT_ICONS
    ) {
      return true;
    }
    return false;
  }, [currentTimeRange, windowSize.width]);

  const renderGraphElements = useMemo(() => {
    const graphElements = getGraphLinesData()
      .map((data) =>
        renderLineData({
          data: data.data,
          variable: data.keyName || '',
          color: data.color,
          stroke: data.stroke,
          maskId: maskId,
          hasMask: true,
          hideLine: true,
          maskLineColor: data.color,
        })
      )
      .flat();

    graphElements.push(
      <ClippingMask
        key={'analyze-mask'}
        maskId={maskId}
        data={{
          areaData: [
            {
              timestamp: currentTimeRange.min,
              value: getYRangeInterval().min,
            },
            {
              timestamp: currentTimeRange.max,
              value: getYRangeInterval().max,
            },
          ],
        }}
      ></ClippingMask>
    );

    if (hasDataToRender()) {
      graphElements.unshift(
        <SunsetSunrise
          key='sunsetSunrise'
          YInterval={[getYRangeInterval()]}
          sunRiseSunSetData={getSunsetSunriseData()}
          showIcons={showTwilightIcons()}
        />
      );
    }
    if (notificationsEnabled) {
      graphElements.push(
        <line
          className='notificationIconTimestampLine'
          stroke='rgba(255, 255, 255, 0.1)'
          strokeWidth={'2px'}
          x1={-100}
          x2={-100}
          y1={0}
          y2={graphHeight}
        />
      );

      graphElements.push(
        <Notification
          key='notifications'
          currentTimeRange={currentTimeRange}
          groupedNotificationsCallback={updateGroupedNotifications}
          notificationsData={notifications!}
          isMobile={isMobile}
        />
      );
    }
    return graphElements;
  }, [
    getGraphLinesData,
    hasDataToRender,
    getYRangeInterval,
    getSunsetSunriseData,
    notificationsEnabled,
    currentTimeRange,
    notifications,
    showTwilightIcons,
    isMobile,
    graphHeight,
    maskId,
  ]);

  const toggleNotifications = () => {
    dispatch(analyzeActions.toggleNotifications());
  };

  return (
    <div className='analyze-view-container'>
      <div className='analyze-container'>
        <WarningMessage
          title={translate('General warning message title info')}
          message={translate('Analyze: header warning unavailable variables')}
          isVisible={showUnavailableVariables}
        />
        <div className='header'>
          <h1 className='title'>{translate('Analyze - header')}</h1>
          <div className='analyze-header-filters'>
            <div
              className={`analyze-header-toggle-notifications ${
                groupPerHour ? 'toggle-disabled' : ''
              }`}
            >
              <span className='analyze-header-toggle-notifications-text'>
                {translate('Analyze page - header toggle - notifications')}
              </span>
              <ToggleSwitch
                onToggle={toggleNotifications}
                initialValue={notificationsEnabled}
                disabled={groupPerHour}
              />
            </div>
            <Sidebar onVariablesChange={setAnalyzeData} />
          </div>
        </div>
        <ChipFilter
          chips={selectedVariables.map((variable) => ({
            value: variable.value,
            label: variable.label,
            color: variable.color,
            source: variable.source,
            disabled: variable.disabled,
          }))}
          onClear={(value) => dispatch(analyzeActions.removeVariable(value))}
          onClearAll={() => dispatch(analyzeActions.removeAllVariables())}
        />
        <div className='crop-seasons'>
          <CropSeasonPreview cropSeasons={selectedCropseasons} />
        </div>

        <div className='analyze-graph-container'>
          <div className='graph'>
            <MultilineChart
              dataToDisplay={getGraphLinesData()}
              resolution={resolution}
              xAxisProperty='timestamp'
              title='Analyze'
              YInterval={[getYRangeInterval()]}
              hasYIntervalExact={true}
              XInterval={[currentTimeRange.min, currentTimeRange.max]}
              hasTooltip={true}
              showTitle={false}
              showYAxisTicks={false}
              customFormatter={analyzeTimestampFormatter(resolution, isMobile, groupPerHour)}
              yOffset={20}
              enforceTicksStartOfDay={false}
              XTickPadding={20}
              linesPatternLegend={<TwilightLegend />}
              hasLinesPatternLegend={!showTwilightIcons() && !!selectedVariables.length}
              applyPadding={false}
              customFormatXTick={analyzeTimestampFormatter(resolution, isMobile, groupPerHour)}
              xAxisOffset={isMobile ? MOBILE_GRAPH_PADDING : DESKTOP_GRAPH_PADDING}
              graphHeightCallback={setGraphHeight}
            >
              {renderGraphElements}
            </MultilineChart>
            {!isEmptyObject(groupNotifications) && (
              <NotificationTooltip
                groupNotifications={groupNotifications}
                showExtraInfo={false}
              ></NotificationTooltip>
            )}
          </div>
          <TimeRangeSlider
            initialRange={initialTimeRange}
            currentRange={currentTimeRange}
            resolution={RESOLUTION._1_hour}
            step={1}
            customClassName='trs-analyze'
            railGraphData={getSliderGraphData()}
            notificationsData={sliderNotifications}
            onCurrentRangeChange={handleCurrentRangeChange}
            showShortHourFormat={groupPerHour}
          />
        </div>
      </div>
      <div
        className={`${isSidebarOpen ? 'settings-sidebar-active' : 'settings-sidebar-inactive'}`}
      ></div>
    </div>
  );
};
export default Analyze;
