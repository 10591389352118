import Chip from 'components/Chip/Chip';
import './ChipFilter.scss';

import { FilterChip } from 'interfaces/filter-chip';
import React from 'react';

interface IChipFilterProps {
  chips?: FilterChip[];
  onClear?: (value: string) => void;
  onClearAll?: () => void;
  hasClose?: boolean;
}

const ChipFilter: React.FC<IChipFilterProps> = ({
  chips = [],
  onClear = () => {},
  onClearAll = () => {},
  hasClose = true,
}) => {
  return (
    <div className={`chip-filter-container`}>
      {chips.map((chip, index) => (
        <Chip
          chip={chip}
          hasClose={hasClose}
          onClear={onClear}
          key={`chipFilter-chip-${index}`}
        ></Chip>
      ))}
      {chips.length ? (
        <button className='clean-button' onClick={() => onClearAll()}>
          Clear all
        </button>
      ) : null}
    </div>
  );
};
export default ChipFilter;
