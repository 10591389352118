export enum NotificationItemConfig {
  StyleDark = 'dark',
  StyleLight = 'light',
  TypeFullPage = 'full_page',
  TypeTooltip = 'tooltip',
}

export type NotificationItemStyle =
  | NotificationItemConfig.StyleDark
  | NotificationItemConfig.StyleLight;
export type NotificationItemType =
  | NotificationItemConfig.TypeFullPage
  | NotificationItemConfig.TypeTooltip;

export type NotificationThresholdType = 'high' | 'low' | 'ghtemp' | 'vadefe';
export enum NotificationThreshold {
  High = 'high',
  Low = 'low',
  GHTEMP = 'ghtemp',
  VADEFE = 'vadefe'
}
