export const checkEnvironmentVariables = () => {
  let error: string[] = [];

  if (!process.env.REACT_APP_REALM) {
    const message: string = 'React app realm not found (REACT_APP_REALM)';
    console.error(message);
    error = error.concat(message);
  }

  if (!process.env.REACT_APP_KEYCLOAK_URL) {
    const message: string = 'React app keycloak url not found (REACT_APP_KEYCLOAK_URL)';
    console.error(message);
    error = error.concat(message);
  }

  if (!process.env.REACT_APP_API_URL || !process.env.REACT_APP_API_REPORTING_URL) {
    let message: string = '';
    if (!process.env.REACT_APP_API_URL) {
      message = 'React app api url not found (REACT_APP_API_URL)';
    } else {
      message = 'React app api reporting url not found (REACT_APP_API_REPORTING_URL)';
    }
    console.error(message);
    error = error.concat(message);
  }

  if (!process.env.REACT_APP_CLIENT_ID) {
    const message: string = 'React app client id not found (REACT_APP_CLIENT_ID)';
    console.error(message);
    error = error.concat(message);
  }

  if (!process.env.REACT_APP_REDIRECT_URI) {
    const message: string = 'React app redirect uri not found (REACT_APP_REDIRECT_URI)';
    console.error(message);
    error = error.concat(message);
  }

  if (!process.env.REACT_APP_SASS_PATH) {
    const message: string = 'React app SASS path not found (REACT_APP_SASS_PATH )';
    console.error(message);
    error = error.concat(message);
  }

  if (!process.env.REACT_APP_UNLEASH_API_URL) {
    const message: string = 'Unleash API URL not found (UNLEASH_API_URL)';
    console.error(message);
    error = error.concat(message);
  }

  if (!process.env.REACT_APP_UNLEASH_API_KEY) {
    const message: string = 'Unleash API Key not found ';
    console.error(message);
    error = error.concat(message);
  }

  return error;
};
