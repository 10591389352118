import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { QUERY_LIMIT, RESOLUTION } from 'config/constants';
import dayjs from 'dayjs';
import { KeyValue } from 'interfaces/keyvalue';
import { Resolution } from 'interfaces/resolution';

import { baseApi } from 'redux/slices/baseApi.slice';
import { getRanges, mergeKeyValues, mergeRanges } from 'utils/getIntervalsForGraphs';
import { formatQueryString } from 'utils/queryString';
import { getEndOfDayFormated, getStartOfDayFormated, getUnix } from 'utils/time.util';
import {
  getKeyValuesFromVariables,
  getResolutionFromMissingVariables,
  getVariablesBasedOnResolution,
} from 'utils/variables.util';

interface QueryParams {
  cropSeasonId: number;
  startDate: string;
  endDate: string;
  resolution: string;
  variables: KeyValue<string>;
  skip?: number;
  limit?: number;
  accountId: number;
  refetchQueryStartTime?: string | null;
}

const query = ({
  cropSeasonId,
  startDate,
  endDate,
  resolution,
  variables,
  skip,
  limit,
  accountId,
  refetchQueryStartTime = null,
}: QueryParams) => {
  const ENDPOINT = `cropseasons/guardrails?`;
  return (
    ENDPOINT +
    formatQueryString({
      id: cropSeasonId,
      skip: skip || 0,
      limit: limit || QUERY_LIMIT,
      resolution: resolution,
      min_local_datetime:
        refetchQueryStartTime || getStartOfDayFormated(dayjs(startDate).isoWeekday(1).format()),
      max_local_datetime: getEndOfDayFormated(dayjs(endDate).add(1, 'week').endOf('week').format()),
      variable: Object.values(variables),
      current_account_id: accountId,
    })
  );
};

const transformResponse = (rawResult: any, { variables, startDate, endDate }: QueryParams): any => {
  let response: any = {
    ranges: {},
    refetch: false,
  };

  const resultTimeseries = rawResult?.result.timeseries;

  if (!resultTimeseries || !Object.keys(resultTimeseries).length) {
    return response;
  }

  const firstCropSeasonId = Object.keys(rawResult?.result?.timeseries)[0];
  const result = rawResult?.result?.timeseries[firstCropSeasonId];

  response.refetch = rawResult?.datetime_pagination?.next_min_local_datetime < endDate;
  response.start_local_datetime = rawResult?.datetime_pagination?.next_min_local_datetime;
  response.ranges = getRanges(result, variables);

  const timestamps = result.local_datetime.map((datetime: string) => getUnix(datetime));

  Object.keys(variables).forEach((variable) => {
    response[variable] = timestamps.flatMap((timestamp: number, i: number) => {
      return {
        timestamp,
        value: [result[variables[variable]]?.min[i], result[variables[variable]]?.max[i]],
      };
    });
  });
  return response;
};

const retrieveTheData = async (
  _arg: QueryParams,
  fetchWithBaseQuery: (
    arg: string | FetchArgs
  ) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>
) => {
  let transformedResponse;
  let missingVariables: string[] = [];
  let response;
  let startDateTime = null;

  do {
    let partialResponse = await fetchWithBaseQuery(
      query({ ..._arg, startDate: _arg.startDate, refetchQueryStartTime: startDateTime })
    );

    if (partialResponse.error?.status === 404) {
      return { response: null, missingVariables: Object.values(_arg.variables) };
    }

    transformedResponse = transformResponse(partialResponse.data, _arg);
    startDateTime = transformedResponse.start_local_datetime;

    missingVariables = (partialResponse.data as any)?.result.variables_not_found;

    if (response) {
      response = {
        ...mergeKeyValues(Object.keys(_arg.variables), response, transformedResponse),
        ranges: mergeRanges(response.ranges, transformedResponse.ranges),
      };
    } else {
      response = { ...transformedResponse };
    }
  } while (transformedResponse.refetch);

  return { missingVariables, response };
};

const guardrailsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getGuardrails: builder.query<any, QueryParams>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        let resolution = _arg.resolution;

        if (_arg.resolution !== RESOLUTION._1_day && _arg.resolution !== RESOLUTION._1_week) {
          resolution = RESOLUTION._1_day;
        }
        const variables = getVariablesBasedOnResolution(_arg.variables, resolution as Resolution);
        const args = { ..._arg, variables };

        let { missingVariables, response } = await retrieveTheData(args, fetchWithBaseQuery);

        const missingResolutionsMap = getResolutionFromMissingVariables(
          args.variables,
          missingVariables
        );

        for (let entry of Object.entries(missingResolutionsMap)) {
          const { response: partialResponse } = await retrieveTheData(
            {
              ...args,
              resolution: entry[0],
              variables: getKeyValuesFromVariables(variables, entry[1]),
            },
            fetchWithBaseQuery
          );

          if (!response) {
            response = partialResponse;
          } else if (partialResponse) {
            response = {
              ...mergeKeyValues(Object.keys(variables), response, partialResponse),
              ranges: mergeRanges(response.ranges, partialResponse.ranges),
            };
          }
        }
        return { data: response };
      },
    }),
    getGuardrailVariableStatus: builder.query<
      any,
      { cropseasonId: number; accountId: number; varDay: string[]; varWeek: string[] }
    >({
      query: ({ cropseasonId, accountId, varDay, varWeek }) => {
        const ENDPOINT = `/notifications/variable-status?`;
        return (
          ENDPOINT +
          formatQueryString({
            skip: 0,
            limit: 1000,
            cropseason_id: cropseasonId,
            current_account_id: accountId,
            var_day: varDay,
            var_week: varWeek,
          })
        );
      },
      transformResponse: (rawResults: { result: any }): KeyValue<string> => {
        let dayStatuses = rawResults.result.day.statuses;
        let weekStatuses = rawResults.result.week.statuses;

        let result = { ...dayStatuses, ...weekStatuses };
        return result;
      },
    }),
  }),
});

export const { useGetGuardrailsQuery, useGetGuardrailVariableStatusQuery } = guardrailsApi;
