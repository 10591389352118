import { QUERY_LIMIT } from 'config/constants';
import dayjs from 'dayjs';
import { KeyValue } from 'interfaces/keyvalue';
import { baseApi } from 'redux/slices/baseApi.slice';
import { getRanges, mergeKeyValues, mergeRanges } from 'utils/getIntervalsForGraphs';
import { formatQueryString } from 'utils/queryString';
import { getEndOfDayFormated, getStartOfDayFormated, getUnix } from 'utils/time.util';

interface QueryParams {
  cropseasonId: number;
  variables: KeyValue<string>;
  startDate: string;
  endDate: string;
  accountId: number;
  limit?: number;
  skip?: number;
  refetchQueryStartTime?: string | null;
}

const query = ({
  cropseasonId,
  startDate,
  endDate,
  skip,
  limit,
  accountId,
  refetchQueryStartTime,
  variables,
}: QueryParams) => {
  const ENDPOINT = `adaptive-plans/timeseries?`;

  return (
    ENDPOINT +
    formatQueryString({
      cropseason_id: cropseasonId,
      skip: skip || 0,
      limit: limit || QUERY_LIMIT,
      min_local_datetime:
        refetchQueryStartTime || getStartOfDayFormated(dayjs(startDate).isoWeekday(1).format()),
      max_local_datetime: getEndOfDayFormated(dayjs(endDate).add(1, 'week').endOf('week').format()),
      variable: Object.values(variables),
      current_account_id: accountId,
    })
  );
};

const transformResponse = (rawResult: any, { variables, startDate, endDate }: any): any => {
  let response: any = {
    ranges: {},
    refetch: false,
  };

  const resultTimeseries = rawResult?.result.timeseries;

  if (!resultTimeseries || !Object.keys(resultTimeseries).length) {
    return response;
  }

  const firstCropSeasonId = Object.keys(rawResult?.result?.timeseries)[0];
  const result = rawResult?.result?.timeseries[firstCropSeasonId];

  response.refetch = rawResult?.datetime_pagination?.next_min_local_datetime < endDate;
  response.start_local_datetime = rawResult?.datetime_pagination?.next_min_local_datetime;
  response.ranges = getRanges(result, variables);

  const timestamps = result.local_datetime.map((datetime: string) => getUnix(datetime));
  Object.keys(variables).forEach((variable) => {
    response[variable] = timestamps.flatMap((timestamp: number, i: number) => {
      return {
        timestamp,
        [`${variable}AdaptivePlan`]: result[variables[variable]]?.[i],
      };
    });
  });

  return response;
};

const adaptivePlanApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdaptivePlan: builder.query<any, QueryParams>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        let skip = 0;
        let response;
        let transformedResponse;
        let startDateTime = null;

        do {
          let partialResponse = await fetchWithBaseQuery(
            query({
              ..._arg,
              skip,
              startDate: _arg.startDate,
              refetchQueryStartTime: startDateTime,
            })
          );

          if (partialResponse.error?.status === 404) {
            partialResponse = await fetchWithBaseQuery(query({ ..._arg, skip }));
          }

          transformedResponse = transformResponse(partialResponse.data, _arg);
          startDateTime = transformedResponse.start_local_datetime;

          if (response) {
            response = {
              ...mergeKeyValues(Object.keys(_arg.variables), response, transformedResponse),
              ranges: mergeRanges(response.ranges, transformedResponse.ranges),
            };
          } else {
            response = { ...transformedResponse };
          }

          skip += QUERY_LIMIT;
        } while (transformedResponse.refetch);

        return { data: response };
      },
    }),
  }),
});

export const { useGetAdaptivePlanQuery } = adaptivePlanApi;
