import React from 'react';
import styles from './WarningMessage.module.scss';
import { ReactComponent as CloseIcon } from 'styles/icons/close.svg';
import { ReactComponent as Info } from 'styles/icons/info.svg';

interface WarningMessageProps {
  title: string;
  message: string;
  noMargin?: boolean;
  isVisible?: boolean;
  handleClose?: () => void;
}

const WarningMessage: React.FC<WarningMessageProps> = ({
  title,
  message,
  noMargin,
  isVisible = true,
  handleClose,
}) => {
  const noMarginClass = noMargin ? styles['no-margin'] : '';

  return (
    <div style={{ display: isVisible ? 'block' : 'none' }}>
      <div className={styles['warning-message'] + ' ' + noMarginClass}>
        <div className={styles['warning-message-left']}>
          <div className={styles['icon-container']}>
            <Info className={styles['icon-dark']} />
          </div>
          <div className={styles['message']}>
            <span>{title} </span> {message}
          </div>
        </div>

        {isVisible && handleClose && (
          <CloseIcon
            className={styles['message-close']}
            onClick={() => {
              handleClose();
            }}
          ></CloseIcon>
        )}
      </div>
    </div>
  );
};

export default WarningMessage;
