import React, { useCallback, useEffect, useState } from 'react';

import styles from './Notification.module.scss';
import { translate } from 'utils/translations.util';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { nofiticationSelector } from 'redux/slices/notification.slice';

import dayjs from 'dayjs';
import { useGetCropSeasonNotificationsQuery } from 'services/notifications.service';
import {
  getEndOfDayFormated,
  getStartOfDayFormated,
  intervalIsLast24H,
  intervalIsLast30Days,
  intervalIsLast7Days,
} from 'utils/time.util';
import NotificationList from './NotificationList/NotificationList';
import {
  useUpdateUserSettingsMutation,
  useGetUserSettingsQuery,
} from 'services/userAccount.service';
import { languageSelector } from 'redux/slices/language.slice';
import NotificationFilter from './NotificationFilter/NotificationFilter';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT_LAPTOP, DESKTOP_GRAPH_PADDING, MOBILE_GRAPH_PADDING } from 'config/constants';

const Notification: React.FC = () => {
  const { dates, selectedCropSeasons } = useAppSelector(nofiticationSelector.filters);

  const accountId = useAppSelector(accountIdSelector).accountId;

  const [footerText, setFooterText] = useState<string>('');
  const [updateDefaultCropSeason] = useUpdateUserSettingsMutation();
  const { data: defaultCropSeason, isSuccess } = useGetUserSettingsQuery();

  const { selectedLanguage } = useAppSelector(languageSelector);
  const windowSize: WindowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  const { data: notificationsData } = useGetCropSeasonNotificationsQuery(
    {
      current_account_id: accountId,
      cropseason_ids: selectedCropSeasons.map((cropseason) => cropseason.id.toString()),
      min_local_datetime: getStartOfDayFormated(dayjs.unix(dates.from).toISOString()),
      max_local_datetime: getEndOfDayFormated(dayjs.unix(dates.to).toISOString()),
    },
    { skip: !selectedCropSeasons.length || !accountId, refetchOnMountOrArgChange: true }
  );

  const updateFooterText = useCallback(
    (from: number, to: number, hasNotifiations: boolean) => {
      if (intervalIsLast24H(from, to)) {
        if (!hasNotifiations) {
          return setFooterText(translate('Notification-list-footer-none-24h', selectedLanguage));
        } else {
          return setFooterText(translate('Notification-list-footer-all-24h', selectedLanguage));
        }
      } else if (intervalIsLast7Days(from, to)) {
        if (!hasNotifiations) {
          return setFooterText(translate('Notification-list-footer-none-7days', selectedLanguage));
        } else {
          return setFooterText(translate('Notification-list-footerall-7days', selectedLanguage));
        }
      } else if (intervalIsLast30Days(from, to)) {
        if (!hasNotifiations) {
          return setFooterText(translate('Notification-list-footer-none-30days', selectedLanguage));
        } else {
          return setFooterText(translate('Notification-list-footer-all-30days', selectedLanguage));
        }
      } else {
        if (!hasNotifiations) {
          return setFooterText(
            translate('Notification-list-footer-none-in-interval', selectedLanguage)
          );
        } else {
          return setFooterText(
            translate('Notification-list-footer-all-in-interval', selectedLanguage)
          );
        }
      }
    },
    [selectedLanguage]
  );

  useEffect(() => {
    const hasNotifiations =
      (notificationsData && notificationsData?.notifications.length > 0) || false;
    updateFooterText(dates.from, dates.to, hasNotifiations);
  }, [
    notificationsData?.notifications,
    dates.from,
    dates.to,
    selectedCropSeasons,
    notificationsData,
    updateFooterText,
  ]);

  useEffect(() => {
    if (
      isSuccess &&
      accountId &&
      defaultCropSeason?.accounts &&
      defaultCropSeason.accounts[accountId]
    ) {
      updateDefaultCropSeason({
        data: {
          ...defaultCropSeason,
          accounts: {
            [accountId]: {
              ...defaultCropSeason.accounts[accountId],
              last_time_seen_notifications: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
            },
          },
        },
      });
    }
  }, [isSuccess, accountId]);

  useEffect(() => {
    setIsMobile(windowSize.width < BREAKPOINT_LAPTOP);
  }, [windowSize.width]);

  const graphXPadding = isMobile ? MOBILE_GRAPH_PADDING : DESKTOP_GRAPH_PADDING;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{translate('Profile page - notifications - tab title')}</div>
        <NotificationFilter />
      </div>
      {selectedCropSeasons.length > 0 && (
        <NotificationList
          notificationList={notificationsData?.notifications || []}
          footerText={footerText}
          isMobile={isMobile}
          graphXPadding={graphXPadding}
        />
      )}
    </div>
  );
};
export default Notification;
