import Input from 'components/Input/Input';
import { MAX_LENGTH_FOLDER_NAME, MIN_LENGTH_FOLDER_NAME } from 'config/constants';
import React, { useState } from 'react';
import { checkFolderName } from 'utils/validation.util';

interface NewPlanFolderContentProps {
  touched?: boolean;
  newFolderName?: any;
}

const NewPlanFolderContent: React.FC<NewPlanFolderContentProps> = ({ newFolderName, touched }) => {
  const [folderName, setFolderName] = useState('');

  return (
    <Input
      label='Folder name'
      name='new-folder'
      value={folderName}
      onChange={(value) => {
        newFolderName.newFolderName = value;
        setFolderName(value);
      }}
      errorText={
        touched ? checkFolderName(folderName, MIN_LENGTH_FOLDER_NAME, MAX_LENGTH_FOLDER_NAME) : ''
      }
    />
  );
};
export default NewPlanFolderContent;
