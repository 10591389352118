import useUrlState from 'hooks/useUrlState';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { cropseasonSelector, setActiveCropseason } from 'redux/slices/cropseason.slice';
import { accountIdSelector, setAccountId } from 'redux/slices/accountId.slice';
import { useGetCropseasonsQuery } from 'services/cropseason.service';
import { setDate, setResolution, trackFilterSelector } from 'redux/slices/trackFilterSlice';
import { getResolutionsInitialValues } from 'utils/getInitialValues';
import { setTrackPageActiveTab } from 'utils/setTrackPageActiveTab';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { stepperSelector } from 'redux/slices/stepperSlice';
import {
  plansSelector,
  setMyPlansFilters,
  setSelectedPlansFolderName,
} from 'redux/slices/plansSlice';
import useGetSidebarFilterOptions from 'views/Analyze/hooks/useGetSidebarFilterOptions';
import { analyzeActions, analyzeSelectors } from 'redux/slices/analyze.slice';
import { useGetFilterOptionsMutation } from 'services/analyze.service';
import { getAnalyzeColor } from 'utils/colors.util';
import { languageSelector } from 'redux/slices/language.slice';
import { translate } from 'utils/translations.util';
import { CropSeason, Variety } from 'interfaces/crop-season';
import { FilterVariable } from 'interfaces/filter-variable';
import { notificationActions } from 'redux/slices/notification.slice';
import { handleResolutionError } from 'utils/variables.util';
import { VARIABLES_ICONS } from 'config/variable-icons';

const useSetLocationUrl = () => {
  const location = useLocation();

  const activeCropseason = useAppSelector(cropseasonSelector);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const activeStep = useAppSelector(stepperSelector('planCreationStep'));
  const { myPlansFilters, selectedPlansFolderName } = useAppSelector(plansSelector);
  const { getLocationOptions, getVarietyOptions } = useGetSidebarFilterOptions();
  const { selectedLanguage } = useAppSelector(languageSelector);
  const variablesAll = useAppSelector(analyzeSelectors.variables);
  const { from, to } = useAppSelector(analyzeSelectors.settings);
  const groupPerHour = useAppSelector(analyzeSelectors.groupPerHour);

  const [urlState, setUrlState] = useUrlState<any>({
    cropseasonId: '0',
    accountId: '0',
    startDate: '',
    endDate: '',
    resolution: '',
    activeTab: '',
    activePlanCreationStep: '',
    varietyFilter: '',
    selectedFolder: '',
    locations: [],
    varieties: [],
    selectedCropseasons: [],
    from: '',
    to: '',
    var: [],
    notificationsLocations: [],
    notificationsStartDate: '',
    notificationsEndDate: '',
    notificationsSelectedCropseasons: [],
    sideBarOpen: true,
    groupPerHour: '',
    invite_token_id: '',
  });

  const dispatch = useAppDispatch();
  const { data: cropSeasons } = useGetCropseasonsQuery(accountId, {
    skip: !accountId,
    refetchOnMountOrArgChange: true,
  });

  const [getFilterOptions] = useGetFilterOptionsMutation();

  const getAllVariables = async (list: any[], from: number, to: number) => {
    if (accountId) {
      return Promise.all(
        list.map(async (item) => {
          return await getFilterOptions({
            cropSeasonId: Number(item),
            dateRange: { from, to },
            resolution: urlState.resolution,
            accountId: accountId,
          }).unwrap();
        })
      );
    } else {
      return [];
    }
  };

  const { dates, activeTab, resolution } = useAppSelector(trackFilterSelector);

  useEffect(() => {
    switch (location.pathname) {
      case '/notification': {
        if (Number(urlState.accountId)) {
          dispatch(setAccountId(Number(urlState.accountId)));
        }
        if (urlState.notificationsLocations.length && getLocationOptions().length) {
          const data = [urlState.notificationsLocations].flat();
          const locations: any[] = [];
          data.forEach((location) => {
            const locationData = getLocationOptions().find((data) => {
              return data.id === Number(location);
            });
            if (locationData) {
              locations.push(locationData);
            }
          });
          dispatch(notificationActions.setFilter({ locations }));
        }
        if (Number(urlState.notificationsStartDate) && Number(urlState.notificationsEndDate)) {
          dispatch(
            notificationActions.setFilterDates({
              from: Number(urlState.notificationsStartDate),
              to: Number(urlState.notificationsEndDate),
            })
          );
        }
        if (urlState.notificationsSelectedCropseasons.length) {
          const data = [urlState.notificationsSelectedCropseasons].flat();

          const cropseasonsData: CropSeason[] = [];

          if (cropSeasons?.length) {
            data.forEach((season) => {
              const cropseason = cropSeasons?.find((data) => {
                return data.id === Number(season);
              });
              if (cropseason) {
                cropseasonsData.push(cropseason);
              }
            });

            dispatch(notificationActions.setFilter({ selectedCropSeasons: cropseasonsData }));
          }
        }
        if (Number(urlState.accountId)) {
          dispatch(setAccountId(Number(urlState.accountId)));
        }
        if (!Number(urlState.accountId)) {
          setUrlState({ ...urlState, accountId: String(accountId) });
        }
        break;
      }
      default: {
        break;
      }
    }
  }, [location.pathname, getLocationOptions().length, cropSeasons]);

  useEffect(() => {
    switch (location.pathname) {
      case '/analyze': {
        if (urlState.locations.length) {
          const data = [urlState.locations].flat();
          const locations: any[] = [];
          data.forEach((location) => {
            const locationData = getLocationOptions().find((data) => {
              return data.id === Number(location);
            });
            if (locationData) {
              locations.push(locationData);
            }
          });
          dispatch(analyzeActions.setCropeasons({ locations }));
        }
        if (urlState.varieties.length) {
          const data = [urlState.varieties].flat();
          const varieties: Variety[] = [];
          data.forEach((variety) => {
            const varietyData = getVarietyOptions().find((data) => {
              return data.name === String(variety);
            });
            if (varietyData) {
              varieties.push(varietyData);
            }
          });
          dispatch(analyzeActions.setCropeasons({ varieties }));
        }
        if (urlState.selectedCropseasons.length) {
          const data = [urlState.selectedCropseasons].flat();
          const cropseasonsData: CropSeason[] = [];
          if (cropSeasons?.length) {
            data.forEach((season) => {
              const cropseason = cropSeasons?.find((data) => {
                return data.id === Number(season);
              });
              if (cropseason) {
                cropseasonsData.push(cropseason);
              }
            });
            dispatch(analyzeActions.setCropeasons({ selectedCropseasons: cropseasonsData }));
          }
        }
        if (Number(urlState.from) && Number(urlState.to)) {
          dispatch(
            analyzeActions.setSettings({ from: Number(urlState.from), to: Number(urlState.to) })
          );
        }
        if (urlState?.resolution?.length) {
          const RESOLUTIONS = getResolutionsInitialValues();
          const resolution = RESOLUTIONS.find(
            (resolution) => resolution.value === urlState.resolution
          );
          dispatch(analyzeActions.setSettings({ resolution: resolution?.value }));
        }
        if (
          urlState.var.length &&
          urlState.selectedCropseasons.length &&
          Number(urlState.from) &&
          Number(urlState.to) &&
          cropSeasons?.length &&
          !handleResolutionError(from, to, urlState.resolution)
        ) {
          const data = [urlState.var].flat();
          let currentVariableColors = [] as string[];
          getAllVariables(
            [urlState.selectedCropseasons].flat(),
            Number(urlState.from),
            Number(urlState.to)
          ).then((variables): any => {
            let variablesData: FilterVariable[] = [];
            if (variables.length) {
              variablesData = [...variables?.[0]];
            }
            data.forEach((variable, index) => {
              //TODO - remove variable name when all variables have an Id
              const variableData = variablesData.find(
                (varData) => varData.id === String(variable) || varData.name === String(variable)
              );

              //TODO remove variable.name after API responds with Id's everywhere
              if (variableData) {
                dispatch(
                  analyzeActions.addVariable({
                    color: getAnalyzeColor(currentVariableColors),
                    label: translate(variableData.id || variableData.name, selectedLanguage),
                    value: variableData.id || variableData.name,
                    unit: translate(variableData?.unit, selectedLanguage),
                    source: variableData?.source,
                    icon: VARIABLES_ICONS[variableData?.source],
                    scalingGroup: variableData.scaling_group,
                  })
                );
                currentVariableColors.push(getAnalyzeColor(currentVariableColors));
              }
            });
          });
        }
        if (urlState.groupPerHour === 'true' && !groupPerHour) {
          dispatch(analyzeActions.toggleGroupPerHour());
        }
        if (Number(urlState.accountId)) {
          dispatch(setAccountId(Number(urlState.accountId)));
        }
        if (!Number(urlState.accountId)) {
          setUrlState({
            accountId: String(accountId),
          });
        }
        if (!Number(urlState.from) && !Number(urlState.to)) {
          setUrlState({ from: from, to: to });
        }
        if (urlState.sideBarOpen === false.toString()) {
          dispatch(analyzeActions.closeSidebar());
        }
        break;
      }
      default: {
        break;
      }
    }
  }, [location.pathname, accountId, cropSeasons, from, to]);

  useEffect(() => {
    switch (location.pathname) {
      case '/climate':
      case '/water-management':
      case '/fresh-weight-gain':
      case '/water-efficiency':
      case '/crop-status':
      case '/production': {
        if (Number(urlState.cropseasonId)) {
          const cropSeason = cropSeasons?.find(
            (cropseason) => cropseason.id === Number(urlState.cropseasonId)
          );
          if (cropSeason) {
            dispatch(setActiveCropseason(cropSeason));
          }
        }
        if (Number(urlState.startDate) && Number(urlState.endDate)) {
          dispatch(setDate({ start: Number(urlState.startDate), end: Number(urlState.endDate) }));
        }
        if (urlState?.resolution?.length) {
          const RESOLUTIONS = getResolutionsInitialValues();
          const resolution = RESOLUTIONS.find(
            (resolution) => resolution.value === urlState.resolution
          );
          dispatch(setResolution(resolution));
        }
        if (urlState.activeTab) {
          setTrackPageActiveTab(Number(urlState.activeTab));
        }
        if (Number(urlState.accountId)) {
          dispatch(setAccountId(Number(urlState.accountId)));
        }
        if (!Number(urlState.cropseasonId)) {
          setUrlState({
            cropseasonId: String(activeCropseason.id),
            accountId: String(accountId),
            startDate: String(dates.start),
            endDate: String(dates.end),
            resolution: resolution.value,
            activeTab: String(activeTab),
          });
        }
        break;
      }
      case '/dashboard': {
        if (Number(urlState.cropseasonId)) {
          const cropSeason = cropSeasons?.find(
            (cropseason: CropSeason) => cropseason.id === Number(urlState.cropseasonId)
          );
          if (cropSeason) {
            setUrlState({ cropseasonId: String(cropSeason.id) });
            dispatch(setActiveCropseason(cropSeason));
          }
        }
        if (Number(urlState.accountId)) {
          dispatch(setAccountId(Number(urlState.accountId)));
        }
        if (!Number(urlState.cropseasonId)) {
          setUrlState({
            cropseasonId: String(activeCropseason.id),
            accountId: String(accountId),
          });
        }
        break;
      }
      case '/plan-settings': {
        setUrlState({ activePlanCreationStep: String(activeStep) });
        break;
      }
      case '/my-plans': {
        if (urlState.varietyFilter) {
          dispatch(setMyPlansFilters({ variety: urlState.varietyFilter }));
        }
        if (Number(urlState.startDate) && Number(urlState.endDate)) {
          dispatch(
            setMyPlansFilters({
              from: Number(urlState.startDate),
              to: Number(urlState.endDate),
            })
          );
        }
        if (urlState.selectedFolder) {
          dispatch(setSelectedPlansFolderName(urlState.selectedFolder));
        }
        if (Number(urlState.accountId)) {
          dispatch(setAccountId(Number(urlState.accountId)));
        }
        if (!Number(urlState.startDate)) {
          setUrlState({
            accountId: String(accountId),
            selectedFolder: selectedPlansFolderName,
            startDate: String(myPlansFilters.from),
            endDate: String(myPlansFilters.to),
            varietyFilter: myPlansFilters.variety,
          });
        }
        break;
      }
      default: {
        break;
      }
    }
    if (location.pathname.includes('/plan/edit')) {
      if (Number(urlState.accountId)) {
        dispatch(setAccountId(Number(urlState.accountId)));
      }
      if (!Number(urlState.accountId)) {
        setUrlState({
          accountId: String(accountId),
        });
      }
    }
  }, [
    location.pathname,
    activeCropseason.id,
    cropSeasons,
    getLocationOptions.length,
    getVarietyOptions.length,
    variablesAll,
  ]);
};

export default useSetLocationUrl;
