import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { UidName } from 'interfaces/uid-name';

import type { RootState } from 'redux/store';

interface DateRange {
  start: number;
  end: number;
}

interface Resolution {
  displayValue: string;
  value: string;
}
interface TrackFilterState {
  dates: DateRange;
  resolution: Resolution;
  activeTab: number;
  groups: UidName[];
}

export const initialState: TrackFilterState = {
  dates: {
    start: dayjs().subtract(30, 'days').unix(),
    end: dayjs().unix(),
  },
  resolution: { displayValue: 'per day', value: '1_day' },
  activeTab: 0,
  groups: [],
};

export const trackFilterSlice = createSlice({
  name: 'trackFilter',
  initialState,
  reducers: {
    setDate: (state: any, { payload }) => {
      state.dates = payload;
    },
    setResolution: (state: any, { payload }) => {
      state.resolution = payload;
    },
    setTab: (state: any, { payload }) => {
      state.activeTab = payload;
    },
    setGroup: (state: TrackFilterState, { payload }: { payload: UidName }) => {
      const isPayloadInGroups = state.groups?.map(({ id }) => id).includes(payload?.id);
      if (isPayloadInGroups) {
        state.groups = state.groups?.filter((group) => group.id !== payload.id);
      } else {
        state.groups = [...state.groups, payload];
      }
    },
    resetGroups: (state: TrackFilterState) => {
      state.groups = [];
    },
    setGroupsDropDown: (state: TrackFilterState, { payload }) => {
      state.groups = payload;
    },
  },
});

export const { setDate, setResolution, setTab, setGroup, resetGroups, setGroupsDropDown } =
  trackFilterSlice.actions;

export const trackFilterSelector = (state: RootState) => state.trackFilter;

export default trackFilterSlice.reducer;
