import { proxyApi } from 'redux/slices/proxyApi.slice';

export const userInviteApi = proxyApi.injectEndpoints({
  endpoints: (builder) => ({
    inviteUser: builder.mutation<any, any>({
      query: (data) => ({
        url: 'email',
        body: data,
        method: 'POST',
      }),
    }),
  }),
});

export const { useInviteUserMutation } = userInviteApi;
