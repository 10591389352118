import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { AnalyzeFilter } from 'interfaces/analyze';
import { CropSeason, Variety, Year } from 'interfaces/crop-season';
import { Location } from 'interfaces/location';
import { IdName } from 'interfaces/id-name';
import { Resolution } from 'interfaces/resolution';

import type { RootState } from 'redux/store';

interface AnalyzeState {
  isSidebarOpen: boolean;
  variables: AnalyzeFilter[];
  cropseasons: {
    previews: IdName[];
    locations: Location[];
    varieties: Variety[];
    years: Year[];
    selectedCropseasons: CropSeason[];
  };
  settings: {
    from: number;
    to: number;
    resolution: Resolution;
  };
  keyword: string;
  isAllvariableCollapsed: boolean;
  variableIndex: number;
  notificationsEnabled: boolean;
  groupPerHour: boolean;
}

const initialState = (): AnalyzeState => ({
  isSidebarOpen: true,
  variables: [],
  cropseasons: {
    previews: [],
    locations: [],
    varieties: [],
    years: [],
    selectedCropseasons: [],
  },
  settings: {
    from: dayjs().subtract(1, 'months').unix(),
    to: dayjs().unix(),
    resolution: Resolution.DAY_1,
  },
  keyword: '',
  isAllvariableCollapsed: true,
  variableIndex: 0,
  notificationsEnabled: false,
  groupPerHour: false,
});

export const analyzeSlice = createSlice({
  name: 'analyze',
  initialState: initialState(),
  reducers: {
    openSidebar: (state) => {
      state.isSidebarOpen = true;
    },
    closeSidebar: (state) => {
      state.isSidebarOpen = false;
    },
    setKeyword: (state, { payload }: { payload: string }) => {
      state.keyword = payload;
    },
    toggleCollapse: (state) => {
      state.isAllvariableCollapsed = !state.isAllvariableCollapsed;
    },
    addVariable: (state, { payload }: { payload: AnalyzeFilter }) => {
      if (!state.variables.some((variable) => variable.value === payload.value)) {
        state.variables.push(payload);
        state.variableIndex += 1;
      }
    },
    removeVariable: (state, { payload }: { payload: string }) => {
      state.variables = state.variables.filter((variable) => variable.value !== payload);
      if (!state.variables.length) {
        state.variableIndex = 0;
      }
    },
    updateVariable: (state, { payload }: { payload: AnalyzeFilter }) => {
      state.variables = state.variables.map((variable) =>
        variable.value === payload.value ? payload : variable
      );
    },
    removeAllVariables: (state) => {
      state.variables = [];
      state.variableIndex = 0;
    },
    setCropeasons: (state, { payload }) => {
      state.cropseasons = {
        ...state.cropseasons,
        ...payload,
      };
    },
    setSettings: (state, { payload }) => {
      state.settings = {
        ...state.settings,
        ...payload,
      };
    },
    resetValues: (state) => {
      state.cropseasons = initialState().cropseasons;
      state.settings = initialState().settings;
      state.variables = initialState().variables;
    },
    resetSelectedCropseason: (state) => {
      state.cropseasons.selectedCropseasons = initialState().cropseasons.selectedCropseasons;
    },
    toggleNotifications: (state) => {
      state.notificationsEnabled = !state.notificationsEnabled;
    },
    toggleGroupPerHour: (state) => {
      state.groupPerHour = !state.groupPerHour;
    },
  },
});

export const analyzeActions = analyzeSlice.actions;

export const analyzeSelectors = {
  raw: (state: RootState) => state.analyze,
  isSidebarOpen: (state: RootState) => state.analyze.isSidebarOpen,
  keyword: (state: RootState) => state.analyze.keyword,
  isAllvariableCollapsed: (state: RootState) => state.analyze.isAllvariableCollapsed,
  variables: (state: RootState) => state.analyze.variables,
  cropseason: (state: RootState) => state.analyze.cropseasons,
  settings: (state: RootState) => state.analyze.settings,
  variableIndex: (state: RootState) => state.analyze.variableIndex,
  notifications: (state: RootState) => state.analyze.notificationsEnabled,
  groupPerHour: (state: RootState) => state.analyze.groupPerHour,
};

export default analyzeSlice.reducer;
