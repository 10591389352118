import React, { useState } from 'react';

import styles from './ProfileNotifications.module.scss';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import {
  useUpdateUserSettingsMutation,
  useGetUserSettingsQuery,
} from 'services/userAccount.service';
import useTranslate from 'hooks/useTranslate';
import usePhoneNumber from 'hooks/usePhoneNumber';
import { useSearchParams } from 'react-router-dom';
import { TOAST_PROMISE } from 'config/constants';
import { dispatchPromiseToast } from 'utils/toast.util';

const ProfileNotifications: React.FC = () => {
  const translate = useTranslate();

  const [, setSearchParams] = useSearchParams();

  const { data } = useGetUserSettingsQuery();
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const { receive_emails, receive_push_message } = data;
  const [toggles, setToogles] = useState({
    receive_emails,
    receive_push_message,
  });

  const currentPhoneNumber = usePhoneNumber();

  const updateNotificationSettings = (input: string) => (value: boolean) => {
    const userSettingsMutationPromise = updateUserSettings({
      ...data,
      data: {
        [input]: value,
      },
    });

    dispatchPromiseToast({
      type: TOAST_PROMISE,
      promise: userSettingsMutationPromise,
      messageLoading: translate('Toast - UserNotifications - updating'),
      messageError: translate('Toast - UserNotifications - update - error'),
      messageSuccess: translate('Toast - UserNotifications - update - success '),
    });

    setToogles((previousValue) => ({
      ...previousValue,
      [input]: value,
    }));
  };

  const handleLinkNavigation = () => {
    setSearchParams({ activeTab: String(0) });
  };

  const getNoWhatsAppMessage = () => {
    const originalText = translate('Profile page - no phone number  disable whatsApp') as string;
    const linkTextPlaceholder = '$linkTextPlaceholder$';
    const endLinkTextPlaceholder = '$endLinkTextPlaceholder$';

    // Find the text before and after the placeholders
    const startIndex = originalText.indexOf(linkTextPlaceholder);
    const endIndex = originalText.indexOf(endLinkTextPlaceholder);

    if (startIndex !== -1 && endIndex !== -1) {
      const textBeforePlaceholder = originalText.substring(0, startIndex);
      const textAfterPlaceholder = originalText.substring(endIndex + endLinkTextPlaceholder.length);
      const textBetweenPlaceholders = originalText.substring(
        startIndex + linkTextPlaceholder.length,
        endIndex
      );

      return (
        <span>
          {textBeforePlaceholder}
          <span onClick={handleLinkNavigation} className={styles['link-disabled-text']}>
            {textBetweenPlaceholders}
          </span>

          {textAfterPlaceholder}
        </span>
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{translate('Profile page - subscribe notifications')}</div>
      <div className={styles.option}>
        <div className={styles['option-row']}>
          <div className={styles['option-title']}>{translate('Profile page - SMS - title')}</div>
          <div className={styles['option-description']}>
            {translate('Profile page - SMS - title description')}
            {!currentPhoneNumber && (
              <div className={styles['option-description-disabled-info']}>
                {getNoWhatsAppMessage()}
              </div>
            )}
          </div>
        </div>
        <div className={styles['option-toggle']}>
          <ToggleSwitch
            initialValue={currentPhoneNumber ? toggles.receive_push_message : false}
            onToggle={updateNotificationSettings('receive_push_message')}
            disabled={!currentPhoneNumber}
          />
        </div>
      </div>
      <div className={styles.option}>
        <div className={styles['option-row']}>
          <div className={styles['option-title']}>{translate('Profile page - email - title')}</div>
          <div className={styles['option-description']}>
            {translate('Profile page - email - title description')}
          </div>
        </div>
        <div className={styles['option-toggle']}>
          <ToggleSwitch
            initialValue={toggles.receive_emails}
            onToggle={updateNotificationSettings('receive_emails')}
          />
        </div>
      </div>
    </div>
  );
};
export default ProfileNotifications;
