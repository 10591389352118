import { useAppSelector } from 'redux/hooks';
import { userInfoSelector } from 'redux/slices/userInfo.slice';
import AuthService from 'services/auth.service';

export default function usePhoneNumber() {
    const { phoneNumber } = useAppSelector(userInfoSelector);
    const userDetails = AuthService.getUserDetails();

    if (phoneNumber) {
        return phoneNumber;
    } else if (phoneNumber === null) {
        //if phone number was just deleted old value will be still on the token until refresh so do no use it
        return '';
    } else {
        return userDetails?.phone_number ? '+' + userDetails?.phone_number : undefined;
    }

}
