import './BenchmarkGraph.scss';

import MultilineChart from 'components/GraphElements/MultilineChart/MultilineChart';
import {
  BENCHMARK_RIBBON_COLOR,
  VARIABLE_GRAPH_DECIMALS,
  VARIABLE_TOOLTIP_DECIMALS,
} from 'config/constants';
import useGetUnitForGraph from 'hooks/useGetUnitForGraph';
import { LegendItem } from 'interfaces/legend-item';
import { GraphLine } from 'interfaces/line-graph-data';
import { BENCHMARK_COLOR_COUNT, getBenchmarkColor } from 'utils/colors.util';
import { getDash } from 'utils/dashes.util';
import { getEnumValue } from 'utils/getEnumValue';
import { renderAreaData, renderLineData } from 'utils/renderDataInGraph';
import { hasExactYInterval } from 'utils/variables.util';
import { getUniqueId } from 'utils/getUniqueId';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import ClippingMask from 'components/GraphElements/ClippingMask/ClippingMask';
import dayjs from 'dayjs';
import { getYAxisInterval } from 'utils/getYAxisInterval';
import { getPlanToActualsMapper, getVariableName } from 'utils/plan-variables-mapper';

import { trackDataTogglesSelector } from 'redux/slices/graphDataToggleSwitches.slice';

interface BenchmarkGraphProps {
  actual: any;
  benchmarks: any;
  ribbon: any;
  variableKey: string;
  variableValue: string;
  resolution: string;
  dates: [number, number];
  legends?: LegendItem[];
  title?: string;
}

export const BenchmarkGraph: React.FC<BenchmarkGraphProps> = ({
  actual,
  benchmarks,
  ribbon,
  variableKey,
  variableValue,
  resolution,
  dates,
  legends,
}) => {
  const { selectedLanguage } = useAppSelector(languageSelector);
  const toggles = useAppSelector(trackDataTogglesSelector);

  let linesToDisplay: GraphLine[] = [];
  let benchmarkRanges: any = [];
  let areasToDisplay: any[] = [];
  let areasRanges: any = [];

  const customToolipProperties = {
    tooltipRangeLabel: 'Ribbon',
  };

  benchmarks?.forEach((benchMark: any) => {
    if (benchMark[variableValue]?.data) {
      linesToDisplay = linesToDisplay.concat(benchMark[variableValue]?.data);
      benchmarkRanges = benchmarkRanges.concat(benchMark[variableValue]?.range);
    }
  });

  let linesToDisplayWithDash = linesToDisplay.map((line, index) => ({
    ...line,
    stroke: line.stroke || getDash(Math.floor(index / (BENCHMARK_COLOR_COUNT + 1))),
    color: line.color || getBenchmarkColor(index),
  }));

  linesToDisplayWithDash.push({
    keyName: `${getPlanToActualsMapper(variableKey)}Actual`,
    color: '#fff',
    data: actual?.[getPlanToActualsMapper(variableKey)],
    unit: useGetUnitForGraph(variableValue),
    stroke: '',
  });

  linesToDisplayWithDash = linesToDisplayWithDash.filter((line) => toggles[line.color] !== false);

  const dataToDisplay: any[] = [...linesToDisplayWithDash];

  if (ribbon && toggles[BENCHMARK_RIBBON_COLOR] !== false) {
    Object?.values(ribbon)?.forEach((area: any, index: number) => {
      if (area?.data[variableValue]) {
        const id = getUniqueId();
        areasToDisplay.push({ data: area?.data[variableValue], color: BENCHMARK_RIBBON_COLOR });
        dataToDisplay.push({
          keyName: `value`,
          color: BENCHMARK_RIBBON_COLOR,
          data: area?.data[variableValue],
          prefix: 'Ribbon: ',
          type: 'area',
        });
        //TODO keep this implementation until variables based on resolution refactor
        areasRanges.push(area?.ranges[variableValue] || area?.ranges[variableKey]);
      }
    });
  }

  const getCalculatedYAxisInterval = (variableKey: any) => {
    return getYAxisInterval([
      actual?.ranges ? actual.ranges[variableKey] : [],
      benchmarkRanges ? benchmarkRanges[0] : [],
      areasRanges ? areasRanges[0] : [],
    ]);
  };
  const graphYInterval = getCalculatedYAxisInterval(getPlanToActualsMapper(variableKey));

  const maskId = getUniqueId();

  return (
    <div className='benchmark-graph graph'>
      <MultilineChart
        xAxisProperty='timestamp'
        dataToDisplay={dataToDisplay}
        title={getVariableName(variableKey, selectedLanguage)}
        hasTooltip={true}
        resolution={resolution}
        XInterval={[
          dayjs.unix(dates[0]).startOf('day').utc(true).unix(),
          dayjs.unix(dates[1]).startOf('day').utc(true).unix(),
        ]}
        YInterval={[{ min: graphYInterval[0], max: graphYInterval[1] }]}
        hasYIntervalExact={hasExactYInterval(variableKey)}
        yAxisUnit={useGetUnitForGraph(variableValue)}
        tooltipDecimals={getEnumValue(VARIABLE_TOOLTIP_DECIMALS, variableKey)}
        axisDecimals={getEnumValue(VARIABLE_GRAPH_DECIMALS, variableKey)}
        customToolipProperties={customToolipProperties}
        applyPadding={false}
        key={variableKey + getUniqueId()}
        maxHeight={400}
      >
        {[
          ...areasToDisplay
            ?.map(({ data, color, index }) =>
              renderAreaData({
                data,
                color,
                key: 'benchmark' + variableKey + index,
                hasMask: true,
                maskId: maskId,
              })
            )
            .flat(),
          <ClippingMask
            key={'benchmark-mask'}
            maskId={maskId}
            isInverted={true}
            data={{
              areaData: [
                {
                  timestamp: dayjs(dates[0] * 1000)
                    .startOf('day')
                    .utc(true)
                    .unix(),
                  value: graphYInterval,
                },
                {
                  timestamp: dayjs(dates[1] * 1000)
                    .endOf('day')
                    .utc(true)
                    .unix(),
                  value: graphYInterval,
                },
              ],
            }}
          ></ClippingMask>,

          ...linesToDisplayWithDash
            ?.map(({ keyName, data = [], color, stroke }) =>
              renderLineData({
                data: data,
                variable: keyName || '',
                color,
                stroke,
                hideLine: true,
                hasBoundaryMask: true,
                boundaryMaskId: maskId,
                maskLineColor: color,
              })
            )
            .flat(),
        ]}
      </MultilineChart>
    </div>
  );
};
