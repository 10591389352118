import Button from 'components/Button/Button';
import { DatePicker } from 'components/DatePicker/DatePicker';
import NumberInput from 'components/NumberInput/NumberInput';
import StaticInput from 'components/StaticInput/StaticInput';
import dayjs from 'dayjs';
import { ButtonVariantEnum } from 'interfaces/button';
import { TemperatureStrategy, TemperatureStrategyType } from 'interfaces/custom-settings';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { customPlanSelector, setCustomPlan } from 'redux/slices/customPlan.slice';
import { ReactComponent as CalendarIcon } from 'styles/icons/calendar.svg';
import { ReactComponent as CloseIcon } from 'styles/icons/close.svg';
import { ReactComponent as PlusIcon } from 'styles/icons/plus.svg';
import { track } from 'utils/analtycs';
import { roundToDecimals } from 'utils/roundToDecimals';
import { translate } from 'utils/translations.util';

import useTemperatureStrategies from '../customSettingsHooks/useTemperatureStrategies';
import styles from './TemperatureStrategy.module.scss';

const PHASE_TEMPERATURE_ITEMS = [
  {
    name: 'phase-outside-radiation',
    align: 'center',
    type: 'small',
    dependentOnLights: false,
    key: 'outside_rad',
  },
  {
    name: 'phase-al-hours',
    align: 'center',
    type: 'small',
    dependentOnLights: true,
    key: 'al_hours',
  },
  {
    name: 'phase-total-radiation',
    align: 'center',
    type: 'small',
    dependentOnLights: true,
    key: 'total_rad',
  },
];

const phaseDateStatic = {
  name: 'phase-date',
  align: 'left',
  icon: <CalendarIcon />,
  type: 'medium',
  dependentOnLights: false,
  key: 'phase_start',
};

const getHeaderText = (headerText: TemperatureStrategyType, index: number) => {
  const tempSumHeaderTexts = {
    phase_start: translate('Custom settings - Temperature Strategy - header - phase start'),
    outside_rad: translate('Custom settings - Temperature Strategy - header - outside radiation'),
    al_hours: translate('Custom settings - Temperature Strategy - header - hours AL'),
    total_rad: translate('Custom settings - Temperature Strategy - header - total radiation'),
    temp_air: translate('Custom settings - Temperature Strategy - header - temp air'),
  };
  if (index === 0) {
    return tempSumHeaderTexts[headerText];
  }
  return undefined;
};

interface TemperatureStrategyProps {
  temperatureInitialValues?: TemperatureStrategy[];
  hasLights?: boolean;
  temperatureStrategiesParams: any;
}

const TemperatureStrategies: React.FC<TemperatureStrategyProps> = ({
  temperatureInitialValues,
  hasLights = true,
  temperatureStrategiesParams,
}) => {
  const dispatch = useDispatch();
  const planTemperature = useAppSelector(customPlanSelector).customPlanState?.phase_temp_strategies;
  const updatedRows =
    useAppSelector(customPlanSelector).customPlanState?.phase_temp_strategies_new_dates;

  const { handleDateChange, handleRemoveRow } = useTemperatureStrategies(
    temperatureStrategiesParams
  );

  const [isDateValid, setIsDateValid] = useState(true);

  useEffect(() => {
    if (temperatureInitialValues) {
      dispatch(
        setCustomPlan({
          phase_temp_strategies: temperatureInitialValues,
        })
      );
    }
  }, [temperatureInitialValues, dispatch]);

  const handleTemperatureAirChange =
    (phase: number, temperatureIndex: number) => (currentValue: string) => {
      if (planTemperature) {
        track('custom settings', {
          field: 'temperatureStrategy row value',
          value: currentValue,
        });
        let updatedTemperatures = planTemperature.map((temp, index) => {
          if (index === temperatureIndex) {
            return {
              ...temp,
              temp_air: currentValue,
            };
          }
          return temp;
        });

        if (updatedRows) {
          const newUpdateDates = updatedRows.map((row) => {
            if (row.date === planTemperature[temperatureIndex].phase_start) {
              return {
                ...row,
                temp_air: currentValue,
                touched: true,
              };
            }
            return row;
          });

          dispatch(
            setCustomPlan({
              phase_temp_strategies_new_dates: newUpdateDates,
            })
          );
        }
        dispatch(
          setCustomPlan({
            phase_temp_strategies: updatedTemperatures,
            temperatureTouched: true,
          })
        );
      }
    };

  const renderStaticInput = (item: any, phase: any, index: number) => {
    let itemKey = item.key as TemperatureStrategyType;
    let currentValue = null;
    if (item.name === 'phase-date') {
      currentValue = 'Week ' + dayjs(phase.phase_start).week();
    } else {
      currentValue = roundToDecimals(phase[itemKey], 0);
    }
    if (!item.dependentOnLights || hasLights) {
      return (
        <StaticInput
          key={item.name + '-' + index}
          headerText={getHeaderText(item.key as TemperatureStrategyType, index)}
          icon={item.icon || undefined}
          align={item.align as 'center' | 'left' | 'right'}
          value={currentValue}
          type={item.type as 'small' | 'medium' | 'large'}
        ></StaticInput>
      );
    }
  };
  const handleAddEmptyRow = () => {
    if (planTemperature) {
      let newPhase = {
        phase: planTemperature.length + 1,
        phase_start: undefined,
        outside_rad: 0,
        al_hours: 0,
        total_rad: 0,
        temp_air: 0,
      };
      track('custom settings', {
        field: 'add temperatureStrategy row',
        value: newPhase.phase,
      });
      dispatch(
        setCustomPlan({
          phase_temp_strategies: [...planTemperature, newPhase],
        })
      );
    }
  };

  const isInvalidWeek = (date: any) => {
    return planTemperature?.some((row) => {
      return row.phase_start && dayjs(row.phase_start).week() === dayjs(date.start).week();
    });
  };
  const handleDatePickerChange = (index: number) => (date: any) => {
    const isInValid = isInvalidWeek(date);
    if (isInValid) {
      setIsDateValid(false);
    } else {
      track('custom settings', {
        field: 'temperatureStrategy row date',
        value: date?.start?.utc(true),
      });
      setIsDateValid(true);
      handleDateChange(index)(date);
    }
  };

  const handleRemoveTemperatureRow = (phase: any) => {
    track('custom settings', {
      field: 'remove temperatureStrategy row',
      value: phase?.phase,
    });
    setIsDateValid(true);
    handleRemoveRow(phase);
  };

  const renderDatePicker = (phase: any, index: number) => {
    const startDate = phase.phase_start ? dayjs(phase.phase_start) : undefined;
    return (
      <>
        <DatePicker
          weekMode
          startDate={startDate}
          dropDownPosition='dropdown-datepicker-middle dropdown-datepicker-top'
          onChange={handleDatePickerChange(phase.phase)}
          insideTooltip={true}
        />
      </>
    );
  };

  const isRowUpdated = (date: string) => {
    if (!updatedRows) return false;
    if (updatedRows) {
      return updatedRows.some((row) => row.date === date && !row.touched);
    }
  };

  const hasEmptyRow = () => {
    if (!planTemperature) return false;
    return planTemperature.some((row) => !row.phase_start);
  };

  const renderTemperatureStrategieRow = (phase: any, index: number) => {
    const firstRow = index === 0;
    const lastRow = planTemperature ? index === planTemperature.length - 1 : false;
    const isUpdated = isRowUpdated(phase.phase_start);

    const rowClassName = `${styles['temp-strategy']} ${
      firstRow ? styles['temp-strategy-first-row'] : ''
    } `;
    return (
      <div key={'temperature-strategies-' + index}>
        <div
          className={
            styles['temp-strategy-columns'] + ' ' + !hasLights
              ? styles['temp-strategy-columns-short']
              : ' '
          }
        >
          <div className={rowClassName}>
            <>
              <div className={styles['temp-strategy-date']}>
                {!firstRow && renderDatePicker(phase, index)}
                {!isDateValid && lastRow && (
                  <div className={styles['error-message']}>
                    {translate('Custom Settings - Validation - Date Invalid')}
                  </div>
                )}
              </div>
              {firstRow && renderStaticInput(phaseDateStatic, phase, index)}
              {PHASE_TEMPERATURE_ITEMS.map((item) => {
                return renderStaticInput(item, phase, index);
              })}
            </>
            <div>
              <NumberInput
                headerText={getHeaderText('temp_air', index)}
                initialValue={phase.temp_air}
                type='small'
                onChange={handleTemperatureAirChange(phase.phase, index)}
                numberOfDecimals={1}
                amount={0.1}
                isHighlighted={isUpdated}
              ></NumberInput>
            </div>
          </div>
          {!firstRow && (
            <div
              className={styles['close-icon-container']}
              onClick={() => handleRemoveTemperatureRow(phase)}
            >
              <CloseIcon className={`icon-light c-hand ${styles['close-icon']}`} />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles['flexible-temperature-container']}>
        <div>
          {planTemperature?.length &&
            planTemperature.map((phase: any, index) => renderTemperatureStrategieRow(phase, index))}
        </div>
        <Button
          id={'add-button'}
          styleType={ButtonVariantEnum.HOLLOW}
          disabled={hasEmptyRow()}
          onHandleClick={handleAddEmptyRow}
        >
          <PlusIcon className='icon-light plus-icon' /> {translate('button Add text')}
        </Button>
      </div>
    </div>
  );
};

export default TemperatureStrategies;
