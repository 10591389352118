import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { accountIdSelector, setAccountId } from 'redux/slices/accountId.slice';
import {
  useGetUserAccountIdQuery,
  useGetUserLinkedAccountsQuery,
} from 'services/userAccount.service';

import { IdName } from 'interfaces/id-name';
import { resetGroups } from 'redux/slices/trackFilterSlice';
import useUrlState from './useUrlState';
import { activeCropseasonInitialValue } from 'config/constants';
import { setActiveCropseason } from 'redux/slices/cropseason.slice';

export default function useSwitchAccountId() {
  const dispatch = useAppDispatch();
  const { data: linkedAccounts } = useGetUserLinkedAccountsQuery();
  const { data: id } = useGetUserAccountIdQuery();
  const [, setUrlState] = useUrlState({ accountId: '0' });
  let accountId = useAppSelector(accountIdSelector).accountId;

  useEffect(() => {
    if (linkedAccounts?.length) {
      const isAccountIdStillActive = linkedAccounts?.some(
        (account: IdName) => account.id === accountId
      );

      if (isAccountIdStillActive) {
        setUrlState({ accountId: String(accountId) });
        dispatch(setAccountId(accountId));
        dispatch(setActiveCropseason(activeCropseasonInitialValue));
      }

      dispatch(resetGroups());
      const hasNotLinkedAccount = !isAccountIdStillActive && linkedAccounts?.length > 0;

      if ((!accountId || hasNotLinkedAccount) && id) {
        setUrlState({ accountId: String(id) });
        dispatch(setAccountId(Number(id)));
        dispatch(setActiveCropseason(activeCropseasonInitialValue));
      }
    }
  }, [accountId, id, dispatch, linkedAccounts]);
}
