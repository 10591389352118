import { isMissing } from './general.util';

interface QueryStringData {
  [key: string]: undefined | null | string | string[] | number | boolean;
}

export const formatQueryString = (queryData: QueryStringData): string => {
  const queryString = new URLSearchParams();

  for (let queryParameter in queryData) {
    const queryValue = queryData[queryParameter];
    if (queryParameter && !isMissing(queryValue)) {
      if (Array.isArray(queryValue)) {
        queryValue.forEach((arrayElement: string) => {
          queryString.append(queryParameter, arrayElement);
        });
      } else {
        queryString.append(queryParameter, queryValue as string);
      }
    }
  }

  return queryString.toString();
};

export const hasQueryParam = (paramName: string = ''): boolean => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const hasQueryParam = urlParams.has(paramName);

  return hasQueryParam;
};

export const getQueryParam = (paramName: string = ''): string | null => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const queryParam = urlParams.get(paramName);

  return queryParam;
};
