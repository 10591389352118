import * as d3 from 'd3';
import React from 'react';

interface LineProps {
  data: any;
  property?: string;
  xScale?: any;
  yScale?: any;
  color?: any;
  strokeWidth?: number;
  transform?: string;
  stroke?: string;
  axis?: 'right' | 'left';
  xProperty?: string;
  maskId?: string;
  hasMask?: boolean;
  hideLine?: boolean;
  maskLineColor?: string;
  hasBoundaryMask?: boolean;
  boundaryMaskId?: string;
}

const Line: React.FC<LineProps> = ({
  data,
  xScale,
  yScale,
  color = '#fff',
  strokeWidth = 2,
  transform = '',
  property = '',
  stroke = '',
  axis = 'left',
  xProperty = 'timestamp',
  maskId,
  hasMask = false,
  hasBoundaryMask = false,
  boundaryMaskId,
  hideLine = false,
  maskLineColor,
  ...props
}) => {
  let d = '';
  const line = d3
    .line()
    .defined((d: any) => (d[property] || d[property] === 0) && (d[xProperty] || d[xProperty] === 0))
    .x((d: any) => xScale(d[xProperty]))
    .y((d: any) => yScale(d[property]));
  if (data?.length) {
    property = property || Object.keys(data[0])[1];
    d = line(data) || '';
  }

  return (
    <>
      {!hideLine && (
        <path
          d={d}
          stroke={color}
          strokeDasharray={stroke}
          strokeWidth={strokeWidth}
          fill='none'
          transform={transform}
        />
      )}
      {hasBoundaryMask && (
        <path
          d={d}
          stroke={color}
          strokeDasharray={stroke}
          strokeWidth={strokeWidth}
          fill='none'
          transform={transform}
          mask={`url(#${boundaryMaskId})`}
        />
      )}
      {hasMask && (
        <path
          d={d}
          stroke={maskLineColor || 'orange'}
          strokeDasharray={stroke}
          strokeWidth={strokeWidth}
          fill='none'
          transform={transform}
          mask={`url(#${maskId})`}
        />
      )}
    </>
  );
};
export default Line;
