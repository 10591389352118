import store from 'redux/store';
import { translate } from 'utils/translations.util';

// Method to replace activeCropseasonInitialValue from constats.ts
export const getActiveCropseasonIntitialValue = () => {
  let selectedLanguage = store.getState().language.selectedLanguage;
  return {
    id: 0,
    name: translate('Landing page CropSeason selector', selectedLanguage),
    account_id: 0,
    transplant_date: '',
    is_active: false,
    end_date: '',
    variety: {
      id: 0,
      name: '',
    },
    location: {
      id: 0,
      name: '',
    },
    tzone: '',
  };
}

export const getResolutionsInitialValues = () => {
  let selectedLanguage = store.getState().language.selectedLanguage;
  return [
    { displayValue: translate('Level 2 - time interval selector - 5mins', selectedLanguage), value: '5_min' },
    { displayValue: translate('Level 2 - time interval selector - hour', selectedLanguage), value: '1_hour' },
    { displayValue: translate('Level 2 - time interval selector - day', selectedLanguage), value: '1_day' },
    { displayValue: translate('Level 2 - time interval selector - week', selectedLanguage), value: '1_week' },
  ]
}

export const getResolutionTranslated = (resolution: string) => {
  let selectedLanguage = store.getState().language.selectedLanguage;
  switch (resolution) {
    case '5_min': {
      return { displayValue: translate('Level 2 - time interval selector - 5mins', selectedLanguage) }
    }
    case '1_hour': {
      return { displayValue: translate('Level 2 - time interval selector - hour', selectedLanguage) }
    }
    case '1_day': {
      return { displayValue: translate('Level 2 - time interval selector - day', selectedLanguage) }
    }
    case '1_week': {
      return { displayValue: translate('Level 2 - time interval selector - week', selectedLanguage) }
    }
    default: {
      return resolution;
    }
  }
}