import React from 'react';

const MAX_ANGLE = 180;
const MIN_ANGLE = 0;

const MAX_LIMIT = 471;
const MIN_LIMIT = 0;

interface IGaugeProps {
  min: number;
  max: number;
  value?: number;
  lowerTarget: number;
  upperTarget: number;
}

const Gauge: React.FC<IGaugeProps> = ({ min, max, value, lowerTarget, upperTarget }) => {
  if (value === undefined) {
    value = 0;
  }

  if (value < min) {
    value = min;
  }

  if (value > max) {
    value = max;
  }

  if (lowerTarget < min) {
    lowerTarget = min;
  }

  if (lowerTarget > max) {
    lowerTarget = max;
  }

  if (upperTarget < min) {
    upperTarget = min;
  }

  if (upperTarget > max) {
    upperTarget = max;
  }

  let angle = (value * MAX_ANGLE - MIN_ANGLE) / (max - min) || 0;

  let lowLimit = (lowerTarget * MAX_LIMIT - MIN_LIMIT) / (max - min) || 0;

  let upperLimit = (upperTarget * MAX_LIMIT - MIN_LIMIT) / (max - min) || 0;

  return (
    <svg height='100%' width='100%' viewBox='25 50 350 170'>
      <g id='gauge' transform={`rotate(180 200 210)`}>
        <circle
          id='low'
          r='150'
          cx='200'
          cy='200'
          stroke='#FF8947'
          strokeWidth='35'
          strokeDasharray='471, 943'
          fill='none'
        ></circle>
        <circle
          id='middle'
          r='150'
          cx='200'
          cy='200'
          stroke='#fff'
          strokeWidth='35'
          strokeDasharray={`${upperLimit}, 943`}
          fill='none'
        ></circle>
        <circle
          id='high'
          r='150'
          cx='200'
          cy='200'
          stroke='#FF8947'
          strokeWidth='35'
          strokeDasharray={`${lowLimit}, 943`}
          fill='none'
        ></circle>
        <circle
          id='sign'
          r='150'
          cx='200'
          cy='200'
          stroke='#132A37'
          strokeWidth='37'
          strokeDasharray='5, 943'
          fill='none'
          transform={`rotate(${angle} 200 200)`}
        ></circle>
      </g>
      <g id='pointer' transform={`rotate(${angle + 90} 200 210)`}>
        <circle id='base' r='10' cx='200' cy='210' fill='#fff'></circle>
        <circle id='end' r='1' cx='200' cy='300' fill='#fff'></circle>
        <polygon points='210,210 190,210 199,300 201,300' fill='#fff' />
      </g>
    </svg>
  );
};
export default Gauge;
