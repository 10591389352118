import useUrlState from 'hooks/useUrlState';

import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { VARIABLES_ICONS } from 'config/variable-icons';
import { AnalyzeOption } from 'interfaces/analyze';

import { Tooltip } from 'react-tooltip';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { analyzeActions, analyzeSelectors } from 'redux/slices/analyze.slice';
import { getAnalyzeColor } from 'utils/colors.util';
import useTranslate from 'hooks/useTranslate';

export default function useGetVariableOption() {
  const dispatch = useAppDispatch();
  const keyword = useAppSelector(analyzeSelectors.keyword);
  const variablesAll = useAppSelector(analyzeSelectors.variables);
  const [, setUrlState] = useUrlState<{ var: string[] }>({ var: [] });
  const selectedVariables = useAppSelector(analyzeSelectors.variables);
  const translate = useTranslate();

  const MAX_NR_VARIABLES_CHECKED = 20;

  useEffect(() => {
    setUrlState({ var: variablesAll.map((variable) => variable.value) });
  }, [variablesAll]);

  const currentVariableColors = useMemo(() => {
    return variablesAll.map((variable) => variable.color);
  }, [variablesAll]);

  const updateFilter = useCallback(
    (event: ChangeEvent<HTMLInputElement>, option: AnalyzeOption) => {
      const checked = event.target.checked;

      if (checked) {
        dispatch(
          analyzeActions.addVariable({
            color: getAnalyzeColor(currentVariableColors),
            label: option.label,
            value: option.id || option.value,
            unit: option?.unit,
            icon: VARIABLES_ICONS[option?.source],
            source: option?.source,
            scalingGroup: option?.scalingGroup,
          })
        );
      } else {
        dispatch(analyzeActions.removeVariable(option.value));
      }
    },
    [variablesAll.length]
  );
  const renderVariableOption = useCallback(
    (option: AnalyzeOption) => {
      //TODO - remove variable name when all variables have an Id
      const optionId = option.id || option.name;
      let disabled = false;

      let tooltipTextMax = translate('Analyze-max-selected-variables-text');

      if (selectedVariables.length > MAX_NR_VARIABLES_CHECKED) {
        disabled = !selectedVariables.some(({ value }) => value === option.value);
      }
      let tooltipText = !disabled ? `${option.label} - ${option.unit}` : tooltipTextMax;

      const tooltipKey = `tooltip-variable-long-name-${optionId.replace(/\s/g, '')} `;
      return (
        <div
          className={`element ${tooltipKey} ${
            !option.label.toLowerCase().includes(keyword.toLowerCase()) ? 'hidden' : ''
          } ${disabled ? 'element-disabled' : ''}`}
        >
          <label className='form-checkbox c-hand'>
            <input
              type='checkbox'
              checked={variablesAll.some(({ value }) => value === option.value)}
              onChange={(event) => updateFilter(event, option)}
              disabled={disabled}
            />
            <i className='form-icon'></i>
            <div className='element-content'>
              <span className='element-text'>
                {option.label} - {option.unit}
              </span>
            </div>
            <Tooltip
              anchorSelect={`.${tooltipKey}`}
              place='left'
              positionStrategy='fixed'
              delayShow={1500}
              variant='light'
              style={{ boxShadow: '0px 7px 20px rgba(26, 26, 26, 0.15)' }}
              opacity={1}
            >
              <span>{tooltipText}</span>
            </Tooltip>
          </label>
          {VARIABLES_ICONS[option.source]}
        </div>
      );
    },
    [keyword, updateFilter, variablesAll]
  );

  return { renderVariableOption };
}
