import React from 'react';

import { ReactComponent as CloseIcon } from 'styles/icons/close.svg';

import styles from './MultiSelectChips.module.scss';
import { Tooltip } from 'react-tooltip';
import { getUniqueId } from 'utils/getUniqueId';

interface MultiSelectChipsProps {
  chips: string[];
  isExpanded: boolean;
  onClear: (value: string) => void;
}

const MultiSelectChips: React.FC<MultiSelectChipsProps> = ({ chips, isExpanded, onClear }) => {
  const renderChip = (value: string) => {
    const id = getUniqueId();
    return (
      <div className={styles.chip} key={id}>
        <span
          className={styles['chips-content']}
          id={`tooltip-multi-select-chip-${id}`}
          data-tooltip-variant='light'
        >
          {value}
        </span>
        <CloseIcon
          className={`${styles.icon} c-hand`}
          onClick={(event: any) => {
            event.stopPropagation();
            onClear(value);
          }}
        />
        <Tooltip
          anchorSelect={`#tooltip-multi-select-chip-${id}`}
          place='top'
          style={{ zIndex: 100, display: 'flex', height: 'auto' }}
          opacity={1}
        >
          <div>{value}</div>
        </Tooltip>
      </div>
    );
  };

  const renderContent = () => {
    if (isExpanded) {
      return chips.map((chip) => renderChip(chip));
    } else {
      return (
        <div className={styles['closed-content']}>
          {renderChip(chips[0])}
          {chips.length > 1 && <div className={styles['details']}>+ {chips.length - 1} more</div>}
        </div>
      );
    }
  };
  return (
    <div className={`${styles.container} ${isExpanded && styles['add-padding']}`}>
      {renderContent()}
    </div>
  );
};
export default MultiSelectChips;
