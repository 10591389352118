import './LinkingStep.scss';

import UnLinkPlanToCropSeasonMessage from 'components/Dialogs/UnlinkPlan';
import CropSeasonSelector from 'components/Filters/CropSeason/CropSeasonSelector';
import { noCropSeason } from 'config/constants';
import dayjs from 'dayjs';
import { CropSeason } from 'interfaces/crop-season';
import { MetaData } from 'interfaces/meta-data';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { languageSelector } from 'redux/slices/language.slice';
import { planCreationSelector, setOption, setState } from 'redux/slices/planCreationSlice';
import { useGetCropseasonsQuery } from 'services/cropseason.service';
import { ModalService } from 'services/modal.service';
import { useGetPlanByCropSeasonIdMutation } from 'services/plans.service';
import { ReactComponent as Info } from 'styles/icons/info.svg';
import { track } from 'utils/analtycs';
import { translate } from 'utils/translations.util';

interface LinkingStepProps {
  varieties: MetaData[];
  rootstocks: MetaData[];
  locations: MetaData[];
}

const LinkingStep: React.FC<LinkingStepProps> = ({ varieties, rootstocks, locations }) => {
  const dispatch = useAppDispatch();
  const cropSeasonOptions: CropSeason[] = [];
  const { linkedCropseason, confirmedCropSeason } = useAppSelector(planCreationSelector);
  const { selectedLanguage } = useAppSelector(languageSelector);

  const accountId = useAppSelector(accountIdSelector).accountId;

  const { data: cropSeasons } = useGetCropseasonsQuery(accountId, { skip: !accountId });

  cropSeasonOptions.push(noCropSeason);
  cropSeasons?.map((cropSeason) => cropSeasonOptions.push(cropSeason));

  const [getPlanByCropseason] = useGetPlanByCropSeasonIdMutation();

  const handleSetOnChange = (value: CropSeason) => {
    dispatch(setOption({ confirmedCropSeason: false }));
    dispatch(setOption({ linkedCropseason: value }));
    if (value === noCropSeason) {
      dispatch(setOption({ confirmedCropSeason: true }));
      dispatch(
        setState({
          linkedCropseason: undefined,
          planCreationStarted: true,
        })
      );
    } else {
      track(`plan create step 1`, {
        field: 'cropseason',
        value: value?.name,
      });
    }
  };

  const getVarietyOption = useMemo(() => {
    return varieties.find((variety) => variety.name === linkedCropseason?.variety.name);
  }, [varieties, linkedCropseason?.variety.name]);

  const getLocationOption = useMemo(() => {
    return locations.find((location) => location.name === linkedCropseason?.location.name);
  }, [locations, linkedCropseason?.location.name]);

  const setOptionsBasedOnSelectedCropSeason = useCallback(() => {
    const plantingDate = dayjs(linkedCropseason?.transplant_date);
    const harvestDate = dayjs(linkedCropseason?.end_date);
    dispatch(
      setOption({
        variety: getVarietyOption,
        location: getLocationOption,
        plantingDate: dayjs(linkedCropseason?.transplant_date).unix(),
        headRemovalDate: dayjs(linkedCropseason?.transplant_date).add(40, 'weeks').unix(),
        sowingDate: dayjs(linkedCropseason?.transplant_date).subtract(80, 'day').unix(),
        firstSettingDate: dayjs(linkedCropseason?.transplant_date).add(23, 'day').unix(),
      })
    );
    if (dayjs(harvestDate).diff(plantingDate, 'years') <= 2) {
      dispatch(setOption({ harvestDate: dayjs(linkedCropseason?.end_date).unix() }));
    } else {
      dispatch(
        setOption({ harvestDate: dayjs(linkedCropseason?.transplant_date).add(50, 'weeks').unix() })
      );
    }
  }, [
    dispatch,
    linkedCropseason?.end_date,
    linkedCropseason?.transplant_date,
    getLocationOption,
    getVarietyOption,
  ]);

  useEffect(() => {
    if (linkedCropseason?.id && !confirmedCropSeason) {
      getPlanByCropseason({ accountId: accountId, cropSeasonId: linkedCropseason.id })
        .unwrap()
        .then((response) => {
          if (response) {
            ModalService.open({
              title: 'Change linked Crop Plan',
              saveText: 'Change',
              cancelText: 'Cancel',
              width: '495px',
              content: <UnLinkPlanToCropSeasonMessage name={linkedCropseason.name} />,
              confirmActionTitle: translate(
                'Plan edit - link plan to cropseason warning header',
                selectedLanguage
              ),
              closeAction: (isConfirmed) => {
                if (!isConfirmed) {
                  dispatch(setOption({ linkedCropseason: undefined }));
                } else {
                  setOptionsBasedOnSelectedCropSeason();
                  dispatch(
                    setOption({
                      confirmedCropSeason: true,
                      touchedFirstSettingDate: false,
                      touchedHarvestDate: false,
                      touchedSowingDate: false,
                      touchedHeadRemovalDate: false,
                    })
                  );
                }
              },
            });
          } else {
            setOptionsBasedOnSelectedCropSeason();
            dispatch(setOption({ confirmedCropSeason: true }));
          }
        });
    }
  }, [
    linkedCropseason,
    accountId,
    getPlanByCropseason,
    dispatch,
    selectedLanguage,
    confirmedCropSeason,
    setOptionsBasedOnSelectedCropSeason,
  ]);

  return (
    <div className='variety-step'>
      <div className='step-title'>
        {translate('Create Plan - step 0 link to cropseason', selectedLanguage)}
        <Info
          width={20}
          height={20}
          className='icon-light c-hand'
          id={`tooltip-anchor-create-plan-link`}
          data-tooltip-variant='light'
        />
        <Tooltip
          anchorSelect={`#tooltip-anchor-create-plan-link`}
          place='right'
          style={{ zIndex: 10, width: '295px', display: 'flex', height: 'auto' }}
          opacity={1}
        >
          <div className='plan-settings-details'>
            {translate('Information hovver: link Plan to CropSeason', selectedLanguage)}
          </div>
        </Tooltip>
      </div>

      <CropSeasonSelector
        hasDefaultCapability={false}
        cropSeasonSelectorTitle={translate('Create Plan - Your CropSeason', selectedLanguage)}
        setOnChange={handleSetOnChange}
        initialCropSeason={noCropSeason}
        hasNoCropSeasonOption={true}
        selectedOption={linkedCropseason}
      />
    </div>
  );
};

export default LinkingStep;
