import NumberInput from 'components/NumberInput/NumberInput';
import useTranslate from 'hooks/useTranslate';
import { ECIrrigation } from 'interfaces/custom-settings';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { customPlanSelector, setCustomPlan } from 'redux/slices/customPlan.slice';
import { languageSelector } from 'redux/slices/language.slice';
import { track } from 'utils/analtycs';
import { ECValidation } from 'utils/customSettngsValidation';

import styles from './ECIrrigation.module.scss';

type ECIrrigationProps = {
  ECIrrigation: ECIrrigation;
};
const ECIrrigationComponent: React.FC<ECIrrigationProps> = ({ ECIrrigation }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const ECData = useAppSelector(customPlanSelector).customPlanState?.EC;
  const { selectedLanguage } = useAppSelector(languageSelector);

  useEffect(() => {
    if (ECIrrigation) {
      dispatch(
        setCustomPlan({
          EC: {
            min: ECIrrigation?.min,
            max: ECIrrigation?.max,
          },
        })
      );
    }
  }, [ECIrrigation, dispatch]);

  const handleECmax = (value: number) => {
    track('custom settings', {
      field: 'ECmax',
      value,
    });
    dispatch(
      setCustomPlan({
        EC: {
          ...ECData,
          max: value,
        },
      })
    );
  };

  const handleECmin = (value: number) => {
    track('custom settings', {
      field: 'ECmin',
      value,
    });
    dispatch(
      setCustomPlan({
        EC: {
          ...ECData,
          min: value,
        },
      })
    );
  };

  const displayECError = () => {
    if (ECData) {
      return ECValidation(ECData.min, ECData.max, selectedLanguage);
    }
  };

  if (!ECData) {
    return null;
  }

  return (
    <div>
      <div className={styles['EC-row']}>
        <span>{translate('Custom settings - EC - max')}</span>
        <NumberInput
          headerText={'EC'}
          initialValue={ECData.max}
          type='medium'
          onChange={(value: string) => handleECmax(Number(value))}
          numberOfDecimals={2}
          amount={0.01}
        ></NumberInput>
      </div>
      <div className={styles['EC-row']}>
        <span>{translate('Custom settings - EC - min')}</span>
        <NumberInput
          headerText={'EC'}
          initialValue={ECData.min}
          type='medium'
          onChange={(value: string) => handleECmin(Number(value))}
          numberOfDecimals={2}
          amount={0.01}
        ></NumberInput>
      </div>
      <div className={styles['EC-error']}>{displayECError()}</div>
    </div>
  );
};

export default ECIrrigationComponent;
