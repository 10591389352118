import LegendGraphDataToggleSwitches from 'components/Track/LegendGraphDataToggleSwitches/LegendGraphDataToggleSwitches';
import PlanGraph from 'components/Track/PlanGraph/PlanGraph';
import {
  activeCropseasonInitialValue,
  PRODUCTION_RESOLUTION,
  VARIABLES_PRODUCTION,
} from 'config/constants';
import dayjs from 'dayjs';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { useGetAdaptivePlanData } from 'hooks/useGetAdaptivePlanData';
import { useGetDataForPlan } from 'hooks/useGetDataForPlan';
import useGetPlanLegends from 'hooks/usePlanLegends';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { cropseasonSelector } from 'redux/slices/cropseason.slice';
import { sideBarSelector } from 'redux/slices/sidebar.slice';
import { trackFilterSelector } from 'redux/slices/trackFilterSlice';
import { useGetActualsQuery } from 'services/actuals.service';
import {
  useGetGuardrailsQuery,
  useGetGuardrailVariableStatusQuery,
} from 'services/guardrails.service';
import { getFooterSizeClass } from 'utils/general.util';
import { getGuardrailsDataBasedOnPlan } from 'utils/getGuardrailsBasedOnPlan';
import { getPlanVariableIds } from 'utils/plans.util';
import { getVariablesStatusWarning, getVariablesDaysWeekResolution } from 'utils/variables.util';

interface PlanProps {}

const Plan: React.FC<PlanProps> = () => {
  const { dates, resolution } = useAppSelector(trackFilterSelector);
  const { id: cropseasonId } = useAppSelector(cropseasonSelector);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const productionVariables = getVariablesDaysWeekResolution(VARIABLES_PRODUCTION);
  const { enabledGuardrailsBasedOnXPercent } = useFeatureFlags();
  const isOpen = useAppSelector(sideBarSelector).isOpen;

  const { data, isSuccess } = useGetActualsQuery(
    {
      cropSeasonId: cropseasonId,
      startDate: dayjs.unix(dates.start).toISOString(),
      endDate: dayjs.unix(dates.end).toISOString(),
      resolution: PRODUCTION_RESOLUTION,
      variables: VARIABLES_PRODUCTION,
      accountId: accountId,
    },
    {
      skip:
        !cropseasonId ||
        (cropseasonId === activeCropseasonInitialValue.id && dates.end < dates.start) ||
        !accountId,
    }
  );

  const { data: guardrail, isSuccess: isGuardRailSuccess } = useGetGuardrailsQuery(
    {
      cropSeasonId: cropseasonId,
      startDate: dayjs.unix(dates.start).toISOString(),
      endDate: dayjs.unix(dates.end).toISOString(),
      resolution: PRODUCTION_RESOLUTION,
      variables: VARIABLES_PRODUCTION,
      accountId: accountId,
    },
    {
      skip:
        cropseasonId === activeCropseasonInitialValue.id ||
        dates.end < dates.start ||
        !accountId ||
        enabledGuardrailsBasedOnXPercent,
    }
  );

  const { data: variableStatus } = useGetGuardrailVariableStatusQuery(
    {
      cropseasonId,
      accountId,
      varDay: productionVariables.varDay,
      varWeek: productionVariables.varWeek,
    },
    {
      skip: !cropseasonId || !accountId,
    }
  );

  const mappedVariablesToPlanID = getPlanVariableIds(VARIABLES_PRODUCTION);
  const planData = useGetDataForPlan(mappedVariablesToPlanID);

  const adaptivePlan = useGetAdaptivePlanData(VARIABLES_PRODUCTION, data?.lastTimestamp);

  let guardrailBasedOnPlan = getGuardrailsDataBasedOnPlan(
    planData.plan,
    mappedVariablesToPlanID,
    enabledGuardrailsBasedOnXPercent
  );
  let guardrailData = guardrail || guardrailBasedOnPlan;

  const legendItems = useGetPlanLegends(
    VARIABLES_PRODUCTION,
    guardrailData,
    planData,
    data,
    adaptivePlan,
    mappedVariablesToPlanID
  );

  return (
    <div className='graph-container plan-graph'>
      {Object.entries(VARIABLES_PRODUCTION).map(([key, value]) => {
        let newClassName = getVariablesStatusWarning(variableStatus, value);

        return (
          <div className={`line-graph-container ${newClassName}`} key={key}>
            <PlanGraph
              actual={isSuccess ? data : undefined}
              guardrail={guardrailData}
              plan={planData.plan}
              planDetails={planData.planDetails}
              variableKey={key}
              variableValue={value}
              resolution={PRODUCTION_RESOLUTION}
              dates={[dates.start, dates.end]}
              adaptivePlan={adaptivePlan}
            />
          </div>
        );
      })}
      {legendItems && (
        <div className={`fixed-footer-legend plan-legend ${getFooterSizeClass(isOpen)}`}>
          <LegendGraphDataToggleSwitches legendItems={legendItems} showFullItem={true} />
        </div>
      )}
    </div>
  );
};
export default Plan;
