import useTranslate from 'hooks/useTranslate';

export default function UseGetNotificationSettings() {
  const translate = useTranslate();
  //CORRECTION FACTOR
  const CORRECTION_FACTORS_LIST = [
    {
      value: '0.95',
      displayValue: '5% ' + translate('notification-prefferences-disorder-more-sensitive'),
    },
    {
      value: '0.96',
      displayValue: '4% ' + translate('notification-prefferences-disorder-more-sensitive'),
    },
    {
      value: '0.97',
      displayValue: '3% ' + translate('notification-prefferences-disorder-more-sensitive'),
    },
    {
      value: '0.98',
      displayValue: '2% ' + translate('notification-prefferences-disorder-more-sensitive'),
    },
    {
      value: '0.99',
      displayValue: '1% ' + translate('notification-prefferences-disorder-more-sensitive'),
    },
    { value: '0', displayValue: translate('notification-prefferences-disorder-default') },
    {
      value: '0.01',
      displayValue: '1% ' + translate('notification-prefferences-disorder-less-sensitive'),
    },
    {
      value: '0.02',
      displayValue: '2% ' + translate('notification-prefferences-disorder-less-sensitive'),
    },
    {
      value: '0.03',
      displayValue: '3% ' + translate('notification-prefferences-disorder-less-sensitive'),
    },
    {
      value: '0.04',
      displayValue: '4% ' + translate('notification-prefferences-disorder-less-sensitive'),
    },
    {
      value: '0.05',
      displayValue: '5% ' + translate('notification-prefferences-disorder-less-sensitive'),
    },
  ];

  return CORRECTION_FACTORS_LIST;
}
