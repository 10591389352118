import React, { useEffect, useMemo } from 'react';
import styles from './YieldPrediction.module.scss';
import CropSeasonSelector from 'components/Filters/CropSeason/CropSeasonSelector';
import MultilineChart from 'components/GraphElements/MultilineChart/MultilineChart';

import { useGetActualsQuery } from 'services/actuals.service';
import dayjs from 'dayjs';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { cropseasonSelector } from 'redux/slices/cropseason.slice';
import {
  RESOLUTION,
  TOAST_PROMISE,
  YIELD_PAGE_CHIPS,
  activeCropseasonInitialValue,
} from 'config/constants';
import { renderLineData } from 'utils/renderDataInGraph';

import useGetUnitForGraph from 'hooks/useGetUnitForGraph';
import Chip from 'components/Chip/Chip';
import useTranslate from 'hooks/useTranslate';
import { useGetYieldPreditionMutation } from 'services/prediction.service';
import { roundToDecimals } from 'utils/roundToDecimals';
import { dispatchPromiseToast } from 'utils/toast.util';
import { Range } from 'interfaces/range';
import { getWeeksBetweenDates } from 'utils/time.util';
import { track } from 'utils/analtycs';

interface YieldPredictionProps {}

const YieldPrediction: React.FC<YieldPredictionProps> = () => {
  const today = dayjs().subtract(1, 'week').unix();
  const last4Weeks = dayjs().isoWeekday(1).subtract(5, 'week').startOf('day').unix();
  const following3Weeks = dayjs.unix(today).add(3, 'week').startOf('week').unix();

  const startDate = dayjs.unix(last4Weeks).toISOString();
  const endDate = dayjs.unix(today).toISOString();
  const translate = useTranslate();

  const [yieldData, setYieldData] = React.useState<any>([]);

  const accountId = useAppSelector(accountIdSelector).accountId;

  const { id: cropseasonId } = useAppSelector(cropseasonSelector);

  const isInitialYieldData = true;

  const predefinedYIntervals = [
    [0, 2],
    [0, 4],
    [0, 6],
  ];

  const { data } = useGetActualsQuery(
    {
      cropSeasonId: cropseasonId,
      startDate: startDate,
      endDate: endDate,
      resolution: RESOLUTION._1_week,
      variables: { yield: 'yield' },
      accountId: accountId,
    },
    {
      skip: !cropseasonId || cropseasonId === activeCropseasonInitialValue.id || !accountId,
    }
  );

  const [getYieldPrediction, { data: yieldPrediction, reset, isLoading }] =
    useGetYieldPreditionMutation();

  const getCompleted6WeeksYieldData = useMemo((): {
    timestamp: number;
    weekNumber: number;
    yieldActual: any;
  }[] => {
    let completed6WeeksData: {
      timestamp: number;
      weekNumber: any;
      yieldActual: any;
    }[] = [];
    let weeks = getWeeksBetweenDates(last4Weeks, today);

    if (data?.yield && data.yield.length > 0) {
      data.yield.forEach((item: any) => {
        if (weeks.some((week) => week.weekNumber === dayjs.unix(item.timestamp).isoWeek())) {
          completed6WeeksData.push({
            timestamp: item.timestamp,
            weekNumber: dayjs.unix(item.timestamp).isoWeek(),
            yieldActual: item.yieldActual,
          });
        }
      });
    } else {
      weeks.forEach((week) => {
        completed6WeeksData.push({
          timestamp: dayjs(week.start).unix(),
          weekNumber: week.weekNumber,
          yieldActual: null,
        });
      });
    }

    return completed6WeeksData;
  }, [data, last4Weeks, today]);

  const getDataToDisplay = useMemo(() => {
    let dataToDisplay = [
      {
        data: getCompleted6WeeksYieldData,
        keyName: 'yieldActual',
        color: '#fff',
        stroke: '0',
        unit: translate('kg/m^2'),
      },
    ];

    if (yieldPrediction) {
      dataToDisplay.push({
        data: yieldPrediction.data,
        keyName: 'yieldPrediction',
        color: YIELD_PAGE_CHIPS.YIELD_PREDICTION.color,
        stroke: '0',
        unit: translate('kg/m^2'),
      });
    }
    return dataToDisplay;
  }, [getCompleted6WeeksYieldData, translate, yieldPrediction]);

  useEffect(() => {
    if (isInitialYieldData) {
      setYieldData(getCompleted6WeeksYieldData);
    }
  }, [data, isInitialYieldData, getCompleted6WeeksYieldData]);

  useEffect(() => {
    reset();
  }, [cropseasonId, accountId]);

  const calculatePrediction = async () => {
    track('calculate yield prediction', { cropseasonId, accountId });
    const predictionPromise = getYieldPrediction({
      cropseason_id: cropseasonId,
      current_account_id: accountId,
    });

    dispatchPromiseToast({
      type: TOAST_PROMISE,
      promise: predictionPromise,
      messageLoading: translate('Yield-Prediction-generate-progress'),
      messageError: translate('Yield-Prediction-generate-fail'),
      messageSuccess: translate('Yield-Prediction-generate-success'),
    });
  };

  const renderGraphElements = useMemo(() => {
    let graphElements = [];
    graphElements.push(
      ...renderLineData({
        data: yieldData,
        variable: 'yieldActual',
        color: '#fff',
      })
    );
    if (yieldPrediction) {
      graphElements.push(
        ...renderLineData({
          data: yieldPrediction.data,
          variable: 'yieldPrediction',
          color: YIELD_PAGE_CHIPS.YIELD_PREDICTION.color,
        })
      );
    }
    return graphElements;
  }, [yieldData, yieldPrediction]);

  /**
   * This function calculates the Y interval for the graph based on the max value of the actual yield and the prediction yield
   * and returns best matching interval from predefinedYIntervals
   * @returns {min: number, max: number}
   */
  const getYInterval = (): Range => {
    let maxYield = 0;
    let maxYieldPrediction = null;
    if (yieldData.length > 0) {
      maxYield = Math.max(...yieldData.map((item: any) => item.yieldActual));
    }
    if (yieldPrediction) {
      maxYieldPrediction = yieldPrediction.range.max || 0;
    }

    let max = maxYieldPrediction ? Math.max(maxYield, maxYieldPrediction) : maxYield;

    let yInterval = predefinedYIntervals.find(([start, end]) => max >= start && max < end) || [
      0, 7,
    ];

    return {
      min: yInterval[0],
      max: yInterval[1],
    };
  };

  return (
    <div className={styles['page-container']}>
      <div className={styles['page-header']}>
        <div className={styles['page-header-title']}>
          {translate('Yield-Prediction-Page-Title')}
        </div>
        <div className={styles['page-header-filters']}>
          <CropSeasonSelector />
        </div>
      </div>
      <div className={styles['yield-graph']}>
        <div className={styles['chips-row']}>
          <Chip chip={YIELD_PAGE_CHIPS.YIELD} hasClose={false}></Chip>
          <Chip chip={YIELD_PAGE_CHIPS.YIELD_PREDICTION} hasClose={false}></Chip>
        </div>
        <div className={styles['yield-graph-container']}>
          <MultilineChart
            dataToDisplay={getDataToDisplay}
            resolution={RESOLUTION._1_week}
            xAxisProperty='timestamp'
            title='YieldPrediction'
            YInterval={[getYInterval()]}
            hasYIntervalExact={true}
            XInterval={[last4Weeks, following3Weeks]}
            hasTooltip={true}
            showTitle={false}
            yOffset={20}
            enforceTicksStartOfDay={true}
            applyPadding={false}
            XTickPadding={30}
            yAxisUnit={useGetUnitForGraph('yield')}
            tooltipDecimals={2}
          >
            {renderGraphElements}
          </MultilineChart>
        </div>
      </div>
      <div className={styles['yield-data-container']}>
        <div className={styles['yield-data-container-actions']}>
          <button
            className={`btn new-plan-btn c-hand ${
              isLoading ? styles['disabled-calculate-btn'] : 'btn-primary'
            }`}
            onClick={calculatePrediction}
          >
            {translate('Yield-Prediction-Calculate-Prediction-Button')}
          </button>
        </div>
        <div className={styles['input-containers']}>
          <div>
            <span className={styles['yield-data-title']}>
              {translate('Yield-Prediction-Calculate-Prediction--Table-Title')}
            </span>
            <div className={styles['yield-data']}>
              {yieldData.map((item: any, index: number) => (
                <div className={styles['yield-data-row']} key={item.timestamp}>
                  <div className={styles['weekNumber']}>{item.weekNumber}</div>
                  <span className={styles['number-input-label']}>
                    {index === 0 ? 'kg/m2/week' : ''}
                  </span>
                  <div className={styles.weekNumber}>
                    {roundToDecimals(item.yieldActual, 2) || '-'}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {yieldPrediction && (
            <div>
              <span className={styles['yield-data-title']}>
                {translate('Yield-Prediction-Calculate-3-week-prediction')}
              </span>
              <div className={styles['yield-data']}>
                {yieldPrediction?.data?.map((item: any) => (
                  <div
                    className={`${styles['yield-data-row']} ${styles['yield-prediction-row']}`}
                    key={item.timestamp}
                  >
                    <div className={styles['weekNumber']}>{item.weekNumber}</div>
                    <div className={styles['weekNumber']}>
                      {roundToDecimals(item.yieldPrediction, 2)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default YieldPrediction;
