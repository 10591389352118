import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'redux/store';

interface planComparisonData {
  variable: string;
  name: string;
  values: [];
  unit: string;
}

interface PlanComparisonState {
  compareVariables: planComparisonData[];
}

const initialState = (): PlanComparisonState => ({
  compareVariables: [],
});

export const planComparisonSlice = createSlice({
  name: 'planComparison',
  initialState: initialState(),
  reducers: {
    addCompareVariable: (state, { payload }: { payload: any }) => {
      state.compareVariables.push(payload);
    },
    removeCompareVariable: (state, { payload }: { payload: any }) => {
      state.compareVariables = state.compareVariables.filter((item) => item.variable !== payload);
    },
    removeCompareAllVariable: (state) => {
      state.compareVariables = [];
    },
  },
});

export const planComparisonActions = planComparisonSlice.actions;

export const planComparisonSelectors = {
  raw: (state: RootState) => state.planComparison,
  compareVariables: (state: RootState) => state.planComparison.compareVariables,
};

export default planComparisonSlice.reducer;
