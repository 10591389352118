import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from 'services/auth.service';

export const proxyApi = createApi({
  reducerPath: 'proxyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PROXY_API_PATH,
    prepareHeaders: async (headers) => {
      if (AuthService.isTokenExpired()) {
        await AuthService.updateToken2();
      }
      headers.set('Authorization', `Bearer ${AuthService.getToken()}`);
      return headers;
    },
  }),
  endpoints: () => ({}),
});
