import { useCallback, useEffect, useState } from 'react';

interface UseDragResult {
  handleDragStart: (event: React.MouseEvent) => void;
}

export default function useDrag(
  onDragLeft = (speed: number) => {},
  onDragRight = (speed: number) => {}
): UseDragResult {
  const [isDraggingStarted, setIsDraggingStarted] = useState(false);
  const [dragValueStart, setDragValueStart] = useState(0);

  const classNameIsDragging = 'isDragging';

  const handleDrag = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault();
      if (isDraggingStarted) {
        document.body.classList.add(classNameIsDragging);
        setDragValueStart(event.clientX);
        if (event.clientX >= dragValueStart) {
          let speed = event.clientX - dragValueStart;
          onDragRight(speed);
        } else if (event.clientX < dragValueStart) {
          let speed = dragValueStart - event.clientX;
          onDragLeft(speed);
        }
      }
    },
    [isDraggingStarted, dragValueStart, onDragLeft, onDragRight]
  );

  const handleDragStart = useCallback((event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggingStarted(true);
    setDragValueStart(event.clientX);
  }, []);

  const handleDragEnd = useCallback((event: MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    document.body.classList.remove(classNameIsDragging);
    setIsDraggingStarted(false);
  }, []);

  useEffect(() => {
    if (isDraggingStarted) {
      window.addEventListener('mousemove', handleDrag);
      window.addEventListener('mouseup', handleDragEnd);
      return () => {
        window.removeEventListener('mousemove', handleDrag);
        window.removeEventListener('mouseup', handleDragEnd);
      };
    }
  }, [isDraggingStarted, dragValueStart, handleDrag, handleDragEnd]);

  return { handleDragStart };
}
