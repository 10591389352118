import React, { useState, useEffect, useCallback } from 'react';

import Input from 'components/Input/Input';
import DropDown from 'components/DropDown/DropDown';

import AuthService from 'services/auth.service';
import { isBetweenRange } from 'validators/plans.validator';

import styles from './PersonalSettings.module.scss';
import { isValidEmail } from 'validators/email.validator';
import { useUpdateUserInfoMutation } from 'services/user.service';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT_MOBILE, DEFAULT_LANGUAGE } from 'config/constants';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setUserInfo, userInfoSelector } from 'redux/slices/userInfo.slice';
import { languageSelector, setLanguage } from 'redux/slices/language.slice';
import useTranslate from 'hooks/useTranslate';
import usePhoneNumber from 'hooks/usePhoneNumber';
import availableLangauages from 'config/locales/available-languages.json';
import Prompt from 'components/Prompt/Prompt';
import { translate } from 'utils/translations.util';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/min';
import 'react-phone-number-input/style.css';
import { useUpdateUserPhoneMutation } from 'services/phoneNumber.service';
import tokenUpdate from 'services/tokenUpdate.service';
import { dispatchToast } from 'utils/toast.util';
import { MutationResult } from 'interfaces/mutation';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { ToastTypes } from 'interfaces/toast';
const ConfirmSavedChanges: React.FC<{ touched?: boolean }> = ({ touched = false }) => {
  const { selectedLanguage } = useAppSelector(languageSelector);
  return <div>{translate('Your changes have been saved successfully', selectedLanguage)}</div>;
};
interface FormErrors {
  errorMessage?: string;
}

const PersonalSettings: React.FC = () => {
  const userDetails = AuthService.getUserDetails();
  const { familyName, givenName, email } = useAppSelector(userInfoSelector);
  const { selectedLanguage } = useAppSelector(languageSelector);
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const [dataHasChanged, setDataHasChanged] = useState(false);

  function findUserLocale() {
    return availableLangauages.find((locale) => locale.value === selectedLanguage);
  }

  const currentPhoneNumber = usePhoneNumber();

  const [formValues, setFormValues] = useState({
    userName: userDetails?.preferred_username,
    firstName: givenName,
    lastName: familyName,
    email: email,
    phoneNumber: currentPhoneNumber,
    language: findUserLocale() || DEFAULT_LANGUAGE,
  });

  const [updateUser] = useUpdateUserInfoMutation();
  const [updatePhoneNumber] = useUpdateUserPhoneMutation();
  const { width }: WindowSize = useWindowSize();

  const [formErrors, setFormErrors] = useState({
    userName: {} as FormErrors,
    firstName: {} as FormErrors,
    lastName: {} as FormErrors,
    email: {} as FormErrors,
    phoneNumber: {} as FormErrors,
  });

  const [currentInput, setCurrentInput] = useState<string>();

  const handleOnChange = (name: string) => (input: string) => {
    setDataHasChanged(true);
    setFormValues({ ...formValues, [name]: input });
    setCurrentInput(name);
  };

  const setErrorMessage = useCallback(
    (field: string, errorMessage?: string) => {
      setFormErrors({
        ...formErrors,
        [field]: {
          errorMessage,
        },
      });
    },
    [formErrors]
  );

  const handleSubmit = async () => {
    let isFormValid = Object.values(formErrors).every((error) => !error.errorMessage);
    try {
      if (isFormValid) {
        const userMutationPromise = updateUser({
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          email: formValues.email,
          locale: formValues.language?.value!,
        });

        const phoneNumberMutationPromise = updatePhoneNumber({
          user: formValues.userName,
          phoneNumber: formValues.phoneNumber || '',
        });

        tokenUpdate.setTokenUpdate();
        await Promise.all([userMutationPromise, phoneNumberMutationPromise])
          .then(
            (
              results: Array<{
                data?: MutationResult<any>;
                error?: FetchBaseQueryError | SerializedError;
              }>
            ) => {
              let showError = false;
              results.forEach((result) => {
                if (result.error) {
                  showError = true;
                  dispatchToast({
                    type: ToastTypes.error,
                    message: translate('Toast - UserProfile - error update'),
                  });
                }
              });
              !showError &&
                dispatchToast({
                  type: ToastTypes.success,
                  message: translate('Toast - UserProfile - sucess update'),
                });
            }
          )
          .catch((error) => {
            console.error('Error:', error);
            dispatchToast({
              type: ToastTypes.error,
              message: translate('Toast - UserProfile - error update'),
            });
          });

        dispatch(setLanguage(formValues.language.value));
        dispatch(
          setUserInfo({
            givenName: formValues.firstName,
            familyName: formValues.lastName,
            email: formValues.email,
            phoneNumber: formValues.phoneNumber || null,
          })
        );
        <ConfirmSavedChanges />;
        setDataHasChanged(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateForm = useCallback(
    (field: string) => {
      switch (field) {
        case 'userName':
          if (!isBetweenRange(formValues[field], 1, Infinity)) {
            setErrorMessage(field, 'The users name should be at least 1 character');
          } else {
            setErrorMessage(field);
          }
          break;
        case 'firstName':
          if (!isBetweenRange(formValues[field], 1, Infinity)) {
            setErrorMessage(field, 'The first name should be at least 1 character');
          } else {
            setErrorMessage(field);
          }
          break;
        case 'lastName':
          if (!isBetweenRange(formValues[field], 1, Infinity)) {
            setErrorMessage(field, 'The last name should be at least 1 character');
          } else {
            setErrorMessage(field);
          }
          break;
        case 'email':
          if (!isValidEmail(formValues[field])) {
            setErrorMessage(field, 'Not a valid email');
          } else {
            setErrorMessage(field);
          }
          break;
        case 'phoneNumber':
          if (formValues['phoneNumber'] && !isValidPhoneNumber(formValues['phoneNumber'])) {
            setErrorMessage(field, 'Not a valid phone number');
          } else {
            setErrorMessage(field);
          }
          break;

        default:
          break;
      }
    },
    [formValues]
  );
  useEffect(() => {
    if (currentInput) {
      validateForm(currentInput);
    }
  }, [formValues, currentInput, validateForm]);

  const renderEmailInput = () => {
    return (
      <Input
        name='email'
        label={translate('Profile page - email address')}
        value={formValues.email}
        onChange={handleOnChange('email')}
        errorText={formErrors.email.errorMessage}
        required={true}
      />
    );
  };

  const renderLastNameInput = () => {
    return (
      <Input
        name='lastName'
        label={translate('Profile page - Last name')}
        value={formValues.lastName}
        onChange={handleOnChange('lastName')}
        errorText={formErrors.lastName.errorMessage}
        required={true}
      />
    );
  };
  const dropdownOnChange = (_: any, option: any) => {
    setFormValues({ ...formValues, language: option });
  };

  return (
    <div className={styles.container}>
      <Prompt hasUnsavedChanges={dataHasChanged} />

      <div className={styles.content}>
        <div className={styles['first-column']}>
          <Input
            name='userName'
            label={translate('Profile page - Username')}
            value={formValues.userName}
            onChange={handleOnChange('userName')}
            errorText={formErrors.userName.errorMessage}
            disabled={true}
            required={true}
          />
          <Input
            name='firstName'
            label={translate('Profile page - First name')}
            value={formValues.firstName}
            onChange={handleOnChange('firstName')}
            errorText={formErrors.firstName.errorMessage}
            required={true}
          />
          {width > BREAKPOINT_MOBILE ? renderEmailInput() : renderLastNameInput()}

          <div className='input-container'>
            <span className='input-label'>{translate('Profile page - phone number')}</span>
            <PhoneInput
              className={styles['phone-input'] + ' input'}
              international
              name='phoneNumber'
              value={formValues.phoneNumber}
              onChange={handleOnChange('phoneNumber')}
              limitMaxLength={true}
              countryCallingCodeEditable={true}
            />
            <div className='error-text'>
              {formErrors.phoneNumber.errorMessage &&
                translate('Profile page - phone number error')}
            </div>
          </div>
        </div>
        <div className={styles['second-column']}>
          {width <= BREAKPOINT_MOBILE ? renderEmailInput() : renderLastNameInput()}
          <DropDown
            dropDownTitle={translate('Profile page - Language')}
            required
            displayProperty='displayValue'
            optionList={availableLangauages}
            initialValue={formValues.language}
            setOnChange={dropdownOnChange}
          />
        </div>
      </div>

      <button
        className={styles['profile-save-changes'] + ' btn btn-primary'}
        onClick={handleSubmit}
      >
        {translate('Profile page - Save changes')}
      </button>
    </div>
  );
};
export default PersonalSettings;
