import { ScaleLinear, scaleLinear } from 'd3';
import dayjs from 'dayjs';
import { useMemo } from 'react';

const useController = (
  XInterval: [number, number] | any,
  YInterval: [number | undefined, number | undefined],
  dimensions: {
    height: number;
    width: number;
  } = { height: 100, width: 200 },
  customScale?: (domain: [number, number]) => ScaleLinear<number, number>,
  offset?: number
) => {
  const xScale = useMemo(() => {
    let scale;
    if (customScale) {
      scale = customScale(XInterval);
    } else {
      scale = scaleLinear().domain([
        dayjs
          .unix(XInterval?.[0] || 0)
          .utc(true)
          .startOf('day')
          .unix(),
        dayjs
          .unix(XInterval?.[1] || 0)
          .utc(true)
          .endOf('day')
          .unix(),
      ]);
    }

    return scale.range([0, dimensions.width - 2 * (offset || 0)]);
  }, [XInterval, dimensions.width, customScale, offset]);

  const yScale = useMemo(
    () =>
      scaleLinear()
        .domain([YInterval[0] || 0, YInterval?.[1] || 0])
        .range([dimensions.height - (offset ? offset - 10 : 0), 0]),
    [YInterval, dimensions.height, offset]
  );

  return {
    xScale,
    yScale,
  };
};

export default useController;
