import PlanGraph from 'components/Track/PlanGraph/PlanGraph';
import {
  activeCropseasonInitialValue,
  CROPSTATUS_RESOLUTION,
  VARIABLES_CROP_STATUS,
} from 'config/constants';
import dayjs from 'dayjs';
import { useGetDataForPlan } from 'hooks/useGetDataForPlan';
import { Point } from 'interfaces/point';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { cropseasonSelector } from 'redux/slices/cropseason.slice';
import { trackFilterSelector } from 'redux/slices/trackFilterSlice';
import { useGetActualsQuery } from 'services/actuals.service';
import {
  useGetGuardrailsQuery,
  useGetGuardrailVariableStatusQuery,
} from 'services/guardrails.service';
import { reduceNumberToGivenInterval } from 'utils/reduceNumberToGivenInterval';
import { getVariablesDaysWeekResolution, getVariablesStatusWarning } from 'utils/variables.util';
import Quadrant from './Quadrant/Quadrant';
import { getQuadrantRange } from 'utils/quadrant.utils';
import { Range } from 'interfaces/range';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { useGetAdaptivePlanData } from 'hooks/useGetAdaptivePlanData';
import useGetPlanLegends from 'hooks/usePlanLegends';
import LegendGraphDataToggleSwitches from 'components/Track/LegendGraphDataToggleSwitches/LegendGraphDataToggleSwitches';
import { getFooterSizeClass } from 'utils/general.util';
import { sideBarSelector } from 'redux/slices/sidebar.slice';
import { getGuardrailsDataBasedOnPlan } from 'utils/getGuardrailsBasedOnPlan';
import { getPlanVariableIds } from 'utils/plans.util';

interface GuardRailsProps {}

const Plan: React.FC<GuardRailsProps> = () => {
  const { dates, resolution } = useAppSelector(trackFilterSelector);
  const { id: cropseasonId } = useAppSelector(cropseasonSelector);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const cropStatusVariables = getVariablesDaysWeekResolution(VARIABLES_CROP_STATUS);
  const [scatterPlotData, setScatterPlotData] = useState<Point[]>();
  const [plantLoadTicks, setPlantLoadTicks] = useState<number[]>();
  const [stemDiameterTicks, setStemDiameterTicks] = useState<number[]>();
  const { enabledGuardrailsBasedOnXPercent } = useFeatureFlags();
  const isOpen = useAppSelector(sideBarSelector).isOpen;

  const { data, isSuccess } = useGetActualsQuery(
    {
      cropSeasonId: cropseasonId,
      startDate: dayjs.unix(dates.start).toISOString(),
      endDate: dayjs.unix(dates.end).toISOString(),
      resolution: CROPSTATUS_RESOLUTION,
      variables: VARIABLES_CROP_STATUS,
      accountId: accountId,
    },
    {
      skip:
        !cropseasonId ||
        cropseasonId === activeCropseasonInitialValue.id ||
        dates.end < dates.start ||
        !accountId,
    }
  );

  const { data: idealBounds, isSuccess: isGuardRailSuccess } = useGetGuardrailsQuery(
    {
      cropSeasonId: cropseasonId,
      startDate: dayjs.unix(dates.start).toISOString(),
      endDate: dayjs.unix(dates.end).toISOString(),
      resolution: CROPSTATUS_RESOLUTION,
      variables: VARIABLES_CROP_STATUS,
      accountId: accountId,
    },
    {
      skip:
        cropseasonId === activeCropseasonInitialValue.id ||
        dates.end < dates.start ||
        enabledGuardrailsBasedOnXPercent,
    }
  );

  const { data: variableStatus } = useGetGuardrailVariableStatusQuery(
    {
      cropseasonId,
      accountId,
      varDay: cropStatusVariables.varDay,
      varWeek: cropStatusVariables.varWeek,
    },
    {
      skip: !cropseasonId || !accountId,
    }
  );

  const mappedVariablesToPlanID = getPlanVariableIds(VARIABLES_CROP_STATUS);

  const planData = useGetDataForPlan(mappedVariablesToPlanID);

  const adaptivePlan = useGetAdaptivePlanData(VARIABLES_CROP_STATUS, data?.lastTimestamp);

  let guardrailBasedOnPlan = useMemo(
    () =>
      getGuardrailsDataBasedOnPlan(
        planData.plan,
        mappedVariablesToPlanID,
        enabledGuardrailsBasedOnXPercent
      ),
    [planData.plan, mappedVariablesToPlanID]
  );

  const guardrailData = useMemo(
    () => idealBounds || guardrailBasedOnPlan,
    [idealBounds, guardrailBasedOnPlan]
  );

  useEffect(() => {
    if (
      data?.plantLoad &&
      data?.plantLoad.length &&
      guardrailData &&
      guardrailData.pload &&
      guardrailData.pload.length > 0 &&
      guardrailData.sdiam &&
      guardrailData.sdiam.length > 0
    ) {
      const plantLoadRange = getQuadrantRange(
        data?.ranges?.plantLoad,
        guardrailData?.ranges?.pload
      );
      const stemDiameterRange = getQuadrantRange(
        data?.ranges?.stemDiameter,
        guardrailData?.ranges?.sdiam
      );

      setPlantLoadTicks(getScatterPlotTicks(plantLoadRange, 5));
      setStemDiameterTicks(getScatterPlotTicks(stemDiameterRange, 6));

      let result: Point[] = [];
      for (let i = 0; i < data.plantLoad.length; i++) {
        const plantLoadValue = data.plantLoad[i]?.plantLoadActual;
        const stemDiameterValue = data.stemDiameter[i]?.stemDiameterActual;

        if (
          plantLoadValue &&
          stemDiameterValue &&
          plantLoadRange.min &&
          plantLoadRange.max &&
          stemDiameterRange.min &&
          stemDiameterRange.max
        ) {
          result.push({
            x: reduceNumberToGivenInterval(
              plantLoadValue,
              plantLoadRange.min,
              plantLoadRange.max,
              [-1, 1]
            ),
            y: reduceNumberToGivenInterval(
              stemDiameterValue,
              stemDiameterRange.min,
              stemDiameterRange.max,
              [-1, 1]
            ),
          });
        }
      }

      setScatterPlotData(result);
    }
  }, [data, guardrailData]);

  function getScatterPlotTicks(input: Range, ticksNumber: number) {
    const step = (input.max - input.min) / (ticksNumber - 1);
    let ticks = [];
    ticks.push(input.min);
    for (let i = 0; i < ticksNumber - 2; i++) {
      ticks.push(input.min + (i + 1) * step);
    }
    ticks.push(input.max);
    return ticks;
  }

  const legendItems = useGetPlanLegends(
    VARIABLES_CROP_STATUS,
    guardrailData,
    planData,
    data,
    adaptivePlan,
    mappedVariablesToPlanID
  );

  return (
    <div className='graph-container plan-graph'>
      {Object.entries(VARIABLES_CROP_STATUS).map(([key, value]) => {
        let newClassName = getVariablesStatusWarning(variableStatus, value);
        return (
          <div className={`line-graph-container ${newClassName}`} key={key}>
            <PlanGraph
              actual={isSuccess ? data : undefined}
              guardrail={guardrailData}
              plan={planData.plan}
              planDetails={planData.planDetails}
              variableKey={key}
              variableValue={value}
              resolution={CROPSTATUS_RESOLUTION}
              dates={[dates.start, dates.end]}
              adaptivePlan={adaptivePlan}
            />
          </div>
        );
      })}
      <div className='actual-quadrant-graph-container'>
        <div className='graph-title plant-balance-graph'>PlantBalance</div>
        {scatterPlotData && (
          <Quadrant
            points={scatterPlotData}
            plantLoadTicks={plantLoadTicks}
            stemDiamterTicks={stemDiameterTicks}
          />
        )}
      </div>

      {legendItems && (
        <div className={`fixed-footer-legend plan-legend ${getFooterSizeClass(isOpen)}`}>
          <LegendGraphDataToggleSwitches legendItems={legendItems} showFullItem={true} />
        </div>
      )}
    </div>
  );
};
export default Plan;
