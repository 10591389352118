import './LegendElement.scss';
import 'react-tooltip/dist/react-tooltip.css';

import { LegendItem } from 'interfaces/legend-item';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as Info } from 'styles/icons/info.svg';
import { ReactComponent as LinkIcon } from 'styles/icons/link-icon.svg';
import { translate } from 'utils/translations.util';
import { useNavigate } from 'react-router-dom';

interface LegendElementProps {
  item: LegendItem;
  id: string;
  fontSize?: string;
}

const showLinkItem = (item: LegendItem) => {
  return Boolean(!item?.disabled && item?.itemHasLink && item?.itemLink);
};

const LegendElement: React.FC<LegendElementProps> = ({ item, id, fontSize, ...props }) => {
  let navigate = useNavigate();
  let legendDisabledClass = item.disabled ? 'legend-item-disabled' : '';

  return (
    <div id={id} className={`legend-item  ${legendDisabledClass}`} {...props}>
      <svg height='4' width='30'>
        <g fill='none'>
          <path stroke={item.color} strokeDasharray={item.stroke} strokeWidth='4' d='M0 2 l30 0' />
        </g>
      </svg>
      <span style={{ fontSize: fontSize }}>{translate(item.label)}</span>
      {showLinkItem(item) && (
        <div className='link-icon'>
          <LinkIcon
            width={20}
            className='icon-light c-hand'
            id={`tooltip-anchor-legend-link${id}`}
            data-tooltip-variant='light'
            onClick={() => navigate(`${item.itemLink}`)}
          ></LinkIcon>
          <Tooltip
            anchorSelect={`#tooltip-anchor-legend-link${id}`}
            place='top'
            positionStrategy='fixed'
            opacity={1}
          >
            {item.itemName}
          </Tooltip>
        </div>
      )}
      {item.customContent ||
        (item.cropSummary && (
          <div className='info-icon'>
            <Info
              width={20}
              className='icon-light c-hand'
              id={`tooltip-anchor${id}`}
              data-tooltip-variant='light'
            />
            <Tooltip
              anchorSelect={`#tooltip-anchor${id}`}
              place='right'
              positionStrategy='fixed'
              opacity={1}
            >
              <div>Name: {item.cropSummary.name}</div>
              <div>Variety: {item.cropSummary.variety}</div>
              <div>Planting date: {item.cropSummary.plantingDate.format('DD-MM-YYYY')}</div>
              <div>Location: {item.cropSummary.location}</div>
            </Tooltip>
          </div>
        ))}
    </div>
  );
};
export default LegendElement;
