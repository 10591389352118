import { Location } from 'interfaces/location';


export const getFirstLocationByName = (locations?: Location[], locationName?: string) => {
    const foundLocations = locations?.filter(
        (location) =>
            location.name?.toLocaleLowerCase() ===
            locationName?.toLocaleLowerCase()
    ) || [];

    return foundLocations[0]?.id;

}