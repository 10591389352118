import './VarietyStep.scss';

import DropDown from 'components/DropDown/DropDown';
import { MetaData } from 'interfaces/meta-data';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { planCreationSelector, setOption } from 'redux/slices/planCreationSlice';
import { ReactComponent as Info } from 'styles/icons/info.svg';
import { track } from 'utils/analtycs';
import { translate } from 'utils/translations.util';

interface IVarietyStep {
  varieties: MetaData[];
  rootstocks: MetaData[];
  lastValidStep?: boolean;
}

const VarietyStep: React.FC<IVarietyStep> = ({ varieties, rootstocks, lastValidStep }) => {
  const dispatch = useAppDispatch();
  const variety = useAppSelector(planCreationSelector).variety;
  const rootstock = useAppSelector(planCreationSelector).rootstock;
  const { linkedCropseason } = useAppSelector(planCreationSelector);
  const { selectedLanguage } = useAppSelector(languageSelector);
  const [filteredVariety, setSearchFilteredVariety] = React.useState<MetaData[]>(varieties);
  const [filteredRootstock, setSearchFilteredRootstock] = React.useState<MetaData[]>(rootstocks);

  const handleSetOnChange = (name: string, value: MetaData) => {
    track(`plan create step 2`, {
      field: name,
      value: value?.name,
    });
    dispatch(setOption({ [name]: value }));
  };

  const checkVariety = () => {
    if (variety && linkedCropseason?.variety.name === variety?.name) {
      return 'Prefilled based on your chosen CropSeason.';
    }
  };

  const filterVarietyBySearch = (keyword: string) => {
    const filteredList = varieties.filter((variety) =>
      variety.name.toLowerCase().includes(keyword.toLowerCase())
    );
    setSearchFilteredVariety(filteredList);
  };

  const filterRootstokBySearch = (keyword: string) => {
    const filteredList = rootstocks.filter((rootstock) =>
      rootstock.name.toLowerCase().includes(keyword.toLowerCase())
    );
    setSearchFilteredRootstock(filteredList);
  };

  return (
    <div className='variety-step'>
      <div className='step-title'>
        {translate('Create Plan - go to 1 select variety screen', selectedLanguage)}
        <Info
          width={20}
          height={20}
          className='icon-light c-hand'
          id={`tooltip-anchor-create-plan-variety`}
          data-tooltip-variant='light'
        />
        <Tooltip
          anchorSelect={`#tooltip-anchor-create-plan-variety`}
          place='right'
          style={{ zIndex: 10, width: '295px', display: 'flex', height: 'auto' }}
          opacity={1}
        >
          <div className='plan-settings-details'>
            {translate('Information hoover: Select your variety', selectedLanguage)}
          </div>
        </Tooltip>
      </div>
      <div className='dropdown-containers'>
        <div className='input'>
          <DropDown
            dropDownTitle={translate('Create Plan - select the variety', selectedLanguage)}
            initialValue={
              variety?.name
                ? variety
                : { name: translate('Create Plan - select the variety', selectedLanguage) }
            }
            name='variety'
            optionList={filteredVariety}
            required={true}
            setOnChange={handleSetOnChange}
            requiredText={lastValidStep && !variety ? 'Please select crop variety' : ''}
            displayProperty={'name'}
            prefilledText={checkVariety()}
            searchOptions={{
              placeholder: 'Search variety',
              onChange: filterVarietyBySearch,
            }}
          />
        </div>
        <div className='input'>
          <DropDown
            dropDownTitle={translate('Create Plan - select the rootstock', selectedLanguage)}
            initialValue={
              rootstock?.name
                ? rootstock
                : { name: translate('Create Plan - select the rootstock', selectedLanguage) }
            }
            name='rootstock'
            optionList={filteredRootstock}
            required={false}
            setOnChange={handleSetOnChange}
            displayProperty={'name'}
            searchOptions={{
              placeholder: 'Search rootstock',
              onChange: filterRootstokBySearch,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default VarietyStep;
