import React, { useEffect, useRef, useState } from 'react';

import styles from './MultiSelectChipsDropdown.module.scss';
import useTranslate from 'hooks/useTranslate';
import { SearchOptions } from 'interfaces/dropdown';
import { ReactComponent as ChevronDown } from 'styles/icons/chevron-down.svg';
import SearchInput from 'components/SearchInput/SearchInput';
import MultiSelectChips from './MultiSelectChips/MultiSelectChips';
import { ReactComponent as CloseIcon } from 'styles/icons/close.svg';

interface MultiSelectChipsDropdownProps {
  initialValue?: any[];
  optionList?: any[];
  setOnChange?: (value: any) => void;
  displayProperty?: string;
  rightSide?: boolean;
  hasTooltip?: boolean;
  searchOptions?: SearchOptions;
  placeholder: string;
}

const MultiSelectChipsDropdown: React.FC<MultiSelectChipsDropdownProps> = ({
  initialValue,
  optionList = [],
  displayProperty = '',
  setOnChange = () => {},
  rightSide = false,
  hasTooltip = false,
  searchOptions,
  placeholder,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [dropDownValue, setDropDownValue] = useState(initialValue);
  const translate = useTranslate();

  const getDropdownValue = (option: any) => {
    if (displayProperty) {
      return option[displayProperty];
    } else {
      return option;
    }
  };

  function handleClick(event: MouseEvent) {
    if (
      dropDownRef.current &&
      isDropDownOpen &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setIsDropDownOpen(false);
    }
  }

  useEffect(() => {
    setDropDownValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  const handleDropDownItemSelect = (option: any) => {
    let newOptions;
    const checkSelectedOption = dropDownValue?.some(
      (value: any) => value[displayProperty] === option[displayProperty]
    );
    if (!checkSelectedOption) {
      newOptions = [...(dropDownValue || []), option];
      setDropDownValue(newOptions);
    } else {
      newOptions = dropDownValue?.filter(
        (value) => value[displayProperty] !== option[displayProperty]
      );
    }

    setDropDownValue(newOptions);
    setOnChange(newOptions);
  };

  const checkOption = (option: any) => {
    return dropDownValue?.some((value) => value[displayProperty] === option[displayProperty]);
  };

  const renderOptions = () => {
    if (searchOptions && !optionList?.length) {
      return (
        <div className={`${styles['list-item-container']} c-none`}>
          <li className={styles['dropdown-item']}>{translate('cropseason-selector-no-results')}</li>
        </div>
      );
    }
    return optionList.map((option: any, index: number) => (
      <div
        title={hasTooltip ? getDropdownValue(option) : undefined}
        className={styles['list-item-container']}
        key={index}
        onClick={() => handleDropDownItemSelect(option)}
      >
        <li
          className={`${styles['dropdown-item']} ${checkOption(option) && styles['selected-item']}`}
        >
          {getDropdownValue(option)}
        </li>
      </div>
    ));
  };

  const getSelectedOption = () => {
    return dropDownValue?.map((value) => value[displayProperty]);
  };

  const clearOption = (input: string) => {
    const filteredOptions = dropDownValue?.filter((value) => value[displayProperty] !== input);
    setDropDownValue(filteredOptions);
    setOnChange(filteredOptions);
  };

  const clearAll = () => {
    setDropDownValue([]);
    setOnChange([]);
  };

  return (
    <div className={styles['dropdown-container']}>
      <div
        className={`dropdown ${styles.dropdown} ${rightSide ? styles['dropdown-right'] : ''}  ${
          isDropDownOpen ? 'active' : ''
        }  c-hand`}
        ref={dropDownRef}
      >
        <div
          className={`${styles['dropdown-button']}`}
          onClick={() => {
            setIsDropDownOpen(!isDropDownOpen);
          }}
        >
          <div className={styles['icon-container']}>
            {dropDownValue && dropDownValue.length > 0 && isDropDownOpen && (
              <CloseIcon className={`icon-light ${styles['dropdown-icon']}`} onClick={clearAll} />
            )}
            <ChevronDown
              className={`icon-light ${
                isDropDownOpen ? styles['rotate-180'] : styles['dropdown-icon']
              }`}
            />
          </div>
          <div className={styles['dropdown-text-container']}>
            {dropDownValue?.length ? (
              <MultiSelectChips
                isExpanded={isDropDownOpen}
                chips={getSelectedOption() || []}
                onClear={clearOption}
              />
            ) : (
              placeholder
            )}
          </div>
        </div>
        <ul className={`${styles.menu} ${styles['dropdown-menu']} menu`}>
          {searchOptions && (
            <div className={styles.search}>
              <SearchInput
                placeholder={searchOptions.placeholder}
                onChange={(event) =>
                  searchOptions.onChange && searchOptions.onChange(event.target.value)
                }
                onReset={() => searchOptions.onChange && searchOptions.onChange('')}
              />
            </div>
          )}
          {renderOptions()}
        </ul>
      </div>
    </div>
  );
};

export default MultiSelectChipsDropdown;
