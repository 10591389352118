import dayjs from 'dayjs';
import { baseApi } from 'redux/slices/baseApi.slice';
import { getRanges } from 'utils/getIntervalsForGraphs';
import { formatQueryString } from 'utils/queryString';

interface Timseries {
  local_datetime: string[];
  yield: number[];
}
const predictionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getYieldPredition: builder.mutation<any, { cropseason_id: number; current_account_id: number }>(
      {
        query: ({ cropseason_id, current_account_id }) => {
          const ENDPOINT = 'prediction/timeseries?';
          return (
            ENDPOINT +
            formatQueryString({
              cropseason_id,
              current_account_id,
            })
          );
        },
        transformResponse: (rawResult: {
          result: {
            timeseries: Timseries;
          };
        }) => {
          const result = rawResult.result.timeseries;
          const range = getRanges(result, { yield: 'yield' });

          const transformedResponse = result.local_datetime.map((timestamp: string, i: number) => {
            return {
              timestamp: dayjs(timestamp).unix(),
              yieldPrediction: result.yield[i],
              weekNumber: dayjs(timestamp).week(),
            };
          });

          return { data: transformedResponse, range: range.yield };
        },
      }
    ),
  }),
});

export const { useGetYieldPreditionMutation } = predictionApi;
