import './LocationStep.scss';

import DropDown from 'components/DropDown/DropDown';
import { MetaData } from 'interfaces/meta-data';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { planCreationSelector, setOption } from 'redux/slices/planCreationSlice';
import { ReactComponent as Info } from 'styles/icons/info.svg';
import { track } from 'utils/analtycs';
import { translate } from 'utils/translations.util';

interface LocationStepProps {
  locations: MetaData[];
  lastValidStep: boolean;
}

const LocationStep: React.FC<LocationStepProps> = ({ locations, lastValidStep }) => {
  const dispatch = useAppDispatch();
  const location = useAppSelector(planCreationSelector).location;
  const { selectedLanguage } = useAppSelector(languageSelector);
  const { linkedCropseason } = useAppSelector(planCreationSelector);

  const handleSetOnChange = (name: string, value: MetaData) => {
    track(`plan create step 3`, {
      field: name,
      value: value?.name,
    });
    dispatch(setOption({ [name]: value }));
  };

  const checkLocation = () => {
    if (location && location?.name === linkedCropseason?.location.name) {
      return 'Prefilled based on your chosen CropSeason.';
    }
  };

  return (
    <div className='location-step'>
      <div className='step-title'>
        {translate('Create Plan - go to 2 select location screen', selectedLanguage)}
        <Info
          width={20}
          height={20}
          className='icon-light c-hand'
          id={`tooltip-anchor-create-plan-variety`}
          data-tooltip-variant='light'
        />
        <Tooltip
          anchorSelect={`#tooltip-anchor-create-plan-variety`}
          place='right'
          style={{ zIndex: 10, width: '295px', display: 'flex', height: 'auto' }}
          opacity={1}
        >
          <div className='plan-settings-details'>
            {translate('Information hoover: Select your location', selectedLanguage)}
          </div>
        </Tooltip>
      </div>
      <div className='dropdown-containers'>
        <div className='input'>
          <DropDown
            dropDownTitle={translate('Create Plan - Select the location', selectedLanguage)}
            initialValue={
              location?.name
                ? location
                : { name: translate('Create Plan - Select the location', selectedLanguage) }
            }
            name='location'
            optionList={locations}
            required={true}
            setOnChange={handleSetOnChange}
            requiredText={
              lastValidStep && !location
                ? translate('Create Plan - Select the location warning', selectedLanguage)
                : ''
            }
            displayProperty={'name'}
            prefilledText={checkLocation()}
          />
        </div>
      </div>
    </div>
  );
};
export default LocationStep;
