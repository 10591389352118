import React from 'react';
import useTranslate from 'hooks/useTranslate';

import styles from './PasswordSettings.module.scss';

const PasswordSettings: React.FC = () => {
  const handleClick = () => {
    window.location.href = PASSWORD_UPDATE_URL;
  };
  const PASSWORD_UPDATE_URL = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/auth?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.href}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`;
  const translate = useTranslate();
  return (
    <div className={styles.container}>
      <div className={styles.title}>{translate('Profile page - Password settings')}</div>
      <div className={styles.content}>
        {translate('Profile page - Change the password for your account')}
      </div>
      <button className={`btn btn-primary ${styles['large-button']}`} onClick={handleClick}>
        {translate('Profile page - Update')}
      </button>
    </div>
  );
};
export default PasswordSettings;
