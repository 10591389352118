import Accordion from 'components/Accordion/Accordion';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { planComparisonActions, planComparisonSelectors } from 'redux/slices/planComparisonSlice';
import { useGetStrategiesTimeseriesQuery, useSaveStrategyMutation } from 'services/shop.service';
import { planCreationSelector, reset, setOption } from 'redux/slices/planCreationSlice';
import { languageSelector } from 'redux/slices/language.slice';
import { translate } from 'utils/translations.util';
import style from './PlansComparisonTable.module.scss';
import { MAX_LENGTH_PLAN_NAME, MIN_LENGTH_PLAN_NAME, VARIABLES_PRODUCTION } from 'config/constants';
import dayjs from 'dayjs';
import ComparisonStrategiesGraph from '../ComparisonStrategiesGraph/ComparisonStrategiesGraph';
import {
  useGetPlanTimeseriesQuery,
  useGetPlanTimeseriesVariablesQuery,
} from 'services/plans.service';
import { useUpdatePlanMutation } from 'services/plans.service';
import { getAnalyzeColor } from 'utils/colors.util';
import Button from 'components/Button/Button';
import { SidebarFileFolderNames } from 'views/EditPlans/EditPlanSidebar/EditPlanSidebar';
import { checkFileName } from 'utils/validation.util';
import { ModalService } from 'services/modal.service';
import SavePlanAsContent from 'views/EditPlans/EditPlanSidebar/SavePlanAsContent/SavePlanAsContent';
import { setStep } from 'redux/slices/stepperSlice';
import { useNavigate } from 'react-router-dom';
import { roundToDecimals } from 'utils/roundToDecimals';
import { KeyValue } from '../../../../interfaces/keyvalue';
import { strategiesActions, strategiesSelectors } from 'redux/slices/planStrategies.slice';
import { accountIdSelector } from 'redux/slices/accountId.slice';

enum variableCategories {
  CropStatus,
  FreshWeight,
  Climate,
  Production,
}
interface Row {
  id: string;
  variable: string;
  name: string;
  unit?: string;
  values: KeyValue<string>[];
}

interface planVariable {
  [key: string]: number;
  timestamp: number;
  dateTimeWeek: number;
}
interface AccordionTitle {
  title: string;
  number: number;
}

interface planComparisonVariables {
  [key: string]: {
    category: variableCategories;
    unit?: string;
    label?: string;
  };
}
interface tableData {
  plan: any;
  firstStrategy: any;
  secondStrategy: any;
}

let accordionTitlesInitial: KeyValue<AccordionTitle> = {
  production: {
    title: 'Production',
    number: 0,
  },
  climate: {
    title: 'Climate',
    number: 0,
  },
  freshWeightGain: {
    title: 'Fresh Weight Gain',
    number: 0,
  },
  cropStatus: {
    title: 'Crop Status',
    number: 0,
  },
};
interface PlansComparisonTableProps {}
const PlansComparisonTable: React.FC<PlansComparisonTableProps> = () => {
  const { selectedLanguage } = useAppSelector(languageSelector);
  const planCreationData = useAppSelector(planCreationSelector);
  const { previewPlanId, openSaveModal } = useAppSelector(planCreationSelector);
  const [updatePlan] = useUpdatePlanMutation();
  const navigate = useNavigate();
  const shop = useAppSelector(strategiesSelectors.shop);
  const compareStrategies = useAppSelector(strategiesSelectors.compareStrategies);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const [tableData, setTableData] = useState<tableData>();

  const { data: firstStrategy } = useGetStrategiesTimeseriesQuery(
    {
      shopId: shop?.id!,
      strategyId: String(compareStrategies[0]?.id),
    },
    { skip: !compareStrategies[0] }
  );

  const { data: secondStrategy } = useGetStrategiesTimeseriesQuery(
    {
      shopId: shop?.id!,
      strategyId: String(compareStrategies[1]?.id),
    },
    { skip: !compareStrategies[1] }
  );

  const { data: planTimeseriesVariables } = useGetPlanTimeseriesVariablesQuery(
    Number(previewPlanId),
    {
      skip: !previewPlanId,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: timeseriesData } = useGetPlanTimeseriesQuery(
    {
      planId: Number(previewPlanId),
      variables: planTimeseriesVariables?.map((variable) => variable.id),
      accountId: accountId,
    },
    {
      skip: !planTimeseriesVariables,
      refetchOnMountOrArgChange: true,
    }
  );

  const planVariables: planComparisonVariables = {
    'yield.cu': { category: variableCategories.Production },
    yield: { category: variableCategories.Production },
    brix: { category: variableCategories.Production },
    afw: { category: variableCategories.Production },
    harvestMaturity: { category: variableCategories.Production },
    RADJCM: { category: variableCategories.Climate },
    GHTEMP: { category: variableCategories.Climate },
    OUTEMP: { category: variableCategories.Climate },
    GHCO2C: { category: variableCategories.Climate },
    'stem.density.setting': { category: variableCategories.FreshWeight },
    'stem load cell raw': { category: variableCategories.FreshWeight },
    growthRate: { category: variableCategories.FreshWeight },
    'stem.diam': { category: variableCategories.CropStatus },
    'plantload.fruits.m2.calc': { category: variableCategories.CropStatus },
    'setting.fruits.m2.wk': { category: variableCategories.CropStatus },
    'setting.truss.stem.cu': { category: variableCategories.CropStatus },
    pruning: { category: variableCategories.CropStatus },
    LAI: { category: variableCategories.CropStatus },
  };

  const getTranslatedText = (variable: string, unit?: string) => {
    let translatedUnit = '';
    if (unit) {
      translatedUnit = translate(unit, selectedLanguage);
    }
    return translate(variable, selectedLanguage) + ` [ ${translatedUnit} ]`;
  };

  const getVariablesByCategory = (
    variables: planComparisonVariables,
    type: variableCategories
  ): planComparisonVariables => {
    return Object.fromEntries(
      Object.entries(variables).filter(([variable]) => variables[variable]?.category === type)
    );
  };

  Object.keys(planVariables).forEach((variable) => {
    if (planTimeseriesVariables && planTimeseriesVariables.length) {
      let unit =
        planTimeseriesVariables.find(
          (varr) => varr.id.toLocaleUpperCase() === variable.toLocaleUpperCase()
        )?.unit || '';
      planVariables[variable].unit = unit;
      planVariables[variable].label = getTranslatedText(variable, unit);
    }
  });

  const planCompareVariables = useAppSelector(planComparisonSelectors.compareVariables);

  const dispatch = useAppDispatch();

  const COMPARISON_PROPDUCTION_VARS = getVariablesByCategory(
    planVariables,
    variableCategories.Production
  );
  const COMPARISON_CLIMATE_VARS = getVariablesByCategory(planVariables, variableCategories.Climate);

  const COMPARISON_FRESH_WEIGHT_VARS = getVariablesByCategory(
    planVariables,
    variableCategories.FreshWeight
  );

  const COMPARISON_CROP_STATUS_VARS = getVariablesByCategory(
    planVariables,
    variableCategories.CropStatus
  );

  let production_rows_data: Row[] = [];
  let fresh_weight_gain_rows_data: Row[] = [];
  let climate_rows_data: Row[] = [];
  let crop_status_rows_data: Row[] = [];
  let key_properties: Row[] = [];
  let variablesFilter = [] as any;

  const [accordionTitleState, setAccordionTitles] =
    useState<KeyValue<AccordionTitle>>(accordionTitlesInitial);

  useEffect(() => {
    updateAccordionTitles();
  }, [planCompareVariables]);

  useEffect(() => {
    updateTableData();
  }, [firstStrategy, secondStrategy, timeseriesData]);

  const updateAccordionTitles = () => {
    let accordionTitlesReset = { ...accordionTitlesInitial };
    accordionTitlesReset.production.number = 0;
    accordionTitlesReset.climate.number = 0;
    accordionTitlesReset.freshWeightGain.number = 0;
    accordionTitlesReset.cropStatus.number = 0;

    planCompareVariables.forEach((items: any) => {
      if (COMPARISON_PROPDUCTION_VARS.hasOwnProperty(items.variable)) {
        accordionTitlesReset.production.number++;
      } else if (COMPARISON_CLIMATE_VARS.hasOwnProperty(items.variable)) {
        accordionTitlesReset.climate.number++;
      } else if (COMPARISON_FRESH_WEIGHT_VARS.hasOwnProperty(items.variable)) {
        accordionTitlesReset.freshWeightGain.number++;
      } else if (COMPARISON_CROP_STATUS_VARS.hasOwnProperty(items.variable)) {
        accordionTitlesReset.cropStatus.number++;
      }
    });
    setAccordionTitles(accordionTitlesReset);
  };

  const updateTableData = () => {
    let tableData = {
      plan: timeseriesData,
      firstStrategy: firstStrategy ? firstStrategy : null,
      secondStrategy: secondStrategy ? secondStrategy : null,
    };

    setTableData(tableData);
  };

  const getAccordionTitle = (key: string) => {
    if (accordionTitleState[key].number === 0) {
      return accordionTitleState[key].title;
    } else {
      return accordionTitleState[key].title + ' (' + accordionTitleState[key].number + ')';
    }
  };

  function getTimeSeriesOutput(planVariableData: planVariable[], variable: string): string {
    if (!planVariableData) {
      return '-';
    }
    const timeseriesArray = planVariableData.map((value) => value[Object.keys(value)[0]]);
    const minNotNull = timeseriesArray.filter((value) => value !== null && value !== 0);
    const min = Math.min(...minNotNull);
    const max = Math.max(...timeseriesArray);
    if (variable === VARIABLES_PRODUCTION.yieldCumulative) {
      return roundToDecimals(max, 2).toString();
    } else {
      return `min: ${roundToDecimals(min, 2)} - max: ${roundToDecimals(max, 2)}`;
    }
  }

  const updateFilter = (event: ChangeEvent<HTMLInputElement>, option: Row, tableData: any) => {
    const checked = event.target.checked;
    const planComparisonIds = Object.keys(tableData).filter((id) => tableData[id]);
    let planComparisonValues = [];

    planComparisonValues = planComparisonIds.map((id) => {
      return {
        [id]: Object.values(tableData[id][option.variable]),
      };
    });

    if (checked) {
      dispatch(
        planComparisonActions.addCompareVariable({
          variable: option.variable,
          name: option.name,
          unit: option.unit,
          values: planComparisonValues,
        })
      );
    } else {
      dispatch(planComparisonActions.removeCompareVariable(option.variable));
    }
  };

  const isDisabledCheckbox = (name: string): boolean => {
    const enabled = planCompareVariables.map((variable) => variable.name).includes(name);
    return !enabled && planCompareVariables.length >= 2;
  };

  const isCheckedCheckbox = (name: string): boolean => {
    const enabled = planCompareVariables.map((variable) => variable.name).includes(name);
    return enabled;
  };

  const getVariableDataAsRow = (
    fields: planComparisonVariables,
    shopStrategiesIds: string[]
  ): Row[] => {
    let dataToDisplay = [] as Row[];
    let keys = Object.keys(fields);
    keys.forEach((key, index) => {
      let rowData = [] as any;

      if (tableData?.plan) {
        rowData.push({
          plan: getTimeSeriesOutput(tableData.plan[key], key),
        });
      }
      if (tableData?.firstStrategy) {
        rowData.push({
          firstStrategy: getTimeSeriesOutput(tableData.firstStrategy[key], key),
        });
      }
      if (tableData?.secondStrategy) {
        rowData.push({
          secondStrategy: getTimeSeriesOutput(tableData.secondStrategy[key], key),
        });
      }

      dataToDisplay.push({
        id: key,
        variable: key,
        name: fields[key].label || '',
        unit: fields[key].unit || '',
        values: rowData,
      });
    });

    return dataToDisplay;
  };

  const getKeyPropsDataAsRow = (shopStrategiesIds: string[]): Row[] => {
    let keyPropsData = [];
    let dataToDisplay = [] as Row[];

    if (planCreationData) {
      planCreationData.rootstock &&
        keyPropsData.push({
          key: 'rootstock',
          name: 'Rootstock',
          value: planCreationData.rootstock?.name || '',
        });
      planCreationData.plantingDate &&
        keyPropsData.push({
          key: 'plantingDate',
          name: 'Planting Date',
          value: dayjs.unix(planCreationData.plantingDate).format('DD/MM/YY'),
        });
      planCreationData.headRemovalDate &&
        keyPropsData.push({
          key: 'headRemovalDate',
          name: 'Head removal Date',
          value: dayjs.unix(planCreationData.headRemovalDate).format('DD/MM/YY'),
        });
      planCreationData.harvestDate &&
        keyPropsData.push({
          key: 'harvestDate',
          name: 'Last harvest date',
          value: dayjs.unix(planCreationData.harvestDate).format('DD/MM/YY'),
        });
    }

    if (keyPropsData && keyPropsData.length > 0) {
      keyPropsData.forEach((item, index) => {
        let rowData = [] as any;
        if (tableData?.plan) {
          rowData.push({
            plan: item.value,
          });
        }
        if (tableData?.firstStrategy) {
          rowData.push({
            firstStategy: item.value,
          });
        }
        if (tableData?.secondStrategy) {
          rowData.push({
            secondStrategy: item.value,
          });
        }
        dataToDisplay.push({
          id: item.key,
          variable: item.key,
          name: item.name,
          values: rowData,
        });
      });
    }
    return dataToDisplay;
  };

  if (tableData) {
    const shopStrategiesIds = Object.keys(tableData);
    key_properties = getKeyPropsDataAsRow(shopStrategiesIds);
    production_rows_data = getVariableDataAsRow(COMPARISON_PROPDUCTION_VARS, shopStrategiesIds);
    climate_rows_data = getVariableDataAsRow(COMPARISON_CLIMATE_VARS, shopStrategiesIds);
    fresh_weight_gain_rows_data = getVariableDataAsRow(
      COMPARISON_FRESH_WEIGHT_VARS,
      shopStrategiesIds
    );
    crop_status_rows_data = getVariableDataAsRow(COMPARISON_CROP_STATUS_VARS, shopStrategiesIds);
    let currentVariablesColors = [] as string[];
    variablesFilter = planCompareVariables.map(
      (variable: { variable: any; unit: string }, index: number) => {
        let currentColor = getAnalyzeColor(currentVariablesColors);
        currentVariablesColors.push(currentColor);
        return {
          label: variable.variable,
          value: variable.variable,
          color: currentColor,
          unit: variable.unit,
        };
      }
    );
  }

  const renderAccordionRow = (option: Row, hasCheck: boolean) => {
    return (
      (hasCheck && (
        <label
          key={option.name}
          className={`${
            style['plan-comparison-row'] + ' ' + style['comparison-flex']
          } form-checkbox c-hand `}
        >
          <div className={style['element-value-name']}>
            <input
              type='checkbox'
              checked={isCheckedCheckbox(option.name)}
              onChange={(event) => updateFilter(event, option, tableData)}
              disabled={isDisabledCheckbox(option.name)}
            />
            <i className='form-icon'></i>
            <span>{option.name}</span>
          </div>
          <div className={style['row-element']}>
            {option.values.map((value: any, index) => {
              let key = option.variable + index;
              return (
                <div key={key} className={style['element-value']}>
                  {Object.values(value)}
                </div>
              );
            })}
          </div>
        </label>
      )) || (
        <div key={option.name} className='element-text'>
          <div key={option.name} className={style['plan-comparison-row']}>
            <div className={style['element-value-name']}>{option.name}</div>
            <div className={style['row-element']}>
              {option.values.map((value: any, index) => {
                let key = option.variable + index;
                return (
                  <div key={key} className={style['element-value']}>
                    {Object.values(value)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )
    );
  };

  useEffect(() => {
    if (openSaveModal) {
      let names: SidebarFileFolderNames = { fileNameField: '', folderNameField: '' };
      const validateFunction = () => {
        return !!(
          !checkFileName(names.fileNameField, MIN_LENGTH_PLAN_NAME, MAX_LENGTH_PLAN_NAME) &&
          names.folderNameField
        );
      };
      ModalService.open({
        title: translate('Save Plan pop up window', selectedLanguage),
        saveText: translate('Save Plan window: Save', selectedLanguage),
        cancelText: translate('Save Plan window: Cancel', selectedLanguage),
        width: '495px',
        validateFunction: validateFunction,
        content: <SavePlanAsContent names={names} />,
        closeAction: async (isConfirmed) => {
          if (isConfirmed) {
            updatePlan({
              id: previewPlanId!,
              data: {
                name: names.fileNameField,
                path: '/' + names.folderNameField,
              },
            }).then((response) => {
              dispatch(setOption({ openSaveModal: false }));
              dispatch(reset());
              dispatch(setStep({ stateName: 'planCreationStep', stepNumber: 0 }));
              navigate('/my-plans');
            });
            names = { fileNameField: '', folderNameField: '' };
            dispatch(setOption({ fileName: '', folderName: '' }));
          } else {
            dispatch(setOption({ openSaveModal: false }));
            ModalService.close();
          }
        },
      });
    }
  }, [openSaveModal]);

  const onHandleClickButton = (event: any) => {
    let id = event.target.id;
    if (id === 'my-plan-save') {
      dispatch(setOption({ openSaveModal: true }));
    } else if (id === 'my-plan-preview') {
      navigate(`../plan/preview/${previewPlanId}`, {
        state: {
          id: previewPlanId,
        },
      });
    }
  };

  const handleOpenStrategyPreview = (id: number) => {
    if (shop && previewPlanId) {
      navigate(`/strategy/preview/${previewPlanId}/${shop.id}/${id}`, {
        state: {
          planId: previewPlanId,
          shopId: shop.id,
          strategyId: id,
        },
      });
    }
  };

  const [saveStrategy] = useSaveStrategyMutation();

  const handleSaveStrategy = (strategyId: number) => {
    let names: SidebarFileFolderNames = { fileNameField: '', folderNameField: '' };
    const validateFunction = () => {
      return !!(
        !checkFileName(names.fileNameField, MIN_LENGTH_PLAN_NAME, MAX_LENGTH_PLAN_NAME) &&
        names.folderNameField
      );
    };
    ModalService.open({
      title: 'Save Strategy',
      saveText: 'Save',
      cancelText: 'Cancel',
      width: '495px',
      validateFunction: validateFunction,
      content: <SavePlanAsContent names={names} />,
      closeAction: async (isConfirmed) => {
        if (isConfirmed) {
          saveStrategy({
            id: shop?.id!,
            strategyId,
          }).then((response: any) => {
            if (!response.error) {
              updatePlan({
                id: previewPlanId!,
                data: {
                  name: names.fileNameField,
                  path: '/' + names.folderNameField,
                },
              }).then((response) => {
                dispatch(setOption({ openSaveModal: false }));
                dispatch(reset());
                dispatch(setStep({ stateName: 'planCreationStep', stepNumber: 0 }));
                dispatch(strategiesActions.reset());
                navigate('/my-plans');
                names = { fileNameField: '', folderNameField: '' };
                dispatch(setOption({ fileName: '', folderName: '' }));
              });
            }
          });
        } else {
          ModalService.close();
        }
      },
    });
  };

  return (
    <div className={style['plans-comparison']}>
      <div className={style['graph-container']}>
        <ComparisonStrategiesGraph
          strategies={compareStrategies}
          variablesFilters={variablesFilter}
        />
      </div>
      <div className={style['table-header-conainer']}>
        <div className={style['plans-comparison-plan0']}></div>
        <div className={style['plans-comparison-plans-container']}>
          <div className={style['plans-comparison-plan1']}>
            <div className={style['plan-comparison-buttons-text']}>My CropPlan</div>
            <div className={style['plan-comparison-buttons']}>
              <Button
                id='my-plan-preview'
                styleType='secondary'
                size='medium'
                onHandleClick={onHandleClickButton}
              >
                Preview
              </Button>
              <Button
                id='my-plan-save'
                styleType='primary'
                size='medium'
                onHandleClick={onHandleClickButton}
              >
                Save
              </Button>
            </div>
          </div>
          {tableData?.firstStrategy && (
            <div className={style['plans-comparison-plan2']}>
              <div className={style['plan-comparison-buttons-text']}>
                {compareStrategies[0].name}
              </div>
              <div className={style['plan-comparison-buttons']}>
                <Button
                  id='firstStrategy-preview'
                  styleType='secondary'
                  size='medium'
                  onHandleClick={() => handleOpenStrategyPreview(compareStrategies[0].id)}
                >
                  Preview
                </Button>
                <Button
                  id='firstStrategy-save'
                  styleType='primary'
                  size='medium'
                  onHandleClick={() => handleSaveStrategy(compareStrategies[0].id)}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          {tableData?.secondStrategy && (
            <div className={style['plans-comparison-plan3']}>
              <div className={style['plan-comparison-buttons-text']}>
                {compareStrategies[1].name}
              </div>
              <div className={style['plan-comparison-buttons']}>
                <Button
                  id='secondStrategy-preview'
                  styleType='secondary'
                  size='medium'
                  onHandleClick={() => handleOpenStrategyPreview(compareStrategies[1].id)}
                >
                  Preview
                </Button>
                <Button
                  id='secondStrategy-save'
                  styleType='primary'
                  size='medium'
                  onHandleClick={() => handleSaveStrategy(compareStrategies[1].id)}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        <Accordion
          title={'Key properties'}
          options={key_properties}
          isInitialOpen={true}
          renderElement={renderAccordionRow}
          isLightTheme={true}
          hasCheck={false}
        ></Accordion>
        <Accordion
          title={getAccordionTitle('production')}
          options={production_rows_data}
          isInitialOpen={true}
          renderElement={renderAccordionRow}
          isLightTheme={true}
          hasCheck={true}
        ></Accordion>
        <Accordion
          title={getAccordionTitle('climate')}
          options={climate_rows_data}
          isInitialOpen={true}
          renderElement={renderAccordionRow}
          isLightTheme={true}
          hasCheck={true}
        ></Accordion>
        <Accordion
          title={getAccordionTitle('freshWeightGain')}
          options={fresh_weight_gain_rows_data}
          isInitialOpen={true}
          renderElement={renderAccordionRow}
          isLightTheme={true}
          hasCheck={true}
        ></Accordion>
        <Accordion
          title={getAccordionTitle('cropStatus')}
          options={crop_status_rows_data}
          isInitialOpen={true}
          renderElement={renderAccordionRow}
          isLightTheme={true}
          hasCheck={true}
        ></Accordion>
      </div>
    </div>
  );
};

export default PlansComparisonTable;
