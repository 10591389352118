import { useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { strategiesActions } from 'redux/slices/planStrategies.slice';

export function useSetFilterRanges(strategies: any) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (strategies?.ranges) {
      dispatch(
        strategiesActions.setFilter({
          afw: strategies.ranges.afw,
          yield: strategies.ranges.yield,
          brix: strategies.ranges.brix,
        })
      );
    }
  }, [strategies?.ranges]);
}
