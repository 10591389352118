import debounce from 'lodash.debounce';
import React, { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setWidth, tableWidthsSelector } from 'redux/slices/tableWidths.slice';

const ResizableTableHeader: React.FC<any> = (props) => {
  const initialSize = props?.style?.width;
  const key = props?.storekey;

  const savedSize = useAppSelector(tableWidthsSelector(key), () => true);
  const dispatch = useAppDispatch();

  let observer = new ResizeObserver(
    debounce((mutations) => {
      const value = mutations[0].target.clientWidth;
      if (key && savedSize !== value) {
        dispatch(setWidth({ key, value }));
      }
    }, 2000)
  );

  const headerRef = useRef<HTMLTableCellElement>(null);
  if (headerRef?.current) {
    observer.observe(headerRef.current);
  }

  return (
    <th ref={headerRef} {...props} style={{ width: savedSize ? `${savedSize}px` : initialSize }}>
      {props.children}
    </th>
  );
};
export default ResizableTableHeader;
