import { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetCropseasonsQuery } from 'services/cropseason.service';
import { cropseasonSelector, setActiveCropseason } from 'redux/slices/cropseason.slice';
import { activeCropseasonInitialValue } from 'config/constants';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import useUrlState from './useUrlState';
import { useGetUserSettingsQuery } from 'services/userAccount.service';

export const useSetCropseason = () => {
  const accountId = useAppSelector(accountIdSelector).accountId;
  const dispatch = useAppDispatch();
  const { data: cropSeasons } = useGetCropseasonsQuery(accountId, {
    skip: !accountId,
    refetchOnMountOrArgChange: true,
  });
  const activeCropseason = useAppSelector(cropseasonSelector);
  const { data: defaultCropSeasons } = useGetUserSettingsQuery();
  const [urlState, setUrlState] = useUrlState({
    cropseasonId: '0',
  });

  const getCropSeasonDataById = useCallback(
    (cropSeasonId: number) => {
      if (cropSeasons) {
        return cropSeasons.find((cropSeason) => cropSeason.id === cropSeasonId);
      }
      return null;
    },
    [cropSeasons]
  );

  const noCropSeasonSelected = useCallback(() => {
    const selectedCropSeson = getCropSeasonDataById(activeCropseason.id)?.id || 0;
    return selectedCropSeson === activeCropseasonInitialValue.id;
  }, [activeCropseason.id, getCropSeasonDataById]);

  useEffect(() => {
    let defaultCropSeasonData;
    if (Number(urlState.cropseasonId)) {
      setUrlState({ cropseasonId: urlState.cropseasonId });
    }

    if (cropSeasons) {
      const activeCropSeasons = cropSeasons.filter((cropSeason) => cropSeason.is_active);
      const defaultCropSeson = defaultCropSeasons?.accounts?.[accountId];
      if (defaultCropSeson) {
        defaultCropSeasonData = getCropSeasonDataById(defaultCropSeson.default_cropseason_id);
      }
      //if it has default but not active - set default
      if (defaultCropSeasonData && noCropSeasonSelected()) {
        dispatch(setActiveCropseason(defaultCropSeasonData));
        setUrlState({ cropseasonId: String(defaultCropSeasonData.id) });
        //if no default and no active - set first
      } else if (noCropSeasonSelected()) {
        const cropSeason = activeCropSeasons.at(-1) || activeCropseasonInitialValue;
        dispatch(setActiveCropseason(cropSeason));
        setUrlState({ cropseasonId: String(cropSeason.id) });
      }
    }
  }, [
    cropSeasons,
    dispatch,
    activeCropseason,
    accountId,
    defaultCropSeasons,
    urlState.cropseasonId,
  ]);
};
