import React, { useEffect, useState } from 'react';

import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import NumberInput from 'components/NumberInput/NumberInput';
import { ReactComponent as Refresh } from 'styles/icons/refresh.svg';
import isEqual from 'lodash.isequal';

import styles from './Settings.module.scss';
import useTranslate from 'hooks/useTranslate';
import { Tooltip } from 'react-tooltip';
import { Constraints } from 'services/notifications.service';
import { compare } from 'utils/compare';
import NotificationItemIcon, {
  NotificationIconStyle,
} from 'components/NotificationItemIcon/NotificationItemIcon';
import { KeyValue } from 'interfaces/keyvalue';
import { isEmptyObject } from 'utils/general.util';
import { ReactComponent as Checkmark } from 'styles/icons/checkmark.svg';
import { getNotificationCorrection, getNotificationThreshold } from 'utils/notifications';
import { NotificationThreshold } from 'interfaces/notificationItem';
import DropDown from 'components/DropDown/DropDown';
import UseGetNotificationSettings from 'views/Notification/hooks/useNotificationSettings';
//TODO implement render content method with regex instead of split
// eslint-disable-next-line no-template-curly-in-string
const SPLIT_MARK = '${threshold}';
const SPLIT_MARK_CORRECTION = '${correction_factor}';
interface SettingsProps {
  iconType: string;
  initialValues: {
    enabled: boolean;
    value: KeyValue<number>;
  };
  text: string;
  handleApplyChanges?: (treshold: KeyValue<number>) => void;
  applyToggleChanges: (toggle: boolean) => void;
  editable: boolean;
  id: string;
  constraints?: Partial<Constraints>;
  defaultValue?: KeyValue<number>;
  hasChangesApplied?: boolean;
  isDisorderNotification?: boolean;
}

const Settings: React.FC<SettingsProps> = ({
  iconType,
  initialValues,
  text,
  handleApplyChanges,
  applyToggleChanges,
  editable,
  id,
  constraints,
  defaultValue,
  hasChangesApplied,
  isDisorderNotification,
}) => {
  const [toggle, setToggle] = useState(initialValues.enabled);
  const [input, setInput] = useState<any | undefined>(initialValues.value);
  const translate = useTranslate();
  const [touched, setTouched] = useState(false);

  const CORRECTION_FACTORS_LIST = UseGetNotificationSettings();

  useEffect(() => {
    setInput((previousValue: KeyValue<number>) => ({
      ...previousValue,
      ...initialValues.value,
    }));
  }, [initialValues.value]);

  const handleReset = () => {
    setTouched(true);
    if (isDisorderNotification) {
      if (!isEmptyObject(defaultValue) && defaultValue !== undefined) {
        setInput({
          ...defaultValue,
          ['correction_factor']: defaultValue.correction_factor,
        });
      }
    } else {
      if (!isEmptyObject(defaultValue)) {
        setInput({ ...defaultValue });
      }
    }
  };

  const areInputValid = () => {
    if (Object.values(input).length > 1) {
      return validateInput(0) || validateInput(1);
    } else {
      return validateInput();
    }
  };

  const getApplyButtonClass = () => {
    if (hasChangesApplied) {
      return;
    } else if (isEqual(initialValues.value, input) || !areInputValid()) {
      return styles['apply-button-disabled'];
    }
  };

  const handleInputChange = (type: string) => (value: string) => {
    if (hasChangesApplied) {
      setTouched(true);
    }
    setInput((previousValue: KeyValue<number>) => ({
      ...previousValue,
      [type]: Number(value),
    }));
  };

  const handleToggleChanges = (value: boolean) => {
    applyToggleChanges(!toggle);
    setToggle(!toggle);
  };

  const markCorrectionOnChange = (name: string, value: any) => {
    if (hasChangesApplied) {
      setTouched(true);
    }
    setInput((previousValue: KeyValue<number>) => ({
      ...previousValue,
      ['correction_factor']: Number(value.value),
    }));
  };

  const renderContent = () => {
    const splitedText = text.split(SPLIT_MARK);
    if (isDisorderNotification) {
      return (
        <div className={styles['text-content']}>
          {splitedText[0]}
          <div className={styles['input-container']}>
            <span className={styles.dropdown}>
              <DropDown
                required={false}
                initialValue={getNotificationCorrection(input, CORRECTION_FACTORS_LIST)}
                optionList={CORRECTION_FACTORS_LIST}
                setOnChange={markCorrectionOnChange}
                displayProperty='displayValue'
                rightSide
                disabled={!toggle}
              />
            </span>
            <span className={styles['error-text']}>{!validateInput() && renderErrorText()}</span>
          </div>
          {splitedText[1]}
        </div>
      );
    } else if (splitedText.length === 1) {
      return <div className={styles['text-content']}>{text}</div>;
    } else if (splitedText.length === 2) {
      return (
        <div className={styles['text-content']}>
          {splitedText[0]}
          <div className={styles['input-container']}>
            <span className={styles.input}>
              <NumberInput
                customClassName='input-container-small'
                initialValue={input?.threshold}
                onChange={handleInputChange('threshold')}
                disabled={!toggle}
              />
            </span>
            <span className={styles['error-text']}>{!validateInput() && renderErrorText()}</span>
          </div>
          {splitedText[1]}
        </div>
      );
    } else {
      return (
        <div className={styles['text-content']}>
          {splitedText[0]}
          <div className={styles['input-container']}>
            <span className={styles.input}>
              <NumberInput
                customClassName='input-container-small'
                initialValue={getNotificationThreshold(input, NotificationThreshold.Low)}
                onChange={handleInputChange('threshold_lower')}
                disabled={!toggle}
              />
            </span>
            <span className={styles['error-text']}>{!validateInput(0) && renderErrorText()}</span>
          </div>
          {splitedText[1]}
          <div className={styles['input-container']}>
            <span className={styles.input}>
              <NumberInput
                customClassName='input-container-small'
                initialValue={getNotificationThreshold(input, NotificationThreshold.High)}
                onChange={handleInputChange('threshold_upper')}
                disabled={!toggle}
              />
            </span>
            <span className={styles['error-text']}>{!validateInput(1) && renderErrorText()}</span>
          </div>
          {splitedText[2]}
        </div>
      );
    }
  };

  const updateChanges = () => {
    setTouched(false);
    if (handleApplyChanges && input) {
      handleApplyChanges({ ...input });
    }
  };

  const validateInput = (inputIndex?: number) => {
    const validationResult: boolean[] = [];
    if (constraints) {
      Object.entries(constraints).forEach(([key, value], constraintIndex) => {
        Object.entries(value).forEach(([operator, threshold]) => {
          if (inputIndex?.toString().length) {
            if (inputIndex === 0 && constraintIndex === 0) {
              validationResult.push(
                compare({
                  threshold,
                  operator,
                  input: getNotificationThreshold(input, NotificationThreshold.Low),
                })
              );
            } else if (constraintIndex === 1 && inputIndex === 1) {
              validationResult.push(
                compare({
                  threshold,
                  operator,
                  input: getNotificationThreshold(input, NotificationThreshold.High),
                })
              );
            }
          } else {
            validationResult.push(compare({ threshold, operator, input: input?.threshold }));
          }
        });
      });
    }
    return validationResult.every((result) => result === true);
  };

  const renderErrorText = () => {
    const splitedText = translate('Notification Settings Input Validation').split('$');
    const values = Object.values(constraints!)[0];
    const constraintsValues = Object.values(values);

    return `${splitedText[0]} ${constraintsValues[0]} ${splitedText[1]} ${constraintsValues[1]} ${splitedText[2]}`;
  };

  return (
    <div
      className={`${styles.container} ${!toggle && styles['container-disabled']} ${
        !editable && styles['non-editable']
      }`}
    >
      <div className={styles['settings-toggle']}>
        <ToggleSwitch initialValue={toggle} onToggle={handleToggleChanges} />
      </div>
      <NotificationItemIcon
        type={iconType}
        showEmpasised={false}
        iconStyle={[NotificationIconStyle.styleDark, NotificationIconStyle.styleBig]}
      />
      {renderContent()}
      <div className={`${styles['button-container']} ${styles['show-buttons']}`}>
        <button
          className={`btn ${styles.reset}`}
          onClick={handleReset}
          id={`tooltip-reset-${id}`}
          data-tooltip-variant='light'
        >
          <Refresh className='icon-white' />
          <Tooltip
            anchorSelect={`#tooltip-reset-${id}`}
            place='top'
            style={{ zIndex: 10, display: 'flex', height: 'auto' }}
            opacity={1}
          >
            <div className='plan-settings-details'>
              {translate('Notification Settings Reset tooltip')}
            </div>
          </Tooltip>
          {translate('Generic-reset-button')}
        </button>
        <button
          className={`btn btn-primary ${getApplyButtonClass()} ${styles['apply-button']}`}
          onClick={updateChanges}
          id={`notification-setting-apply-button-${id}`}
          data-tooltip-variant='light'
        >
          {hasChangesApplied && !touched ? (
            <span>
              <Checkmark className='icon-white' /> {translate('Generic-applied-button')}
            </span>
          ) : (
            translate('Generic-apply-button')
          )}
          <Tooltip
            anchorSelect={`#notification-setting-apply-button-${id}`}
            place='top'
            style={{ zIndex: 10, display: 'flex', height: 'auto' }}
            opacity={1}
          >
            <div className='plan-settings-details'>
              {translate('Notification Settings Apply tooltip')}
            </div>
          </Tooltip>
        </button>
      </div>
    </div>
  );
};

export default Settings;
