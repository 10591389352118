import { ReactElement } from 'react';

import Area from 'components/GraphElements/Area/Area';
import Circle from 'components/GraphElements/Circle/Circle';
import Line from 'components/GraphElements/Line/Line';
import { getUniqueId } from './getUniqueId';

export function renderLineData({
  data,
  variable,
  color,
  stroke,
  hasMask,
  axis,
  maskId,
  hasBoundaryMask = false,
  hideLine,
  maskLineColor,
  boundaryMaskId,
}: {
  data: any[];
  variable: string;
  color?: string;
  stroke?: string;
  hasMask?: boolean;
  hasBoundaryMask?: boolean;
  axis?: 'left' | 'right';
  maskId?: string;
  hideLine?: boolean;
  maskLineColor?: string;
  boundaryMaskId?: string;
}) {
  if (data?.length === 1) {
    return [<Circle data={data} property={variable} color={color} key={variable} />];
  } else {
    return [
      <Line
        data={data}
        property={variable}
        color={color}
        stroke={stroke}
        key={variable + getUniqueId()}
        hasMask={hasMask}
        axis={axis}
        maskId={maskId}
        hideLine={hideLine}
        maskLineColor={maskLineColor}
        hasBoundaryMask={hasBoundaryMask}
        boundaryMaskId={boundaryMaskId}
      />,
    ];
  }
}

export function renderAreaData({
  data,
  color,
  key,
  customPattern,
  customPatternId,
  hasMask,
  maskId,
  opacity,
}: {
  data: any[];
  color?: string;
  key?: string;
  customPattern?: ReactElement;
  customPatternId?: string;
  hasMask?: boolean;
  maskId?: string;
  opacity?: number;
}) {
  if (data?.length === 1) {
    return [
      <Circle
        data={[
          {
            ...data?.[0],
            value: data[0]?.value?.[0],
          },
        ]}
        color={color}
        property={'value'}
      />,
      <Circle
        data={[
          {
            ...data?.[0],
            value: data[0]?.value?.[1],
          },
        ]}
        color={color}
        property={'value'}
      />,
    ];
  } else {
    return [
      <Area
        key={key}
        data={data}
        color={color}
        customPattern={customPattern}
        customPatternId={customPatternId}
        hasMask={hasMask}
        maskId={maskId}
        opacity={opacity}
      />,
    ];
  }
}
