import { FC, ReactNode } from 'react';
import { ReactComponent as CloseIcon } from 'styles/icons/close.svg';

import './SideSlide.scss';

interface ISideSlideProps {
  isOpen?: boolean;
  hasHeader?: boolean;
  onClose?: () => void;
  title?: ReactNode;
  children?: ReactNode | ReactNode[];
}

const SideSlide: FC<ISideSlideProps> = ({
  isOpen = false,
  hasHeader = true,
  title = '',
  onClose = () => {},
  children = '',
}) => {
  return (
    <div className={`mumus ${isOpen ? 'active' : ''}`}>
      <div className={`side-slide-container`}>
        {hasHeader ? (
          <div className='side-slide-header'>
            {title}
            <CloseIcon className='icon-close ' onClick={() => onClose()} />
          </div>
        ) : (
          ''
        )}
        {children}
      </div>
    </div>
  );
};
export default SideSlide;
