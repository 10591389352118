import { translate } from 'utils/translations.util';
import './DialogBox.scss';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { replacePlaceholders } from 'utils/general.util';

interface UnLinkPlanToCropSeasonMessageProps {
  touched?: boolean;
  name: string;
}

const UnLinkPlanToCropSeasonMessage: React.FC<UnLinkPlanToCropSeasonMessageProps> = ({
  touched = false,
  name,
}) => {
  const { selectedLanguage } = useAppSelector(languageSelector);
  let message = translate('dialogBox - unlikCropseason - message', selectedLanguage);

  const finalMessage = replacePlaceholders(message, [name]);
  return <div className='dialog-box-body'>{finalMessage}</div>;
};

export default UnLinkPlanToCropSeasonMessage;
