import './DatePickerSelector.scss';

import { Dayjs } from 'dayjs';
import React from 'react';
import { ReactComponent as ChevronLeft } from 'styles/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'styles/icons/chevron-right.svg';

export interface ICalendarCell {
  text: string;
  value: Dayjs;
}

export interface IDatePickerSelectorProps {
  shownDate: Dayjs;
  increment: () => void;
  decrement: () => void;
}

export const DatePickerSelector: React.FC<IDatePickerSelectorProps> = ({
  shownDate,
  increment,
  decrement,
}) => {
  return (
    <div className={'datepicker-selector'}>
      <div className={'icon icon-left'} onClick={decrement}>
        <ChevronLeft className='icon-fill' />
      </div>

      <div className={'date'}>{shownDate.format('MMMM YYYY')}</div>

      <div className={'icon icon-right'} onClick={increment}>
        <ChevronRight className='icon-fill' />
      </div>
    </div>
  );
};
