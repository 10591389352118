import { STRATEGIES_FIELDS } from 'config/constants';
import { Range } from 'interfaces/range';
import { Strategy } from 'interfaces/strategies';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { strategiesActions } from 'redux/slices/planStrategies.slice';
import { extractMinMax } from 'utils/getIntervalsForGraphs';

interface Input {
  strategies: any;
  yieldValue: Range;
  brix: Range;
  afw: Range;
}

export function useFilteredData({ strategies, yieldValue, brix, afw }: Input) {
  const dispatch = useAppDispatch();

  const [filteredData, setFilteredData] = useState<Strategy[]>();

  useEffect(() => {
    if (strategies?.data) {
      const data = [...strategies?.data];

      const filtered: Strategy[] = data.filter(
        (data) => data.yield >= yieldValue.min && data.yield <= yieldValue.max
      );
      const ranges = extractMinMax(filtered, STRATEGIES_FIELDS);

      dispatch(
        strategiesActions.setFilter({
          brix: ranges.brix,
          afw: ranges.afw,
        })
      );

      setFilteredData(filtered);
      dispatch(strategiesActions.setPagination({ currentPage: 1 }));
      dispatch(strategiesActions.setPagination({ totalCount: filtered.length }));
    }
  }, [strategies?.data, yieldValue.max, yieldValue.min]);

  useEffect(() => {
    if (strategies?.data) {
      const data = [...strategies?.data];
      const filtered = data.filter((data) => Math.floor(data.brix) >= brix.min && Math.floor(data.brix) <= brix.max);
      const ranges = extractMinMax(filtered, STRATEGIES_FIELDS);

      dispatch(
        strategiesActions.setFilter({
          afw: ranges.afw,
        })
      );
      setFilteredData(filtered);
      dispatch(strategiesActions.setPagination({ totalCount: filtered.length }));
    }
  }, [brix.min, brix.max]);

  useEffect(() => {
    if (strategies?.data) {
      const data = [...strategies?.data];
      const filtered = data.filter((data) => data.afw >= afw.min && data.afw <= afw.max);
      setFilteredData(filtered);
      dispatch(strategiesActions.setPagination({ totalCount: filtered.length }));
    }
  }, [afw.min, afw.max]);

  return filteredData;
}

export default useFilteredData;
