import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { QUERY_LIMIT } from 'config/constants';
import { KeyValue } from 'interfaces/keyvalue';
import { Resolution } from 'interfaces/resolution';
import { baseApi } from 'redux/slices/baseApi.slice';
import { getRanges, mergeKeyValues, mergeRanges } from 'utils/getIntervalsForGraphs';
import { formatQueryString } from 'utils/queryString';
import {
  addResolutionToTimestamp,
  getEndOfDayFormated,
  getStartOfDayFormated,
  getUnix,
  subtractResolutionFromTimestamp,
} from 'utils/time.util';
import {
  getKeyValuesFromVariables,
  getResolutionFromMissingVariables,
  getVariablesBasedOnResolution,
} from 'utils/variables.util';

interface QueryParams {
  cropSeasonId: number;
  startDate: string;
  endDate: string;
  resolution: string;
  variables: KeyValue<string>;
  accountId: number;
  skip?: number;
  limit?: number;
  refetchQueryStartTime?: string | null;
}

const query = ({
  cropSeasonId,
  startDate,
  endDate,
  resolution,
  variables,
  accountId,
  skip,
  limit,
  refetchQueryStartTime = null,
}: QueryParams) => {
  const ENDPOINT = `cropseasons/timeseries?`;
  return (
    ENDPOINT +
    formatQueryString({
      id: cropSeasonId,
      skip: skip || 0,
      limit: limit || QUERY_LIMIT,
      resolution: resolution,
      min_local_datetime:
        refetchQueryStartTime ||
        getStartOfDayFormated(
          subtractResolutionFromTimestamp({
            input: startDate,
            resolution: resolution as Resolution,
          })
        ),
      max_local_datetime: getEndOfDayFormated(
        addResolutionToTimestamp({ input: endDate, resolution: resolution as Resolution })
      ),
      variable: Object.values(variables),
      current_account_id: accountId,
    })
  );
};

const transformResponse = (rawResult: any, { variables, startDate, endDate }: QueryParams): any => {
  let response: any = {
    ranges: {},
    refetch: false,
  };

  const resultTimeseries = rawResult?.result?.timeseries;

  if (!resultTimeseries || !Object.keys(resultTimeseries).length) {
    return response;
  }

  const firstCropSeasonId = Object.keys(rawResult?.result?.timeseries)[0];
  const result = rawResult?.result?.timeseries[firstCropSeasonId];

  response.refetch = rawResult?.datetime_pagination?.next_min_local_datetime < endDate;
  response.start_local_datetime = rawResult?.datetime_pagination?.next_min_local_datetime;
  response.ranges = getRanges(result, variables);

  const timestamps = result.local_datetime.map((datetime: string) => getUnix(datetime));

  Object.keys(variables).forEach((variable) => {
    response[variable] = timestamps.flatMap((timestamp: number, i: number) => {
      return {
        timestamp,
        [`${variable}Actual`]: result[variables[variable]]?.[i],
      };
    });
  });

  return { data: response, lastTimestamp: timestamps[timestamps.length - 1] };
};

const retrieveTheData = async (
  _arg: QueryParams,
  fetchWithBaseQuery: (
    arg: string | FetchArgs
  ) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>
) => {
  let transformedResponse;
  let missingVariables: string[] = [];
  let response;
  let startDateTime = null;
  let lastTimestamp = 0;
  do {
    let partialResponse: any = await fetchWithBaseQuery(
      query({ ..._arg, startDate: _arg.startDate, refetchQueryStartTime: startDateTime })
    );
    if (partialResponse.error?.status === 404) {
      return { response: null, missingVariables: Object.values(_arg.variables) };
    }

    const transformRepsonse = transformResponse(partialResponse.data, _arg);

    transformedResponse = transformRepsonse.data;
    lastTimestamp = transformRepsonse.lastTimestamp || lastTimestamp;

    startDateTime = transformedResponse.start_local_datetime;

    missingVariables = (partialResponse.data as any)?.result.variables_not_found;

    if (response) {
      response = {
        ...mergeKeyValues(Object.keys(_arg.variables), response, transformedResponse),
        ranges: mergeRanges(response.ranges, transformedResponse.ranges),
      };
    } else {
      response = { ...transformedResponse };
    }
  } while (transformedResponse.refetch);

  return { missingVariables, response, lastTimestamp };
};

const actualsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getActuals: builder.query<any, QueryParams>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        const variables = getVariablesBasedOnResolution(
          _arg.variables,
          _arg.resolution as Resolution
        );
        const args = { ..._arg, variables };
        let savedLastTimestamp;

        let { missingVariables, response, lastTimestamp } = await retrieveTheData(
          args,
          fetchWithBaseQuery
        );

        const missingResolutionsMap = getResolutionFromMissingVariables(
          args.variables,
          missingVariables
        );

        for (let entry of Object.entries(missingResolutionsMap)) {
          const { response: partialResponse, lastTimestamp } = await retrieveTheData(
            {
              ...args,
              resolution: entry[0],
              variables: getKeyValuesFromVariables(variables, entry[1]),
            },
            fetchWithBaseQuery
          );
          savedLastTimestamp = lastTimestamp;

          if (!response) {
            response = partialResponse;
          } else if (partialResponse) {
            response = {
              ...mergeKeyValues(Object.keys(variables), response, partialResponse),
              ranges: mergeRanges(response.ranges, partialResponse.ranges),
            };
          }
        }

        return { data: { ...response, lastTimestamp: lastTimestamp || savedLastTimestamp } };
      },
    }),
  }),
});

export const { useGetActualsQuery } = actualsApi;
