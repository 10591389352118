import React from 'react';

import styles from './StrategiesFilter.module.scss';
import StrategiesInput from 'components/StrategiesInput/StrategiesInput';
import StrategiesSlider from 'components/StrategiesSlider/StrategiesSlider';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { strategiesActions, strategiesSelectors } from 'redux/slices/planStrategies.slice';
import { KeyValue } from 'interfaces/keyvalue';
import { Range } from 'interfaces/range';

interface StrategiesFilterProps {
  ranges: KeyValue<Range>;
}

const StrategiesFilter: React.FC<StrategiesFilterProps> = ({ ranges }) => {
  const dispatch = useAppDispatch();

  const { afw, brix, yield: yieldValue } = useAppSelector(strategiesSelectors.filters);

  const handleSliderChange = (name: string) => (values: [number, number]) => {
    dispatch(
      strategiesActions.setFilter({
        [name]: { min: values[0], max: values[1] },
      })
    );
  };

  const handleInputChange = (name: string) => (field: string) => (value: string) => {
    dispatch(strategiesActions.setSingleFilter({ name, value: { [field]: Number(value) } }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles['section-title']}>Cumulative yield (kg/m²)</div>
        <div className={styles['section-inputs']}>
          <StrategiesInput value={yieldValue.min} setOnChange={handleInputChange('yield')('min')} />
          <StrategiesInput value={yieldValue.max} setOnChange={handleInputChange('yield')('max')} />
        </div>
        <div className={styles['section-slider']}>
          <StrategiesSlider
            min={ranges?.yield.min}
            max={ranges?.yield.max}
            values={[yieldValue.min, yieldValue.max]}
            setOnChange={handleSliderChange('yield')}
          />
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles['section-title']}>Average Brix (B)</div>
        <div className={styles['section-inputs']}>
          <StrategiesInput value={brix.min} setOnChange={handleInputChange('brix')('min')} />
          <StrategiesInput value={brix.max} setOnChange={handleInputChange('brix')('max')} />
        </div>
        <div className={styles['section-slider']}>
          <StrategiesSlider
            min={ranges?.brix.min}
            max={ranges?.brix.max}
            values={[brix.min, brix.max]}
            setOnChange={handleSliderChange('brix')}
          />
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles['section-title']}>Average fruit weight (g)</div>
        <div className={styles['section-inputs']}>
          <StrategiesInput value={afw.min} setOnChange={handleInputChange('afw')('min')} />
          <StrategiesInput value={afw.max} setOnChange={handleInputChange('afw')('max')} />
        </div>
        <div className={styles['section-slider']}>
          <StrategiesSlider
            min={ranges?.afw.min}
            max={ranges?.afw.max}
            values={[afw.min, afw.max]}
            setOnChange={handleSliderChange('afw')}
          />
        </div>
      </div>
    </div>
  );
};
export default StrategiesFilter;
