import './App.scss';

import { useUnleashContext } from '@unleash/proxy-client-react';
import Spinner from 'components/Spinner/Spinner';
import { useConsumeInviteToken } from 'hooks/useConsumeInviteToken';
import useDayJsTranslated from 'hooks/useDayJsLanguage';
import { useEffect } from 'react';
import Router from 'routes/Router';
import AuthService from 'services/auth.service';
import { Userpilot } from 'userpilot';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}

const App = () => {
  const updateContext = useUnleashContext();
  const userDetails = AuthService.getUserDetails();
  const appReady = useConsumeInviteToken();

  Userpilot.initialize(process.env.REACT_APP_USERPILOT_KEY || '');

  useEffect(() => {
    if (!userDetails) {
      return;
    }

    updateContext({ userId: userDetails.preferred_username });
    Userpilot.identify(userDetails.preferred_username, {
      name: userDetails.given_name,
      email: userDetails.email,
    });
  }, [userDetails, updateContext]);

  useDayJsTranslated();

  return appReady ? <Router /> : <Spinner />;
};

export default App;
