import { activeCropseasonInitialValue } from 'config/constants';
import dayjs from 'dayjs';
import { KeyValue } from 'interfaces/keyvalue';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { cropseasonSelector } from 'redux/slices/cropseason.slice';
import { trackFilterSelector } from 'redux/slices/trackFilterSlice';
import { useGetAdaptivePlanQuery } from 'services/adaptivePlan.service';
import useFeatureFlags from './useFeatureFlags';

export function useGetAdaptivePlanData(variables: KeyValue<string>, startDate: number) {
  const { dates } = useAppSelector(trackFilterSelector);
  const { id: cropseasonId } = useAppSelector(cropseasonSelector);
  const accountId = useAppSelector(accountIdSelector).accountId;

  const { enableAdaptivePlan } = useFeatureFlags();

  const { data: adaptivePlan, isSuccess } = useGetAdaptivePlanQuery(
    {
      cropseasonId,
      accountId,
      variables: variables,
      startDate: dayjs.unix(dates.start).toISOString(),
      endDate: dayjs.unix(dates.end).toISOString(),
    },
    {
      skip: cropseasonId === activeCropseasonInitialValue.id || !accountId || !enableAdaptivePlan,
    }
  );

  return isSuccess ? adaptivePlan : undefined;
}
