import './StrategyPreview.scss';

import Switch from 'components/Switch/Switch';
import useTranslate from 'hooks/useTranslate';
import { KeyValue } from 'interfaces/keyvalue';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { plansSelector, setEditViewMode } from 'redux/slices/plansSlice';
import { useGetPlanQuery } from 'services/plans.service';
import { useGetShopQuery, useGetStrategiesTimeseriesQuery } from 'services/shop.service';
import { ReactComponent as ArrowLeft } from 'styles/icons/arrow-left.svg';
import { ReactComponent as BarChart } from 'styles/icons/bar-chart.svg';
import { ReactComponent as List } from 'styles/icons/list.svg';
import { roundToDecimals } from 'utils/roundToDecimals';

import PreviewGraph from './PreviewGraph/PreviewGraph';
import PreviewTable from './PreviewTable/PreviewTable';
import ExportTableContent from 'views/EditPlans/ExportTableContent/ExportTableContent';

const StrategyPreview: React.FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let { planId, shopId, strategyId } = useParams();
  const translate = useTranslate();

  const { data: planDetails } = useGetPlanQuery(Number(planId), {
    skip: !planId,
  });

  const { data: shopDetails } = useGetShopQuery(shopId || '', {
    skip: !shopId,
  });

  let strategyDetails;
  if (shopDetails && shopDetails.strategies) {
    strategyDetails = shopDetails.strategies.find(
      ({ id }: { id: number }) => id === Number(strategyId)
    );
  }

  const planParameters = planDetails?.parameters;
  const { editViewMode } = useAppSelector(plansSelector);

  useEffect(() => {
    dispatch(setEditViewMode('GRAPH'));
  }, [dispatch]);

  const { data: timeseriesData } = useGetStrategiesTimeseriesQuery(
    {
      shopId: shopId || '',
      strategyId: strategyId!.toString() || '',
    },
    { skip: !shopId || !strategyId }
  );

  //TODO: the format of the data does not seem okay at all. this is a workaround to be able to work with it.
  // will want to have some discussions and update it
  let timeseries: any = null;
  if (timeseriesData && strategyId) {
    const strategyData = timeseriesData;
    timeseries = {};
    Object.keys(strategyData).forEach((key) => {
      if (key.toString() === 'ranges') {
        timeseries[key] = strategyData[key];
        return;
      }

      timeseries[key] = strategyData[key].map((point: KeyValue<number>) => {
        const { timestamp, dateTimeWeek } = point;
        const value = point[`${key}${strategyId}`];
        return { [key]: value, timestamp: timestamp, dateTimeWeek };
      });
    });
  }

  const handleSwitchChange = (buttonState: 1 | 2) => {
    if (buttonState === 1) {
      return dispatch(setEditViewMode('GRAPH'));
    } else {
      return dispatch(setEditViewMode('TABLE'));
    }
  };

  const getColorMode = () => {
    if (editViewMode === 'GRAPH') {
      return 'DARK';
    } else {
      return 'LIGHT';
    }
  };

  const previewHeader = (
    <>
      <div>
        <span className='header-variable'>CUMULATIVE YIELD</span>
        {`${roundToDecimals(strategyDetails?.yield, 2) || 0} ${translate('kg/m^2')}`}
      </div>
      <div>
        <span className='header-variable'>BRIX</span>
        {`${roundToDecimals(strategyDetails?.brix, 2) || 0} ${translate('B')}`}
      </div>
      <div>
        <span className='header-variable'>AVERAGE FRUIT WEIGHT</span>
        {`${roundToDecimals(strategyDetails?.afw, 2) || 0} ${translate('g')}`}
      </div>
      <div className='export-container'>
        <ExportTableContent
          timeseriesData={timeseries}
          planName={translate('Strategy preview export file name')}
        />
      </div>
    </>
  );

  return (
    <>
      <div
        className={`strategy-preview-container ${
          editViewMode === 'TABLE' ? 'strategy-preview-table-view' : ''
        }`}
      >
        <div className='strategy-preview-page-name'>Plan / Create Plan</div>
        <div className='strategy-preview-header'>
          <div className='strategy-preview-page-name-mobile'>Plan / Create Plan</div>
          <div className='strategy-preview-title-container'>
            <ArrowLeft
              className='strategy-preview-back icon-light c-hand'
              width={30}
              height={30}
              onClick={() => {
                navigate(-1);
              }}
            />
            <h2 className='strategy-preview-title'>{planDetails?.name || '-'}</h2>
          </div>
          <div className='strategy-preview-crop-information'>
            <div className='strategy-preview-crop-variety'>
              <div className='strategy-preview-crop-variety-field'>
                {translate('Plan view - crop variety of the plan')}
              </div>
              <div className='strategy-preview-crop-variety-value'>
                {planParameters?.variety.name || '-'}
              </div>
            </div>
            <div className='strategy-preview-crop-location'>
              <div className='strategy-preview-crop-location-field'>
                {translate('Plan view - Location of the plan')}
              </div>
              <div className='strategy-preview-crop-location-value'>
                {planParameters?.location?.name || '-'}
              </div>
            </div>
          </div>
          <div className='strategy-preview-actions'>
            <div className='strategy-preview-actions-switch c-hand'>
              <Switch onChange={handleSwitchChange} colorMode={getColorMode()}>
                <BarChart className='icon-svg' />
                <List className='icon-svg' />
              </Switch>
            </div>
          </div>
        </div>
        {editViewMode === 'GRAPH' ? (
          <PreviewGraph timeseriesData={timeseries} header={previewHeader} />
        ) : (
          <PreviewTable timeseriesData={timeseries} header={previewHeader} />
        )}
      </div>
    </>
  );
};
export default StrategyPreview;
