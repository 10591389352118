import React from 'react';
import Slider from 'rc-slider';

import styles from './StrategiesSlider.module.scss';
import 'rc-slider/assets/index.css';
import { roundToDecimals } from 'utils/roundToDecimals';

interface StrategiesSliderProps {
  min: number;
  max: number;
  values: [number, number];
  setOnChange: (values: [number, number]) => void;
}

const StrategiesSlider: React.FC<StrategiesSliderProps> = ({
  min = 0,
  max = 100,
  values,
  setOnChange,
}) => {
  const handleChange = (values: number[] | number) => {
    const [firstValue, maxValue] = values as number[];
    setOnChange([firstValue, maxValue]);
  };

  return (
    <div>
      <Slider
        range
        allowCross={false}
        min={min}
        max={max}
        value={values}
        handleStyle={{
          width: 18,
          height: 18,
          backgroundColor: '#fff',
          opacity: 1,
          borderColor: '#00BCFF',
          top: 6,
        }}
        trackStyle={{ backgroundColor: '#00BCFF', height: '10px' }}
        onChange={handleChange}
        railStyle={{
          borderTop: '2px solid #d9d9d9',
          borderRadius: '12px',
          height: '10px',
        }}
        step={1}
      />
      <div className={styles['slider-values']}>
        <span className={styles.value}>{roundToDecimals(min, 2)}</span>
        <span className={styles.value}>{roundToDecimals(max, 2)}</span>
      </div>
    </div>
  );
};
export default StrategiesSlider;
