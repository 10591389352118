import PlansComparisonTable from '../PlansComparisonTable/PlansComparisonTable';
import { ReactComponent as ArrowLeft } from 'styles/icons/arrow-left.svg';
import style from './PlansComparisonStep.module.scss';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const PlansComparisonStep = () => {
  let navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };
  const [visibleWarning, setVisibleWarning] = useState(true);
  return (
    <div className={style['compare-strategy-graph-page']}>
      <div className={style['compare-strategy-graph-body']}>
        <WarningMessage
          title='Info'
          message='Only 2 values can be selected for comparison.'
          noMargin={true}
          isVisible={visibleWarning}
          handleClose={() => {
            setVisibleWarning((isVisible) => !isVisible);
          }}
        />
        <div className={style['compare-strategy-graph-title']}>
          <ArrowLeft
            className={`${style['compare-strategy-graph-title-icon']} c-hand`}
            onClick={navigateBack}
          />
          <h2>Comparison of plan strategies</h2>
        </div>
        <PlansComparisonTable></PlansComparisonTable>
      </div>
    </div>
  );
};

export default PlansComparisonStep;
