import './DatesStep.scss';

import { DatePicker } from 'components/DatePicker/DatePicker';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { planCreationSelector, setOption } from 'redux/slices/planCreationSlice';
import { track } from 'utils/analtycs';
import {
  firstSettingDateValidation,
  harvestValidation,
  headRemovalValidation,
  sowingValidation,
} from 'utils/cropPlanDatesValidation.util';
import { translate } from 'utils/translations.util';

interface DatesStepProps {
  lastValidStep: boolean;
  checkIsValidStep: (stepNumber: number) => boolean;
}

const DatesStep: React.FC<DatesStepProps> = ({ lastValidStep }) => {
  const dispatch = useAppDispatch();
  const { selectedLanguage } = useAppSelector(languageSelector);

  const {
    linkedCropseason,
    plantingDate,
    headRemovalDate,
    harvestDate,
    sowingDate,
    firstSettingDate,
    touchedHeadRemovalDate,
    touchedHarvestDate,
    touchedSowingDate,
    touchedFirstSettingDate,
  } = useAppSelector(planCreationSelector);

  const onChangeDate = (name: string) => (date: any) => {
    track(`plan create step 4`, {
      field: name,
      value: date?.utc(true),
    });
    if (
      name === 'plantingDate' &&
      !headRemovalDate &&
      !harvestDate &&
      !sowingDate &&
      !firstSettingDate
    ) {
      dispatch(
        setOption({
          [name]: date.unix(),
          headRemovalDate: dayjs(date).add(40, 'week').unix(),
          harvestDate: dayjs(date).add(50, 'weeks').unix(),
          sowingDate: dayjs(date).subtract(80, 'day').unix(),
          firstSettingDate: dayjs(date).add(23, 'day').unix(),
        })
      );
    } else {
      if (name === 'headRemovalDate') {
        dispatch(setOption({ touchedHeadRemovalDate: true }));
      } else if (name === 'sowingDate') {
        dispatch(setOption({ touchedSowingDate: true }));
      } else if (name === 'firstSettingDate') {
        dispatch(setOption({ touchedFirstSettingDate: true }));
      } else {
        dispatch(setOption({ touchedHarvestDate: true }));
      }
      dispatch(
        setOption({
          [name]: date.unix(),
        })
      );
    }
  };

  const renderPrefilledText = () => {
    return <span className='preffiled-text'>Prefilled based on your chosen CropSeason.</span>;
  };

  const showPrefilledText = (type: string) => {
    if (type === 'headRemovalDate') {
      return (
        !!linkedCropseason?.id &&
        !!headRemovalDate &&
        !touchedHeadRemovalDate &&
        !lastValidStep &&
        !!linkedCropseason &&
        renderPrefilledText()
      );
    } else if (type === 'harvestDate') {
      return (
        !!linkedCropseason?.id &&
        !!harvestDate &&
        !lastValidStep &&
        !touchedHarvestDate &&
        !!linkedCropseason &&
        renderPrefilledText()
      );
    } else if (type === 'sowingDate') {
      return (
        !!linkedCropseason?.id &&
        !!sowingDate &&
        !lastValidStep &&
        !touchedSowingDate &&
        !!linkedCropseason &&
        renderPrefilledText()
      );
    } else if (type === 'firstSettingDate') {
      return (
        !!linkedCropseason?.id &&
        !!firstSettingDate &&
        !lastValidStep &&
        !touchedFirstSettingDate &&
        !!linkedCropseason &&
        renderPrefilledText()
      );
    }
  };

  useEffect(() => {
    if (!headRemovalDate && !harvestDate && !sowingDate && !firstSettingDate && plantingDate) {
      dispatch(
        setOption({
          headRemovalDate: dayjs.unix(plantingDate!).add(40, 'week').unix(),
          harvestDate: dayjs.unix(plantingDate!).add(50, 'weeks').unix(),
          sowingDate: dayjs(plantingDate!).subtract(80, 'day').unix(),
          firstSettingDate: dayjs(plantingDate!).add(23, 'day').unix(),
        })
      );
    }
  }, [plantingDate, dispatch, headRemovalDate, harvestDate, sowingDate]);

  const checkDates = (firstDate: string, secondDate: number) => {
    return dayjs(firstDate).unix() === secondDate;
  };

  return (
    <div className='date-step'>
      <div className='step-title date-step-title'>
        {translate('Create Plan - go to select crop plan dates screen', selectedLanguage)}
      </div>
      <div className={'dropdown-containers date-step-container date-step-vertical'}>
        <div className='datepicker-container'>
          <div className='timeline-first timeline-dot'></div>
          <DatePicker
            insideTooltip={true}
            startDate={sowingDate ? dayjs.unix(sowingDate) : undefined}
            onChange={onChangeDate('sowingDate')}
            label={translate('Create Plan - select the sowing date', selectedLanguage)}
            dropDownPosition='dropdown-datepicker-middle dropdown-datepicker-bottom'
            required={true}
            disabled={!plantingDate}
          />
          {lastValidStep && sowingValidation(plantingDate, sowingDate) && (
            <span className='error-text'>
              {lastValidStep && sowingValidation(plantingDate, sowingDate)}
            </span>
          )}
          {showPrefilledText('sowingDate')}
        </div>

        <div className='datepicker-container'>
          <div className='timeline timeline-dot'></div>
          <DatePicker
            insideTooltip={true}
            startDate={plantingDate ? dayjs.unix(plantingDate) : undefined}
            onChange={onChangeDate('plantingDate')}
            label={translate('Create Plan - select the planting date', selectedLanguage)}
            dropDownPosition='dropdown-datepicker-middle dropdown-datepicker-bottom'
            required={true}
          />
          {lastValidStep && !plantingDate && (
            <span className='error-text'>
              {translate('Create Plan - select the planting date warning', selectedLanguage)}
            </span>
          )}
          {!!linkedCropseason?.id &&
            checkDates(linkedCropseason?.transplant_date!, plantingDate!) && (
              <span className='preffiled-text'>Prefilled based on your chosen CropSeason.</span>
            )}
        </div>

        <div className='datepicker-container'>
          <div className='timeline timeline-dot'></div>

          <DatePicker
            insideTooltip={true}
            startDate={firstSettingDate ? dayjs.unix(firstSettingDate) : undefined}
            onChange={onChangeDate('firstSettingDate')}
            label={'First Setting Date'}
            dropDownPosition='dropdown-datepicker-middle dropdown-datepicker-top'
            required={true}
            disabled={!plantingDate}
          />
          {lastValidStep &&
            firstSettingDateValidation(headRemovalDate, plantingDate, firstSettingDate) && (
              <span className='error-text'>
                {lastValidStep &&
                  firstSettingDateValidation(headRemovalDate, plantingDate, firstSettingDate)}
              </span>
            )}
          {showPrefilledText('firstSettingDate')}
        </div>

        <div className='datepicker-container'>
          <div className='timeline timeline-dot'></div>
          <DatePicker
            insideTooltip={true}
            startDate={headRemovalDate ? dayjs.unix(headRemovalDate) : undefined}
            label={translate('Create Plan - select the planned head removal', selectedLanguage)}
            dropDownPosition='dropdown-datepicker-middle dropdown-datepicker-top'
            onChange={onChangeDate('headRemovalDate')}
            required={true}
            disabled={!plantingDate}
          />
          {lastValidStep && headRemovalValidation(harvestDate, plantingDate, headRemovalDate) && (
            <span className='error-text'>
              {lastValidStep && headRemovalValidation(harvestDate, plantingDate, headRemovalDate)}
            </span>
          )}
          {showPrefilledText('headRemovalDate')}
        </div>

        <div className='datepicker-container'>
          <div className='timeline-last timeline-dot'></div>
          <DatePicker
            insideTooltip={true}
            startDate={harvestDate ? dayjs.unix(harvestDate) : undefined}
            label={translate('Create Plan - select the last harvest date', selectedLanguage)}
            onChange={onChangeDate('harvestDate')}
            dropDownPosition='dropdown-datepicker-middle dropdown-datepicker-top'
            required={true}
            disabled={!plantingDate}
          />
          {lastValidStep && harvestValidation(harvestDate, plantingDate, headRemovalDate) && (
            <span className='error-text'>
              {lastValidStep && harvestValidation(harvestDate, plantingDate, headRemovalDate)}
            </span>
          )}
          {showPrefilledText('harvestDate')}
        </div>
      </div>
    </div>
  );
};
export default DatesStep;
