import React, { Children, cloneElement } from 'react';
import { useAreaController } from '../hooks/Area.controller';
import Mask from '../Mask/Mask';

interface ClippingMaskProps {
  xScale?: any;
  yScale?: any;
  width?: number;
  height?: number;
  transform?: string;
  data?: { path?: string; areaData?: any };
  maskId?: string;
  children?: JSX.Element | JSX.Element[];
  isInverted?: boolean;
}

const ClippingMask: React.FC<ClippingMaskProps> = ({
  xScale,
  yScale,
  width,
  height,
  transform,
  maskId,
  data,
  children,
  isInverted,
}) => {
  const constructedPath = useAreaController(data?.areaData, xScale, yScale);

  const updateChildrenWithProps = Children.map(children, (child, i) => {
    return cloneElement(child!, {
      xScale,
      yScale,
      transform,
      height,
      maskId,
      width,
      areaPath: data?.path || constructedPath,
    });
  });

  return (
    <>
      <Mask
        areaPath={data?.path || constructedPath}
        height={height}
        width={width}
        isInverted={isInverted}
        id={maskId || ''}
      />
      {updateChildrenWithProps}
    </>
  );
};
export default ClippingMask;
