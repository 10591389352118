import Keycloak, { KeycloakLoginOptions } from 'keycloak-js';
import { setLanguage } from 'redux/slices/language.slice';
import { setUserInfo } from 'redux/slices/userInfo.slice';
import store from 'redux/store';
import { hasQueryParam } from 'utils/queryString';

const _kc = new Keycloak({
  realm: process.env.REACT_APP_REALM!,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_CLIENT_ID!,
});

const originalCreateLoginUrl = _kc.createLoginUrl;
const token = localStorage.getItem('kc_token')!;
const refreshToken = localStorage.getItem('kc_refreshToken')!;

const initKeycloak = (onAuthenticatedCallback: () => void, redirectPath: string) => {
  // we overwrite the original create login url, so it redirects based on the params either to login or to register
  _kc.createLoginUrl = (options?: KeycloakLoginOptions | undefined) => {
    const hasInviteLink = hasQueryParam('invite_token_id');

    let updatedOptions: KeycloakLoginOptions = {};

    if (options) {
      updatedOptions = { ...options };
    }

    if (hasInviteLink) {
      updatedOptions.action = 'register';
    }

    return originalCreateLoginUrl(updatedOptions);
  };

  _kc
    .init({
      onLoad: 'login-required',
      token,
      refreshToken,
      redirectUri: process.env.REACT_APP_REDIRECT_URI + redirectPath,
      checkLoginIframe: false,
      scope: 'phone',
    })
    .then((authenticated: boolean) => {
      localStorage.setItem('kc_token', _kc.token!);
      localStorage.setItem('kc_refreshToken', _kc.refreshToken!);
      const user = getUserDetails();
      if (user) {
        store.dispatch(setLanguage(user.locale));
        store.dispatch(
          setUserInfo({
            givenName: user.given_name,
            familyName: user.family_name,
            email: user.email,
          })
        );
      }
      window.analytics.identify(`${user?.sub}`, {
        email: user?.email,
      });

      if (!authenticated) {
        console.error('User is not authenticated!');
        return;
      }
      return onAuthenticatedCallback();
    })
    .catch((error) => {
      console.error(error);
      _kc.logout();
      _kc.login();
    });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const isTokenExpired = () => _kc.isTokenExpired();

const updateToken2 = () => _kc.updateToken(5);

const updateToken = (successCallback: () => void) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles: []) => roles.some((role) => _kc.hasRealmRole(role));

const getUserDetails = () => {
  return _kc.tokenParsed;
};

const getKeycloakParameters = () => {
  return {
    refresh_token: String(_kc.refreshToken),
    client_secret: String(_kc.clientSecret),
    client_id: String(_kc.clientId),
    grant_type: 'refresh_token',
  };
};

const getAccountUrl = () => _kc.createAccountUrl()?.split('?')[0];

const AuthService = {
  getKeycloakParameters,
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getUserDetails,
  isTokenExpired,
  updateToken2,
  getAccountUrl,
};

export default AuthService;
