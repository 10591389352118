import React from 'react';

import styles from './BenchMarkChips.module.scss';
import { UidName } from 'interfaces/uid-name';

interface BenchMarkChipsProps {
  options: UidName[];
  setOnChange: (input: UidName) => void;
  currentValues: UidName[];
}

const BenchMarkChips: React.FC<BenchMarkChipsProps> = ({ options, setOnChange, currentValues }) => {
  const handleClick = (input: UidName) => {
    setOnChange(input);
  };

  const checkOption = (input: string) => {
    const isOptionChecked = currentValues.map(({ name }) => name).includes(input);
    if (isOptionChecked) {
      return styles.active;
    }
  };

  return (
    <div className={styles.container}>
      {options?.map((option, index) => (
        <div
          className={`${styles.option} ${checkOption(option.name)} c-hand`}
          onClick={() => {
            handleClick(option);
          }}
          key={index}
        >
          {option.name}
        </div>
      ))}
    </div>
  );
};
export default BenchMarkChips;
