import { MAX_LENGTH_PLAN_NAME, MIN_LENGTH_PLAN_NAME, SORT } from 'config/constants';
import useSorter from 'hooks/useSorter';
import { Strategy, StrategyHeaderItem } from 'interfaces/strategies';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { planCreationSelector, reset, setOption } from 'redux/slices/planCreationSlice';
import { strategiesActions, strategiesSelectors } from 'redux/slices/planStrategies.slice';
import { ReactComponent as Eye } from 'styles/icons/eye.svg';
import { roundToDecimals } from 'utils/roundToDecimals';
import { getSortingHoverIcon, getSortingIcon } from 'utils/sort-icon-getter';
import SavePlanAsContent from 'views/EditPlans/EditPlanSidebar/SavePlanAsContent/SavePlanAsContent';
import styles from './StrategiesTable.module.scss';
import { SidebarFileFolderNames } from 'views/EditPlans/EditPlanSidebar/EditPlanSidebar';
import { checkFileName } from 'utils/validation.util';
import { ModalService } from 'services/modal.service';
import { useUpdatePlanMutation } from 'services/plans.service';
import { useSaveStrategyMutation } from 'services/shop.service';
import { setStep } from 'redux/slices/stepperSlice';

interface StrategiesTableProps {
  strategies: Strategy[];
  shopId: string;
  planId: number;
}

const HEADER_STRATEGIES: StrategyHeaderItem[] = [
  { id: 'header_select', name: '', class: 'cell-plan-row-select' },
  { id: 'header_mame', name: 'Name', sortingField: 'name', class: 'cell-plan-name' },
  {
    id: 'header_yield',
    name: 'Cumulative Yields (yield)',
    sortingField: 'yield',
    class: 'cell-plan-yield',
  },
  {
    id: 'header_vg_brix',
    name: 'Avrage Brix (B)',
    sortingField: 'brix',
    class: 'cell-plan-avg-brix',
  },
  {
    id: 'header_afw',
    name: 'Average Fruit Weights (g)',
    sortingField: 'afw',
    class: 'cell-plan-avg-weight',
  },
  { id: 'header_preview', name: '', class: 'cell-plan-preview' },
  { id: 'header_check', name: 'Clear All', class: 'cell-plan-compare' },
];

const StrategiesTable: React.FC<StrategiesTableProps> = ({ strategies, shopId, planId }) => {
  const dispatch = useAppDispatch();
  const [isHovering, setIsHovering] = useState('');
  const compareStrategies = useAppSelector(strategiesSelectors.compareStrategies);
  const navigate = useNavigate();
  const shop = useAppSelector(strategiesSelectors.shop);

  const fields = {
    name: SORT.ASCENDING,
    yield: SORT.NONE,
    brix: SORT.NONE,
    afw: SORT.NONE,
  };

  const [dataToDisplay, fieldsToSort, sorter] = useSorter(strategies, fields);

  const handleMouseEnter = (headerItem: StrategyHeaderItem) => {
    if (headerItem.sortingField) {
      setIsHovering(headerItem.sortingField);
    }
  };
  const [updatePlan] = useUpdatePlanMutation();
  const [saveStrategy] = useSaveStrategyMutation();
  const { previewPlanId } = useAppSelector(planCreationSelector);

  const handleMouseLeave = () => {
    setIsHovering('');
  };

  const handleCompareOnChange = (strategy: Strategy) => {
    if (
      compareStrategies.length &&
      compareStrategies.map((item) => item.id).includes(strategy.id)
    ) {
      dispatch(strategiesActions.removeCompareStrategy(strategy.id));
    } else {
      dispatch(strategiesActions.addCompareStrategy(strategy));
    }
  };

  const clearAllCompareStrategies = (event: React.MouseEvent<HTMLElement>, disabled: boolean) => {
    event.preventDefault();
    if (!disabled) {
      dispatch(strategiesActions.removeAllCompareStrategies());
    }
  };

  const handlePreview = (strategyId: number) => {
    navigate(`/strategy/preview/${planId}/${shopId}/${strategyId}`, {
      state: {
        planId: previewPlanId,
        shopId,
        strategyId,
      },
    });
  };

  const handleSaveSelectedStrategy = (strategy: Strategy) => {
    let names: SidebarFileFolderNames = { fileNameField: '', folderNameField: '' };
    const validateFunction = () => {
      return !!(
        !checkFileName(names.fileNameField, MIN_LENGTH_PLAN_NAME, MAX_LENGTH_PLAN_NAME) &&
        names.folderNameField
      );
    };
    ModalService.open({
      title: 'Save Strategy',
      saveText: 'Save',
      cancelText: 'Cancel',
      width: '495px',
      validateFunction: validateFunction,
      content: <SavePlanAsContent names={names} />,
      closeAction: async (isConfirmed) => {
        if (isConfirmed) {
          saveStrategy({
            id: shop?.id!,
            strategyId: strategy.id,
          }).then((response: any) => {
            if (!response.error) {
              updatePlan({
                id: previewPlanId!,
                data: {
                  name: names.fileNameField,
                  path: '/' + names.folderNameField,
                },
              }).then((response) => {
                dispatch(setOption({ openSaveModal: false }));
                dispatch(reset());
                dispatch(setStep({ stateName: 'planCreationStep', stepNumber: 0 }));
                dispatch(strategiesActions.reset());
                navigate('/my-plans');
                names = { fileNameField: '', folderNameField: '' };
                dispatch(setOption({ fileName: '', folderName: '' }));
              });
            }
          });
        } else {
          ModalService.close();
        }
      },
    });
  };

  const isDisabledCheckbox = (checkedStrategy: Strategy): boolean => {
    const enabled = compareStrategies.map((strategy) => strategy.id).includes(checkedStrategy.id);
    return !enabled && compareStrategies.length >= 2;
  };

  const showSortingIcons = (headerItem: StrategyHeaderItem): boolean => {
    if (headerItem.sortingField) {
      return fieldsToSort[headerItem.sortingField] !== SORT.NONE;
    }
    return false;
  };

  const getRowClassName = (rowIndex: number) => {
    return rowIndex % 2 === 0 ? styles['even-row'] : styles['odd-row'];
  };

  const renderHeaderItem = (headerItem: StrategyHeaderItem, index: number) => {
    if (headerItem.sortingField) {
      return (
        <th key={index} className={styles[headerItem.class]}>
          <div
            className={styles['cell-sorter'] + ' ' + styles[headerItem.id]}
            onClick={() => {
              sorter(headerItem.sortingField, true);
            }}
            data-value={headerItem.sortingField}
            onMouseEnter={() => handleMouseEnter(headerItem)}
            onMouseLeave={handleMouseLeave}
          >
            <div className={styles['cell-item']}>
              <span>{headerItem.name}</span>
              <div className={styles['sorting-icon']}>
                {isHovering === headerItem.sortingField
                  ? getSortingHoverIcon(fieldsToSort[headerItem.sortingField])
                  : showSortingIcons(headerItem) &&
                    getSortingIcon(fieldsToSort[headerItem.sortingField])}
              </div>
            </div>
          </div>
        </th>
      );
    } else if (headerItem.id === 'header_check') {
      let disabledLinkClass = compareStrategies.length ? '' : 'btn-link-disabled';
      return (
        <th key={index} className={styles[headerItem.class]}>
          <div
            className={styles['cell-item'] + ' btn btn-link ' + disabledLinkClass}
            onClick={(event) => {
              clearAllCompareStrategies(event, disabledLinkClass === 'btn-link-disabled');
            }}
          >
            {headerItem.name}
          </div>
        </th>
      );
    } else {
      return (
        <th key={index} className={styles[headerItem.class]}>
          <div className={styles['cell-item']}>{headerItem.name}</div>
        </th>
      );
    }
  };

  const renderStrategyRow = (strategy: Strategy, index: number) => {
    return (
      <tr key={strategy.id} className={getRowClassName(index)}>
        <td className={styles['cell-plan-row-select']}>
          <span className=' btn btn-link' onClick={() => handleSaveSelectedStrategy(strategy)}>
            Save Plan
          </span>
        </td>
        <td className={styles['cell-plan-name']}>{strategy.name}</td>
        <td className={styles['cell-plan-yield']}>{roundToDecimals(strategy.yield, 1)}</td>
        <td className={styles['cell-plan-avg-brix']}>{roundToDecimals(strategy.brix, 0)}</td>
        <td className={styles['cell-plan-avg-weight']}>{roundToDecimals(strategy.afw, 0)}</td>
        <td className={styles['cell-plan-preview']}>
          <div>
            <Eye
              className={styles['preview-icon']}
              id={`tooltip-anchor-preview-${index}`}
              onClick={() => handlePreview(strategy.id)}
            ></Eye>
            <Tooltip
              anchorSelect={`#tooltip-anchor-preview-${index}`}
              place='top'
              style={{
                zIndex: 10,
                width: '83px',
                display: 'flex',
                height: 'auto',
                borderRadius: '4px',
                backgroundColor: '#fff',
                color: '#132A37',
                boxShadow: '0px 7px 20px rgba(26, 26, 26, 0.08)',
              }}
              opacity={1}
            >
              <div className='tooltip-text'>Preview</div>
            </Tooltip>
          </div>
        </td>
        <td className={styles['cell-plan-compare']}>
          <label className='form-checkbox c-han d'>
            <input
              type='checkbox'
              id={`custom-checkbox-${strategy.id}`}
              name={strategy.name}
              value={strategy.id}
              onChange={() => handleCompareOnChange(strategy)}
              disabled={isDisabledCheckbox(strategy)}
              checked={compareStrategies.map((strategy) => strategy.id).includes(strategy.id)}
            />

            <i className='form-icon light-icon icon-right'></i>
            <span className='text'>Compare</span>
          </label>
        </td>
      </tr>
    );
  };

  return (
    <div className={styles['strategies-container']}>
      <table className={styles['strategies-table']}>
        <thead className={styles['strategies-table-header']}>
          <tr>
            {HEADER_STRATEGIES.map((headerItem, index) => renderHeaderItem(headerItem, index))}
          </tr>
        </thead>
        <tbody className={styles['strategies-table-body']}>
          {dataToDisplay?.map((strategy: Strategy, index: number) =>
            renderStrategyRow(strategy, index)
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StrategiesTable;
