import React, { useState } from 'react';

import Input from 'components/Input/Input';

import styles from './InviteModal.module.scss';
import { isValidEmail } from 'validators/email.validator';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { translate } from 'utils/translations.util';

interface InviteModalProps {
  touched?: boolean;
  inputValues: any;
}

const InviteModal: React.FC<InviteModalProps> = ({ touched, inputValues }) => {
  const [emailInputValue, setEmailInputValue] = useState('');
  const { selectedLanguage } = useAppSelector(languageSelector);

  const handleOnChange = (value: string) => {
    inputValues.email = value;
    setEmailInputValue(value);
  };

  return (
    <div className={styles.container}>
      <Input
        name='email'
        required
        label={translate('Add e-mail input placeholder', selectedLanguage)}
        onChange={handleOnChange}
        errorText={
          touched && !isValidEmail(emailInputValue)
            ? translate('Not a valid email', selectedLanguage)
            : ''
        }
      />
    </div>
  );
};
export default InviteModal;
