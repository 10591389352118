import { KeyValue } from 'interfaces/keyvalue';
import { useMemo, useRef, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router';

const useUrlState = <S extends Record<string, any>>(
  initialState: S,
  navigateMode: 'push' | 'replace' = 'push'
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryFromUrl = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const data: KeyValue<string | string[]> = {};

    for (const [key,] of searchParams.entries()) {
      if (searchParams.getAll(key).length > 1) {
        data[key] = searchParams.getAll(key);
      } else {
        data[key] = searchParams.getAll(key).join();
      }
    }

    return data;
  }, [location.search]);

  const initialStateRef = useRef(initialState);
  const state: S = useMemo(() => {
    return {
      ...initialStateRef?.current,
      ...queryFromUrl,
    };
  }, [queryFromUrl]);

  const setState = (s: SetStateAction<Partial<S>>) => {
    const newQuery = typeof s === 'function' ? s(state) : s;

    const searchParams = new URLSearchParams(location.search);

    for (const [k, v] of Object.entries(newQuery)) {
      if (Array.isArray(v)) {
        searchParams.delete(k);
        v.forEach((v: any) => searchParams.append(k, v));
      } else {
        searchParams.set(k, v);
      }
    }

    navigate(
      {
        hash: location.hash,
        search: searchParams.toString(),
      },
      {
        replace: navigateMode === 'replace',
        state: location.state,
      }
    );
  };

  const resetState = () => {
    location.search = '';

    navigate({
      hash: location.hash,
    });
  };

  return [state, setState, resetState] as const;
};

export default useUrlState;
