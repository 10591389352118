const checkName =
  (errorText1: string, errorText2: string, errorText3: string) =>
    (input: string, minimumLength: number = 1, maximumLength: number = 100): string => {
      let error = '';
      var specialCharactersRegex = /[^A-Za-z 0-9]/g;
      if (specialCharactersRegex.test(input)) {
        error = `${errorText1}`;
      } else if (input.trim().length < minimumLength) {
        error = `${errorText2}`;
      } else if (input.trim().length > maximumLength) {
        error = `${errorText3}`;
      }
      return error;
    };

export const checkFileName = checkName(
  'The file name should not contain special characters',
  'File name too short',
  'File name too long'
);

export const checkFolderName = checkName(
  'The folder name should not contain special characters',
  'Folder name too short',
  'Folder name too long'
);
