import { Location } from 'react-router-dom';
import AuthService from 'services/auth.service';

let user = AuthService.getUserDetails();

export const page = (location: Location) => {
  if (!user) {
    user = AuthService.getUserDetails();
  }
  window.analytics.page(getCleanLocation(location), {
    email: user?.email,
    state: location.state,
    query: getSearchParams(location.search),
  });
};

export const track = (name: string, properties: any) => {
  if (!user) {
    user = AuthService.getUserDetails();
  }
  window.analytics.track(name, { ...properties, email: user?.email });
};

const getCleanLocation = (location: Location) => {
  let path = location.pathname;
  const state = location.state;

  if (!path || !state) {
    return path;
  }

  Object.values(state).forEach((value) => {
    path = path.replaceAll(`/${value}`, '');
  });

  return path;
};

const getSearchParams = (queryString: string) => {
  const searchParams: any = {};

  if (!queryString) {
    return {};
  }

  queryString
    .slice(1)
    .split('&')
    .forEach((param) => {
      const [key, value] = param.split('=');
      if (searchParams[key]) {
        searchParams[key].push(value);
      } else {
        searchParams[key] = [value];
      }
    });

  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key].length === 1) {
      searchParams[key] = searchParams[key][0];
    }
  });

  return searchParams;
};
