import { DatePicker } from 'components/DatePicker/DatePicker';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import dayjs, { Dayjs } from 'dayjs';
import useTranslate from 'hooks/useTranslate';
import { AcceleratedRipening } from 'interfaces/custom-settings';
import { DateRange } from 'interfaces/date-range';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { customPlanSelector, setCustomPlan } from 'redux/slices/customPlan.slice';
import { languageSelector } from 'redux/slices/language.slice';
import { track } from 'utils/analtycs';
import { ripeningDateValidation } from 'utils/customSettngsValidation';

import styles from './Ripening.module.scss';

type RipeningProps = {
  accelerated_ripening?: AcceleratedRipening;
  head_removal_date: number;
  end_date: number;
};
const Ripening: React.FC<RipeningProps> = ({
  accelerated_ripening,
  head_removal_date,
  end_date,
}) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { selectedLanguage } = useAppSelector(languageSelector);

  const ripeningData = useAppSelector(customPlanSelector).customPlanState?.accelerated_ripening;

  const ripeningClassName = ripeningData?.enabled ? 'ripening-data-show' : 'ripening-data-hide';

  useEffect(() => {
    if (accelerated_ripening) {
      dispatch(
        setCustomPlan({
          accelerated_ripening: {
            enabled: accelerated_ripening?.enabled,
            starting_from: dayjs(accelerated_ripening?.starting_from).utc(false).unix(),
          },
        })
      );
    }
  }, [accelerated_ripening, dispatch]);

  const handleRipeningDate = (value: DateRange | Dayjs) => {
    let range = value as DateRange;
    track('custom settings', {
      field: 'accelerated ripening date',
      value: range?.start,
    });
    dispatch(
      setCustomPlan({
        accelerated_ripening: {
          ...ripeningData,
          starting_from: range.start.unix(),
        },
      })
    );
  };

  const handleRipeningToggle = () => () => {
    track('custom settings', {
      field: 'accelerated ripening toggle',
      value: !ripeningData?.enabled,
    });
    dispatch(
      setCustomPlan({
        accelerated_ripening: {
          ...ripeningData,
          enabled: !ripeningData?.enabled,
        },
      })
    );
  };

  if (!ripeningData) {
    return null;
  }

  const displayRipeningError = () => {
    if (ripeningData.enabled) {
      return ripeningDateValidation(
        head_removal_date,
        ripeningData.starting_from,
        end_date,
        selectedLanguage
      );
    }
  };

  return (
    <>
      <div className={`${styles['ripening-row-full']}`}>
        <div className={`${styles['ripening-toggle-text']}`}>
          {translate('Custom settings - Accelerated ripening - confirm')}
        </div>
        <ToggleSwitch onToggle={handleRipeningToggle()} initialValue={ripeningData.enabled} />
      </div>
      <div className={`${styles['ripening-row']}`}>
        <div className={`${styles['ripening-content']}  ${styles[`${ripeningClassName}`]}`}>
          <div className={styles['ripening-period']}>
            {translate('Custom settings - Accelerated ripening - select week')}
          </div>
          <div className={styles['ripening-week-row']}>
            <DatePicker
              weekMode
              startDate={dayjs.unix(ripeningData.starting_from)}
              dropDownPosition='dropdown-datepicker-top dropdown-datepicker-middle'
              label='Select the week'
              onChange={(value) => {
                handleRipeningDate(value);
              }}
              insideTooltip={true}
            />
            <div className={styles['ripening-error']}>{displayRipeningError()}</div>
          </div>
        </div>

        {!ripeningData.enabled && (
          <div className={styles['ripening-period-warning']}>
            {translate('Custom settings - Accelerated ripening - toggle required')}
          </div>
        )}
      </div>
    </>
  );
};

export default Ripening;
