import './Legend.scss';
import 'react-tooltip/dist/react-tooltip.css';

import { LegendItem } from 'interfaces/legend-item';
import React from 'react';

import LegendElement from '../LegendElement/LegendElement';

interface LegendProps {
  content: LegendItem[];
  id: string;
  fontSize?: string;
}

const Legend: React.FC<LegendProps> = ({ content, id, fontSize = '14px' }) => {
  return (
    <div className='legend-container'>
      {content.map((item, index) => {
        let legendId = item.id ? item.id : `${index}${id}`;
        return <LegendElement item={item} key={index} id={legendId} fontSize={fontSize} />;
      })}
    </div>
  );
};
export default Legend;
