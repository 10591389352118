import React, { useEffect, useState } from 'react';

import styles from './ToggleSwitch.module.scss';

interface ToggleSwitchProps {
  initialValue?: boolean;
  onToggle?: (newState: boolean, name?: string) => void;
  name?: string;
  disabled?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  initialValue = false,
  name = '',
  onToggle = (newState: boolean, name?: string) => {},
  disabled = false,
}) => {
  const [toggleValue, setToggleValue] = useState<boolean>(initialValue);

  const handleChange = () => {
    setToggleValue(!toggleValue);
    if (!name) {
      onToggle(!toggleValue);
      return;
    }
    onToggle(!toggleValue, name);
  };

  useEffect(() => {
    setToggleValue(initialValue);
  }, [initialValue]);

  return (
    <label
      className={
        styles.toggle +
        ` ${toggleValue ? styles.active : ''} ${disabled ? styles['toggle-disabled'] : ''}`
      }
      onClick={handleChange}
    >
      <div className={styles.slider}></div>
    </label>
  );
};
export default ToggleSwitch;
