import './EditPlans.scss';

import SideSlide from 'components/SideSlide/SideSlide';
import Switch from 'components/Switch/Switch';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  plansSelector,
  setEditViewMode,
  setSelectedPlansFolderName,
  toggleEditSidebar,
} from 'redux/slices/plansSlice';
import { useGetPlanQuery } from 'services/plans.service';
import {
  useGetPlanTimeseriesQuery,
  useGetPlanTimeseriesVariablesQuery,
} from 'services/plans.service';
import { ReactComponent as ArrowLeft } from 'styles/icons/arrow-left.svg';
import { ReactComponent as BarChart } from 'styles/icons/bar-chart.svg';
import { ReactComponent as List } from 'styles/icons/list.svg';
import { ReactComponent as More } from 'styles/icons/more-horizontal-small.svg';
import { track } from 'utils/analtycs';
import EditPlansGraphsContent from 'views/EditPlans/EditPlansGraphsContent/EditPlansGraphsContent';
import EditPlanSidebar from 'views/EditPlans/EditPlanSidebar/EditPlanSidebar';
import EditPlansTableContent from 'views/EditPlans/EditPlansTableContent/EditPlansTableContent';
import useTranslate from 'hooks/useTranslate';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { PLAN_VARIABLES_OLD_NAME_ID_MAPPING } from 'config/constants';

interface EditPlansProps {
  isPreview?: boolean;
}

const EditPlans: React.FC<EditPlansProps> = ({ isPreview = false }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let { id: planId } = useParams();
  const translate = useTranslate();
  const accountId = useAppSelector(accountIdSelector).accountId;

  const { data: planData, refetch: refetchPlanData } = useGetPlanQuery(Number(planId), {
    skip: !planId,
    refetchOnMountOrArgChange: true,
  });

  const planParameters = planData?.parameters;
  const { editViewMode, editSidebar } = useAppSelector(plansSelector);

  useEffect(() => {
    dispatch(setEditViewMode('GRAPH'));
  }, []);

  const { data: planTimeseriesVariables, refetch: refetchVariables } =
    useGetPlanTimeseriesVariablesQuery(Number(planId), {
      skip: !planId,
      refetchOnMountOrArgChange: true,
    });

  const { data: timeseriesData, refetch: refetchTimeseries } = useGetPlanTimeseriesQuery(
    {
      planId: Number(planId),
      variables: planTimeseriesVariables?.map((variable) => variable.id),
      accountId: accountId,
    },
    { skip: !planId || !planTimeseriesVariables, refetchOnMountOrArgChange: true }
  );

  let expectedYieldValue = 0;
  if (timeseriesData && timeseriesData[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield.cu']]) {
    expectedYieldValue =
      timeseriesData[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield.cu']][
        timeseriesData[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield.cu']].length - 1
      ]?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING['yield.cu']];
  }

  const handleSwitchChange = (buttonState: 1 | 2) => {
    if (buttonState === 1) {
      return dispatch(setEditViewMode('GRAPH'));
    } else {
      return dispatch(setEditViewMode('TABLE'));
    }
  };

  const getColorMode = () => {
    if (editViewMode === 'GRAPH') {
      return 'DARK';
    } else {
      return 'LIGHT';
    }
  };

  return (
    <>
      <div
        className={`edit-plans-container ${
          editViewMode === 'TABLE' ? 'edit-plans-table-view' : ''
        }`}
      >
        <div className='edit-plans-page-name'>
          Plan / {isPreview ? 'Preview Plan' : 'Create Plan'}
        </div>
        <div className='edit-plans-header'>
          <div className='edit-plans-page-name-mobile'>
            Plan / {isPreview ? 'Preview Plan' : 'Create Plan'}
          </div>
          <div className='edit-plans-title-container'>
            <ArrowLeft
              className='edit-plans-back icon-light c-hand'
              width={30}
              height={30}
              onClick={() => {
                dispatch(setSelectedPlansFolderName(planData?.path.slice(1) || ''));
                navigate(-1);
              }}
            />
            <h2 className='edit-plans-title'>
              {isPreview ? 'Unnamed Plan (Preview)' : planData?.name || '-'}
            </h2>
          </div>
          <div className='edit-plans-crop-information'>
            <div className='edit-plans-crop-variety'>
              <div className='edit-plans-crop-variety-field'>
                {translate('Plan view - crop variety of the plan')}
              </div>
              <div className='edit-plans-crop-variety-value'>
                {planParameters?.variety.name || '-'}
              </div>
            </div>
            <div className='edit-plans-crop-location'>
              <div className='edit-plans-crop-location-field'>
                {translate('Plan view - Location of the plan')}
              </div>
              <div className='edit-plans-crop-location-value'>
                {planParameters?.location?.name || '-'}
              </div>
            </div>
          </div>
          <div className='edit-plans-actions'>
            <div className='edit-plans-actions-switch c-hand'>
              <Switch onChange={handleSwitchChange} colorMode={getColorMode()}>
                <BarChart className='icon-svg' />
                <List className='icon-svg' />
              </Switch>
            </div>
            {!isPreview && (
              <div
                className='edit-plans-actions-more c-hand'
                onClick={() => {
                  dispatch(toggleEditSidebar());
                  track('plan toggle edit', { planId });
                }}
              >
                <More className='icon-fill' />
              </div>
            )}
          </div>
        </div>
        {editViewMode === 'GRAPH' ? (
          <EditPlansGraphsContent
            timeseriesData={timeseriesData}
            expectedYield={expectedYieldValue}
            planName={planData?.name || ''}
          />
        ) : (
          <EditPlansTableContent
            timeseriesData={timeseriesData}
            expectedYield={expectedYieldValue}
            planName={planData?.name || ''}
          />
        )}
        {isPreview && (
          <button
            onClick={() => {
              navigate(-1);
            }}
            className='btn preview-button btn-primary'
          >
            Close Preview
          </button>
        )}
      </div>
      {planData && (
        <SideSlide
          hasHeader={false}
          isOpen={editSidebar}
          onClose={() => dispatch(toggleEditSidebar())}
        >
          <EditPlanSidebar
            planData={planData}
            refetchPlanData={refetchPlanData}
            refetchTimeseries={refetchTimeseries}
            refetchVariables={refetchVariables}
          />
        </SideSlide>
      )}
    </>
  );
};
export default EditPlans;
