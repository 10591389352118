import React, { useState } from 'react';

import Input from 'components/Input/Input';
import FileList from 'views/PlanSettings/PlanCreation/FileList/FileList';

import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { setOption, planCreationSelector } from 'redux/slices/planCreationSlice';

import './SaveStep.scss';

import { checkFileName, checkFolderName } from 'utils/validation.util';
import { ReactComponent as FolderAddIcon } from 'styles/icons/folder-add.svg';
import { ModalService } from 'services/modal.service';
import {
  MAX_LENGTH_FOLDER_NAME,
  MAX_LENGTH_PLAN_NAME,
  MIN_LENGTH_FOLDER_NAME,
  MIN_LENGTH_PLAN_NAME,
} from 'config/constants';
import NewPlanFolderContent from 'views/EditPlans/EditPlanSidebar/SavePlanAsContent/NewPlanFolderContent/NewPlanFolderContent';
import { languageSelector } from 'redux/slices/language.slice';
import { translate } from 'utils/translations.util';

interface ISaveStepProps {
  lastValidStep: boolean;
}

const SaveStep: React.FC<ISaveStepProps> = ({ lastValidStep }) => {
  const dispatch = useAppDispatch();
  const fileName = useAppSelector(planCreationSelector).fileName;
  const folderName = useAppSelector(planCreationSelector).folderName;
  const [createdFolders, setCreatedFolders] = useState<string[]>([]);
  const { selectedLanguage } = useAppSelector(languageSelector);

  const handleChange = (name: string) => (input: string) => {
    dispatch(setOption({ [name]: input }));
  };

  const addFolder = () => {
    let newFolderName = { newFolderName: '' };
    const validateFunction = () => {
      return !checkFolderName(
        newFolderName.newFolderName,
        MIN_LENGTH_FOLDER_NAME,
        MAX_LENGTH_FOLDER_NAME
      );
    };
    ModalService.open({
      title: 'Add folder',
      saveText: 'Add',
      cancelText: 'Cancel',
      validateFunction,
      width: '300px',
      content: <NewPlanFolderContent newFolderName={newFolderName} />,
      closeAction: (isConfirmed) => {
        if (isConfirmed) {
          setCreatedFolders([...createdFolders, newFolderName.newFolderName]);
        }
      },
    });
  };

  return (
    <div className='save-step'>
      <div className='step-title'>
        {translate('Create Plan - Save crop plan header', selectedLanguage)}
      </div>
      <Input
        errorText={
          lastValidStep
            ? checkFileName(fileName || '', MIN_LENGTH_PLAN_NAME, MAX_LENGTH_PLAN_NAME)
            : ''
        }
        label={translate('Create Plan - give the plan a name', selectedLanguage)}
        name='name'
        onChange={handleChange('fileName')}
        value={fileName || ''}
      />
      <div>
        <span className='file-list-title'>
          {translate('Create Plan - select folder for saved plan', selectedLanguage)}
        </span>
        <FileList
          selectedFolder={folderName ? folderName : ''}
          setSelectedFolder={handleChange('folderName')}
          additionalFolders={createdFolders}
        />
        <span className='error-text'>
          {lastValidStep && !folderName && translate('Please select a folder', selectedLanguage)}
        </span>
      </div>
      <div className='bottom-section'>
        <div className='new-folder' onClick={addFolder}>
          <FolderAddIcon className='icon-fill' />
          <span>{translate('Create Plan - Create a new folder', selectedLanguage)}</span>
        </div>
      </div>
    </div>
  );
};
export default SaveStep;
