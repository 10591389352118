import { activeCropseasonInitialValue } from 'config/constants';
import dayjs from 'dayjs';
import { KeyValue } from 'interfaces/keyvalue';
import { LegendItem } from 'interfaces/legend-item';
import { Range } from 'interfaces/range';
import { Resolution } from 'interfaces/resolution';
import { UidName } from 'interfaces/uid-name';
import cloneDeep from 'lodash.clonedeep';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import {
  useGetBenchMarkDetailsQuery,
  useGetBenchMarkTimeseriesQuery,
} from 'services/benchmark.service';
import { useGetVariablesQuery } from 'services/cropseason.service';
import { BENCHMARK_COLOR_COUNT, getBenchmarkColor } from 'utils/colors.util';
import { getDash } from 'utils/dashes.util';
import { findKeyInObjectByValue } from 'utils/findKeyInObject';
import { getUnix } from 'utils/time.util';
import { translate } from 'utils/translations.util';
import { getVariablesBasedOnResolution, hasBenchmarkResolutionError } from 'utils/variables.util';

const mapGroupsToLines = (
  activeCropseasonId: number,
  benchmarkTimeseries: any,
  measurementUnits: KeyValue<string>,
  variablesInput: KeyValue<string>,
  dates: { start: number; end: number },
  areas: any,
  benchmarkDetails: any,
  ids?: string[]
) => {
  const groups: any = {};

  if (!benchmarkTimeseries || !benchmarkDetails) {
    return null;
  }

  if (!Object.keys(benchmarkTimeseries).some((key) => ids?.includes(key))) {
    return {};
  }

  Object.entries(benchmarkTimeseries).forEach(([groupId, groupContent]: [any, any]) => {
    if (groupContent?.cropseason_timeseries) {
      groups[groupId] = {};
      Object.entries(groupContent.cropseason_timeseries).forEach(
        ([cropseasonId, variables]: [any, any], cropseasonIndex) => {
          if (activeCropseasonId.toString() === cropseasonId.toString() || !variables) {
            return;
          }

          Object.entries(variables).forEach(([variable, timeseries]: [any, any]) => {
            if (variable === 'local_datetime') {
              return;
            }

            if (!groups[groupId][variable]) {
              const range: Range = { min: Infinity, max: -Infinity };
              Object.values(benchmarkTimeseries[groupId].ranges).forEach((variables: any) => {
                range.min = Math.min(
                  variables[findKeyInObjectByValue(variablesInput, variable)]?.min,
                  range.min
                );
                range.max = Math.max(
                  variables[findKeyInObjectByValue(variablesInput, variable)]?.max,
                  range.max
                );
              });
              groups[groupId][variable] = { data: [], length: 0, range };
            }
            if (timeseries) {
              if (timeseries.length > groups[groupId][variable].length) {
                groups[groupId][variable].length = timeseries.length;
              }

              let legendIndex = getLegendIndex(
                activeCropseasonId,
                cropseasonId,
                groupId,
                benchmarkDetails
              );
              const stroke = getDash(Math.floor(legendIndex / BENCHMARK_COLOR_COUNT));
              groups[groupId][variable].data.push({
                keyName: `c${cropseasonId}`,
                stroke,
                color: getBenchmarkColor(legendIndex),
                data: timeseries.map((value: number, index: number) => ({
                  timestamp: getUnix(variables.local_datetime[index]),
                  [`c${cropseasonId}`]: value,
                })),
                unit: measurementUnits[variable],
              });
            }
          });
        }
      );
    }
  });
  return groups;
};

const mapGroupsToAreas = (
  benchmarkTimeseries: any,
  dates: { start: number; end: number },
  ids?: string[]
) => {
  const groups: any = {};
  if (!benchmarkTimeseries) {
    return null;
  }

  if (!Object.keys(benchmarkTimeseries).some((key) => ids?.includes(key))) {
    return {};
  }

  Object.entries(benchmarkTimeseries).forEach(([groupId, groupContent]: [any, any]) => {
    if (groupContent?.timeseries) {
      groups[groupId] = {};
      groups[groupId].data = {};
      groups[groupId].ranges = groupContent.ranges;

      Object.entries(groupContent.timeseries).forEach(([key, value]: [any, any]) => {
        if (key === 'local_datetime') {
          return;
        }

        groups[groupId].data[key] = [];

        groupContent.timeseries.local_datetime.forEach((time: any, index: number) => {
          groups[groupId].data[key].push({
            timestamp: getUnix(time),
            value: [value.min[index], value.max[index]],
          });
        });
      });
    }
  });
  return groups;
};

function mapDetailsToLegends(activeCropseasonId: number, groupDetails: any[], areas: any) {
  const legends: KeyValue<LegendItem[]> = {};

  if (!groupDetails) {
    return {};
  }
  let index = -1;
  groupDetails.forEach((group) => {
    if (!group.members) {
      return;
    }

    const legendList: LegendItem[] = Object.values(group.members)
      .filter((cropseason: any) => activeCropseasonId.toString() !== cropseason.id.toString())
      .map((cropseason: any) => {
        index++;
        const stroke = getDash(Math.floor(index / BENCHMARK_COLOR_COUNT));

        return {
          color: getBenchmarkColor(index),
          label: cropseason.name,
          title: cropseason.account.name,
          stateName: getBenchmarkColor(index),
          stroke,
          cropSummary: {
            id: cropseason.id,
            location: cropseason.location?.name,
            name: cropseason.name,
            plantingDate: dayjs(cropseason.transplant_date),
            variety: cropseason.variety?.name,
          },
        };
      });
    legends[group.id] = legendList;
  });

  let mergedLegends = Object.entries(legends).flatMap(([_, value]) => value);

  return {
    legends: mergedLegends as LegendItem[],
  } as KeyValue<LegendItem[]>;
}

const getLegendIndex = (
  activeCropseasonId: number,
  cropseasonId: number,
  groupId: string,
  benchmarkDetails: any
) => {
  let index = 0;

  let indexReturned = -1;

  for (let i = 0; i < benchmarkDetails.length; i++) {
    const group = benchmarkDetails[i];
    if (group.id.toString() !== groupId.toString()) {
      let groupMembers = group?.members;
      if (groupMembers) {
        index += Object.values(groupMembers).filter(
          (cropseason: any) => activeCropseasonId !== cropseason.id
        ).length;
      }
    } else {
      Object.values(group.members)
        .filter((cropseason: any) => activeCropseasonId.toString() !== cropseason.id.toString())
        .forEach((cropseason: any) => {
          if (cropseasonId.toString() !== cropseason.id.toString()) {
            index++;
          } else {
            indexReturned = index;
          }
        });
    }
  }

  return indexReturned > -1 ? indexReturned : index;
};

export default function useGetBenchmark(
  cropseasonId: number,
  dates: { start: number; end: number },
  resolution: string,
  variables: KeyValue<string>,
  groupIds?: UidName[]
) {
  const resolutionError = hasBenchmarkResolutionError(
    dates.start,
    dates.end,
    resolution as Resolution
  );
  const hasResolutionError = resolutionError !== false;

  variables = getVariablesBasedOnResolution(variables, resolution as Resolution);
  const accountId = useAppSelector(accountIdSelector).accountId;

  const ids = groupIds?.map(({ id }) => id) || [];

  const { data: benchmarkDetails } = useGetBenchMarkDetailsQuery(
    { groupIds: ids, accountId },
    {
      skip:
        cropseasonId === activeCropseasonInitialValue.id ||
        !accountId ||
        !ids.length ||
        hasResolutionError,
    }
  );

  const { data: benchmarkTimeseries, isSuccess } = useGetBenchMarkTimeseriesQuery(
    {
      accountId,
      groupIds: ids,
      resolution,
      startDate: dayjs.unix(dates.start).toISOString(),
      endDate: dayjs.unix(dates.end).toISOString(),
      variables,
    },
    {
      skip:
        cropseasonId === activeCropseasonInitialValue.id ||
        !accountId ||
        !ids ||
        !ids.length ||
        hasResolutionError,
    }
  );

  const { data: allVariables } = useGetVariablesQuery(accountId, {
    refetchOnMountOrArgChange: true,
  });

  let lines: any = {},
    areas: any = {};

  if (!hasResolutionError) {
    const measurementUnits: KeyValue<string> = {};

    allVariables?.forEach((variable) => {
      measurementUnits[variable.name] = translate(variable.unit);
    });

    const initialAreas = mapGroupsToAreas(benchmarkTimeseries, dates, ids);

    areas = cloneDeep(initialAreas);

    lines = mapGroupsToLines(
      cropseasonId,
      benchmarkTimeseries,
      measurementUnits,
      variables,
      dates,
      areas,
      benchmarkDetails,
      ids
    );

    if (benchmarkTimeseries?.refetches) {
      const refetchedLines = mapGroupsToLines(
        cropseasonId,
        benchmarkTimeseries.refetches,
        measurementUnits,
        variables,
        dates,
        areas,
        benchmarkDetails,
        ids
      );
      const refetchedAreas = mapGroupsToAreas(benchmarkTimeseries.refetches, dates, ids);

      Object.entries(refetchedLines).forEach(([groupId, group]: [string, any]) => {
        Object.assign(lines[groupId], group);
      });

      Object.entries(refetchedAreas).forEach(([groupId, group]: [string, any]) => {
        const ranges: KeyValue<Range> = {};
        Object.keys(group.data).forEach((variable) => {
          ranges[variable] = group.ranges[findKeyInObjectByValue(variables, variable)];
        });
        areas[groupId].data = Object.assign(
          {},
          initialAreas[groupId].data,
          refetchedAreas[groupId].data
        );
        areas[groupId].ranges = Object.assign({}, initialAreas[groupId].ranges, ranges);
      });
    }
  }

  if (!ids.length || hasResolutionError) {
    return {
      timeseries: {},
      ribbons: {},
      legends: {},
    };
  }

  return {
    timeseries: isSuccess ? lines : undefined,
    ribbons: isSuccess ? areas : undefined,
    legends: mapDetailsToLegends(cropseasonId, benchmarkDetails, areas),
  };
}
