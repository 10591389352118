import type { RootState } from 'redux/store';
import { createSlice } from '@reduxjs/toolkit';

interface languageSliceState {
  selectedLanguage: string;
}

const initialState: languageSliceState = {
  selectedLanguage: 'en',
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.selectedLanguage = payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export const languageSelector = (state: RootState) => state.language;

export default languageSlice.reducer;
