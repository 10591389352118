import { proxyApi } from 'redux/slices/proxyApi.slice';
import { formatQueryString } from 'utils/queryString';

export const uploadApi = proxyApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadExcel: builder.mutation<any, any>({
      query: ({ account_id, language, data }) => {
        const ENDPOINT = `upload?`;
        return {
          url:
            ENDPOINT +
            formatQueryString({
              account_id: account_id,
              language: language,
            }),
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useUploadExcelMutation } = uploadApi;
