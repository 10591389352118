import { keyCloakBaseApi } from 'redux/slices/keyCloakApiSlice';
import { MutationResult } from 'interfaces/mutation';

export const userApi = keyCloakBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateUserInfo: builder.mutation<
      MutationResult<any>,
      { firstName: string; lastName: string; email: string; locale: string }
    >({
      query: ({ firstName, lastName, email, locale }) => {
        return {
          url: '',
          method: 'POST',
          body: {
            firstName,
            lastName,
            email,
            attributes: {
              locale,
            },
          },
        };
      },
    }),
  })
});



export const { useUpdateUserInfoMutation } = userApi;
