/* eslint-disable no-useless-escape */
import NumberInput from 'components/NumberInput/NumberInput';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import useTranslate from 'hooks/useTranslate';
import { ArtificialLight } from 'interfaces/custom-settings';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { customPlanSelector, setCustomPlan } from 'redux/slices/customPlan.slice';
import { track } from 'utils/analtycs';
import { isMissing } from 'utils/general.util';

import styles from './ArtificialLights.module.scss';

interface ArtificialLightsProps {
  ALInitialData: ArtificialLight;
}

const ArtificialLights: React.FC<ArtificialLightsProps> = ({ ALInitialData }) => {
  const dispatch = useAppDispatch();
  const artificialLightsData = useAppSelector(customPlanSelector).customPlanState;

  const hasLights = artificialLightsData?.AL_enabled || ALInitialData.AL_enabled;

  const translate = useTranslate();

  useEffect(() => {
    const updateArtificialLightsData = (key: keyof ArtificialLight, value: number | boolean) => {
      if (value && (!artificialLightsData || isMissing(artificialLightsData[key]))) {
        dispatch(
          setCustomPlan({
            ...artificialLightsData,
            [key]: value,
          })
        );
      }
    };
    updateArtificialLightsData('AL_max_capacity', ALInitialData.AL_max_capacity);
    updateArtificialLightsData('AL_percentage_LED', ALInitialData.AL_percentage_LED);
    updateArtificialLightsData('AL_radiation_threshold', ALInitialData.AL_radiation_threshold);
    updateArtificialLightsData('AL_enabled', ALInitialData.AL_enabled);
  }, [ALInitialData, dispatch, artificialLightsData]);

  const handleInstalledCapacityNumberInputChange = (currentValue: string) => {
    track('custom settings', {
      field: 'AL_max_capacity',
      value: currentValue,
    });
    dispatch(
      setCustomPlan({
        ...artificialLightsData,
        AL_max_capacity: currentValue,
        lightsTouched: true,
      })
    );
  };
  const handleLEDNumberInputChange = (currentValue: string) => {
    track('custom settings', {
      field: 'AL_percentage_LED',
      value: currentValue,
    });
    dispatch(
      setCustomPlan({
        ...artificialLightsData,
        AL_percentage_LED: currentValue,
        lightsTouched: true,
      })
    );
  };
  const handleSwitchOffNumberInputChange = (currentValue: string) => {
    track('custom settings', {
      field: 'AL_radiation_threshold',
      value: currentValue,
    });
    dispatch(
      setCustomPlan({
        ...artificialLightsData,
        AL_radiation_threshold: currentValue,
        lightsTouched: true,
      })
    );
  };

  const handleSwitchALEnabled = (currentValue: boolean) => {
    track('custom settings', {
      field: 'AL_enabled',
      value: currentValue,
    });
    dispatch(
      setCustomPlan({
        ...artificialLightsData,
        AL_enabled: currentValue,
        lightsTouched: true,
      })
    );
  };

  return (
    <div className={styles['main-container']}>
      <div className={`${styles['amount-al-toggle']}`}>
        <div className={`${styles['al-toggle-text']}`}>
          {translate('Custom Plan - artificial lights - question')}
        </div>
        <ToggleSwitch onToggle={handleSwitchALEnabled} initialValue={hasLights} />
      </div>
      {!hasLights && (
        <div className={styles['blur-message-container']}>
          <div className={styles['blur-message']}>
            {translate('Custom Plan - artificial lights - message')}
          </div>
        </div>
      )}
      <div
        className={`${styles['artificial-lights-container']} ${
          hasLights ? '' : styles['blur-content']
        }`}
      >
        <div className={styles['amount-row']}>
          <div className={styles['amount-title']}>
            {translate('Custom Plan - artificial lights - Max installed capacity')}
          </div>
          <div className={styles['amount']}>
            <NumberInput
              headerText={'μmol/s/m2'}
              initialValue={artificialLightsData?.AL_max_capacity}
              positive
              amount={5}
              onChange={handleInstalledCapacityNumberInputChange}
            />
          </div>
        </div>
        <div className={styles['amount-row']}>
          <div className={styles['amount-title']}>
            {translate('Custom Plan - artificial lights - % of μmol/s/m2 = LED')}
          </div>
          <div className={styles['amount']}>
            <NumberInput
              headerText={'%'}
              initialValue={artificialLightsData?.AL_percentage_LED}
              positive
              amount={5}
              onChange={handleLEDNumberInputChange}
            />
          </div>
        </div>
        <div className={styles['amount-row']}>
          <div className={styles['amount-title']}>
            {translate('Custom Plan - artificial lights - Switch off artificial lights at')}
          </div>
          <div className={styles['amount']}>
            <NumberInput
              headerText={'Joule/cm²/day'}
              initialValue={artificialLightsData?.AL_radiation_threshold}
              positive
              amount={50}
              onChange={handleSwitchOffNumberInputChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ArtificialLights;
