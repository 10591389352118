import Area from 'components/GraphElements/Area/Area';
import Line from 'components/GraphElements/Line/Line';
import MultilineChart from 'components/GraphElements/MultilineChart/MultilineChart';
import React from 'react';
import { getNumberScale } from 'utils/d3-utils/getScale';
import { weekFormat } from 'utils/d3-utils/tooltip-formatter';

import './DashboardAreaGraph.scss';

interface DashboardAreaGraphProps {
  data?: any;
  title: string;
}

const DashboardAreaGraph: React.FC<DashboardAreaGraphProps> = ({ data, title }) => {
  return (
    <MultilineChart
      resolution='week'
      title={title}
      xAxisProperty='timestamp'
      showTitle={false}
      YInterval={[data.timeseries.yInterval]}
      XInterval={data.timeseries.xInterval}
      customScale={getNumberScale}
      dataToDisplay={[
        {
          data: data?.timeseries?.lineData,
          color: '#fff',
          keyName: data.name,
          unit: data.unit,
        },
        {
          data: data?.timeseries?.areaData,
          color: '#fff',
          keyName: data.name,
          unit: data.unit,
          type: 'area',
        },
      ]}
      hasTooltip={false}
      showAxis={false}
      applyOffset={false}
      applyPadding={false}
      drawTooltipLine={false}
      drawTooltipPointShadow={true}
      drawTooltipBorder={true}
      customFormatter={weekFormat}
    >
      <Line data={data?.timeseries?.lineData} property={data.name} xProperty={'timestamp'} />
      <Area data={data?.timeseries?.areaData} color='#fff' opacity={0.1} />
    </MultilineChart>
  );
};
export default DashboardAreaGraph;
