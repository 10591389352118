import { KeyValue } from 'interfaces/keyvalue';
import { Range } from 'interfaces/range';

const RANGE_EXTENSION = 0.1;

export function getIntervalForActual(input: any, field: string): Range {
  if (!input) {
    return { min: Infinity, max: Infinity };
  }
  const max = Math.max(...input[field]?.filter((element: any) => element === 0 || Number(element)));
  const min = Math.min(...input[field]?.filter((element: any) => element === 0 || Number(element)));

  return { min, max };
}

function getIntervalForGuardRail(input: any, field: string): Range {
  const max = Math.max(
    ...input[field]?.max.filter((element: any) => element === 0 || Number(element))
  );
  const min = Math.min(
    ...input[field]?.min.filter((element: any) => element === 0 || Number(element))
  );

  return { min, max };
}

function extendRange(range: Range, percent: number): Range {
  const { min, max } = range;
  const span = max - min || 1;
  const margin = span * percent;

  const interval = { min: min - margin, max: max + margin };

  if (min >= 0 && interval.min < 0) {
    interval.min = 0;
  }

  return interval;
}

export function getRanges(input: any, keyValues: KeyValue<string>): KeyValue<Range> {
  const ranges: KeyValue<Range> = {};

  Object.keys(keyValues).forEach((key) => {
    if (!input[keyValues[key]]) {
      ranges[key] = { min: Infinity, max: -Infinity };
      return;
    }

    if (input[keyValues[key]].max) {
      ranges[key] = getIntervalForGuardRail(input, keyValues[key]);
    } else {
      ranges[key] = getIntervalForActual(input, keyValues[key]);
    }

    ranges[key] = extendRange(ranges[key], RANGE_EXTENSION);
  });

  return ranges;
}

export function mergeRanges(...input: KeyValue<Range>[]): KeyValue<Range> {
  const ranges: KeyValue<Range> = {};
  Object.keys(input[0]).forEach((key: string) => {
    const variableInput = input.filter((data, index: number) => input[index][key]);
    ranges[key] = {
      min: Math.min(...variableInput.map((data, index: number) => input[index][key].min)),
      max: Math.max(...variableInput.map((data, index: number) => input[index][key].max)),
    };
  });
  return ranges;
}

export function mergeKeyValues(
  keys: string[] | null,
  ...input: KeyValue<number[]>[]
): KeyValue<number[]> {
  if (!keys) {
    keys = Object.keys(input[0]);
  }

  let mergedData: KeyValue<any> = {};
  keys.forEach((key: string) => {
    mergedData[key] = [...input.filter(Boolean).map((_, index: number) => input[index][key])]
      .filter((array) => array?.length)
      .flat();
  });

  return mergedData;
}

export function extractMinMax(input: any, keyValues: KeyValue<string>): KeyValue<Range> {
  const result: KeyValue<Range> = {};

  Object.keys(keyValues).forEach((key) => {
    const fieldValues = input?.map((item: any) => item[key]);
    if (!fieldValues) {
      result[key] = {
        min: Infinity,
        max: -Infinity,
      };
    } else {
      result[key] = {
        min: Math.floor(Math.min(...fieldValues)),
        max: Math.ceil(Math.max(...fieldValues)),
      };
    }
  });
  return result;
}
