import { DatePicker } from 'components/DatePicker/DatePicker';
import NumberInput from 'components/NumberInput/NumberInput';
import dayjs, { Dayjs } from 'dayjs';
import { StemDensityValues } from 'interfaces/custom-settings';
import { DateRange } from 'interfaces/date-range';
import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { CustomPeriod, customPlanSelector, setCustomPlan } from 'redux/slices/customPlan.slice';
import { languageSelector } from 'redux/slices/language.slice';
import { ReactComponent as CloseIcon } from 'styles/icons/close.svg';
import { getUniqueId } from 'utils/getUniqueId';
import { getFirstWeekOfGivenDate } from 'utils/time.util';
import { translate } from 'utils/translations.util';
import { ReactComponent as PlusIcon } from 'styles/icons/plus.svg';

import styles from './StemDensity.module.scss';
import { ButtonVariantEnum } from 'interfaces/button';
import Button from 'components/Button/Button';
import { track } from 'utils/analtycs';

interface StemDensityProps {
  harvestDate: number;
  stemDensityEvents: StemDensityValues[];
}

const StemDensity: React.FC<StemDensityProps> = ({ harvestDate, stemDensityEvents }) => {
  const dispatch = useAppDispatch();
  const stemDensityData = useAppSelector(customPlanSelector).customPlanState?.stemDensityPeriod;
  const stemDensityDataReversed = stemDensityData?.slice().reverse();
  const { selectedLanguage } = useAppSelector(languageSelector);

  const getInitialEvents = useCallback(() => {
    let initialEvents: any = [];
    if (stemDensityEvents) {
      stemDensityEvents.forEach((event: any, index: number) => {
        if (index === 0) {
          initialEvents.push({
            periodId: getUniqueId(),
            periodTitle: translate('Custom Plan - stem density - "title', selectedLanguage),
            datePickerTitle: 'First week',
            numberInputHeader: translate('Custom Plan - stem density - Stems/m2', selectedLanguage),
            startDate: dayjs(event.timestamp).unix(),
            value: event.value,
            isValid: true,
            isInRange: true,
          });
          if (index === stemDensityEvents.length - 1) {
            initialEvents.push({
              periodId: getUniqueId(),
              periodTitle: 'Final period',
              datePickerTitle: 'Final week',
              numberInputHeader: translate(
                'Custom Plan - stem density - Stems/m2',
                selectedLanguage
              ),
              startDate: harvestDate,
              value: event.value,
              isValid: true,
              isInRange: true,
            });
          }
          return;
        }

        initialEvents.push({
          periodId: getUniqueId(),
          periodTitle: translate('Custom Plan - stem density - Next period', selectedLanguage),
          datePickerTitle: translate(
            'Custom Plan - stem density - Select the week',
            selectedLanguage
          ),
          numberInputHeader: translate('Custom Plan - stem density - Stems/m2', selectedLanguage),
          startDate: dayjs(event.timestamp).unix(),
          value: event.value,
          isValid: true,
          isInRange: true,
        });

        if (index === stemDensityEvents.length - 1) {
          initialEvents.push({
            periodId: getUniqueId(),
            periodTitle: 'Final period',
            datePickerTitle: 'Final week',
            numberInputHeader: translate('Custom Plan - stem density - Stems/m2', selectedLanguage),
            startDate: harvestDate,
            value: event.value,
            isValid: true,
            isInRange: true,
          });
        }
      });
    }
    return initialEvents;
  }, [harvestDate, selectedLanguage, stemDensityEvents]);

  useEffect(() => {
    if (stemDensityEvents) {
      dispatch(
        setCustomPlan({
          stemDensityPeriod: getInitialEvents(),
        })
      );
    }
  }, [stemDensityEvents, dispatch, getInitialEvents]);

  const handleRemoveRow = (id: string) => {
    track('custom settings', {
      field: 'remove stemDensity row',
      value: id,
    });
    dispatch(
      setCustomPlan({
        stemDensityPeriod: stemDensityData?.filter((period) => period.periodId !== id),
      })
    );
  };

  const handleAddRow = () => {
    let itemToAdd = {} as any;

    //TODO refactor logic, extract it in builder method
    if (stemDensityData?.length! > 2) {
      itemToAdd = {
        periodId: getUniqueId(),
        periodTitle: translate('Custom Plan - stem density - Next period', selectedLanguage),
        datePickerTitle: translate(
          'Custom Plan - stem density - Select the week',
          selectedLanguage
        ),
        numberInputHeader: translate('Custom Plan - stem density - Stems/m2', selectedLanguage),
        startDate: dayjs.unix(stemDensityData?.at(-2)?.startDate!).add(1, 'week').unix(),
        value: stemDensityData?.at(0)?.value,
        isValid: true,
        isInRange: true,
      };
    } else if (stemDensityData) {
      const [firstDay] = getFirstWeekOfGivenDate(
        dayjs.unix(stemDensityData.at(0)?.startDate!).add(1, 'week')
      );

      itemToAdd = {
        periodId: getUniqueId(),
        periodTitle: translate('Custom Plan - stem density - Next period', selectedLanguage),
        datePickerTitle: translate(
          'Custom Plan - stem density - Select the week',
          selectedLanguage
        ),
        numberInputHeader: translate('Custom Plan - stem density - Stems/m2', selectedLanguage),
        startDate: firstDay.unix(),
        value: stemDensityData.at(0)?.value,
        isValid: true,
        isInRange: true,
      };
    }

    if (stemDensityData) {
      track('custom settings', {
        field: 'add stemDensity row',
        value: itemToAdd?.periodId,
      });
      dispatch(
        setCustomPlan({
          stemDensityPeriod: [
            ...stemDensityData?.slice(0, -1),
            itemToAdd,
            ...stemDensityData.slice(-1),
          ],
        })
      );
    }
  };

  useEffect(() => {
    if (!stemDensityData) {
      return;
    }
    if (stemDensityData.length === 1) {
      return;
    }
    for (let i = 1; i < stemDensityData.length - 1; i++) {
      const currentItem = stemDensityData[i];
      const currentItemStartDate = currentItem.startDate;

      const previousItem = stemDensityData[i - 1];
      const previousItemStartDate = previousItem.startDate;

      const currentItemId = currentItem.periodId;

      if (currentItemStartDate <= previousItemStartDate && currentItem.isValid) {
        const currentItemId = currentItem.periodId;

        dispatch(
          setCustomPlan({
            stemDensityPeriod: stemDensityData.map((period: CustomPeriod) => {
              if (period.periodId === currentItemId) {
                return {
                  ...period,
                  isValid: false,
                };
              } else {
                return period;
              }
            }),
          })
        );
      } else if (currentItemStartDate > previousItemStartDate && !currentItem.isValid) {
        dispatch(
          setCustomPlan({
            stemDensityPeriod: stemDensityData.map((period: CustomPeriod) => {
              const currentItemId = currentItem.periodId;
              if (period.periodId === currentItemId) {
                return {
                  ...period,
                  isValid: true,
                };
              } else {
                return period;
              }
            }),
          })
        );
      }
      if (
        (currentItemStartDate <= stemDensityData[0].startDate ||
          currentItemStartDate >= stemDensityData[stemDensityData.length - 1].startDate) &&
        currentItem.isInRange
      ) {
        dispatch(
          setCustomPlan({
            stemDensityPeriod: stemDensityData.map((period: CustomPeriod) => {
              if (period.periodId === currentItemId) {
                return {
                  ...period,
                  isInRange: false,
                };
              } else {
                return period;
              }
            }),
          })
        );
      } else if (
        currentItemStartDate > stemDensityData[0].startDate &&
        currentItemStartDate > stemDensityData[stemDensityData.length - 1].startDate &&
        !currentItem.isInRange
      ) {
        dispatch(
          setCustomPlan({
            pruning: stemDensityData.map((period: CustomPeriod) => {
              if (period.periodId === currentItemId) {
                return {
                  ...period,
                  isInRange: true,
                };
              } else {
                return period;
              }
            }),
          })
        );
      }
    }
  }, [stemDensityData, dispatch]);

  const handleDatePickerChange = (periodId: string) => (value: DateRange | Dayjs) => {
    let range = value as DateRange;
    track('custom settings', {
      field: 'stemDensity row startDate',
      value: range?.start?.utc(true),
    });
    dispatch(
      setCustomPlan({
        stemDensityPeriod: stemDensityData?.map((period: CustomPeriod) => {
          if (period.periodId === periodId) {
            return {
              ...period,
              startDate: range.start.unix(),
            };
          } else {
            return period;
          }
        }),
      })
    );
  };

  const handleNumberInputChange = (periodId: string) => (currentValue: string) => {
    track('custom settings', {
      field: 'stemDensity row value',
      value: currentValue,
    });
    dispatch(
      setCustomPlan({
        stemDensityPeriod: stemDensityData?.map((period: CustomPeriod) => {
          if (period.periodId === periodId) {
            return {
              ...period,
              value: currentValue,
            };
          } else {
            return period;
          }
        }),
      })
    );
  };

  return (
    <div className={styles['main-container']}>
      <div className={styles['stem-density-container']}>
        <div className={styles['period-row']}>
          <div className={styles['period-title-until-end']}>
            {translate('Custom Plan - stem density - Untill end', selectedLanguage)}
          </div>
        </div>
        {stemDensityDataReversed
          ?.slice(1, -1)
          ?.filter(Boolean)
          .map((period: any, index: number) => {
            return (
              <React.Fragment key={period.periodId}>
                {!period.isValid && period.isInRange && (
                  <p className={styles['warning-valid-week']}>Select a valid week</p>
                )}
                {!period.isInRange && (
                  <p className={styles['warning']}>
                    Select a week within the time range of this CropPlan
                  </p>
                )}
                <div className={styles['period-row']} key={period.periodId}>
                  <div className={styles['period-title']}>{period.periodTitle}</div>
                  <div className={styles['week']}>
                    <DatePicker
                      weekMode
                      startDate={dayjs.unix(period.startDate || 0)}
                      dropDownPosition='dropdown-datepicker-middle'
                      label={period.datePickerTitle}
                      onChange={handleDatePickerChange(period.periodId)}
                    />
                  </div>
                  <div className={styles['amount']}>
                    <NumberInput
                      headerText={period.numberInputHeader}
                      initialValue={period.value}
                      positive
                      minValue={0.01}
                      onChange={handleNumberInputChange(period.periodId)}
                      numberOfDecimals={2}
                      amount={0.05}
                    />
                  </div>
                  <div
                    className={styles['close-icon-container']}
                    onClick={() => handleRemoveRow(period.periodId)}
                  >
                    {index < stemDensityDataReversed.length - 1 && (
                      <CloseIcon className={`icon-light c-hand ${styles['close-icon']}`} />
                    )}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        <div className={styles['period-row']}>
          <div className={styles['period-title-from-start-header']}>
            {translate('Custom Plan - stem density - "title', selectedLanguage)}
          </div>
          <div className={styles['period-title-from-start']}>
            {translate('Custom Plan - stem density - From Start', selectedLanguage)}
          </div>
          <div className={styles['amount']}>
            <NumberInput
              headerText={stemDensityData?.[0]?.numberInputHeader}
              initialValue={stemDensityData?.[0]?.value || 0}
              positive
              minValue={1}
              onChange={handleNumberInputChange(stemDensityData?.[0]?.periodId || '')}
              numberOfDecimals={2}
              amount={0.05}
            />
          </div>
        </div>
      </div>
      <Button
        id={'add-button-stem'}
        styleType={ButtonVariantEnum.HOLLOW}
        onHandleClick={handleAddRow}
      >
        <PlusIcon className='icon-light plus-icon' /> {translate('button Add text')}
      </Button>
    </div>
  );
};
export default StemDensity;
