import { BREAKPOINT_LAPTOP_LARGE, GRAPH_TICKS_COUNT, RESOLUTION } from 'config/constants';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { roundTickPeriod } from 'utils/tickFormatter';

import useWindowSize, { WindowSize } from './useWindowSize';

export default function useGetTicksForGraphs(
  domain?: [number, number],
  numberOfTicks: number = GRAPH_TICKS_COUNT,
  customFunction?: (domainInput: [number, number], input: number) => number[],
  resolution?: string,
  enforceTicksStartOfDay?: boolean
) {
  const [ticks, setTicks] = useState<number[]>();

  useEffect(() => {
    if (domain) {
      if (customFunction) {
        setTicks(customFunction(domain, numberOfTicks));
      } else {
        let start = dayjs.unix(domain[0]).utc(true);
        let end = dayjs.unix(domain[1]).utc(true);
        let roundedStart = dayjs.unix(domain[0]).utc(true);
        if (enforceTicksStartOfDay) {
          start = start.startOf('day');
          end = end.endOf('day');
        }
        let diff = end.diff(start, 'seconds') / numberOfTicks;

        diff = roundTickPeriod(diff);
        numberOfTicks = Math.floor(end.diff(start, 'seconds') / diff);

        let constructedArray = [];
        constructedArray.push(start.unix());
        if (enforceTicksStartOfDay) {
          roundedStart = roundedStart.startOf('day');
        }

        if (resolution === RESOLUTION._1_week) {
          roundedStart = roundedStart.day(1);
        }

        for (let i = 1; i <= numberOfTicks; i++) {
          let currentItem = roundedStart.add(diff * i, 'seconds').unix();

          if (currentItem < end.unix()) {
            constructedArray.push(currentItem);
          }
        }

        constructedArray.push(end.unix());

        setTicks(Array.from(new Set(constructedArray)));
      }
    }
  }, [domain, numberOfTicks, customFunction]);

  return ticks;
}

export function useSetNumberOfTicksForGraph() {
  const windowSize: WindowSize = useWindowSize();
  const [numberOfTicks, setNumberOfTicks] = useState(0);

  useEffect(() => {
    if (windowSize.width < BREAKPOINT_LAPTOP_LARGE) {
      setNumberOfTicks(1);
    } else {
      setNumberOfTicks(0);
    }
  }, [windowSize.width]);

  return numberOfTicks;
}
export function useNumberOfGraphTicks() {
  const windowSize: WindowSize = useWindowSize();
  const [numberOfTicks, setNumberOfTicks] = useState(2);

  useEffect(() => {
    if (windowSize.width < BREAKPOINT_LAPTOP_LARGE) {
      setNumberOfTicks(4);
    } else {
      setNumberOfTicks(6);
    }
  }, [windowSize.width]);

  return numberOfTicks;
}
