import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { languageSelector } from 'redux/slices/language.slice';
import { useGetVariablesQuery } from 'services/cropseason.service';
import { getVariableUnitMapper } from 'utils/plan-variables-mapper';
import { translate } from 'utils/translations.util';

export default function useGetUnitForGraph(input: string) {
  const [unit, setUnit] = useState<string>();

  const accountId = useAppSelector(accountIdSelector).accountId;
  const { data: variables } = useGetVariablesQuery(accountId, { skip: !accountId });
  const { selectedLanguage } = useAppSelector(languageSelector);

  useEffect(() => {
    if (variables) {
      let mappedVariable = getVariableUnitMapper(input as string);
      let variable = variables.find((variable) => variable.name === mappedVariable);
      if (variable) {
        setUnit(translate(variable.unit, selectedLanguage));
      }
    }
  }, [input, variables]);

  return unit;
}
