import { ModalProps } from 'interfaces/modal';

export const ModalService = (function () {
  let observers: Map<string, (modals: ModalProps[]) => void> = new Map();
  let openModals: ModalProps[] = [];

  const open = (props: ModalProps) => {
    openModals = [...openModals, { ...props, touched: false }];
    observers.forEach((observer) => observer(openModals));
  };

  const close = (isConfirmed: boolean = false) => {
    const removedItem = openModals[openModals.length - 1];

    openModals = openModals.slice(0, -1);
    observers.forEach((observer) => observer(openModals));

    if (removedItem?.closeAction) {
      removedItem.closeAction(isConfirmed);
    }
  };

  const subscribe = (key: string, observer: (modals: ModalProps[]) => void) => {
    observers.set(key, observer);
  };

  const unsubscribe = (key: string) => {
    observers.delete(key);
  };

  return {
    open,
    close,
    subscribe,
    unsubscribe,
  };
})();
