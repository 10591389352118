import store from 'redux/store';

import { formatTimestampCustomSettings } from './formatTimeStamp';
import dayjs from 'dayjs';

export const getCustomPlanValues = (
  source: 'creationCustomSettings' | 'editCustomSettings' | 'customPlanState'
) => {
  const customPlanSettings = store.getState().customPlan[source];

  if (!customPlanSettings) {
    return null;
  }

  if (Object.keys(customPlanSettings).length === 0) {
    return null;
  }

  let custom_settings = {};
  let pruning_events = [];
  let stem_density_events = [];

  for (let index = 0; index < customPlanSettings?.pruning?.length! - 1; index++) {
    const currentPruningPeriod = customPlanSettings?.pruning?.[index]!;
    pruning_events.push({
      timestamp: formatTimestampCustomSettings(currentPruningPeriod.startDate),
      value: currentPruningPeriod.value,
    });
  }

  custom_settings = { pruning_events };

  for (let index = 0; index < customPlanSettings?.stemDensityPeriod?.length! - 1; index++) {
    const currentStemDensityPeriod = customPlanSettings?.stemDensityPeriod?.[index]!;
    stem_density_events.push({
      timestamp: formatTimestampCustomSettings(currentStemDensityPeriod.startDate),
      value: currentStemDensityPeriod.value,
    });
  }

  custom_settings = { ...custom_settings, stem_density_events };

  const CO2_min = customPlanSettings.CO2?.CO2min;
  custom_settings = { ...custom_settings, CO2_min };

  const CO2_max = customPlanSettings.CO2?.CO2max;
  custom_settings = { ...custom_settings, CO2_max };

  const tempStrategies = customPlanSettings.phase_temp_strategies;

  custom_settings = {
    ...custom_settings,
    phase_temp_strategies: tempStrategies,
    EC: customPlanSettings.EC,
  };

  if (customPlanSettings.accelerated_ripening) {
    custom_settings = {
      ...custom_settings,
      accelerated_ripening: {
        enabled: customPlanSettings.accelerated_ripening?.enabled,
        starting_from: dayjs
          .unix(customPlanSettings.accelerated_ripening.starting_from)
          .format('YYYY-MM-DD'),
      },
    };
  }

  const AL_max_capacity = customPlanSettings.AL_max_capacity;
  const AL_percentage_LED = customPlanSettings.AL_percentage_LED;
  const AL_radiation_threshold = customPlanSettings.AL_radiation_threshold;
  const AL_enabled = customPlanSettings.AL_enabled || false;

  custom_settings = {
    ...custom_settings,
    AL_max_capacity,
    AL_percentage_LED,
    AL_radiation_threshold,
    AL_enabled,
    lightsTouched: customPlanSettings.lightsTouched,
    temperatureTouched: customPlanSettings.temperatureTouched,
  };

  return custom_settings;
};
