import WarningMessage from 'components/WarningMessage/WarningMessage';
import React, { useCallback, useEffect, useState } from 'react';
import { useGetShopStatusMutation } from 'services/shop.service';
import styles from './Waiting.module.scss';
import { useAppSelector } from 'redux/hooks';
import { strategiesSelectors } from 'redux/slices/planStrategies.slice';
import { useNavigate } from 'react-router-dom';
import { ShopStatus } from 'config/constants';
import { translate } from 'utils/translations.util';
import { languageSelector } from 'redux/slices/language.slice';

const STRATEGY_TIME_TRASHOLD_SECONDS = 30;

const WaitingPage: React.FC = () => {
  const [completed, setCompleted] = useState(0);
  const shop = useAppSelector(strategiesSelectors.shop);
  const navigate = useNavigate();
  const { selectedLanguage } = useAppSelector(languageSelector);

  const [getShopStatus] = useGetShopStatusMutation();

  const getStatus = useCallback(async () => {
    return await getShopStatus(shop?.id!).unwrap();
  }, [getShopStatus, shop?.id]);

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    if (completed === shop?.numberOfStrategies) {
      (async function () {
        let status = (await getStatus()).status;
        while (status === ShopStatus.Generating) {
          await delay(1000);
          status = (await getStatus()).status;
        }
        if (status === ShopStatus.Finished) {
          navigate(`/shop/${shop?.id}`, {
            state: {
              id: shop?.id,
            },
          });
        }
      })();
    }
  }, [completed, shop?.numberOfStrategies, getStatus]);

  const estimatedTime = (shop?.estimatedTime || 0) + STRATEGY_TIME_TRASHOLD_SECONDS;
  const estimatedTimePerStrategy = (estimatedTime / shop?.numberOfStrategies!) * 1000;

  const progressPercentage = (completed * 100) / shop?.numberOfStrategies!;
  const fadedContentRows = new Array(5).fill('waiting-faded-row');

  useEffect(() => {
    const interval = setInterval(() => {
      setCompleted((item) => {
        if (item > shop?.numberOfStrategies!) {
          clearInterval(interval);
        }
        return item < shop?.numberOfStrategies! ? item + 1 : item;
      });
    }, estimatedTimePerStrategy);

    return () => clearInterval(interval);
  }, [estimatedTimePerStrategy, shop?.numberOfStrategies]);

  return (
    <div className={styles['waiting-container']}>
      <WarningMessage
        title={translate(`Generating strategies: info title`, selectedLanguage)}
        message={translate(`Generating strategies: info message`, selectedLanguage)}
      />
      <div className={styles['waiting-faded-header']}></div>
      <div className={styles['waiting-faded-content']}>
        <div className={styles['waiting-faded-sidebar']}></div>
        <div className={styles['waiting-faded-rows']}>
          {fadedContentRows.map((className, index) => {
            return <div key={index} className={styles[className]}></div>;
          })}
        </div>
      </div>
      <div className={styles['progress-bar-container']}>
        <div>
          {translate(`Generating strategies message`, selectedLanguage)} ({`${completed}`}/
          {shop?.numberOfStrategies})
        </div>
        <div className={styles['progress-bar']}>
          <div
            style={{ width: `${progressPercentage}%` }}
            className={styles['progress-bar-progress']}
          ></div>
        </div>
      </div>
    </div>
  );
};
export default WaitingPage;
