import dayjs from 'dayjs';

import { translate } from './translations.util';

// TODO: add translations
export const harvestValidation = (
  harvestDate?: number,
  plantingDate?: number,
  headRemovalDate?: number
) => {
  if (harvestDate && plantingDate) {
    const harvest = dayjs.unix(harvestDate);
    const planting = dayjs.unix(plantingDate);
    if (dayjs(harvest).diff(planting, 'days') <= 150) {
      return 'Should be greater than 150 days';
    } else if (dayjs(harvest).diff(planting, 'weeks') >= 52) {
      return 'Should be less than 52 weeks';
    }
  }
  if (headRemovalDate && harvestDate) {
    if (headRemovalDate > harvestDate) {
      return 'Should be greater than head removal date';
    }
  }

  return;
};

export const headRemovalValidation = (
  harvestDate?: number,
  plantingDate?: number,
  headRemovalDate?: number
) => {
  if (headRemovalDate && plantingDate) {
    const headRemovel = dayjs.unix(headRemovalDate);
    const planting = dayjs.unix(plantingDate);
    if (dayjs(headRemovel).diff(planting, 'days') <= 100) {
      return 'Should be greater than 100 days';
    } else if (dayjs(headRemovel).diff(planting, 'weeks') > 52) {
      return 'Should be less than 52 weeks';
    }
  }
  if (headRemovalDate && harvestDate) {
    if (headRemovalDate > harvestDate) {
      return 'Should be less than harvest date';
    }
  }
  return;
};

export const sowingValidation = (plantingDate?: number, sowingDate?: number | undefined) => {
  if (!plantingDate || !sowingDate) {
    return translate('Plan creation First Setting Date - missing');
  }
  if (sowingDate < dayjs.unix(plantingDate).subtract(80, 'day').unix()) {
    return 'Should be grater than 80 days before planting date';
  }
  if (sowingDate >= plantingDate) {
    return 'Should be less than planting date';
  }
  return null;
};

export const firstSettingDateValidation = (
  headRemovalDate?: number,
  plantingDate?: number,
  firstSettingDate?: number | undefined
) => {
  if (!firstSettingDate || !headRemovalDate || !plantingDate) {
    return translate('Plan creation First Setting Date - missing');
  }
  if (firstSettingDate > dayjs.unix(headRemovalDate).unix()) {
    return translate('Plan creation First Setting Date - less than head removal');
  }
  if (firstSettingDate < dayjs.unix(plantingDate).unix()) {
    return translate('Plan creation First Setting Date - greater than planting');
  }
  return null;
};
