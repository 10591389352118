import { useEffect, useRef } from 'react';
import { unstable_useBlocker } from 'react-router-dom';
import type { BlockerFunction } from '@remix-run/router';

export function usePrompt(onLocationChange: BlockerFunction, hasUnsavedChanges: boolean) {
  const blocker = unstable_useBlocker(hasUnsavedChanges ? onLocationChange : false);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);
}
