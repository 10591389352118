import { CustomSettingsVariabiles } from 'interfaces/custom-settings';
import { Plan } from '../../../interfaces/plans';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { customPlanSelector, setCustomPlan } from 'redux/slices/customPlan.slice';
import {
  useGetCustomSettingsTemperatureStrategiesQuery,
  useGetCustomSettingsVariabilesQuery,
} from 'services/plans.service';
import { useDispatch } from 'react-redux';
import { ModalService } from 'services/modal.service';
import { translate } from 'utils/translations.util';
import { languageSelector } from 'redux/slices/language.slice';
import { isMissing } from 'utils/general.util';
import { getTemperatureSettingsDates } from 'utils/temperatureStrategies';

interface UseCustomSettingsResult {
  customSettings: CustomSettingsVariabiles | undefined;
  updatedResetParametersObject: any;
}

export default function useEditPlanCustomSettings({
  planData,
  resetParametersObject,
  sidebarPlanParameters,
}: {
  planData: Plan;
  resetParametersObject: any;
  sidebarPlanParameters: any;
}): UseCustomSettingsResult {
  const dispatch = useDispatch();
  const { selectedLanguage } = useAppSelector(languageSelector);
  const planPlantingDate = planData?.parameters?.transplant_date;
  const varietyId = planData?.parameters?.variety.id;

  const harvestDate = sidebarPlanParameters?.end_date;
  const savedCustomSettings = useAppSelector(customPlanSelector).customPlanState;

  const { data: customSettings } = useGetCustomSettingsVariabilesQuery(
    {
      varietyId: varietyId as number,
      transplantDate: planPlantingDate as string,
      head_removal_date: planData.parameters.head_removal_date as string,
      end_date: planData.parameters.end_date as string,
      location_id: planData.parameters.location?.id,
    },
    { skip: !varietyId || !planPlantingDate }
  );

  const lightsTouched = savedCustomSettings?.lightsTouched;
  const temperatureTouched = savedCustomSettings?.temperatureTouched;

  //latest values for artificial lights in order to be used for the call on temperature strategies
  const currentArtificialLights = {
    AL_enabled:
      savedCustomSettings?.AL_enabled ||
      sidebarPlanParameters?.custom_settings?.AL_enabled ||
      customSettings?.AL_enabled,
    AL_max_capacity:
      savedCustomSettings?.AL_max_capacity ||
      sidebarPlanParameters?.custom_settings?.AL_max_capacity ||
      customSettings?.AL_max_capacity,
    AL_percentage_LED:
      savedCustomSettings?.AL_percentage_LED ||
      sidebarPlanParameters?.custom_settings?.AL_percentage_LED ||
      customSettings?.AL_percentage_LED,
    AL_radiation_threshold:
      savedCustomSettings?.AL_radiation_threshold ||
      sidebarPlanParameters?.custom_settings?.AL_radiation_threshold ||
      customSettings?.AL_radiation_threshold,
  };

  const customRowsDates = useAppSelector(customPlanSelector).customPlanState?.phase_temp_strategies;
  const updatedRows =
    useAppSelector(customPlanSelector).customPlanState?.phase_temp_strategies_new_dates;

  const { data: customSettingsTemperatureStrategies } =
    useGetCustomSettingsTemperatureStrategiesQuery(
      {
        transplant_date: planPlantingDate as string,
        end_date: dayjs.unix(harvestDate).format('YYYY-MM-DD'),
        AL_enabled: currentArtificialLights.AL_enabled,
        AL_max_capacity: currentArtificialLights.AL_max_capacity,
        AL_percentage_LED: currentArtificialLights.AL_percentage_LED,
        AL_radiation_threshold: currentArtificialLights.AL_radiation_threshold,
        phase_start: getTemperatureSettingsDates(customRowsDates, updatedRows),
        location_id: planData.parameters.location?.id,
      },
      {
        skip:
          !harvestDate ||
          !planPlantingDate ||
          !currentArtificialLights.AL_max_capacity ||
          !currentArtificialLights.AL_percentage_LED ||
          !currentArtificialLights.AL_radiation_threshold ||
          !planData.parameters.location?.id ||
          isMissing(currentArtificialLights.AL_enabled) ||
          temperatureTouched === true,
      }
    );

  // If old Plan does not have phase temp queries, then use the ones from the custom settings
  // If old plan does not have Artificial Lights enabled, then use the ones from the custom settings

  let updatedResetParametersObject = {
    ...resetParametersObject,
    custom_settings: {
      ...resetParametersObject.custom_settings,
      phase_temp_strategies:
        planData.parameters?.custom_settings?.phase_temp_strategies ||
        customSettingsTemperatureStrategies,
    },
  };

  useEffect(() => {
    dispatch(
      setCustomPlan({
        phase_temp_strategies: customSettingsTemperatureStrategies,
      })
    );
  }, [customSettingsTemperatureStrategies]);

  const modalCloseActionResetTemperature = (isConfirmed: boolean) => {
    if (isConfirmed) {
      dispatch(
        setCustomPlan({
          phase_temp_strategies: customSettingsTemperatureStrategies,
          temperatureTouched: false,
        })
      );
    } else {
      dispatch(
        setCustomPlan({
          AL_enabled: sidebarPlanParameters.custom_settings?.AL_enabled,
          AL_max_capacity: sidebarPlanParameters.custom_settings?.AL_max_capacity,
          AL_percentage_LED: sidebarPlanParameters.custom_settings?.AL_percentage_LED,
          AL_radiation_threshold: sidebarPlanParameters.custom_settings?.AL_radiation_threshold,
          lightsTouched: false,
          temperatureTouched: true,
        })
      );
    }
  };

  useEffect(() => {
    if (
      (!savedCustomSettings?.EC || !savedCustomSettings?.accelerated_ripening) &&
      customSettings
    ) {
      dispatch(
        setCustomPlan({
          EC: customSettings?.EC,
          accelerated_ripening: {
            enabled: customSettings?.accelerated_ripening?.enabled,
            starting_from: dayjs(customSettings?.accelerated_ripening?.starting_from).unix(),
          },
        })
      );
    }
  }, [customSettings]);

  useEffect(() => {
    if (lightsTouched === true && temperatureTouched === true) {
      ModalService.open({
        title: translate('dialogBox - resetTemperature - title', selectedLanguage),
        saveText: translate('Reset generic button', selectedLanguage),
        cancelText: translate('Cancel generic button', selectedLanguage),
        width: '495px',
        content: <div>{translate('dialogBox - resetTemperature - message', selectedLanguage)}</div>,
        closeAction: (isConfirmed) => {
          modalCloseActionResetTemperature(isConfirmed);
        },
      });
    }
  }, [
    savedCustomSettings?.AL_enabled,
    savedCustomSettings?.AL_max_capacity,
    savedCustomSettings?.AL_percentage_LED,
    savedCustomSettings?.AL_radiation_threshold,
  ]);

  return {
    customSettings: customSettings,
    updatedResetParametersObject: updatedResetParametersObject,
  };
}
