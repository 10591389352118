import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

interface NotificationSettingsState {
    selectedCropSeasons: [],
    selectedNotificationSettings: null,
}

const initialState = (): NotificationSettingsState => ({
    selectedCropSeasons: [],
    selectedNotificationSettings: null
});

export const notificationSettingsSlice = createSlice({
    name: 'notificationSettings',
    initialState: initialState(),
    reducers: {
        setFilter: (state, payload) => {
            state = {
                ...state,
                ...payload,
            };
        },
    },
});

export const nofiticationSettingsSelector = {
    filters: (state: RootState) => state.notificationSettings,
};

export default notificationSettingsSlice.reducer;
