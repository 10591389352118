import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

interface sideBarState {
  isOpen: boolean;
  linksGroup: {
    track: boolean;
    plan: boolean;
    analyze: boolean;
    notifications: boolean;
    yieldPrediction: boolean;
    importData: boolean;
  };
}

const initialState: sideBarState = {
  isOpen: true,
  linksGroup: {
    track: true,
    plan: false,
    analyze: false,
    notifications: false,
    yieldPrediction: false,
    importData: false,
  },
};

export const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    toggle: (state) => {
      state.isOpen = !state.isOpen;
    },
    setLinksGroup: (state, { payload }) => {
      state.linksGroup = {
        ...state.linksGroup,
        ...payload,
      };
    },
  },
});

export const { toggle, setLinksGroup } = sideBarSlice.actions;

export const sideBarSelector = (state: RootState) => state.sideBar;

export default sideBarSlice.reducer;
