import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import EnvironmentalErrors from 'views/EnvironmentalErrors/EnvironmentalErrors';

import store, { persistor } from 'redux/store';
import AuthService from 'services/auth.service';
import HttpService from 'services/http.service';
import { checkEnvironmentVariables } from 'services/env.service';
import { FlagProvider } from '@unleash/proxy-client-react';
import { configFeatureFlag } from 'config/featureFlagConfig';

import './styles/_index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const renderApp = () =>
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={true} persistor={persistor}>
          <FlagProvider config={configFeatureFlag}>
            <ToastContainer></ToastContainer>
            <App />
          </FlagProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
const renderEnvironmentalErrors = () =>
  root.render(
    <React.StrictMode>
      <EnvironmentalErrors />
    </React.StrictMode>
  );

HttpService.configure();

const envErrors = checkEnvironmentVariables();
const redirectPath = window.location.pathname + window.location.search;
if (envErrors.length === 0) {
  AuthService.initKeycloak(renderApp, redirectPath);
} else {
  renderEnvironmentalErrors();
}
