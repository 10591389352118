import React, { useEffect } from 'react';

import { ReactComponent as Left } from 'styles/icons/chevron-left.svg';
import { ReactComponent as CheckMark } from 'styles/icons/checkmark-full.svg';

import { useAppSelector, useAppDispatch } from 'redux/hooks';
import {
  stepperSelector,
  incrementStep,
  decrementStep,
  StateKey,
  setStep,
} from 'redux/slices/stepperSlice';
import { planCreationSelector, setOption } from 'redux/slices/planCreationSlice';
import { ReactComponent as Refresh } from 'styles/icons/refresh.svg';
import { ReactComponent as Strategy } from 'styles/icons/copy.svg';
import { Tooltip } from 'react-tooltip';

import './Stepper.scss';
import { languageSelector } from 'redux/slices/language.slice';
import { translate } from 'utils/translations.util';
import { resetCustomPlan } from 'redux/slices/customPlan.slice';
import { useGetCustomSettingsVariabilesQuery } from 'services/plans.service';
import { formatTimestampToYearMonthDay } from 'utils/formatTimestampToYearMonthDay';
import { useCreateShopMutation } from 'services/shop.service';
import { strategiesActions, strategiesSelectors } from 'redux/slices/planStrategies.slice';
import { useNavigate } from 'react-router-dom';
import useFeatureFlags from 'hooks/useFeatureFlags';

interface Step {
  stepNumber: string;
  stepText: string;
}

interface IStepperProps {
  stepsList: Step[];
  finalStepButtonText: string;
  stepperName: StateKey;
  numberOfStepsState: StateKey;
  lastValidStepUpdate?: () => number;
  isValidStepToNavigate?: (stepNumber: number) => boolean;
}

const Stepper: React.FC<IStepperProps> = ({
  stepsList,
  finalStepButtonText,
  stepperName,
  numberOfStepsState: numberOfSteps,
  lastValidStepUpdate = () => 0,
  isValidStepToNavigate = () => true,
}) => {
  const activeStep = useAppSelector(stepperSelector(stepperName));
  const dispatch = useAppDispatch();
  const { selectedLanguage } = useAppSelector(languageSelector);
  const varietyId = useAppSelector(planCreationSelector).variety?.id;
  const { plantingDate, harvestDate, previewPlanId, headRemovalDate } =
    useAppSelector(planCreationSelector);
  const shop = useAppSelector(strategiesSelectors.shop);
  const location = useAppSelector(planCreationSelector).location;
  const { enabledPlanShop } = useFeatureFlags();

  const [createStrategies, { data: shopData }] = useCreateShopMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (shopData && shopData.id) {
      dispatch(strategiesActions.updateShop(shopData));
      navigate(`/shop/waiting-page/${shopData.id}`, {
        state: {
          id: shopData.id,
        },
      });
    }
  }, [shopData, dispatch, navigate]);

  const isActive = (step: number) => {
    if (step === activeStep) {
      return 'active-step';
    }
  };

  const handleStepChange = (stepNumber: number) => {
    if (stepNumber <= activeStep) {
      dispatch(setStep({ stateName: stepperName, stepNumber: stepNumber - 1 }));
    } else {
      if (isValidStepToNavigate(stepNumber - 1)) {
        dispatch(setStep({ stateName: stepperName, stepNumber: stepNumber - 1 }));
      } else {
        let lastValidStep = lastValidStepUpdate() || activeStep;
        dispatch(setStep({ stateName: stepperName, stepNumber: lastValidStep }));
      }
    }
  };

  const handleIncrementStep = () => {
    if (isValidStepToNavigate(activeStep + 1)) {
      dispatch(
        incrementStep({
          stateName: stepperName,
          stateStepsNumber: numberOfSteps,
        })
      );
    } else {
      const lastStep = lastValidStepUpdate();
      dispatch(setStep({ stateName: stepperName, stepNumber: lastStep }));
    }
  };

  const renderCompleteStep = (step: number, stepNumber: string) => {
    if (step < activeStep) {
      return <CheckMark className='icon-stepper icon-fill' />;
    } else {
      return <div className='step-number'>{stepNumber}</div>;
    }
  };

  const renderStepDividerLine = (step: number) => {
    if (step < stepsList.length - 1) {
      return <hr className='steps-divider' />;
    }
  };

  const handleOpenSaveStep = () => {
    dispatch(setOption({ openSaveModal: true }));
  };

  const { data: customSettings } = useGetCustomSettingsVariabilesQuery(
    {
      varietyId: varietyId as number,
      transplantDate: formatTimestampToYearMonthDay(plantingDate!),
      head_removal_date: formatTimestampToYearMonthDay(headRemovalDate!),
      end_date: formatTimestampToYearMonthDay(harvestDate!),
      location_id: location?.id,
    },
    { skip: !varietyId || !plantingDate }
  );

  const handleReset = () => {
    dispatch(resetCustomPlan({ plantingDate, harvestDate, customSettings }));
  };

  const handeCreateStrategies = () => {
    if (previewPlanId && !shop?.id) {
      createStrategies(previewPlanId);
    } else if (shop?.id) {
      navigate(`/shop/${!shop?.id}`, {
        state: {
          id: !shop?.id,
        },
      });
    }
  };

  return (
    <div className='stepper'>
      <div
        className={`previous-step ${activeStep !== 0 ? 'c-hand' : ''}`}
        onClick={() => {
          dispatch(decrementStep({ stateName: stepperName }));
        }}
      >
        {activeStep !== 0 && (
          <>
            <Left className='icon-stepper icon-fill' />
            <div className='previous-text'>
              {translate('Create Plan - go one step back', selectedLanguage)}
            </div>
          </>
        )}
      </div>
      <div className='steps-container'>
        {stepsList.map((step, index) => {
          return (
            <div
              key={step.stepNumber}
              onClick={() => handleStepChange(Number(step.stepNumber))}
              className={`step-container ${isActive(index)}`}
            >
              {renderCompleteStep(index, step.stepNumber)}
              <div className='step-text'>{translate(step.stepText, selectedLanguage)}</div>
              {renderStepDividerLine(index)}
            </div>
          );
        })}
      </div>
      {activeStep <= stepsList.length - 1 && (
        <>
          <div className='next-step'>
            {activeStep === stepsList.length - 1 && (
              <button className='btn reset' onClick={handleReset}>
                <Refresh className='icon-white' />
                {translate('Custom Plan Settings - Reset', selectedLanguage)}
              </button>
            )}
            <button
              className='next-button c-hand'
              onClick={() => {
                handleIncrementStep();
              }}
            >
              {activeStep === stepsList.length
                ? finalStepButtonText
                : translate('Create Plan - go to next step', selectedLanguage)}
            </button>
          </div>
        </>
      )}
      {activeStep === stepsList.length && (
        <div className='final-step'>
          <Tooltip
            anchorSelect={`#tooltip-strategy`}
            place='top'
            style={{ zIndex: 10, width: '305px', display: 'flex', height: 'auto' }}
            opacity={1}
          >
            <div className='strategy-details'>
              {translate('Create Plan - strategies hover tooltip text', selectedLanguage)}
            </div>
          </Tooltip>
          {enabledPlanShop && (
            <button
              className='btn-primary btn c-hand strategy-button'
              id={`tooltip-strategy`}
              data-tooltip-variant='light'
              onClick={handeCreateStrategies}
            >
              <Strategy className='icon-light' />
              {translate('Create Plan - strategies action button', selectedLanguage)}
            </button>
          )}
          {previewPlanId && (
            <button className='btn preview-button' onClick={handleOpenSaveStep}>
              {translate('Create Plan - last step', selectedLanguage)}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default Stepper;
