import NumberInput from 'components/NumberInput/NumberInput';
import useTranslate from 'hooks/useTranslate';
import { CO2Values } from 'interfaces/custom-settings';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { customPlanSelector, setCustomPlan } from 'redux/slices/customPlan.slice';

import styles from './CO2.module.scss';
import { track } from 'utils/analtycs';

interface CO2Props {
  CO2InitialValues: CO2Values;
}

const CO2: React.FC<CO2Props> = ({ CO2InitialValues }) => {
  const dispatch = useAppDispatch();
  const CO2Data = useAppSelector(customPlanSelector).customPlanState?.CO2;
  const translate = useTranslate();

  useEffect(() => {
    if (CO2InitialValues.CO2_max && CO2InitialValues.CO2_min) {
      dispatch(
        setCustomPlan({
          CO2: { CO2min: CO2InitialValues.CO2_min, CO2max: CO2InitialValues.CO2_max },
        })
      );
    }
  }, [dispatch, CO2InitialValues.CO2_min, CO2InitialValues.CO2_max]);

  const handleCO2MinNumberInputChange = (currentValue: string) => {
    track('custom settings', {
      field: 'CO2min',
      value: currentValue,
    });
    dispatch(
      setCustomPlan({
        CO2: { ...CO2Data, CO2min: currentValue },
      })
    );
  };
  const handleCO2MaxNumberInputChange = (currentValue: string) => {
    track('custom settings', {
      field: 'CO2max',
      value: currentValue,
    });
    dispatch(
      setCustomPlan({
        CO2: { ...CO2Data, CO2max: currentValue },
      })
    );
  };

  return (
    <div className={styles['main-container']}>
      <div className={styles['co2-container']}>
        <div className={styles['amount-row']}>
          <div className={styles['amount-title']}>
            {translate('Custom Plan - CO2 - CO2 max (windows closed)')}
          </div>
          <div className={styles['amount']}>
            <NumberInput
              headerText={translate('Custom Plan - CO2 - ppm')}
              initialValue={CO2Data?.CO2max}
              positive
              amount={100}
              onChange={handleCO2MaxNumberInputChange}
            />
          </div>
        </div>
        <div className={styles['amount-row']}>
          <div className={styles['amount-title']}>
            {translate('Custom Plan - CO2 - CO2 min (e.g. summer)')}
          </div>
          <div className={styles['amount']}>
            <NumberInput
              headerText={translate('Custom Plan - CO2 - ppm')}
              initialValue={CO2Data?.CO2min}
              positive
              amount={100}
              onChange={handleCO2MinNumberInputChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CO2;
