import { usePrompt } from 'hooks/usePrompt';
import React, { useCallback } from 'react';

import { useBeforeUnload } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { translate } from 'utils/translations.util';

interface PromptProps {
  hasUnsavedChanges: boolean;
}

const Prompt: React.FC<PromptProps> = ({ hasUnsavedChanges }) => {
  const { selectedLanguage } = useAppSelector(languageSelector);

  const onLocationChange = useCallback(() => {
    if (hasUnsavedChanges) {
      return !window.confirm(
        translate('Personal Settings unsaved changes message', selectedLanguage)
      );
    }
    return true;
  }, [hasUnsavedChanges]);

  usePrompt(onLocationChange, hasUnsavedChanges);
  useBeforeUnload(
    useCallback(
      (event) => {
        if (hasUnsavedChanges) {
          event.preventDefault();
          event.returnValue = '';
        }
      },
      [hasUnsavedChanges]
    ),
    { capture: true }
  );

  return null;
};
export default Prompt;
