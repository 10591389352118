import { CropseasonNotification } from 'interfaces/cropseason-notification';

/**
 * Map the notification value according to the notification type.
 */
export function getNotificationValue(notification: CropseasonNotification): number {
  let type = notification.type;
  let rawValue = notification.values.value;

  if (type === 'fresh_weight_gain_drop' && Number(rawValue) < 0) {
    rawValue = -rawValue;
  }

  return rawValue;
}
