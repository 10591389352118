import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import purgeStoredState from 'redux-persist/es/purgeStoredState';
import storageSession from 'redux-persist/lib/storage/session';
import customPlanReducer from 'redux/slices/customPlan.slice';
import planCreationReducer from 'redux/slices/planCreationSlice';
import sideBarReducer from 'redux/slices/sidebar.slice';
import stepperReducer from 'redux/slices/stepperSlice';
import trackFilterReducer from 'redux/slices/trackFilterSlice';
import StoreVersionService from 'services/store-version.service';
import accountIdReducer from './slices/accountId.slice';
import analyzeReducer from './slices/analyze.slice';
import strategiesReducer from './slices/planStrategies.slice';
import cropseasonReducer from './slices/cropseason.slice';
import { keyCloakBaseApi } from './slices/keyCloakApiSlice';
import plansSliceReducer from './slices/plansSlice';
import userInfoReducer from './slices/userInfo.slice';
import languageReducer from './slices/language.slice';
import { baseApi } from './slices/baseApi.slice';
import { baseReportingApi } from './slices/baseReportingApi.slice';
import planComparisonReducer from './slices/planComparisonSlice';
import notificationReducer from './slices/notification.slice';
import notificationSettingsReducer from './slices/notificationSettings.slice';
import tableWidthsReducer from './slices/tableWidths.slice';
import { proxyApi } from './slices/proxyApi.slice';
import trackDataToggleSwitchesReducer from './slices/graphDataToggleSwitches.slice';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: [
    baseApi.reducerPath,
    baseReportingApi.reducerPath,
    'userName',
    keyCloakBaseApi.reducerPath,
    proxyApi.reducerPath,
  ],
};

if (!StoreVersionService.isVersionMatching()) {
  purgeStoredState(persistConfig);
  StoreVersionService.updateStoreVersion();
}

const reducers = combineReducers({
  sideBar: sideBarReducer,
  stepper: stepperReducer,
  planCreation: planCreationReducer,
  customPlan: customPlanReducer,
  [keyCloakBaseApi.reducerPath]: keyCloakBaseApi.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [baseReportingApi.reducerPath]: baseReportingApi.reducer,
  [proxyApi.reducerPath]: proxyApi.reducer,
  plans: plansSliceReducer,
  analyze: analyzeReducer,
  trackFilter: trackFilterReducer,
  cropseason: cropseasonReducer,
  accountId: accountIdReducer,
  userInfo: userInfoReducer,
  language: languageReducer,
  planStrategies: strategiesReducer,
  planComparison: planComparisonReducer,
  notification: notificationReducer,
  notificationSettings: notificationSettingsReducer,
  tableWidths: tableWidthsReducer,
  trackDataToggles: trackDataToggleSwitchesReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(baseApi.middleware)
      .concat(baseReportingApi.middleware)
      .concat(keyCloakBaseApi.middleware)
      .concat(proxyApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
