import './DropDown.scss';

import SearchInput from 'components/SearchInput/SearchInput';
import useTranslate from 'hooks/useTranslate';
import { DropDownProps } from 'interfaces/dropdown';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as ChevronDown } from 'styles/icons/chevron-down.svg';
import { ReactComponent as SetAsDefaultIcon } from 'styles/icons/plus-circle.svg';
import { ReactComponent as RemoveDefaultIcon } from 'styles/icons/minus-circle.svg';
import { ReactComponent as IsDefaultIcon } from 'styles/icons/stop-circle.svg';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { translate as translateMethod } from 'utils/translations.util';
import { isEllipsisActive } from 'utils/general.util';

type EllipsisOption = {
  index: number;
  text: string;
};

const DropDown: React.FC<DropDownProps> = ({
  initialValue,
  optionList = [],
  dropDownTitle,
  displayProperty = '',
  setOnChange = () => {},
  name = 'dropdown',
  required = false,
  requiredText,
  rightSide = false,
  hasTooltip = false,
  prefilledText,
  isSorted = false,
  activePreElement,
  inactivePreElement,
  hasDefaultValue = false,
  setAsDefault = () => {},
  removeDefault = () => {},
  isValueDefault = () => {},
  searchOptions,
  colorMode = 'dark',
  disabled = false,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [dropDownValue, setDropDownValue] = useState(initialValue);
  const [optionTooltips, setOptionTooltips] = useState<EllipsisOption[] | null>(null);
  const translate = useTranslate();
  const { selectedLanguage } = useAppSelector(languageSelector);
  const optionEllipsisRefs = useRef<(HTMLDivElement | null)[]>([]);

  const getDropdownValue = useCallback(
    (option: any) => {
      if (displayProperty) {
        return translateMethod(option[displayProperty], selectedLanguage);
      } else {
        return translateMethod(option, selectedLanguage);
      }
    },
    [displayProperty, selectedLanguage]
  );

  function handleClick(event: any) {
    if (dropDownRef.current && isDropDownOpen && !dropDownRef.current.contains(event.target)) {
      setIsDropDownOpen(false);
    }
  }

  useEffect(() => {
    setDropDownValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  const handleDropDownItemSelect = (option: any) => {
    setDropDownValue(option);
    setIsDropDownOpen(false);
    setOnChange(name, option);
  };

  const handleDropDownItemDefaultSelect = (event: React.MouseEvent<HTMLElement>, option: any) => {
    event.stopPropagation();
    event.preventDefault();
    setAsDefault(option);
  };

  const handleDropDownItemRemoveDefault = (event: React.MouseEvent<HTMLElement>, option: any) => {
    event.stopPropagation();
    event.preventDefault();
    removeDefault(option);
  };

  const renderErrorText = () => {
    if (requiredText) {
      return <div className='required-text'>{requiredText}</div>;
    }
  };

  const renderPreElement = (option: any) => {
    if (getDropdownValue(option) === getDropdownValue(dropDownValue)) {
      return activePreElement;
    } else {
      return inactivePreElement;
    }
  };

  const getPrePaddingClass = () => {
    if (activePreElement || inactivePreElement) {
      return 'visible';
    }
    return '';
  };

  const getPostPaddingClass = () => {
    if (hasDefaultValue) {
      return 'hasPostPadding';
    }
    return '';
  };

  const sortedOptions = useMemo(() => {
    const moveSelectedToTop = (options: any[], selectedOption: any) => {
      const sortedOptions = [];
      for (let i = 0; i < options.length; i++) {
        if (getDropdownValue(options[i]) === getDropdownValue(selectedOption)) {
          sortedOptions.unshift(options[i]);
        } else {
          sortedOptions.push(options[i]);
        }
      }
      return sortedOptions;
    };

    return isSorted ? moveSelectedToTop(optionList, dropDownValue) : optionList;
  }, [optionList, dropDownValue, isSorted, getDropdownValue]);

  const renderDefault = (option: any) => {
    if (isValueDefault(option)) {
      return (
        <div
          className='dropdown-item-default is-default'
          onClick={(event) => handleDropDownItemRemoveDefault(event, option)}
        >
          <IsDefaultIcon className='dropdown-icon is-default-icon tooltip-anchor-is-default'></IsDefaultIcon>
          <RemoveDefaultIcon className='dropdown-icon remove-default-icon tooltip-anchor-remove-default'></RemoveDefaultIcon>
        </div>
      );
    } else {
      return (
        <div
          className='dropdown-item-default'
          onClick={(event) => handleDropDownItemDefaultSelect(event, option)}
        >
          <SetAsDefaultIcon className='dropdown-icon tooltip-anchor-add-default'></SetAsDefaultIcon>
        </div>
      );
    }
  };

  useEffect(() => {
    if (sortedOptions.length > 0 && isDropDownOpen) {
      let timer = setTimeout(() => {
        const ellipsisElements: EllipsisOption[] = [];
        sortedOptions.forEach((option, index: number) => {
          if (isEllipsisActive(optionEllipsisRefs.current[index])) {
            ellipsisElements.push({ index: index, text: getDropdownValue(option) });
          }
        });
        setOptionTooltips(ellipsisElements);
        return () => {
          clearTimeout(timer);
        };
      }, 0);
    }
  }, [sortedOptions, isDropDownOpen, getDropdownValue]);

  const renderOptionTooltips = () => {
    return (
      <div>
        {optionTooltips?.map((option) => (
          <div key={'ellipsisTooltip' + option.text}>
            <Tooltip
              anchorSelect={`.tooltip-anchor-long-name-${option.index}`}
              place='top'
              positionStrategy='fixed'
              variant='light'
              style={{
                zIndex: '350',
                boxShadow: '0px 7px 20px rgba(26, 26, 26, 0.15)',
              }}
              opacity={1}
            >
              {option.text}
            </Tooltip>
          </div>
        ))}
      </div>
    );
  };

  const renderOptions = () => {
    if (searchOptions && !sortedOptions?.length) {
      return (
        <div className='list-item-container c-none'>
          <li className='dropdown-item'>
            <div className={`pre-padding ${getPrePaddingClass()}`}></div>{' '}
            {translate('cropseason-selector-no-results')}
          </li>
        </div>
      );
    }
    return sortedOptions.map((option: any, index: number) => (
      <div
        className={
          `list-item-container ` + (hasTooltip ? ` tooltip-anchor-long-name-${index}` : '')
        }
        key={index}
        onClick={() => handleDropDownItemSelect(option)}
      >
        <li className={`dropdown-item ${getPostPaddingClass()}`}>
          <div className={`pre-padding ${getPrePaddingClass()}`}>{renderPreElement(option)}</div>
          <div
            className={'dropdown-item-value'}
            ref={(ref) => (optionEllipsisRefs.current[index] = ref)}
          >
            {getDropdownValue(option)}
          </div>
          {hasDefaultValue && renderDefault(option)}
        </li>
      </div>
    ));
  };

  return (
    <div
      className={`dropdown-container ${disabled ? 'dropdown-disabled' : ''}`}
      data-tooltip-variant='light'
    >
      {dropDownTitle && (
        <div className={`dropdown-title  ${required && 'required'}`}>{dropDownTitle}</div>
      )}
      <div
        className={`dropdown ${rightSide ? 'dropdown-right' : ''} 
        ${isDropDownOpen ? 'active' : ''} 
        c-hand ${colorMode}`}
        ref={dropDownRef}
      >
        <div
          className={`dropdown-button ${colorMode}`}
          onClick={() => {
            setIsDropDownOpen(!isDropDownOpen);
          }}
        >
          <ChevronDown
            className={`icon-light ${isDropDownOpen ? 'rotate-180' : 'dropdown-icon'} ${
              colorMode === 'light' && 'icon-light-mode'
            }`}
          />
          <div className='dropdown-text-container'>{getDropdownValue(dropDownValue)}</div>
        </div>
        <ul className='menu dropdown-menu'>
          {searchOptions && (
            <SearchInput
              placeholder={searchOptions.placeholder}
              onChange={(event) =>
                searchOptions.onChange && searchOptions.onChange(event.target.value)
              }
              onReset={() => searchOptions.onChange && searchOptions.onChange('')}
            ></SearchInput>
          )}
          {renderOptions()}
        </ul>
      </div>
      {renderOptionTooltips()}
      <div className='info-text'>
        {requiredText && <div className='required-text'>{renderErrorText()}</div>}
        {prefilledText && <div className='prefilled-text'>{prefilledText}</div>}
      </div>
      <Tooltip
        anchorSelect={`.tooltip-anchor-add-default`}
        place='top'
        positionStrategy='fixed'
        variant='light'
        style={{ zIndex: '350', boxShadow: '0px 7px 20px rgba(26, 26, 26, 0.15)' }}
        opacity={1}
      >
        <span>{translate('Dropdown - set as default')}</span>
      </Tooltip>

      <Tooltip
        anchorSelect={`.tooltip-anchor-remove-default`}
        place='top'
        positionStrategy='fixed'
        variant='light'
        style={{ zIndex: '350', boxShadow: '0px 7px 20px rgba(26, 26, 26, 0.15)' }}
        opacity={1}
      >
        <span>{translate('Dropdown - remove default')}</span>
      </Tooltip>
    </div>
  );
};

export default DropDown;
