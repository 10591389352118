import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import styles from './NotificationList.module.scss';
import { ReactComponent as NotificationDetailsIcon } from '../../../styles/icons/arrow_more_details.svg';

import NotificationItem from 'components/NotificationItem/NotificationItem';

import { useReadNotificationsMutation } from 'services/notifications.service';

import { isPast30Days, isPast7Days, isToday, isYesterday } from 'utils/time.util';
import { nofiticationSelector, notificationActions } from 'redux/slices/notification.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import NotificationDetails from '../NotificationDetails/NotificationDetails';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { translate } from 'utils/translations.util';
import { track } from 'utils/analtycs';
import { Notification } from 'interfaces/cropseason-notification';
import useUrlState from 'hooks/useUrlState';
import { NotificationItemConfig } from 'interfaces/notificationItem';

interface NotificationListProps {
  notificationList: Array<Notification>;
  footerText?: string;
  isMobile?: boolean;
  graphXPadding?: number;
}

interface GroupedNotificationList {
  today: Array<Notification>;
  yesterday: Array<Notification>;
  past7Days: Array<Notification>;
  past30Days: Array<Notification>;
  earlier: Array<Notification>;
}

const getGroupNotificationTitle = (key: string) => {
  switch (key) {
    case 'today':
      return translate('Notification-list-header-today');
    case 'yesterday':
      return translate('Notification-list-header-yesterday');
    case 'past7Days':
      return translate('Notification-list-header-past-7days');
    case 'past30Days':
      return translate('Notification-list-header-past-30days');
    case 'earlier':
      return translate('Notification-list-header-earlier');
    default:
      return '';
  }
};

const getGroupedNotificationList = (
  notificationList: Array<Notification>
): GroupedNotificationList => {
  let groupedNotificationList: GroupedNotificationList = {
    today: [],
    yesterday: [],
    past7Days: [],
    past30Days: [],
    earlier: [],
  };

  notificationList.forEach((notification) => {
    let unix_date = dayjs(notification.local_datetime).unix();
    if (isToday(unix_date)) {
      groupedNotificationList.today.push(notification);
    } else if (isYesterday(unix_date)) {
      groupedNotificationList.yesterday.push(notification);
    } else if (isPast7Days(unix_date)) {
      groupedNotificationList.past7Days.push(notification);
    } else if (isPast30Days(unix_date)) {
      groupedNotificationList.past30Days.push(notification);
    } else {
      groupedNotificationList.earlier.push(notification);
    }
  });
  return groupedNotificationList;
};

const groupHasNotifications = (group: GroupedNotificationList, key: string): boolean => {
  return group[key as keyof GroupedNotificationList].length > 0;
};

const NotificationList: React.FC<NotificationListProps> = ({
  notificationList = [],
  footerText,
  isMobile,
  graphXPadding,
}): JSX.Element => {
  const [notifications, setNotifications] = useState(notificationList);
  useEffect(() => {
    setNotifications(notificationList);
  }, [notificationList]);
  const dispatch = useAppDispatch();
  const selectedNotification = useAppSelector(nofiticationSelector.selectedNotifications);

  let reversedNotificationList = [...notifications].reverse();
  let groupedNotificationList = getGroupedNotificationList(reversedNotificationList);
  let groupedKeys = Object.keys(groupedNotificationList);
  const accountId = useAppSelector(accountIdSelector).accountId;
  const [timeOut, setTimeOut] = useState<NodeJS.Timeout>();
  const [shouldNotificationBeUpdated, setShouldNotificationBeUpdated] = useState(false);
  const [updateNotificationsState] = useReadNotificationsMutation();

  const [urlState, setUrlState] = useUrlState({
    selectedNotificationId: '',
  });

  useEffect(() => {
    if (
      shouldNotificationBeUpdated &&
      selectedNotification &&
      !selectedNotification.has_been_seen
    ) {
      updateNotificationsState({
        accountId,
        notificationsIds: [selectedNotification?.id],
      });
      const updatedNotificationList = notifications.map((notification) => {
        if (notification.id === selectedNotification.id) {
          return {
            ...selectedNotification,
            has_been_seen: true,
          };
        }
        return notification;
      });
      setNotifications(updatedNotificationList);
    }
  }, [shouldNotificationBeUpdated, selectedNotification]);

  useEffect(() => {
    if (urlState.selectedNotificationId) {
      const notification = reversedNotificationList.find(
        (notification) => notification.id === urlState.selectedNotificationId
      );
      dispatch(notificationActions.setSelectedNotifications(notification!));
    } else {
      dispatch(notificationActions.setSelectedNotifications(null));
    }
  }, [urlState.selectedNotificationId, reversedNotificationList, dispatch]);

  const handleOpenNotificationDetails = (notification: Notification) => {
    track('Open Notification Details', {
      Notification: { ...notification },
    });
    setShouldNotificationBeUpdated(false);
    setUrlState({ selectedNotificationId: notification.id });
    dispatch(notificationActions.setSelectedNotifications(notification));
    clearTimeout(timeOut);
    setTimeOut(
      setTimeout(() => {
        setShouldNotificationBeUpdated(true);
      }, 3000)
    );
  };

  const showDetails = () => {
    return notificationList.length > 0 && selectedNotification;
  };

  const showDetailsMobile = (notificationId: string) => {
    if (isMobile) {
      return (
        notificationList.length > 0 &&
        selectedNotification &&
        selectedNotification.id === notificationId
      );
    }
  };

  const getDetailsViewClasses = () => {
    let classes = '';
    if (!isMobile) {
      classes += showDetails() ? 'has-side-view' : 'no-side-view';
    } else {
      classes += showDetails() ? 'has-open-details' : 'no-open-details';
    }
    return classes;
  };

  return (
    <div className={styles[`notifications`] + ' ' + styles[`${getDetailsViewClasses()}`]}>
      <div className={styles['notification-list-container']}>
        {groupedKeys.map((key) => {
          return (
            groupHasNotifications(groupedNotificationList, key) && (
              <div key={`notification-list-${key}`} className={styles['notification-list-group']}>
                <div className={styles['notification-list-title']}>
                  {getGroupNotificationTitle(key)}
                </div>
                <div className={styles['notification-list']}>
                  {groupedNotificationList[key as keyof GroupedNotificationList].map(
                    (notification: Notification, index: number) => {
                      const isSelected =
                        selectedNotification && selectedNotification.id === notification.id;
                      return (
                        <div>
                          <NotificationItem
                            data={notification}
                            id={index}
                            key={`notification-item-key-${index}`}
                            style={NotificationItemConfig.StyleDark}
                            type={NotificationItemConfig.TypeFullPage}
                            hasAction={<NotificationDetailsIcon></NotificationDetailsIcon>}
                            handleNotificationClickCallback={handleOpenNotificationDetails}
                            isSelected={isSelected}
                            showNotificationState={true}
                          ></NotificationItem>

                          {showDetailsMobile(notification.id) && (
                            <div className={styles['notification-details-inside']}>
                              <NotificationDetails
                                notification={selectedNotification!}
                                isMobile={true}
                              ></NotificationDetails>
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )
          );
        })}
        {footerText && <div className={styles['notification-list-footer']}>{footerText}</div>}
      </div>

      <div className={styles['notification-details']}>
        {showDetails() && !isMobile && (
          <NotificationDetails
            notification={selectedNotification!}
            isMobile={false}
          ></NotificationDetails>
        )}
      </div>
    </div>
  );
};

export default NotificationList;
