import React from 'react';
import { ReactComponent as More } from 'styles/icons/more-horizontal.svg';
import { ReactComponent as Left } from 'styles/icons/chevron-left.svg';
import { ReactComponent as Right } from 'styles/icons/chevron-right.svg';

import styles from './Pagination.module.scss';
import { usePagination } from 'hooks/usePagination';

interface PaginationProps {
  setOnChange: (value: number) => void;
  currentPage: number;
  pageSize: number;
  totalCount: number;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  setOnChange,
  pageSize,
  totalCount,
}) => {
  const pagination = usePagination({
    currentPage,
    pageSize,
    totalCount,
  });

  const handleNext = () => {
    setOnChange(currentPage + 1);
  };

  const handlePrevious = () => {
    setOnChange(currentPage - 1);
  };

  const handleClick = (value: number) => {
    setOnChange(value);
  };

  let lastPage = pagination?.[pagination.length - 1];
  return (
    <ul className={styles.container}>
      <li
        className={`${styles['icon-container']} ${currentPage === 1 && styles.disabled} ${
          styles['icon-left']
        }`}
        onClick={handlePrevious}
      >
        <Left
          className={`icon-pagination c-hand ${currentPage === 1 && styles['icon-disabled']}`}
        />
      </li>
      {pagination?.map((pageNumber: number, index: number) => {
        if (pageNumber === -1) {
          return (
            <li className={`${styles['icon-container']} `} key={index}>
              <More className='icon-pagination' />;
            </li>
          );
        }
        return (
          <li
            className={`${styles.item} ${pageNumber === currentPage && styles.selected}`}
            onClick={() => handleClick(pageNumber)}
            key={index}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`${styles['icon-container']} ${currentPage === lastPage && styles.disabled}`}
        onClick={handleNext}
      >
        <Right
          className={`icon-pagination c-hand  ${
            currentPage === lastPage && styles['icon-disabled']
          }`}
        />
      </li>
    </ul>
  );
};
export default Pagination;
