import { useNumberOfGraphTicks } from 'hooks/useGetTicksForGraphs';
import { LegendItem } from 'interfaces/legend-item';
import { GraphLine } from 'interfaces/line-graph-data';
import cloneDeep from 'lodash.clonedeep';
import React, { Children, cloneElement, ReactElement, useCallback } from 'react';
import { getYAxisInterval } from 'utils/getYAxisInterval';
import { roundToDecimals } from 'utils/roundToDecimals';
import { xTickFormater } from 'utils/tickFormatter';

import Axis from '../Axis/Axis';
import GridLine from '../GridLine/GridLine';
import useController from '../hooks/Graph.controller';
import useDimensions from '../hooks/useDimensions';
import Legend from '../Legend/Legend';
import { Overlay } from '../Overlay/Overlay';
import Tooltip from '../Tooltip/Tooltip';
import styles from './MultilineChart.module.scss';

import TooltipCustom from 'components/TooltipCustom/TooltipCustom';
import RangeTooltip from 'components/RangeTooltip/RangeTooltip';
import { hoverGraphDataTooltip } from 'interfaces/tooltip';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT_LAPTOP, DESKTOP_GRAPH_PADDING } from 'config/constants';

const BLUR_MESSAGE = 'For this variable no data is available for the selected time interval.';

interface CustomTooltipProperties {
  tooltipRangeLabel?: string;
}

interface MultilineChartProps {
  customClassName?: string;
  dataToDisplay?: GraphLine[];
  xAxisProperty: string;
  margin?: { left?: number; top?: number };
  title: string;
  hasLinesPatternLegend?: boolean;
  linesPatternLegend?: ReactElement;
  hasTooltip?: boolean;
  resolution?: string;
  XInterval?: [number, number] | any; // Xinterval is the timestamp between the start and end of the graph.
  YInterval?: ({ min: number; max: number } | undefined)[];
  hasYIntervalExact?: boolean;
  rightAxisYInterval?: ({ min: number; max: number } | undefined)[];
  yAxisUnit?: string;
  blur?: boolean;
  axisDecimals?: number;
  tooltipDecimals?: number;
  showTitle?: boolean;
  showYAxisTicks?: boolean;
  children?: JSX.Element | JSX.Element[];
  leftAxisColor?: string;
  rightAxisColor?: string;
  isBiAxial?: boolean;
  getTicks?: (domainInput: [number, number], input: number) => number[];
  getXTicks?: (domainInput: [number, number], input: number) => number[];
  customScale?: (domain: any) => any;
  legend?: LegendItem[];
  id?: string;
  legendFontSize?: string;
  titleTooltip?: ReactElement;
  tooltipScale?: number;
  showAxis?: boolean;
  applyOffset?: boolean;
  applyPadding?: boolean;
  drawTooltipLine?: boolean;
  drawTooltipPointShadow?: boolean;
  drawTooltipBorder?: boolean;
  hasAxisOffset?: boolean;
  hasLegend?: boolean;
  yOffset?: number;
  customTransform?: string;
  customFormatter?: (input: any) => string;
  customFormatXTick?: (input: any) => string;
  customTooltipContent?: ReactElement;
  enforceTicksStartOfDay?: boolean;
  customToolipProperties?: CustomTooltipProperties;
  XTickPadding?: number;
  xAxisOffset?: number;
  background?: ReactElement;
  maskId?: string;
  graphHeightCallback?: (height: number) => void;
  customLegend?: ReactElement;
  maxHeight?: number;
}

const MultilineChart: React.FC<MultilineChartProps> = ({
  customClassName = 'general-graph',
  dataToDisplay = [],
  title = '',
  hasTooltip = true,
  hasLinesPatternLegend = false,
  linesPatternLegend,
  resolution = '1_day',
  XInterval = [0, 100],
  YInterval = [{ min: 0, max: 100 }],
  hasYIntervalExact = false,
  rightAxisYInterval = [{ min: 0, max: 100 }],
  yAxisUnit = '',
  blur = false,
  axisDecimals = 1,
  tooltipDecimals = 1,
  showTitle = true,
  showYAxisTicks = true,
  leftAxisColor = '#fff',
  rightAxisColor = '#fff',
  isBiAxial = false,
  getTicks,
  getXTicks,
  customScale,
  children,
  legend = [],
  legendFontSize,
  titleTooltip,
  showAxis = true,
  applyOffset = true,
  applyPadding = true,
  drawTooltipLine = true,
  drawTooltipPointShadow = false,
  drawTooltipBorder = false,
  hasAxisOffset = true,
  hasLegend = true,
  yOffset = 0,
  customTransform,
  customToolipProperties,
  customFormatter,
  customFormatXTick,
  enforceTicksStartOfDay = true,
  id = `${Math.floor(Math.random() * 1000)}`,
  xAxisOffset = DESKTOP_GRAPH_PADDING,
  XTickPadding = 0,
  maskId,
  graphHeightCallback,
  customLegend,
  maxHeight = 0,
}) => {
  const overlayRef = React.useRef(null);
  const [tooltipHoveredData, setTooltipHoveredData] = React.useState([] as hoverGraphDataTooltip[]);
  const windowSize: WindowSize = useWindowSize();
  const TOOLTIP_PLACE = windowSize.width > BREAKPOINT_LAPTOP ? 'right' : 'bottom';

  const updateTooltipHoveredData = (data: hoverGraphDataTooltip[]) => {
    setTooltipHoveredData(data);
  };

  const getXAxisOfffset = useCallback(() => {
    if (applyOffset) {
      return xAxisOffset || DESKTOP_GRAPH_PADDING;
    }
    return 0;
  }, [applyOffset, xAxisOffset]);

  const getYAxisOfffset = useCallback(() => {
    if (applyOffset) {
      return 10 + yOffset;
    }
    return 0 + yOffset;
  }, [applyOffset, yOffset]);

  const SVG_HEIGHT_PADDING_CORRESPONDING_TO_WIDTH = DESKTOP_GRAPH_PADDING - xAxisOffset;

  let [containerRef, { svgWidth, svgHeight, width, height }] = useDimensions();
  if (maxHeight && height > maxHeight) {
    height = maxHeight;
    svgHeight = maxHeight;
  }
  const computedYDomain = getYAxisInterval(YInterval, hasYIntervalExact);
  graphHeightCallback && graphHeightCallback(height - xAxisOffset / 2 + yOffset);

  const computedRightAxisDomain = getYAxisInterval(rightAxisYInterval);

  const controller = useController(
    XInterval,
    computedYDomain,
    {
      height: height,
      width: width - (!applyPadding ? 0 : getXAxisOfffset()),
    },
    customScale,
    hasAxisOffset ? getXAxisOfffset() : 0
  );

  //grid includes only the graph area, not the axis
  const gridHeight = height - getXAxisOfffset() + 20;
  const gridWidth = width - (!applyPadding ? 2 : 3) * getXAxisOfffset();

  const rightAxisController = useController(
    XInterval,
    computedRightAxisDomain,
    {
      height: height,
      width: width - getXAxisOfffset(),
    },
    customScale,
    hasAxisOffset ? getXAxisOfffset() : 0
  );
  const { xScale, yScale } = controller;
  const { yScale: rightAxisYScale } = rightAxisController;
  const numberOfticks = useNumberOfGraphTicks();

  const addScalingToData = useCallback(() => {
    const data: GraphLine[] = cloneDeep(dataToDisplay);
    data.map((data) => {
      if (data?.axis === 'right') {
        data.scaleFunction = rightAxisYScale;
      } else {
        data.scaleFunction = yScale;
      }
      return data;
    });
    return data;
  }, [dataToDisplay, yScale, rightAxisYScale]);

  if (!svgHeight || !numberOfticks) {
    return <div className={`${styles['graph']}`} ref={containerRef}></div>;
  }

  const updateChildrenWithProps = Children.map(children, (child, i) => {
    if (child?.props.axis === 'right') {
      return cloneElement(child!, {
        xScale,
        yScale: rightAxisYScale,
        transform: `translate(${getXAxisOfffset()}, ${getYAxisOfffset()})`,
        height: height,
        maskId: child?.props.maskId ? child?.props.maskId : id,
      });
    } else if (!hasAxisOffset) {
      return cloneElement(child!, {
        xScale,
        yScale,
        transform: child?.props.transform
          ? child?.props.transform
          : customTransform || `translate(0,0)`,
        height: height,
      });
    }
    return cloneElement(child!, {
      xScale,
      yScale,
      transform: `translate(${getXAxisOfffset()}, ${getYAxisOfffset()})`,
      height: height,
      maskId: child?.props.maskId ? child?.props.maskId : id,
      width: svgWidth,
    });
  });

  const renderTooltipContent = () => {
    return (
      <RangeTooltip
        hoverData={tooltipHoveredData}
        customFormatter={customFormatter}
        rangeLabel={customToolipProperties?.tooltipRangeLabel}
        resolution={resolution}
        numberOfDecimals={tooltipDecimals}
      ></RangeTooltip>
    );
  };

  return (
    <div
      className={`${styles['graph']} ${
        styles[customClassName] + ' ' + hasLinesPatternLegend ? 'header-pattern-legend' : ''
      }  `}
      ref={containerRef}
    >
      {blur && (
        <div className={`${styles['blur-message-container']}`}>
          <div className={`${styles['blur-message']}`}>{BLUR_MESSAGE}</div>
        </div>
      )}
      {showTitle && (
        <div className={styles['title-container']}>
          <div className={styles.title}>
            {title} {titleTooltip}
          </div>
          <div className={styles.unit}>{yAxisUnit}</div>
        </div>
      )}

      {hasLinesPatternLegend && (
        <div className={`${styles['lines-pattern-legend']}`}>{linesPatternLegend}</div>
      )}

      <div
        className={`${styles['svg']} ${blur ? styles.blur : ''} ${
          applyPadding ? styles.padding : ''
        }`}
      >
        <svg
          width={width}
          height={svgHeight + yOffset + XTickPadding + SVG_HEIGHT_PADDING_CORRESPONDING_TO_WIDTH}
          data-tooltip-float={true}
          className={`${styles['multiline-chart-svg']} ${
            maxHeight ? `${styles['max-graph']}` : ''
          }`}
        >
          {updateChildrenWithProps}
          {showAxis && (
            <>
              <GridLine
                type='vertical'
                scale={xScale}
                ticks={numberOfticks}
                size={height - getXAxisOfffset()}
                isAnimating={false}
                transform={`translate(${getXAxisOfffset()}, ${gridHeight + yOffset})`}
                interval={XInterval}
                getTicks={getXTicks || getTicks}
                resolution={resolution}
                enforceTicksStartOfDay={enforceTicksStartOfDay}
              />
              <GridLine
                type='horizontal'
                scale={yScale}
                ticks={3}
                size={gridWidth}
                isAnimating={false}
                transform={`translate(${getXAxisOfffset()}, ${10 + yOffset})`}
                interval={getTicks ? computedYDomain : undefined}
                getTicks={getTicks}
                enforceTicksStartOfDay={enforceTicksStartOfDay}
              />
            </>
          )}
          {showAxis && (
            <>
              <Axis
                tickFormat={xTickFormater(resolution, customFormatXTick)}
                isAnimating={false}
                type='bottom'
                transform={`translate(${getXAxisOfffset()}, ${gridHeight + yOffset})`}
                scale={xScale}
                ticks={numberOfticks}
                interval={XInterval}
                getTicks={getXTicks || getTicks}
                resolution={resolution}
                enforceTicksStartOfDay={enforceTicksStartOfDay}
                tickPadding={XTickPadding}
              />
              <Axis
                isAnimating={false}
                tickFormat={(d) => roundToDecimals(d, axisDecimals)}
                transform={`translate(${getXAxisOfffset()},  ${10 + yOffset})`}
                type='left'
                scale={yScale}
                ticks={3}
                showTicks={showYAxisTicks}
                color={leftAxisColor}
                interval={getTicks ? computedYDomain : undefined}
                getTicks={getTicks}
                enforceTicksStartOfDay={enforceTicksStartOfDay}
              />
            </>
          )}
          {isBiAxial && (
            <Axis
              isAnimating={false}
              tickFormat={(d) => roundToDecimals(d, axisDecimals)}
              transform={`translate(${width - 2 * getXAxisOfffset()},  ${10 + yOffset})`}
              type='right'
              scale={rightAxisYScale}
              ticks={3}
              showTicks={showYAxisTicks}
              color={rightAxisColor}
              interval={computedRightAxisDomain}
              getTicks={getTicks}
              enforceTicksStartOfDay={enforceTicksStartOfDay}
            />
          )}

          {hasTooltip && (
            <Overlay
              ref={overlayRef}
              width={gridWidth}
              height={gridHeight}
              transform={`translate(${getXAxisOfffset()}, ${yOffset})`}
              dataTooltipId={`MultilineChart-tooltip-${title}`}
            >
              <Tooltip
                anchorEl={overlayRef.current}
                width={width}
                height={height - 10}
                xScale={xScale}
                yScale={rightAxisYScale}
                dataToDisplay={addScalingToData()}
                resolution={resolution}
                tooltipName={`tooltip-${title.replace(/[\s.]/g, '')}`}
                tooltipDecimals={tooltipDecimals}
                applyOffset={applyOffset}
                yOffset={yOffset}
                drawTooltipLine={drawTooltipLine}
                drawTooltipPointShadow={drawTooltipPointShadow}
                drawTooltipBorder={drawTooltipBorder}
                customFormatter={customFormatter}
                tooltipHoverDataCallback={updateTooltipHoveredData}
                graphXInterval={XInterval}
                xAxisOffset={xAxisOffset}
              />
            </Overlay>
          )}
        </svg>
        {hasTooltip && (
          <TooltipCustom
            dataForId={`MultilineChart-tooltip-${title}`}
            key={`MultilineChart-tooltip-key-${title}`}
            renderContent={renderTooltipContent}
            showTooltip={tooltipHoveredData.length > 0}
            place={TOOLTIP_PLACE}
            float={true}
          ></TooltipCustom>
        )}

        {hasLegend && (
          <div className={`${styles['legend-container']}`}>
            {legend.length ? (
              <Legend content={legend} id={id} fontSize={legendFontSize} />
            ) : (
              customLegend
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default MultilineChart;
