const existingVersion = localStorage.getItem('store_version')!;
const currentVersion = process.env.REACT_APP_STORE_VERSION;

const isVersionMatching = () => {
  return existingVersion === currentVersion;
};

const updateStoreVersion = () => {
  localStorage.setItem('store_version', `${currentVersion}`);
};

const StoreVersionService = {
  isVersionMatching,
  updateStoreVersion,
};

export default StoreVersionService;
