import { FileCardActions } from 'interfaces/plans';
import React, { ReactElement } from 'react';
import { ReactComponent as Checkmark } from 'styles/icons/checkmark-circle.svg';
import { ReactComponent as Close } from 'styles/icons/close.svg';
import { ReactComponent as More } from 'styles/icons/more-horizontal-small.svg';

import styles from './FileCard.module.scss';

interface FileCardProps {
  icon?: ReactElement;
  actions?: FileCardActions;
  title?: string;
  isEditing?: boolean;
  colorMode?: 'LIGHT' | 'DARK';
  children?: ReactElement;
  isClickable?: boolean;
  handleCardClick?: () => void;
  renameInput?: string;
  onChangeRename?: () => void;
  handleConfirmRename?: (value: string) => void;
  handleCancelRename?: () => void;
  toggleActions?: () => void;
  showActions?: () => void;
  popupRef?: React.RefObject<HTMLDivElement>;
  componentId?: string;
}

const FileCard: React.FC<FileCardProps> = ({
  icon,
  actions = {},
  title = '',
  isEditing = false,
  colorMode = 'LIGHT',
  children = [],
  isClickable = false,
  handleCardClick = () => null,
  renameInput = '',
  onChangeRename = () => null,
  handleConfirmRename = () => null,
  handleCancelRename = () => null,
  toggleActions = () => null,
  showActions = () => null,
  popupRef = null,
  componentId = '',
}) => {
  const getClassName = (renameInput: string): string => {
    if (!actions.HANDLE_VALIDATE) {
      return '';
    }

    return !actions.HANDLE_VALIDATE(renameInput) ? styles['not-valid'] : '';
  };

  return (
    <div className={`${styles.card} ${colorMode === 'LIGHT' ? styles['light-mode'] : ''}`}>
      <div className={`${styles.content} ${isClickable && 'c-hand'}`} onClick={handleCardClick}>
        <div className={styles.icon}>{icon}</div>
        {isEditing ? (
          <div className={styles['input-container']}>
            <input
              autoFocus
              value={renameInput}
              onChange={onChangeRename}
              type='text'
              className={`${styles.input} ${getClassName(renameInput)}`}
            ></input>
            <div className={styles['confirmations-container']}>
              <Checkmark
                className={`${styles['actions-icon']} icon-light c-hand`}
                onClick={() => handleConfirmRename(renameInput)}
              />
              <Close
                className={`${styles['actions-icon']} icon-light c-hand`}
                onClick={handleCancelRename}
              />
            </div>
          </div>
        ) : (
          <div className={styles['title-container']}>
            <div className={styles.title}>{title}</div>
            {colorMode === 'LIGHT' && <div className={styles.description}>{children}</div>}
          </div>
        )}
      </div>
      {!isEditing && (
        <div>
          <div className={styles.actions}>
            <div
              onClick={toggleActions}
              className={`accordion 
            ${styles['accordion-generic-card']} 
            ${isClickable && 'c-hand'}`}
              open={isClickable && showActions}
              ref={popupRef}
            >
              <More
                className={`${styles['actions-icon']}
             ${colorMode === 'DARK' ? 'icon-light' : ''}`}
              />
              <label className='accordion-header' htmlFor={componentId}></label>
              <div className={`${styles['accordion-generic-card-body']} accordion-body`}>
                {actions.SHOW_ACTIONS()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default FileCard;
