import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from 'services/auth.service';

export const baseReportingApi = createApi({
    reducerPath: 'baseReportingApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_REPORTING_URL,
        prepareHeaders: async (headers) => {
            if (AuthService.isTokenExpired()) {
                await AuthService.updateToken2();
            }
            headers.set('Authorization', `Bearer ${AuthService.getToken()}`);
            return headers;
        },
    }),
    endpoints: () => ({}),
});
