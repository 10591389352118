import React from 'react';
import PasswordSettings from './PasswordSettings/PasswordSettings';
import useTranslate from 'hooks/useTranslate';

import PersonalSettings from './PersonalSettings/PersonalSettings';

import styles from './Profile.module.scss';
import Tabs from 'components/Tabs/Tabs';
import ProfileNotifications from './ProfileNotifications/ProfileNotifications';
import useFeatureFlags from 'hooks/useFeatureFlags';

interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => {
  const { enableProfilePageNotifications } = useFeatureFlags();

  const translate = useTranslate();

  const getContent = () => {
    const tabs = [
      {
        label: translate('Profile page - personal - tab title'),
        content: <PersonalSettings />,
      },
      {
        label: translate('Profile page - password - tab title'),
        content: <PasswordSettings />,
      },
    ];
    if (enableProfilePageNotifications) {
      tabs.push({
        label: translate('Profile page - notifications - tab title'),
        content: <ProfileNotifications />,
      });
    }

    return tabs;
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{translate('Profile page - Profile')}</div>
      <Tabs tabs={getContent()} className='profile-settings-view' />
    </div>
  );
};
export default Profile;
