import { ReactComponent as CheckMark } from 'styles/icons/checkmark-full.svg';
import { ReactComponent as High } from 'styles/icons/arrow-circle-up-full.svg';
import { ReactComponent as Low } from 'styles/icons/arrow-circle-down-full.svg';
import { ReactComponent as ToBeDetermined } from 'styles/icons/question-mark-circle.svg';
import { ReactComponent as Climate } from 'styles/icons/climate.svg';
import { ReactComponent as WaterManagement } from 'styles/icons/water-management.svg';
import { ReactComponent as Weight } from 'styles/icons/fresh-weight-gain.svg';
import { ReactComponent as WaterEfficiency } from 'styles/icons/water-efficiency.svg';
import { ReactComponent as Production } from 'styles/icons/production.svg';
import { ReactComponent as CropStatus } from 'styles/icons/crop-status.svg';
import { ReactComponent as MinusCircle } from 'styles/icons/minus-circle.svg';
import { translate } from 'utils/translations.util';

export const getTrackStatusIcon = (input: string) => {
  switch (input) {
    case 'ok':
      return <CheckMark className='track-ideal-icon' />;
    case 'to_high':
      return <High className='track-danger-icon' />;
    case 'to_low':
    case 'too_high':
      return <High className='track-danger-icon' />;
    case 'too_low':
      return <Low className='track-danger-icon' />;
    case 'to_be_determined':
      return <ToBeDetermined className='track-ideal-icon' />;
    default:
      return <CheckMark className='track-ideal-icon' />;
  }
};
export const getTrackHeaderIcon = (input?: string) => {
  switch (input) {
    case 'climate':
      return <Climate className='icon-fill track-card-header-icon' />;
    case 'water_management':
      return <WaterManagement className='icon-fill track-card-header-icon' />;
    case 'water_efficiency':
      return <WaterEfficiency className='icon-fill track-card-header-icon' />;
    case 'crop_status':
      return <CropStatus className='icon-fill track-card-header-icon' />;
    case 'production':
      return <Production className='icon-stroke track-card-header-icon' />;
    case 'fresh_weight_gain':
      return <Weight className='icon-fill track-card-header-icon' />;
    default:
      return <MinusCircle className='icon-fill track-card-header-icon' />;
  }
};

export const getTrackStatus = (status: string) => {
  switch (status) {
    case 'ok':
      return 'ideal';
    case 'to_high':
      return 'danger';
    case 'to_low':
      return 'danger';
    case 'too_high':
      return 'danger';
    case 'too_low':
      return 'danger';
    default:
      return 'ideal';
  }
};

export const getCardTitleTranslated = (
  variabileId: string,
  name: string,
  selectedLanguage: string
) => {
  switch (variabileId) {
    case 'climate': {
      return translate('Landing page climate tile title', selectedLanguage);
    }
    case 'water_management': {
      return translate('Landing page Water Management tile title', selectedLanguage);
    }
    case 'water_efficiency': {
      return translate('Landing page Water Efficiency tile title', selectedLanguage);
    }
    case 'crop_status': {
      return translate('Landing page Crop Status tile title', selectedLanguage);
    }
    case 'production': {
      return translate('Landing page Production tile title', selectedLanguage);
    }
    case 'fresh_weight_gain': {
      return translate('Landing page Fresh Weight Gain tile title', selectedLanguage);
    }
    default: {
      return name;
    }
  }
};

export const getVariableDefinitionTranslated = (variableId: string, selectedLanguage: string) => {
  let varDefinition;
  switch (variableId) {
    case 'climate': {
      varDefinition = translate('Landing page climate tile description', selectedLanguage).split(
        ':'
      );
      break;
    }
    case 'water_management': {
      varDefinition = translate(
        'Landing page Water Management tile description',
        selectedLanguage
      ).split(':');
      break;
    }
    case 'fresh_weight_gain': {
      varDefinition = translate(
        'Landing page Fresh Weight Gain tile description',
        selectedLanguage
      ).split(':');
      break;
    }
    case 'water_efficiency': {
      varDefinition = translate(
        'Landing page Water Efficiency tile description',
        selectedLanguage
      ).split(':');
      break;
    }
    case 'crop_status': {
      varDefinition = translate(
        'Landing page Crop Status tile description',
        selectedLanguage
      ).split(':');
      break;
    }
    case 'production': {
      varDefinition = translate('Landing page Production tile description', selectedLanguage).split(
        ':'
      );
      break;
    }
    default: {
      return '';
    }
  }
  if (varDefinition[1]) {
    return varDefinition[1];
  } else {
    return varDefinition;
  }
};

export const getVariableNameTranslated = (
  variableId: string,
  variableName: string,
  selectedLanguage: string
) => {
  switch (variableId) {
    case 'climate': {
      return translate('Landing page climate tile description', selectedLanguage).split(':')[0];
    }
    case 'water_management': {
      return translate('Landing page Water Management tile description', selectedLanguage).split(
        ':'
      )[0];
    }
    case 'fresh_weight_gain': {
      return translate('Landing page Fresh Weight Gain tile description', selectedLanguage).split(
        ':'
      )[0];
    }
    case 'water_efficiency': {
      return translate('Landing page Water Efficiency tile description', selectedLanguage).split(
        ':'
      )[0];
    }
    case 'crop_status': {
      return translate('Landing page Crop Status tile description', selectedLanguage).split(':')[0];
    }
    case 'production': {
      return translate('Landing page Production tile description', selectedLanguage).split(':')[0];
    }
    default: {
      return variableName;
    }
  }
};

export const getVariableUnitTranslated = (
  variableId: string,
  unit: string,
  selectedLanguage: string
) => {
  let unitMasurement = unit.split(' ')[0];

  switch (variableId) {
    case 'climate': {
      return unitMasurement + ' ' + translate('Landing page climate tile period', selectedLanguage);
    }
    case 'water_management': {
      return (
        unitMasurement +
        ' ' +
        translate('Landing page Water Management tile period', selectedLanguage)
      );
    }
    case 'water_efficiency': {
      return unit;
    }
    case 'fresh_weight_gain': {
      return (
        unitMasurement +
        ' ' +
        translate('Landing page Fresh Weight Gain tile period', selectedLanguage)
      );
    }
    case 'production': {
      return (
        unitMasurement + ' ' + translate('Landing page Production tile period', selectedLanguage)
      );
    }
    default: {
      return unit;
    }
  }
};
