import './NotificationItem.scss';
import React from 'react';

import { ReactComponent as LocationIcon } from '../../styles/icons/location.svg';
import { ReactComponent as CropseasonIcon } from '../../styles/icons/cropseason.svg';

import {
  CropseasonNotification,
  MissingDataNotification,
} from 'interfaces/cropseason-notification';
import { nofiticationSelector, notificationActions } from 'redux/slices/notification.slice';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';

import UseGetNotificationItemData from 'hooks/useGetNotificationItemData';
import NotificationItemIcon, {
  NotificationIconStyle,
} from 'components/NotificationItemIcon/NotificationItemIcon';
import {
  NotificationItemConfig,
  NotificationItemStyle,
  NotificationItemType,
} from 'interfaces/notificationItem';
import { getNotificationTooltipExtra } from 'config/notifications/getNotificationTooltipExtra';
import useTranslate from 'hooks/useTranslate';

interface NotificationItemProps {
  data: CropseasonNotification | MissingDataNotification;
  id: number;
  hasAction?: JSX.Element;
  handleNotificationClickCallback?: (
    notificationItem: CropseasonNotification | MissingDataNotification
  ) => void;
  style: NotificationItemStyle;
  type: NotificationItemType;
  isSelected?: boolean | null;
  showNotificationState?: boolean;
  showExtraInfo?: boolean;
}

const NotificationItem: React.FC<NotificationItemProps> = (notificationItem) => {
  const { selectedLanguage } = useAppSelector(languageSelector);
  const translate = useTranslate();
  const dispatch = useAppDispatch();
  const { getFormatedTimestamp, getNotificationInfo } =
    UseGetNotificationItemData(selectedLanguage);
  const selectedNotification = useAppSelector(nofiticationSelector.selectedNotifications);

  const hasActionClassName = notificationItem.hasAction ? 'notification-hasAction' : '';
  const isSelectedClassName = notificationItem.isSelected ? 'notification-selected' : '';

  const showEmphasised = () => {
    return Boolean(!notificationItem.data.has_been_seen && notificationItem.showNotificationState);
  };
  const handleCloseDetails = () => {
    dispatch(notificationActions.setSelectedNotifications(null));
  };

  let iconStyle = [];
  if (notificationItem.style === NotificationItemConfig.StyleDark) {
    iconStyle.push(NotificationIconStyle.styleDark);
  } else {
    iconStyle.push(NotificationIconStyle.styleLight);
  }
  if (notificationItem.type === NotificationItemConfig.TypeTooltip) {
    iconStyle.push(NotificationIconStyle.styleTooltip);
  } else {
    iconStyle.push(NotificationIconStyle.styleBig);
  }

  const getIconStyles = () => {
    let iconStyle = [];
    if (notificationItem.style === NotificationItemConfig.StyleDark) {
      iconStyle.push(NotificationIconStyle.styleDark);
    } else {
      iconStyle.push(NotificationIconStyle.styleLight);
    }
    if (notificationItem.type === NotificationItemConfig.TypeTooltip) {
      iconStyle.push(NotificationIconStyle.styleTooltip);
    } else {
      iconStyle.push(NotificationIconStyle.styleBig);
    }
    return iconStyle;
  };

  const showExtraInfoText = notificationItem.showExtraInfo;

  return (
    <div
      className={`notification-row notification-style-type-${notificationItem.style} notification-type-${notificationItem.type} ${hasActionClassName}  ${isSelectedClassName}`}
      key={`notification-row-${notificationItem.id}`}
      onClick={() => {
        notificationItem.handleNotificationClickCallback &&
          notificationItem.handleNotificationClickCallback(notificationItem.data);

        if (notificationItem.data.id.toString() === selectedNotification?.id) {
          handleCloseDetails();
        }
      }}
    >
      {showExtraInfoText && getNotificationTooltipExtra(notificationItem.data.type) && (
        <div className='notification-row-extra-info'>
          {translate(getNotificationTooltipExtra(notificationItem.data.type))}
        </div>
      )}
      <div className='notification-row-info'>
        <NotificationItemIcon
          type={notificationItem.data.type}
          showEmpasised={showEmphasised()}
          iconStyle={getIconStyles()}
        />

        <div className='notification-row-description'>
          <div>
            <span className='notification-row-description-text'>
              {getNotificationInfo(notificationItem.data)}
            </span>
          </div>
          <div className='notification-row-footer'>
            <div className='notification-footter-left'>
              <div className='footer-info'>
                <LocationIcon
                  width={12}
                  height={15}
                  className='notification-footer-icon'
                ></LocationIcon>
                <span>{notificationItem.data?.location?.name}</span>
              </div>
              <div className='footer-info'>
                <CropseasonIcon
                  width={14}
                  height={15}
                  className='notification-footer-icon'
                ></CropseasonIcon>
                <span>{notificationItem.data.cropseason.name}</span>
              </div>
            </div>
            <span className='notification-row-timestamp'>
              {getFormatedTimestamp(notificationItem.data.local_datetime)}
            </span>
          </div>
        </div>
      </div>
      {notificationItem.hasAction && (
        <div className='notification-row-action'>{notificationItem.hasAction}</div>
      )}
    </div>
  );
};
export default NotificationItem;
