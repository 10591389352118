import Links from 'components/Links/Links';
import React from 'react';

import { useAppSelector } from 'redux/hooks';
import { sideBarSelector } from 'redux/slices/sidebar.slice';
import './SidebarMobile.scss';

const SidebarMobile: React.FC = () => {
  const isOpen = useAppSelector(sideBarSelector).isOpen;
  return (
    <div
      className={`sidebar-mobile off-canvas ${isOpen ? 'display-off-canvas' : 'hide-off-canvas'}`}
    >
      <div className='overlay'></div>
      <div className={`off-canvas-sidebar mobile ${isOpen ? 'active' : ''}`}>
        <Links />
      </div>
    </div>
  );
};
export default SidebarMobile;
