import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Arrow } from 'styles/icons/arrow-right-more.svg';

import AuthService from 'services/auth.service';
import { findAccountName, getUserFullName, getUserInitials } from 'utils/userInfo';

import './Profile.scss';
import { useGetUserLinkedAccountsQuery } from 'services/userAccount.service';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { userInfoSelector } from 'redux/slices/userInfo.slice';
import useTranslate from 'hooks/useTranslate';
import useWindowSize, { WindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT_LAPTOP, TOAST_PROMISE } from 'config/constants';
import { toggle } from 'redux/slices/sidebar.slice';
import { isValidEmail } from 'validators/email.validator';
import { ModalService } from 'services/modal.service';
import InviteModal from 'components/Profile/InviteModal/InviteModal';
import { useInviteUserMutation } from 'services/userInvite.service';
import { useGetLocationsQuery } from 'services/metaData.service';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { track } from 'utils/analtycs';
import { dispatchPromiseToast } from 'utils/toast.util';

interface ProfileProps {
  userDetails: any;
  show: (show: boolean) => void;
}

const Profile: React.FC<ProfileProps> = ({ userDetails, show }) => {
  const { data: linkedAccounts } = useGetUserLinkedAccountsQuery();
  const accountId = useAppSelector(accountIdSelector).accountId;
  const windowSize: WindowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    AuthService.doLogout();
  };
  const navigate = useNavigate();
  const { familyName, givenName } = useAppSelector(userInfoSelector);
  const [inviteUser] = useInviteUserMutation();
  const { data: locations } = useGetLocationsQuery(accountId, { skip: !accountId });
  const { enableUserIvite } = useFeatureFlags();

  const translate = useTranslate();

  const handleLinkClick = () => {
    if (windowSize.width <= BREAKPOINT_LAPTOP) {
      dispatch(toggle());
    }
  };

  const handleInviteUser = () => {
    const input = { email: ' ' };
    const validateFunction = () => {
      return !!isValidEmail(input.email);
    };
    ModalService.open({
      title: translate('Profile - Invite user'),
      cancelText: translate('Cancel generic button'),
      saveText: translate('Invite generic button'),
      validateFunction,
      content: <InviteModal inputValues={input} />,
      closeAction: async (isConfirmed) => {
        if (isConfirmed) {
          track('invite user', { email: input.email, location_id: locations?.[0].id });

          const inviteUserPromise = inviteUser({
            email: input.email,
            location_id: locations?.[0].id,
            account: findAccountName(accountId, linkedAccounts),
          });

          dispatchPromiseToast({
            type: TOAST_PROMISE,
            promise: inviteUserPromise,
            messageLoading: translate('Toast - UserInvite - loading'),
            messageError: translate('Toast - UserInvite - error'),
            messageSuccess: translate('Toast - UserInvite - success'),
          });
        }
      },
    });
  };

  return (
    <div className='profile'>
      <div className='profile-header'>
        <figure
          className='avatar profile-image avatar-mobile'
          data-initial={getUserInitials(givenName, familyName)}
        >
          {userDetails.img && <img src={userDetails.img} alt='profile' />}
        </figure>
        <div className='profile-name'>
          {getUserFullName(givenName, familyName)}
          <div className='account-name'>{findAccountName(accountId, linkedAccounts)}</div>
        </div>
      </div>
      <div className='profile-body'>
        <span
          onClick={() => {
            navigate('/profile');
            handleLinkClick();
          }}
        >
          {translate('Profile Page - selector in top-right menu')}
        </span>
        <span className='switch-account' onClick={() => show(true)}>
          {translate('Profile - Switch account')} <Arrow />
        </span>
        {enableUserIvite && (
          <button className='btn btn-invite' onClick={handleInviteUser}>
            Invite users
          </button>
        )}
        <span onClick={handleLogout}>{translate('Profile - Log out')}</span>
      </div>
      <hr />
    </div>
  );
};
export default Profile;
