import { baseReportingApi } from 'redux/slices/baseReportingApi.slice';
import { MutationResult } from 'interfaces/mutation';


export const userAccountPhoneNumberApi = baseReportingApi.injectEndpoints({
    endpoints: (builder) => ({
        updateUserPhone: builder.mutation<MutationResult<any>, { user: string, phoneNumber: string }>({
            query: ({ user, phoneNumber }) => ({
                url: `users/${user}/phone?phone=${phoneNumber}`,
                body: phoneNumber,
                method: 'PUT',
                credentials: 'include',
            }),
            async onQueryStarted({ phoneNumber }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    console.error('Could not update phone number.');
                }
            },
        }),
    }),
});

export const {
    useUpdateUserPhoneMutation,
} = userAccountPhoneNumberApi;
