import dayjs from 'dayjs';

import { baseApi } from 'redux/slices/baseApi.slice';

import { FilterVariable } from 'interfaces/filter-variable';

import { formatQueryString } from 'utils/queryString';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { QUERY_LIMIT } from 'config/constants';
import { mergeKeyValues } from 'utils/getIntervalsForGraphs';
import { AnalyzeData, VariablesTimeseries } from 'interfaces/analyze';
import {
  addResolutionToTimestamp,
  getEndOfDayFormated,
  getStartOfDayFormated,
  getUnix,
  subtractResolutionFromTimestamp,
} from 'utils/time.util';
import { Resolution } from 'interfaces/resolution';

interface FilterOptionsParams {
  cropSeasonId: number;
  dateRange: { from: number; to: number };
  resolution: string;
  accountId: number;
}

interface AvailableVariablesApiResponse {
  message: string;
  result: {
    variables: FilterVariable[];
  };
}

interface responseTimeseries {
  data: AnalyzeData;
  refetch?: boolean;
  start_local_datetime?: string;
}

interface QueryParams {
  cropSeasonIds: number[];
  startDate: string;
  endDate: string;
  resolution: string;
  variables: any[];
  variables_id: any[];
  skip?: number;
  limit?: number;
  accountId: number;
  refetchQueryStartTime?: string | null;
}

//TODO - remove variable name when all variables have an Id
const queryAnalyzeTimeseries = ({
  cropSeasonIds,
  startDate,
  endDate,
  resolution,
  variables,
  variables_id,
  refetchQueryStartTime = null,
  limit,
  accountId,
}: QueryParams) => {
  const ENDPOINT = `cropseasons/timeseries?`;
  return (
    ENDPOINT +
    formatQueryString({
      limit: limit || QUERY_LIMIT,
      id: cropSeasonIds.map((id) => id.toString()),
      resolution: resolution,
      min_local_datetime:
        refetchQueryStartTime ||
        getStartOfDayFormated(
          subtractResolutionFromTimestamp({
            input: startDate,
            resolution: resolution as Resolution,
          })
        ),
      max_local_datetime: getEndOfDayFormated(
        addResolutionToTimestamp({ input: endDate, resolution: resolution as Resolution })
      ),
      variable: variables,
      variable_id: variables_id,
      current_account_id: accountId,
    })
  );
};

const transformResponse = (rawResult: any, args: QueryParams): any => {
  let response: responseTimeseries = {
    data: {},
    refetch: false,
  };
  const result = rawResult?.result.timeseries;

  if (!result) {
    return response;
  } else {
    Object.keys(result).forEach((cropSeasonId) => {
      let local_datetime = result[cropSeasonId].local_datetime.map((date: string) => getUnix(date));
      result[cropSeasonId].local_datetime = local_datetime;
    });
  }
  response.data = result;
  response.refetch =
    rawResult?.datetime_pagination?.next_min_local_datetime < args.endDate ? true : false;
  response.start_local_datetime = rawResult?.datetime_pagination?.next_min_local_datetime;
  return response;
};

const retrieveTheData = async (
  _arg: QueryParams,
  fetchWithBaseQuery: (
    arg: string | FetchArgs
  ) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>
) => {
  let transformedResponse;
  let missingVariables: string[] = [];
  let response;
  let startDateTime = null;

  do {
    let partialResponse = await fetchWithBaseQuery(
      queryAnalyzeTimeseries({
        ..._arg,
        startDate: _arg.startDate,
        refetchQueryStartTime: startDateTime,
      })
    );
    if (partialResponse.error?.status === 404) {
      return { response: null, missingVariables: Object.values(_arg.variables) };
    }
    transformedResponse = transformResponse(partialResponse.data, _arg);
    startDateTime = transformedResponse.start_local_datetime || null;

    missingVariables = (partialResponse.data as any)?.result.variables_not_found;

    if (response) {
      let cropSeasonIds = Object.keys(response.data);
      let mergedData = null;
      for (let i = 0; i < cropSeasonIds.length; i++) {
        let cropSeasonId = cropSeasonIds[i];
        let variable_array = Object.values(_arg.variables);
        variable_array.push('local_datetime');
        mergedData = {
          ...mergeKeyValues(
            variable_array,
            response.data[cropSeasonId],
            transformedResponse.data[cropSeasonId]
          ),
        };
        response.data[Number(cropSeasonId)] = mergedData;
      }
    } else {
      response = { ...transformedResponse };
    }
  } while (transformedResponse.refetch);

  return { missingVariables, response };
};

const analyzeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFilterOptions: builder.mutation<FilterVariable[], FilterOptionsParams>({
      query: (props: FilterOptionsParams) => {
        const ENDPOINT = `cropseasons/variables?`;

        let dateFrom = dayjs().subtract(2, 'years').unix();
        let dateTo = dayjs().unix();

        if (props.dateRange) {
          dateFrom = props.dateRange.from;
          dateTo = props.dateRange.to;
        }

        return (
          ENDPOINT +
          formatQueryString({
            id: props.cropSeasonId,
            current_account_id: props.accountId,
            resolution: props.resolution,
            min_local_datetime: getStartOfDayFormated(dayjs.unix(dateFrom).toISOString()),
            max_local_datetime: getEndOfDayFormated(dayjs.unix(dateTo).toISOString()),
          })
        );
      },
      transformResponse: (rawResult: AvailableVariablesApiResponse): FilterVariable[] => {
        const variables = rawResult.result.variables;
        return variables;
      },
    }),

    getTimeseries: builder.query<AnalyzeData, QueryParams>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        const { response } = await retrieveTheData(_arg, fetchWithBaseQuery);
        let transformedResponse: AnalyzeData = {};
        let variablesData: VariablesTimeseries = {};

        Object.keys(response.data).forEach((cropSeasonId: string) => {
          let local_datetime = [...response.data[cropSeasonId].local_datetime];
          let variables = Object.keys(response.data[cropSeasonId]).filter(
            (key) => key !== 'local_datetime'
          );
          variablesData = {};
          variables.forEach((variable) => {
            variablesData[variable] = response.data[cropSeasonId][variable];
          });

          transformedResponse[cropSeasonId] = {
            local_datetime: local_datetime,
            variables: variablesData,
          };
        });
        return { data: transformedResponse };
      },
    }),

    getDayProfiles: builder.query<AnalyzeData, QueryParams>({
      query: ({ cropSeasonIds, startDate, endDate, variables, variables_id, accountId }) => {
        const ENDPOINT = `cropseasons/day-profiles?`;

        return (
          ENDPOINT +
          formatQueryString({
            id: cropSeasonIds.map((id) => id.toString()),
            min_local_datetime: getStartOfDayFormated(startDate),
            max_local_datetime: getEndOfDayFormated(endDate),
            variable: variables,
            variable_id: variables_id,
            current_account_id: accountId,
          })
        );
      },
      transformResponse: (rawResult: any): AnalyzeData => {
        let transformedResponse: AnalyzeData = {};
        let variablesData: VariablesTimeseries = {};

        Object.keys(rawResult.result.timeseries).forEach((cropSeasonId: string) => {
          variablesData = {};
          let local_datetime = [...rawResult.result.timeseries[cropSeasonId].time];
          let variables = Object.keys(rawResult.result.timeseries[cropSeasonId]).filter(
            (key) => key !== 'time'
          );
          variables.forEach((variable) => {
            variablesData[variable] = rawResult.result.timeseries[cropSeasonId][variable];
          });

          transformedResponse[cropSeasonId] = {
            local_datetime: local_datetime,
            variables: variablesData,
          };
        });
        return transformedResponse;
      },
    }),
  }),
});

export const { useGetFilterOptionsMutation, useGetTimeseriesQuery, useGetDayProfilesQuery } =
  analyzeApi;
