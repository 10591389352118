import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

import { MetaData } from 'interfaces/meta-data';
import { CropSeason } from 'interfaces/crop-season';

export interface planCreationState {
  variety?: MetaData;
  rootstock?: MetaData;
  location?: MetaData;
  plantingDate?: number;
  firstSettingDate?: number;
  headRemovalDate?: number;
  sowingDate?: number;
  harvestDate?: number; //This is actually the end_date
  fileName?: string;
  folderName?: string;
  previewPlanId?: number;
  openSaveModal?: boolean;
  planCreationStarted: boolean;
  linkedCropseason?: CropSeason;
  confirmedCropSeason?: boolean;
  touchedHeadRemovalDate?: boolean;
  touchedHarvestDate?: boolean;
  touchedSowingDate?: boolean;
  touchedFirstSettingDate?: boolean;
}

const initialState: planCreationState = {
  planCreationStarted: false,
};

export const planCreationSlice = createSlice({
  name: 'planCreation',
  initialState,
  reducers: {
    setOption: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    setState: (state, { payload }) => {
      return {
        ...payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setOption, reset, setState } = planCreationSlice.actions;

export const planCreationSelector = (state: RootState): planCreationState => state.planCreation;

export default planCreationSlice.reducer;

export type StateKey = keyof typeof initialState;
