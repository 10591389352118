import { KeyValue } from 'interfaces/keyvalue';

type PlanDataVariableItem = {
  timestamp: number;
  [value: string]: number;
};

const GUARD_RAIL_X_PERCENT_VALUE = 0.1;

export const getGuardrailsDataBasedOnPlan = (
  planData: any,
  variableskey: KeyValue<string>,
  isGuardrailsEnabled: boolean
) => {
  //TODO - need to define a interface for guardrailBasedOnPlan, Guardrails, Plan, etc

  let guardrailBasedOnPlan: any = {};
  if (isGuardrailsEnabled) {
    guardrailBasedOnPlan.ranges = {};

    if (planData && planData.ranges) {
      Object.entries(variableskey).forEach(([key]) => {
        guardrailBasedOnPlan[key] = [];
        planData?.[key]?.forEach((item: PlanDataVariableItem) => {
          let variable = `${key}Plan`;
          let lowGuardrailValue = item[variable] - item[variable] * GUARD_RAIL_X_PERCENT_VALUE;
          let highGuardrailValue = item[variable] + item[variable] * GUARD_RAIL_X_PERCENT_VALUE;
          guardrailBasedOnPlan[key].push({
            timestamp: item.timestamp,
            value: [lowGuardrailValue, highGuardrailValue],
          });
        });
        guardrailBasedOnPlan.ranges[key] = {
          min: planData.ranges[key]?.min - planData.ranges[key]?.min * GUARD_RAIL_X_PERCENT_VALUE,
          max: planData.ranges[key]?.max + planData.ranges[key]?.max * GUARD_RAIL_X_PERCENT_VALUE,
        };
      });
    }
  }
  return guardrailBasedOnPlan;
};
