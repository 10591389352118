import MultilineChart from 'components/GraphElements/MultilineChart/MultilineChart';
import useGetUnitForGraph from 'hooks/useGetUnitForGraph';
import React from 'react';
import { renderAreaData, renderLineData } from 'utils/renderDataInGraph';
import ClippingMask from 'components/GraphElements/ClippingMask/ClippingMask';

import {
  ADAPTIVE_PLAN_GRAH_COLOR,
  GUARD_RAIL_GRAPH_COLOR,
  GUARD_RAIL_GRAPH_LEGEND_TOOLTIP_COLOR,
  PLAN_GRAP_COLOR,
  PLAN_VARIABLES_OLD_NAME_ID_MAPPING,
  VARIABLE_TOOLTIP_DECIMALS,
} from 'config/constants';
import { hasExactYInterval } from 'utils/variables.util';
import { Tooltip } from 'react-tooltip';
import { useAppSelector } from 'redux/hooks';
import { languageSelector } from 'redux/slices/language.slice';
import { getEnumValue } from 'utils/getEnumValue';
import { getYAxisInterval } from 'utils/getYAxisInterval';
import { getUniqueId } from 'utils/getUniqueId';
import dayjs from 'dayjs';
import { trackDataTogglesSelector } from 'redux/slices/graphDataToggleSwitches.slice';
import {
  getPlanToActualsMapper,
  getVariableDataMapper,
  getVariableName,
} from 'utils/plan-variables-mapper';

interface PlanGraphProps {
  actual: any;
  guardrail: any;
  plan: any;
  planDetails?: any;
  variableKey: string;
  variableValue: string;
  resolution: string;
  dates: [number, number];
  adaptivePlan?: any;
}

const getTooltipText = (noAvailableText: string) => {
  return (
    <div>
      No {noAvailableText} available <br /> for this variable
    </div>
  );
};

export const renderTooltipDisabled = (noAvailableText: string, tooltipAnchor: string) => {
  return (
    <div className='info-icon'>
      <Tooltip
        data-html={true}
        anchorSelect={`${tooltipAnchor}`}
        place='top'
        positionStrategy='fixed'
        className='planGraph-tooltip'
        style={{ backgroundColor: '#fff', color: '#000' }}
      >
        {getTooltipText(noAvailableText)}
      </Tooltip>
    </div>
  );
};

const PlanGraph: React.FC<PlanGraphProps> = ({
  actual,
  guardrail,
  plan,
  planDetails,
  variableKey,
  variableValue,
  resolution,
  dates,
  adaptivePlan,
}) => {
  const { selectedLanguage } = useAppSelector(languageSelector);
  const planVariableKey = (PLAN_VARIABLES_OLD_NAME_ID_MAPPING as Record<string, string>)[
    variableValue
  ];
  let guardrailDataTimeseries: any[] = [];

  const toggles = useAppSelector(trackDataTogglesSelector);

  const hasGuardrail = (): boolean => {
    return guardrail && guardrail[planVariableKey]?.length;
  };

  const hasPlan = (): boolean => {
    return plan && plan[planVariableKey]?.length;
  };

  const hasActuals = (): boolean => {
    return actual && actual[getPlanToActualsMapper(variableKey)]?.length;
  };

  const hasAdaptivePlan = (): boolean => {
    return adaptivePlan && adaptivePlan[variableKey]?.length;
  };

  const graphUnit = useGetUnitForGraph(getPlanToActualsMapper(variableValue));

  const dataToDisplay: any = [];

  if (hasActuals() && toggles.actuals) {
    dataToDisplay.push({
      keyName: `${getPlanToActualsMapper(variableKey)}Actual`,
      color: '#fff',
      data: actual?.[getPlanToActualsMapper(variableKey)],
      unit: graphUnit,
    });
  }

  if (hasPlan() && toggles.plan) {
    const planDataTimeseries = plan
      ? getVariableDataMapper(planVariableKey, plan[planVariableKey], `${planVariableKey}Plan`)
      : [];
    dataToDisplay.push({
      keyName: `${planVariableKey}Plan`,
      color: PLAN_GRAP_COLOR,
      data: planDataTimeseries,
      unit: graphUnit,
    });
  }

  if (hasAdaptivePlan() && toggles.adaptivePlan) {
    const adaptivePlanData = adaptivePlan
      ? getVariableDataMapper(variableKey, adaptivePlan[variableKey], `${variableKey}AdaptivePlan`)
      : [];
    dataToDisplay.push({
      keyName: `${variableKey}AdaptivePlan`,
      color: ADAPTIVE_PLAN_GRAH_COLOR,
      data: adaptivePlanData,
      unit: graphUnit,
    });
  }
  //TODO investigate why not having guardrails as the last element  in dataToDisaplay, breaks the guardrails points(not showing max point)

  if (hasGuardrail() && toggles.guardRails) {
    guardrailDataTimeseries = guardrail
      ? getVariableDataMapper(
          planVariableKey,
          guardrail[planVariableKey],
          `${planVariableKey}Guardrails`
        )
      : [];

    dataToDisplay.push({
      keyName: `${planVariableKey}Guardrails`,
      color: GUARD_RAIL_GRAPH_LEGEND_TOOLTIP_COLOR,
      prefix: 'Ideal range: ',
      data: guardrailDataTimeseries,
      type: 'area',
      hasRange: true,
    });
  }

  const HAS_MASK = toggles.guardRails ? hasGuardrail() : false;

  const getCalculatedYAxisInterval = (variableKey: any) => {
    let planVariableKey = (PLAN_VARIABLES_OLD_NAME_ID_MAPPING as Record<string, string>)[
      variableValue
    ];

    if (planVariableKey === PLAN_VARIABLES_OLD_NAME_ID_MAPPING.dli && actual) {
      guardrailDataTimeseries = guardrail
        ? getVariableDataMapper(
            planVariableKey,
            guardrail[planVariableKey],
            `${planVariableKey}Guardrails`
          )
        : [];

      const planDataTimeseries = plan
        ? getVariableDataMapper(planVariableKey, plan[planVariableKey], `${planVariableKey}Plan`)
        : [];
      const adaptivePlanData = adaptivePlan
        ? getVariableDataMapper(
            variableKey,
            adaptivePlan[variableKey],
            `${variableKey}AdaptivePlan`
          )
        : [];
      let guardrailRanges, planRanges, adaptivePlanRanges, actualsRanges;
      actualsRanges = actual?.ranges ? actual.ranges[getPlanToActualsMapper(variableKey)] : [];
      if (guardrailDataTimeseries && guardrailDataTimeseries.length) {
        guardrailRanges = {
          min: Math.min(...guardrailDataTimeseries.map((item: any) => item.value[0])),
          max: Math.max(...guardrailDataTimeseries.map((item: any) => item.value[1])),
        };
      } else {
        guardrailRanges = {
          min: -Infinity,
          max: +Infinity,
        };
      }
      if (planDataTimeseries && planDataTimeseries.length) {
        planRanges = {
          min: Math.min(...planDataTimeseries.map((item: any) => item[`${planVariableKey}Plan`])),
          max: Math.max(...planDataTimeseries.map((item: any) => item[`${planVariableKey}Plan`])),
        };
      } else {
        planRanges = {
          min: -Infinity,
          max: Infinity,
        };
      }

      if (adaptivePlanData && adaptivePlanData.length) {
        adaptivePlanRanges = {
          min: Math.min(...adaptivePlanData.map((item: any) => item[`${variableKey}AdaptivePlan`])),
          max: Math.max(...adaptivePlanData.map((item: any) => item[`${variableKey}AdaptivePlan`])),
        };
      } else {
        adaptivePlanRanges = {
          min: -Infinity,
          max: Infinity,
        };
      }

      return getYAxisInterval([actualsRanges, guardrailRanges, planRanges, adaptivePlanRanges]);
    } else {
      return getYAxisInterval([
        actual?.ranges ? actual.ranges[variableKey] : [],
        guardrail?.ranges ? guardrail.ranges[planVariableKey] : [],
        plan?.ranges ? plan.ranges[planVariableKey] : [],
        adaptivePlan?.ranges ? adaptivePlan.ranges[variableKey] : [],
      ]);
    }
  };
  const graphYInterval = getCalculatedYAxisInterval(variableKey);

  const boundaryMaskId = getUniqueId();

  const renderGraph = (): any => {
    let graphElements = [];
    if (hasGuardrail() && toggles.guardRails) {
      graphElements.unshift(
        ...renderAreaData({
          data: guardrailDataTimeseries ? guardrailDataTimeseries : guardrail?.[planVariableKey],
          color: GUARD_RAIL_GRAPH_COLOR,
          key: `${planVariableKey}area`,
          hasMask: true,
          maskId: boundaryMaskId,
          opacity: 0.4,
        })
      );
    }
    if (hasPlan() && toggles.plan) {
      const planDataTimeseries = plan
        ? getVariableDataMapper(planVariableKey, plan[planVariableKey], `${planVariableKey}Plan`)
        : [];

      graphElements.push(
        ...renderLineData({
          data: planDataTimeseries,
          variable: `${planVariableKey}Plan`,
          color: PLAN_GRAP_COLOR,
          hasBoundaryMask: true,
          boundaryMaskId: boundaryMaskId,
          hideLine: true,
          maskLineColor: PLAN_GRAP_COLOR,
        })
      );
    }
    if (hasAdaptivePlan() && toggles.adaptivePlan) {
      const adaptivePlanData = adaptivePlan
        ? getVariableDataMapper(
            variableKey,
            adaptivePlan[variableKey],
            `${variableKey}AdaptivePlan`
          )
        : [];
      graphElements.push(
        ...renderLineData({
          data: adaptivePlanData,
          variable: `${variableKey}AdaptivePlan`,
          color: ADAPTIVE_PLAN_GRAH_COLOR,
          hasBoundaryMask: true,
          boundaryMaskId: boundaryMaskId,
          hideLine: true,
          maskLineColor: ADAPTIVE_PLAN_GRAH_COLOR,
        })
      );
    }
    graphElements.push(
      <ClippingMask
        key={'plan-mask'}
        maskId={boundaryMaskId}
        isInverted={true}
        data={{
          areaData: [
            {
              timestamp: dayjs(dates[0] * 1000)
                .startOf('day')
                .utc(true)
                .unix(),
              value: graphYInterval,
            },
            {
              timestamp: dayjs(dates[1] * 1000)
                .endOf('day')
                .utc(true)
                .unix(),
              value: graphYInterval,
            },
          ],
        }}
      />
    );

    if (hasActuals() && toggles.actuals) {
      graphElements.push(
        <ClippingMask data={{ areaData: guardrail?.[planVariableKey] }} key={'mask'}>
          {renderLineData({
            data: actual?.[getPlanToActualsMapper(variableKey)],
            variable: `${getPlanToActualsMapper(variableKey)}Actual`,
            color: '#fff',
            hasMask: HAS_MASK,
            hasBoundaryMask: true,
            hideLine: true,
            boundaryMaskId: boundaryMaskId,
          })}
        </ClippingMask>
      );
    }

    return graphElements;
  };

  return (
    <div className='graph'>
      <MultilineChart
        customClassName='guardrails-graph'
        xAxisProperty='timestamp'
        dataToDisplay={dataToDisplay}
        title={getVariableName(variableKey, selectedLanguage)}
        hasTooltip={true}
        resolution={resolution}
        XInterval={[
          dayjs.unix(dates[0]).startOf('day').utc(true).unix(),
          dayjs.unix(dates[1]).startOf('day').utc(true).unix(),
        ]}
        YInterval={[{ min: graphYInterval[0], max: graphYInterval[1] }]}
        hasYIntervalExact={hasExactYInterval(variableKey)}
        yAxisUnit={graphUnit}
        applyPadding={false}
        tooltipDecimals={getEnumValue(VARIABLE_TOOLTIP_DECIMALS, variableKey)}
        axisDecimals={getEnumValue(VARIABLE_TOOLTIP_DECIMALS, variableKey)}
      >
        {renderGraph()}
      </MultilineChart>
    </div>
  );
};
export default PlanGraph;
