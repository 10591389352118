import { Range } from 'interfaces/range';
import { GraphLine } from 'interfaces/line-graph-data';
import { Twilight } from 'interfaces/twilights';
import { useCallback } from 'react';
import { useGetTwilightsQuery } from 'services/misc.service';
import { getUtcIso } from 'utils/time.util';

export default function useTwilights(
  locationId: number,
  startDate: number,
  endDate: number,
  graphLines: GraphLine[] = [],
  timeRange: Range
): { getSunsetSunriseData: () => Twilight[] } {
  const { data: twilightsData } = useGetTwilightsQuery(
    {
      location_id: locationId,
      min_local_datetime: getUtcIso(startDate),
      max_local_datetime: getUtcIso(endDate),
    },
    {
      skip: !locationId || !startDate || !endDate,
    }
  );

  const getSunsetSunriseData = useCallback((): Twilight[] => {
    const dataLines = graphLines.length;
    if (!dataLines || !twilightsData || !twilightsData.length) {
      return [];
    }
    const lastTwilight = twilightsData[twilightsData.length - 1];
    const filteredTwilights = twilightsData.filter(({ timestamp }) => {
      return timestamp > timeRange.min && timestamp < timeRange.max;
    });
    filteredTwilights.push({
      timestamp: timeRange.max,
      isSunrise: !lastTwilight.isSunrise,
    });
    filteredTwilights.unshift({
      timestamp: timeRange.min,
      isSunrise: !filteredTwilights[0].isSunrise,
    });
    return filteredTwilights as Twilight[];
  }, [graphLines, twilightsData, timeRange.min, timeRange.max]);

  return { getSunsetSunriseData };
}
