import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as Link } from 'styles/icons/link.svg';
import { ReactComponent as ChevronIcon } from 'styles/icons/chevron-down.svg';
import { ReactComponent as CheckMark } from 'styles/icons/checkmark.svg';

import styles from './DropUp.module.scss';

interface DropUpProps {
  optionList: any[];
  displayProperty?: string;
  initialValue: any;
  setOnChange: (option: any) => void;
  initialText?: string;
  hasTooltip?: boolean;
}

const DropUp: React.FC<DropUpProps> = ({
  optionList,
  displayProperty,
  initialValue,
  setOnChange,
  initialText,
  hasTooltip = false,
}) => {
  const dropUpRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);
  const [value, setValue] = useState(initialValue);

  function handleClick({ target }: MouseEvent) {
    if (dropUpRef.current && showList && !dropUpRef.current.contains(target as Node)) {
      setShowList(false);
    }
  }

  function getOptionValue(option: any, property?: string) {
    if (property) {
      return option?.[property];
    } else {
      return option;
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  function handleOptionSelect(option: string) {
    setValue(option);
    setShowList(false);
    setOnChange(option);
  }

  function checkOption(option: string) {
    const currentValue = getOptionValue(value, displayProperty);
    if (currentValue === option) {
      return <CheckMark />;
    }
  }

  return (
    <div className={styles.container} ref={dropUpRef}>
      <div className={styles.title} onClick={() => setShowList(!showList)}>
        <Link className={`${value ? 'icon-light' : 'icon-fill'}`} />
        <div className={`${styles.text} ${!value && styles['missing-value']}`}>
          {value ? getOptionValue(value, displayProperty!) : initialText}
        </div>
        <ChevronIcon
          className={`${value ? 'icon-light' : 'icon-fill'} ${showList && styles['rotate-180']}`}
        />
      </div>
      {showList && (
        <div className={styles.content}>
          {optionList?.map((option: any, index: number) => (
            <div
              title={hasTooltip ? getOptionValue(option, displayProperty!) : undefined}
              className={styles['option-container']}
              key={index}
              onClick={() => handleOptionSelect(option)}
            >
              <div className={styles.option}>
                {getOptionValue(option, displayProperty!)}
                {checkOption(getOptionValue(option, displayProperty!))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropUp;
