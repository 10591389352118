import useTranslate from 'hooks/useTranslate';
import { CustomSettingsVariabiles } from 'interfaces/custom-settings';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { customPlanSelector } from 'redux/slices/customPlan.slice';
import { isMissing } from 'utils/general.util';

import ArtificialLights from './ArtificialLights/ArtificialLights';
import CO2 from './CO2/CO2';
import ECIrrigation from './ECIrrigation/ECIrrigation';
import styles from './PlanCustomSettings.module.scss';
import Pruning from './Pruning/Pruning';
import Ripening from './Ripening/Ripening';
import StemDensity from './StemDensity/StemDensity';
import TemperatureStrategies from './TemperatureStrategy/TemperatureStrategies';

interface PlanCustomSettingsProps {
  plantingDate: number;
  harvestDate: number;
  headRemovalDate: number;
  customPlanSettings: CustomSettingsVariabiles;
  locationId: number;
}

const PlanCustomSettings: React.FC<PlanCustomSettingsProps> = ({
  plantingDate,
  harvestDate,
  headRemovalDate,
  customPlanSettings,
  locationId,
}) => {
  const translate = useTranslate();

  const AL_enabled = useAppSelector(customPlanSelector).customPlanState?.AL_enabled;
  const customPlanState = useAppSelector(customPlanSelector).customPlanState;

  const getAL_enabled = (): boolean => {
    if (!isMissing(AL_enabled)) {
      return AL_enabled!;
    }
    if (!isMissing(customPlanSettings?.AL_enabled)) {
      return customPlanSettings?.AL_enabled || false;
    }
    return false;
  };

  const hasArtificialLights = getAL_enabled();

  const temperatureStrategiesParams = {
    transplant_date: plantingDate,
    end_date: harvestDate,
    AL_enabled: hasArtificialLights,
    AL_max_capacity: customPlanSettings.AL_max_capacity || customPlanState.AL_max_capacity,
    AL_percentage_LED: customPlanSettings.AL_percentage_LED || customPlanState.AL_percentage_LED,
    AL_radiation_threshold:
      customPlanSettings.AL_radiation_threshold || customPlanState.AL_radiation_threshold,
    location_id: locationId,
  };

  return (
    <div className={`${styles['main-container']} ${styles['menu-links-container']}`}>
      <hr className={styles['horizontal-divider']} />
      <ul className={`${styles['menu-links']} ${styles.menu} ${styles['p-relative']}`}>
        <li className={`${styles['menu-item']}`}>
          <div className={`${styles['accordion-links']} ${styles.accordion}`}>
            <input
              type='checkbox'
              id='accordion-links-artificial-lights'
              name='accordion-checkbox '
              hidden
            />
            <label
              className={`${styles['accordion-header']} c-hand`}
              htmlFor='accordion-links-artificial-lights'
            >
              {translate('Custom Plan - artificial lights - title')}
              <i className={`icon icon-arrow-down ${styles['rotate-180']}`} />
            </label>

            <div
              className={`${styles['accordion-body']} ${styles['content-artificial-lights']} accordion-body `}
            >
              <ArtificialLights
                ALInitialData={{
                  AL_max_capacity: customPlanSettings.AL_max_capacity,
                  AL_percentage_LED: customPlanSettings.AL_percentage_LED,
                  AL_radiation_threshold: customPlanSettings.AL_radiation_threshold,
                  AL_enabled: hasArtificialLights,
                }}
              />
            </div>
          </div>
        </li>
        <hr className={styles['horizontal-divider']} />
        <li className={`${styles['menu-item']}`}>
          <div className={`${styles['accordion-links']} ${styles.accordion}`}>
            <input type='checkbox' id='accordion-links-co2' name='accordion-checkbox ' hidden />
            <label className={`${styles['accordion-header']} c-hand`} htmlFor='accordion-links-co2'>
              {translate('Custom Plan - CO2 - title')}
              <i className={`icon icon-arrow-down ${styles['rotate-180']}`} />
            </label>
            <div className={`${styles['accordion-body']} ${styles['content-co2']} accordion-body `}>
              <CO2
                CO2InitialValues={{
                  CO2_min: customPlanSettings.CO2_min,
                  CO2_max: customPlanSettings.CO2_max,
                }}
              />
            </div>
          </div>
        </li>

        <hr className={styles['horizontal-divider']} />

        <>
          <li className={styles['menu-item']}>
            <div className={`${styles['accordion-links']} ${styles.accordion}`}>
              <input
                type='checkbox'
                id='accordion-links-temperature-strategy'
                name='accordion-checkbox'
                hidden
              />
              <label
                className={`${styles['accordion-header']} c-hand`}
                htmlFor='accordion-links-temperature-strategy'
              >
                {translate('Custom settings - Temperature Strategy - title')}
                <i className={`icon icon-arrow-down ${styles['rotate-180']}`} />
              </label>
              <div
                className={`${styles['accordion-body']} ${styles['content-temperature-strategy']} accordion-body `}
              >
                <TemperatureStrategies
                  temperatureInitialValues={customPlanSettings.phase_temp_strategies}
                  hasLights={hasArtificialLights}
                  temperatureStrategiesParams={temperatureStrategiesParams}
                />
              </div>
            </div>
          </li>
          <hr className={styles['horizontal-divider']} />
        </>

        <>
          <li className={styles['menu-item']}>
            <div className={`${styles['accordion-links']} ${styles.accordion}`}>
              <input
                type='checkbox'
                id='accordion-links-ec-irrigation'
                name='accordion-checkbox'
                hidden
              />
              <label
                className={`${styles['accordion-header']} c-hand`}
                htmlFor='accordion-links-ec-irrigation'
              >
                {translate('Custom settings - EC - title')}
                <i className={`icon icon-arrow-down ${styles['rotate-180']}`} />
              </label>
              <div
                className={`${styles['accordion-body']} ${styles['content-ec-irrigation']} accordion-body `}
              >
                <ECIrrigation ECIrrigation={customPlanSettings.EC}></ECIrrigation>
              </div>
            </div>
          </li>
          <hr className={styles['horizontal-divider']} />
        </>

        <li className={styles['menu-item']}>
          <div className={`${styles['accordion-links']} ${styles.accordion}`}>
            <input type='checkbox' id='accordion-links-pruning' name='accordion-checkbox' hidden />
            <label
              className={`${styles['accordion-header']} c-hand`}
              htmlFor='accordion-links-pruning'
            >
              {translate('Custom Plan - pruning - title')}
              <i className={`icon icon-arrow-down ${styles['rotate-180']}`} />
            </label>
            <div
              className={`${styles['accordion-body']} ${styles['content-pruning']} accordion-body `}
            >
              <Pruning
                harvestDate={harvestDate}
                pruningEvents={customPlanSettings.pruning_events}
              />
            </div>
          </div>
          <hr className={styles['horizontal-divider']} />
        </li>

        <li className={`${styles['menu-item']}`}>
          <div className={`${styles['accordion-links']} ${styles.accordion}`}>
            <input
              type='checkbox'
              id='accordion-links-stem-density'
              name='accordion-checkbox '
              hidden
            />
            <label
              className={`${styles['accordion-header']} c-hand`}
              htmlFor='accordion-links-stem-density'
            >
              {translate('Custom Plan - stem density - "title')}
              <i className={`icon icon-arrow-down ${styles['rotate-180']}`} />
            </label>
            <div
              className={`${styles['accordion-body']} ${styles['content-stem-density']} accordion-body `}
            >
              <StemDensity
                harvestDate={harvestDate}
                stemDensityEvents={customPlanSettings.stem_density_events}
              />
            </div>
          </div>
        </li>
        <hr className={styles['horizontal-divider']} />

        <li className={`${styles['menu-item']}`}>
          <div className={`${styles['accordion-links']} ${styles.accordion}`}>
            <input
              type='checkbox'
              id='accordion-links-ripening'
              name='accordion-checkbox '
              hidden
            />
            <label
              className={`${styles['accordion-header']} c-hand`}
              htmlFor='accordion-links-ripening'
            >
              {translate('Custom settings - Accelerated ripening - title')}
              <i className={`icon icon-arrow-down ${styles['rotate-180']}`} />
            </label>
            <div
              className={`${styles['accordion-body']} ${styles['content-ripening']} accordion-body `}
            >
              <Ripening
                accelerated_ripening={customPlanSettings.accelerated_ripening}
                head_removal_date={headRemovalDate}
                end_date={harvestDate}
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default PlanCustomSettings;
