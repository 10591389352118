import { BENCHMARK_RIBBON_COLOR, activeCropseasonInitialValue } from 'config/constants';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { cropseasonSelector } from 'redux/slices/cropseason.slice';
import { useGetBenchMarkSuggestionsQuery } from 'services/benchmark.service';
import { ReactComponent as Question } from 'styles/icons/question-mark-circle.svg';

interface RibbonLegend {
  color: string;
  label: string;
  stateName: string;
  customContent: JSX.Element;
}

export default function useGetRibbon(): {
  getRibbonLegend: (color: string, id: string, name: string) => RibbonLegend;
  getRibbonName: (input: string) => string;
} {
  const accountId = useAppSelector(accountIdSelector).accountId;
  const { id: cropseasonId, name: cropseasonName } = useAppSelector(cropseasonSelector);
  const { data: suggestions } = useGetBenchMarkSuggestionsQuery(
    { cropseasonId, accountId },
    {
      skip: cropseasonId === activeCropseasonInitialValue.id || !accountId,
      refetchOnMountOrArgChange: true,
    }
  );

  const getRibbonLegend = (color: string, id: string, name: string): RibbonLegend => ({
    color: color,
    label: name,
    stateName: BENCHMARK_RIBBON_COLOR,
    customContent: (
      <div className='question-icon'>
        <Question
          width={20}
          className='icon-light c-hand'
          id={`tooltip-anchor-${id}`}
          data-tooltip-variant='light'
        />
        <Tooltip anchorSelect={`#tooltip-anchor-${id}`} place='right' positionStrategy='fixed'>
          Based on participant with the highest
          <br />
          and the participant with the lowest
          <br />
          value on a specific moment.
        </Tooltip>
      </div>
    ),
  });

  const getRibbonName = (input: string) => {
    const ribbon = suggestions?.find((suggestion) => suggestion.id === input)!;

    return ribbon?.name || 'Ribbon';
  };

  return { getRibbonLegend, getRibbonName };
}
