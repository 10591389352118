import { current } from '@reduxjs/toolkit';
import useGetRibbon from 'hooks/useGetRibbon';
import { LegendItem } from 'interfaces/legend-item';
import { useEffect, useState, useRef } from 'react';
import { getUniqueId } from './getUniqueId';
import { BENCHMARK_RIBBON_COLOR } from 'config/constants';

const useResizeObserver = () => {
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef(null);
  const CONTENT_PADDING = 30;

  useEffect(() => {
    const containerNode = containerRef.current;
    if (!containerNode) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === containerNode) {
          const height = entry.contentRect.height + CONTENT_PADDING;
          setContainerHeight(height);
        }
      }
    });

    resizeObserver.observe(containerNode);

    return () => resizeObserver.disconnect();
  }, []);

  return { containerRef, containerHeight };
};

const getBenchmarkLenged = (
  currentAccountName: string,
  cropseasonName: string,
  legends: any,
  ribbons: any,
  variables: any
) => {
  let legend: LegendItem[] = [
    {
      color: '#fff',
      title: currentAccountName,
      label: cropseasonName,
      stateName: '#fff',
    },
  ];
  const { getRibbonLegend, getRibbonName } = useGetRibbon();

  if (legends) {
    const firstLegendGroup = Object.values(legends)[0] as LegendItem[];
    if (firstLegendGroup) {
      legend = legend.concat(firstLegendGroup);
    }
  }

  if (ribbons && Object.values(ribbons).length > 0) {
    let name = '';
    let id = '';
    Object.entries(variables).map(([key, value]) => {
      Object?.values(ribbons)?.forEach((area: any, index: number) => {
        const id = getUniqueId();
        if (area?.data[value as string]) {
          name = getRibbonName(Object.keys(ribbons)[index]);
        }
      });
    });
    legend = legend.concat(getRibbonLegend(BENCHMARK_RIBBON_COLOR, id, name));
  }
  return legend;
};

export { useResizeObserver, getBenchmarkLenged };
