import {
  EXACT_Y_INTERVAL_VARIABLES,
  RESOLUTION,
  VARIABLE_MAX_RESOLUTION,
  VARIABLES_STATUS,
} from 'config/constants';
import variablesByResolution from 'config/variables-by-resolution.json';
import dayjs from 'dayjs';
import { AnalyzeCategory } from 'interfaces/analyze';
import { FilterVariable } from 'interfaces/filter-variable';
import { KeyValue } from 'interfaces/keyvalue';
import { Resolution } from 'interfaces/resolution';

import { getEnumValue } from './getEnumValue';
import { translate } from './translations.util';

export const mapVariablesToFilterOptions = (
  variables: FilterVariable[] = [],
  selectedLanguage: string
): AnalyzeCategory[] => {
  const filters: { [key: string]: AnalyzeCategory } = {};
  variables.forEach((variable, index) => {
    //TODO - remove variable name when all variables have an Id
    const variableId = variable.id || variable.name;
    filters[variable.type] = {
      options: [
        ...(filters[variable.type]?.options ?? []),
        {
          id: variableId,
          name: variableId + index,
          label: translate(variableId, selectedLanguage),
          value: variableId,
          unit: translate(variable.unit, selectedLanguage),
          source: variable.source,
          scalingGroup: variable.scaling_group,
        },
      ],
      type: translate(
        `Analyze select variable - chapter title - ${variable.type}`,
        selectedLanguage
      ),
    };
  });

  let filterOptions = Object.values(filters);

  filterOptions.forEach((variableSet) => {
    if (Array.isArray(variableSet.options)) {
      variableSet.options.sort((a, b) => a.label.localeCompare(b.label));
    }
  });

  return filterOptions;
};

export const getVariableName = (variables: KeyValue<string>, variable: string) => {
  if (!variables || !variable) {
    return '';
  }

  for (let entry of Object.entries(variables)) {
    if (entry[1] === variable) {
      return entry[0];
    }
  }

  return '';
};

export const getKeyValuesFromVariables = (keyValues: KeyValue<string>, variables: string[]) => {
  const result: KeyValue<string> = {};
  variables.forEach((variable) => (result[getVariableName(keyValues, variable)] = variable));
  return result;
};

export const getResolutionFromMissingVariables = (
  variables: KeyValue<string>,
  missingVariables: string[]
) => {
  const resolutions: KeyValue<string[]> = {};

  if (!missingVariables) {
    return resolutions;
  }

  missingVariables.forEach((variable) => {
    const resolution = getEnumValue(VARIABLE_MAX_RESOLUTION, getVariableName(variables, variable));

    if (!resolution) {
      return;
    }

    if (resolutions[resolution]) {
      resolutions[resolution].push(variable);
    } else {
      resolutions[resolution] = [variable];
    }
  });

  return resolutions;
};

export const getVariablesBasedOnResolution = (
  variables: KeyValue<string>,
  resolution: Resolution
) => {
  const result: KeyValue<string> = {};

  if (!variables || !resolution) {
    return result;
  }

  Object.keys(variables).forEach((key) => {
    result[key] = getEnumValue(variablesByResolution[resolution], key);
  });

  return result;
};

export const getVariablesStatusWarning = (variableStatus: KeyValue<string>, key: string) => {
  if (!variableStatus) {
    return '';
  }
  let newClassName = '';
  if (
    variableStatus[key] === VARIABLES_STATUS.tooHigh ||
    variableStatus[key] === VARIABLES_STATUS.tooLow
  ) {
    newClassName = 'line-graph-warning';
  }
  return newClassName;
};

export const getVariablesDaysWeekResolution = (variables: KeyValue<string>) => {
  let varWeek: string[] = [],
    varDay: string[] = [];
  Object.entries(variables).forEach(([key, value]) => {
    type keyResolution = keyof typeof VARIABLE_MAX_RESOLUTION;
    if (VARIABLE_MAX_RESOLUTION[key as keyResolution] === RESOLUTION._1_week) {
      varWeek.push(value);
    } else {
      varDay.push(value);
    }
  });
  return {
    varWeek,
    varDay,
  };
};

export const hasExactYInterval = (variable: string): boolean => {
  return EXACT_Y_INTERVAL_VARIABLES.includes(variable);
};

export const getVariableXInterval = (data: any, variable: string) => {
  if (!data || !data[variable]) return { min: 0, max: 0 };
  const xValues = data[variable].map((item: any) => item.timestamp);
  return [Math.min(...xValues), Math.max(...xValues)];
};

export const hasBenchmarkResolutionError = (from: number, to: number, resolution: Resolution) => {
  const start = dayjs.unix(from).utc(true).startOf('day');
  const end = dayjs.unix(to).utc(true).endOf('day');
  const timeDifferenceBetweenSelectedDatesInWeeks = end.diff(start, 'weeks');

  if (resolution === Resolution.MIN_5 && timeDifferenceBetweenSelectedDatesInWeeks > 1) {
    return translate('resolution error 5 min benchmark');
  }
  return false;
};

export const handleResolutionError = (from: number, to: number, resolution: Resolution) => {
  const start = dayjs.unix(from).utc(true).startOf('day');
  const end = dayjs.unix(to).utc(true).endOf('day');
  const timeDifferenceBetweenSelectedDatesInWeeks = end.diff(start, 'weeks');
  const timeDifferenceBetweenSelectedDatesInDays = end.diff(start, 'days');

  if (resolution === Resolution.MIN_5 && timeDifferenceBetweenSelectedDatesInWeeks > 2) {
    return translate('resolution error 5 min analyze');
  } else if (resolution === Resolution.HOUR_1 && timeDifferenceBetweenSelectedDatesInWeeks > 26) {
    return translate('resolution error 1 hour');
  } else if (
    timeDifferenceBetweenSelectedDatesInDays < 1 &&
    resolution !== Resolution.MIN_5 &&
    resolution !== Resolution.HOUR_1
  ) {
    return 'The resolution should be set to 5 minutes or 1 hour intervals';
  }
  return false;
};
