import React, { forwardRef, PropsWithChildren } from 'react';

interface OverlayProps {
  width: number;
  height: number;
  transform?: string;
  dataTooltipId?: string;
}

export const Overlay = forwardRef<any, PropsWithChildren<OverlayProps>>(
  ({ width, height, children, transform, dataTooltipId }, ref) => (
    <g>
      {children}
      <rect
        ref={ref}
        width={Math.max(width, 0)}
        height={Math.max(height, 0)}
        opacity={0}
        transform={transform}
        data-tooltip-id={dataTooltipId}
      />
    </g>
  )
);
