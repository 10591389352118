import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { getUniqueId } from 'utils/getUniqueId';

import type { RootState } from 'redux/store';

import type {
  AcceleratedRipening,
  ECIrrigation,
  TemperatureStrategy,
} from 'interfaces/custom-settings';
export interface CustomPeriod {
  periodId: string;
  periodTitle: string;
  datePickerTitle: string;
  numberInputHeader: string;
  startDate: number;
  value: number;
  isValid: boolean;
  isInRange: boolean;
}

interface CO2 {
  CO2max: number;
  CO2min: number;
}

interface phaseTempStrategiesNewDates {
  temp_air: number;
  date: string;
  touched: boolean;
}
interface customPlanState {
  pruning?: CustomPeriod[];
  stemDensityPeriod?: CustomPeriod[];
  CO2?: CO2;
  sowing?: number;
  AL_enabled?: boolean;
  AL_max_capacity?: number;
  AL_percentage_LED?: number;
  AL_radiation_threshold?: number;
  phase_temp_strategies?: TemperatureStrategy[];
  phase_temp_strategies_new_dates?: phaseTempStrategiesNewDates[];
  EC?: ECIrrigation;
  lightsTouched?: boolean;
  temperatureTouched?: boolean;
  accelerated_ripening?: AcceleratedRipening;
  variety?: any;
  plantingDate?: any;
  harvestDate?: any;
  headRemovalDate?: any;
  location?: any;
}

const initialState: {
  customPlanState: customPlanState;
  creationCustomSettings: customPlanState;
  editCustomSettings: customPlanState;
} = { customPlanState: {}, creationCustomSettings: {}, editCustomSettings: {} };

export const customPlanSlice = createSlice({
  name: 'customPlan',
  initialState,
  reducers: {
    setCustomPlan: (state, { payload }) => {
      state.customPlanState = { ...state.customPlanState, ...payload };
    },
    setCreationCustomSettings: (state) => {
      state.creationCustomSettings = state.customPlanState;
    },
    setEditCustomSettings: (state) => {
      state.editCustomSettings = state.customPlanState;
    },
    resetCustomPlan: (state, { payload }) => {
      if (Object.keys(payload).length === 0) {
        return {};
      }
      let initialState: any;
      let customPlanSettings: any = { ...payload.customPlanSettings };
      let customSettings: any = payload.customSettings;

      const getDefaultValues = (payload: any) => {
        return {
          pruning: [
            {
              periodId: getUniqueId(),
              periodTitle: 'Pruning',
              datePickerTitle: 'Initial week',
              numberInputHeader: 'Fruit/truss',
              startDate: payload.plantingDate,
              value: customSettings.pruning_events[0].value,
              isValid: true,
              isInRange: true,
            },
            {
              periodId: getUniqueId(),
              periodTitle: 'Final period',
              datePickerTitle: 'Final week',
              numberInputHeader: 'Fruit/truss',
              startDate: payload.harvestDate,
              value: customSettings.pruning_events[0].value,
              isValid: true,
              isInRange: true,
            },
          ],
          stemDensityPeriod: [
            {
              periodId: getUniqueId(),
              periodTitle: 'Stem density',
              datePickerTitle: 'First week',
              numberInputHeader: 'Stems/m2',
              startDate: payload.plantingDate,
              value: customSettings.stem_density_events[0].value,
              isValid: true,
              isInRange: true,
            },
            {
              periodId: getUniqueId(),
              periodTitle: 'Final period',
              datePickerTitle: 'Final week',
              numberInputHeader: 'Stems/m2',
              startDate: payload.harvestDate,
              value: customSettings.stem_density_events[0].value,
              isValid: true,
              isInRange: true,
            },
          ],
          CO2: {
            CO2min: customSettings.CO2_min,
            CO2max: customSettings.CO2_max,
          },
          AL_max_capacity: customSettings.AL_max_capacity,
          AL_percentage_LED: customSettings.AL_percentage_LED,
          AL_radiation_threshold: customSettings.AL_radiation_threshold,
          AL_enabled: customSettings.AL_enabled,

          EC: {
            min: customSettings.EC.min,
            max: customSettings.EC.max,
          },

          ...(customSettings.accelerated_ripening && {
            accelerated_ripening: {
              enabled: customSettings.accelerated_ripening.enabled,
              starting_from: dayjs(customSettings.accelerated_ripening.starting_from).unix(),
            },
          }),
          phase_temp_strategies: customSettings.phase_temp_strategies,
          phase_temp_strategies_new_dates: [],
          lightsTouched: false,
          temperatureTouched: false,
        };
      };

      const getInitialCustomValues = () => {
        let initialPruningEvents: any = [];
        let initialStemDensityEvents: any = [];

        customPlanSettings.pruning_events.forEach((event: any, index: number) => {
          if (index === 0) {
            initialPruningEvents.push({
              periodId: getUniqueId(),
              periodTitle: 'Pruning',
              datePickerTitle: 'Initial week',
              numberInputHeader: 'Fruit/truss',
              startDate: dayjs(event.timestamp).unix(),
              value: event.value,
              isValid: true,
              isInRange: true,
              disabled: true,
            });
            if (customPlanSettings.pruning_events.length === 1) {
              initialPruningEvents.push({
                periodId: getUniqueId(),
                periodTitle: 'Final period',
                datePickerTitle: 'Final week',
                numberInputHeader: 'Fruit/truss',
                startDate: payload.harvestDate,
                value: event.value,
                isValid: true,
                isInRange: true,
                disabled: true,
              });
            }
            return;
          }
          initialPruningEvents.push({
            periodId: getUniqueId(),
            periodTitle: 'Next period',
            datePickerTitle: 'Select the week',
            numberInputHeader: 'Fruit/truss',
            startDate: dayjs(event.timestamp).unix(),
            value: event.value,
            isValid: true,
            disabled: false,
            isInRange: true,
          });
          if (index === customPlanSettings.pruning_events.length - 1) {
            initialPruningEvents.push({
              periodId: getUniqueId(),
              periodTitle: 'Final period',
              datePickerTitle: 'Final week',
              numberInputHeader: 'Fruit/truss',
              startDate: payload.harvestDate,
              value: event.value,
              isValid: true,
              isInRange: true,
              disabled: true,
            });
          }
        });
        customPlanSettings.stem_density_events.forEach((event: any, index: number) => {
          if (index === 0) {
            initialStemDensityEvents.push({
              periodId: getUniqueId(),
              periodTitle: 'Stem density',
              datePickerTitle: 'First week',
              numberInputHeader: 'Stems/m2',
              startDate: dayjs(event.timestamp).unix(),
              value: event.value,
              isValid: true,
              isInRange: true,
            });

            if (customPlanSettings.stem_density_events.length === 1) {
              initialStemDensityEvents.push({
                periodId: getUniqueId(),
                periodTitle: 'Final period',
                datePickerTitle: 'Final week',
                numberInputHeader: 'Stems/m2',
                startDate: payload.harvestDate,
                value: event.value,
                isValid: true,
                isInRange: true,
              });
            }
            return;
          }
          initialStemDensityEvents.push({
            periodId: getUniqueId(),
            periodTitle: 'Next period',
            datePickerTitle: 'Select the week',
            numberInputHeader: 'Stems/m2',
            startDate: dayjs(event.timestamp).unix(),
            value: event.value,
            isValid: true,
            isInRange: true,
          });
          if (index === customPlanSettings.stem_density_events.length - 1) {
            initialStemDensityEvents.push({
              periodId: getUniqueId(),
              periodTitle: 'Final period',
              datePickerTitle: 'Final week',
              numberInputHeader: 'Stems/m2',
              startDate: payload.harvestDate,
              value: event.value,
              isValid: true,
              isInRange: true,
            });
          }
        });

        return {
          pruning: initialPruningEvents,
          stemDensityPeriod: initialStemDensityEvents,
          CO2: {
            CO2min: customPlanSettings.CO2_min,
            CO2max: customPlanSettings.CO2_max,
          },
          AL_max_capacity: customPlanSettings.AL_max_capacity,
          AL_percentage_LED: customPlanSettings.AL_percentage_LED,
          AL_radiation_threshold: customPlanSettings.AL_radiation_threshold,
          AL_enabled: customPlanSettings.AL_enabled,

          EC: {
            min: customPlanSettings.EC.min,
            max: customPlanSettings.EC.max,
          },

          ...(customPlanSettings.accelerated_ripening && {
            accelerated_ripening: {
              enabled: customPlanSettings.accelerated_ripening.enabled,
              starting_from: dayjs(customPlanSettings.accelerated_ripening.starting_from).unix(),
            },
          }),
          phase_temp_strategies: customPlanSettings.phase_temp_strategies
            ? customPlanSettings.phase_temp_strategies
            : customSettings.phase_temp_strategies,
          phase_temp_strategies_new_dates: [],
          lightsTouched: false,
          temperatureTouched: false,
        };
      };

      if (Object.keys(customPlanSettings).length === 0) {
        initialState = { customPlanState: getDefaultValues(payload) };
      } else {
        initialState = { customPlanState: getInitialCustomValues() };
      }
      return initialState;
    },
  },
});

export const { setCustomPlan, resetCustomPlan, setCreationCustomSettings, setEditCustomSettings } =
  customPlanSlice.actions;

export const customPlanSelector = (state: RootState) => state.customPlan;

export default customPlanSlice.reducer;

export type StateKey = keyof typeof initialState;
