import dayjs from 'dayjs';
import { Range } from 'interfaces/range';
import { useCallback } from 'react';
import { CropseasonNotification } from 'interfaces/cropseason-notification';
import { isInRange } from 'utils/time.util';

export interface GroupedNotification {
  [groupTimestamp: number]: CropseasonNotification[];
}

export default function useGraphNotifications(
  notificationsData: CropseasonNotification[] | undefined,
  width: number,
  currentTimeRange: Range
) {
  const notificationIconWidth = 20;

  const getGroupedNotifications = useCallback((): GroupedNotification => {
    let svgWidth = width ? width : 0;
    let timeForOnePixel = (currentTimeRange.max - currentTimeRange.min) / svgWidth;
    let iconSizeTime = timeForOnePixel * notificationIconWidth;

    let groupedNotifications: GroupedNotification = {};

    if (!notificationsData) return groupedNotifications;

    notificationsData.forEach((notification) => {
      let timestamp = dayjs(notification.local_datetime).unix();
      let rangeTimeIcon = {
        min: timestamp - iconSizeTime,
        max: timestamp + iconSizeTime,
      };
      let merged = false;

      Object.keys(groupedNotifications).forEach((groupTimestamp) => {
        let timestamp = Number(groupTimestamp);
        if (isInRange(Number(timestamp), rangeTimeIcon)) {
          if (groupedNotifications[timestamp]) {
            groupedNotifications[timestamp].push(notification);
            merged = true;
          }
        }
      });
      if (!merged && isInRange(timestamp, currentTimeRange)) {
        groupedNotifications[timestamp] = [notification];
      }
    });

    return groupedNotifications;
  }, [notificationsData, width, currentTimeRange]);

  const renderNumber = useCallback((numberOfNotifications: number) => {
    if (numberOfNotifications < 10) {
      return (
        <>
          <circle cx='20' cy='5' r='6' fill='white'></circle>
          <text x='18' y='8' fill='black' fontSize='8px'>
            {numberOfNotifications}
          </text>
        </>
      );
    } else {
      let numberOFDigits = numberOfNotifications.toString().length;
      let dWidth = numberOFDigits * 3.5;
      let dLeft = 17 + numberOFDigits * 0.6;
      return (
        <>
          <path
            d={`M${dLeft},-1 h${dWidth} a5,5 0 0 1 5,5 v2 a5,5 0 0 1 -5,5 h-${dWidth} a5,5 0 0 1 -5,-5 v-2 a5,5 0 0 1 5,-5 z1`}
            fill='white'
          />
          <text x='18' y='8' fill='black' font-size='8px'>
            {numberOfNotifications}
          </text>
        </>
      );
    }
  }, []);

  return { getGroupedNotifications, renderNumber };
}
