import { TOAST_PROMISE } from 'config/constants';
import { CropSeason } from 'interfaces/crop-season';
import { useCallback } from 'react';

import { useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import {
  useUpdateUserSettingsMutation,
  useGetUserSettingsQuery,
  useRemoveDefaultCropSeasonMutation,
} from 'services/userAccount.service';
import { dispatchPromiseToast } from 'utils/toast.util';
import useTranslate from './useTranslate';

type DefaultCropSeasons = { [account_id: number]: { default_cropseason_id: number } };

interface useDefaultCropSeasonResult {
  handleDefaultCropseason: (option: CropSeason) => void;
  handleRemoveDefaultCropseason: () => void;
  isValueDefault: (option: CropSeason) => boolean;
  getDefaultCropSeasonId: () => number | null;
}

const useDefaultCropSeason = (): useDefaultCropSeasonResult => {
  const accountId = useAppSelector(accountIdSelector).accountId;
  const [addDefaultCropseason] = useUpdateUserSettingsMutation();
  const [removeDefaultCropseason] = useRemoveDefaultCropSeasonMutation();
  const { data: defaultCropSeason } = useGetUserSettingsQuery();
  const translate = useTranslate();

  const handleDefaultCropseason = (option: CropSeason): void => {
    let allDefaultCropSeasons: DefaultCropSeasons = { ...defaultCropSeason.accounts };
    allDefaultCropSeasons[accountId] = { default_cropseason_id: option.id };
    const defaultCropseasonPromise = addDefaultCropseason({
      data: {
        ...defaultCropSeason,
        accounts: { ...allDefaultCropSeasons },
      },
    });

    dispatchPromiseToast({
      type: TOAST_PROMISE,
      promise: defaultCropseasonPromise,
      messageLoading: translate('Toast - Default Cropseason - set - loading'),
      messageError: translate("Toast - Default Cropseason - set - error"),
      messageSuccess: translate('Toast - Default Cropseason - set - success')
    });
  };

  const handleRemoveDefaultCropseason = (): void => {
    let allDefaultCropSeasons: DefaultCropSeasons = { ...defaultCropSeason.accounts };
    delete allDefaultCropSeasons[accountId];
    const removeCropseasonPromise = removeDefaultCropseason({
      data: {
        accounts: { ...allDefaultCropSeasons },
      },
    });

    dispatchPromiseToast({
      type: TOAST_PROMISE,
      promise: removeCropseasonPromise,
      messageLoading: translate('Toast - Default Cropseason - remove - loading'),
      messageError: translate("Toast - Default Cropseason - remove - error"),
      messageSuccess: translate("Toast - Default Cropseason - remove - success"),
    });
  };

  const isValueDefault = (option: CropSeason): boolean => {
    return (
      defaultCropSeason &&
      option.id === defaultCropSeason.accounts?.[accountId]?.default_cropseason_id
    );
  };

  const getDefaultCropSeasonId = useCallback((): number | null => {
    return defaultCropSeason?.accounts?.[accountId]?.default_cropseason_id;
  }, [defaultCropSeason, accountId]);

  return {
    handleDefaultCropseason,
    handleRemoveDefaultCropseason,
    isValueDefault,
    getDefaultCropSeasonId,
  };
};
export default useDefaultCropSeason;
