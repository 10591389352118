import { ReactComponent as ApiIcon } from '../styles/icons/variable_api.svg';
import { ReactComponent as CalculationIcon } from '../styles/icons/variable_calculation.svg';
import { ReactComponent as ClimateComputerIcon } from '../styles/icons/variable_climate_computer.svg';
import { ReactComponent as RegistrationsIcon } from '../styles/icons/variable_manual_registrations.svg';
import { ReactComponent as WeatherIcon } from '../styles/icons/variable_weather.svg';
import { ReactComponent as WirelessIcon } from '../styles/icons/variable_wireless.svg';
import { KeyValue } from 'interfaces/keyvalue';
import { ReactElement } from 'react';

export const VARIABLES_ICONS: KeyValue<ReactElement> = {
  Calculation: <CalculationIcon></CalculationIcon>,
  Climate: <ClimateComputerIcon></ClimateComputerIcon>,
  Api: <ApiIcon></ApiIcon>,
  Weather: <WeatherIcon></WeatherIcon>,
  Registration: <RegistrationsIcon></RegistrationsIcon>,
  Sensor: <WirelessIcon></WirelessIcon>,
};
