export const getNumberTicks =
  (step?: number, treshold?: number) =>
  (domainInput: [number, number], input: number): number[] => {
    const start = domainInput[0];
    const end = domainInput[1];
    const diff = (end - start) / input;

    let constructedArray = [];
    constructedArray.push(start);
    for (let i = 1; i < input + 1; i++) {
      let currentItem;
      if (step) {
        currentItem = step * i;
      } else {
        currentItem = start + i * diff;
      }

      if (currentItem < end - (treshold || 0)) {
        constructedArray.push(currentItem);
      }
    }

    constructedArray.push(end);

    return Array.from(
      new Set(constructedArray.filter((element: any) => element === 0 || Number(element)))
    );
  };
