import './PreviewGraph.scss';

import Line from 'components/GraphElements/Line/Line';
import MultilineChart from 'components/GraphElements/MultilineChart/MultilineChart';
import TitleTooltip from 'components/GraphElements/TitleTooltip/TitleTooltip';
import {
  EDIT_PLAN_LEGENDS,
  PLAN_VARIABLES_OLD_NAME_ID_MAPPING,
  RESOLUTION,
} from 'config/constants';
import { useGetUnitsForEditPlan } from 'hooks/useGetUnitsForEditPlan';
import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { getNumberScale } from 'utils/d3-utils/getScale';
import { getNumberTicks } from 'utils/d3-utils/getTicks';
import { weekFromTimestamp, weekFromTimestampNoLabel } from 'utils/d3-utils/tooltip-formatter';
import { getVariableXInterval } from 'utils/variables.util';

interface PreviewGraphProps {
  timeseriesData: any;
  header: ReactNode;
}

const PreviewGraph: React.FC<PreviewGraphProps> = ({ timeseriesData, header }) => {
  let { planId: id } = useParams();
  const getUnit = useGetUnitsForEditPlan;

  return (
    <>
      <div className='preview-graph-header'>{header}</div>
      <div className='plan-graphs-container'>
        <div className='preview-graph-container preview-input-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: 'RADJCM',
                  color: '#00FFD1',
                  data: timeseriesData?.['RADJCM'],
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'RADJCM'),
                },
                {
                  keyName: 'OUTEMP',
                  color: '#CB36FF',
                  data: timeseriesData?.['OUTEMP'],
                  axis: 'right',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'OUTEMP'),
                },
                {
                  keyName: 'GHTEMP',
                  color: '#CB36FF',
                  data: timeseriesData?.['GHTEMP'],
                  axis: 'right',
                  strokeWidth: 2,
                  stroke: '3 4',
                  unit: getUnit(Number(id), 'GHTEMP'),
                },
              ]}
              title='Climate'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.CLIMATE}
              YInterval={[timeseriesData?.ranges['RADJCM']]}
              XInterval={getVariableXInterval(timeseriesData, 'RADJCM')}
              rightAxisYInterval={[timeseriesData?.ranges.OUTEMP, timeseriesData?.ranges.GHTEMP]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              titleTooltip={<TitleTooltip text='Input' />}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
            >
              <Line data={timeseriesData?.['RADJCM']} property={'RADJCM'} color='#00FFD1' />
              <Line
                data={timeseriesData?.['OUTEMP']}
                property={'OUTEMP'}
                axis={'right'}
                color='#CB36FF'
              />
              <Line
                data={timeseriesData?.['GHTEMP']}
                property={'GHTEMP'}
                axis={'right'}
                color='#CB36FF'
                stroke='3 4'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='preview-graph-container preview-input-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: 'GHCO2C',
                  color: '#fff',
                  data: timeseriesData?.['GHCO2C'],
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'GHCO2C'),
                },
              ]}
              title='CO2'
              xAxisProperty='timestamp'
              YInterval={[timeseriesData?.ranges['GHCO2C']]}
              XInterval={getVariableXInterval(timeseriesData, 'GHCO2C')}
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legend={EDIT_PLAN_LEGENDS.CO2}
              legendFontSize={'12px'}
              titleTooltip={<TitleTooltip text='Input' />}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
            >
              <Line data={timeseriesData?.['GHCO2C']} property={'GHCO2C'} color='#fff' />
            </MultilineChart>
          </div>
        </div>
        <div className='preview-graph-container preview-input-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: 'stem.density.setting',
                  color: '#fff',
                  data: timeseriesData?.['stem.density.setting'],
                  strokeWidth: 2,
                  axis: 'left',
                  unit: getUnit(Number(id), 'stem.density.setting'),
                },
                {
                  keyName: 'pruning',
                  color: '#CB36FF',
                  data: timeseriesData?.pruning,
                  strokeWidth: 2,
                  axis: 'left',
                  unit: getUnit(Number(id), 'pruning'),
                },
                {
                  keyName: PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI,
                  color: '#36DBFF',
                  data: timeseriesData?.PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI,
                  strokeWidth: 2,
                  axis: 'left',
                  unit: getUnit(Number(id), PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI),
                },
              ]}
              title='Plant Status'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.PLANT_STATUS}
              YInterval={[
                timeseriesData?.ranges['stem.density.setting'],
                timeseriesData?.ranges['pruning'],
                timeseriesData?.ranges[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI],
              ]}
              XInterval={getVariableXInterval(timeseriesData, 'stem.density.setting')}
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              titleTooltip={<TitleTooltip text='Input' />}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
            >
              <Line
                data={timeseriesData?.['stem.density.setting']}
                property={'stem.density.setting'}
                color='#fff'
              />
              <Line data={timeseriesData?.['pruning']} property={'pruning'} color='#CB36FF' />
              <Line
                data={timeseriesData?.[PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI]}
                property={PLAN_VARIABLES_OLD_NAME_ID_MAPPING.LAI}
                color='#36DBFF'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='preview-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: 'harvested.fruits.m2.wk',
                  color: '#00FFD1',
                  data: timeseriesData?.['harvested.fruits.m2.wk'],
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'harvested.fruits.m2.wk'),
                },
                {
                  keyName: 'harvestMaturity',
                  color: '#CB36FF',
                  data: timeseriesData?.harvestMaturity,
                  axis: 'right',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'harvestMaturity'),
                },
                {
                  keyName: 'setting.fruits.m2.wk',
                  color: '#CB36FF',
                  data: timeseriesData?.['setting.fruits.m2.wk'],
                  axis: 'right',
                  strokeWidth: 2,
                  stroke: '3 4',
                  unit: getUnit(Number(id), 'setting.fruits.m2.wk'),
                },
              ]}
              title='Maturity'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.MATURITY}
              YInterval={[timeseriesData?.ranges['harvested.fruits.m2.wk']]}
              XInterval={getVariableXInterval(timeseriesData, 'harvested.fruits.m2.wk')}
              rightAxisYInterval={[
                timeseriesData?.ranges.harvestMaturity,
                timeseriesData?.ranges?.['setting.fruits.m2.wk'],
              ]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
            >
              <Line
                data={timeseriesData?.['harvested.fruits.m2.wk']}
                property={'harvested.fruits.m2.wk'}
                color='#00FFD1'
              />
              <Line
                data={timeseriesData?.['harvestMaturity']}
                property={'harvestMaturity'}
                axis={'right'}
                color='#CB36FF'
              />
              <Line
                data={timeseriesData?.['setting.fruits.m2.wk']}
                property={'setting.fruits.m2.wk'}
                axis={'right'}
                color='#CB36FF'
                stroke='3 4'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='preview-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: 'stem.diam',
                  color: '#00FFD1',
                  data: timeseriesData?.['stem.diam'],
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'stem.diam'),
                },
                {
                  keyName: 'plantload.fruits.m2.calc',
                  color: '#CB36FF',
                  data: timeseriesData?.['plantload.fruits.m2.calc'],
                  axis: 'right',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'plantload.fruits.m2.calc'),
                },
              ]}
              title='Plant load'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.PLANT_LOAD}
              YInterval={[timeseriesData?.ranges['stem.diam']]}
              XInterval={getVariableXInterval(timeseriesData, 'stem.diam')}
              rightAxisYInterval={[timeseriesData?.ranges?.['plantload.fruits.m2.calc']]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
            >
              <Line data={timeseriesData?.['stem.diam']} property={'stem.diam'} color='#00FFD1' />
              <Line
                data={timeseriesData?.['plantload.fruits.m2.calc']}
                property={'plantload.fruits.m2.calc'}
                axis={'right'}
                color='#CB36FF'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='preview-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: 'growthRate',
                  color: '#00FFD1',
                  data: timeseriesData?.growthRate,
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'growthRate'),
                },
                {
                  keyName: 'stem load cell raw',
                  color: '#CB36FF',
                  data: timeseriesData?.['stem load cell raw'],
                  axis: 'right',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'stem load cell raw'),
                },
              ]}
              title='Fresh Weight Gain'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.FRESH_WEIGHT_GAIN}
              YInterval={[timeseriesData?.ranges['growthRate']]}
              XInterval={getVariableXInterval(timeseriesData, 'growthRate')}
              rightAxisYInterval={[timeseriesData?.ranges?.['stem load cell raw']]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
            >
              <Line data={timeseriesData?.['growthRate']} property={'growthRate'} color='#00FFD1' />
              <Line
                data={timeseriesData?.['stem load cell raw']}
                property={'stem load cell raw'}
                axis={'right'}
                color='#CB36FF'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='preview-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: 'yield',
                  color: '#00FFD1',
                  data: timeseriesData?.yield,
                  strokeWidth: 2,
                  axis: 'left',
                  unit: getUnit(Number(id), 'yield'),
                },
                {
                  axis: 'right',
                  keyName: 'yield.cu',
                  color: '#CB36FF',
                  data: timeseriesData?.['yield.cu'],
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'yield.cu'),
                },
              ]}
              title='Weekly yield'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.WEEKLY_YIELD}
              YInterval={[timeseriesData?.ranges['yield']]}
              XInterval={getVariableXInterval(timeseriesData, 'yield')}
              rightAxisYInterval={[timeseriesData?.ranges?.['yield.cu']]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
            >
              <Line data={timeseriesData?.['yield']} property={'yield'} color='#00FFD1' />
              <Line
                data={timeseriesData?.['yield.cu']}
                property={'yield.cu'}
                axis={'right'}
                color='#CB36FF'
              />
            </MultilineChart>
          </div>
        </div>
        <div className='preview-graph-container'>
          <div className='multi-line'>
            <MultilineChart
              dataToDisplay={[
                {
                  keyName: 'brix',
                  color: '#00FFD1',
                  data: timeseriesData?.brix,
                  axis: 'left',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'brix'),
                },
                {
                  keyName: 'afw',
                  color: '#CB36FF',
                  data: timeseriesData?.afw,
                  axis: 'right',
                  strokeWidth: 2,
                  unit: getUnit(Number(id), 'afw'),
                },
              ]}
              title='Brix'
              xAxisProperty='timestamp'
              legend={EDIT_PLAN_LEGENDS.BRIX}
              YInterval={[timeseriesData?.ranges['brix']]}
              XInterval={getVariableXInterval(timeseriesData, 'brix')}
              rightAxisYInterval={[timeseriesData?.ranges?.['afw']]}
              leftAxisColor='#00FFD1'
              rightAxisColor='#CB36FF'
              isBiAxial
              getTicks={getNumberTicks()}
              customScale={getNumberScale}
              legendFontSize={'12px'}
              axisDecimals={0}
              tooltipDecimals={0}
              resolution={RESOLUTION._1_week}
              customFormatXTick={weekFromTimestampNoLabel}
              customFormatter={weekFromTimestamp}
            >
              <Line data={timeseriesData?.['brix']} property={'brix'} color='#00FFD1' />
              <Line
                data={timeseriesData?.['afw']}
                property={'afw'}
                axis={'right'}
                color='#CB36FF'
              />
            </MultilineChart>
          </div>
        </div>
      </div>
    </>
  );
};
export default PreviewGraph;
