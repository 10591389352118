import React, { useEffect, useRef } from 'react';

import styles from './PlanPreviewStep.module.scss';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { accountIdSelector } from 'redux/slices/accountId.slice';
import { getDataForPlan } from 'utils/getPlanCreationParameters';
import { useCreatePlanMutation, useUpdatePlanMutation } from 'services/plans.service';
import { planCreationSelector, reset, setOption } from 'redux/slices/planCreationSlice';
import dayjs from 'dayjs';

import { ReactComponent as Eye } from 'styles/icons/eye.svg';
import { translate } from 'utils/translations.util';
import { languageSelector } from 'redux/slices/language.slice';
import { useNavigate } from 'react-router-dom';
import {
  MAX_LENGTH_PLAN_NAME,
  MIN_LENGTH_PLAN_NAME,
  TOAST_ERROR,
  TOAST_PROMISE,
} from 'config/constants';
import { ModalService } from 'services/modal.service';
import SavePlanAsContent from 'views/EditPlans/EditPlanSidebar/SavePlanAsContent/SavePlanAsContent';
import { SidebarFileFolderNames } from 'views/EditPlans/EditPlanSidebar/EditPlanSidebar';
import { checkFileName } from 'utils/validation.util';
import { setStep } from 'redux/slices/stepperSlice';
import { strategiesActions } from 'redux/slices/planStrategies.slice';
import { dispatchPromiseToast, dispatchToast } from 'utils/toast.util';
import { setCreationCustomSettings } from 'redux/slices/customPlan.slice';
import isEqual from 'lodash.isequal';
import { track } from 'utils/analtycs';

interface PlanPreviewStepProps {
  lastValidStep: boolean;
}

const PlanPreviewStep: React.FC<PlanPreviewStepProps> = ({ lastValidStep }) => {
  const dispatch = useAppDispatch();
  const [createPlan, { isUninitialized }] = useCreatePlanMutation();
  const {
    variety,
    location,
    linkedCropseason,
    plantingDate,
    openSaveModal,
    previewPlanId,
    headRemovalDate,
    harvestDate,
    sowingDate,
    firstSettingDate,
  } = useAppSelector(planCreationSelector);
  const { selectedLanguage } = useAppSelector(languageSelector);
  const [updatePlan] = useUpdatePlanMutation();
  let navigate = useNavigate();
  const { accountId } = useAppSelector(accountIdSelector);
  const toastPlanCreationPromissesRef = useRef<Promise<any>[]>([]);

  const flag = useRef(false);

  const handleSavePreviewPlan = () => {
    dispatch(setCreationCustomSettings());
    const createPreviewPlanData: any = {
      name: 'preview',
      path: '/drafts',
      account_id: accountId,
      parameters: getDataForPlan('creationCustomSettings'),
    };
    if (!!linkedCropseason?.id) {
      createPreviewPlanData.cropseason_id = linkedCropseason.id;
    }

    const planCreationPromisse = createPlan(createPreviewPlanData);
    toastPlanCreationPromissesRef.current.push(planCreationPromisse);
    return planCreationPromisse;
  };

  const handleUpdatePreviewPlan = () => {
    dispatch(setCreationCustomSettings());
    const updatePreviewPlanData: any = {
      id: previewPlanId!,
      data: {
        parameters: getDataForPlan('creationCustomSettings'),
      },
    };
    if (!!linkedCropseason?.id) {
      updatePreviewPlanData.data.cropseason_id = linkedCropseason.id;
    }
    const planUpdatePromisse = updatePlan(updatePreviewPlanData);
    toastPlanCreationPromissesRef.current.push(planUpdatePromisse);
    return planUpdatePromisse;
  };

  const handleOpenPreview = () => {
    navigate(`../plan/preview/${previewPlanId}`, {
      state: {
        id: previewPlanId,
      },
    });
  };

  useEffect(() => {
    if (lastValidStep) {
      if (!previewPlanId && isUninitialized && !flag.current) {
        flag.current = true;
        (async function savePreviewPlan() {
          const createdPreviewPlan = await handleSavePreviewPlan().unwrap();
          const previewPlanId = createdPreviewPlan?.result?.new_plan?.id;

          if (previewPlanId) {
            dispatch(setOption({ previewPlanId: previewPlanId }));
            dispatch(setCreationCustomSettings());
          }
        })();
      } else if (
        previewPlanId &&
        !isEqual(getDataForPlan('customPlanState'), getDataForPlan('creationCustomSettings'))
      ) {
        (async function updatePreviewPlan() {
          await handleUpdatePreviewPlan();
        })();
      }
    }
  }, [previewPlanId, flag]);

  useEffect(() => {
    if (openSaveModal) {
      let names: SidebarFileFolderNames = { fileNameField: '', folderNameField: '' };
      const validateFunction = () => {
        return !!(
          !checkFileName(names.fileNameField, MIN_LENGTH_PLAN_NAME, MAX_LENGTH_PLAN_NAME) &&
          names.folderNameField
        );
      };
      ModalService.open({
        title: translate('Save Plan pop up window', selectedLanguage),
        saveText: translate('Save Plan window: Save', selectedLanguage),
        cancelText: translate('Save Plan window: Cancel', selectedLanguage),
        width: '495px',
        validateFunction: validateFunction,
        content: <SavePlanAsContent names={names} />,
        closeAction: async (isConfirmed) => {
          if (isConfirmed) {
            track('plan create save', {
              previewPlanId,
              accountId,
              planName: names.fileNameField,
              planPath: names.folderNameField,
            });

            const updatePlanPromise = updatePlan({
              id: previewPlanId!,
              data: {
                name: names.fileNameField,
                path: '/' + names.folderNameField,
              },
            });
            toastPlanCreationPromissesRef.current.push(updatePlanPromise);

            let planCreationSuccessfulToast = true;

            Promise.all(toastPlanCreationPromissesRef.current).then((results) => {
              results.forEach((promise) => {
                if (!promise || !promise?.data || promise.error) {
                  planCreationSuccessfulToast = false;
                }
              });

              dispatch(setOption({ openSaveModal: false }));

              if (planCreationSuccessfulToast) {
                dispatchPromiseToast({
                  type: TOAST_PROMISE,
                  promise: Promise.all(toastPlanCreationPromissesRef.current),
                  messageLoading: translate(
                    'Toast - PlanCreation - loading - message',
                    selectedLanguage
                  ),
                  messageError: translate(
                    'Toast - PlanCreation - error - message',
                    selectedLanguage
                  ),
                  messageSuccess: translate(
                    'Toast - PlanCreation - success - message',
                    selectedLanguage
                  ),
                });
                dispatch(reset());
                dispatch(setStep({ stateName: 'planCreationStep', stepNumber: 0 }));
                dispatch(strategiesActions.reset());
                navigate('/plan/edit/' + previewPlanId, {
                  state: {
                    id: previewPlanId,
                  },
                });
              } else {
                dispatchToast({
                  type: TOAST_ERROR,
                  message: translate('Toast - PlanCreation - error - message', selectedLanguage),
                });
              }
              names = { fileNameField: '', folderNameField: '' };
              dispatch(setOption({ fileName: '', folderName: '' }));
            });
          } else {
            dispatch(setOption({ openSaveModal: false }));
            ModalService.close();
          }
        },
      });
    }
  }, [openSaveModal]);

  const formatDate = (input: number) => {
    return dayjs.unix(input).format('DD/MM/YYYY');
  };

  return (
    <div className={styles.container}>
      <div className={styles.title + ' step-title'}>
        {translate('Overview Plan page title: Overview of your plan', selectedLanguage)}
      </div>
      <div className={styles.content}>
        <div className={styles.details}>
          <div className={styles['column']}>
            <div className={styles['details-title']}>
              {translate('Plan view - oversight created plan', selectedLanguage)}
              <span className={styles['details-value']}>{variety?.name}</span>
            </div>
            <div className={styles['details-title']}>
              {translate('Plan view - preview plan - location', selectedLanguage)}
              <span className={styles['details-value']}>{location?.name}</span>
            </div>
            {!!linkedCropseason?.id && (
              <div className={styles['details-title']}>
                {translate('Create Plan - overview Plan - CropSeason', selectedLanguage)}{' '}
                <span className={styles['details-value']}>{linkedCropseason?.name}</span>
              </div>
            )}
          </div>
          <div className={styles['column']}>
            <div className={styles['details-title']}>
              {translate('Plan - sowing date - Plan overview', selectedLanguage)}
              <span className={styles['details-value']}>
                {sowingDate && formatDate(sowingDate)}
              </span>
            </div>
            <div className={styles['details-title']}>
              {translate('Plan creation overview page: Planting date', selectedLanguage)}
              <span className={styles['details-value']}>
                {plantingDate && formatDate(plantingDate!)}
              </span>
            </div>
            <div className={styles['details-title']}>
              {translate('Plan creation overview page: First Setting Day', selectedLanguage)}
              <span className={styles['details-value']}>
                {firstSettingDate && formatDate(firstSettingDate!)}
              </span>
            </div>
          </div>
          <div className={styles['column']}>
            <div className={styles['details-title']}>
              {translate('Overview of your plan page: Head removal', selectedLanguage)}
              <span className={styles['details-value']}>
                {headRemovalDate && formatDate(headRemovalDate)}
              </span>
            </div>
            <div className={styles['details-title']}>
              {translate('Overview of your plan: Last Harvest date', selectedLanguage)}
              <span className={styles['details-value']}>
                {harvestDate && formatDate(harvestDate!)}
              </span>
            </div>
          </div>
        </div>

        {previewPlanId && (
          <div className={`${styles.preview} c-hand`} onClick={handleOpenPreview}>
            <Eye className={`icon-light `} />
            <span className={styles['preview-text']}>
              {translate('Create Plan - click to seen plan preview', selectedLanguage)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default PlanPreviewStep;
