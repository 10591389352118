import { FC, useEffect, useRef, useState } from 'react';
import './DropButton.scss';
import { ReactComponent as ChevronIcon } from 'styles/icons/chevron-down.svg';
import { ActionItem } from 'interfaces/action-item';

interface DropButtonProps {
  actions: ActionItem[];
  isDropUp?: boolean;
}

const DropButton: FC<DropButtonProps> = ({ actions = [], isDropUp }) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  function handleOutsideClick(event: MouseEvent) {
    if (
      dropDownRef.current &&
      isDropDownOpen &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setIsDropDownOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  if (!actions.length) {
    return <button className='btn'>No action</button>;
  }

  return (
    <div
      className={`drop-button-container dropdown ${isDropDownOpen ? 'active' : ''} ${
        isDropUp ? 'dropup' : ''
      }`}
      ref={dropDownRef}
    >
      <button className='btn btn-primary'>
        <span onClick={actions[0].action}>{actions[0].label}</span>
        <span
          className='dropdown-toggle'
          tabIndex={0}
          onClick={() => {
            setIsDropDownOpen(!isDropDownOpen);
          }}
        >
          <ChevronIcon className={`icon-fill ${isDropDownOpen ? 'rotate-180' : ''}`} />
        </span>
        <ol className={`menu`}>
          {actions.map((action, index) => (
            <li
              key={index}
              onClick={() => {
                action.action();
                setIsDropDownOpen(false);
              }}
            >
              {action.label}
            </li>
          ))}
        </ol>
      </button>
    </div>
  );
};
export default DropButton;
