import React from 'react';

import { ReactComponent as MaxValue } from 'styles/icons/maximum-value.svg';
import { ReactComponent as MinValue } from 'styles/icons/minimum-value.svg';
import { trimDotsAndSpaces } from 'utils/d3-utils/format';
import { roundToDecimals } from 'utils/roundToDecimals';
import { xTickFormater } from 'utils/tickFormatter';

interface RangeTooltipProps {
  customFormatter?: (input: any) => string;
  resolution: string;
  rangeLabel?: string;
  hoverData?: any;
  numberOfDecimals?: number;
}

const styles = {
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: { display: 'inline-block', margin: '0px 5px' },
};

const SCALE_CHANGE_LINE_COUNT = 8;

const RangeTooltip: React.FC<RangeTooltipProps> = ({
  customFormatter,
  resolution,
  rangeLabel = 'Ideal Range:',
  hoverData,
  numberOfDecimals = 1,
}) => {
  const getFormatedData = (index: number) => {
    let valueText = '',
      minText = '',
      maxText = '',
      footerTime = '';
    if (hoverData?.length) {
      const scaling = hoverData[index]?.scaling || 1;
      const unit = hoverData[index]?.unit || '';
      const label = hoverData[index]?.label || '';
      const data = hoverData[index];
      if (data.d[data.keyName]?.length) {
        minText =
          (data.d[data.keyName][0] &&
            `${roundToDecimals(data.d[data.keyName][0] * scaling, numberOfDecimals)}`) ||
          'No data';
        maxText =
          (data.d[data.keyName][1] &&
            `${roundToDecimals(data.d[data.keyName][1] * scaling, numberOfDecimals)}`) ||
          'No data';
      } else if (data.d[data.keyName] || data.d[data.keyName] === 0) {
        valueText =
          `${roundToDecimals(
            data.d[data.keyName] * scaling,
            numberOfDecimals
          )}  ${unit} ${label}` || 'No data';
      }
      if (customFormatter) {
        footerTime = customFormatter(hoverData[index]?.d.timestamp);
      } else {
        footerTime = `${xTickFormater(resolution)(hoverData[index]?.d.timestamp)}`;
      }
    }
    return {
      valueText: valueText || 'No data',
      minText: minText || 'No data',
      maxText: maxText || 'No data',
      footerTime: footerTime || 'No data',
    };
  };

  if (!hoverData?.length) return null;
  const scaleFactor = hoverData.length < SCALE_CHANGE_LINE_COUNT ? 1 : 0.7;
  return (
    <div>
      {hoverData.map((data: any, index: number) => {
        return (
          <div style={styles.row} key={index}>
            <svg
              height='5'
              width='25'
              style={{
                marginRight: '10px',
              }}
            >
              <path
                stroke={data.color}
                strokeDasharray={data.stroke || ''}
                strokeWidth={4 * scaleFactor}
                d={`M0 2 l${25 * scaleFactor} 0`}
              />
            </svg>
            {data.type === 'area' ? (
              <>
                <span>{rangeLabel}</span>
                <MinValue
                  className={`icon-white ${trimDotsAndSpaces(data.keyName)}-min-icon`}
                  style={styles.icon}
                />
                <span className={`${trimDotsAndSpaces(data.keyName)}-min-value-${index}`}>
                  {getFormatedData(index).minText || 'No Data'}
                </span>
                <MaxValue className={`icon-white`} style={styles.icon} />
                <span className={`${trimDotsAndSpaces(data.keyName)}-max-value-${index}`}>
                  {getFormatedData(index).maxText || 'No Data'}
                </span>
              </>
            ) : (
              <span className={`${trimDotsAndSpaces(data.keyName)}-value`}>
                {getFormatedData(index).valueText || 'No Data'}
              </span>
            )}
          </div>
        );
      })}

      <p
        style={{
          fontSize: '11px',
          fontFamily: 'Helvetica Neue LT Pro',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        {getFormatedData(0).footerTime}
      </p>
    </div>
  );
};
export default RangeTooltip;
