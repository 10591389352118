import { PLAN_VARIABLES_OLD_NAME_ID_MAPPING } from 'config/constants';
import { useCallback } from 'react';
import { useGetPlanTimeseriesVariablesQuery } from 'services/plans.service';
import { getVariableUnitMapper } from 'utils/plan-variables-mapper';
import { translate } from 'utils/translations.util';

export const useGetUnitsForEditPlan = (id: number, variable?: string) => {
  const { data: planTimeseriesVariables } = useGetPlanTimeseriesVariablesQuery(Number(id), {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const getUnitForVariable = useCallback(
    (input: string) => {
      if (planTimeseriesVariables) {
        let variable = planTimeseriesVariables.find((variable) => variable.id === input);
        if (variable?.id === PLAN_VARIABLES_OLD_NAME_ID_MAPPING.dli) {
          return getVariableUnitMapper(variable.id);
        }
        return translate(variable?.unit);
      }
    },
    [planTimeseriesVariables]
  );
  if (variable) {
    return getUnitForVariable(variable);
  }
};
