import React from 'react';

import Tabs from 'components/Tabs/Tabs';
import Benchmark from './Benchmark/Benchmark';
import TrackHeader from 'components/Track/TrackHeader/TrackHeader';

import { useAppSelector } from 'redux/hooks';
import { trackFilterSelector } from 'redux/slices/trackFilterSlice';
import { setTrackPageActiveTab } from 'utils/setTrackPageActiveTab';
import useTranslate from 'hooks/useTranslate';
import './Climate.scss';
import Plan from './Plan/Plan';
import { Resolution } from 'interfaces/resolution';
import { hasBenchmarkResolutionError } from 'utils/variables.util';

interface ClimateProps {}

const Climate: React.FC<ClimateProps> = () => {
  const { dates, activeTab, resolution } = useAppSelector(trackFilterSelector);
  const translate = useTranslate();

  const resolutionError = hasBenchmarkResolutionError(
    dates.start,
    dates.end,
    resolution.value as Resolution
  );
  const hasResolutionError = resolutionError !== false && activeTab === 1;

  return (
    <div className='track-page-container'>
      <div className='track-header-container'>
        <TrackHeader
          title={translate('Level 2 - Climate - page title')}
          dates={dates}
          stateName='climate'
        />
        {hasResolutionError && <div className='track-dates-error'>{resolutionError}</div>}
      </div>
      <Tabs
        tabs={[
          { label: translate('Level 2 - tab - Plan'), content: <Plan /> },
          { label: translate('Level 2 - tab - Benchmark'), content: <Benchmark /> },
        ]}
        activeTab={activeTab}
        onChange={setTrackPageActiveTab}
      />
    </div>
  );
};

export default Climate;
