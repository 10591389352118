import { DatePicker } from 'components/DatePicker/DatePicker';
import NumberInput from 'components/NumberInput/NumberInput';
import dayjs, { Dayjs } from 'dayjs';
import { PruningValues } from 'interfaces/custom-settings';
import { DateRange } from 'interfaces/date-range';
import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { CustomPeriod, customPlanSelector, setCustomPlan } from 'redux/slices/customPlan.slice';
import { languageSelector } from 'redux/slices/language.slice';
import { ReactComponent as CloseIcon } from 'styles/icons/close.svg';
import { ReactComponent as PlusIcon } from 'styles/icons/plus.svg';
import { getUniqueId } from 'utils/getUniqueId';
import { getFirstWeekOfGivenDate } from 'utils/time.util';
import { translate } from 'utils/translations.util';

import styles from './Pruning.module.scss';
import { ButtonVariantEnum } from 'interfaces/button';
import Button from 'components/Button/Button';
import { track } from 'utils/analtycs';

interface PruningProps {
  harvestDate: number;
  pruningEvents: PruningValues[] | [];
}

const Pruning: React.FC<PruningProps> = ({ harvestDate, pruningEvents }) => {
  const dispatch = useAppDispatch();
  const pruningData = useAppSelector(customPlanSelector).customPlanState?.pruning;
  const { selectedLanguage } = useAppSelector(languageSelector);

  const getInitialEvents = useCallback(() => {
    let initialEvents: any = [];
    if (pruningEvents) {
      pruningEvents.forEach((event: any, index: number) => {
        if (index === 0) {
          initialEvents.push({
            periodId: getUniqueId(),
            periodTitle: translate('Custom Plan - pruning - title', selectedLanguage),
            datePickerTitle: 'Initial week',
            numberInputHeader: translate('Custom Plan - pruning - Fruits/truss', selectedLanguage),
            startDate: dayjs(event.timestamp).unix(),
            value: event.value,
            isValid: true,
            isInRange: true,
            disabled: true,
          });
          if (pruningEvents.length === 1) {
            initialEvents.push({
              periodId: getUniqueId(),
              periodTitle: 'Final period',
              datePickerTitle: 'Final week',
              numberInputHeader: translate(
                'Custom Plan - pruning - Fruits/truss',
                selectedLanguage
              ),
              startDate: harvestDate,
              value: event.value,
              isValid: true,
              isInRange: true,
              disabled: true,
            });
          }
          return;
        }
        initialEvents.push({
          periodId: getUniqueId(),
          periodTitle: translate('Custom Plan - pruning - Next period', selectedLanguage),
          datePickerTitle: 'Select the week',
          numberInputHeader: translate('Custom Plan - pruning - Fruits/truss', selectedLanguage),
          startDate: dayjs(event.timestamp).unix(),
          value: event.value,
          isValid: true,
          disabled: false,
          isInRange: true,
        });
        if (index === pruningEvents.length - 1) {
          initialEvents.push({
            periodId: getUniqueId(),
            periodTitle: 'Final period',
            datePickerTitle: 'Final week',
            numberInputHeader: translate('Custom Plan - pruning - Fruits/truss', selectedLanguage),
            startDate: harvestDate,
            value: event.value,
            isValid: true,
            isInRange: true,
            disabled: true,
          });
        }
      });
    }
    return initialEvents;
  }, [harvestDate, pruningEvents, selectedLanguage]);

  const pruningDataReversed = pruningData?.slice().reverse();

  useEffect(() => {
    if (pruningEvents) {
      dispatch(
        setCustomPlan({
          pruning: getInitialEvents(),
        })
      );
    }
  }, [pruningEvents, dispatch, getInitialEvents]);

  const handleRemoveRow = (id: string) => {
    track('custom settings', {
      field: 'remove pruning row',
      value: id,
    });
    dispatch(setCustomPlan({ pruning: pruningData?.filter((period) => period.periodId !== id) }));
  };

  const handleAddRow = () => {
    let itemToAdd = {} as any;
    //TODO refactor logic, extract it in builder method
    if (pruningData && pruningData.length > 2) {
      itemToAdd = {
        periodId: getUniqueId(),
        periodTitle: translate('Custom Plan - pruning - Next period', selectedLanguage),
        datePickerTitle: 'Select the week',
        numberInputHeader: translate('Custom Plan - pruning - Fruits/truss', selectedLanguage),
        startDate: dayjs.unix(pruningData?.at(-2)?.startDate!).add(1, 'week').unix(),
        value: pruningData.at(0)!.value,
        isValid: true,
        disabled: false,
        isInRange: true,
      };
    } else if (pruningData) {
      const [firstDay] = getFirstWeekOfGivenDate(
        dayjs.unix(pruningData.at(0)?.startDate!).add(1, 'week')
      );

      itemToAdd = {
        periodId: getUniqueId(),
        periodTitle: translate('Custom Plan - pruning - Next period', selectedLanguage),
        datePickerTitle: 'Select the week',
        numberInputHeader: translate('Custom Plan - pruning - Fruits/truss', selectedLanguage),
        startDate: firstDay.unix(),
        value: pruningData.at(0)!.value,
        isValid: true,
        disabled: false,
        isInRange: true,
      };
    }

    if (pruningData) {
      track('custom settings', {
        field: 'add pruning row',
        value: itemToAdd?.periodId,
      });
      dispatch(
        setCustomPlan({
          pruning: [...pruningData.slice(0, -1), itemToAdd, ...pruningData.slice(-1)],
        })
      );
    }
  };

  useEffect(() => {
    if (!pruningData) {
      return;
    }
    if (pruningData.length === 1) {
      return;
    }
    for (let i = 1; i < pruningData.length - 1; i++) {
      const currentItem = pruningData[i];
      const currentItemStartDate = currentItem.startDate;

      const previousItem = pruningData[i - 1];
      const previousItemStartDate = previousItem.startDate;

      const currentItemId = currentItem.periodId;

      if (currentItemStartDate <= previousItemStartDate && currentItem.isValid) {
        dispatch(
          setCustomPlan({
            pruning: pruningData.map((period: CustomPeriod) => {
              if (period.periodId === currentItemId) {
                return {
                  ...period,
                  isValid: false,
                };
              } else {
                return period;
              }
            }),
          })
        );
      } else if (currentItemStartDate > previousItemStartDate && !currentItem.isValid) {
        dispatch(
          setCustomPlan({
            pruning: pruningData.map((period: CustomPeriod) => {
              const currentItemId = currentItem.periodId;
              if (period.periodId === currentItemId) {
                return {
                  ...period,
                  isValid: true,
                };
              } else {
                return period;
              }
            }),
          })
        );
      }
      if (
        (currentItemStartDate <= pruningData[0].startDate ||
          currentItemStartDate >= pruningData[pruningData.length - 1].startDate) &&
        currentItem.isInRange
      ) {
        dispatch(
          setCustomPlan({
            pruning: pruningData.map((period: CustomPeriod) => {
              if (period.periodId === currentItemId) {
                return {
                  ...period,
                  isInRange: false,
                };
              } else {
                return period;
              }
            }),
          })
        );
      } else if (
        currentItemStartDate > pruningData[0].startDate &&
        currentItemStartDate < pruningData[pruningData.length - 1].startDate &&
        !currentItem.isInRange
      ) {
        dispatch(
          setCustomPlan({
            pruning: pruningData.map((period: CustomPeriod) => {
              if (period.periodId === currentItemId) {
                return {
                  ...period,
                  isInRange: true,
                };
              } else {
                return period;
              }
            }),
          })
        );
      }
    }
  }, [pruningData, dispatch]);

  const handleDatePickerChange = (periodId: string) => (value: DateRange | Dayjs) => {
    let range = value as DateRange;
    track('custom settings', {
      field: 'pruning row startDate',
      value: range?.start,
    });
    dispatch(
      setCustomPlan({
        pruning: pruningData?.map((period: CustomPeriod) => {
          if (period.periodId === periodId) {
            return {
              ...period,
              startDate: range.start.unix(),
            };
          } else {
            return period;
          }
        }),
      })
    );
  };

  const handleNumberInputChange = (periodId: string) => (currentValue: string) => {
    track('custom settings', {
      field: 'pruning row value',
      value: currentValue,
    });
    dispatch(
      setCustomPlan({
        pruning: pruningData?.map((period: CustomPeriod) => {
          if (period.periodId === periodId) {
            return {
              ...period,
              value: currentValue,
            };
          } else {
            return period;
          }
        }),
      })
    );
  };

  return (
    <div className={styles['main-container']}>
      <div className={styles['pruning-periods-container']}>
        <div className={styles['period-row']}>
          <div className={styles['period-title-until-end']}>
            {translate('Custom Plan - pruning - Untill end', selectedLanguage)}
          </div>
        </div>
        {pruningDataReversed
          ?.slice(1, -1)
          ?.filter(Boolean)
          .map((period: any, index: number) => {
            return (
              <React.Fragment key={period.periodId}>
                {!period.isValid && period.isInRange && (
                  <p className={styles['warning-valid-week']}>Select a valid week</p>
                )}
                {!period.isInRange && (
                  <p className={styles['warning']}>
                    Select a week within the time range of this CropPlan
                  </p>
                )}
                <div className={styles['period-row']}>
                  <div className={styles['period-title']}>{period.periodTitle}</div>
                  <div className={styles['week']}>
                    <DatePicker
                      weekMode
                      startDate={dayjs.unix(period.startDate || 0)}
                      dropDownPosition='dropdown-datepicker-middle'
                      label={period.datePickerTitle}
                      onChange={handleDatePickerChange(period.periodId)}
                    />
                  </div>
                  <div className={styles['amount']}>
                    <NumberInput
                      headerText={period.numberInputHeader}
                      initialValue={period.value}
                      positive
                      minValue={0.05}
                      onChange={handleNumberInputChange(period.periodId)}
                      amount={0.05}
                      numberOfDecimals={2}
                    />
                  </div>
                  <div
                    className={styles['close-icon-container']}
                    onClick={() => handleRemoveRow(period.periodId)}
                  >
                    {index < pruningDataReversed.length - 1 && (
                      <CloseIcon className={`icon-light c-hand ${styles['close-icon']}`} />
                    )}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        <div className={styles['period-row']}>
          <div className={styles['period-title-from-start-header']}>
            {translate('Custom Plan - pruning - title', selectedLanguage)}
          </div>
          <div className={styles['period-title-from-start']}>
            {translate('Custom Plan - pruning - From Start', selectedLanguage)}
          </div>
          <div className={styles['amount']}>
            <NumberInput
              headerText={pruningData?.[0]?.numberInputHeader}
              initialValue={pruningData?.[0]?.value || 0}
              positive
              minValue={0.05}
              onChange={handleNumberInputChange(pruningData?.[0]?.periodId || '')}
              amount={0.05}
              numberOfDecimals={2}
            />
          </div>
        </div>
      </div>
      <Button
        id={'add-button-pruring'}
        styleType={ButtonVariantEnum.HOLLOW}
        onHandleClick={handleAddRow}
      >
        <PlusIcon className='icon-light plus-icon' /> {translate('button Add text')}
      </Button>
    </div>
  );
};
export default Pruning;
