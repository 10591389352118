import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { CropSeason } from 'interfaces/crop-season';
import { Location } from 'interfaces/location';

import type { RootState } from 'redux/store';
import { CropseasonNotification } from 'interfaces/cropseason-notification';

interface NotificationState {
  locations: Location[];
  selectedCropSeasons: CropSeason[];

  dates: {
    from: number;
    to: number;
  };
  selectedNotification: CropseasonNotification | null;
}

const initialState = (): NotificationState => ({
  locations: [],
  selectedCropSeasons: [],

  dates: {
    from: dayjs().subtract(1, 'months').unix(),
    to: dayjs().unix(),
  },

  selectedNotification: null,
});

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState(),
  reducers: {
    setFilter: (state, { payload }) => {
      return (state = {
        ...state,
        ...payload,
      });
    },
    resetFilter: (state) => {
      return (state = { ...initialState() });
    },
    setFilterDates: (state, { payload }) => {
      state.dates = {
        ...state.dates,
        ...payload,
      };
    },
    setSelectedNotifications: (state, { payload }) => {
      state.selectedNotification = payload;
    },
  },
});

export const nofiticationSelector = {
  filters: (state: RootState) => state.notification,
  selectedNotifications: (state: RootState) => state.notification.selectedNotification,
};

export const notificationActions = notificationSlice.actions;

export default notificationSlice.reducer;
